import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Transaction, { RelatedBooking, relationTypeCompareFc, TransStatus, transStatusToDisplayString } from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import withAsycnData from "../data/WithAsyncData";
import { scheduleBookingViewJss } from "./ScheduleBookingView.jss";
import { BOOKING_DETAIL_VIEW_PATH } from "./TransactionsViewConstants";
import View from "../view/View";
import { Theme } from "../css/Theme";
import UIUtil from "../util/UIUtil";
import Util from "../util/Util";
import ScheduleBookingForm, { ScheduleBookingFormData } from "./ScheduleBookingForm";
import { useTheme } from 'react-jss';
import ScheduleBookingTrack from "./ScheduleBookingTrack";
import ScheduleBookingConfirm from "./ScheduleBookingConfirm";
import { CheckboxStyled } from "./BookingsView";
import { AppContext } from "../app/App";
import { BookingSubtitle } from "./BookingSubtitle";

type IStyle = ReturnType<typeof scheduleBookingViewJss>;

interface IProps extends WithClasses<IStyle> {
    booking?: Transaction;
    relatedBookings?: Transaction[];
    onRequestClose?: () => void;
}
/**
 * Workaround to remove repeated related bookings. Remove when fixed.
 */
export function removeRepeatedId(relatedBookings: RelatedBooking[]) {
    const result: RelatedBooking[] = [];
    relatedBookings.forEach(rb => {
        if (!result.some(r => r.bookingId === rb.bookingId)) {
            result.push(rb);
        }
    });
    return result;
}

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

const ScheduleBookingView: FunctionComponent<IProps> = ({ booking, relatedBookings, onRequestClose, classes, appClasses }) => {
    const { waitFor } = useContext(AppContext);
    const theme = useTheme() as Theme;
    const bookingUpdate = Util.clone(booking!);
    // `booking` is optional since we need to request them initially.
    const [relatedBookingUpdates, setRelatedBookingUpdates] = useState<Optional<ScheduleBookingFormData, 'booking'>[]>(() => {
        let related = [{ bookingId: bookingUpdate.id, type: bookingUpdate.relatedBookings.some(rb => rb.type === "OUTBOUND") ? "RETURN" : "OUTBOUND", booking: bookingUpdate! } as Optional<ScheduleBookingFormData, 'booking'>]
            .concat(removeRepeatedId(bookingUpdate.relatedBookings).map(rb => ({ ...rb, booking: relatedBookings?.find(rbp => rbp.id === rb.bookingId) })));
        related = related.filter(rb => {
            const scheduleAction = rb.booking?.actions.find(action => action.action === "SCHEDULE" || action.action === "RESCHEDULE");
            return scheduleAction && !scheduleAction.disabled;
        }); // Filter schedulable bookings
        related.sort((a, b) => relationTypeCompareFc(a.type, b.type));
        related.forEach(rb => { // Skip cancelled legs by default (just if there are more than one related booking, for single bookings the skip checkbox won't be displayed).
            if (related.length > 1 && rb.booking?.status && (rb.booking?.status === TransStatus.PROVIDER_CANCELED || rb.booking?.status === TransStatus.PROVIDER_DECLINED || rb.booking?.status === TransStatus.PROVIDER_NO_SHOW)) {
                rb.skip = true;
                rb.skipReason = `Notice this leg is canceled (status ${transStatusToDisplayString(rb?.booking.status!)}).`;
            }
        })
        return related;
    });
    // This will always be true given we now request all related bookings before rendering this component 
    // (`renderWhenData` passed in true, where data includes related bookings).
    // TODO: simplify the following logic considering that.
    const hasAllBookings = relatedBookingUpdates.every(rb => rb.booking);
    useEffect(() => {
        if (hasAllBookings) {
            const returnUpdate = relatedBookingUpdates.find(rb => rb.type === "RETURN");
            const outboundUpdate = relatedBookingUpdates.find(rb => rb.type === "OUTBOUND");
            if (returnUpdate && outboundUpdate
                // Comparing with Util.equals doesn't work since the locations sometimes have differencies
                // in lat / lng decimals, so compare by address as a workaround.
                // && Util.equals(returnUpdate.booking!.from, outboundUpdate.booking!.to)
                // && Util.equals(returnUpdate.booking!.to, outboundUpdate.booking!.from)
                && returnUpdate.booking!.from.address === outboundUpdate.booking!.to.address
                && returnUpdate.booking!.to.address === outboundUpdate.booking!.from.address
            ) {
                returnUpdate.syncFromTo = true;
            };
        }
    }, [hasAllBookings]);
    const hasRelatedBookings = relatedBookingUpdates.length > 1;
    const [selectedBookingId, setSelectedBookingId] = useState<string | undefined>(relatedBookingUpdates[0]?.bookingId);
    const selectedBookingForm = relatedBookingUpdates.find(rb => rb.bookingId === selectedBookingId);
    const selectedBookingI = relatedBookingUpdates.findIndex(rb => rb.bookingId === selectedBookingId);
    const onFormChange = (update: ScheduleBookingFormData): void => {
        const relatedBookingI = relatedBookingUpdates.findIndex(rb => rb.bookingId === selectedBookingId);
        // Checked 'Sync with outbound', so need to update current from / to (return trip) with outbounds to / from, respectively.
        if (update.syncFromTo && !relatedBookingUpdates[relatedBookingI]!.syncFromTo) {
            const outbound = relatedBookingUpdates.find(rbu => rbu.type === "OUTBOUND");
            if (outbound) {
                update.booking!.from = outbound.booking!.to
                update.booking!.to = outbound.booking!.from
            }
        }
        // Updated outbound's from or to, and the return trip has 'Sync with outbound' checked, so need to update return's to or from, respectively.
        if (relatedBookingUpdates[relatedBookingI]!.type === "OUTBOUND" && update &&
            (update.booking!.from !== relatedBookingUpdates[relatedBookingI]!.booking!.from ||
                update.booking!.to !== relatedBookingUpdates[relatedBookingI]!.booking!.to)) {
            const returnTrip = relatedBookingUpdates.find(rbu => rbu.type === "RETURN");
            if (returnTrip && returnTrip.syncFromTo) {
                returnTrip.booking!.from = update.booking!.to;
                returnTrip.booking!.to = update.booking!.from;
            }
        }
        relatedBookingUpdates[relatedBookingI] = update;
        setRelatedBookingUpdates([...relatedBookingUpdates]);
    };
    const onPrevNext = (next?: boolean) => {
        const prev = !next;
        const relatedBookingI = relatedBookingUpdates.findIndex(rb => rb.bookingId === selectedBookingId);  // Undefined when on review view.
        if (prev) {
            if (relatedBookingI === 0) {
                onRequestClose?.();
            } else {
                setSelectedBookingId(relatedBookingUpdates[relatedBookingI !== -1 ? relatedBookingI - 1 : relatedBookingUpdates.length - 1].bookingId);
            }
            return;
        }
        if (relatedBookingUpdates.length === 1 || selectedBookingId === undefined) { // Review
            const schedulePromises: Promise<void>[] = [];
            relatedBookingUpdates.forEach(rb => {
                if (!rb.skip) {
                    schedulePromises.push(TransactionsData.instance.update(rb.booking!));
                    // schedulePromises.push(Promise.reject("reject!!!"));
                };
            });
            waitFor(
                Promise.all(schedulePromises)
                    .catch(e => {
                        console.log(e);
                        return UIUtil.errorMessage(e);
                    })    // Catch error and show error message
                    .finally(() => onRequestClose?.()));

        } else {
            setSelectedBookingId(relatedBookingUpdates[relatedBookingI + 1]?.bookingId);    // Selects next booking, or undefined if the current is the last one, indicating review.
        }
    }
    return (
        <View
            title={"Schedule Trip" + (hasRelatedBookings ? "s" : "")}
            subtitle={relatedBookingUpdates[0]?.booking ? <BookingSubtitle booking={relatedBookingUpdates[0].booking} /> : undefined}
            right={hasRelatedBookings && hasAllBookings ?
                <div className={classes.subtitle}>
                    <ScheduleBookingTrack
                        relatedBookingsData={relatedBookingUpdates as ScheduleBookingFormData[]}
                        selectedBookingId={selectedBookingId}
                    />
                    {selectedBookingForm ?
                        <div className={appClasses.form}>
                            <div className={appClasses.formGroup}>
                                <label>Skip this booking</label>
                                <CheckboxStyled
                                    checked={!!selectedBookingForm.skip}
                                    onChange={e => {
                                        onFormChange({ ...selectedBookingForm as ScheduleBookingFormData, skip: e.target.checked });
                                    }}
                                    key={selectedBookingId}
                                />
                                <div style={{ marginLeft: '20px', color: theme.colorError }}>{selectedBookingForm.skipReason}</div>
                            </div>
                        </div> : <div style={{ height: '24px' }} />}
                </div> : undefined
            }
        >
            {selectedBookingId ? // Require update to be instantiated, to then cast form below to ScheduleBookingFormData.
                (selectedBookingForm?.booking ?
                    <div className={appClasses.form}>
                        <ScheduleBookingForm
                            form={selectedBookingForm as ScheduleBookingFormData}
                            relatedBookings={relatedBookingUpdates as ScheduleBookingFormData[]}    // TODO: this cannot actually be ensured, since update can be missing for some elements.
                            onFormChange={onFormChange}
                            theme={theme}
                            onRequestClose={onPrevNext}
                            key={selectedBookingId}
                            cancelLabel={selectedBookingI === 0 ? "Cancel" : "Back"}
                            proceedLabel={hasRelatedBookings ? "Next" : "Schedule"}
                        />
                    </div>
                    : null
                )
                :
                <ScheduleBookingConfirm
                    relatedBookingsData={relatedBookingUpdates as ScheduleBookingFormData[]}  // TODO: this cannot actually be ensured, since update can be missing for some elements.
                    onConfirm={() => {
                        onPrevNext(true);
                    }}
                    onRequestClose={onPrevNext}
                />
            }
        </View>
    );
}

const ScheduleBookingViewStyled = withStyles(ScheduleBookingView, scheduleBookingViewJss);

// noinspection JSUnusedLocalSymbols
const ScheduleBookingViewWithData = withAsycnData(ScheduleBookingViewStyled,
    async (query: { id: string }) => {
        const booking = await TransactionsData.instance.get(query.id);
        const relatedBookings = await Promise.all(booking.relatedBookings.map(rb => TransactionsData.instance.get(rb.bookingId!)));
        return ({ booking, relatedBookings } as { booking?: Transaction, relatedBookings?: Transaction[] });
    });

export const BOOKING_SCHEDULE_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: BOOKING_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/schedule")).concat(["*/bookingSchedule/:bookingScheduleId"]),
    propsFromMatch: (match: any) => ({ id: match.params.id || match.params.tripId || match.params.bookingScheduleId }),
    propsToPath: () => "/schedule",
    navLabel: () => "Schedule Booking",
    render: ({ viewProps, navHistory }) => {
        return (
            <ScheduleBookingViewWithData
                id={viewProps.id}
                onRequestClose={() => {
                    navHistory.pop();
                }}
                // Since on constructor it determines if it's an editing or a creation based on if the user (data) is
                // present or not (undefined). Also avoids the need to display the editing view in a waiting state.
                renderWhenData={true}
                // Avoid re-construction of view after save clicked (notice WithAsyncData updates on any url update due
                // to strong props compare).
                undefineOnUpdate={false}
            />
        );
    }
};