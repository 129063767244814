import React from 'react';
import { WithClasses, withStyles } from "../css/StyleHelper";
import { genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import AdminProfile from "./AdminProfile";
import { roleToLabel } from '../settings/EditAdminView';
import { useProviders } from '../data/ProvidersData';

const accountBtnJss = (theme: Theme) => ({
    main: {
        ...resetJss.button,
        cursor: 'pointer',
        padding: '5px',
        margin: '25px 25px 0 0',
        ...genJss.flex,
        ...genJss.borderRadius(10)
    },
    picture: {
        height: '30px',
        width: 'auto',
        marginRight: '10px',
        ...genJss.alignSelfCenter,
        ...genJss.borderRadius(50, '%')
    },
    leftPanel: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignEnd
    },
    userID: {
        ...theme.textWeightSemibold
    },
    role: {
        ...theme.textColorGray
    },
    angleDown: {
        padding: '3px',
        marginLeft: '3px',
        width: '10px',
        height: '10px'
    }
});

type IStyle = ReturnType<typeof accountBtnJss>

interface IProps extends WithClasses<IStyle> {
    adminProfile: AdminProfile;
    onClick?: () => void;
}

const AccountBtn: React.FunctionComponent<IProps> = (props: IProps) => {
    const classes = props.classes;
    const profile = props.adminProfile;
    const providers = useProviders({ adminProfile: profile });
    return (
        <button className={classes.main} onClick={props.onClick}>
            <img className={classes.picture} src={profile.picture} />
            <div className={classes.leftPanel}>
                <div className={classes.userID}>
                    {profile.name || profile.nickname}
                </div>
                <div className={classes.role}>
                    {profile.tspMode ? providers?.find(p => p.id === profile.tspMode)?.name ?? roleToLabel(profile.role) : roleToLabel(profile.role)}
                </div>
            </div>
            <IconTriangleDown className={classes.angleDown} />
        </button>
    )
};

export default withStyles(AccountBtn, accountBtnJss);