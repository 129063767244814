import React from "react";
import { Theme } from "../css/Theme";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { genJss } from "../css/gen.jss";
import { Subtract } from "utility-types";

type IStyle = ReturnType<typeof userAutocompleteResultJss>;

interface IProps extends WithClasses<IStyle> {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    icon?: React.ReactNode;
    isHighlighted?: boolean;
    reference?: (el: any) => void;
    onClick?: () => void;   // Need this to forward onClick set by react-autocomplete.
}

const userAutocompleteResultJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        // ...genJss.alignCenter,
        padding: '5px',
        margin: '0 5px'
    },
    icon: {
        height: '24px',
        width: '24px',
        marginRight: '10px',
        '& path': {
            fill: '#5f636'
        }
    },
    resultItem: {
        ...genJss.flex,
        ...genJss.column,
        '& > *': {
            lineHeight: '24px'
        }
    },
    resultTitle: {

    },
    resultSubtitle: {
        color: '#212a33de'
    }
});

const AutocompleteResult: React.FunctionComponent<IProps> = ({ title, subtitle, icon, isHighlighted, reference, onClick, classes }) => {

    return (
        <div className={classes.main} style={{ background: isHighlighted ? '#efeded' : 'white' }}>
            {icon &&
                <div className={classes.icon}>
                    {icon}
                </div>}
            <div
                className={classes.resultItem}
                ref={reference}
                onClick={onClick}
            >
                <div className={classes.resultTitle}>
                    {title}
                </div>
                <div className={classes.resultSubtitle}>
                    {subtitle}
                </div>
            </div>
        </div>
    );

}

const AutocompleteResultStyled = withStyles(AutocompleteResult, userAutocompleteResultJss);

// This ref forwarding is necessary since react-select passes a reference
// to this component (if I ommit this an exception is thrown).
export default React.forwardRef((props: Subtract<IProps, WithClasses<IStyle>>, ref: any) => (
    <AutocompleteResultStyled {...props} reference={ref} />
));