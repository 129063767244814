import { Subtract } from "utility-types/dist/mapped-types";
import AdminUser from "../model/AdminUser";
import { AppMetadata } from "./AdminProfile";
import { getAppIdPath } from "./WithAuth";

enum Envs {
    "PROD" = "PROD",
    "STAGING" = "STAGING",
    "BETA" = "BETA"
};

function getEnvironment(): Envs {
    const hostname = window.location.hostname;
    return hostname.includes("-beta") ? Envs.BETA :
        hostname.includes("-staging") ? Envs.STAGING : Envs.PROD;
}

export const appId = getAppIdPath() ?? "feonix-catcharide";

const environment = getEnvironment();

export interface IAppCredentials {
    appId: string;
    auth0Domain: string;
    auth0ClientId: string;
    gqlApiKey: string;
    gqlApiRegion: string;
    gqlApiUrl: string;
}

function getAppCredentials(): IAppCredentials {
    const appCredentials: Record<string, Subtract<IAppCredentials, { appId: string }>> = {
        "feonix-catcharide": {
            ...environment === Envs.BETA ? {
                "auth0Domain": "catch-a-ride-beta.us.auth0.com",
                "auth0ClientId": "Kv6cxe8em3sKi9jlFSQYKdO40wBNF5aM",
                "gqlApiKey": "da2-iyger76vdferloxcw36wxb565a",
                "gqlApiRegion": "eu-central-1",
                "gqlApiUrl": "https://djhmatfcmfd5xntdquqywejyvu.appsync-api.eu-central-1.amazonaws.com/graphql"
            } : environment === Envs.STAGING ? {
                "auth0Domain": "catch-a-ride-staging.us.auth0.com",
                "auth0ClientId": "66JPPIF2y8VAP2NpVjEA3iGnoYk8uByB",
                "gqlApiKey": "da2-iyger76vdferloxcw36wxb565a",
                "gqlApiRegion": "us-east-2",
                "gqlApiUrl": "https://jdaxafdvt5av5es57fh4k4hqva.appsync-api.us-east-2.amazonaws.com/graphql"
            } : {
                "auth0Domain": "feonix-waupaca.us.auth0.com",
                "auth0ClientId": "61zQUZ7DhqP3QRNtFxNBfbiAwCrQR2xn",
                "gqlApiKey": "da2-f6q6mglcfnekvenznx3tlfd7la",
                "gqlApiRegion": "us-west-2",
                "gqlApiUrl": "https://siz4nmddf5dzhiowagrexha7om.appsync-api.us-west-2.amazonaws.com/graphql"
            }
        },
        // To hit pre-release BE from any environment
        "feonix-catcharide-test": {
            ...environment === Envs.BETA ? {
                "auth0Domain": "catch-a-ride-beta.us.auth0.com",
                "auth0ClientId": "Kv6cxe8em3sKi9jlFSQYKdO40wBNF5aM",
                "gqlApiKey": "da2-iyger76vdferloxcw36wxb565a",
                "gqlApiRegion": "us-east-2",
                "gqlApiUrl": "https://vttai6zvpvavtlsy325decus4q.appsync-api.us-east-2.amazonaws.com/graphql"
            } : environment === Envs.STAGING ? {
                "auth0Domain": "catch-a-ride-staging.us.auth0.com",
                "auth0ClientId": "66JPPIF2y8VAP2NpVjEA3iGnoYk8uByB",
                "gqlApiKey": "da2-iyger76vdferloxcw36wxb565a",
                "gqlApiRegion": "us-east-2",
                "gqlApiUrl": "https://vttai6zvpvavtlsy325decus4q.appsync-api.us-east-2.amazonaws.com/graphql"
            } : {
                "auth0Domain": "feonix-waupaca.us.auth0.com",
                "auth0ClientId": "61zQUZ7DhqP3QRNtFxNBfbiAwCrQR2xn",
                "gqlApiKey": "da2-iyger76vdferloxcw36wxb565a",
                "gqlApiRegion": "us-east-2",
                "gqlApiUrl": "https://vttai6zvpvavtlsy325decus4q.appsync-api.us-east-2.amazonaws.com/graphql"
            }
        },
        // To hit prod BE from any environment
        "feonix-catcharide-prod": {
            "auth0Domain": "feonix-waupaca.us.auth0.com",
            "auth0ClientId": "61zQUZ7DhqP3QRNtFxNBfbiAwCrQR2xn",
            "gqlApiKey": "da2-f6q6mglcfnekvenznx3tlfd7la",
            "gqlApiRegion": "us-west-2",
            "gqlApiUrl": "https://siz4nmddf5dzhiowagrexha7om.appsync-api.us-west-2.amazonaws.com/graphql"
        }
    }
    return { appId, ...appCredentials[appId] };
}

export const appCredentials = getAppCredentials();

export function additionalAppData(user: AdminUser): AppMetadata {
    const isBeta = environment === Envs.BETA;
    const isStaging = environment === Envs.STAGING;
    const isProd = !isBeta && !isStaging;
    const isSkedGoDev = user.isSkedGoDev;
    const exportConfigBuilder = appID => {
        return ({
            export: {
                booking: {
                    csvFormat:
                        [
                            { label: 'Trip ID', value: 'shortId' },
                            { label: 'Rider name', value: 'userName' },
                            { label: 'Rider email', value: 'userEmail' },
                            { label: 'Rider phone', value: 'userPhoneNumber' },
                            { label: 'Rider ID', value: 'userShortId' },
                            { label: 'transportation provider', value: 'productName' },
                            { label: 'provider phone', value: 'phone' },
                            { label: 'from address', value: 'from.address' },
                            { label: 'from street', value: 'from.street' },
                            { label: 'from city', value: 'from.city' },
                            { label: 'from state', value: 'from.state' },
                            { label: 'from zip', value: 'from.zip' },
                            { label: 'to address', value: 'to.address' },
                            { label: 'to street', value: 'to.street' },
                            { label: 'to city', value: 'to.city' },
                            { label: 'to state', value: 'to.state' },
                            { label: 'to zip', value: 'to.zip' },
                            { value: 'depart', label: 'depart date', trans: 'toISODate' },
                            { value: 'depart', label: 'depart time', trans: 'toISOTime' },
                            { value: 'arrive', label: 'arrive date', trans: 'toISODate' },
                            { value: 'arrive', label: 'arrive time', trans: 'toISOTime' },
                            { value: 'createdAt', label: 'created at', trans: 'toDateTime' },
                            { value: 'updatedAt', label: 'updated at', trans: 'toDateTime' },
                            { value: 'price', trans: 'centsToDollars' },
                            { value: 'distance', trans: 'distance' },
                            'status',
                            { label: 'mobility options', value: 'confirmationInput.mobilityOptions' },
                            { label: 'purpose', value: 'confirmationInput.purpose' },
                            { label: 'confirmation notes', value: 'confirmationInput.notes' },
                            { label: 'return trip', value: 'confirmationInput.returnTrip' },
                            { label: 'payment type', value: 'paymentStatus' },
                            { label: 'charge automatically', value: 'chargeAutomatically' },
                            { label: 'wallet name', value: 'walletName' },
                            { label: 'cba', value: 'organizationId', trans: 'toOrganizationName' },
                            { label: 'initiative', value: 'initiative.title' },
                        ]
                },
                moneyTrans: {
                    csvFormat: [
                        'Id',
                        // { label: 'user name', value: 'userName' },
                        { value: 'timestamp', label: 'time', trans: 'toDateTime' },
                        { label: 'money type', value: 'moneyType' },
                        { label: 'money operation', value: 'moneyOperation' },
                        { value: 'note' },
                        { label: 'amount', value: 'price', trans: 'centsToDollars' },
                        { label: 'wallet balance', value: 'walletBalance', trans: 'centsToDollars' }
                    ]
                },
                formats: ["csv"]
            }
        });
    };
    const features = {
        queryTimeAndPref: true,
        createBooking: true,
        pickupWindow: true,
        resetPassword: true,
        bookingPrice: true,
        payments: user.role !== "tsp_admin",    // Disabled for TSPUsers.
        autoCharge: true,
        emailOrPhoneRequired: true,
        bundles: true,
        rewards: false,
        chargeWarns: true,
        organizations: true,
        reschedule: true,
        returnTrip: false,
        relatedBookings: true,
        management: true,
        addMoney: true,
        moneyTransactions: isSkedGoDev,
        createSimilarBooking21820: true,
        suspendUser21819: true,
        bundleImg20922: true,
        priceChanges21702: true,
        walletAuditing20675: true,
        favorites22590: true,
        newSearch20645: true,
        bookingStatuses20489: true,
        trackTripInitiative22828: isBeta || isStaging,
        providerSettings22829: isBeta || isStaging,
        providerMobilityOptions22827: isBeta || isStaging,
        internalTripAndRiderNotes22953: isBeta,
        cancellationPolicy22860: isBeta || isStaging,
        editUserInfo23131: isBeta || isStaging,
        editAssignedWallet23000: false,
        editProvider23380: isSkedGoDev,
        favNamesOnBookingDetails23475: true,
        ...user.features,
        tickets20717: true
    };
    // Disable some feature flags according to admin role. Do it after overriding them with user.features,
    // so this cannot be accidentally enabled for an incorrect role from the BE.
    // Disable createSimilarBooking21820 for TSP admins.
    if (features.createSimilarBooking21820) {
        features.createSimilarBooking21820 = user.role !== "tsp_admin";
    }
    // Disable suspendUser21819 for non-admins
    if (features.suspendUser21819) {
        features.suspendUser21819 = user.role === "admin";
    }
    // Disable tickets20717 for non-admins
    if (features.tickets20717) {
        features.tickets20717 = user.role === "admin";
    }
    // Disable trackTripInitiative22828 for non-admins
    if (features.trackTripInitiative22828) {
        features.trackTripInitiative22828 = user.role === "admin";
    }
    const appsSpecs = {
        "feonix-catcharide": {
            name: "Catch a Ride",
            environments: [Envs.PROD, Envs.STAGING, Envs.BETA],
            isSuperApp: "true",
            logoUrl: "/images/logo-catcharide.png",
            features,
            theme: {    // Default theme for the super app, overriden by app client specific themes.
                colorPrimary: '#5f439a',
                colorSecondary: '#373936',
                textColorGray: {
                    color: '#212A33'
                }
            },
            locale: 'en-US',
            timezone: 'America/Chicago',
            defaultCity: 'US.WI.Waupaca',
            justEnabledByDefault: ["ps_drt", "wa_wal"],
            ...exportConfigBuilder("feonix-catcharide"),
            geocoding: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            map: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            ...isBeta && {
                "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
                // "tripgoApiUrl": "https://api-beta.tripgo.com/v1",                
                "tripgoApiUrl": "https://galaxies.skedgo.com/lab/beta/satapp",
                "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                "stripeKey": "pk_test_pcFz1IPSJed68tco8okyFcaw",
                modes: {
                },
                clientSpecificModes: {
                    "1409622924572": {  // Waupaca
                        "ps_drt_waupaca-microtransit": {
                            "title": "Feonix MicroTransit"
                        },
                        "ps_drt_waupaca-microtransit-gw": {
                            "title": "Feonix MicroTransit GW",
                            "exclusive": true,
                            "unschedulable": true
                        }
                    },
                    "1409623477025": {  // Michigan
                        "ps_drt_mi-care-on-wheels": {
                            "title": "Care On Wheels Transportation"
                        },
                        "ps_drt_mi-chelsea-wave": {
                            "title": "WAVE"
                        },
                        "ps_drt_mi-detroit-spedsta": {
                            "title": "Volunteers - Detroit",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-test-drt": {
                            "title": "Feonix DRT Test",
                            "exclusive": true
                        },
                        "ps_drt_mi-grand-rapids-spedsta": {
                            "title": "Volunteers - Grand Rapids",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-jackson-spedsta": {
                            "title": "Volunteers - Jackson",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-hillsdale-county-key-opportunities": {
                            "title": "Key Opportunities"
                        },
                        "ps_drt_mi-kent-county-hope-network": {
                            "title": "Hope Network"
                        },
                        "ps_drt_mi-ride-your-way": {
                            "title": "Ride Your Way"
                        },
                        "ps_drt_mi-ride-your-way-rg": {
                            "title": "Ride Your Way RG",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_michigan-uber": {
                            "title": "Uber",
                            "_unschedulable": true,
                            "_unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-lenawee": {
                            "title": "Lenawee Public Transport Authority"
                        },
                        "ps_drt_mi-jackson-transportation": {
                            "title": "Jackson Transportation Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-ride-to-wellness": {
                            "title": "Rides to Wellness",
                            "exclusive": true
                        },
                        "ps_drt_mi-loving-touch": {
                            "title": "Loving Touch"
                        },
                        "ps_drt_mi-ready-ride": {
                            "title": "Ready Ride"
                        },
                        "ps_drt_mi-ride-care": {
                            "title": "Ride Care Transit"
                        },
                        "ps_drt_mi-leesa-transportation": {
                            "title": "Leesa J Transportation"
                        },
                        "ps_drt_washtenaw-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - Washtenaw",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                        "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                        "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                        "ps_drt_washtenaw-people-express": { "title": "People Express" },
                        "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                        "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                        "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                        "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                        "pt_pub": { "title": "Token Transit" },
                        "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                        "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                        "ps_drt_mi-lbu-taxi": { "title": "LBU Taxi" },
                        "ps_drt_mi-midwest-transport-services": { "title": "Midwest Transport Services LLC" },
                        "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" },
                        "ps_drt_michigan-uber-api": { "title": "Michigan Uber API" }
                    },
                    "1409624091304": {  // Dallas (North Texas)
                        "ps_drt_dallas-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - North Texas",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_dallas-alto": { "title": "Alto" },
                        "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                        "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                        "ps_drt_dallas-because-the-cares": { "title": "Because The Cares" },
                        "ps_drt_dallas-caring-at-home": { "title": "Caring @ Home" },
                        "ps_drt_dallas-dart": { "title": "DART" },
                        "ps_drt_dallas-inner-city": { "title": "Inner City" },
                        // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                        "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                        "ps_drt_dallas-t-and-r": { "title": "T&R" },
                        "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                        "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                        "ps_drt_dallas-homebase": { "title": "Home Base Transportation" },
                        "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                    },
                    "1409623939035": {  // N4
                        "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center" },
                        "ps_drt_nrcnaa": { "title": "NRCNAA" },
                        "ps_drt_ElyBus": { "title": "Ely Bus" },
                        "ps_drt_sanford": { "title": "Sanford" },
                        "ps_drt_MyRide": { "title": "My Ride" },
                        "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center" },
                        "ps_drt_Healthcare": { "title": "Healthcare" },
                        "ps_drt_StoreyCounty": { "title": "Storey County" },
                        "ps_drt_n4-uber": { "title": "Uber" },
                        "ps_drt_RSVP": { "title": "RSVP" },
                        "ps_drt_jac-assist": { "title": "JAC Assist" },
                        "ps_drt_pyramid-lake": { "title": "Pyramid Lake" },
                        "ps_drt_CART": { "title": "CART" },
                        "ps_drt_n4-connect-transportation-spedsta": {
                            "title": "N4 Connect Spedsta",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_pleasant-senior-center": {
                            "title": "Pleasant Senior Center",
                            "exclusive": true,
                            "unschedulable": true
                        }
                    },
                    "1409624989148": {  // Tennessee
                        "ps_drt_tennessee-feonix-microtransit": {
                            "title": "Feonix MicroTransit",
                            "exclusive": true,
                        },
                        "ps_drt_tennessee-volunteers": {
                            "title": "Feonix Volunteers",
                            "exclusive": true,
                        }
                    }
                },
                clientsData: {
                    "1409622924572": {  // Waupaca
                        timezone: 'America/Chicago',
                        defaultCity: 'US.WI.Waupaca'
                    },
                    "1409623477025": {  // Michigan
                        timezone: 'US/Eastern',
                        defaultCity: 'US.MI.Detroit'
                    },
                    "1409624091304": {  // Dallas
                        timezone: 'US/Central',
                        defaultCity: 'US.TX.Dallas'
                    },
                    "1409623939035": { // Reno and Las Vegas
                        timezone: 'America/Los_Angeles',
                        defaultCity: 'US.NV.Reno'
                    },
                    "1409624989148": { // Tennessee
                        timezone: 'US/Central',
                        defaultCity: 'US.TN.Memphis'
                    }
                }
            },
            ...isProd && {
                "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
                "tripgoApiUrl": "https://us.api.tripgo.com/v1",
                "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                "stripeKey": "pk_live_zD6mK0JJC9OTIT8jBZkD6crb",
                modes: {
                },
                clientSpecificModes: {
                    "1409622924572": {  // Waupaca
                        "ps_drt_waupaca-microtransit": {
                            "title": "Feonix MicroTransit"
                        },
                        "ps_drt_waupaca-taxi": {
                            "title": "Waupaca Taxi Public Transit"
                        }
                    },
                    "1409623477025": {  // Michigan
                        "ps_drt_mi-care-on-wheels": {
                            "title": "Care On Wheels Transportation"
                        },
                        // "ps_drt_mi-chelsea-wave": {
                        //     "title": "WAVE"
                        // },
                        "ps_drt_mi-detroit-spedsta": {
                            "title": "Volunteers - Detroit",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-test-spedsta": {
                            "title": "Feonix Spedsta Test",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-test-drt": {
                            "title": "Feonix DRT Test",
                            "exclusive": true
                        },
                        "ps_drt_mi-grand-rapids-spedsta": {
                            "title": "Volunteers - Grand Rapids",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-jackson-spedsta": {
                            "title": "Volunteers - Jackson",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-hillsdale-county-key-opportunities": {
                            "title": "Key Opportunities"
                        },
                        "ps_drt_mi-kent-county-hope-network": {
                            "title": "Hope Network"
                        },
                        "ps_drt_mi-ride-your-way": {
                            "title": "Ride Your Way"
                        },
                        "ps_drt_michigan-uber": {
                            "title": "Uber",
                            "_unschedulable": true,
                            "_unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-lenawee": {
                            "title": "Lenawee Public Transport Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-jackson-transportation": {
                            "title": "Jackson Transportation Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-ride-to-wellness": {
                            "title": "Rides to Wellness",
                            "exclusive": true
                        },
                        "ps_drt_mi-loving-touch": {
                            "title": "Loving Touch"
                        },
                        "ps_drt_mi-ready-ride": {
                            "title": "Ready Ride"
                        },
                        "ps_drt_mi-ride-care": {
                            "title": "Ride Care Transit"
                        },
                        "ps_drt_mi-leesa-transportation": {
                            "title": "Leesa J Transportation"
                        },
                        "ps_drt_washtenaw-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - Washtenaw",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                        "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                        "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                        "ps_drt_washtenaw-people-express": { "title": "People's Express" },
                        "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                        "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                        "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                        "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                        "pt_pub": { "title": "Token Transit" },
                        "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                        "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                        "ps_drt_mi-lbu-taxi": { "title": "LBU Taxi" },
                        "ps_drt_mi-midwest-transport-services": { "title": "Midwest Transport Services LLC" },
                        "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" }
                    },
                    "1409624091304": {  // Dallas (North Texas)
                        "ps_drt_dallas-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - North Texas",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        // "ps_drt_dallas-alto": { "title": "Alto"},
                        "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                        "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                        "ps_drt_dallas-because-de-cares": { "title": "Because De Cares" },
                        "ps_drt_dallas-caring-at-home": { "title": "Caring at Home" },
                        // "ps_drt_dallas-dart": { "title": "DART"},
                        "ps_drt_dallas-inner-city": { "title": "Inner City" },
                        // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                        "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                        // "ps_drt_dallas-t-and-r": { "title": "T&R"},
                        "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                        "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                        "ps_drt_dallas-homebase": { "title": "Home Base Transportation" },
                        "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                    },
                    "1409624989148": {  // Tennessee
                        "ps_drt_tennessee-feonix-microtransit": {
                            "title": "Feonix MicroTransit",
                            "exclusive": true,
                        },
                        "ps_drt_tennessee-volunteers": {
                            "title": "Feonix Volunteers",
                            "exclusive": true,
                        }
                    }
                    //,                    
                    // "1409623939035": {  // N4
                    //     "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center"},
                    //     "ps_drt_nrcnaa": { "title": "NRCNAA"},
                    //     "ps_drt_ElyBus": { "title": "Ely Bus"},
                    //     "ps_drt_sanford": { "title": "Sanford"},
                    //     "ps_drt_MyRide": { "title": "My Ride"},
                    //     "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center"},
                    //     "ps_drt_Healthcare": { "title": "Healthcare"},
                    //     "ps_drt_StoreyCounty": { "title": "Storey County"},
                    //     "ps_drt_n4-uber": { "title": "Uber"},
                    //     "ps_drt_RSVP": { "title": "RSVP"},
                    //     "ps_drt_jac-assist": { "title": "JAC Assist"},
                    //     "ps_drt_pyramid-lake": { "title": "Pyramid Lake"},
                    //     "ps_drt_CART": { "title": "CART"},
                    //     "ps_drt_n4-connect-transportation-spedsta" : {
                    //         "title": "N4 Connect Spedsta",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     },
                    //     "ps_drt_pleasant-senior-center" : {
                    //         "title": "Pleasant Senior Center",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     }
                    // }
                },
                clientsData: {
                    "1409622924572": {  // Waupaca
                        timezone: 'America/Chicago',
                        defaultCity: 'US.WI.Waupaca'
                    },
                    "1409623477025": {  // Michigan
                        timezone: 'US/Eastern',
                        defaultCity: 'US.MI.Detroit'
                    },
                    "1409624091304": {  // Dallas
                        timezone: 'US/Central',
                        defaultCity: 'US.TX.Dallas'
                    },
                    "1409623939035": { // Reno and Las Vegas
                        timezone: 'America/Los_Angeles',
                        defaultCity: 'US.NV.Reno'
                    },
                    "1409624989148": { // Tennessee
                        timezone: 'US/Central',
                        defaultCity: 'US.TN.Memphis'
                    }
                }
            },
            ...isStaging && {
                "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
                "tripgoApiUrl": "https://api-feonix.tripgo.com/v1",
                "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                "stripeKey": "pk_test_pcFz1IPSJed68tco8okyFcaw",
                modes: {
                },
                clientSpecificModes: {
                    "1409622924572": {  // Waupaca
                        "ps_drt_waupaca-microtransit": {
                            "title": "Feonix MicroTransit"
                        },
                        "ps_drt_waupaca-taxi": {
                            "title": "Waupaca Taxi Public Transit"
                        },
                        "ps_drt_waupaca-microtransit-gw": {
                            "title": "Feonix MicroTransit GW",
                            "exclusive": true,
                            "unschedulable": true
                        }
                    },
                    "1409623477025": {  // Michigan
                        "ps_drt_mi-care-on-wheels": {
                            "title": "Care On Wheels Transportation"
                        },
                        "ps_drt_mi-chelsea-wave": {
                            "title": "WAVE"
                        },
                        "ps_drt_mi-detroit-spedsta": {
                            "title": "Volunteers - Detroit",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-grand-rapids-spedsta": {
                            "title": "Volunteers - Grand Rapids",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-jackson-spedsta": {
                            "title": "Volunteers - Jackson",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-hillsdale-county-key-opportunities": {
                            "title": "Key Opportunities"
                        },
                        "ps_drt_mi-kent-county-hope-network": {
                            "title": "Hope Network"
                        },
                        "ps_drt_mi-ride-your-way": {
                            "title": "Ride Your Way"
                        },
                        "ps_drt_mi-ride-your-way-rg": {
                            "title": "Ride Your Way RG",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_michigan-uber": {
                            "title": "Uber",
                            "_unschedulable": true,
                            "_unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-lenawee": {
                            "title": "Lenawee Public Transport Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-jackson-transportation": {
                            "title": "Jackson Transportation Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-ride-to-wellness": {
                            "title": "Rides to Wellness",
                            "exclusive": true
                        },
                        "ps_drt_mi-loving-touch": {
                            "title": "Loving Touch"
                        },
                        "ps_drt_mi-ready-ride": {
                            "title": "Ready Ride"
                        },
                        "ps_drt_mi-ride-care": {
                            "title": "Ride Care Transit"
                        },
                        "ps_drt_mi-leesa-transportation": {
                            "title": "Leesa J Transportation"
                        },
                        "ps_drt_washtenaw-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - Washtenaw",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                        "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                        "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                        "ps_drt_washtenaw-people-express": { "title": "People Express" },
                        "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                        "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                        "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                        "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                        "pt_pub": { "title": "Token Transit" },
                        "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                        "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                        "ps_drt_mi-lbu-taxi": { "title": "LBU Taxi" },
                        "ps_drt_mi-midwest-transport-services": { "title": "Midwest Transport Services LLC" },
                        "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" },
                        "ps_drt_michigan-uber-api": { "title": "Michigan Uber API" }
                    },
                    "1409624091304": {  // Dallas (North Texas)
                        "ps_drt_dallas-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - North Texas",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_dallas-alto": { "title": "Alto" },
                        "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                        "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                        "ps_drt_dallas-because-the-cares": { "title": "Because The Cares" },
                        "ps_drt_dallas-caring-at-home": { "title": "Caring @ Home" },
                        "ps_drt_dallas-dart": { "title": "DART" },
                        "ps_drt_dallas-inner-city": { "title": "Inner City" },
                        // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                        "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                        "ps_drt_dallas-t-and-r": { "title": "T&R" },
                        "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                        "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                        "ps_drt_dallas-homebase": { "title": "Home Base Transportation" },
                        "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                    },
                    "1409623939035": {  // N4
                        "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center" },
                        "ps_drt_nrcnaa": { "title": "NRCNAA" },
                        "ps_drt_ElyBus": { "title": "Ely Bus" },
                        "ps_drt_sanford": { "title": "Sanford" },
                        "ps_drt_MyRide": { "title": "My Ride" },
                        "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center" },
                        "ps_drt_Healthcare": { "title": "Healthcare" },
                        "ps_drt_StoreyCounty": { "title": "Storey County" },
                        "ps_drt_n4-uber": { "title": "Uber" },
                        "ps_drt_RSVP": { "title": "RSVP" },
                        "ps_drt_jac-assist": { "title": "JAC Assist" },
                        "ps_drt_pyramid-lake": { "title": "Pyramid Lake" },
                        "ps_drt_CART": { "title": "CART" },
                        "ps_drt_n4-connect-transportation-spedsta": {
                            "title": "N4 Connect Spedsta",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_pleasant-senior-center": {
                            "title": "Pleasant Senior Center",
                            "exclusive": true,
                            "unschedulable": true
                        }
                    },
                    "1409624989148": {  // Tennessee
                        "ps_drt_tennessee-feonix-microtransit": {
                            "title": "Feonix MicroTransit",
                            "exclusive": true,
                        },
                        "ps_drt_tennessee-volunteers": {
                            "title": "Feonix Volunteers",
                            "exclusive": true,
                        }
                    }
                },
                clientsData: {
                    "1409622924572": {  // Waupaca
                        timezone: 'America/Chicago',
                        defaultCity: 'US.WI.Waupaca'
                    },
                    "1409623477025": {  // Michigan
                        timezone: 'US/Eastern',
                        defaultCity: 'US.MI.Detroit'
                    },
                    "1409624091304": {  // Dallas
                        timezone: 'US/Central',
                        defaultCity: 'US.TX.Dallas'
                    },
                    "1409623939035": { // Reno and Las Vegas
                        timezone: 'America/Los_Angeles',
                        defaultCity: 'US.NV.Reno'
                    },
                    "1409624989148": { // Tennessee
                        timezone: 'US/Central',
                        defaultCity: 'US.TN.Memphis'
                    }
                }
            }
        },
        "feonix-catcharide-test": {
            name: "Catch a Ride test",
            environments: [Envs.PROD, Envs.STAGING, Envs.BETA],
            isSuperApp: "true",
            logoUrl: "/images/logo-catcharide.png",
            features: {
                ...features,
                priceChanges21702: false,
                walletAuditing20675: false,
                favorites22590: false
            },
            theme: {
                colorPrimary: '#c01f49',
                colorSecondary: '#373936',
                textColorGray: {
                    color: '#212A33'
                }
            },
            locale: 'en-US',
            timezone: 'America/Chicago',
            defaultCity: 'US.WI.Waupaca',
            justEnabledByDefault: ["ps_drt", "wa_wal"],
            ...exportConfigBuilder("feonix-catcharide"),
            geocoding: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            map: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            ...isProd && {
                // "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
                // "tripgoApiUrl": "https://us.api.tripgo.com/v1",
                // "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                // "stripeKey": "pk_live_zD6mK0JJC9OTIT8jBZkD6crb",
                "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81", // Use staging creds for prod.
                "tripgoApiUrl": "https://api-feonix.tripgo.com/v1",
                "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                "stripeKey": "pk_test_pcFz1IPSJed68tco8okyFcaw",
                modes: {
                },
                clientSpecificModes: {
                    "1409622924572": {  // Waupaca
                        "ps_drt_waupaca-microtransit": {
                            "title": "Feonix MicroTransit"
                        },
                        "ps_drt_waupaca-taxi": {
                            "title": "Waupaca Taxi Public Transit"
                        }
                    },
                    "1409623477025": {  // Michigan
                        "ps_drt_mi-care-on-wheels": {
                            "title": "Care On Wheels Transportation"
                        },
                        // "ps_drt_mi-chelsea-wave": {
                        //     "title": "WAVE"
                        // },
                        "ps_drt_mi-detroit-spedsta": {
                            "title": "Volunteers - Detroit",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-test-spedsta": {
                            "title": "Feonix Spedsta Test",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-test-drt": {
                            "title": "Feonix DRT Test",
                            "exclusive": true
                        },
                        "ps_drt_mi-grand-rapids-spedsta": {
                            "title": "Volunteers - Grand Rapids",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-jackson-spedsta": {
                            "title": "Volunteers - Jackson",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-hillsdale-county-key-opportunities": {
                            "title": "Key Opportunities"
                        },
                        "ps_drt_mi-kent-county-hope-network": {
                            "title": "Hope Network"
                        },
                        "ps_drt_mi-ride-your-way": {
                            "title": "Ride Your Way"
                        },
                        "ps_drt_michigan-uber": {
                            "title": "Uber",
                            "_unschedulable": true,
                            "_unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-lenawee": {
                            "title": "Lenawee Public Transport Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-jackson-transportation": {
                            "title": "Jackson Transportation Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-ride-to-wellness": {
                            "title": "Rides to Wellness",
                            "exclusive": true
                        },
                        "ps_drt_mi-loving-touch": {
                            "title": "Loving Touch"
                        },
                        "ps_drt_mi-ready-ride": {
                            "title": "Ready Ride"
                        },
                        "ps_drt_mi-ride-care": {
                            "title": "Ride Care Transit"
                        },
                        "ps_drt_mi-leesa-transportation": {
                            "title": "Leesa J Transportation"
                        },
                        "ps_drt_washtenaw-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - Washtenaw",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                        "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                        "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                        "ps_drt_washtenaw-people-express": { "title": "People's Express" },
                        "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                        "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                        "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                        "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                        "pt_pub": { "title": "Token Transit" },
                        "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                        "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                        "ps_drt_mi-lbu-taxi": { "title": "LBU Taxi" },
                        "ps_drt_mi-midwest-transport-services": { "title": "Midwest Transport Services LLC" },
                        "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" }
                    },
                    "1409624091304": {  // Dallas (North Texas)
                        "ps_drt_dallas-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - North Texas",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        // "ps_drt_dallas-alto": { "title": "Alto"},
                        "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                        "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                        "ps_drt_dallas-because-de-cares": { "title": "Because De Cares" },
                        "ps_drt_dallas-caring-at-home": { "title": "Caring at Home" },
                        // "ps_drt_dallas-dart": { "title": "DART"},
                        "ps_drt_dallas-inner-city": { "title": "Inner City" },
                        // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                        "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                        // "ps_drt_dallas-t-and-r": { "title": "T&R"},
                        "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                        "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                        "ps_drt_dallas-homebase": { "title": "Home Base Transportation" },
                        "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                    },
                    "1409624989148": {  // Tennessee
                        "ps_drt_tennessee-feonix-microtransit": {
                            "title": "Feonix MicroTransit",
                            "exclusive": true,
                        }
                    }
                    //,                    
                    // "1409623939035": {  // N4
                    //     "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center"},
                    //     "ps_drt_nrcnaa": { "title": "NRCNAA"},
                    //     "ps_drt_ElyBus": { "title": "Ely Bus"},
                    //     "ps_drt_sanford": { "title": "Sanford"},
                    //     "ps_drt_MyRide": { "title": "My Ride"},
                    //     "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center"},
                    //     "ps_drt_Healthcare": { "title": "Healthcare"},
                    //     "ps_drt_StoreyCounty": { "title": "Storey County"},
                    //     "ps_drt_n4-uber": { "title": "Uber"},
                    //     "ps_drt_RSVP": { "title": "RSVP"},
                    //     "ps_drt_jac-assist": { "title": "JAC Assist"},
                    //     "ps_drt_pyramid-lake": { "title": "Pyramid Lake"},
                    //     "ps_drt_CART": { "title": "CART"},
                    //     "ps_drt_n4-connect-transportation-spedsta" : {
                    //         "title": "N4 Connect Spedsta",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     },
                    //     "ps_drt_pleasant-senior-center" : {
                    //         "title": "Pleasant Senior Center",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     }
                    // }
                },
                clientsData: {
                    "1409622924572": {  // Waupaca
                        timezone: 'America/Chicago',
                        defaultCity: 'US.WI.Waupaca'
                    },
                    "1409623477025": {  // Michigan
                        timezone: 'US/Eastern',
                        defaultCity: 'US.MI.Detroit'
                    },
                    "1409624091304": {  // Dallas
                        timezone: 'US/Central',
                        defaultCity: 'US.TX.Dallas'
                    },
                    "1409623939035": { // Reno and Las Vegas
                        timezone: 'America/Los_Angeles',
                        defaultCity: 'US.NV.Reno'
                    },
                    "1409624989148": { // Tennessee
                        timezone: 'US/Central',
                        defaultCity: 'US.TN.Memphis'
                    }
                }
            },
            ...isStaging && {
                "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
                "tripgoApiUrl": "https://api-feonix.tripgo.com/v1",
                "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
                "stripeKey": "pk_test_pcFz1IPSJed68tco8okyFcaw",
                modes: {
                },
                clientSpecificModes: {
                    "1409622924572": {  // Waupaca
                        "ps_drt_waupaca-microtransit": {
                            "title": "Feonix MicroTransit"
                        },
                        "ps_drt_waupaca-taxi": {
                            "title": "Waupaca Taxi Public Transit"
                        }
                    },
                    "1409623477025": {  // Michigan
                        "ps_drt_mi-care-on-wheels": {
                            "title": "Care On Wheels Transportation"
                        },
                        // "ps_drt_mi-chelsea-wave": {
                        //     "title": "WAVE"
                        // },
                        "ps_drt_mi-detroit-spedsta": {
                            "title": "Volunteers - Detroit",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-test-spedsta": {
                            "title": "Feonix Spedsta Test",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-test-drt": {
                            "title": "Feonix DRT Test",
                            "exclusive": true
                        },
                        "ps_drt_mi-grand-rapids-spedsta": {
                            "title": "Volunteers - Grand Rapids",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-jackson-spedsta": {
                            "title": "Volunteers - Jackson",
                            "unschedulable": true,
                            "unschedulableReason": "This trip is being scheduled by an external party"
                        },
                        "ps_drt_mi-hillsdale-county-key-opportunities": {
                            "title": "Key Opportunities"
                        },
                        "ps_drt_mi-kent-county-hope-network": {
                            "title": "Hope Network"
                        },
                        "ps_drt_mi-ride-your-way": {
                            "title": "Ride Your Way"
                        },
                        "ps_drt_michigan-uber": {
                            "title": "Uber",
                            "_unschedulable": true,
                            "_unschedulableReason": "This trip is being scheduled by an external party",
                            "exclusive": true
                        },
                        "ps_drt_mi-lenawee": {
                            "title": "Lenawee Public Transport Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-jackson-transportation": {
                            "title": "Jackson Transportation Authority",
                            "exclusive": true
                        },
                        "ps_drt_mi-ride-to-wellness": {
                            "title": "Rides to Wellness",
                            "exclusive": true
                        },
                        "ps_drt_mi-loving-touch": {
                            "title": "Loving Touch"
                        },
                        "ps_drt_mi-ready-ride": {
                            "title": "Ready Ride"
                        },
                        "ps_drt_washtenaw-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - Washtenaw",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                        "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                        "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                        "ps_drt_washtenaw-people-express": { "title": "People's Express" },
                        "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                        "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                        "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                        "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                        "pt_pub": { "title": "Token Transit" },
                        "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                        "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                        "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" },
                        "ps_drt_mi-ride-care": {
                            "title": "Ride Care Transit"
                        },
                        "ps_drt_mi-leesa-transportation": {
                            "title": "Leesa J Transportation"
                        }
                    },
                    "1409624091304": {  // Dallas (North Texas)
                        "ps_drt_dallas-feonix-volunteer-force": {
                            "title": "Feonix Volunteer Force - North Texas",
                            "exclusive": true,
                            "unschedulable": true
                        },
                        // "ps_drt_dallas-alto": { "title": "Alto"},
                        "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                        "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                        "ps_drt_dallas-because-de-cares": { "title": "Because De Cares" },
                        "ps_drt_dallas-caring-at-home": { "title": "Caring at Home" },
                        // "ps_drt_dallas-dart": { "title": "DART"},
                        "ps_drt_dallas-inner-city": { "title": "Inner City" },
                        // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                        "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                        // "ps_drt_dallas-t-and-r": { "title": "T&R"},
                        "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                        "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                        "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                    },
                    "1409624989148": {  // Tennessee
                        "ps_drt_tennessee-feonix-microtransit": {
                            "title": "Feonix MicroTransit",
                            "exclusive": true,
                        }
                    }
                    //,                    
                    // "1409623939035": {  // N4
                    //     "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center"},
                    //     "ps_drt_nrcnaa": { "title": "NRCNAA"},
                    //     "ps_drt_ElyBus": { "title": "Ely Bus"},
                    //     "ps_drt_sanford": { "title": "Sanford"},
                    //     "ps_drt_MyRide": { "title": "My Ride"},
                    //     "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center"},
                    //     "ps_drt_Healthcare": { "title": "Healthcare"},
                    //     "ps_drt_StoreyCounty": { "title": "Storey County"},
                    //     "ps_drt_n4-uber": { "title": "Uber"},
                    //     "ps_drt_RSVP": { "title": "RSVP"},
                    //     "ps_drt_jac-assist": { "title": "JAC Assist"},
                    //     "ps_drt_pyramid-lake": { "title": "Pyramid Lake"},
                    //     "ps_drt_CART": { "title": "CART"},
                    //     "ps_drt_n4-connect-transportation-spedsta" : {
                    //         "title": "N4 Connect Spedsta",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     },
                    //     "ps_drt_pleasant-senior-center" : {
                    //         "title": "Pleasant Senior Center",
                    //         "exclusive": true,
                    //         "unschedulable": true
                    //     }
                    // }
                },
                clientsData: {
                    "1409622924572": {  // Waupaca
                        timezone: 'America/Chicago',
                        defaultCity: 'US.WI.Waupaca'
                    },
                    "1409623477025": {  // Michigan
                        timezone: 'US/Eastern',
                        defaultCity: 'US.MI.Detroit'
                    },
                    "1409624091304": {  // Dallas
                        timezone: 'US/Central',
                        defaultCity: 'US.TX.Dallas'
                    },
                    "1409623939035": { // Reno and Las Vegas
                        timezone: 'America/Los_Angeles',
                        defaultCity: 'US.NV.Reno'
                    },
                    "1409624989148": { // Tennessee
                        timezone: 'US/Central',
                        defaultCity: 'US.TN.Memphis'
                    }
                }
            }
        },
        "feonix-catcharide-prod": {
            name: "Catch a Ride hitting Prod",
            environments: [Envs.PROD, Envs.STAGING, Envs.BETA],
            isSuperApp: "true",
            logoUrl: "/images/logo-catcharide.png",
            features: {
                ...features,
                priceChanges21702: false,
                walletAuditing20675: false,
                favorites22590: false
            },
            theme: {
                colorPrimary: '#c01f49',
                colorSecondary: '#373936',
                textColorGray: {
                    color: '#212A33'
                }
            },
            locale: 'en-US',
            timezone: 'America/Chicago',
            defaultCity: 'US.WI.Waupaca',
            justEnabledByDefault: ["ps_drt", "wa_wal"],
            ...exportConfigBuilder("feonix-catcharide"),
            geocoding: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            map: {
                provider: 'google',
                apiKey: 'AIzaSyBPGqg-SOLk3vKht3i3gl4ydLkkbsVPF34'
            },
            "tripgoApiKey": "e735bbf789be4c1f6947e62d45fbfa81",
            "tripgoApiUrl": "https://us.api.tripgo.com/v1",
            "tripgoApiAccountAccessToken": "32ba46c7-45d7-41be-86c8-128d4f5fa34d",
            "stripeKey": "pk_live_zD6mK0JJC9OTIT8jBZkD6crb",
            modes: {
            },
            clientSpecificModes: {
                "1409622924572": {  // Waupaca
                    "ps_drt_waupaca-microtransit": {
                        "title": "Feonix MicroTransit"
                    },
                    "ps_drt_waupaca-taxi": {
                        "title": "Waupaca Taxi Public Transit"
                    }
                },
                "1409623477025": {  // Michigan
                    "ps_drt_mi-care-on-wheels": {
                        "title": "Care On Wheels Transportation"
                    },
                    // "ps_drt_mi-chelsea-wave": {
                    //     "title": "WAVE"
                    // },
                    "ps_drt_mi-detroit-spedsta": {
                        "title": "Volunteers - Detroit",
                        "unschedulable": true,
                        "unschedulableReason": "This trip is being scheduled by an external party"
                    },
                    "ps_drt_mi-test-spedsta": {
                        "title": "Feonix Spedsta Test",
                        "unschedulable": true,
                        "unschedulableReason": "This trip is being scheduled by an external party",
                        "exclusive": true
                    },
                    "ps_drt_mi-test-drt": {
                        "title": "Feonix DRT Test",
                        "exclusive": true
                    },
                    "ps_drt_mi-grand-rapids-spedsta": {
                        "title": "Volunteers - Grand Rapids",
                        "unschedulable": true,
                        "unschedulableReason": "This trip is being scheduled by an external party"
                    },
                    "ps_drt_mi-jackson-spedsta": {
                        "title": "Volunteers - Jackson",
                        "unschedulable": true,
                        "unschedulableReason": "This trip is being scheduled by an external party"
                    },
                    "ps_drt_mi-hillsdale-county-key-opportunities": {
                        "title": "Key Opportunities"
                    },
                    "ps_drt_mi-kent-county-hope-network": {
                        "title": "Hope Network"
                    },
                    "ps_drt_mi-ride-your-way": {
                        "title": "Ride Your Way"
                    },
                    "ps_drt_michigan-uber": {
                        "title": "Uber",
                        "_unschedulable": true,
                        "_unschedulableReason": "This trip is being scheduled by an external party",
                        "exclusive": true
                    },
                    "ps_drt_mi-lenawee": {
                        "title": "Lenawee Public Transport Authority",
                        "exclusive": true
                    },
                    "ps_drt_mi-jackson-transportation": {
                        "title": "Jackson Transportation Authority",
                        "exclusive": true
                    },
                    "ps_drt_mi-ride-to-wellness": {
                        "title": "Rides to Wellness",
                        "exclusive": true
                    },
                    "ps_drt_mi-loving-touch": {
                        "title": "Loving Touch"
                    },
                    "ps_drt_mi-ready-ride": {
                        "title": "Ready Ride"
                    },
                    "ps_drt_washtenaw-feonix-volunteer-force": {
                        "title": "Feonix Volunteer Force - Washtenaw",
                        "exclusive": true,
                        "unschedulable": true
                    },
                    "ps_drt_washtenaw-jewish-family-services": { "title": "Jewish Family Services" },
                    "ps_drt_washtenaw-michigan-green-cabs": { "title": "Michigan Green Cabs" },
                    "ps_drt_washtenaw-milan-seniors": { "title": "Milan Seniors" },
                    "ps_drt_washtenaw-people-express": { "title": "People's Express" },
                    "ps_drt_washtenaw-safeway-medical": { "title": "Safeway Medical" },
                    "ps_drt_washtenaw-stadium-taxi": { "title": "Stadium Taxi" },
                    "ps_drt_washtenaw-the-wave": { "title": "The Wave" },
                    "ps_drt_washtenaw-gemini": { "title": "Gemini" },
                    "pt_pub": { "title": "Token Transit" },
                    "ps_drt_mi-jackson-city-transport": { "title": "Jackson City Transport" },
                    "ps_drt_mi-safecare-transports": { "title": "SafeCare Transports" },
                    "ps_drt_mi-prime-transportation": { "title": "Prime Transportation" },
                    "ps_drt_mi-ride-care": {
                        "title": "Ride Care Transit"
                    },
                    "ps_drt_mi-leesa-transportation": {
                        "title": "Leesa J Transportation"
                    }
                },
                "1409624091304": {  // Dallas (North Texas)
                    "ps_drt_dallas-feonix-volunteer-force": {
                        "title": "Feonix Volunteer Force - North Texas",
                        "exclusive": true,
                        "unschedulable": true
                    },
                    // "ps_drt_dallas-alto": { "title": "Alto"},
                    "ps_drt_dallas-baby-boomer": { "title": "Baby Boomer" },
                    "ps_drt_dallas-bec-and-call": { "title": "Bec and Call" },
                    "ps_drt_dallas-because-de-cares": { "title": "Because De Cares" },
                    "ps_drt_dallas-caring-at-home": { "title": "Caring at Home" },
                    // "ps_drt_dallas-dart": { "title": "DART"},
                    "ps_drt_dallas-inner-city": { "title": "Inner City" },
                    // "ps_drt_dallas-safe-and-secure": { "title": "Safe and Secure"},
                    "ps_drt_dallas-southern-link": { "title": "Southern Link" },
                    // "ps_drt_dallas-t-and-r": { "title": "T&R"},
                    "ps_drt_dallas-uber": { "title": "Uber - North Texas" },
                    "ps_drt_dallas-icare": { "title": "iCare Senior Transit Services" },
                    "ps_drt_dallas-dove-transport": { "title": "Dove Transport" }
                },
                "1409624989148": {  // Tennessee
                    "ps_drt_tennessee-feonix-microtransit": {
                        "title": "Feonix MicroTransit",
                        "exclusive": true,
                    }
                }
                //,                    
                // "1409623939035": {  // N4
                //     "ps_drt_LanderSeniorCenter": { "title": "Lander Senior Center"},
                //     "ps_drt_nrcnaa": { "title": "NRCNAA"},
                //     "ps_drt_ElyBus": { "title": "Ely Bus"},
                //     "ps_drt_sanford": { "title": "Sanford"},
                //     "ps_drt_MyRide": { "title": "My Ride"},
                //     "ps_drt_EurekaSeniorCenter": { "title": "Eureka Senior Center"},
                //     "ps_drt_Healthcare": { "title": "Healthcare"},
                //     "ps_drt_StoreyCounty": { "title": "Storey County"},
                //     "ps_drt_n4-uber": { "title": "Uber"},
                //     "ps_drt_RSVP": { "title": "RSVP"},
                //     "ps_drt_jac-assist": { "title": "JAC Assist"},
                //     "ps_drt_pyramid-lake": { "title": "Pyramid Lake"},
                //     "ps_drt_CART": { "title": "CART"},
                //     "ps_drt_n4-connect-transportation-spedsta" : {
                //         "title": "N4 Connect Spedsta",
                //         "exclusive": true,
                //         "unschedulable": true
                //     },
                //     "ps_drt_pleasant-senior-center" : {
                //         "title": "Pleasant Senior Center",
                //         "exclusive": true,
                //         "unschedulable": true
                //     }
                // }
            },
            clientsData: {
                "1409622924572": {  // Waupaca
                    timezone: 'America/Chicago',
                    defaultCity: 'US.WI.Waupaca'
                },
                "1409623477025": {  // Michigan
                    timezone: 'US/Eastern',
                    defaultCity: 'US.MI.Detroit'
                },
                "1409624091304": {  // Dallas
                    timezone: 'US/Central',
                    defaultCity: 'US.TX.Dallas'
                },
                "1409623939035": { // Reno and Las Vegas
                    timezone: 'America/Los_Angeles',
                    defaultCity: 'US.NV.Reno'
                },
                "1409624989148": { // Tennessee
                    timezone: 'US/Central',
                    defaultCity: 'US.TN.Memphis'
                }
            }

        }
    };
    const appMetadata = appsSpecs[appId];
    const userAppsSummaryInfo = [appId].map(appId => ({
        id: appId,
        name: appsSpecs[appId].name,
        logoUrl: appsSpecs[appId].logoUrl
    }));
    const { configOverrides } = process.env.NODE_ENV === 'development' ? require("../data/json/app_metadata_mauro+admin.json") : { configOverrides: undefined };
    const appMetadataOverride = configOverrides && configOverrides[appId];

    return {
        ...appMetadata,
        ...appMetadataOverride,
        // Override apps id/s with apps info/s. Also pass app, in case it was undefined so the default was picked
        app: appId, // TODO: check if necessary
        apps: userAppsSummaryInfo,
        appName: appMetadata ? appMetadata.name : undefined, // Also pass app name in 'appName' field.
        isBeta: isBeta, // TODO: check if necessary
        environment
    };
}
