import React, { useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { editNoteViewJss } from "./EditNoteView.jss";
import Loading from "../view/Loading";
import SideView from "../view/SideView";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import withAsycnData from "../data/WithAsyncData";
import { ValidatorForm } from "react-form-validator-core";
import UIUtil from "../util/UIUtil";
import { i18n } from "../i18n/TKI18nConstants";
import TransactionsData from "../data/TransactionsData";
import { BOOKING_DETAIL_VIEW_PATH } from "./TransactionsViewConstants";
import Transaction, { TransactionNote } from "../model/Transaction";
import Util from "../util/Util";
import DateTimeUtil from "../util/DateTimeUtil";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Radio from "@mui/material/Radio/Radio";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { adminProfile } from "../account/AdminProfile";

type IStyle = ReturnType<typeof editNoteViewJss>;

interface IProps extends WithClasses<IStyle> {
    note?: TransactionNote;
    onSave?: (updateData?: { update: TransactionNote | null }) => void;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

const EditNoteView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { note, onSave, classes, appClasses } = props;
    const [waiting, setWaiting] = useState<boolean>(false);
    const [update, setUpdate] = useState<TransactionNote>(note ?? TransactionNote.create("", { internal: adminProfile.isSuperAdmin }));
    const formRef = useRef<any>(null);
    const isCreate = !note;
    return (
        <SideView
            title={i18n.t("Edit.note")}
            onRequestClose={onSave}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        <div className={appClasses.formGroup}>
                            <label>
                                Note
                            </label>
                            <div className={classNames(appClasses.value)} style={{ width: '100%' }}>
                                <textarea
                                    className={genStyles.grow}
                                    style={{ alignSelf: 'stretch' }}
                                    value={update.text}
                                    onChange={event => {
                                        setUpdate(Util.iAssign(update, { text: event.target.value }));
                                    }}
                                />
                            </div>
                        </div>
                        {adminProfile.isSuperAdmin &&
                            <div className={classNames(appClasses.formGroup, classes.radioGroup)}>
                                <label>Visibility</label>
                                {isCreate ?
                                    <div className={appClasses.value}>
                                        <RadioGroup
                                            value={update.internal ? "internal" : "public"}
                                            onChange={(_event, value) => {
                                                setUpdate(Util.iAssign(update, { internal: value === "internal" }));
                                            }}
                                        >
                                            <FormControlLabel value="internal" control={<Radio />} label={"Internal"} />
                                            <FormControlLabel value="public" control={<Radio />} label={"Public"} />
                                        </RadioGroup>
                                    </div>
                                    :
                                    <div className={appClasses.value}>
                                        {update.internal ? "Internal" : "Public"}
                                    </div>}
                            </div>}
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onSave?.()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    {!isCreate &&
                        <button
                            onClick={() => {
                                onSave?.({ update: null });
                            }}
                            className={appClasses.buttonDelete}
                            style={{ marginLeft: 'auto', marginRight: '70px' }}
                        >
                            Delete
                        </button>}
                    <button onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                if (valid) {
                                    update.timestamp = DateTimeUtil.getNow().utc().toISOString();
                                    onSave?.({ update });
                                    setWaiting(true);
                                }
                            })
                    }} className={appClasses.buttonAdd}>
                        {i18n.t("Save")}
                    </button>
                </div>
            </div>
        </SideView >
    );
};

const EditNoteViewStyled = withStyles(EditNoteView, editNoteViewJss);

const CreateBookingNoteViewData = withAsycnData(EditNoteViewStyled,
    async (query: CreateBookingNoteRouteProps) => {
        const booking = await TransactionsData.instance.get(query.bookingId);
        const onSave = (updateData?: { update: TransactionNote }) => {
            query.onRequestClose?.(booking, updateData);
        }
        return ({ onSave } as { note?: TransactionNote, onRequestClose?: (updateData?: { update: string }) => void; });
    });

const EditBookingNoteViewData = withAsycnData(EditNoteViewStyled,
    async (query: EditBookingNoteRouteProps) => {
        const booking = await TransactionsData.instance.get(query.bookingId);
        const onSave = (updateData?: { update: TransactionNote | null }) => {
            query.onRequestClose?.(booking, updateData);
        }
        return ({ note: booking.notes[query.noteId], onSave } as { note?: TransactionNote, onRequestClose?: (updateData?: { update: string }) => void; });
    });
interface CreateBookingNoteRouteProps {
    bookingId: string;
    onRequestClose?: (booking: Transaction, updateData?: { update: TransactionNote | null }) => void;
}
interface EditBookingNoteRouteProps extends CreateBookingNoteRouteProps {
    noteId: number;
};

// noinspection JSUnusedLocalSymbols
export const ADD_BOOKING_NOTE_VIEW: IViewRouteConfig<CreateBookingNoteRouteProps> =
{
    path: BOOKING_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/addNote")),
    propsFromMatch: (match: any) => {
        return ({ bookingId: match.params.id ?? match.params.tripId });
    },
    propsToPath: props => "/addNote",
    navLabel: () => i18n.t("Add.note"),
    render: ({ viewProps, navHistory }) => {
        const onRequestClose = async (booking: Transaction, updateData?: { update: TransactionNote | null }) => {
            if (updateData && updateData.update) {
                const bookingUpdate = Util.deepClone(booking);
                bookingUpdate.notes = [updateData.update, ...booking.notes];
                try {
                    await TransactionsData.instance.update(bookingUpdate);
                } catch (error) {
                    UIUtil.errorMessage(error as Error);
                } finally {
                    navHistory.pop();
                }
            } else {
                navHistory.pop();
            }
        };
        return (
            <CreateBookingNoteViewData
                bookingId={viewProps.bookingId}
                onRequestClose={onRequestClose}
                renderWhenData={true}
            />
        );
    },
    isModal: true
};

// noinspection JSUnusedLocalSymbols
export const EDIT_BOOKING_NOTE_VIEW: IViewRouteConfig<EditBookingNoteRouteProps> =
{
    path: BOOKING_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/editNote/:noteId")),
    propsFromMatch: (match: any) => {
        return ({ bookingId: match.params.id ?? match.params.tripId, noteId: match.params.noteId });
    },
    propsToPath: props => `/editNote/${props.noteId}`,
    navLabel: () => i18n.t("Edit.note"),
    render: ({ viewProps, navHistory, setWaiting }) => {
        const onRequestClose = async (booking: Transaction, updateData?: { update: TransactionNote | null }) => {
            if (!updateData) {
                navHistory.pop();
                return;
            }
            if (updateData.update === null) {
                UIUtil.confirmMsg({
                    title: 'Confirm to delete',
                    onConfirm: async () => {
                        setWaiting(true);
                        const bookingUpdate = Util.deepClone(booking);
                        bookingUpdate.notes.splice(viewProps.noteId, 1);
                        console.log(bookingUpdate);
                        try {
                            await TransactionsData.instance.update(bookingUpdate);
                        } catch (error) {
                            UIUtil.errorMessage(error as Error);
                        } finally {
                            navHistory.pop();
                            setWaiting(false);
                        }
                    }
                });
            } else {
                const bookingUpdate = Util.deepClone(booking);
                bookingUpdate.notes[viewProps.noteId] = updateData.update;
                try {
                    await TransactionsData.instance.update(bookingUpdate);
                } catch (error) {
                    UIUtil.errorMessage(error as Error);
                } finally {
                    navHistory.pop();
                }
            }
        };
        return (
            <EditBookingNoteViewData
                bookingId={viewProps.bookingId}
                noteId={viewProps.noteId}
                onRequestClose={onRequestClose}
                renderWhenData={true}
                undefineOnUpdate={false}
            />
        );
    },
    isModal: true
};