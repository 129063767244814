import gql from "graphql-tag";
import { MoneyTransaction, PaymentStatus, default as Transaction, TransStatus, TransType } from "../model/Transaction";
import Util from "../util/Util";
import NetworkUtil from "../util/NetworkUtil";
import { adminProfile } from "../account/AdminProfile";
import { USER_BALANCE_SCHEMA } from "./UsersSchema";
import { INITIATIVE_SCHEMA_BUILDER } from "../model/Initiative";

export const getBookingCacheRedirect = (_, args, { getCacheKey }) => {
    return getCacheKey({ __typename: 'UserBookings', Id: args.Id });
};

export const TRANSACTION_ID_FIELD = "Id";
export const TRANSACTION_SHORT_ID_FIELD = "shortId";
export const TRANSACTION_CLIENT_ID_FIELD = "clientId";
export const TRANSACTION_ORGANIZATION_FIELD = "organizationId";
export const TRANSACTION_USER_ID_FIELD = "userID";
export const TRANSACTION_USER_SHORT_ID_FIELD = "userShortId";
export const TRANSACTION_USER_NAME_FIELD = "userName";
export const TRANSACTION_USER_PHONE_FIELD = "userPhoneNumber";
export const TRANSACTION_USER_EMAIL_FIELD = "userEmail";
export const TRANSACTION_USER_EXTERNAL_ID_FIELD = "userExternalId";
export const TRANSACTION_TYPE_FIELD = "type";
export const TRANSACTION_TIMESTAMP_FIELD = "timestamp";
export const TRANSACTION_AMOUNT_FIELD = "amount";
export const TRANSACTION_NOTE_FIELD = "note";
export const TRANSACTION_NOTES_FIELD = "notes";
export const TRANSACTION_POINTS_FIELD = "points";
export const TRANSACTION_EXTERNAL_ID_FIELD = "externalID";
export const TRANSACTION_WALLET_BALANCE_FIELD = "walletBalance";
export const BOOKING_TRANSPORT_ID_FIELD = "transportID";
export const BOOKING_PHONE_FIELD = "phone";
export const BOOKING_CONFIRMATION_INPUT_FIELD = "confirmationInput";
export const BOOKING_CONFIRMATION_INPUT_MOBILITY_OPTIONS_FIELD = "mobilityOptions";
export const BOOKING_CONFIRMATION_INPUT_PURPOSE_FIELD = "purpose";
export const BOOKING_CONFIRMATION_INPUT_NOTES_FIELD = "notes";
export const BOOKING_CONFIRMATION_INPUT_RETURN_TRIP_FIELD = "returnTrip";
export const BOOKING_CONFIRMATION_INPUT_TOTAL_PASSENGERS_FIELD = "totalPassengers";
export const BOOKING_CONFIRMATION_INPUT_WHEELCHAIR_PASSENGERS_FIELD = "wheelchairPassengers";
export const BOOKING_RETURN_TRIP_DEPART_FIELD = "returnTripDepart";
export const BOOKING_QUERY_TIME = "queryTime";
export const BOOKING_QUERY_IS_LEAVE_AFTER = "queryIsLeaveAfter";
export const BOOKING_PICKUP_WINDOW_DURATION = "pickupWindowDuration";

export const TRANSACTION_NOTES_TIMESTAMP_FIELD = "timestamp";
export const TRANSACTION_NOTES_TEXT_FIELD = "text";
export const TRANSACTION_NOTES_INTERNAL_FIELD = "internalNote";
export const TRANSACTION_NOTES_EMAIL_FIELD = "creatorEmail";
export const TRANSACTION_NOTES_PROVIDER_MODE_FIELD = "providerMode";

export const BOOKING_FROM_FIELD = "from";
export const BOOKING_TO_FIELD = "to";
export const BOOKING_DEPART_FIELD = "depart";
export const BOOKING_ARRIVE_FIELD = "arrive";
export const BOOKING_MODE_FIELD = "mode";
export const BOOKING_PRICE_FIELD = "price";
export const BOOKING_BUDGET_POINTS_FIELD = "budgetPoints";
export const BOOKING_PRODUCT_NAME_FIELD = "productName";
export const BOOKING_PAYMENT_ID_FIELD = "paymentID";
export const BOOKING_AUTO_CHARGE_FIELD = "chargeAutomatically";
export const BOOKING_MESSAGE_FIELD = "message";
export const BOOKING_RELATED_BOOKINGS = "relatedBooks";
export const RELATED_BOOKING_ID_FIELD = "bookingId";
export const RELATED_BOOKING_TYPE_FIELD = "type";
export const BOOKING_ACTIONS = "actions";
export const BOOKING_ACTION_NEW_STATUS = "newStatus";
export const BOOKING_ACTION_ACTION = "action";
export const BOOKING_ACTION_CHANGES = "changes";
export const BOOKING_ACTION_ALERT = "alert";
export const BOOKING_ACTION_HINT = "hint";
export const BOOKING_ACTION_DISABLED = "disabled";
export const BOOKING_ACTION_NOTIFICATIONS = "notifications";
export const BOOKING_ACTION_WITH_RELATED = "withRelated";
export const BOOKING_ACTION_ALERT_CHECKED = "alertChecked";
export const BOOKING_ACTION_ALERT_UNCHECKED = "alertUnchecked";
export const BOOKING_ACTION_ONLY_CHECKED = "onlyChecked";
export const BOOKING_UPDATE_RELATED = "affectRelated";
export const BOOKING_CREATED_AT_FIELD = "createdAt";
export const BOOKING_UPDATED_AT_FIELD = "updatedAt";
export const BOOKING_DISTANCE_FIELD = "distance";
export const BOOKING_PRICE_CHANGES_FIELD = "priceChanges";
export const BOOKING_PRICE_CHANGE_ID_FIELD = "Id";
export const BOOKING_PRICE_CHANGE_CREATED_AT_FIELD = "createdAt";
export const BOOKING_PRICE_CHANGE_STATUS_FIELD = "status";
export const BOOKING_PRICE_CHANGE_AMOUNT_FIELD = "amount";
export const BOOKING_PRICE_CHANGE_ORIGINAL_AMOUNT_FIELD = "originalAmount";
export const BOOKING_PRICE_CHANGE_NOTE_FIELD = "note";
export const BOOKING_INITIATIVE_FIELD = "initiative";
export const BOOKING_INITIATIVE_ID_FIELD = "initiativeId";


// Money transaction
export const TRANSACTION_PAYMENT_STATUS_FIELD = "paymentStatus";
export const TRANSACTION_STRIPE_URL_FIELD = "stripeUrl";

export const TRANSACTION_BUNDLE_NAME_FIELD = "walletName";

export const TRANSACTION_WALLET_FIELD = "wallet";
export const TRANSACTION_WALLET_NAME_FIELD = "name";
export const TRANSACTION_WALLET_APPLIED_TIMESTAMP_FIELD = "appliedTimestamp";
export const TRANSACTION_WALLET_EXPIRATION_TIMESTAMP_FIELD = "expirationTimestamp";

export const TRANSACTION_BALANCE_FIELD = "balance";

export const MONEY_TRANSACTION_BOOKING_ID_FIELD = "bookingId";
export const MONEY_TRANSACTION_TYPE_FIELD = "moneyType";
export const MONEY_TRANSACTION_OPERATION_FIELD = "moneyOperation";

export const REDEMPTION_STATUS_FIELD = "status";
export const REDEMPTION_REWARD_ID_FIELD = "rewardID";
export const REDEMPTION_REWARD_TITLE_FIELD = "title";
export const REDEMPTION_REWARD_DESCRIPTION_FIELD = "description";
export const REDEMPTION_UPDATE_MESSAGES_FIELD = "updateMessages";
export const REDEMPTION_UPDATE_MESSAGE_TIMESTAMP_FIELD = "timestamp";
export const REDEMPTION_UPDATE_MESSAGE_USER_FIELD = "user";
export const REDEMPTION_UPDATE_MESSAGE_FIELD = "message";
export const REDEMPTION_FIXED_STATUS_FIELD = "fixedStatus";

export const TRANSACTION_SCHEMA_BUILDER = () => `
    {
        ${adminProfile.isSuperApp ? TRANSACTION_CLIENT_ID_FIELD : ""}
        ${adminProfile.features.organizations ? TRANSACTION_ORGANIZATION_FIELD : ""}
        ${TRANSACTION_ID_FIELD}
        ${TRANSACTION_SHORT_ID_FIELD}
        ${TRANSACTION_TIMESTAMP_FIELD}
        ${TRANSACTION_USER_ID_FIELD}
        ${TRANSACTION_USER_SHORT_ID_FIELD}
        ${TRANSACTION_TYPE_FIELD}
        ${TRANSACTION_NOTE_FIELD}
        ${TRANSACTION_NOTES_FIELD} {
            ${TRANSACTION_NOTES_TIMESTAMP_FIELD}
            ${TRANSACTION_NOTES_TEXT_FIELD}
            ${TRANSACTION_NOTES_INTERNAL_FIELD}
            ${TRANSACTION_NOTES_EMAIL_FIELD}
            ${TRANSACTION_NOTES_PROVIDER_MODE_FIELD}
        }
        ${TRANSACTION_AMOUNT_FIELD}
        ${adminProfile.features.walletAuditing20675 ? TRANSACTION_WALLET_BALANCE_FIELD : ""}
        ${TRANSACTION_USER_NAME_FIELD}
        ${TRANSACTION_USER_PHONE_FIELD}
        ${TRANSACTION_USER_EMAIL_FIELD}
        ${TRANSACTION_USER_EXTERNAL_ID_FIELD}
        ${TRANSACTION_POINTS_FIELD}
        ${BOOKING_MODE_FIELD}       
        ${BOOKING_FROM_FIELD} {
            address
            lat
            lng
            street
            city
            state
            zip
        }
        ${BOOKING_TO_FIELD} {
            address
            lat
            lng
            street
            city
            state
            zip
        }
        ${BOOKING_DEPART_FIELD}
        ${BOOKING_ARRIVE_FIELD}
        ${BOOKING_PRICE_FIELD}
        ${BOOKING_CREATED_AT_FIELD}
        ${BOOKING_UPDATED_AT_FIELD}
        ${BOOKING_DISTANCE_FIELD}
        ${REDEMPTION_STATUS_FIELD}
        ${REDEMPTION_REWARD_ID_FIELD}                    
        ${REDEMPTION_REWARD_TITLE_FIELD}                    
        ${REDEMPTION_REWARD_DESCRIPTION_FIELD}
        ${REDEMPTION_UPDATE_MESSAGES_FIELD} {
            ${REDEMPTION_UPDATE_MESSAGE_TIMESTAMP_FIELD}
            ${REDEMPTION_UPDATE_MESSAGE_FIELD}
        }              
        ${REDEMPTION_FIXED_STATUS_FIELD}      
        ${BOOKING_BUDGET_POINTS_FIELD}
        ${BOOKING_PRODUCT_NAME_FIELD}
        ${BOOKING_TRANSPORT_ID_FIELD}
        ${BOOKING_PHONE_FIELD}
        ${BOOKING_CONFIRMATION_INPUT_FIELD} {
            ${BOOKING_CONFIRMATION_INPUT_PURPOSE_FIELD}
            ${BOOKING_CONFIRMATION_INPUT_MOBILITY_OPTIONS_FIELD}
            ${BOOKING_CONFIRMATION_INPUT_NOTES_FIELD}
            ${BOOKING_CONFIRMATION_INPUT_RETURN_TRIP_FIELD}
            ${adminProfile.features.multiPassenger ? BOOKING_CONFIRMATION_INPUT_TOTAL_PASSENGERS_FIELD : ""}
            ${adminProfile.features.multiPassenger ? BOOKING_CONFIRMATION_INPUT_WHEELCHAIR_PASSENGERS_FIELD : ""}
        }
        ${adminProfile.features.queryTimeAndPref ? BOOKING_QUERY_TIME : ""}
        ${adminProfile.features.queryTimeAndPref ? BOOKING_QUERY_IS_LEAVE_AFTER : ""}
        ${adminProfile.features.pickupWindow ? BOOKING_PICKUP_WINDOW_DURATION : ""}
        ${adminProfile.features.payments ? BOOKING_PAYMENT_ID_FIELD : ""}      
        ${adminProfile.features.payments ? TRANSACTION_PAYMENT_STATUS_FIELD : ""}
        ${adminProfile.features.payments ? TRANSACTION_STRIPE_URL_FIELD : ""}
        ${TRANSACTION_BUNDLE_NAME_FIELD}
        ${adminProfile.features.walletAuditing20675 ?
        `
            ${TRANSACTION_WALLET_FIELD} {
            ${TRANSACTION_WALLET_NAME_FIELD}
            ${TRANSACTION_WALLET_APPLIED_TIMESTAMP_FIELD}
            ${TRANSACTION_WALLET_EXPIRATION_TIMESTAMP_FIELD}
        }
        ` : ""}
        ${adminProfile.features.walletAuditing20675 ?
        `
        ${TRANSACTION_BALANCE_FIELD} ${USER_BALANCE_SCHEMA()}
        ` : ""}
        ${adminProfile.features.payments && adminProfile.features.autoCharge ? BOOKING_AUTO_CHARGE_FIELD : ""}
        ${adminProfile.features.payments && adminProfile.features.chargeWarns ? BOOKING_MESSAGE_FIELD : ""}
        ${adminProfile.features.relatedBookings ?
        `
            ${BOOKING_RELATED_BOOKINGS} {
                ${RELATED_BOOKING_ID_FIELD}
                ${RELATED_BOOKING_TYPE_FIELD}
            }
        `
        : ""}        
        ${BOOKING_ACTIONS} {
            ${BOOKING_ACTION_NEW_STATUS}
            ${BOOKING_ACTION_ACTION}
            ${BOOKING_ACTION_CHANGES}
            ${BOOKING_ACTION_ALERT}
            ${BOOKING_ACTION_NOTIFICATIONS}
            ${BOOKING_ACTION_HINT}
            ${BOOKING_ACTION_DISABLED}
            ${BOOKING_ACTION_WITH_RELATED} {
                ${BOOKING_ACTION_ALERT_CHECKED}
                ${BOOKING_ACTION_ALERT_UNCHECKED}
                ${BOOKING_ACTION_ONLY_CHECKED}
            }
        }
        ${BOOKING_PRICE_CHANGES_FIELD} {
            ${BOOKING_PRICE_CHANGE_ID_FIELD}
            ${BOOKING_PRICE_CHANGE_CREATED_AT_FIELD}
            ${BOOKING_PRICE_CHANGE_STATUS_FIELD}
            ${BOOKING_PRICE_CHANGE_AMOUNT_FIELD}
            ${BOOKING_PRICE_CHANGE_ORIGINAL_AMOUNT_FIELD}
            ${BOOKING_PRICE_CHANGE_NOTE_FIELD}
        }
        ${adminProfile.features.walletAuditing20675 ? MONEY_TRANSACTION_BOOKING_ID_FIELD : ""}
        ${adminProfile.features.walletAuditing20675 ? MONEY_TRANSACTION_TYPE_FIELD : ""}
        ${adminProfile.features.walletAuditing20675 ? MONEY_TRANSACTION_OPERATION_FIELD : ""}
        ${adminProfile.features.trackTripInitiative22828 ?
        `${BOOKING_INITIATIVE_FIELD} ${INITIATIVE_SCHEMA_BUILDER()}` : ""}
    }
`;
export interface IListBookingsQuery {
    dateInit?: number;
    dateEnd?: number;
    clientId?: string;
    userId?: string;
    mode?: string;
    type?: TransType;
    status?: TransStatus;
    paymentStatus?: PaymentStatus;
    bundleId?: string;
    sortAsc?: boolean;
    limit?: number;
    organizationIds?: string[];
    nextToken?: string;
    priceChange?: string;
    search?: string;
    balanceID?: string;
    initiativeId?: string;
}

// Add dashUserType inside getBookings function (don't surface it to function clients) since we should send this value in general, for any query/mutation in the future.
export const LIST_BOOKINGS_QUERY = "listTransactions";
export const getBookings = (query: IListBookingsQuery) => {
    return gql`
    query listTransactions {
        ${LIST_BOOKINGS_QUERY} ( ${NetworkUtil.getGQLParamsFromQuery({ ...query, ...adminProfile.features.bookingStatuses20489 ? { dashUserType: adminProfile.userType } : {} }, ["type", "status", "dashUserType", "priceChange"])}) {
            items ${TRANSACTION_SCHEMA_BUILDER()}           
            nextToken
    }
}
`
};

export const GET_EXPIRING_PAYMENTS_BOOKINGS = "getExpiringPaymentsBookings";
export const getExpiringPaymentsBookings = (query: { clientId?: string, limit?: number; nextToken?: string; }) => {
    const queryParams = NetworkUtil.getGQLParamsFromQuery({ ...query, daysToAdd: adminProfile.local.daysToAdd });
    return gql`
    query getExpiringPaymentsBookings {
        ${GET_EXPIRING_PAYMENTS_BOOKINGS} ${queryParams ? `(${queryParams})` : ""} {    
            items ${TRANSACTION_SCHEMA_BUILDER()}
            nextToken
    }
}
`;
};

export const GET_BOOKING_QUERY = "getBooking";
export const getBooking = (id: string) => gql`
query getBooking {
    ${GET_BOOKING_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ Id: id, ...adminProfile.features.bookingStatuses20489 ? { dashUserType: adminProfile.userType } : {} }, ["dashUserType"])})    
        ${TRANSACTION_SCHEMA_BUILDER()}
}
`;

const bookingCreateFields = [
    BOOKING_MODE_FIELD,
    BOOKING_FROM_FIELD,
    BOOKING_TO_FIELD,
    BOOKING_DEPART_FIELD,
    BOOKING_ARRIVE_FIELD,
    BOOKING_PRICE_FIELD,
    TRANSACTION_POINTS_FIELD,
    TRANSACTION_NOTE_FIELD,
    TRANSACTION_USER_ID_FIELD
];

export const createBooking = (booking: Transaction) => gql`
    mutation createBooking {
        createBooking (
            input: ${Util.stringify(booking, bookingCreateFields)}
    )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

export const bookingUpdateFieldsBuilder = () => [
    // BOOKING_MODE_FIELD,
    BOOKING_FROM_FIELD,
    BOOKING_TO_FIELD,
    BOOKING_DEPART_FIELD,
    BOOKING_ARRIVE_FIELD,
    BOOKING_PRICE_FIELD,
    REDEMPTION_STATUS_FIELD,
    // TRANSACTION_POINTS_FIELD,
    TRANSACTION_NOTES_FIELD,
    TRANSACTION_ID_FIELD,
    BOOKING_TRANSPORT_ID_FIELD,
    BOOKING_PHONE_FIELD,
    BOOKING_RETURN_TRIP_DEPART_FIELD,
    ...adminProfile.features.pickupWindow ? [BOOKING_PICKUP_WINDOW_DURATION] : [],
    ...adminProfile.features.payments && adminProfile.features.autoCharge ? [BOOKING_AUTO_CHARGE_FIELD] : [],
    ...adminProfile.isSuperApp ? [TRANSACTION_CLIENT_ID_FIELD] : [],
    BOOKING_UPDATE_RELATED,
    ...adminProfile.features.trackTripInitiative22828 ? [BOOKING_INITIATIVE_ID_FIELD] : [],
];

export const updateBooking = (transaction: Transaction) => {
    const bookingObj = Util.serialize(transaction);
    bookingObj[BOOKING_INITIATIVE_ID_FIELD] = transaction.initiative?.id ?? null;
    const query = {
        ...Util.filterKeys(bookingObj, bookingUpdateFieldsBuilder()),
        ...adminProfile.features.bookingStatuses20489 ? { dashUserType: adminProfile.userType } : {}
    };
    return gql`
    mutation updateBooking {
        updateBooking (
            input: ${Util.graphqlStringify(query, [REDEMPTION_STATUS_FIELD, "dashUserType"])}
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;
};

export const updateExternalBooking = (id: string, clientId: string) => {
    return gql`
    mutation updateExternalBooking {
        updateExternalBooking (
            ${NetworkUtil.getGQLParamsFromQuery({ Id: id, clientId, dashUserType: adminProfile.userType }, ["dashUserType"])}
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;
}

export const redemptionUpdateFields = [
    TRANSACTION_ID_FIELD,
    REDEMPTION_STATUS_FIELD,
    TRANSACTION_POINTS_FIELD,
    TRANSACTION_NOTE_FIELD,
    // Needs to send the user ID to make the updateRedemption endpoint to busy wait for the balance update.
    TRANSACTION_USER_ID_FIELD,
    REDEMPTION_UPDATE_MESSAGES_FIELD
];

export const updateRedemption = (transaction: Transaction) => gql`
    mutation updateRedemption {
        updateRedemption (
            input: ${Util.removeQuotesValue(Util.stringify(transaction, redemptionUpdateFields), REDEMPTION_STATUS_FIELD)}
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

export const deleteBooking = (id: string, clientId?: string) => gql`
    mutation deleteBooking {
        deleteBooking (
            input: ${Util.stringify({ Id: id, clientId })}            
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

export const deleteMoneyTrans = (id: string) => gql`
    mutation deleteMoneyTransaction {
        deleteMoneyTransaction (
            input: {
                Id:"${id}"
            }
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

const moneyTransCreateFields = [
    TRANSACTION_USER_ID_FIELD,
    TRANSACTION_TIMESTAMP_FIELD,
    TRANSACTION_AMOUNT_FIELD,
    TRANSACTION_NOTE_FIELD
];

export const createTransaction = (transaction: MoneyTransaction) => gql`
    mutation createMoneyTransaction {
        createMoneyTransaction (
            input: ${Util.stringify(transaction, moneyTransCreateFields)}
    )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

export const updateMoneyTrans = (booking: MoneyTransaction) => gql`
    mutation updateMoneyTransaction {
        updateMoneyTransaction (
            input: ${Util.stringify(booking)}
        )${TRANSACTION_SCHEMA_BUILDER()}
}
`;

export const PAY_BOOKING_QUERY = "payTransaction";
export const payBooking = (paymentId: string, clientId?: string) => gql`
query payTransaction {
    ${PAY_BOOKING_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ paymentID: paymentId, clientId })})
        {
            result
        }
}
`;

export interface PriceChangeInput {
    bookingID: string;
    amount: number;
    note?: string;
}

export const changePrice = (clientId: string, priceChange: PriceChangeInput) => gql`
    mutation changePrice {
        changePrice (
            clientId: "${clientId}",
            priceChangeInput: ${Util.stringifyJustValues(priceChange)}
    )${TRANSACTION_SCHEMA_BUILDER()}    
}
`;

export const createPriceChangeRequest = (clientId: string, priceChange: PriceChangeInput) => gql`
    mutation createPriceChangeRequest {
        createPriceChangeRequest (
            clientId: "${clientId}",
            priceChangeInput: ${Util.stringifyJustValues(priceChange)}
    )${TRANSACTION_SCHEMA_BUILDER()}    
}
`;

export interface PriceChangeResponse {
    bookingID: string;
    accept: boolean;
    note: string;
}

export const priceChangeResponse = (clientId: string, priceChangeResponse: PriceChangeResponse) => gql`
    mutation priceChangeResponse {
        priceChangeResponse (
            clientId: "${clientId}",
            priceChangeResponse: ${Util.stringifyJustValues(priceChangeResponse)}
    )${TRANSACTION_SCHEMA_BUILDER()}
}
`;