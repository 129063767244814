import React, { FunctionComponent } from "react";
import Bundle, { TMode } from "../model/Bundle";
import classNames from "classnames";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import genStyles from "../css/general.module.css";
import TransportUtil from "../util/TransportUtil";
import FormatUtil from "../util/FormatUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bundleTableJss } from "./BundleTable.jss";
import { DISCOUNT_TOOLTIP, OFFER_DESCRIPTION_TOOLTIP, REWARD_POINTS_PER_COST_TOOLTIP } from "./EditBundleView";
import Tooltip from "../uiutil/Tooltip";
import { useProviders } from "../data/ProvidersData";
import Provider from "../model/Provider";

type IStyle = ReturnType<typeof bundleTableJss>;

interface IProps extends WithClasses<IStyle> {
    bundle?: Bundle;
    className?: string;
}

const BundleTable: FunctionComponent<IProps> = (props: IProps) => {
    const { bundle, className, classes, appClasses } = props;
    const tmodes = bundle && bundle.tmodes!;
    const providers = useProviders({ clientId: bundle?.clientId });
    return (
        <Table className={classNames(appClasses.table, classes.table, className)}>
            <colgroup>
                <col />
                <col width="20%" />
                <col width="20%" />
                <col width="30%" />
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell className={appClasses.field}>Transport Mode</TableCell>
                    <TableCell className={appClasses.fieldSecondary}>
                        <Tooltip title={DISCOUNT_TOOLTIP}>
                            Discount
                        </Tooltip>
                    </TableCell>
                    <TableCell className={appClasses.fieldSecondary}>
                        <Tooltip title={REWARD_POINTS_PER_COST_TOOLTIP}>
                            <div className={appClasses.cursorHelp}>Reward points <br /> per dollar spent</div>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={appClasses.fieldSecondary}>
                        <Tooltip title={OFFER_DESCRIPTION_TOOLTIP}>
                            <div className={appClasses.cursorHelp}>Offer description</div>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tmodes && providers && tmodes.map((mode: TMode, i: number) => {
                    const modeId = providers?.find(provider => provider.id === mode.mode) ?? new Provider();
                    return (
                        <TableRow key={mode.mode}>
                            <TableCell className={appClasses.cell}>
                                <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                                    <img src={TransportUtil.getTransportIconFromProvider(modeId)}
                                        className={appClasses.modeIcon}
                                        alt=""
                                    />
                                    <span className={genStyles.charSpaceLeft}>{modeId!.modeName}</span>
                                </div>
                            </TableCell>
                            <TableCell className={appClasses.cell}>
                                {mode.discountPoints && mode.discountPoints !== 0 ?
                                    "$" + FormatUtil.formatDiscountPoints(mode.discountPoints) :
                                    FormatUtil.formatDiscountPercent(mode.pointsPerCost ?? 0, { decimals: 2 }) + "%"}
                            </TableCell>
                            <TableCell className={appClasses.cell}>
                                {FormatUtil.truncateToDecimals((mode.rewardPerCost !== undefined ? mode.rewardPerCost : 0) * 100, 1)}
                            </TableCell>
                            <TableCell className={appClasses.cell}>
                                {mode.offerDescription}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

export default withStyles(BundleTable, bundleTableJss);