import React, { useEffect, useRef } from "react";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { Theme } from "../css/Theme";
import classnames from "classnames";
import { genJss, resetJss } from "../css/gen.jss";
import { ReactComponent as NoteIcon } from "../images/ic-sticky-note.svg";

const noteJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        overflow: 'hidden',
    },
    iconAndText: {
        ...genJss.flex,
        ...genJss.alignStart,
        overflow: 'hidden',
        lineHeight: '24px',
        '& svg': {
            ...genJss.noShrink,
            marginRight: '5px'
        }
    },
    expandable: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    expanded: {
        ...genJss.column,
        '& $expandable': {
            overflow: 'visible',
            textOverflow: 'unset',
            whiteSpace: 'normal'
        }
    },
    expandBtn: {
        ...resetJss.button,
        ...genJss.alignSelfEnd,
        ...genJss.noShrink,
        marginLeft: '5px',
        cursor: 'pointer',
        color: theme.colorPrimary,
        lineHeight: '24px',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
})

type IStyle = ReturnType<typeof noteJss>;

interface IProps extends WithClasses<IStyle> {
    text: string;
    expanded?: boolean;
    onExpanded?: (expanded: boolean) => void;
}

const FoldableNote: React.FunctionComponent<IProps> = ({ expanded: expandedProp, onExpanded: setExpandedProp, classes, text }) => {
    const [expandedState, setExpandedState] = React.useState(false);
    const expanded = expandedProp !== undefined ? expandedProp : expandedState;
    const setExpanded = setExpandedProp !== undefined ? setExpandedProp : setExpandedState;
    const expandableRef = useRef<HTMLDivElement>(null);
    const [isOverflowing, setIsOverflowing] = React.useState(false);
    useEffect(() => {
        // Check if the text overflows on initial render
        if (expandableRef.current && expandableRef.current.scrollWidth > expandableRef.current.clientWidth) {
            setIsOverflowing(true);
        }
    }, []);
    return (
        <div className={classnames(classes.main, expanded && classes.expanded)}>
            <div className={classes.iconAndText}>
                <NoteIcon />
                <div className={classes.expandable} ref={expandableRef}>
                    {text}
                </div>
            </div>
            {isOverflowing &&
                <button className={classes.expandBtn} onClick={() => setExpanded(!expanded)}>
                    {expanded ? 'Show less' : 'Show more'}
                </button>}
        </div>
    );
}

export default withStylesO(FoldableNote, noteJss);