import { JsonObject, JsonProperty } from "json2typescript";
import {
    USER_CARD_NUMBER_FIELD,
    USER_CURRENT_BUNDLE_FIELD,
    USER_EMAIL_FIELD,
    USER_FUTURE_BUNDLE_FIELD,
    USER_ID_FIELD,
    USER_NAME_FIELD,
    USER_BALANCE_FIELD,
    USER_BALANCE_RAW_FIELD,
    USER_BALANCE_REWARDS_FIELD,
    USER_BALANCE_USER_FIELD,
    USER_BALANCE_AVAILABLE_REWARDS_FIELD,
    USER_BALANCE_LIFETIME_REWARDS_FIELD,
    USER_GIVEN_NAME_FIELD,
    USER_SURNAME_FIELD,
    USER_APP_DATA_FIELD,
    USER_PASSWORD_FIELD,
    USER_PHONE_FIELD,
    USER_PICTURE_SMALL,
    USER_PICTURE_LARGE,
    USER_CLIENT_ID_FIELD,
    USER_DISABLED_FIELD,
    USER_SHORT_ID_FIELD,
    USER_BALANCE_CURRENT_BALANCE_FIELD,
    USER_BALANCE_INITIAL_BALANCE_FIELD,
    USER_BALANCE_FINAL_BALANCE_FIELD,
    USER_INITIATIVES_FIELD,
    USER_PUBLIC_NOTE_FIELD,
    USER_INTERNAL_NOTE_FIELD
} from "../data/UsersSchema";
import { default as Bundle } from "./Bundle";
import AppData from "./AppData";
import Initiative from "./Initiative";

@JsonObject
export class Balance {
    @JsonProperty(USER_BALANCE_RAW_FIELD, Number, true)
    public rawBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_REWARDS_FIELD, Number, true)
    public rewardsBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_USER_FIELD, Number, true)
    public userBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_AVAILABLE_REWARDS_FIELD, Number, true)
    public availableRewardsBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_LIFETIME_REWARDS_FIELD, Number, true)
    public lifetimeRewardsBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_CURRENT_BALANCE_FIELD, Number, true)
    public currentBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_INITIAL_BALANCE_FIELD, Number, true)
    public initialBalance: number | undefined = undefined;
    @JsonProperty(USER_BALANCE_FINAL_BALANCE_FIELD, Number, true)
    public finalBalance: number | undefined = undefined;
}

@JsonObject
class User {
    @JsonProperty(USER_ID_FIELD, String)
    public readonly id: string = "";
    @JsonProperty(USER_SHORT_ID_FIELD, String, true)
    public readonly shortId: string = "";
    @JsonProperty(USER_NAME_FIELD, String, true)
    public name: string | undefined = undefined;
    @JsonProperty(USER_GIVEN_NAME_FIELD, String, true)
    public givenName: string | null = null;
    @JsonProperty(USER_SURNAME_FIELD, String, true)
    public surname: string | null = null;
    @JsonProperty(USER_EMAIL_FIELD, String, true)
    public email: string | undefined = undefined;
    @JsonProperty(USER_CARD_NUMBER_FIELD, String, true)
    public cardNumber: string | undefined | null = undefined;
    @JsonProperty(USER_CURRENT_BUNDLE_FIELD, Bundle, true)
    public currentBundle: Bundle | undefined = undefined;
    @JsonProperty(USER_FUTURE_BUNDLE_FIELD, Bundle, true)
    public futureBundle: Bundle | undefined | null = undefined;
    @JsonProperty(USER_BALANCE_FIELD, Balance, true)
    public balance?: Balance = undefined;
    @JsonProperty(USER_APP_DATA_FIELD, AppData, true)
    public appData: AppData | undefined = undefined;
    @JsonProperty(USER_PASSWORD_FIELD, String, true)
    public password: string | undefined = undefined;
    @JsonProperty(USER_PHONE_FIELD, String, true)
    public phoneNumber: string | null = null;
    @JsonProperty(USER_PICTURE_SMALL, String, true)
    public pictureSmall: string | undefined = undefined;
    @JsonProperty(USER_PICTURE_LARGE, String, true)
    public pictureLarge: string | undefined = undefined;
    @JsonProperty(USER_CLIENT_ID_FIELD, String, true)
    public clientId?: string = undefined;
    @JsonProperty(USER_DISABLED_FIELD, Boolean, true)
    public disabled: boolean = false;
    @JsonProperty(USER_INITIATIVES_FIELD, [Initiative], true)
    public initiatives: Initiative[] = [];
    @JsonProperty(USER_PUBLIC_NOTE_FIELD, String, true)
    public readonly publicNote: string = "";
    @JsonProperty(USER_INTERNAL_NOTE_FIELD, String, true)
    public readonly internalNote: string = "";

    public initiativesUpdate: string[] = [];

    get clientIds(): string[] {
        return this.clientId ? [this.clientId] : [];
    }
}

export default User;