import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import Util from "../util/Util";
import { genJss } from "../css/gen.jss";
import { ValidatorForm } from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import DateTimeUtil from "../util/DateTimeUtil";
import Transaction, { RelatedBookingType, TransStatus } from "../model/Transaction";
import { scheduleBookingFormJss } from "./ScheduleBookingForm.jss";
import { locIconStyleOverride } from "./BookingViewHelpers";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { ReactComponent as IconLocked } from '../images/ic-locked.svg';
import Tooltip from "../uiutil/Tooltip";
import DateTimePicker from "../time/DateTimePicker";
import { TKUIMapView, TKRoot, TKUILocationBox, TKLocation, LatLng, overrideClass, black, RegionsData, Region, TripGoApi, genClassNames } from "tripkit-react";
import { adminProfile } from "../account/AdminProfile";
import { geocodingConfig, mapConfig } from "../app/App";
import { Theme } from "../css/Theme";
import { ReactComponent as IconSwap } from '../images/ic-swap.svg';
import { CheckboxStyled } from "./BookingsView";
import Note from "./Note";
import { Subtract } from "utility-types";
import { useBeforeInitialRender } from "../util/usehooks";
import { TKUIMapViewClass } from "tripkit-react/dist/map/TKUIMapView";

type IStyle = ReturnType<typeof scheduleBookingFormJss>;

export interface ScheduleBookingFormData {
    bookingId: string,
    type: RelatedBookingType,
    booking: Transaction;
    skip?: boolean;
    skipReason?: string;
    syncFromTo?: boolean;
}

type ScheduleBookingFormDataUpdate = Partial<Subtract<ScheduleBookingFormData, { booking: Transaction }> & { booking: Partial<Transaction> }>;

interface IProps extends WithClasses<IStyle> {
    // TODO: switch back to passing a value / onChange, instead of this form.
    form: ScheduleBookingFormData;
    relatedBookings: ScheduleBookingFormData[];
    onFormChange: (update: ScheduleBookingFormData) => void;
    onRequestClose?: (schedule?: boolean) => void;
    theme: Theme;
    cancelLabel?: string;
    proceedLabel?: string;
}

const ScheduleBookingForm: FunctionComponent<IProps> = (props: IProps) => {
    const { form, relatedBookings, onFormChange, onRequestClose, classes, appClasses } = props;
    const formRef = useRef<any>();
    const fogShield = form.skip;

    function getDataUpdate(form: ScheduleBookingFormData, formUpdate: ScheduleBookingFormDataUpdate): ScheduleBookingFormData {
        const { booking, ...restFormUpdate } = formUpdate;
        const formUpdated = { ...form, ...restFormUpdate, ...booking ? { booking: Util.iAssign(form.booking, booking) } : {} };
        return formUpdated;
    }

    function updateData(formUpdate: ScheduleBookingFormDataUpdate) {
        const { booking, ...restFormUpdate } = formUpdate;
        const formUpdated = { ...form, ...restFormUpdate, ...booking ? { booking: Util.iAssign(form.booking, booking) } : {} };
        onFormChange(formUpdated);
    };


    function updateBookingData(key, value) {
        updateData({ booking: { [key]: value } });
    };

    function updateBooking() {
        if (form.skip) {
            onRequestClose?.(true)
            return;
        }
        formRef.current.isFormValid(false)
            .then((valid: boolean) => {
                if (valid && onRequestClose) {
                    updateBookingData("status", TransStatus.PROVIDER_ACCEPTED);
                    onRequestClose(true);
                }
            });
    };

    useBeforeInitialRender(() => {
        // Set the client id to that one of the booking.
        TripGoApi.clientID = form.booking?.clientId;
    });

    const [defaultRegion, setDefaultRegion] = useState<Region | undefined>()
    const defaultCityLatLng = defaultRegion && defaultRegion.cities.length > 0 ? defaultRegion.cities[0] : undefined

    const [preFrom, setPreFrom] = useState<TKLocation | undefined>()
    const [preTo, setPreTo] = useState<TKLocation | undefined>()

    // Do this to always display the address, and not the name of the location (e.g. in case of favorites).
    const fromAddress = useMemo(() => form.booking.from && Util.iAssign(form.booking.from, { name: undefined }), [form.booking.from]);
    const toAddress = useMemo(() => form.booking.to && Util.iAssign(form.booking.to, { name: undefined }), [form.booking.to]);

    const mapRef = useRef<TKUIMapViewClass>(null);

    useEffect(() => {
        let formUpdate = form;
        if (form.booking.confirmationInput?.returnTrip) {
            formUpdate = getDataUpdate(formUpdate, { booking: { returnTripDepart: form.booking.confirmationInput?.returnTrip } });
        }
        if (formUpdate !== form) {
            // Need to chain the data updates, or else just the last one will be applied.
            onFormChange(formUpdate);
        }
        RegionsData.instance.getCloserRegionP(form.booking.from).then((region: Region) => {
            setDefaultRegion(region);
        });
        // value in HTML5 format (like ISO date-time but without timezone)
        ValidatorForm.addValidationRule('isInTheFuture', ({ value }) => {
            return value > DateTimeUtil.getNow().valueOf() / 1000;
        });
        ValidatorForm.addValidationRule('isArriveAfterDepart', ({ arrive, depart }) => {
            if (!arrive) {
                return true;
            }
            return arrive > depart;
        });
        ValidatorForm.addValidationRule('isAfterOutboundArrive', ({ value, outboundArrive }) => {
            if (!outboundArrive) {
                return true;
            }
            return value >= outboundArrive;
        });
        ValidatorForm.addValidationRule('isReturnAfterTripArrive', ({ form }) => {
            if (!form.booking.returnTripDepart) {
                return true;
            }
            return !DateTimeUtil.momentDefaultTZ(form.booking.returnTripDepart).isBefore(form.booking.arriveMoment);
        });
        // Workaround since after upgrading react-scripts to v5 the map doesn't fit the bounds properly the first time (like it's not ready yet)
        setTimeout(() => {
            mapRef.current?.fitMapFromTo();
        }, 500);
    }, []);
    const config = {
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        i18n: {
            locale: adminProfile.locale,
            translations: {} as any
        },
        TKUIMapView: {
            props: () => mapConfig()
        },
        TKUIMapLocationIcon: {
            styles: locIconStyleOverride
        },
        geocoding: geocodingConfig,
        theme: {
            colorPrimary: props.theme.colorPrimary,
            fontFamily: props.theme.fontFamily
        },
        isDarkMode: false
    };
    const previousNotes = form.booking.notes.filter(note => !note.editing);
    const fromToForm =
        <div className={classNames(genStyles.flex, genStyles.alignCenter, appClasses.formGroup)}>
            <div className={genStyles.grow}>
                <div className={appClasses.formGroup}>
                    <label htmlFor="depart">From</label>
                    <div className={classNames(appClasses.value, genStyles.grow)}>
                        <div className={appClasses.input}>
                            <TKUILocationBox
                                value={fromAddress}
                                onChange={(value: TKLocation | null) => {
                                    setPreFrom(undefined);
                                    updateData({ booking: { from: value ?? undefined } });
                                }}
                                onResultHighlight={(value: TKLocation | null) => {
                                    setPreFrom(value ?? undefined);
                                }}
                                showCurrLoc={false}
                                bounds={defaultRegion?.bounds}
                                focus={defaultCityLatLng}
                                placeholder={"From"}
                                styles={{
                                    input: overrideClass({
                                        color: black()
                                    }),
                                    menu: overrideClass({
                                        top: '41px',
                                        left: '-15px',
                                        width: 'calc(100% + 30px)'
                                    })
                                }}
                                disabled={form.syncFromTo}
                            />
                        </div>
                    </div>
                </div>
                <div className={appClasses.formGroup}>
                    <label htmlFor="depart">To</label>
                    <div className={classNames(appClasses.value, genStyles.grow)}>
                        <div className={appClasses.input}>
                            <TKUILocationBox
                                value={toAddress}
                                onChange={(value: TKLocation | null) => {
                                    setPreTo(undefined);
                                    updateData({ booking: { to: value ?? undefined } });
                                }}
                                onResultHighlight={(value: TKLocation | null) => {
                                    setPreTo(value ?? undefined);
                                }}
                                showCurrLoc={false}
                                bounds={defaultRegion?.bounds}
                                focus={defaultCityLatLng}
                                placeholder={"To"}
                                styles={{
                                    input: overrideClass({
                                        color: black()
                                    }),
                                    menu: overrideClass({
                                        top: '41px',
                                        left: '-15px',
                                        width: 'calc(100% + 30px)'
                                    })
                                }}
                                disabled={form.syncFromTo}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button className={classes.lockButton}
                    onClick={() => {
                        updateData({ booking: { from: form.booking.to, to: form.booking.from } });
                    }}
                    disabled={form.syncFromTo}
                >
                    <IconSwap />
                </button>
            </div>
        </div>;
    const outboundArrive = form.type === "RETURN" ? relatedBookings.find(rbu => rbu.type === "OUTBOUND")?.booking?.arrive : undefined;
    return (
        <TKRoot config={config}>
            <div className={classes.main}>
                <div className={classes.body}>
                    <div className={classes.formContainer}>
                        <ValidatorForm
                            onSubmit={() => { }}
                            instantValidate={false}
                            ref={formRef}
                            className={genJss.grow}
                        >
                            <div
                                className={classNames(genStyles.flex, genStyles.column, genStyles.spaceBetween, genStyles.height100)}>
                                <div className={appClasses.form}>
                                    {form.type === "RETURN" && relatedBookings.some(rb => rb.type === "OUTBOUND") ?
                                        <div className={classNames(classes.syncFromTo, !form.syncFromTo && classes.free)}>
                                            {fromToForm}
                                            <div className={appClasses.formGroup}>
                                                <label>Sync with outbound</label>
                                                <CheckboxStyled
                                                    checked={!!form.syncFromTo}
                                                    onChange={e => {
                                                        updateData({ syncFromTo: e.target.checked });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        :
                                        fromToForm}
                                    <div className={classNames(genStyles.flex, genStyles.alignCenter, appClasses.formGroup)}>
                                        <div>
                                            <div className={appClasses.formGroup}>
                                                <label htmlFor="depart">Pick up time</label>
                                                <Validator
                                                    value={{ value: form.booking.depart, outboundArrive: outboundArrive }}
                                                    validators={["isInTheFuture", "isAfterOutboundArrive"]}
                                                    errorMessages={["should be a date in the future", "should be after outbound trip arrive"]}
                                                    resetOnValid
                                                >
                                                    {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                                        const departMoment = DateTimeUtil.html5FromMillis(form.booking.depart * 1000, form.booking.timezone);
                                                        return (
                                                            <div className={appClasses.value}>
                                                                <DateTimePicker
                                                                    value={departMoment}
                                                                    onChange={(value) => {
                                                                        const newDepart = DateTimeUtil.html5ToMillis(value, form.booking.timezone) / 1000;
                                                                        updateBookingData("depart", newDepart);
                                                                        // if (form.preserveDuration) {
                                                                        //     updateData({ update: { arrive: newDepart + form.tripDuration! } });
                                                                        // } else {
                                                                        //     updateData({ tripDuration: form.update.arrive! - form.update.depart });
                                                                        // }
                                                                    }} />
                                                                {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                                            </div>
                                                        );
                                                    }}
                                                </Validator>
                                                {DateTimeUtil.isOnDifferentTimezone(form.booking.timezone) &&
                                                    <div className={classes.timezone}>
                                                        {DateTimeUtil.timezoneToGMTString(form.booking.timezone)}
                                                    </div>}
                                            </div>
                                            <div className={appClasses.formGroup}>
                                                <label htmlFor="depart">Drop off time</label>
                                                <Validator
                                                    value={{ arrive: form.booking.arrive, depart: form.booking.depart }}
                                                    validators={["isArriveAfterDepart"]}
                                                    errorMessages={["drop off should be after pick up"]}
                                                    resetOnValid
                                                >
                                                    {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                                        const arriveMoment = DateTimeUtil.html5FromMillis(form.booking.arrive! * 1000, form.booking.timezone);
                                                        return (
                                                            <div className={appClasses.value}>
                                                                <DateTimePicker
                                                                    value={arriveMoment}
                                                                    onChange={(value) => {
                                                                        const newArrive = DateTimeUtil.html5ToMillis(value, form.booking.timezone) / 1000;
                                                                        updateBookingData("arrive", newArrive);
                                                                        // if (form.preserveDuration) {
                                                                        //     updateData({ update: { depart: newArrive - form.tripDuration! } });
                                                                        // } else {
                                                                        //     updateData({ tripDuration: form.update.arrive! - form.update.depart });
                                                                        // }
                                                                    }} />
                                                                {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                                            </div>
                                                        );
                                                    }}
                                                </Validator>
                                                {DateTimeUtil.isOnDifferentTimezone(form.booking.timezone) &&
                                                    <div className={classes.timezone}>
                                                        {DateTimeUtil.timezoneToGMTString(form.booking.timezone)}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/* {false && // TODO: Add app based setting to show / hide.
                                        <div className={classes.lockPanel}>
                                            <div className={classes.lockLines} />
                                            <Tooltip title={"Lock / unlock trip duration"}>
                                                <button className={classes.lockButton}
                                                    onClick={() =>
                                                        // this.setState((state: IState) => ({ preserveDuration: !state.preserveDuration }))
                                                        updateData({ preserveDuration: !form.preserveDuration })
                                                    }
                                                >
                                                    {form.preserveDuration ? <IconLocked /> :
                                                        <IconUnlocked />}
                                                </button>
                                            </Tooltip>
                                        </div>} */}
                                    </div>
                                    <div className={appClasses.formGroup}>
                                        <Tooltip className={appClasses.cursorHelp}
                                            title={"Optional - Fill in your dispatching system's ID for this trip."}>
                                            <label htmlFor="transportId">Transport ID</label>
                                        </Tooltip>
                                        <Validator
                                            value={form.booking.transportId || ""}
                                            // validators={["required"]}    // Parameterize if other client wants it to be required.
                                            // errorMessages={["this field is required"]}
                                            validators={[]}
                                            errorMessages={[]}
                                            resetOnValid
                                        >
                                            {(params: { errorMessage: any }) =>
                                                <div className={appClasses.value}>
                                                    <input name="transportId"
                                                        onChange={(e) => updateBookingData(e.currentTarget.name,
                                                            e.currentTarget.value === "" ? undefined : e.currentTarget.value)}
                                                        value={form.booking.transportId || ""} // #avoid_uncontrolled_to_controlled
                                                        // To avoid the warning about changing input from
                                                        // uncontrolled to controlled due to value being initially
                                                        // undefined.
                                                        type="text"
                                                        placeholder="Transport ID"
                                                    />
                                                    {params.errorMessage && <div
                                                        className={appClasses.validationError}>{params.errorMessage}</div>}
                                                </div>}
                                        </Validator>
                                    </div>
                                    <div className={appClasses.formGroup}>
                                        <Tooltip className={appClasses.cursorHelp}
                                            title={"Contact phone number of the service provider to be displayed to the rider."}>
                                            <label htmlFor="phone">Service phone number</label>
                                        </Tooltip>
                                        <div className={appClasses.value}>
                                            <input name="phone"
                                                type="tel"
                                                onChange={(e) => updateBookingData(e.currentTarget.name,
                                                    e.currentTarget.value === "" ? undefined : e.currentTarget.value)}
                                                value={form.booking.phone || ""}   // #avoid_uncontrolled_to_controlled
                                                placeholder="Service phone number"
                                            />
                                        </div>
                                    </div>
                                    {false && adminProfile.features.payments && adminProfile.features.autoCharge &&
                                        form.booking.paymentStatus === "requires_capture" &&
                                        <div className={appClasses.formGroup}>
                                            <label>Charge automatically</label>
                                            <CheckboxStyled
                                                checked={!!form.booking.chargeAutomatically}
                                                onChange={e => {
                                                    updateBookingData("chargeAutomatically", e.target.checked);
                                                }}
                                            />
                                        </div>}
                                    <div className={appClasses.formGroup} style={{ marginTop: '50px' }}>
                                        <Tooltip className={appClasses.cursorHelp} title={"Note to be delivered to the rider."}>
                                            <label htmlFor="externalNote" className={genClassNames.alignSelfStart}>Note to rider</label>
                                        </Tooltip>
                                        <textarea name="externalNote"
                                            value={form.booking.editingExternalNote}
                                            onChange={(e) => {
                                                updateData({ booking: { editingExternalNote: e.currentTarget.value } });
                                            }}
                                            placeholder="Enter a note to the rider here..."
                                        />
                                    </div>
                                    {(adminProfile.features.internalTripAndRiderNotes22953 ? adminProfile.isSuperAdmin : true) &&
                                        <div className={appClasses.formGroup}>
                                            <Tooltip className={appClasses.cursorHelp}
                                                title={"Note just visible to other admins, not to the rider."}>
                                                <label htmlFor="internalNote" className={genClassNames.alignSelfStart}>Internal note</label>
                                            </Tooltip>
                                            <textarea name="internalNote"
                                                value={form.booking.editingInternalNote}
                                                onChange={(e) => {
                                                    updateData({ booking: { editingInternalNote: e.currentTarget.value } });
                                                }}
                                                placeholder="Enter an internal note here..."
                                            />
                                            <Tooltip title={"Internal note, not visible to the rider."} className={appClasses.cursorHelp}>
                                                <IconLocked className={classes.lock} />
                                            </Tooltip>
                                        </div>}
                                    {previousNotes.length > 0 &&
                                        <div className={appClasses.formGroup}>
                                            <label className={genClassNames.alignSelfStart}>Previous notes</label>
                                            <div className={genClassNames.grow}>
                                                {previousNotes.map((note, i) => <Note note={note} timezone={form.booking.timezone} key={i} />)}
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </ValidatorForm>
                    </div>
                    <div className={classes.mapContainer}>
                        <TKUIMapView
                            from={preFrom || form.booking.from}
                            to={preTo || form.booking.to || undefined}
                            onFromChange={location => updateBookingData("from", location || undefined)}
                            onToChange={location => updateBookingData("to", location || undefined)}
                            mapClickBehaviour="SET_FROM_TO"
                            rightClickMenu={[
                                { label: "Select as From", effect: "SET_FROM" },
                                { label: "Select as To", effect: "SET_TO" }
                            ]}
                            // Possibly use this logic as the default one in tripkit, and in that case
                            // remove this property (maybe there's a better way to provide customizable fit
                            // map conditions / behaviour).
                            shouldFitMap={(from: TKLocation | undefined, to: TKLocation | undefined, preFrom: TKLocation | undefined, preTo: TKLocation | undefined) => {
                                const fitFrom = !!from && from.isResolved() && !from.isDroppedPin() && (!preFrom ||
                                    JSON.stringify(Util.transerialize(from, LatLng)) !== JSON.stringify(Util.transerialize(preFrom, LatLng)));
                                const fitTo = !!to && to.isResolved() && !to.isDroppedPin() && (!preTo ||
                                    JSON.stringify(Util.transerialize(to, LatLng)) !== JSON.stringify(Util.transerialize(preTo, LatLng)));
                                return fitFrom || fitTo;
                            }}
                            showCurrLocBtn={false}
                            hideLocations={true}
                            readonly={fogShield}
                            ref={mapRef}
                        />
                    </div>
                    {fogShield && <div className={classes.fogShield} />}
                </div>
                <div className={classes.footer}>
                    <button
                        className={appClasses.buttonCancel}
                        onClick={() => onRequestClose?.()}>
                        {props.cancelLabel ?? "Cancel"}
                    </button>
                    <button className={appClasses.buttonAdd}
                        onClick={() => updateBooking()}
                    // disabled={!canSchedule(booking)}
                    >
                        {props.proceedLabel ?? "Schedule"}
                    </button>
                </div>
            </div >
        </TKRoot >
    );
}

export default withStyles(ScheduleBookingForm, scheduleBookingFormJss);