import React, { useState } from "react";
import Transaction, { TransStatus, TransType } from "../model/Transaction";
import FormatUtil from "../util/FormatUtil";
import DateTimeUtil from "../util/DateTimeUtil";
import Util from "../util/Util";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { IProps as TransactionsTableProps } from "./TransactionsTable";
import { Subtract } from "utility-types";
import { TransStatusSelect } from "../rewards/StatusSelect";
import genStyles from "../css/general.module.css";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import { ReactComponent as IconCross } from "../images/ic-cross2.svg";
import Loading from "../view/Loading";
import classNames from "classnames";
import LocationUtil from "../util/LocationUtil";
import { ReactComponent as ShowIcon } from "../images/ic-external-link.svg";
import { TransStatusPill } from "./StatusPill";
import { useProviders } from "../data/ProvidersData";

export interface IProps extends Subtract<TransactionsTableProps, { values: Transaction[] }> {
    value: Transaction;
}

export const TransactionRow: React.FunctionComponent<IProps> = (props: IProps) => {
    const [updating, setUpdating] = useState<Transaction | undefined>(undefined);
    const [error, setError] = useState<boolean>(false);
    const styles = props.classes;
    const appClasses = props.appClasses;
    const transaction = props.value;
    const transTime = transaction.type === TransType.BOOKING ?
        transaction.departMoment : transaction.timestampMoment;
    const money = transaction.type === TransType.BOOKING && transaction.price ?
        FormatUtil.toMoney(-transaction.price, { nInCents: true }) :
        (transaction.type === TransType.MONEY && transaction.amount ?
            FormatUtil.toMoney(transaction.amount, { nInCents: true }) : "-");
    const points = transaction.type === TransType.REWARD && transaction.points ? transaction.points :
        (transaction.type === TransType.REDEMPTION && transaction.points) ?
            (props.pointsAsDeducted ? transaction.points : -transaction.points) : "-";
    let description;
    const providers = useProviders({ clientId: transaction.clientId });
    if (transaction.type === TransType.BOOKING) {
        const modeIdentifier = providers?.find(provider => provider.id === transaction.mode);
        description =
            <span>
                {LocationUtil.getMainText(transaction.from) + " to " + (transaction.to ? LocationUtil.getMainText(transaction.to) : "")}
                {modeIdentifier ? " by " + modeIdentifier.modeName : ""}
            </span>;
    } else if (transaction.type === TransType.REDEMPTION) {
        description = transaction.rewardTitle ? FormatUtil.addPeriod(transaction.rewardTitle) : "";
        if (transaction.description) {
            description += (description ? " " : "") + FormatUtil.addPeriod(transaction.description);
        }
        if (!props.showStatus) {
            description =
                <React.Fragment>
                    {description}
                    &nbsp;&nbsp;
                    <div className={styles.statusInDescription}>
                        <TransStatusPill status={transaction.status!} />
                    </div>
                </React.Fragment>
        }
    } else if (transaction.type === TransType.MONEY) {
        description = transaction.note && FormatUtil.addPeriod(transaction.note);
        description =
            <React.Fragment>
                {description}
                {transaction.externalId &&
                    <a href={"https://dashboard.stripe.com/test/payments/" + transaction.externalId} target="_blank" className={classNames(appClasses.link, description && genStyles.charSpaceLeft)}>
                        Open in Stripe
                    </a>}
            </React.Fragment>
    } else {
        description = transaction.description || transaction.note;
    }
    const type = FormatUtil.toFirstUpperCase(transaction.type);
    const isSelectable = props.isSelectable && props.isSelectable(transaction);
    return (
        <TableRow
            onClick={() => isSelectable && props.onSelect && props.onSelect(transaction)}
            hover
            className={classNames(appClasses.row, isSelectable && genStyles.pointer)}
        >
            <TableCell className={appClasses.cell}>
                {transTime.format(DateTimeUtil.dayMonthFormat())}
            </TableCell>
            {props.showTime &&
                <TableCell className={appClasses.cell}>
                    {transTime.format(DateTimeUtil.timeFormat())}
                </TableCell>}
            {props.showEmployee &&
                <TableCell className={appClasses.cell}>
                    <div className={appClasses.cellHiddenBtnContainer}>
                        {transaction.userName}
                        <button className={appClasses.cellHiddenBtn}
                            onClick={(e) => {
                                let baseUrl = window.location.origin + window.location.pathname;
                                window.location.assign(baseUrl + "#/parts/id/" + transaction.userId)
                                // Cannot do this way (push user detail view on top of Redemption view) since Reward
                                // view (the embedding view) remains visible.
                                // navHistory.push(PART_DETAIL_VIEW, {id: transaction.userId});
                                e.stopPropagation();
                            }}
                        >
                            <ShowIcon />
                        </button>
                    </div>
                </TableCell>}
            {props.showType &&
                <TableCell className={appClasses.cell}>{type}</TableCell>}
            <TableCell className={classNames(appClasses.cell, appClasses.cellFit)}>{description}</TableCell>
            {props.showProduct &&
                <TableCell className={appClasses.cell}>
                    {transaction.productName}
                </TableCell>}
            {props.showMoney &&
                <TableCell className={appClasses.cell}>
                    {money}
                </TableCell>}
            {props.showPoints &&
                <TableCell className={appClasses.cell}>
                    {points}
                </TableCell>}
            {props.showStatus && !props.onEditedTrans &&
                <TableCell className={appClasses.cell}>
                    <div className={genStyles.flex}>
                        <TransStatusPill status={transaction.status!} />
                    </div>
                </TableCell>}
            {props.showStatus && props.onEditedTrans &&
                <TableCell className={appClasses.cell}>
                    <TransStatusSelect
                        value={updating ? updating.status : transaction.status}
                        onChange={(status?: TransStatus) => {
                            const update = Util.iAssign(transaction, { status: status });
                            setUpdating(update);
                            props.onEditedTrans && props.onEditedTrans(update)
                                .then(() => setUpdating(undefined))
                                .catch(() => {
                                    setUpdating(undefined);
                                    setError(true);
                                    setTimeout(() => setError(false), 3000);
                                });
                        }}
                        isDisabled={transaction.fixedStatus}
                    />
                </TableCell>}
            <TableCell className={appClasses.cell}>
                {props.isEditable && props.isEditable(transaction) &&
                    <IconEdit
                        className={styles.iconEdit}
                        onClick={() => props.onEdit && props.onEdit(transaction)}
                    />}
            </TableCell>
            <TableCell>

                {error ? <IconCross className={styles.error} /> :
                    updating ? <div className={styles.loading}><Loading /></div> : null}

            </TableCell>
        </TableRow>
    );
};

