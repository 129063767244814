import { Theme } from "../css/Theme";
import { black, genJss, keyFramesStyles, resetJss, white } from "../css/gen.jss";

export const sideViewJss = (theme: Theme) => ({
    modalContainer: {
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
        position: 'absolute'    // Override position: 'fixed', doesn't work as expected when embedded.
    },
    modal: {
        // ...theme.cardBackground,
        position: 'absolute',
        ...genJss.borderRadius(0),
        fontFamily: theme.fontFamily,
        width: '50%',
        height: '100%',
        ...genJss.flex,
        border: 'none',
        padding: '0',
        top: '0',
        bottom: '0',
        right: '0',
        outline: 'none'
    },

    slideIn: {
        animation: keyFramesStyles.keyframes.rightSlideIn + ' .1s linear'
    },
    slideOut: {
        animation: keyFramesStyles.keyframes.rightSlideOut + ' .1s linear',
        animationFillMode: 'forwards'
    },

    main: {
        height: '100%',
        width: '100%',
        fontFamily: theme.fontFamily,
        ...theme.textColorDefault,
        ...theme.textSizeBody,
        ...genJss.flex,
        ...genJss.column,
        overflow: 'hidden',
        backgroundColor: white(),
        boxShadow: '0 0 4px 0 rgba(0,0,0,.2), 0 6px 12px 0 rgba(0,0,0,.08)'
    },

    header: {
        padding: '24px 32px 24px 32px',
        color: black(),
        ...genJss.flex,
        ...genJss.column,
        ...theme.divider
    },

    headerTop: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.spaceBetween,
        ...genJss.alignCenter
    },

    title: {
        ...genJss.fontXL,
        ...theme.textWeightSemibold
    },

    subtitle: {
        ...genJss.fontM,
        ...theme.textColorGray
    },

    btnClear: {
        ...resetJss.button,
        height: '24px',
        width: '24px',
        padding: '6px',
        cursor: 'pointer',
        '& svg path': {
            fill: black(1)
        },
        '&:hover svg path, &:active svg path': {
            fill: black()
        }
    },

    iconClear: {
        width: '100%',
        height: '100%'
    },

    body: {
        ...genJss.grow,
        padding: '20px',
        overflowY: 'auto'
    }
});