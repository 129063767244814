import React, { useContext } from "react";
import Autocomplete from 'tripkit-react/dist/util_components/TKUIAutocomplete';
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import { ReactComponent as IconGlass } from "../images/ic-glass.svg";
import { IFieldAutocomplete } from "../nav/IViewRouteConfig";
import { ReactComponent as IconSpin } from "../images/ic-loading2.svg";
import { AppContext } from "../app/App";
import { searchBoxJss } from "./SearchBox.jss";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { SearchField } from "../data/Filter";
import Tooltip from "../uiutil/Tooltip";

type IStyle = ReturnType<typeof searchBoxJss>

interface IProps extends WithClasses<IStyle> {
    fields?: IFieldAutocomplete[],
    value?: Item;
    onChange: (value?: Item) => void;
    clientId?: string;
    className?: string;
    tooltip?: string;
}

export interface Item {
    label: string,
    field?: string,
    data?: string,  // To specify extra data.
    typed?: boolean // Item corresponding to text typed by the user, not an autocomplete result.
    // Just put in true for item created by SearchBox.
}

interface IState {
    items: Item[];
    value?: Item;
    text: string;
    loading: boolean;
    isFocused: boolean;
}

class SearchBox extends React.Component<IProps, IState> {

    private highlightedItem: Item | undefined = undefined;
    private inputRef = React.createRef();

    constructor(props: IProps) {
        super(props);
        this.state = {
            items: [],
            value: this.props.value,
            text: this.props.value ? SearchBox.itemLabel(this.props.value) : "",
            loading: false,
            isFocused: false
        };
        this.onKeyDown = this.onKeyDown.bind(this);
        this.refreshHighlight = this.refreshHighlight.bind(this);
        this.renderInput = this.renderInput.bind(this);
        this.onClearClicked = this.onClearClicked.bind(this);
        this.renderMenu = this.renderMenu.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    private onKeyDown(e: any) {
        if (e.keyCode === 38 || e.keyCode === 40) {
            setTimeout(this.refreshHighlight, 50);
        }
        // Patch to issue: Autocomplete does not trigger onSelect if there is no highlighted item when user hits enter,
        // which happens when you browse to option, then lose focus, then click on input text again, and hit enter.
        if (e.keyCode === 13 && this.state.value && (this.inputRef?.current as any).state.highlightedIndex === null) {
            this.setValue(this.state.value, true);
        }
        if (e.keyCode === 13) {
            this.onChange(this.state.value);
            this.refreshItems();
            setTimeout(() => {
                if (this.inputRef.current) { (this.inputRef.current as any).blur() }
            }, 100);
        }
    }

    private refreshHighlight() {
        if (this.highlightedItem) {
            this.setState({
                value: SearchBox.undefineEmpty(this.highlightedItem),
                // Uncomment to update input text on autocompletion result highlight
                // text: SearchBox.itemLabel(this.highlightedItem)
            })
        }
    }

    private refreshItems() {
        this.setState((state: IState) => {
            this.refreshResults(state.value ? SearchBox.itemLabel(state.value) : "");
        });
    }

    private static fillWithField(predictions: Item[], fieldName: string): Item[] {
        predictions.forEach((prediction: Item) => prediction.field = fieldName);
        return predictions;
    }

    private itemsFromInputText(inputText: string): Promise<{ items: Item[], typedItem: Item }> {
        let fieldText;
        let searchText;
        if (inputText.indexOf(":") !== -1) {
            [fieldText, searchText] = inputText.split(":");
            searchText = searchText.trimLeft();
        } else {
            searchText = inputText;
        }
        const relevantFields = fieldText ?
            this.props.fields!.filter((field: IFieldAutocomplete) => field.field.indexOf(fieldText) !== -1) : this.props.fields!;
        const fieldsPromises: Promise<Item[]>[] =
            relevantFields.map((field: IFieldAutocomplete) =>
                field.predictor(searchText)
                    .then((predictions: Item[]) => SearchBox.fillWithField(predictions, field.field))
                    .then((predictions: Item[]) => {
                        // If empty predictions for field but searchText matches field name, then put all default predictions for field.
                        if (predictions.length === 0 && field.field.includes(searchText)) {
                            return field.predictor("")
                                .then((predictions: Item[]) => SearchBox.fillWithField(predictions, field.field))
                        } else {
                            return predictions;
                        }
                    }));
        return Promise.all(fieldsPromises).then((values: Item[][]) => {
            const results: Item[] = values.reduce((acc: Item[], value: Item[]) => acc.concat(value), []);
            const typedItem: Item = Object.values(SearchField).includes(fieldText) ?    // fieldText is a valid field
                { label: searchText, field: fieldText, typed: true } :
                { label: inputText, typed: true };
            return ({
                items: inputText ? [typedItem].concat(results) : results.concat([typedItem]),
                typedItem: typedItem,
            });
        });
    }

    private renderInput(props: any) {
        const { classes, tooltip, appClasses } = this.props;
        const content =
            <div>
                <div className={classNames(classes.inputWrapper, appClasses.focusGlow, genStyles.flex, genStyles.alignCenter)}>
                    <input type="text"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        {...props}
                        onFocus={() => {
                            this.setState({ isFocused: true });
                            props.onFocus && props.onFocus();
                        }}
                        onBlur={() => {
                            this.setState({ isFocused: false });
                            props.onBlur && props.onBlur();
                        }}
                        onKeyDown={(e) => {
                            this.onKeyDown(e);
                            props.onKeyDown && props.onKeyDown(e);
                        }}
                        placeholder="Search..."
                    />
                    {this.state.loading ?
                        <IconSpin className="LocationBox-iconLoading sg-animate-spin" focusable="false" /> :
                        (this.state.value ?
                            <button onClick={this.onClearClicked} className={classes.btnClear} aria-hidden={true} tabIndex={-1}>
                                <IconRemove aria-hidden={true} className={classNames(classes.iconClear, genStyles.svgFillCurrColor)} focusable="false" />
                            </button> :
                            <IconGlass aria-hidden={true} className={classNames(classes.iconGlass, genStyles.svgFillCurrColor)} focusable="false" />)
                    }
                </div>
            </div>;
        return tooltip ?
            <Tooltip title={"Search by name, user ID, email or phone number"}>
                {content}
            </Tooltip> : content;
    }
    private onClearClicked() {
        this.setState({
            value: undefined,
            text: ""
        }, () => {
            this.onChange(undefined);
            this.refreshItems();
            (this.inputRef?.current as any).focus();
        });
    }

    private renderMenu(items: any[], value: any, style: any) {
        const classes = this.props.classes;
        return (
            <div
                children={items}
                className={items.length > 1 ? classes.menu : genStyles.displayNone}
                role="listbox"
            />
        );
    }

    private static undefineEmpty(item?: Item): Item | undefined {
        return item && (item.label || item.field) ? item : undefined;
    }

    private onChange(value?: Item) {
        this.props.onChange(SearchBox.undefineEmpty(value));
    }

    public setValue(value?: Item, fireEvents: boolean = false) {
        this.setState({ value: value, text: value ? SearchBox.itemLabel(value) : "" }, () => this.refreshItems());
        if (fireEvents) {
            this.onChange(value);
        }
    }

    private refreshResults(inputText: string): Promise<Item> {
        return this.itemsFromInputText(inputText).then(({ items, typedItem }) => {
            this.setState({
                items: items
            });
            return typedItem;
        })
    }

    private static itemLabel(item: Item) {
        return (item.field ? item.field + ":" + (item.label ? " " : "") : "") + item.label;
    }

    public render(): React.ReactNode {
        const { classes } = this.props;
        return (
            <Autocomplete
                getItemValue={(item: Item) => SearchBox.itemLabel(item)}
                items={this.state.items}
                value={this.state.text}
                onChange={(e) => {
                    this.setState({
                        text: e.target.value
                    });
                    this.refreshResults(e.target.value)
                        .then((typedItem: Item) => {
                            this.setState({
                                value: SearchBox.undefineEmpty(typedItem)
                            });
                        });
                }}
                onSelect={(value: string, item: Item) => {
                    this.setValue(SearchBox.undefineEmpty(item), true);
                    if (this.inputRef.current) {
                        (this.inputRef.current as any).blur();   // Lose focus on selection (e.g. user hits enter on highligthed result)
                    }
                }}
                renderItem={(item: Item, isHighlighted) => {
                    if (isHighlighted) {
                        this.highlightedItem = item;
                    }
                    return (
                        <div className={classNames(classes.item, genStyles.overflowEllipsis, item.typed ? classes.typed : undefined, isHighlighted && classes.highlighted)} key={item.label}>
                            {item.field &&
                                <span className={classes.resultField}>
                                    {item.field + ":"}
                                </span>}
                            {item.label}
                        </div>
                    );
                }}
                wrapperStyle={{
                    position: 'relative'
                }}
                renderInput={this.renderInput}
                renderMenu={this.renderMenu}
                // open={true}
                // autoHighlight={false}
                ref={this.inputRef}
            />
        );
    }

    public componentDidMount() {
        this.refreshItems();
    }

    public componentDidUpdate(prevProps: IProps): void {
        if (JSON.stringify(prevProps.fields) !== JSON.stringify(this.props.fields)) {
            this.refreshItems();
        }
        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setValue(this.props.value);
        }
        // Need this so when clientId changes items are refreshed just in case
        // field predictors depend on clientId, e.g. as predictor for 'bundle' and 'upcoming bundle' fields.
        // Other option would be to change passed bundle predictor when cliendId changes (not trivial), 
        // and detect here bundle predictor change.
        if (prevProps.clientId !== this.props.clientId) {
            this.refreshItems();
        }
    }

    public setLoading(loading: boolean) {
        this.setState({ loading: loading });
    }
}

const SearchBoxStyled = withStylesO(SearchBox, searchBoxJss);

const SearchBoxBound = () => {
    const appContext = useContext(AppContext);
    const topView = appContext.navHistory.viewAt(0);
    const topMatch = appContext.navHistory.getMatchFromViewMap().get(topView);
    const searchBoxProps = topView && topView.searchProps &&
        topView.searchProps!({ viewProps: topView.propsFromMatch!(topMatch, appContext.profile), ...appContext });
    return searchBoxProps ? <SearchBoxStyled {...searchBoxProps} /> : null;
};

export default SearchBoxBound;