import { appGlobalJss } from "../css/app.jss";
import { black, genJss, white } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import UIUtil from "../util/UIUtil";

export const filterWrapper = {     // This props are the react-select props, this class is called directly with them.
    background: white(),
    padding: '0 10px',
    border: '1px solid ' + black(3),
    ...genJss.borderRadius(6),
    boxShadow: 'none',
    cursor: 'pointer',
};

export const dropdownMenu = {
    top: '100%',
    position: 'absolute',
    overflowY: "auto",
    left: '0',
    right: '0',
    background: white(),
    padding: '4px',
    zIndex: '1',
    border: '1px solid ' + black(3),
    boxSizing: 'border-box',
    ...genJss.borderRadius(4)
};

export const statusSelectJss = (theme: Theme) => ({
    select: {
        width: '300px',
        '& .select__value-container': {
            padding: '0',
            display: 'flex'
        },
        '& .select__value-container--is-multi': {
            flexWrap: 'inherit'
        },
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        },
        '& .select__control--is-disabled': {
            backgroundColor: UIUtil.colorWithOpacity(black(), .04)
        },
        '& .select__control--is-disabled svg': {
            opacity: '.2'
        }
    },
    option: {
        ...theme.textSizeBody,
        ...theme.textWeightSemibold,
        cursor: 'pointer'
    },
    control: { ...filterWrapper, ...appGlobalJss(theme).focusGlow },
    menu: dropdownMenu,
    singleValue: (props) => props.optionStyle(theme, props.value)
});

export const selectOptionJss = (theme: Theme) => ({
    option: (props) => ({
        ...genJss.flex,
        padding: '8px',
        ...genJss.borderRadius(6),
        '&$selected': {
            backgroundColor: '#ebebeb'
        },
        '&$focused': {
            backgroundColor: black(4)
        },
    }),
    selected: {},
    focused: {}
});

export const defaultOptionStyle = (theme: Theme, optionValue?: any) => ({

});