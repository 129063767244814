import User from "../model/User";
import Util from "../util/Util";
import { default as AppSync } from "./AppSync";
import Filter, { SearchField, SortOrder } from "./Filter";
import {
    CREATE_USER_QUERY,
    createUser,
    deleteUser, GET_USER_BY_EXTERNAL_ID_QUERY,
    GET_USER_QUERY,
    getUser, getUserByExternalId,
    IListUsersQuery,
    LIST_USERS_QUERY,
    listUsers,
    updateUser,
    resetPassword,
    RESET_PASSWORD_QUERY,
    setBundle,
    removeBundle,
    addMoney
} from "./UsersSchema";
import NetworkUtil from "../util/NetworkUtil";
import { Observable, BehaviorSubject } from "rxjs";
import ItemsData from "./ItemsData";
import { getOrgsFromFilter } from "../app/OrgSelectorHelpers";
import { Item } from "../search/SearchBox";
import { getClientIDPath, getOrgIDPath } from "../app/App";
import AdminProfile from "../account/AdminProfile";
import Favourite from "tripkit-react/dist/model/favourite/Favourite";
import Location from "tripkit-react/dist/model/Location";
import FavouriteLocation from "tripkit-react/dist/model/favourite/FavouriteLocation";
import LocationUtil from "tripkit-react/dist/util/LocationUtil";
import { deserialize } from "tripkit-react/dist/favourite/TKFavouritesProvider";

function userQueryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const usersQueryParams: IListUsersQuery = {
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        nextToken: nextToken,
        initiativeId: filter.initiativeId
    };
    const searchQuery = filter.search;
    if (searchQuery) {
        if (searchQuery.field === SearchField.BUNDLE) {
            usersQueryParams.currentBundleId = searchQuery.data || searchQuery.label;
        }
        if (searchQuery.field === SearchField.UPCOMING_BUNDLE) {
            usersQueryParams.futureBundleId = searchQuery.data || searchQuery.label;
        }
        if (!searchQuery.field || searchQuery.field === SearchField.NAME) {
            usersQueryParams.name = searchQuery.label;
        }
    }
    usersQueryParams.limit = limit;
    usersQueryParams.clientId = filter.clientId;
    usersQueryParams.disabled = filter.userDisabled;

    usersQueryParams.organizationIds = getOrgsFromFilter(filter.orgId);
    return listUsers(usersQueryParams);
}

function processResults({ data, errors }) {
    if (!data || !data[LIST_USERS_QUERY]) {
        return { error: errors && errors[0] && errors[0].message || "This data is currently unavailable" };
    }
    const queryResult = data[LIST_USERS_QUERY];
    const items = queryResult.items.map((userJson: any) => Util.deserialize(userJson, User));
    return { items, nextToken: queryResult.nextToken }
}

class UsersData extends ItemsData<User> {

    private static _instance: UsersData;

    public static get instance(): UsersData {
        if (!this._instance) {
            this._instance = new UsersData(userQueryFromFilter, processResults);
        }
        return this._instance;
    }

    public search(query: string, options: { limit?: number, clientId?: string, orgId?: string | null } = {}): Promise<User[]> {
        const { limit = 200, clientId, orgId } = options;
        const usersQueryParams: IListUsersQuery = {
            search: query,
            // name: query,
            limit,
            clientId,
            organizationIds: orgId === null ? undefined : getOrgsFromFilter(orgId)
        };
        return AppSync.query({
            query: listUsers(usersQueryParams)
        }).then((data: any) => {
            return data.data[LIST_USERS_QUERY].items.slice(0, 5)
                .map((userJson: any) => Util.deserialize(userJson, User));
        })
    }

    public get(id: string, fallbackExternalId = true): Promise<User> {
        return AppSync.query({
            query: getUser(id),
        }).then((data: any) => {
            const user = data.data[GET_USER_QUERY];
            if (!user) {
                if (fallbackExternalId) {
                    return this.getByExternalId(id);
                } else {
                    throw new Error("User not found for id: '" + id + "'");
                }
            }
            return Util.deserialize(user, User);
        });
    }

    public getByExternalId(id: string): Promise<User> {
        return AppSync.query({
            query: getUserByExternalId(id),
            fetchPolicy: "network-only"
        }).then((data: any) => {
            const user = data.data[GET_USER_BY_EXTERNAL_ID_QUERY];
            if (!user) {
                throw new Error("User not found for id: '" + id + "'");
            }
            return Util.deserialize(user, User);
        });
    }

    public create(user: User): Promise<User> {
        return AppSync.mutate({
            mutation: createUser(user)
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const user = data.data[CREATE_USER_QUERY];
                if (!user) {    // Given the NetworkUtil.rejectOnGQLError above it may just happen if error was not properly specified on graphql response, but user didn't come.
                    throw new Error("User creation failed");
                }
                return Util.deserialize(user, User);
            });
    }

    public update(user: User): Promise<void> {
        return AppSync.mutate({
            // undefineNulls in false since we need to explicitly send futureBundle in null to remove it.
            mutation: updateUser(user)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public delete(id: string, clientId?: string): Promise<void> {
        return AppSync.mutate({
            mutation: deleteUser(id, clientId)
        });
    }

    public assignBundle(props: { userID: string, bundleID: string, future?: boolean, clientID: string, amount?: number, note?: string, freshStart?: boolean }) {
        return AppSync.mutate({
            mutation: setBundle(props)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public addMoney(props: { userID: string, clientID: string, amount: number, note?: string }) {
        return AppSync.mutate({
            mutation: addMoney(props)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public removeBundle(props: { userID: string, future?: boolean, clientID: string }): Promise<void> {
        return AppSync.mutate({
            mutation: removeBundle(props)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public removeOpalTripsForUser(userId: string): Promise<void> {
        const url = NetworkUtil.getSatappUrl("data/user/opal/user/" + userId + "/card");
        const options = {
            method: NetworkUtil.MethodType.DELETE,
            headers: NetworkUtil.getTGApiHeaders()
        };
        return fetch(url, options).then(NetworkUtil.status);
    }

    public resetPassword(email: string, clientId?: string): Promise<{ result: string }> {
        return AppSync.query({
            query: resetPassword(email, clientId),
            fetchPolicy: "network-only"
        }).then(({ data }) => {
            const response = data[RESET_PASSWORD_QUERY]
            if (!response?.result) {
                throw new Error("Reset password failed");
            }
            return response;
        });
    }

    private fetchCache: Map<string, Promise<Response>> = new Map<string, Promise<Response>>();

    private async fetch(input: RequestInfo | URL, init?: RequestInit, fromCache: boolean = true): Promise<Response> {
        const cacheKey = input + JSON.stringify(init);
        if (!fromCache || !this.fetchCache.has(cacheKey)) {
            this.fetchCache.set(cacheKey, fetch(input, init));
        }
        return (await this.fetchCache.get(cacheKey)!).clone();
    }

    public invalidateFetchCache(match?: string) {
        if (!match) {
            this.fetchCache.clear();
        } else {
            for (const url of Array.from(this.fetchCache.keys())) {
                if (url.includes(match)) {
                    this.fetchCache.delete(url);
                }
            }
        }
    }

    private fetchInsistCache: Map<string, Observable<{ result: any, insisted: number, insisting: boolean }>>
        = new Map<string, Observable<{ result: any, insisted: number, insisting: boolean }>>();

    // Caché not cleaned up by invalidateFetchInsistCache on purpose. It can be used as initial values of subjects,
    // (possibly put this as an option to the fetchInsist) so we still display old values while waiting for new ones.
    // TODO: can replace by specifying a policy like in graphql (cache-first, cache-and-network, or network-only) and
    // using fetchInsistCache instead of a new one (fetchInsistValuesCache). But old values won't display after cache
    // cleanup, you need to do a cache-and-network instead of a cache-first in those situations where you want to display
    // the old values until the new ones arrive.
    private fetchInsistValuesCache: Map<string, any> = new Map<string, any>();

    private fetchInsist(url: string, options: any,
        until: (result: any, insisted: number) => boolean,
        fromCache: boolean = true): Observable<{ result: any, insisted: number, insisting: boolean }> {
        const cacheKey = url + JSON.stringify(options);
        if (!fromCache || !this.fetchInsistCache.has(cacheKey)) {
            const fetchSubject = new BehaviorSubject<{ result: any | undefined, insisted: number, insisting: boolean }>
                ({ result: fromCache && this.fetchInsistValuesCache.get(cacheKey), insisted: 0, insisting: true });
            UsersData.fetchInsistUntil(url, options, until, 0, fetchSubject);
            this.fetchInsistCache.set(cacheKey, fetchSubject);
        }
        return this.fetchInsistCache.get(cacheKey)!;
    }

    public invalidateFetchInsistCache() {
        this.fetchInsistCache.clear();
    }

    private static fetchInsistUntil(url: string, options: any,
        until: (result: any, insisted: number) => boolean, insisted: number,
        subscriber: any) {
        fetch(url, options).then(NetworkUtil.jsonCallback)
            .then((result: any) => {
                insisted++;
                const insist = !until(result, insisted);
                const cacheKey = url + JSON.stringify(options);
                UsersData.instance.fetchInsistValuesCache.set(cacheKey, result);
                subscriber.next({ result, insisted, insisting: insist });
                if (insist) {
                    setTimeout(() => {
                        this.fetchInsistUntil(url, options, until, insisted, subscriber);
                    }, 3000);
                }
            })
    }

    public invalidateUserListCaches() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_USERS_QUERY) && cache.data.delete(key);
        }
        );
    }

    public invalidateUserCache(id: string) {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => key === id && cache.data.delete(key));
    }

    public async requestTripGoAPIUserToken({ userId, adminProfile, selectedOrgId }: { userId: string, adminProfile: AdminProfile, selectedOrgId?: string }): Promise<string> {
        const adminTokenEndpoint = adminProfile.appMetadata?.tripgoApiUrl + "/booking/admin/token";
        return this.fetch(adminTokenEndpoint, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'X-Account-Access-Token': adminProfile.appMetadata?.tripgoApiAccountAccessToken || ""
            },
            body: JSON.stringify({
                userID: userId,
                orgID: selectedOrgId
            })
        })
            .then(response => response.json())
            .then(tokenData => {
                return tokenData.result;
            })
    }

    public async fetchUserFavorites({ userId, clientAppId, adminProfile }: { userId: string, clientAppId: string, adminProfile: AdminProfile }): Promise<Favourite[]> {
        const adminToken = await this.requestTripGoAPIUserToken({ userId, adminProfile });
        const response = await this.fetch(adminProfile.appMetadata!.tripgoApiUrl + "/data/user/favorite", {
            "headers": {
                "accept": "application/json",
                "usertoken": adminToken,
                "x-tripgo-client-id": clientAppId,
                "x-tripgo-key": adminProfile.appMetadata!.tripgoApiKey
            },
            "method": "GET"
        }, true);
        return (await response.json()).result.map(fav => deserialize(fav));
    }
}

export const userPredictor = (text: string): Promise<Item[]> => {
    text = text.toLowerCase();
    if (text.length < 1) {
        return Promise.resolve([]);
    }
    return UsersData.instance.search(text, { limit: 5, clientId: getClientIDPath(), orgId: getOrgIDPath() })
        .then((users: User[]) => {
            return users.map((user: User) => ({
                label: user.name!,
                data: user.id
            }))
        })
};

export function matchFavorite(favorite: Favourite, location: Location): boolean {
    return favorite instanceof FavouriteLocation
        && (favorite.location.address === location.address)
        && LocationUtil.distanceInMetres(favorite.location, location) < 10;
}

export default UsersData;