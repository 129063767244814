import { appGlobalJss } from "../css/app.jss";
import { genJss, white } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const actionsMenuButtonJss = (theme: Theme) => ({
    buttonActions: {
        ...appGlobalJss(theme).buttonAdd,
        '& svg': {
            width: '10px',
            height: '10px',
            marginLeft: '10px',
            '& path': {
                fill: white()
            }
        }
    },
    actionIcon: {
        height: '22px',
        width: '22px',
        marginRight: '10px',
        '& path, polygon': {
            fill: theme.colorPrimary
        }
    }
});