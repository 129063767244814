import React, {useContext, useState} from "react";
import {WithClasses, withStyles} from "../css/StyleHelper";
import {editRewardViewJss} from "./EditRewardView.jss";
import ModalView from "../view/ModalView";
import Reward from "../model/Reward";
import {IViewRouteConfig} from "../nav/IViewRouteConfig";
import {RewardsContext} from "./RewardsProvider";
import {AppContext} from "../app/App";
import RewardsAPI from "./RewardsAPI";
import Util from "../util/Util";
import DatePicker from "../time/DatePicker";
import {genJss} from "../css/gen.jss";
import classNames from "classnames";
import {ValidatorForm} from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import genStyles from "../css/general.module.css";
import DateTimeUtil from "../util/DateTimeUtil";
import DnDImage from "./DnDImage";
import TransactionsData from "../data/TransactionsData";
import FormatUtil from "../util/FormatUtil";
import {CheckboxStyled} from "../booking/BookingsView";
import Tooltip from "../uiutil/Tooltip";
import * as moment from 'moment-timezone';

type IStyle = ReturnType<typeof editRewardViewJss>;

interface IProps extends WithClasses<IStyle> {
    reward?: Reward;
    onRequestClose?: (update?: Reward) => void;
}

interface IState {
    update: Reward;
}

export const ACTIVE_REWARD_TOOLTIP = "If inactive it will not appear on the list of available rewards on the mobile app.";

export const ESTIMATED_TIME_OF_APPROVAL_TOOLTIP = "How many days it usually takes for the admin staff to review, and " +
    "either approve or reject, a redemption of this reward.";


class EditRewardView extends React.Component<IProps, IState> {

    // private titleRef = React.createRef<HTMLInputElement>();
    private titleRef: any;
    private formRef = React.createRef<any>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            update: props.reward ? Util.clone(props.reward) : new Reward()
        };
        // value in ISO date-time
        ValidatorForm.addValidationRule('isDateInTheFuture', (value) => {
            const valueMoment = moment(value).set('hour', 0).set('minutes', 0).set('second', 0).set('millisecond', 0);
            const nowDateMoment = DateTimeUtil.getNowDate();
            return valueMoment.isAfter(nowDateMoment);
        });
    }

    private updateRewardState = (key, value) => {
        const updatedReward = Util.iAssign(this.state.update, { [key]: value });
        this.setState({
            update: updatedReward
        })
    };

    private handleChange = event => {
        this.updateRewardState(event.currentTarget.name, event.currentTarget.value);
    };

    private pointsChange = (points: number) => {
        this.updateRewardState("points", points);
    };

    private static expiryDateISOToDateTimeISO(dateS: string): string {
        return DateTimeUtil.momentDefaultTZ(dateS, DateTimeUtil.HTML5_DATE_FORMAT)
            .add(1, 'day').subtract(1, 'millisecond').format();
    }

    private static expiryDateTimeISOToDateISO(dateTimeS: string): string {
        return DateTimeUtil.momentDefaultTZ(dateTimeS).format(DateTimeUtil.HTML5_DATE_FORMAT);
    }

    private dateChange = ( dateS: string ) => {
        this.updateRewardState("expiryDate", EditRewardView.expiryDateISOToDateTimeISO(dateS));
    };

    private handleCheck = ( event ) => {
        this.updateRewardState("active", event.target.checked);
    };

    private updateReward = () => {
        this.formRef.current.isFormValid(false)
            .then((valid: boolean) => {
                if (valid && this.props.onRequestClose) {
                    this.props.onRequestClose(this.state.update);
                }
            });
    };

    render(): React.ReactNode {
        const props = this.props;
        const classes = this.props.classes;
        const appClasses = this.props.appClasses;
        const update = this.state.update;
        const isCreate = props.reward === undefined;
        return (
            <ModalView
                title={this.props.reward ? "Edit reward" : "Add reward"}
                onRequestClose={() => props.onRequestClose && props.onRequestClose()}
            >
                <ValidatorForm
                    onSubmit={() => {
                        console.log("callback");
                    }}
                    instantValidate={false}
                    ref={this.formRef}
                    className={genJss.grow}
                    noValidate  // To avoid HTML5 form validation, since we are providing our own. E.g. url field, of type url
                >
                    <div className={classNames(appClasses.form, appClasses.valueMinWidth)}>
                        <label className={classes.imageLabel}>Reward image</label>
                        <div className={classes.dnDImage}>
                            <DnDImage imageUrl={update.imageUrl}
                                      onImageChange={(file?: any, url?: string) =>
                                          this.setState({
                                              update: Util.iAssign(update, {image: file, imageUrl: url})}
                                          )}
                            />
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="title">Reward name</label>
                            <Validator
                                value={update.title}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: {errorMessage: any, value: any, inputRefCallback: any}) =>
                                    <div className={classNames(appClasses.value, genStyles.grow)}>
                                        <input name="title"
                                               ref={(ref) => {
                                                   if (!this.titleRef) { // To ensure focus is given just once.
                                                       this.titleRef = ref;
                                                       !this.props.reward && this.titleRef.focus();
                                                   }
                                                   params.inputRefCallback(ref);
                                               }}
                                               value={params.value}
                                               onChange={this.handleChange}
                                               type="text"
                                               placeholder="Title"
                                        />
                                        {params.errorMessage && <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="description">Description</label>
                            <Validator
                                value={update.description}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: {errorMessage: any, value: any, inputRefCallback: any}) =>
                                    <div className={classNames(appClasses.value, genStyles.grow)}>
                                        <textarea name="description"
                                                  value={params.value}
                                                  onChange={this.handleChange}
                                                  placeholder="Description"/>
                                        {params.errorMessage && <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="moneyAmount">Money reward</label>
                            <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                <div className={appClasses.currency}>$</div>
                                <input name="moneyAmount"
                                       type={"number"}
                                       min={0}
                                       step={"0.1"}
                                       // Use 0 as placeholder to indicate that this is the default value. No missing field validation required
                                       placeholder={"0"}
                                       value={!update.moneyAmount || update.moneyAmount <= 0 ? "" : FormatUtil.truncateToDecimals(update.moneyAmount / 100, 2)}
                                       onChange={(event) => {
                                           const parsed = parseFloat(event.target.value);
                                           const moneyAmount = isNaN(parsed) || parsed === 0 ? undefined : parsed * 100;
                                           this.updateRewardState("moneyAmount", moneyAmount);
                                       }}

                                />
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="points">Points to be deducted</label>
                            <Validator
                                value={update.points}
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) =>
                                    <div className={classNames(appClasses.value, appClasses.valueMinWidth)}>
                                        <input name="points"
                                               type={"number"}
                                               min={0}
                                               step={1}
                                               onChange={(e) => this.pointsChange(parseInt(e.target.value))}
                                               value={params.value}
                                               placeholder="Enter amount of points"/>
                                        {params.errorMessage &&
                                        <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="estimatedTimeOfApproval">
                                <Tooltip title={ESTIMATED_TIME_OF_APPROVAL_TOOLTIP}>
                                    Estimated time to approval
                                </Tooltip>
                            </label>
                            <div className={appClasses.value}>
                                <input name="estimatedTimeOfApproval"
                                       type={"number"}
                                       min={0}
                                       step={1}
                                       onChange={(e) => {
                                           const days = parseInt(e.target.value);
                                           return this.updateRewardState("estimatedTimeOfApproval", isNaN(days) ? undefined : days);
                                       }}
                                       value={update.estimatedTimeOfApproval !== undefined ? Math.round(update.estimatedTimeOfApproval) : undefined}
                                       placeholder="Time to approval"
                                       className={classes.durationInput}
                                />
                            </div>
                            <div className={appClasses.rightUnit}>
                                days
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="code">Code</label>
                            <Validator
                                value={update.code}
                                validators={[]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) =>
                                    <div className={appClasses.value}>
                                        <input name="code" key="code"
                                               onChange={(e) => this.updateRewardState(e.currentTarget.name,
                                                   e.currentTarget.value === "" ? undefined : e.currentTarget.value)}
                                               value={params.value} type="text" placeholder="Code"/>
                                        {params.errorMessage && <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label>Has validity end date</label>
                            <CheckboxStyled
                                   checked={this.state.update.expiryDate !== undefined}
                                   onChange={(e) => {
                                       const checked = e.target.checked;
                                       const newUpdate = Util.iAssign(update, {
                                           expiryDate: checked ?
                                               EditRewardView.expiryDateISOToDateTimeISO(DateTimeUtil.getNow().add(1, 'month').format(DateTimeUtil.HTML5_DATE_FORMAT)) : undefined
                                       });
                                       this.setState({update: newUpdate})
                                   }}
                            />
                        </div>
                        {update.expiryDate !== undefined &&
                        <div className={appClasses.formGroup}>
                            <label htmlFor="expiryDate">Valid until</label>
                            <div className={appClasses.value}>
                                <DatePicker name="expiryDatePicker"
                                            value={update.expiryDate ? EditRewardView.expiryDateTimeISOToDateISO(update.expiryDate) : DateTimeUtil.getNow().add(1, 'month').format(DateTimeUtil.HTML5_DATE_FORMAT)}
                                            onChange={this.dateChange}/>
                            </div>
                        </div>}
                        <div className={appClasses.formGroup}>
                            <label htmlFor="url">Terms URL</label>
                            <input name="url" key="url" onChange={this.handleChange}
                                   value={update.url} type="url" placeholder="URL"/>
                        </div>
                        <div className={appClasses.formGroup}>
                            <Tooltip title={ACTIVE_REWARD_TOOLTIP}>
                                <label className={appClasses.cursorHelp}>
                                    Active
                                </label>
                            </Tooltip>
                            <CheckboxStyled
                                name="active"
                                checked={update.active}
                                onChange={this.handleCheck}
                            />
                        </div>
                        <div className={appClasses.formGroup}
                             style={{minHeight: '44px'}}
                        >
                            <label>Featured</label>
                            <CheckboxStyled
                                checked={this.state.update.featured}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const newUpdate = Util.iAssign(update, {
                                        featured: checked,
                                        featuredUpTo: undefined
                                    });
                                    this.setState({update: newUpdate})
                                }}
                            />
                            {update.featured &&
                            <React.Fragment>
                                <label htmlFor="featuredUntil">until</label>
                                <CheckboxStyled
                                    checked={this.state.update.featuredUpTo !== undefined}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        const newUpdate = Util.iAssign(update, {
                                            featuredUpTo: checked ?
                                                EditRewardView.expiryDateISOToDateTimeISO(DateTimeUtil.getNow().add(1, 'month').format(DateTimeUtil.HTML5_DATE_FORMAT)) : undefined
                                        });
                                        this.setState({update: newUpdate})
                                    }}
                                />
                                {this.state.update.featuredUpTo !== undefined &&
                                <Validator
                                    value={update.featuredUpTo}
                                    validators={["isDateInTheFuture"]}
                                    errorMessages={["should be a date in the future"]}
                                    resetOnValid
                                >
                                    {(params: { errorMessage: any, inputRefCallback: any }) =>
                                        <div className={appClasses.value} style={{marginLeft: '20px'}}>
                                            <DatePicker name="featuredUntil"
                                                        value={update.featuredUpTo ? EditRewardView.expiryDateTimeISOToDateISO(update.featuredUpTo) : DateTimeUtil.getNow().add(1, 'month').format(DateTimeUtil.HTML5_DATE_FORMAT)}
                                                        onChange={(dateS: string) => {
                                                            this.updateRewardState("featuredUpTo", EditRewardView.expiryDateISOToDateTimeISO(dateS))
                                                        }}
                                            />
                                            {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                        </div>}
                                </Validator>}
                            </React.Fragment>}
                        </div>
                        <div className={classes.footer}>
                            <button className={appClasses.buttonCancel} onClick={() => this.props.onRequestClose && this.props.onRequestClose()}>Cancel</button>
                            <button className={appClasses.buttonAdd} onClick={() => this.updateReward()}>{isCreate ? "Add" : "Save"}</button>
                        </div>
                    </div>
                </ValidatorForm>
            </ModalView>
        );
    }
}

const EditRewardViewStyled = withStyles(EditRewardView, editRewardViewJss);

const useReward = (id?: string) => {
    const [reward, setReward] = useState<Reward | undefined>(undefined);
    const [waiting, setWaiting] = useState<boolean>(false);
    const {rewards} = useContext(RewardsContext);

    if (id === undefined || waiting) {
        return undefined;
    }
    if (reward && reward.id === id) {
        return reward;
    }
    const cachedReward = rewards && rewards[id];
    if (cachedReward) {
        setReward(cachedReward);
        return cachedReward;
    }
    setWaiting(true);
    RewardsAPI.getReward(id).then((reward: Reward) => {
        setReward(reward);
        setWaiting(false);
    });
    return undefined;
};

const EditRewardViewWithData: React.SFC<{id?: string}> = (props: {id?: string}) => {
    const reward = useReward(props.id);
    const {save} = useContext(RewardsContext);
    const {navHistory, waitFor} = useContext(AppContext);
    if (props.id !== undefined && !reward) { // Editing view and waiting for reward request.
        return null;
    }
    return (
        <EditRewardViewStyled
            reward={reward}
            onRequestClose={(update?: Reward) => {
                if (update) {
                    waitFor(save(update)).then(() => {
                        TransactionsData.instance.invalidateTransCache();
                        navHistory.pop();
                    });
                } else {
                    navHistory.pop();
                }
            }}
        />
    );
};

export const REWARD_EDIT_VIEW: IViewRouteConfig<{id?: string}> =
    {
        path: ["*/rewards/admin/id/:id/edit", "*/rewards/admin/new"],
        propsFromMatch: (match: any) => ({id: match.params.id}),
        propsToPath: (props: {id?: string}) => props.id ? "/id/" + props.id + "/edit" : "/new",
        navLabel: (routeProps: {viewProps: {id?: string}}) => routeProps.viewProps.id ? "Edit reward" : "New reward",
        render: ({viewProps}) => {
            return <EditRewardViewWithData id={viewProps.id}/>;
        },
        isModal: true
    };