import React, { FunctionComponent } from "react";
import Transaction from "../model/Transaction";
import { ReactComponent as IconAccept } from "../images/ic-check.svg";
import { ReactComponent as IconReject } from "../images/ic-cross2.svg";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { priceChangeDetailsJss } from "./PriceChangeDetails.jss";
import { ReactComponent as IconInfo } from "../images/ic-info.svg";
import classNames from "classnames";
import { genClassNames } from "tripkit-react/dist/css/GenStyle.css";
import FormatUtil from "../util/FormatUtil";
import { ReactComponent as IconArrowRight } from "../images/ic-arrow-right.svg";
import { PriceChangeActionType } from "./PriceChangeViewHelpers";

type IStyle = ReturnType<typeof priceChangeDetailsJss>;

interface IProps extends WithClasses<IStyle> {
    booking: Transaction;
    showStatus?: boolean;
    onOpenPriceChange?: (trans: Transaction, priceChangeAction?: Extract<PriceChangeActionType, "RESPOND">) => void;
}


const PriceChangeDetails: FunctionComponent<IProps> = ({ booking, showStatus = true, onOpenPriceChange, classes, appClasses }) => {
    const changeRequest = booking.priceChangeRequest;
    const changeResponse = booking.priceChangeResponse;
    const responseStatus = changeResponse?.status;
    if (!changeRequest) {
        return null;
    }
    const status = booking.priceChangeResponse?.status;
    const statusUI = (!showStatus || !status) ? null :
        status === "ACCEPTED" ? <IconAccept className={classes.iconAccept} /> : <IconReject className={classes.iconReject} />;
    const action = booking.actions.find(action => action.changes === "PRICE" && action.action === "RESPOND");
    const actionUI = onOpenPriceChange &&
        <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} style={{ marginLeft: '10px' }}>
            {action ?
                <button
                    className={appClasses.buttonAdd}
                    style={{ marginLeft: '5px' }}
                    onClick={(e) => {
                        onOpenPriceChange?.(booking, "RESPOND");
                        e.stopPropagation();
                    }}
                >
                    Review
                </button >
                :
                <button
                    className={classes.infoButton}
                    onClick={(e) => {
                        onOpenPriceChange?.(booking);
                        e.stopPropagation();
                    }}
                >
                    <IconInfo />
                </button>}
        </div>
    return (
        <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} >
            <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} style={{ flexWrap: 'wrap', marginRight: 'auto' }}>
                <div style={responseStatus === "ACCEPTED" ? { textDecoration: 'line-through' } : {}}>
                    {FormatUtil.toMoney(changeRequest.originalAmount ?? 0, { nInCents: true })}
                </div>
                <IconArrowRight style={{ margin: '0 8px', flexShrink: 0 }} />
                <div style={responseStatus === "REJECTED" ? { textDecoration: 'line-through' } : {}}>
                    {FormatUtil.toMoney(changeRequest.amount ?? 0, { nInCents: true })}
                </div>
            </div>
            {statusUI}
            {actionUI}
        </div>
    );
}

export default withStyles(PriceChangeDetails, priceChangeDetailsJss);