enum Env {
    PRODUCTION = "PRODUCTION",
    BETA = "BETA",
    DEVELOPMENT = "DEVELOPMENT"
}

const DEPLOY_HOST = window.location.host;

class Environment {

    private static environment = Env.PRODUCTION;

    public static initialize() {
        if (DEPLOY_HOST.startsWith("localhost")) {
            this.environment = Env.DEVELOPMENT;
        } else if (DEPLOY_HOST.includes("-beta") || DEPLOY_HOST.includes("beta.")) {
            this.environment = Env.BETA;
        }
        console.log("Environment: " + this.environment);
    }

    public static isProd(): boolean {
        return this.environment === Env.PRODUCTION;
    }

    public static isStaging(): boolean {
        return this.isProd() && DEPLOY_HOST.includes("-staging");
    }

    public static isBeta(): boolean {
        return this.environment === Env.BETA;
    }

    public static isDev(): boolean {
        return this.environment === Env.DEVELOPMENT;
    }

    public static isNotProdAnd(cond: boolean) {
        return !this.isProd() && cond;
    }

    public static isDevAnd(cond: boolean) {
        return this.isDev() && cond;
    }

}

Environment.initialize();

export default Environment;