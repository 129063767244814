import React from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { Theme } from "../css/Theme";
import { ReactComponent as IconCopy } from "../images/ic-copy-to-clipboard.svg";
import { genJss } from "../css/gen.jss";
import Tooltip from "./Tooltip";

type IStyle = ReturnType<typeof copyToClipboardJss>;

const copyToClipboardJss = (theme: Theme) => ({
    main: {
        cursor: 'pointer',
        ...genJss.flex,
        ...genJss.alignCenter,
        ...theme.textColorDefault,
        ...theme.textSizeBody,
        ...theme.textWeightRegular,
        whiteSpace: 'nowrap',
        '&:hover': {
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },
    icon: {
        marginLeft: '5px',
        width: '20px',
        height: '20px',
        ...genJss.noShrink
    }
});

interface IProps extends WithStyles<IStyle> {
    text: string;
    label?: React.ReactNode;
}

const CopyToClipboard: React.FunctionComponent<IProps> = props => {
    const { text, label, classes } = useStyles(props, copyToClipboardJss);
    const [copiedTooltip, setCopiedTooltip] = React.useState(false);
    const onClick = (e) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        setCopiedTooltip(true);
        setTimeout(() => setCopiedTooltip(false), 2000);
    }
    return (
        <Tooltip title={"Copied to clipboard"} open={copiedTooltip} placement="right">
            <div className={classes.main} onClick={onClick}>
                {label ?? text}
                <IconCopy className={classes.icon} />
            </div>
        </Tooltip>
    );
}

export default CopyToClipboard;