import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";

export const noteJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.relative
    },
    internal: {
        '& $bubble': {
            background: black(5),
            color: '#424141'
        }
    },
    public: {
        '& $bubble': {
            background: '#ffff99'
        }
    },
    bubbleAndLock: {
        ...genJss.flex,
        ...genJss.alignCenter
    },
    bubble: {
        border: '1px solid ' + black(4),
        padding: '15px',
        ...genJss.flex,
        ...genJss.borderRadius(4),
        ...genJss.grow
    },
    text: {
        ...genJss.grow
    },
    iconEdit: {
        height: '16px',
        width: '16px',
        cursor: 'pointer',
        marginLeft: '16px',
        '& path': {
            fill: theme.colorPrimary
        }
    },
    footer: {
        ...theme.textSizeCaption,
        ...theme.textColorGray,
        textAlign: 'right',
        marginTop: '5px'
    },
    lock: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginRight: '-25px',
        ...genJss.noShrink,
        '& path': {
            fill: black(2)
        }
    }
});