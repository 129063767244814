import { gql } from "graphql-tag";
import Initiative, { INITIATIVE_ID_FIELD, INITIATIVE_TITLE_FIELD, INITIATIVE_DESCRIPTION_FIELD, INITIATIVE_CLIENT_ID_FIELD, INITIATIVE_CREATED_AT_FIELD, INITIATIVE_DELETED_AT_FIELD, INITIATIVE_SCHEMA_BUILDER, INITIATIVE_BILLING_CODE_FIELD } from "../model/Initiative";
import Filter, { SortOrder } from "./Filter";
import ItemsData from "./ItemsData";
import NetworkUtil from "../util/NetworkUtil";
import Util from "../util/Util";
import AppSync from "./AppSync";
import GQLError from "../util/GQLError";
import { useEffect, useState } from "react";
import { StatusSelectOption } from "../rewards/StatusSelect";
import { adminProfile } from "../account/AdminProfile";

export interface IListInitiativesQuery {
    search?: string;
    limit?: number;
    clientId?: string,
    sortAsc?: boolean;
    disabled?: boolean;
    nextToken?: string;
}

export const LIST_INITIAITIVES_QUERY = "listInitiatives";
export const listInitiatives = (query: IListInitiativesQuery) => {
    return gql`
        query listInitiatives {
            ${LIST_INITIAITIVES_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
                items ${INITIATIVE_SCHEMA_BUILDER()}           
                nextToken
        }
    }
    `
};

function queryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const queryParams: IListInitiativesQuery = {
        search: filter.search?.label,
        clientId: filter.clientId,
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        disabled: filter.disabled,
        limit: limit,
        nextToken: nextToken
    };
    return listInitiatives(queryParams);
}

function processResults({ data, errors }) {
    if (!data?.[LIST_INITIAITIVES_QUERY]) {
        return { error: errors?.[0]?.message ?? "This data is currently unavailable" };
    }
    const queryResult = data[LIST_INITIAITIVES_QUERY];
    const items = queryResult.items
        .map((itemJson: any) => Util.deserialize(itemJson, Initiative));
    return { items, nextToken: queryResult.nextToken }
}

export const GET_INITIATIVE_QUERY = "getInitiative";
const GET_INITIATIVE_ID_PARAM = "Id";
export const getInitiative = (id: string) => gql`
query getInitiative {
    ${GET_INITIATIVE_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ [GET_INITIATIVE_ID_PARAM]: id })})    
        ${INITIATIVE_SCHEMA_BUILDER()}
}
`;
export interface ICreateInitiativeInput {
    title: string;
    description: string;
    clientId: string;
}

export const initiativeCreateFieldsBuilder = () => [
    INITIATIVE_TITLE_FIELD,
    INITIATIVE_BILLING_CODE_FIELD,
    INITIATIVE_DESCRIPTION_FIELD,
    INITIATIVE_CLIENT_ID_FIELD
]

const CREATE_INITIATIVE_QUERY = "createInitiative";
const createInitiative = (value: Initiative) => {
    const query = { ...Util.filterKeys(Util.serialize(value), initiativeCreateFieldsBuilder()) };
    return gql`
    mutation createInitiative {
        ${CREATE_INITIATIVE_QUERY} (
            input: ${Util.graphqlStringify(query)}
        ) ${INITIATIVE_SCHEMA_BUILDER()}
    }
    `;
};

export const initiativeUpdateFieldsBuilder = () => [
    INITIATIVE_ID_FIELD,
    ...initiativeCreateFieldsBuilder()
];

export const UPDATE_INITIATIVE_QUERY = "updateInitiative";
export const updateInitiative = (value: Initiative) => {
    const query = { ...Util.filterKeys(Util.serialize(value), initiativeUpdateFieldsBuilder()) };
    return gql`
    mutation updateInitiative {
        ${UPDATE_INITIATIVE_QUERY} 
        (
            input: ${Util.graphqlStringify(query)}            
        ) ${INITIATIVE_SCHEMA_BUILDER()}
    }
`
};

export const DELETE_INITIATIVE_QUERY = "disableInitiative";
export const deleteInitiative = (id: string, clientId: string) => {
    return gql`
    mutation deleteInitiative {
        ${DELETE_INITIATIVE_QUERY} 
        (            
            Id: "${id}",
            clientId: "${clientId}"
        ) 
        {
            result
        }
    }
`
};

export function useInitiatives(clientId?: string): Initiative[] | undefined {
    const [initiatives, setInitiatives] = useState<Initiative[] | undefined>();
    useEffect(() => {
        if (!adminProfile.features.trackTripInitiative22828) {
            return;
        }
        InitiativesData.instance.getAll(clientId).then((initiatives: Initiative[]) => {
            setInitiatives(initiatives);
        });
    }, [clientId])
    return initiatives;
}

export function useInitiativeOptions(clientId?: string): (StatusSelectOption<string> & { initiative: Initiative })[] | undefined {
    return useInitiatives(clientId)?.map((initiative: Initiative) => ({ value: initiative.id, label: initiative.title, initiative }));
}

class InitiativesData extends ItemsData<Initiative> {
    private static _instance: InitiativesData;
    public static get instance(): InitiativesData {
        if (!this._instance) {
            this._instance = new InitiativesData(queryFromFilter, processResults);
        }
        return this._instance;
    }

    public invalidateCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_INITIAITIVES_QUERY) && cache.data.delete(key);
        }
        );
    }

    public getAll(clientId?: string): Promise<Initiative[]> {
        return AppSync.query({
            query: listInitiatives({ clientId, limit: 500 })
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                const queryResult = data[LIST_INITIAITIVES_QUERY];
                return (queryResult?.items)
                    ?.map((itemJson: any) => Util.deserialize(itemJson, Initiative)) ?? [];
            });
    }

    public get(id: string): Promise<Initiative> {
        return AppSync.query({
            query: getInitiative(id)
        }).then(({ data }) => {
            const organizationJSON = data[GET_INITIATIVE_QUERY];
            if (!organizationJSON) {
                throw new GQLError('Booking not found for id: ' + id, undefined, true);
            }
            return Util.deserialize(organizationJSON, Initiative);
        });
    }

    public create(initiative: Initiative): Promise<Initiative | undefined> {
        return AppSync.mutate({
            mutation: createInitiative(initiative)
        }).then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const resultJson = data.data[CREATE_INITIATIVE_QUERY];
                return resultJson && Util.deserialize(Util.undefineNulls(resultJson, true), Initiative);
            });
    }

    public update(value: Initiative): Promise<void> {
        return AppSync.mutate({
            mutation: updateInitiative(value)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public delete(value: Initiative): Promise<void> {
        return AppSync.mutate({
            mutation: deleteInitiative(value.id, value.clientId!)
        });
    }
}

export const getInitiativeCacheRedirect = (_, args, { getCacheKey }) => {
    return getCacheKey({ __typename: 'Initiative', [GET_INITIATIVE_ID_PARAM]: args.Id });
};

export default InitiativesData;