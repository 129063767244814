import React from 'react';
import BemMixin from '../utils/BemMixin';
import PureRenderMixin from '../utils/PureRenderMixin';


class CalendarSelection extends React.Component {
  mixins = [BemMixin, PureRenderMixin]
  displayName = "CalendarSelection"

  constructor(props) {
    super(props);
    this.mixins.forEach(mixin => Object.assign(this, mixin));
  }

  render() {
    let { modifier, pending } = this.props;
    let modifiers = { [modifier]: true };
    let states = {
      pending,
    };

    return (
      <div className={this.cx({ states, modifiers })} />
    );
  }
};

export default CalendarSelection;
