import React, {useContext, useEffect} from "react";
import {WithClasses, withStyles} from "../css/StyleHelper";
import {rewardsViewJss} from "./RewardsView.jss";
import ViewsRenderer from "../nav/ViewsRenderer";
import {IViewRouteConfig} from "../nav/IViewRouteConfig";
import {AppContext} from "../app/App";
import {REWARDS_ADMIN_VIEW} from "./RewardsAdminView";
import {ReactComponent as IconClock} from "../images/ic-clock.svg";
import {ReactComponent as IconReward} from "../images/ic-reward.svg";
import classNames from "classnames";
import {REDEMPTIONS_VIEW} from "./RedemptionsView";

type IStyle = ReturnType<typeof rewardsViewJss>;
interface IProps extends WithClasses<IStyle> {}

const RewardsView: React.SFC<IProps> = (props: IProps) => {
    const {navHistory} = useContext(AppContext);
    const classes = props.classes;
    const appClasses = props.appClasses;
    useEffect(() => {
        navHistory.viewAt() === REWARDS_VIEW && navHistory.push(REWARDS_ADMIN_VIEW, {});
    });
    // return (
    //     <div className={classes.main}>
    //         <div className={classes.sideBar}>
    //             <button onClick={() => navHistory.replace(REDEMPTIONS_VIEW, {})}
    //                     className={classNames(classes.menuButton, navHistory.getMatchFromViewMap().get(REDEMPTIONS_VIEW) && classes.selected)}>
    //                 <IconClock/>
    //                 Redemption Events
    //             </button>
    //             <button onClick={() => navHistory.replace(REWARDS_ADMIN_VIEW, {})}
    //                     className={classNames(classes.menuButton, navHistory.getMatchFromViewMap().get(REWARDS_ADMIN_VIEW) && classes.selected)}>
    //                 <IconReward/>
    //                 Manage Rewards
    //             </button>
    //         </div>
    //         <div className={classes.container}>
    //             <ViewsRenderer views={REWARDS_VIEW.subViews!}/>
    //         </div>
    //     </div>
    // );
    return <ViewsRenderer views={REWARDS_VIEW.subViews!}/>;
};

const RewardsViewStyled = withStyles(RewardsView, rewardsViewJss);

export const REWARDS_VIEW: IViewRouteConfig<{}> =
    {
        path: ["*/rewards"],
        propsFromMatch: (match: any) => ({}),
        propsToPath: (props: {}) => "/rewards",
        navLabel: () => "Rewards",
        render: () => <RewardsViewStyled/>,
        subViews: [REDEMPTIONS_VIEW, REWARDS_ADMIN_VIEW]
    };