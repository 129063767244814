import {JsonObject, JsonProperty, JsonConverter, JsonCustomConvert} from "json2typescript";
import {
    ENABLED_MODES_FIELD,
    MOBILITY_OPTIONS_FIELD,
    USER_EXTERNAL_ID_FIELD,
    USER_TYPE_FIELD,
    USER_APP_DATA_OS_FIELD,
    USER_ORGANIZATIONS_FIELD
} from "../data/UsersSchema";

export enum OS {
    iOS = "iOS",
    android = "android"
}

@JsonConverter
export class OSConverter implements JsonCustomConvert<OS> {
    public serialize(value: OS): any {
        return value;
    }
    public deserialize(valueS: any): OS {
        return valueS;
    }
}

@JsonObject
class AppData {
    @JsonProperty(USER_EXTERNAL_ID_FIELD, String, true)
    public externalId: string | undefined = undefined;
    @JsonProperty(USER_TYPE_FIELD, String, true)    // Values for UQ: "STUDENT", "STAFF", "GUEST"
    public userType: string | undefined = undefined;
    @JsonProperty(MOBILITY_OPTIONS_FIELD, [String], true)
    public mobilityOptions?: string[] = undefined;
    @JsonProperty(ENABLED_MODES_FIELD, [String], true)
    public enabledModes?: string[] = undefined;
    @JsonProperty(USER_APP_DATA_OS_FIELD, OSConverter, true)    // Values: iOS, android
    public oS?: OS = undefined;
    @JsonProperty(USER_ORGANIZATIONS_FIELD, [String], true)
    public organizations?: string[] = undefined;
}

export default AppData;