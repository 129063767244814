import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import "reflect-metadata";
import './index.css';
import App from "./app/App";
import { IAccountContext, SignInStatus, WithAuth } from "./account/WithAuth";
import LoadingDash from "./app/LoadingDash";
import { appCredentials } from './account/appData';

//------------------------------------------------------------------------------
// Hardcoded keys for development. Comment out for prod compile and for commit.
// const devCredentials = require("./dev-credentials");
// const testAccount = window.location.host.includes("localhost") && devCredentials.mauroAdminAccountMiRide;
// const testAccount = window.location.host.includes("localhost") && devCredentials.mauroAdminAccountN4Connect;
// const testAccount = window.location.host.includes("localhost") && devCredentials.mauroTestDrt1AdminAccount;
// const testAccount = window.location.host.includes("localhost") && devCredentials.mauroTestDrt2AdminAccount;
// const testAccount = window.location.host.includes("localhost") && devCredentials.mauroAdminAccountUQ;
//------------------------------------------------------------------------------
const testAccount = undefined;

const AppWithAccount: React.FunctionComponent<{ accountContext: IAccountContext }> = (props) => {
    const account = props.accountContext;
    useEffect(() => {
        account.status === SignInStatus.signedOut && account.login()
    }, [props.accountContext.status]);
    return account.user ?
        <App remoteProfile={account.user} logout={account.logout} /> :
        <LoadingDash />;
};

function renderApp(containerId: string = "root") {
    const root = createRoot(document.getElementById(containerId))
    root.render(
        <WithAuth auth0Domain={appCredentials.auth0Domain} auth0ClientId={appCredentials.auth0ClientId}>
            {(account: IAccountContext) => <AppWithAccount accountContext={testAccount || account} />}
        </WithAuth>);
}

renderApp();

// if (process.env.NODE_ENV === 'development') {
//     require("./mock/mock.js");
// }


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

(window as any).renderApp = renderApp;