import { default as AppSync } from "./AppSync";
import Util from "../util/Util";
import Bundle from "../model/Bundle";
import {
    BUNDLE_SCHEMA_BUILDER,
    createBundle,
    deleteBundle,
    GET_BUNDLE_QUERY,
    getBundle, IListBundlesQuery,
    LIST_BUNDLES_QUERY,
    listBundles,
    updateBundle
} from "./BundlesSchema";
import ItemsData from "./ItemsData";
import Filter, { SortOrder } from "./Filter";
import ItemsResult from "../model/ItemsResult";
import { Item } from "../search/SearchBox";
import { adminProfile, ModelOperation, ModelType } from "../account/AdminProfile";
import { getClientIDPath } from "../app/App";
import { i18n } from "../i18n/TKI18nConstants";
import { gql } from "graphql-tag";
import NetworkUtil from "../util/NetworkUtil";

function bundleQueryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const queryParams: IListBundlesQuery = {
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        initiativeId: filter.initiativeId,
        limit: limit,
        nextToken: nextToken
    };
    const searchQuery = filter.search;
    if (searchQuery) {
        queryParams.search = searchQuery.label;
    }
    queryParams.clientId = filter.clientId;
    return listBundles(queryParams);
}

function processResults({ data, errors }) {
    if (!data || !data[LIST_BUNDLES_QUERY]) {
        return { error: errors && errors[0] && errors[0].message || "This data is currently unavailable" };
    }
    const queryResult = data[LIST_BUNDLES_QUERY];
    const items = queryResult.items.map((bundleJson: any) => Util.deserialize(bundleJson, Bundle));
    return { items, nextToken: queryResult.nextToken }
}

class BundlesData extends ItemsData<Bundle> {

    private static _instance: BundlesData;

    bundles: Bundle[] = [];

    public static get instance(): BundlesData {
        if (!this._instance) {
            this._instance = new BundlesData(bundleQueryFromFilter, processResults);
        }
        return this._instance;
    }

    public getList(filter?: Filter): Promise<ItemsResult<Bundle>> {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.read)) {
            return Promise.reject("Unauthorized access");
        }
        const queryFilter = filter || Util.deserialize({ pageSize: 10, page: 1 }, Filter);
        return new Promise<ItemsResult<Bundle>>((resolve, reject) => {
            this.watchQuery(queryFilter)
                .subscribe((next?: ItemsResult<Bundle>) => next && !next.waitingMore && resolve(next))
        });
    }

    public get(id: string): Promise<Bundle> {
        return AppSync.query({
            query: getBundle(id),
        }).then((data: any) => {
            const bundle = data.data[GET_BUNDLE_QUERY];
            if (!bundle) {
                throw new Error(i18n.t("Bundle") + ' not found for id: ' + id);
            }
            return Util.deserialize(Util.undefineNulls(bundle, true), Bundle);
        });
    }

    /**
     * @returns the created bundle, or undefined if it could not be retrieved.
     */
    public create(bundle: Bundle): Promise<Bundle | undefined> {
        return AppSync.mutate({
            mutation: createBundle(bundle)
        }).then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const bundleJson = data.data["createBundle"];
                return bundleJson && Util.deserialize(Util.undefineNulls(bundleJson, true), Bundle);
            });
    }

    public update(bundle: Bundle): Promise<void> {
        return AppSync.mutate({
            mutation: updateBundle(bundle)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public delete(id: string): Promise<void> {
        return AppSync.mutate({
            mutation: deleteBundle(id)
        });
    }

    public getBundleByName(name: string): Bundle | undefined {
        return this.bundles.find((bundle: Bundle) => bundle.name!.toLowerCase() === name);
    }

    public invalidateBundlesCaches() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_BUNDLES_QUERY) && cache.data.delete(key);
        }
        );
    }

    public search(query: string, options: { limit?: number, clientId?: string, orgId?: string | null } = {}): Promise<Bundle[]> {
        const { limit = 5, clientId, orgId } = options;
        const queryParams: IListBundlesQuery = {
            search: query,
            limit,
            clientId
        };
        return AppSync.query({
            query: listBundles(queryParams)
        }).then((data: any) => {
            return data.data[LIST_BUNDLES_QUERY].items
                .slice(0, 5)    // TODO: check if this is still necesessary
                .map((resultJson: any) => Util.deserialize(resultJson, Bundle));
        })
    }

    // public search(query: string, options: { limit?: number, clientId?: string, orgId?: string | null } = {}): Promise<Bundle[]> {
    //     const { limit = 10, clientId, orgId } = options;
    //     query = query.toLowerCase();
    //     const queryParams: Filter = Util.deserialize({
    //         pageSize: 100,
    //         page: 1,
    //         clientId,
    //         orgId,
    //     }, Filter);
    //     return BundlesData.instance.getList(queryParams)
    //         .then((result: ItemsResult<Bundle>) => result.items
    //             .filter((item: Bundle) => LocationUtil.relevance(query, item.name!.toLowerCase(), { preferShorter: true, allQueryWordsMatch: true }) > 0.5)
    //             .sort((a: Bundle, b: Bundle) => LocationUtil.relevance(query, b.name!.toLowerCase(), { preferShorter: true }) - LocationUtil.relevance(query, a.name!.toLowerCase(), { preferShorter: true }))
    //             .slice(0, limit)
    //             .map((item: Bundle) => { console.log(LocationUtil.relevance(query, item.name!.toLowerCase(), { preferShorter: true })); return item }));
    // }

    public updateInCache(bundle: Bundle) {
        const cache = AppSync.getClient().cache;
        console.log(cache.data.get(bundle.id));
        // With Apollo V3
        // if (cache.data.get(bundle.id)) {
        //     cache.modify({
        //         id: bundle.id,
        //         fields: Util.serialize(bundle)
        //     });
        // }
        // With Apollo V2. Confirmed it works!!!
        AppSync.getClient().writeFragment({
            id: bundle.id,
            fragment: gql`
              fragment getBundle on Bundle
                ${BUNDLE_SCHEMA_BUILDER()}              
            `,
            data: Util.serialize(bundle)
        });
        console.log(cache.data.get(bundle.id));
        // Object.keys(cache.data.data).forEach(key => key === id && cache.data.delete(key));
    }
}

const bundlePredictor = (text: string): Promise<Item[]> => {
    text = text.toLowerCase();
    return BundlesData.instance.getList(Util.deserialize({ pageSize: 100, page: 1, clientId: getClientIDPath() }, Filter))
        .then((result: ItemsResult<Bundle>) => {
            BundlesData.instance.bundles = result.items;
            return result.items.map((bundle: Bundle) => ({
                label: bundle.name!.toLowerCase(),
                data: bundle.id
            }))
                .filter((item: Item) => item.label.includes(text))
                .slice(0, 5);
        })
};

export default BundlesData;
export { bundlePredictor }