import { resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { priceChangeStatusColor } from "../model/Transaction";

export const priceChangeDetailsJss = (theme: Theme) => ({
    iconAccept: {
        height: '24px',
        width: '24px',
        marginLeft: '10px',
        flexShrink: 0,
        '& path': {
            fill: priceChangeStatusColor('ACCEPTED', theme)
        }
    },
    iconReject: {
        height: '24px',
        width: '24px',
        marginLeft: '10px',
        padding: '4px',
        boxSizing: 'border-box',
        flexShrink: 0,
        '& path': {
            fill: priceChangeStatusColor('REJECTED', theme)
        }
    },
    infoButton: {
        ...resetJss.button,
        height: '24px',
        width: '24px',
        borderRadius: '50%',
        '& svg': {
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },
});