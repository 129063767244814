import React, { useContext } from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { viewJss } from "./View.jss";
import NavStack from "../nav/NavStack";
import { AppContext } from "../app/App";
import { ReactComponent as IconAngleRight } from '../images/ic-angle-right.svg';
import classNames from "classnames";

interface IProps extends WithStyles<IStyle> {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    right?: React.ReactNode;
    children?: React.ReactNode;
    showNav?: boolean;  // default true
    onBack?: () => void;
    style?: React.CSSProperties;
    className?: string;
}

type IStyle = ReturnType<typeof viewJss>;

export const View: React.FunctionComponent<IProps> = (props: IProps) => {
    const { navHistory, profile } = useContext(AppContext);
    const { title, subtitle, right, showNav, onBack, children, classes, className, style } = useStyles(props, viewJss);
    return (
        <div className={classNames(classes.main, className)} style={style}>
            <div className={classes.header}>
                <div className={classes.left}>
                    <div className={classes.navContainer}>
                        {onBack ?
                            <button className={classes.back}
                                onClick={() => onBack()}>
                                <IconAngleRight />
                                Back
                            </button> :
                            showNav !== false ?
                                <NavStack navHistory={navHistory} profile={profile} /> : null}
                    </div>
                    <h1 className={classes.title}>{title}</h1>
                    <div className={classes.subtitle}>
                        {subtitle}
                    </div>
                </div>
                {right}
            </div>
            {children}
        </div>
    );
};

export default View;