import React, { useContext, useEffect, useState } from "react";
import Transaction, { moneyOperationToDisplayString, moneyTypeToDisplayString } from "../model/Transaction";
import DateTimeUtil from "../util/DateTimeUtil";
import FormatUtil from "../util/FormatUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { moneyTransactionsTableJss } from "./MoneyTransactionsTable.jss";
import TableSortLabel from "@mui/material/TableSortLabel";
import Filter, { SortOrder } from "../data/Filter";
import Util from "../util/Util";
import { TransGroups } from "./BookingsView";
import AdminProfile from "../account/AdminProfile";
import Table, { ITableColSpec } from "../view/Table";
import { AppContext, appPathUpdate } from "../app/App";
import { descendentOrg, orgFromId } from "../app/OrgSelectorHelpers";
import TransactionsData from "../data/TransactionsData";
import { Client } from "../model/Client";
import { ReactComponent as ShowIcon } from "../images/ic-external-link.svg";
import { PART_DETAIL_VIEW_MODAL } from "../user/UserView";
import { i18n } from "../i18n/TKI18nConstants";
import { walletsViewId } from "./WalletsView";
import { ReactComponent as IconExternalLink } from "../images/ic-external-link.svg";

type IStyle = ReturnType<typeof moneyTransactionsTableJss>;

interface IProps extends WithClasses<IStyle> {
    values: Transaction[];
    onSelect?: (transaction: Transaction) => void;
    onEdit?: (trans: Transaction) => void;
    onSchedule?: (trans: Transaction) => void;
    tableId: string;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    clients?: Client[];
    /**
     * If undefined, the config button is not displayed, but space is reserved for it.
     */
    showConfigBtn?: boolean;
}

type TableColId = "time" | "user" | "type" | "operation" | "description" | "client_app" | "org" | "status" | "amount" | "wallet_balance" | "edit";

/**
 * Order is relevant, too. Notice the order of charge column changes depending on the tableId.
 * No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(tableId: string, adminProfile: AdminProfile): TableColId[] {
    const insIf = (field: TableColId, cond: boolean) => Util.insertIf<TableColId>(cond, field);
    const dafaultColumns: TableColId[] = [
        "time", "user", "type", "operation", "description", "client_app", "amount", "wallet_balance", "edit"
    ];
    return dafaultColumns;
}

const MoneyTransactionsTable: React.FunctionComponent<IProps> = (props: IProps) => {

    const { values, filter, clients, tableId, showConfigBtn, onSelect, onFilterChange, onSchedule, onEdit, classes, appClasses } = props;

    const { profile: adminProfile, selectedOrgID, selectedClientID, navHistory } = useContext(AppContext);

    const [highlightRelated, setHighlightRelated] = useState<Transaction | undefined>();
    const theOtherRelatedId = highlightRelated?.relatedBookings.find(rb => rb.type === "OUTBOUND" || rb.type === "RETURN")?.bookingId;
    const [theOtherRelated, setTheOtherRelated] = useState<Transaction | undefined>();

    useEffect(() => {
        if (theOtherRelatedId) {
            TransactionsData.instance.get(theOtherRelatedId)
                .then(booking => setTheOtherRelated(booking))
        } else {
            setTheOtherRelated(undefined);
        }
    }, [theOtherRelatedId])

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = filter.sortOrder;
            const filterUpdate = Util.iAssign(filter,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate);
        }
    }

    const groupByDay = filter.groupBy === TransGroups.DAY;

    const tableContent: ITableColSpec<Transaction, TableColId>[] = [
        {
            id: "time",
            name: "Time",
            label: (
                <TableSortLabel
                    active={true}
                    direction={filter.sortOrder || SortOrder.DESC}
                    onClick={handleSortOrderClick}
                >
                    Time
                </TableSortLabel>
            ),
            cellValue: (trans: Transaction) => {
                const timezone = trans.timezone;
                const showGMT = DateTimeUtil.isOnDifferentTimezone(trans.timezone);
                const time = DateTimeUtil.momentTZTime(trans.timestamp * 1000, timezone);
                return groupByDay ? time.format(DateTimeUtil.timeFormat()) + (showGMT ? " " + DateTimeUtil.toGMTString(time) : "") :
                    time.format(DateTimeUtil.dateFormat() + " " + DateTimeUtil.timeFormat()) + (showGMT ? " " + DateTimeUtil.toGMTString(time) : "");
            },
            width: groupByDay ? 100 : 180
        },
        {
            id: "user",
            name: "User",
            cellValue: (trans: Transaction) =>
                <div className={appClasses.cellHiddenBtnContainer}>
                    {trans.userName}
                    <button className={appClasses.cellHiddenBtn}
                        onClick={(e) => {
                            let baseUrl = window.location.origin + window.location.pathname;
                            // window.location.assign(baseUrl + "#/parts/id/" + trans.userId)
                            navHistory.push(PART_DETAIL_VIEW_MODAL, { id: trans.userId })
                            // Cannot do this way (push user detail view on top of Redemption view) since Reward
                            // view (the embedding view) remains visible.
                            // navHistory.push(PART_DETAIL_VIEW, {id: transaction.userId});
                            e.stopPropagation();
                        }}
                    >
                        <ShowIcon />
                    </button>
                </div>,
            width: 140,
            available: !filter.userId
        },
        {
            id: "type",
            name: "Type",
            cellValue: (trans: Transaction) => trans.moneyType && moneyTypeToDisplayString(trans.moneyType),
            width: 70,
            available: !tableId.includes("-" + walletsViewId)
        },
        {
            id: "operation",
            name: "Operation",
            cellValue: (trans: Transaction) => trans.moneyOperation && moneyOperationToDisplayString(trans.moneyOperation),
            width: 100
        },
        {
            id: "description",
            name: "Description",
            cellValue: (trans: Transaction) =>
                <div className={classes.descriptionCell}>
                    {trans.note &&
                        <div>
                            {FormatUtil.addPeriod(trans.note)}
                        </div>}
                    {trans.stripeUrl &&
                        <a
                            href={trans.stripeUrl}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            className={appClasses.detailLinkIconOnHover}
                        >
                            Open in Stripe
                            <IconExternalLink />
                        </a>}
                    {trans.bookingId &&
                        <a
                            href={`#${appPathUpdate({ path: `/trans/all/id/${trans.bookingId}` })}`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            className={appClasses.detailLinkIconOnHover}
                            style={{ textDecoration: "underline" }}
                        >
                            Open booking
                            <IconExternalLink />
                        </a>}
                </div>,
            width: 200
        },
        {
            id: "amount",
            name: "$",
            cellValue: (trans: Transaction) => FormatUtil.toMoney(trans.amount, { nInCents: true }),
            width: 100
        },
        {
            id: "wallet_balance",
            name: tableId.includes("-" + walletsViewId) ? "Balance" : "Wallet Balance",
            cellValue: (trans: Transaction) => trans.walletBalance && FormatUtil.toMoney(trans.walletBalance, { nInCents: true }),
            width: 100
        },
        {
            id: "client_app",
            name: i18n.t("Client.app"),
            cellValue: (booking: Transaction) => clients?.find(client => client.clientID === booking.clientId)?.clientName,
            width: 140,
            available: adminProfile.isSuperApp,
            visible: !selectedClientID && !filter.userId    // Hide client column for wallets view since it's always in the context of a user, which is associated to one client.
        },
        {
            id: "org",
            name: i18n.t("Organization"),
            cellValue: (booking: Transaction) => booking.organizationId && adminProfile.orgs && (orgFromId(booking.organizationId, adminProfile.orgs)?.name ?? "unknown"),
            width: 140,
            available: adminProfile.features.organizations && !tableId.includes("-" + walletsViewId),
            visible: !selectedOrgID ||
                (adminProfile.orgs && adminProfile.orgs.some(o => o.id !== selectedOrgID && orgFromId(selectedOrgID, adminProfile.orgs!) && descendentOrg(o, orgFromId(selectedOrgID, adminProfile.orgs!)!)))
        }
    ];

    return (
        <div className={classes.tableContainer}>
            <Table
                tableId={tableId}
                contentSpec={tableContent}
                defaultColumns={getDefaultColumns(tableId, adminProfile)}
                items={values}
                tableClass={appClasses.scrollX}
                onClick={booking => onSelect?.(booking)}
                configurable={showConfigBtn}
            />
        </div>
    );
}

export default withStyles(MoneyTransactionsTable, moneyTransactionsTableJss);