import { Theme } from "../css/Theme";
import { black, genJss, resetJss, white } from "../css/gen.jss";

export const modalViewJss = (theme: Theme) => ({
    main: {
        height: '100%',
        fontFamily: theme.fontFamily,
        ...theme.textColorDefault,
        ...theme.textSizeBody,
        ...genJss.flex,
        ...genJss.column,
        overflow: 'hidden',
        backgroundColor: white(),
        boxShadow: '0 0 4px 0 rgba(0,0,0,.2), 0 6px 12px 0 rgba(0,0,0,.08)',
        ...genJss.borderRadius(12)
    },

    modal: {
        background: 'none',
        border: 'none',
        padding: '5px',
        transform: 'translate(-50%, 0)',
        left: '50%',
        width: '720px'
    },

    header: {
        padding: '24px 32px 24px 32px',
        color: black(),
        ...genJss.flex,
        ...genJss.column,
        ...theme.divider
    },

    headerTop: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.spaceBetween,
        ...genJss.alignCenter
    },

    title: {
        ...genJss.fontXL,
        ...theme.textWeightSemibold
    },

    subtitle: {
        ...genJss.fontM,
        ...theme.textColorGray
    },

    btnClear: {
        ...resetJss.button,
        height: '24px',
        width: '24px',
        padding: '6px',
        cursor: 'pointer',
        '& svg path': {
            fill: black(1)
        },
        '&:hover svg path, &:active svg path': {
            fill: black()
        }
    },

    iconClear: {
        width: '100%',
        height: '100%'
    },

    body: {
        ...genJss.grow,
        padding: '20px',
        overflowY: 'auto'
    }
});