import { JsonConvert, ValueCheckingMode } from "json2typescript";
import Filter from "../data/Filter";

type Update<T> = {
    [P in keyof T]?: T[P]
}

export type Required<T> = {
    [P in keyof T]-?: T[P];
};

class Util {

    // must cast as any to set property on window
    public static global: any = (window /* browser */ || global /* node */) as any;

    public static getWindowHeight(): number {
        return "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    }

    /**
     * Generic clone, requires T to have a default constructor
     */

    public static clone<T extends { constructor: any }>(instance: T): T {
        return Object.assign(new (instance.constructor as { new(): T })(), instance);
    }

    /**
     * Deep clone, works with classes annotated with json2typescript.
     */

    public static deepClone<T extends { constructor: any }>(instance: T): T {
        return this.transerialize(instance, instance.constructor as { new(): T });
    }

    /**
     * Serializes input object and deserializes to specified class. Both input object and passed classes should be
     * annotated with json2typescript.
     */

    public static transerialize<T, U>(instance: T, classRef: { new(): U }): U {
        return this.deserialize(this.serialize(instance), classRef);
    }

    /**
     * Generic immutable assign, requires T to have a default constructor Util.clone can be used.
     */

    // public static iAssign<T>(target: T, source: object): T {
    //     return Object.assign(Util.clone(target), source)
    // }

    public static iAssign<T extends { constructor: any }>(target: T, source: Update<T>): T {
        return Object.assign(Util.clone(target), source);
    }

    public static equals<T>(obj1: T, obj2: T): boolean {
        return JSON.stringify(this.serialize(obj1)) === JSON.stringify(this.serialize(obj2));
    }

    /**
     * Needed this since Set type does not allow to specify a custom equality comparator:
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set.
     * Mutable.
     */
    public static addNoRep<T>(array: T[], elem: T, equal: (e1: T, e2: T) => boolean): T[] {
        if (!array.find((e: T) => equal(elem, e))) {
            array.push(elem);
        }
        return array;
    }

    public static addAllNoRep<T>(array: T[], elems: T[], equal: (e1: T | null, e2: T | null) => boolean): T[] {
        for (const elem of elems) {
            this.addNoRep(array, elem, equal);
        }
        return array;
    }

    public static isEmpty(obj: any): boolean {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }

    private static jsonConvertI: JsonConvert;

    public static jsonConvert(): JsonConvert {
        if (!this.jsonConvertI) {
            this.jsonConvertI = new JsonConvert();
            this.jsonConvertI.valueCheckingMode = ValueCheckingMode.ALLOW_NULL;
        }
        return this.jsonConvertI;
    }

    public static deserialize<T>(json: any, classRef: { new(): T }, undefineNulls: boolean = true): T {
        try {
            return this.jsonConvert().deserialize(undefineNulls ? Util.undefineNulls(json, true) : json, classRef) as T;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    /**
     * Ensure immutablity of the json by cloning it before undefining nulls.     
     */
    public static deserialize2<T>(json: any, classRef: { new(): T }, undefineNulls: boolean = true): T {
        try {
            // Clone json to avoid modifying the original object. It caused issues in AppSync.queryWithCache, when comparing json from network and cache, where
            // the network one has the nulls removed due to this method, executed afterwards, updating the original object.
            let cleanJson;
            try {
                cleanJson = undefineNulls ? Util.undefineNulls(structuredClone(json), true) : json;
                // cleanJson = undefineNulls ? Util.undefineNulls(JSON.parse(JSON.stringify(json)), true) : json;                
            } catch (e) {
                cleanJson = undefineNulls ? Util.undefineNulls(json, true) : json;
            }
            return this.jsonConvert().deserialize(cleanJson, classRef) as T;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public static serialize<T>(value: T): any {
        try {
            return this.jsonConvert().serialize(value);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    public static filterKeys(obj: object, keys: string[]): object {
        return Object.keys(obj).reduce((result: object, key: string) => {
            if (keys.indexOf(key) !== -1) {
                result[key] = obj[key];
            }
            return result;
        }, {});
    }

    public static stringify<T>(value: T, keys?: string[]): string {
        return this.stringifyJustValues(keys ? this.filterKeys(this.serialize(value), keys) : this.serialize(value));
    }

    public static stringifyObj(value: object, keys?: string[]): string {
        return this.stringifyJustValues(keys ? this.filterKeys(value, keys) : this.serialize(value));
    }

    public static removeQuotesArrayValue(jSONString: string, field: string) {
        const regex = new RegExp(field + ':\\["([^(\\])]+)"\\]', "g");
        return jSONString.replace(regex, (match) => match.replace(/['"]+/g, ''));
    }

    public static removeQuotesValue(jSONString: string, field: string) {
        const regex = new RegExp(field + ':"([^(")"]+)"', "g");
        const jSONStringNoQuotesValues = jSONString.replace(regex, field + ":$1");
        return this.removeQuotesArrayValue(jSONStringNoQuotesValues, field);
    }

    public static removeQuotesValues(jSONString: string, fields: string[] = []) {
        return fields.reduce((acc, field) => this.removeQuotesValue(acc, field), jSONString);
    }

    public static graphqlStringify(query: any, dontQuoteFields: string[] = []): string {
        return this.removeQuotesValues(this.stringifyJustValues(query), dontQuoteFields);
    }

    public static undefineNulls(json: any, recursive: boolean = false): any {
        if (json === null || json === undefined) {
            json = undefined;
        } else if (Array.isArray(json)) {
            if (recursive) {
                json = json.map((elem: any) => this.undefineNulls(elem, recursive));
            }
        } else if (typeof (json) === "object") {
            for (const key in json) {
                if (!Object.prototype.hasOwnProperty.call(json, key)) {
                    continue;
                }
                if (json[key] === null) {
                    json[key] = undefined;
                } else if (recursive) {
                    json[key] = this.undefineNulls(json[key], recursive);
                }
            }
        }
        return json;
    }

    public static stringifyJustValues(json: any): string {
        return JSON.stringify(json).replace(/"([^(")"]+)":/g, "$1:")
    }

    public static promiseTimeout(time: number): Promise<void> {
        return new Promise(function (resolve) {
            setTimeout(() => resolve(), time);
        });
    };

    public static async retryOnError<T>(call: () => Promise<T>, options: { times: number, delay?: number }): Promise<T> {
        try {
            return await call();
        } catch (e) {
            const { times, delay } = options;
            if (times > 0) {
                if (delay) {
                    await this.promiseTimeout(delay);
                }
                return this.retryOnError(call, { times: times - 1 });
            } else {
                throw e;
            }
        }
    }

    public static isSafari() {
        return (navigator.userAgent.match(/AppleWebKit/) && !navigator.userAgent.match(/Chrome/));
    }

    public static insertIf<T>(condition?: boolean, ...elements: T[]): T[] {
        return condition ? elements : [];
    }

    public static isFunction(functionToCheck: any) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    }

    public static fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve((reader.result as string)
                .replace("data:", "")
                .replace(/^.+,/, ""));
            reader.onerror = reject;
        });
    }

    /**
     * Encode search queries to avoid introducing breaking characters through search. This could be generalized to other fields.
     * Also escape backslashes in search string before JSON.stringify to avoid an error on JSON.parse.            
     */
    public static stringifyFilter(filter: Filter): string {
        const clone = JSON.parse(JSON.stringify(Util.serialize(filter)));
        if (clone.search?.label) {
            clone.search.label = encodeURIComponent(clone.search.label.replace(/\\/g, "\\\\"));
        }
        return JSON.stringify(clone);
    }

    public static parseFilter(filterS: string): Filter {
        const filter = JSON.parse(filterS);
        if (filter.search?.label) {
            filter.search.label = decodeURIComponent(filter.search.label.replace(/\\\\/g, "\\"));
        }
        return Util.deserialize(filter, Filter);
    }
}

export default Util;