import { gql } from "graphql-tag";

export const POLYGON_TYPE_FIELD = "type";
export const POLYGON_COORDINATES_FIELD = "coordinates";

const POLYGON_SCHEMA = `
{
    ${POLYGON_TYPE_FIELD}
    ${POLYGON_COORDINATES_FIELD}
}
`

export const COLOR_RED = "red";
export const COLOR_GREEN = "green";
export const COLOR_BLUE = "blue";

const COLOR_SCHEMA = `
{
    ${COLOR_RED}
    ${COLOR_GREEN}
    ${COLOR_BLUE}
}
`

export const CLIENT_ID_FIELD = "clientID";
export const CLIENT_NAME_FIELD = "clientName";
export const CLIENT_POLYGON_FIELD = "polygon";
export const CLIENT_APP_COLORS_FIELD = "appColors";
export const CLIENT_APP_COLORS_TINT_COLOR_FIELD = "tintColor";

const CLIENT_SCHEMA = `
    {
        ${CLIENT_ID_FIELD}
        ${CLIENT_NAME_FIELD}
        ${CLIENT_POLYGON_FIELD} ${POLYGON_SCHEMA}
        ${CLIENT_APP_COLORS_FIELD} {
            ${CLIENT_APP_COLORS_TINT_COLOR_FIELD} ${COLOR_SCHEMA}
        } 
    }
`;

export const GET_CLIENTS_QUERY = "getClients";
export const getClients = () => {
    return gql`
    query getClients {
        ${GET_CLIENTS_QUERY} ${CLIENT_SCHEMA}                        
    }
    `;
};