import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const providerViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex
    },
    leftContainer: {
        ...genJss.flex,
        ...genJss.column,
        width: '50%',
        overflowY: 'auto',
        height: '100%'
    },
    mapContainer: {
        width: '50%'
    },
    detail: {
        ...genJss.flex,
        ...genJss.column,
        height: '100%',
        overflowY: 'auto',
        paddingTop: '30px'
    },
    title: {
        ...genJss.flex,
        ...genJss.alignStart
    },
    fieldsGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridRowGap: '50px',
        gridColumnGap: '20px',
        marginBottom: '50px'
    },
    providerColor: {
        width: '10px',
        height: '30px',
        borderRadius: '4px',
        margin: '5px 10px 5px 0',
        flexShrink: '0'
    },
    providerName: {
        whiteSpace: 'normal'
    },
    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },
    value: {
        ...theme.textColorGray
    },
    link: {
        color: black()
    },
    products: {
        ...genJss.flex,
        ...genJss.column,
        '& > *': {
            marginBottom: '30px'
        }
    },
    disabled: {
        color: theme.textColorDisabled,
        fontStyle: 'italic'
    },
    supported: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    unsupported: {
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        color: theme.textColorDisabled,
        fontStyle: 'italic'
    },
    unsupportedTitle: {
        ...theme.textWeightBold,
        marginRight: '1ch'
    }
});