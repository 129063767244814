import { graphqlSchema } from "../data/schema";
import { GraphQLSchema } from "graphql";
import { makeExecutableSchema } from "@graphql-tools/schema";
import { getDirectives } from "@graphql-tools/utils";
import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert, Any } from "json2typescript";
import { defaultTheme, Theme } from "../css/Theme";
import Organization from "../model/Organization";
import Util from "../util/Util";
import ModeIdentifier from "../model/ModeIdentifier";
import { ExportFormat } from "../export/ExportButton";
import AdminUser from "../model/AdminUser";
import Provider from "../model/Provider";

@JsonConverter
class PageSizeByViewConverter implements JsonCustomConvert<Map<string, number>> {
    public serialize(pageSizeByView: Map<string, number>): any {
        return Array.from(pageSizeByView.entries());
    }
    public deserialize(pageSizeByViewJson: any): Map<string, number> {
        return new Map<string, number>(pageSizeByViewJson);
    }
}

@JsonConverter
class TableConfigByIdConverter implements JsonCustomConvert<Map<string, TableConfig<string>>> {
    public serialize(map: Map<string, TableConfig<string>>): any {
        return Array.from(map.entries());
    }
    public deserialize(mapJSON: any): Map<string, TableConfig<string>> {
        return new Map<string, TableConfig<string>>(mapJSON);
    }
}

export interface TableConfig<CID> {
    visibleCols: CID[];
    availableCols: CID[];
}

@JsonObject
export class LocalProfile {
    @JsonProperty("pageSizeByView", PageSizeByViewConverter)
    public readonly pageSizeByView: Map<string, number> = new Map<string, number>();

    @JsonProperty("tableConfigById", TableConfigByIdConverter, true)
    public readonly tableConfigById: Map<string, TableConfig<string>> = new Map<string, TableConfig<string>>();

    // @JsonProperty("pollRefreshBookings", Boolean, true)  // Always in true.
    public pollRefreshBookings: boolean = true;

    @JsonProperty("daysToAdd", Number, true)
    public daysToAdd?: number = undefined;
}

export enum ModelType {
    Transaction = "Transaction",
    User = "User",
    Bundle = "Bundle"
}

export enum ModelOperation {
    create = "create",
    update = "update",
    delete = "delete",
    read = "read"
}

export interface FieldCondition {
    field: string;
    values?: string[];
    valueTokenField?: string;
}

export interface AuthRule {
    roles?: string[];
    operations?: ModelOperation[];
    fields?: string[];
    conditions?: FieldCondition[];
}

export interface Features {
    bundles?: boolean;
    rewards?: boolean;
    mobilityOptionsRequired?: boolean;
    queryTimeAndPref?: boolean;
    pickupWindow?: boolean;
    createBooking?: boolean;
    resetPassword?: boolean;
    bookingPrice?: boolean;
    multiPassenger?: boolean;
    payments?: boolean;
    autoCharge?: boolean;   // #18658: Support charge automatically. Just considered if payments is true.
    emailOrPhoneRequired?: boolean;
    userOS?: boolean;
    chargeWarns?: boolean;
    organizations?: boolean;
    reschedule?: boolean;
    relatedBookings?: boolean;  // #19782
    management?: boolean;   // #18973
    addMoney?: boolean; // #20125
    moneyTransactions?: boolean; // #20178
    newSearch20645?: boolean; // #20645. Remove once deployed to production.
    bookingStatuses20489?: boolean; // #20489
    bundleImg20922?: boolean; // #20489
    suspendUser21819?: boolean; // #21819
    createSimilarBooking21820?: boolean; // #21820
    tickets20717?: boolean; // #20717
    favorites22590?: boolean;
    priceChanges21702?: boolean; // #21702
    walletAuditing20675?: boolean; // #20675
    trackTripInitiative22828?: boolean; // #22828
    providerSettings22829?: boolean; // #22829
    providerMobilityOptions22827?: boolean; // #22827
    internalTripAndRiderNotes22953?: boolean; // #22953
    cancellationPolicy22860?: boolean; // #22860
    editUserInfo23131?: boolean; // #23131
    editAssignedWallet23000?: boolean; // #23000
    editProvider23380?: boolean; // #23380
    favNamesOnBookingDetails23475?: boolean; // #23475
}

export enum Envs {
    PROD = "PROD",
    STAGING = "STAGING",
    BETA = "BETA"
}
export interface AppMetadata {
    localTimezone?: string;
    apps?: { id: string; name: string, logoUrl: string }[];
    app?: string;
    isBeta?: boolean;
    environment: Envs;
    tspMode?: string;
    tripgoApiUrl: string;
    tripgoApiKey: string;
    tripgoApiAccountAccessToken?: string;
    gqlApiUrl: string;
    gqlApiKey: string;
    gqlApiRegion: string;
    stripeKey?: string;
    appName: string;
    isSuperApp?: boolean;
    logoUrl: string;
    /**
     * Feature flags. They are defined in the following places, with priority from highest to lowest:
     * 1. URL query parameters, like `?feature.bundles=true&feature.rewards=false`.
     * 2. AdminUser profile coming from the BE: gql query `getAdminUserGrants`.
     * 3. Values hardcoded in appData.ts.
     */
    features: Features;
    theme?: Partial<Theme>;
    modes?: object;
    clientSpecificModes?: object;
    clientsData?: Record<string, Pick<AppMetadata, 'timezone' | 'defaultCity'>>;
    userTypes?: string[];
    transStatusValues: (string | { id: string, label: string })[]
    /**
     * To be used by strings and date formats. Default to navigator.language (by getter) if not specified.
     * Maybe this should always be navigator.language since HTML5 datetime-local inputs will always assume
     * that locale (cannot override with another locale).
     */
    locale?: string;
    timezone?: string;
    defaultCity?: string;
    justEnabledByDefault?: string[],
    dev?: {
        scheduleEndpoint?: string;
    },
    export?: {
        booking?: {
            csvFormat?: (string | { label?: string; value: string; trans?: string; })[];
        },
        moneyTrans?: {
            csvFormat?: (string | { label?: string; value: string; trans?: string; })[];
        },
        formats?: ExportFormat[];
    },
    geocoding?: {
        provider: "google" | "geocode_earth";
        apiKey: string;
    },
    map?: {
        provider: "google" | "mapbox";
        apiKey: string;
    }
    clientIds: string[];
    orgIds: string[];
}

export interface Auth0Profile {
    nickname?: string;
    name?: string;
    email: string;
    email_verified: boolean;
    picture: string;
}
export interface RemoteProfile extends Auth0Profile {
    name: string;
    isSkedGoDev: boolean;
    userData: AdminUser;
    appData: AppMetadata
}

export let orgsPResolve: (value: Organization[] | PromiseLike<Organization[]>) => void = () => { };
const orgsP: Promise<Organization[]> = new Promise<Organization[]>((resolve, reject) => {
    orgsPResolve = resolve;
});

export type DashUserType = "ADMIN" | "ORG" | "TSP";

/**
 * Make it serializable so Util.clone works on AdminProfile so can make immutable changes. Just LocalProfile will be
 * updated for now, but remote could possibly be updated in the future.
 */
@JsonObject
class AdminProfile {

    @JsonProperty("remote", Any)
    public remote: RemoteProfile;
    @JsonProperty("local", LocalProfile)
    public local: LocalProfile;

    // This is to pick client dependent config / data transparently, like timezone and default city. Notice AppMetadata includes
    // client specific data (`clientsData` field) for super apps.
    @JsonProperty("selectedClientID", String, true)
    public selectedClientID?: string = undefined;

    public orgsP: Promise<Organization[]> = orgsP;
    @JsonProperty("allOrgs", [Organization], true)
    public allOrgs?: Organization[] = undefined;
    @JsonProperty("orgs", [Organization], true)
    public orgs?: Organization[] = undefined;

    constructor(remote: RemoteProfile, local: LocalProfile) {
        this.remote = remote;
        this.local = local;
        // Override feature flags with those specified in the URL.
        if (remote?.appData) {   // The Util.deepClone calls the constructor without arguments, so this is to avoid an exception.                                                                                                
            const urlSearch = new URLSearchParams(window.location.search);
            const featureFlagsInSearch = {};
            for (const [key, value] of urlSearch as any) {
                if (key.startsWith("feature.")) {
                    const featureName = key.substring("feature.".length);
                    featureFlagsInSearch[featureName] = value === "true" ? true : value === "false" ? false : value;
                    this.appMetadata!.features = { ...this.features, ...featureFlagsInSearch };
                }
            }
        }
    }

    get name(): string | undefined {
        return this.remote.name;
    }

    get nickname(): string | undefined {
        return this.remote.nickname;
    }

    get email(): string {
        return this.remote.email;
    }

    get picture(): string {
        return this.remote.picture;
    }

    get appMetadata(): AppMetadata | undefined {
        return this.remote.appData;
    }

    get role(): string {
        return this.remote.userData.role ?? "guest";
    }

    get userType(): DashUserType {
        return this.isSuperAdmin ? "ADMIN" : this.isOrgUser ? "ORG" : "TSP";
    }

    get tspMode(): string | undefined {
        return this.remote.userData.providers[0]?.id;
    }

    get provider(): Provider | undefined {
        return this.remote.userData.providers[0];
    }

    get tripgoApiUrl(): string {
        return this.appMetadata?.tripgoApiUrl || "";
    }

    get tripgoApiKey(): string {
        return this.appMetadata?.tripgoApiKey || "";
    }

    get tripgoApiAccountAccessToken(): string {
        return this.appMetadata?.tripgoApiAccountAccessToken || "";
    }

    get gqlApiUrl(): string {
        return this.appMetadata?.gqlApiUrl || "";
    }

    get gqlApiKey(): string {
        return this.appMetadata?.gqlApiKey || "";
    }

    get gqlApiRegion(): string {
        return this.appMetadata?.gqlApiRegion || "";
    }

    get app(): string {
        return this.appMetadata?.app || "";
    }

    get appName(): string {
        return this.appMetadata?.appName || "";
    }

    get isSuperApp(): boolean {
        return !!this.appMetadata?.isSuperApp;
    }

    get logoUrl(): string {
        return this.appMetadata?.logoUrl || "";
    }

    get features(): Features {
        return this.appMetadata?.features || { bundles: true, rewards: true };
    }

    get userTypes(): string[] {
        return this.appMetadata?.userTypes || [];
    }

    get locale(): string {
        return this.appMetadata?.locale || navigator.language;
    }

    get timezone(): string {
        // return this.appMetadata?.timezone || "Australia/Sydney";
        return this.getTimezone();
    }

    public getTimezone(clientID?: string): string {
        clientID = clientID ?? this.selectedClientID;
        return (this.isSuperApp && clientID && this.appMetadata?.clientsData?.[clientID]?.timezone) ?
            this.appMetadata?.clientsData?.[clientID]?.timezone! : this.appMetadata?.timezone ?? "Australia/Sydney";
    }

    get localTimezone(): string | undefined {
        try {   // localTimezone in appMetadata allows to override the admin browser's timezone.
            return this.appMetadata?.localTimezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone; // https://stackoverflow.com/a/34602679
        } catch (e) {
            return undefined;
        }
    }

    get defaultCity(): string | undefined {
        const clientID = this.selectedClientID;
        return (this.isSuperApp && clientID && this.appMetadata?.clientsData?.[clientID]?.defaultCity) ?
            this.appMetadata?.clientsData?.[clientID]?.defaultCity! : this.appMetadata?.defaultCity;
    }

    get pageSizeByView(): Map<string, number> {
        return this.local.pageSizeByView;
    }

    get pollRefreshBookings(): boolean {
        return this.local.pollRefreshBookings;
    }

    set pollRefreshBookings(value: boolean) {
        this.local.pollRefreshBookings = value;
    }

    private schema: GraphQLSchema = makeExecutableSchema({
        typeDefs: graphqlSchema
    });

    /**
     * @param {ModelType} type
     * @param {ModelOperation} operation
     * @returns {AuthRule | false} the matching AuthRule, with {} (empty AuthRule) if there is no auth directive for
     * the model type (in this case it's authorized unconditionally), or false if not authorized.
     * This allows to use result in conditions, and use optional chaining (?.) to test if authorized with some
     * restriction.
     */

    public itemAuth(type: ModelType, operation: ModelOperation): AuthRule | false {
        const modelType = this.schema.getType(type)!;
        const authDirective = getDirectives(this.schema, modelType).auth;
        if (!authDirective) {
            return {};
        }
        const rules: AuthRule[] = authDirective.rules;
        const matchingRule = rules.find((rule: AuthRule) =>
            (!rule.roles || rule.roles.includes(this.role)) &&
            (!rule.operations || rule.operations.includes(operation)));
        return matchingRule || false;   // matchingRule if not undefined, otherwise false.
    }

    public fieldAuth(type: ModelType, operation: ModelOperation, field: string): boolean {
        const itemAccess = this.itemAuth(type, operation);
        return typeof itemAccess === "boolean" ? itemAccess : (!itemAccess.fields || itemAccess.fields.includes(field));
    }

    public fieldCondValues(type: ModelType, operation: ModelOperation, field: string): string[] | undefined {
        const itemAuth = this.itemAuth(type, operation);
        const fieldCondition = itemAuth ? itemAuth.conditions?.find((fieldCond: FieldCondition) => fieldCond.field === field) : undefined;
        // undefined means no restriction on field.
        return fieldCondition?.values || (fieldCondition?.valueTokenField ? [fieldCondition.valueTokenField] : undefined);
    }

    public getModes(clientID?: string): object {
        let clientSpecificModes = {};
        if (this.appMetadata?.clientSpecificModes) {
            if (clientID) {
                clientSpecificModes = { ...this.appMetadata.clientSpecificModes[clientID] };
            } else {
                Object.keys(this.appMetadata.clientSpecificModes)
                    .filter(clientID => adminProfile.appMetadata?.clientIds === undefined || adminProfile.appMetadata?.clientIds.includes(clientID)) // Just consider modes for clients enabled for the admin.
                    .forEach(clientID =>
                        clientSpecificModes = { ...clientSpecificModes, ...this.appMetadata?.clientSpecificModes?.[clientID] });
            }
        }
        return ({ ...this.appMetadata?.modes, ...clientSpecificModes });
    }

    public getModesList(clientID?: string): ModeIdentifier[] {
        const modesData = this.getModes(clientID) || {};
        const result = [] as ModeIdentifier[];
        for (const modeKey of Object.keys(modesData)) {
            const modeIdentifier = Util.deserialize(modesData[modeKey], ModeIdentifier) as ModeIdentifier;
            modeIdentifier.identifier = modeKey;
            result.push(modeIdentifier);
        }
        result.sort((el1: ModeIdentifier, el2: ModeIdentifier) => el1.title.localeCompare(el2.title));
        return result;
    }

    get orgIds(): string[] | undefined {
        return this.isTSPUser ? undefined : this.remote.userData.organizations.map(org => org.id);
    }

    get clientIds(): string[] {
        return (this.isTSPUser ?
            [...new Set(this.remote.userData.providers.map(p => p.clientId).filter(clientId => clientId !== undefined))] :
            [...new Set(this.remote.userData.organizations.map(org => org.isRoot ? undefined : org.clientId).filter(clientId => clientId !== undefined))]) as string[];
    }

    get environment(): Envs {
        return this.appMetadata?.environment ?? Envs.BETA;
    }

    /**
     * Assuming for now that admins with orgIds are org users. Consider adding a specific `org` role.
     */
    get isOrgUser(): boolean {
        return this.role === 'org_admin';
    }

    get isTSPUser(): boolean {
        return this.role === 'tsp_admin';
    }

    get isSuperAdmin(): boolean {
        return this.role === 'admin' && !this.isOrgUser && !this.isTSPUser;
    }

    get isSkedGoDev(): boolean {
        return this.remote?.isSkedGoDev ?? false;
    }

}

/**
 * Define this to make current profile accessible from some particular places, but should reafactor code to avoid
 * the need of this.
 */
export let adminProfile: AdminProfile;
export function setAdminProfile(profile: AdminProfile) {
    adminProfile = profile;
}
export default AdminProfile;