import UIUtil from "../util/UIUtil";
import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";
import {
    dropdownMenu,
    filterWrapper
} from "../rewards/StatusSelect.jss";

export const pageSizeSelectJss = (theme: Theme) => ({
    select: {
        width: '80px',
        '& .select__value-container': {
            padding: '0',
            display: 'flex'
        },
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        },
        '& .select__control--is-disabled': {
            backgroundColor: UIUtil.colorWithOpacity(black(), .04)
        },
        '& .select__control--is-disabled svg': {
            opacity: '.2'
        }
    },
    option: {
        ...genJss.flex,
        padding: '8px',
        ...genJss.borderRadius(6),
    },
    optionSelected: {
        backgroundColor: '#ebebeb'
    },
    optionFocused: {
        backgroundColor: '#f6f6f6'
    },
    control: {
        ...filterWrapper,
        padding: '0 10px 0 5px',
    },
    menu: {
        ...dropdownMenu,
        zIndex: 2   // So it's above (sticky) table headers.
    },
    singleValue: {
        ...theme.textSizeBody,
        ...genJss.flex,
        ...genJss.borderRadius(12),
        padding: '0 8px'
    }
});