import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert } from "json2typescript";
import {
    BUNDLE_APPLIED_TIMESTAMP_FIELD,
    BUNDLE_CLIENT_ID_FIELD,
    BUNDLE_CONFIG_FIELD,
    BUNDLE_CURRENT_USER_COUNT_FIELD,
    BUNDLE_EXPIRATION_TIMESTAMP_FIELD,
    BUNDLE_FUTURE_USER_COUNT_FIELD,
    BUNDLE_ID_FIELD,
    BUNDLE_INITIATIVE_FIELD,
    BUNDLE_NAME_FIELD,
    BUNDLE_NOTE_FIELD,
    BUNDLE_PURCHASE_ONCE_FIELD,
    BUNDLE_RESTRICTED_ONLY_TO_FIELD,
    BUNDLE_SHORT_ID_FIELD,
    BUNDLE_SPONSOR_DESCRIPTION_FIELD,
    BUNDLE_SPONSOR_IMAGE_BASE64_FIELD,
    BUNDLE_SPONSOR_IMAGE_URL_FIELD,
    BUNDLE_SPONSOR_TITLE_FIELD,
    BUNDLE_STATUS_FIELD,
    BUNDLE_SUBSCRIPTION_AUTO_RENEW_FIELD,
    BUNDLE_SUBSCRIPTION_DURATION_FIELD,
    BUNDLE_SUBSCRIPTION_FEE_FIELD,
    BUNDLE_TMODES_FIELD, BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD,
    TMODE_DISCOUNT_POINTS_FIELD,
    TMODE_HIDE_COST_FIELD,
    TMODE_MODE_FIELD,
    TMODE_NOTIFICATION_EMAIL_FIELD,
    TMODE_OFFER_DESCRIPTION_FIELD,
    TMODE_POINTS_PER_COST_FIELD,
    TMODE_REWARD_PER_COST_FIELD,
    TMODE_SYMBOLIC_REWARD_PER_COST_FIELD
} from "../data/BundlesSchema";
import DateTimeUtil from "../util/DateTimeUtil";
import { Moment } from "moment-timezone";
import Initiative from "./Initiative";

@JsonObject
class TMode {
    @JsonProperty(TMODE_MODE_FIELD)
    public mode: string = "";
    @JsonProperty(TMODE_POINTS_PER_COST_FIELD, Number, true)
    public pointsPerCost: number | undefined = undefined;
    @JsonProperty(TMODE_DISCOUNT_POINTS_FIELD, Number, true)
    public discountPoints: number | undefined = undefined;
    @JsonProperty(TMODE_REWARD_PER_COST_FIELD, Number, true)
    public rewardPerCost: number | undefined = undefined;
    @JsonProperty(TMODE_HIDE_COST_FIELD, Number, true)
    public hideCost: number | undefined = undefined;
    @JsonProperty(TMODE_SYMBOLIC_REWARD_PER_COST_FIELD, Number, true)
    public symbolicRewardPerCost: number | undefined = undefined;
    @JsonProperty(TMODE_NOTIFICATION_EMAIL_FIELD, String, true)
    public notificationEmail: string | undefined = undefined;
    @JsonProperty(TMODE_OFFER_DESCRIPTION_FIELD, String, true)
    public offerDescription: string | null = null;
}


export enum BundleStatus {
    ACTIVE = "active",
    INACTIVE = "inactive"
}

@JsonConverter
export class BundleStatusConverter implements JsonCustomConvert<BundleStatus> {
    public serialize(bundleStatus: BundleStatus): any {
        return bundleStatus;
    }
    public deserialize(bundleStatusS: any): BundleStatus {
        return bundleStatusS;
    }
}

export const PAYGO_BUNDLE_ID = "paygo";

@JsonObject
class BundleConfig {
    @JsonProperty(BUNDLE_SUBSCRIPTION_FEE_FIELD, Number, true)
    public subscriptionFee: number = 0;
    @JsonProperty(BUNDLE_SUBSCRIPTION_DURATION_FIELD, Number, true)
    public subscriptionDurationInDays: number = 30;    // In days, how long it will last, when present. No value means one month.
    @JsonProperty(BUNDLE_STATUS_FIELD, BundleStatusConverter, true)
    public status: BundleStatus = BundleStatus.ACTIVE;
    @JsonProperty(BUNDLE_CURRENT_USER_COUNT_FIELD, Number, true)
    public currentUserCount: number = 0;
    @JsonProperty(BUNDLE_FUTURE_USER_COUNT_FIELD, Number, true)
    public futureUserCount: number = 0;
}

@JsonObject
class Bundle {
    @JsonProperty(BUNDLE_NAME_FIELD, String, true)
    public name: string | undefined = undefined;
    @JsonProperty(BUNDLE_TMODES_FIELD, [TMode], true)
    public tmodes: TMode[] | undefined = undefined;
    // Need to use null instead of undefined to allow to remove field value on update, since when serializing
    // json2typescript explicitly put the null/s, but ignores the undefined/s, see doc:
    // "When you try to serialize a TypeScript object to a JSON object ... If the property is not defined in the class
    // and optional, it will not be added to the JSON object."
    @JsonProperty(BUNDLE_NOTE_FIELD, String, true)
    public note: string | null = null;
    @JsonProperty(BUNDLE_RESTRICTED_ONLY_TO_FIELD, [String], true)
    public restrictedOnlyTo?: string[] = undefined;
    @JsonProperty(BUNDLE_ID_FIELD, String)
    public id: string = "";
    @JsonProperty(BUNDLE_SHORT_ID_FIELD, String, true)
    public shortId: string = "";
    @JsonProperty(BUNDLE_SUBSCRIPTION_AUTO_RENEW_FIELD, Boolean, true)
    public subscriptionAutoRenew?: boolean = undefined;    // Whether it will auto-renew at the end of the subscription or not    
    @JsonProperty(BUNDLE_APPLIED_TIMESTAMP_FIELD, Number, true)
    public appliedTimestamp?: number = undefined;       // When this bundle applies (in millis). Only for bundle applied to user
    @JsonProperty(BUNDLE_EXPIRATION_TIMESTAMP_FIELD, Number, true)
    public expirationTimestamp?: number = undefined;    // When this bundle will expire (in millis). Only for bundle applied to user
    @JsonProperty(BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD, Number, true)
    public toBeAppliedTimestamp?: number = undefined;
    @JsonProperty(BUNDLE_CONFIG_FIELD, [BundleConfig], true)
    public bundleConfig: BundleConfig[] = [];
    @JsonProperty(BUNDLE_PURCHASE_ONCE_FIELD, Boolean, true)
    public subscriptionPurchaseOnce?: boolean = undefined;
    @JsonProperty(BUNDLE_CLIENT_ID_FIELD, String, true)
    public clientId?: string = undefined;
    @JsonProperty(BUNDLE_SPONSOR_TITLE_FIELD, String, true)
    public sponsorTitle?: string = undefined;
    @JsonProperty(BUNDLE_SPONSOR_DESCRIPTION_FIELD, String, true)
    public sponsorDescription?: string = undefined;
    @JsonProperty(BUNDLE_SPONSOR_IMAGE_URL_FIELD, String, true)
    public sponsorImageUrl?: string = undefined;
    @JsonProperty(BUNDLE_SPONSOR_IMAGE_BASE64_FIELD, String, true)
    public sponsorBase64Img?: string | null = undefined;    // Value null means deleting the image. This field is just relevant for creation and update, and it never comes from the BE.
    @JsonProperty(BUNDLE_INITIATIVE_FIELD, Initiative, true)
    public initiative?: Initiative = undefined;

    // This is not part of the bundle (coming from the BE), but it is used to store the image file when editing the sponsor image.
    // Value null means deleting the image.
    public sponsorImageFile?: File | null;

    public isPayGo(): boolean {
        return this.id === PAYGO_BUNDLE_ID;
    }

    get appliedMoment(): Moment | undefined {
        return this.appliedTimestamp && DateTimeUtil.momentTZTime(this.appliedTimestamp * 1000);
    }

    get expirationMoment(): Moment | undefined {
        return this.expirationTimestamp && DateTimeUtil.momentTZTime(this.expirationTimestamp * 1000);
    }

    get toBeAppliedMoment(): Moment | undefined {
        return this.toBeAppliedTimestamp && DateTimeUtil.momentTZTime(this.toBeAppliedTimestamp * 1000);
    }

    get currentUserCount(): number {
        return this.bundleConfig.reduce((count, config) => count + config.currentUserCount, 0);
    }

    get futureUserCount(): number {
        return this.bundleConfig.reduce((count, config) => count + config.futureUserCount, 0);
    }

    get userCount(): number {
        return this.currentUserCount + this.futureUserCount;
    }

    /**
     * Now this is a per-config value, so probably will not make much sense to use this, except to display the aggregated value in
     * bundles table view.
     */
    get status(): BundleStatus {
        return this.bundleConfig.some(config => config.status === BundleStatus.ACTIVE) ? BundleStatus.ACTIVE : BundleStatus.INACTIVE;
    }
}

export default Bundle;
export { TMode, BundleConfig };