import * as moment from 'moment-timezone';
import { Moment, Calendar } from "moment-timezone";
import { adminProfile } from "../account/AdminProfile";

class DateTimeUtil {

    /**
     * @deprecated in favor of dateFormat()
     */
    public static readonly DATE_FORMAT = "DD MMM YYYY";
    /**
     * @deprecated in favor of timeFormat()
     */
    public static readonly TIME_FORMAT = "h:mm A";
    public static readonly TIME_FORMAT_TRIP = "h:mma";
    /**
     * @deprecated
     */
    public static readonly DATE_TIME_FORMAT = DateTimeUtil.DATE_FORMAT + ", " + DateTimeUtil.TIME_FORMAT;
    public static readonly HTML5_DATE_FORMAT = "YYYY-MM-DD";
    public static readonly HTML5_TIME_FORMAT = "HH:mm";
    public static readonly HTML5_DATE_TIME_FORMAT = "YYYY-MM-DDTHH:mm";

    public static timeFormat = () => {
        return "h:mm A";
    };

    /**
     * Considered using momentjs [i18n](https://momentjs.com/docs/#/i18n/), and even
     * [customization](https://momentjs.com/docs/#/customization/), but declined since:
     * - don't want to get tied to momentjs since I may replace it
     * - for specific formats, not contemplated by [long date formats](https://momentjs.com/docs/#/customization/long-date-formats/)
     * or [calendar formats](https://momentjs.com/docs/#/customization/calendar/) I need anyway to 'switch' through locale strings.
     */
    public static dateFormat = () => {
        return adminProfile.locale === 'en-US' ? "MMM DD YYYY" : "DD MMM YYYY";
    };

    public static dateFormatWithDay = () => {
        return adminProfile.locale === 'en-US' ? "ddd MM/DD" : "ddd DD/MM";
    };

    public static weekdayDateFormat = () => {
        return adminProfile.locale === 'en-US' ? "dddd MMM DD YYYY" : "dddd DD MMM YYYY";
    };

    public static shortWeekdayDateFormat = () => {
        return adminProfile.locale === 'en-US' ? "ddd MMM DD YYYY" : "ddd DD MMM YYYY";
    };

    public static dayMonthFormat = () => {
        return adminProfile.locale === 'en-US' ? "MMM DD" : "DD MMM";
    };

    public static dayLongMonthFormat = () => {
        return adminProfile.locale === 'en-US' ? "MMMM DD" : "DD MMMM";
    };

    public static formatRelativeDay(moment: Moment, formatWithDay: string, options: { partialReplace?: string, timezone?: string } = {}): string {
        const { partialReplace, timezone } = options;
        const calendarFormat = {
            sameDay: partialReplace ? formatWithDay.replace(partialReplace, "[Today]") : "[Today]",
            nextDay: partialReplace ? formatWithDay.replace(partialReplace, "[Tomorrow]") : "[Tomorrow]",
            nextWeek: formatWithDay,
            lastDay: partialReplace ? formatWithDay.replace(partialReplace, "[Yesterday]") : "[Yesterday]",
            lastWeek: formatWithDay,
            sameElse: formatWithDay,
        };
        return moment.calendar(DateTimeUtil.getNow(timezone), calendarFormat);
    };

    public static getNow(timezone?: string) {
        return moment.tz(timezone ?? adminProfile.timezone);
    }

    public static isOnDifferentTimezone(timezone: string): boolean {
        return DateTimeUtil.getNow(timezone).utcOffset() !== DateTimeUtil.getNow(adminProfile.localTimezone).utcOffset();
    }

    public static getNowDate() {
        return moment.tz(adminProfile.timezone).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    }

    public static toJustDate(moment: Moment): Moment {
        // Immutable
        return moment.clone().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);
    }

    public static moment(timeS: string | number, format?: string): Moment {
        return moment(timeS, format);
    }

    public static momentTZ(timeS: string, timezone: string, format?: string): Moment {
        return format ? moment.tz(timeS, format, timezone) : moment.tz(timeS, timezone);
    }

    public static momentDefaultTZ(timeS: string, format?: string) {
        return format ? moment.tz(timeS, format, adminProfile.timezone) : moment.tz(timeS, adminProfile.timezone);
    }

    public static momentTZTime(time: number, timezone?: string) {
        return moment.tz(time, timezone ?? adminProfile.timezone);
    }

    public static calendar(referenceTime: Moment | undefined, format: any): Calendar {
        return moment().calendar(referenceTime, format);
    }

    public static durationToBriefString(durationInMinutes: number, space: boolean = true, decimal: boolean = false): string {
        durationInMinutes = Math.floor(durationInMinutes);
        if (durationInMinutes === 0) {
            return "0" + (space ? " " : "") + "mins";
        }
        let totalMinutes = durationInMinutes;
        const days = Math.floor(totalMinutes / (60 * 24));
        const twiceTheDays = Math.ceil((2 * totalMinutes / (60 * 24)));
        totalMinutes -= days * 24 * 60;
        const justHours = Math.floor(totalMinutes / 60);
        const twiceTheHours = Math.ceil(2 * (totalMinutes) / 60);
        let justMinutes = totalMinutes % 60;
        if (totalMinutes === 60) {
            justMinutes = 60;
        }

        let result = "";

        if (days > 0) {
            if (decimal && (days > 1 || justHours % 12 === 0)) {
                return twiceTheDays % 2 === 0 ? result + days + "d" : result + (twiceTheDays / 2).toFixed(1) + "d";
            }
            result += days + (space ? " " : "") + "d";
        }

        if (totalMinutes > 60) {
            if (result.length !== 0) {
                result += " ";
            }
            if (decimal && (justHours > 1 || justMinutes % 30 === 0)) {
                return twiceTheHours % 2 === 0 ? result += Math.floor(twiceTheHours / 2) + (space ? " " : "") + "h" :
                    result + (twiceTheHours / 2).toFixed(1) + (space ? " " : "") + "h";
            }
            result += justHours + (space ? " " : "") + "h";
        }

        if (result.length !== 0) {
            result += " ";
        }
        result += justMinutes + (space ? " " : "") + "min";

        return result;
    }

    public static timezoneToGMTString(timezone: string): string {
        return this.toGMTString(DateTimeUtil.getNow(timezone));
    }

    public static toGMTString(moment: Moment): string {
        return `(GMT${moment.utcOffset() / 60})`;
    }

    public static minutesToDepartToString(minutes: number) {
        minutes = Math.floor(minutes);
        if (0 <= minutes && minutes < 2) {
            return "Now";
        } else if (-60 <= minutes && minutes < 60) {
            return Math.floor(minutes) + "min";
        } else if (-24 * 60 <= minutes && minutes < 24 * 60) {
            const durationInHours = Math.floor(minutes / 60);
            return durationInHours + "h";
        } else {
            const durationInDays = Math.floor(minutes / (24 * 60));
            return durationInDays + "d";
        }
    }

    public static millisToSeconds(millis: number): number {
        return Math.floor(millis / 1000);
    }

    public static html5ToMillis(dateTimeHtml5: string, timezone: string = adminProfile.timezone): number {
        return this.momentTZ(dateTimeHtml5, timezone).valueOf();
    }

    public static html5FromMillis(dateTimeMillis: number, timezone?: string): string {
        return this.momentTZTime(dateTimeMillis, timezone).format(this.HTML5_DATE_TIME_FORMAT);
    }

    /**
     * To use as value prop on datetime-local input so it's displayed as if the user's local timezone were that one
     * in the ISO date. E.g. 2021-06-21T04:23:21-07:00 would be displayed as 2021-06-21T04:23, and not as
     * 2021-06-21T08:23 if the user is in GMT-3.
     * To convert updated value (through onChange) back to a ISO format we must remember offset part discarded when
     * doing isoToHtml5 (the '-07:00' in the example), and then just append it again to the updated value.
     * We also need to add the seconds part.
     */
    public static isoToHtml5(iso: string) {
        return iso.substring(0, 16);
    }

    public static isoToDefaultTimezone(iso: string): string {
        return moment(iso).tz(adminProfile.timezone).format();
    }

    public static isoToMomentDefaultTimezone(iso: string): Moment {
        return moment(iso).tz(adminProfile.timezone);
    }

    public static isoToMomentTimezone(iso: string, timezone?: string): Moment {
        return moment(iso).tz(timezone ?? adminProfile.timezone);
    }

    public static pastToFuture(time: Moment, strategy: "toNow" | "sameTime" | "recurrence"): Moment {
        const now = DateTimeUtil.getNow();
        if (time.isAfter(now)) {    // In the future
            return time;
        }
        switch (strategy) {
            case "toNow":
                return now;
            case "sameTime":
                let result = now.clone().set('hour', time.hour()).set('minute', time.minute()).set('second', time.second()).set('millisecond', time.millisecond());
                if (!result.isAfter(now)) {
                    result = result.add(1, 'day');
                }
                return result;
            case "recurrence":
                if (now.valueOf() - time.valueOf() < 60 * 60 * 1000) {    // Less than 1 hour ago
                    return now;
                } else if (now.valueOf() - time.valueOf() < 24 * 60 * 60 * 1000) {    // Less than 24 hours ago
                    return time.add(1, 'day');
                } else if (now.valueOf() - time.valueOf() < 7 * 24 * 60 * 60 * 1000) {    // Less than 7 days ago
                    return time.add(1, 'week');
                } else if (now.valueOf() - time.valueOf() < 30 * 24 * 60 * 60 * 1000) {    // Less than 30 days ago
                    return time.add(1, 'month');
                } else {
                    return now;
                }
        }

    }

}

export default DateTimeUtil;

(window as any).DateTimeUtil = DateTimeUtil;