import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStyles, WithStyles } from "../css/StyleHelper";
import Organization from '../model/Organization';
import classNames from 'classnames';
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import { ReactComponent as IconOrg } from '../images/ic-org.svg';
import { adminProfile } from '../account/AdminProfile';
import { i18n } from '../i18n/TKI18nConstants';
import { orgSelectorJss } from './OrgSelector.jss';

type IStyle = ReturnType<typeof orgSelectorJss>

interface IProps extends WithStyles<IStyle> {
    values: Organization[];
    value?: Organization;
    onChange: (value: Organization) => void;
}

type OrgNode = { org: Organization, children: OrgNode[] };

/**
 * Versions that just include organizations that the user has access to.
 */
function insertInTree2(org: Organization, tree: OrgNode): OrgNode {
    const child = tree.children.find(child => org.path.startsWith(child.org.path))    // find a child that is an ancestor of node.
    if (child) {
        insertInTree2(org, child);
    } else {
        tree.children.push({ org, children: [] });
    }
    return tree;
}

function orgsAsTree2(orgs: Organization[]): OrgNode {
    orgs.sort((org1, org2) => org1.path.localeCompare(org2.path)); // Sort by path lexicographically, which ensures tree nodes are in pre-order    
    return orgs.reduce((tree: OrgNode | undefined, org: Organization) => {
        if (!tree) {
            return { org, children: [] };
        }
        if (!org.fullAccess) {
            return tree;
        }
        return insertInTree2(org, tree);
    }, undefined)!;
}


/**
 * @param tree 
 * @returns a version of the tree without non direct access nodes with a single child.
 * Could alternatively simply remove (hide from selector) non direct access nodes, and move children 1 level up (replace node by children).
 */
function removeRedundantNodes(tree: OrgNode): OrgNode {
    if (tree.children.length === 0) {
        return tree;
    }
    tree.children = tree.children.map(child => removeRedundantNodes(child));
    if (!tree.org.fullAccess && tree.children.length === 1) {
        return tree.children[0];
    }
    return tree;
}

function sortTree(tree: OrgNode): OrgNode {
    tree.children.sort((a, b) => a.org.name.localeCompare(b.org.name));
    tree.children.forEach(child => sortTree(child));
    return tree;
}

function orgLabel(org: Organization) {
    const isRoot = org.path.length === 4;
    return isRoot ? i18n.t("All.organizations") : org.name;
}


const OrgSelector: React.FunctionComponent<IProps> = (props: IProps) => {

    const { values, value = values[0], onChange, classes, injectedStyles } = useStyles(props, orgSelectorJss);

    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onClick = ({ target }: MouseEvent): void => {
            if (wrapperRef.current && !wrapperRef.current.contains(target as Node)) {
                setShowPopup(false);
            }
        };
        window.addEventListener('click', onClick);
        return () => window.removeEventListener('click', onClick);
    }, []);

    // const orgsTree = useMemo(() => sortTree(removeRedundantNodes(orgsAsTree2(values))), [values]);
    // Don't remove redundant nodes for admin users, since if so, if the admin has access to just one client app,
    // the root, Feonix, will be removed so "All Departments" won't appear in the organizations selector, and
    // the client app will be the root of the selector.
    // TODO: This won't be a problem anymore when sending a client app organization in a filter considers it as a client app,
    // instead of as a regular organization. In the meantime I could do that FE side, so if the organizations is a client app, then I should
    // set the clientId filter, instead of the organization id filter. But in any case, I should remove the client selector.
    const orgsTree = useMemo(() => sortTree(adminProfile.isOrgUser ? removeRedundantNodes(orgsAsTree2(values)) : orgsAsTree2(values)), [values]);
    function renderTree(node: OrgNode): React.ReactNode {
        const level = (node.org.path.length / 4) - 1;
        return (
            <>
                <div
                    className={classNames(classes.treeItem, value === node.org && classes.selected)}
                    key={node.org.path}
                    style={{
                        marginLeft: (level * 10) + 'px'
                    }}
                    onClick={() => {
                        onChange(node.org);
                        setShowPopup(false);
                    }}
                >
                    {orgLabel(node.org)}
                </div>
                {node.children.map(child => renderTree(child))}
            </>
        )
    }
    const [showPopup, setShowPopup] = useState<boolean>(false);

    return (
        <div className={classNames(classes.wrapper, showPopup && classes.popupOpen)} ref={wrapperRef}>
            <button
                className={classNames(classes.main, showPopup && classes.open, values.length === 0 && classes.cursorDefault)}
                onClick={values.length > 0 ? (() => setShowPopup(!showPopup)) : undefined}
                disabled={orgsTree.children.length === 0}
            >
                <IconOrg className={classes.icon} />
                <div className={classes.name}>{orgLabel(value)}</div>
                {values.length > 0 &&
                    <IconTriangleDown className={classNames(classes.angleDown, showPopup && classes.up)} />}
            </button>
            {showPopup &&
                <div className={classes.popup}>
                    <div className={classes.tree}>
                        {renderTree(orgsTree)}
                    </div>
                </div>}
        </div>
    )
};

export default OrgSelector;