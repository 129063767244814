import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";

export const scheduleBookingTrackJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.alignCenter
    },
    step: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignCenter,
        // '&:first-child $line:first-child, &:last-child $line:last-child': {
        //     visibility: 'hidden'
        // }
    },
    current: {
        '& $circle': {
            border: '3px solid ' + theme.colorPrimary,
            margin: '3px 0'
        },
        '& $line:first-child': {
            borderColor: theme.colorPrimary
        }
    },
    past: {
        '& $circle': {
            borderColor: theme.colorPrimary,
        },
        '&:not($skip) $circle': {
            background: theme.colorPrimary
        },
        '&$scheduled $circle': {
            background: theme.colorPrimary
        },
        '& $line': {
            borderColor: theme.colorPrimary,
        }
    },
    skip: {
        '& $circle': {
            opacity: '.5'
        },
        '& $label': {
            opacity: '.5'
        }
    },
    scheduled: {

    },
    lineCircle: {
        ...genJss.flex,
        ...genJss.alignCenter
    },
    circle: {
        height: '14px',
        width: '14px',
        border: '2px solid ' + black(2),
        margin: '4px 0px',
        ...genJss.borderRadius(50, '%'),
        transition: 'border 200ms ease'
    },
    line: {
        width: '50px',  // TODO: make this responsive: with relative to available size.
        border: '2px solid ' + black(2),
        transition: 'border 200ms ease'
    },
    label: {
        ...theme.textColorGray
    }
});