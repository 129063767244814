import { genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const editInitiativeViewJss = (theme: Theme) => ({
    narrowForm: {
        '& label': {
            width: '250px'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colorPrimary
        },
        ...genJss.grow
    },
    narrowFormGroup: {
        maxWidth: '600px'
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    }
});