import React from 'react';
import { genJss } from "../css/gen.jss";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { Subtract } from 'utility-types';

const TooltipStyled = (props: TooltipProps) =>
    <Tooltip
        {...props}
        slotProps={{
            tooltip: {
                sx: {
                    ...genJss.fontS
                }
            }
        }}
    />

const cursorHelp = {
    cursor: 'help'
};

const MDashTooltip = (props: Subtract<TooltipProps, { children: React.ReactElement<any> }> & { children: React.ReactElement<any> | string }) => {
    const { children, ...tooltipProps } = props;
    return (
        <TooltipStyled
            placement={"top"}
            enterDelay={500}
            leaveDelay={1}  // To avoid hiding and re-displaying when moving hover the tooltip (see interactive prop doc in https://material-ui.com/api/tooltip/)
            // leaveDelay={100000}
            {...tooltipProps}
            // If children is a string, surround it with a div and put the help cursor style. In any other case help cursor must
            // be put by the client component, outside, if appropriate. Notice that when, for instance, children is a button, then
            // we don't want a help cursor, but a pointer cursor.
            children={typeof children === 'string' ?
                <div style={cursorHelp}>{children}</div> :
                children as React.ReactElement<any>}
        />
    );
};

export default MDashTooltip;