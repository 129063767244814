import React, { useContext, useState } from 'react';
import { WithClasses, withStyles } from "../css/StyleHelper";
import { tableConfigJss as tableConfigViewJss } from "./TableConfigView.jss";
import ModalView from "./ModalView";
import { AppContext } from "../app/App";
import classNames from 'classnames';
import Util from "../util/Util";
import { TableConfig } from '../account/AdminProfile';
import { genClassNames } from 'tripkit-react';
import { ReactComponent as IconArrowUp } from "../images/ic-arrow-up.svg";

type IStyle = ReturnType<typeof tableConfigViewJss>;

interface IProps<ID> extends WithClasses<IStyle> {
    tableId: string;
    availableColumns: { id: ID, name: string, available?: boolean }[];
    defaultColumns: ID[];
    storedColumns?: ID[];
    onRequestClose?: () => void;
}

const TableConfigView = <ID extends string>(props: IProps<ID>) => {
    const { tableId, availableColumns, defaultColumns, storedColumns, onRequestClose, classes, appClasses } = props;
    const { profile, onProfileChange } = useContext(AppContext);
    const [update, setUpdate] = useState<TableConfig<ID>>(({ visibleCols: storedColumns ?? defaultColumns, availableCols: availableColumns.filter(col => col.available !== false).map(col => col.id) }) as TableConfig<ID>)
    const [availableSelection, setAvailableSelection] = useState<ID[]>([]);
    const [visibleSelection, setVisibleSelection] = useState<ID[]>([]);
    const visibleColumns = update.visibleCols;
    const hiddenColumns = update.availableCols.filter(col => !visibleColumns.includes(col)).sort();
    function onAddRemoveClick(add: boolean) {
        if (add) {
            setUpdate(update => ({
                ...update,
                visibleCols: update.visibleCols.concat(availableSelection)
            }));
            setAvailableSelection([]);
        } else {
            setUpdate(update => ({
                ...update,
                visibleCols: update.visibleCols.filter(col => !visibleSelection.includes(col))
            }));
            setVisibleSelection([]);
        }
    }
    function onUpDownClick(up: boolean, double?: boolean) {
        setUpdate(update => {
            const visibleCols = update.visibleCols.slice();
            let newVisibleCols = update.visibleCols.slice();
            if (!up) {
                visibleCols.reverse();
                newVisibleCols.reverse();
            }
            if (double) {
                newVisibleCols = visibleSelection.concat(visibleCols.filter(col => !visibleSelection.includes(col)));
            } else {
                visibleCols.forEach((vCol, i) => {
                    if (visibleSelection.includes(vCol)) {
                        const iTarget = Math.max(i - 1, 0);
                        if (visibleSelection.includes(newVisibleCols[iTarget])) {
                            return;
                        }
                        const swap = newVisibleCols[iTarget];
                        newVisibleCols[iTarget] = newVisibleCols[i];
                        newVisibleCols[i] = swap;
                    }
                });
            }
            if (!up) {
                newVisibleCols.reverse();
            }
            return ({
                ...update,
                visibleCols: newVisibleCols
            });
        })
    }
    function onResetClick() {
        setUpdate(update => {
            return ({
                ...update,
                visibleCols: defaultColumns
            });
        })
    }
    function onApply() {
        const profileUpdate = Util.deepClone(profile);
        profileUpdate.local.tableConfigById.set(tableId, update);
        onProfileChange(profileUpdate);
        onRequestClose?.();
    }
    return (
        <ModalView
            title={"Table Config"}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                <div className={classes.columnSelector}>
                    <div className={classNames(genClassNames.flex, genClassNames.column, genClassNames.alignCenter)}>
                        Available columns
                        <select
                            multiple
                            className={classes.select}
                            onChange={e => setAvailableSelection(Array.from(e.target.selectedOptions).map(opt => opt.value as ID))}
                        >
                            {hiddenColumns.map(col =>
                                <option value={col} key={col}>
                                    {availableColumns.find(acol => acol.id === col)?.name}
                                </option>)}
                        </select>
                    </div>
                    <div className={classNames(genClassNames.flex, genClassNames.column, genClassNames.alignCenter)}>
                        Visible columns
                        <div className={classNames(genClassNames.flex, genClassNames.alignCenter, classes.visibleSelector)}>
                            <div className={classNames(genClassNames.flex, genClassNames.column, classes.buttons)}>
                                <button onClick={() => onAddRemoveClick(true)}><IconArrowUp style={{ transform: 'rotate(90deg)' }} /></button>
                                <button onClick={() => onAddRemoveClick(false)}><IconArrowUp style={{ transform: 'rotate(270deg)' }} /></button>
                            </div>
                            <select
                                multiple
                                className={classes.select}
                                onChange={e => setVisibleSelection(Array.from(e.target.selectedOptions).map(opt => opt.value as ID))}
                            >
                                {visibleColumns.map(col =>
                                    <option value={col} key={col}>
                                        {availableColumns.find(acol => acol.id === col)?.name}
                                    </option>)}
                            </select>
                            <div className={classNames(genClassNames.flex, genClassNames.column, classes.buttons)}>
                                <button onClick={() => onUpDownClick(true, true)}><IconArrowUp /><IconArrowUp style={{ marginLeft: '-6px' }} /></button>
                                <button onClick={() => onUpDownClick(true)}><IconArrowUp /></button>
                                <button onClick={() => onUpDownClick(false)}><IconArrowUp style={{ transform: 'rotate(180deg)' }} /></button>
                                <button onClick={() => onUpDownClick(false, true)}><IconArrowUp style={{ transform: 'rotate(180deg)' }} /><IconArrowUp style={{ transform: 'rotate(180deg)', marginLeft: '-6px' }} /></button>
                            </div>
                        </div>
                        <button className={classes.resetBtn} onClick={onResetClick}>Reset</button>
                    </div>
                </div>
                <div className={classes.footer}>
                    <button className={appClasses.buttonCancel} onClick={onRequestClose}>
                        Cancel
                    </button>
                    <button className={appClasses.buttonAdd} onClick={onApply}>
                        Apply
                    </button>
                </div>
            </div>
        </ModalView>
    )
};

export default withStyles(TableConfigView, tableConfigViewJss)