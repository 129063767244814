import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const changeBundleViewJss = (theme: Theme) => ({
    bundleTable: {
        width: 'initial!important',
        '& th:nth-child(1)': {
            width: '300px'
        },
        '& th:nth-child(2)': {
            width: '150px'
        },
        '& th:nth-child(3)': {
            width: '150px'
        }
    },

    form: {
        '& $select .select__control': {
            ...genJss.borderRadius(5),
            border: 'none',
            backgroundColor: '#f6f6f6',
            lineHeight: '40px',
            fontSize: '16px',
            padding: '0 10px'
        }
    },

    select: {
        width: '100%',
        paddingRight: '10px',
        '& .select__value-container': {
            padding: '0',
            display: 'flex'
        },
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        }
    },

    buttonsPanel: {
        '& button': {
            height: '50px',
            minWidth: '120px',
            marginLeft: '30px'
        }
    },

    startWarnRow: {
        verticalAlign: 'top!important'
    },

    startWarn: {
        fontSize: '17px',
        padding: '0 5px'
    }
});