import { JsonConverter, JsonObject, JsonProperty } from "json2typescript/src/json2typescript/json-convert-decorators";
import { JsonCustomConvert } from "json2typescript/src/json2typescript/json-custom-convert";
import { FeatureCollection, MultiPolygon } from "tripkit-react/node_modules/@types/geojson";

export const PROVIDER_ID_FIELD = "id";
export const PROVIDER_NAME_FIELD = "name";
export const PROVIDER_CLIENT_ID_FIELD = "clientId";
export const PROVIDER_CONFIG_FIELD = "config";
export const PROVIDER_COVERAGE_FIELD = "coverage";
export const PROVIDER_INFO_FIELD = "provider";
export const PROVIDER_PURPOSES_FIELD = "purposes";
export const PROVIDER_MOBILITY_OPTIONS_FIELD = "mobilityOptions";
export const PROVIDER_PURPOSE_ID_FIELD = "Id";
export const PROVIDER_PURPOSE_NAME_FIELD = "name";
export const PROVIDER_PURPOSE_TRANSLATIONS_FIELD = "translations";
export const PROVIDER_MOBILITY_OPTION_ID_FIELD = "Id";
export const PROVIDER_MOBILITY_OPTION_NAME_FIELD = "name";
export const PROVIDER_MOBILITY_OPTION_TRANSLATIONS_FIELD = "translations";
export const PROVIDER_MOBILITY_OPTION_CODE_NUMBER_FIELD = "codeNumber";
export const PROVIDER_MOBILITY_OPTION_CODE_CATEGORY_FIELD = "codeCategory";
interface ProviderConfig {
    url: string;
    code: string;
    type: string;
    enabledByDefault: boolean;
    exclusive: boolean;
    hideTimes: boolean;
    authentication: string;
    membersOnly: boolean;
    mayChangeTrips: boolean;
    alwaysEnableRealTime: boolean;
    useProviderDetailsWebsite: boolean;
    disabled?: boolean;
}

export interface IProviderPricingRule {
    planId: string;
    name: string;
    description: string;
    fare: {
        parts: {
            name: string;
            currencyCode: string;
            type: string;
            amount: number;
            unitType: string;
            units: number;
            scales?: {
                from: number;
                to: number;
                type: string;
            }[];
            zoneIds?: string[];
        }[];
    };
}

export interface IProviderProduct {
    title: string;
    id: string;
    bookingOption: {
        bookingRules: {
            weekdays: string;
            prebooking: {
                minHoursBefore: number;
            };
            workingHours: {
                from: number;
                to: number;
                type: string;
                proportional: boolean;
            }[];
        }[];
    };
    planIds: string[];
    planId: string;
    defaultPlanId: string;
}

export interface IProviderZone {
    id: string;
    geometry: MultiPolygon;
}

export interface IProviderCoverageProperties {
    products: IProviderProduct[];
    pricingRules: IProviderPricingRule[];
    zones?: IProviderZone[];
}

export interface ProviderCoverage extends FeatureCollection<MultiPolygon, IProviderCoverageProperties> { }

export interface IColor {
    red: number;
    blue: number;
    green: number;
}
interface ProviderInfo {
    companyInfo: {
        name: string;
        address: string;
        phone: string;
        website: string;
        color: IColor;
    };
    modesEnabled: string[];
    preferGenericMode: boolean;
    userPhoneRequired: boolean;
    billingEnabled: boolean;
}
@JsonObject
export class Purpose {
    @JsonProperty(PROVIDER_PURPOSE_ID_FIELD, String, true)
    public id: string = "";
    @JsonProperty(PROVIDER_PURPOSE_NAME_FIELD, String, true)
    public name: string = "";
    @JsonProperty(PROVIDER_PURPOSE_TRANSLATIONS_FIELD, String, true)
    public translations: string = "";
}

@JsonObject
export class MobilityOption {
    @JsonProperty(PROVIDER_MOBILITY_OPTION_ID_FIELD, String, true)
    public id: string = "";
    @JsonProperty(PROVIDER_MOBILITY_OPTION_NAME_FIELD, String, true)
    public name: string = "";
    @JsonProperty(PROVIDER_MOBILITY_OPTION_TRANSLATIONS_FIELD, String, true)
    public translations: string = "";
    @JsonProperty(PROVIDER_MOBILITY_OPTION_CODE_NUMBER_FIELD, String, true)
    public codeNumber: string = "";
    @JsonProperty(PROVIDER_MOBILITY_OPTION_CODE_CATEGORY_FIELD, String, true)
    public codeCategory: string = "";
}

@JsonConverter
export class AWSJSONConverter implements JsonCustomConvert<object> {
    public serialize(value: object): string {
        return JSON.stringify(value);
    }
    public deserialize(valueS: string): object {
        try {
            return JSON.parse(valueS);
        } catch (e) {
            return {};
        }
    }
}

@JsonObject
class Provider {
    @JsonProperty(PROVIDER_ID_FIELD, String, true)
    public id: string = "";
    @JsonProperty(PROVIDER_NAME_FIELD, String, true)
    public name: string = "";
    @JsonProperty(PROVIDER_CLIENT_ID_FIELD, String, true)
    public clientId: string = "";
    @JsonProperty(PROVIDER_CONFIG_FIELD, AWSJSONConverter, true)
    public config: ProviderConfig = {} as ProviderConfig;
    @JsonProperty(PROVIDER_COVERAGE_FIELD, AWSJSONConverter, true)
    public coverage: ProviderCoverage = {} as ProviderCoverage;
    @JsonProperty(PROVIDER_INFO_FIELD, AWSJSONConverter, true)
    public info: ProviderInfo = {} as ProviderInfo;
    @JsonProperty(PROVIDER_PURPOSES_FIELD, [Purpose], true)
    public purposes?: Purpose[] = undefined;    // undefined means that any purpose is allowed
    @JsonProperty(PROVIDER_MOBILITY_OPTIONS_FIELD, [MobilityOption], true)
    public mobilityOptions?: MobilityOption[] = undefined;  // undefined means that any mobility option is allowed

    get isExclusive(): boolean {
        return this.config.membersOnly;
    }

    get modeName(): string {
        return this.info?.companyInfo?.name ?? this.name;
    }

    get isUnschedulable(): boolean {
        return this.config.type === "taxi-like" && !!this.config.url;
    }

    get unschedulableReason(): string {
        return "This trip is being scheduled by an external party";
    }

    get disabled(): boolean {
        return !!this.config.disabled;
    }
}

export default Provider;