import { Theme } from "../css/Theme";
import { genJss, resetJss } from "../css/gen.jss";

export const bundleViewJss = (theme: Theme) => ({

    iconEdit: {
        ...genJss.svgPathFillCurrColor,
        height: '16px',
        width: '16px',
        marginRight: '1ch'
    },

    fieldsGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridRowGap: '50px',
        gridColumnGap: '20px',
        marginBottom: '30px',
        '& div:first-child': {
            marginRight: '100px'
        }
    },

    noteSection: {
        gridColumnStart: '1',
        gridColumnEnd: '3',
        '& $value': {
            textAlign: 'left'
        }
    },

    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },

    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },

    value: {
        ...theme.textColorGray
    },

    sponsorValue: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },

    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },

    sponsorImgPlaceholder: {
        ...resetJss.button,
        border: '1px dashed #ccc',
        height: '100px',
        width: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        position: 'relative',
        '&:hover': {
            borderColor: theme.colorPrimary,
            '& svg path': {
                fill: theme.colorPrimary
            }
        }
    },

    sponsorImgContainer: {
        ...genJss.flex,
        position: 'relative',
        borderRadius: '4px',
        '&:hover svg path': {
            fill: theme.colorPrimary
        }
    },

    sponsorImg: {
        width: '150px',
        height: 'auto',
        maxHeight: '250px',
        borderRadius: '8px',
        minHeight: '90px',
        objectFit: 'contain'
    },

    addImgBtn: {
        ...resetJss.button,
        position: 'absolute',
        bottom: '-5px',
        right: '-10px',
        height: '30px',
        width: '30px',
        padding: '6px',
        background: 'white',
        border: '1px solid lightgray',
        borderRadius: '50%',
        '& svg': {
            width: '100%',
            height: '100%'
        },
        '& svg path': {
            fill: 'gray'
        }
    }

});