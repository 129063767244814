import React, { FunctionComponent, useContext, useEffect, useMemo, useRef } from "react";
import TKRoot from "tripkit-react/dist/config/TKRoot";
import { TKUIConfig } from "tripkit-react/dist/config/TKUIConfig";
import TKUIMapView, { TKUIMapViewClass } from "tripkit-react/dist/map/TKUIMapView";
import { adminProfile } from "../account/AdminProfile";
import { AppContext, geocodingConfig, mapConfig } from "../app/App";
import { useBeforeInitialRender } from "../util/usehooks";
import TripGoApi from "tripkit-react/dist/api/TripGoApi";
import Provider, { IProviderCoverageProperties } from "../model/Provider";
import { Polygon } from "tripkit-react/node_modules/react-leaflet";
import { LatLngExpression } from "tripkit-react/node_modules/leaflet";
import MapUtil from "tripkit-react/dist/util/MapUtil";
import { Feature, MultiPolygon } from "tripkit-react/node_modules/@types/geojson";
import Util from "../util/Util";
import Color from "../util/Color";
import { FARE_PART_HIGHLIGHT_COLOR, PRODUCT_HIGHLIGHT_COLOR } from "./ProviderView";

interface IProps {
    provider: Provider;
    selectedFeature?: Feature<MultiPolygon, IProviderCoverageProperties>;
    selectedZoneIds?: string[];
}

const ProviderMap: FunctionComponent<IProps> = (props: IProps) => {
    const { provider, selectedFeature, selectedZoneIds } = props;
    const { clients } = useContext(AppContext);
    const mapRef = useRef<TKUIMapViewClass>(null);
    useBeforeInitialRender(() => {
        // Set the client id to that one of the booking.
        // Use client ID in path as fallback, but client ID should always come for super app bookings, and also
        // when no client is selected ("All apps") then there won't be any client ID on path, so it's fundamental that
        // the one in booking comes.
        TripGoApi.clientID = provider.clientId;
    });
    const config: TKUIConfig = useMemo(() => ({
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        TKUIMapView: {
            props: () => mapConfig()
        },
        geocoding: geocodingConfig,
        isDarkMode: false
    }), []);
    const polygons = useMemo(() => provider.coverage.features.map(feature => MapUtil.toLeafletMultiPolygon(feature.geometry)), []);
    const zonePolygons: Record<string, LatLngExpression[][][]> = useMemo(() => provider.coverage.features
        .reduce((result, feature) => {
            feature.properties.zones?.forEach(zone => {
                result[zone.id] = MapUtil.toLeafletMultiPolygon(zone.geometry);
            });
            return result;
        }, {}), []);
    useEffect(() => {
        const providerClient = clients?.find(client => client.clientID === provider.clientId);
        if (providerClient) {
            mapRef.current?.fitBounds(MapUtil.createBBoxGeoJson(providerClient.polygon));
        }
    }, [clients]);
    const providerColor = Util.deserialize(provider.info.companyInfo?.color ?? { red: 0, blue: 0, green: 0 }, Color);
    return (
        <TKRoot config={config}>
            <TKUIMapView
                readonly={true}
                hideLocations={true}
                showCurrLocBtn={false}
                ref={mapRef}
            >
                {polygons.map((polygon, i) => {
                    const isSelected = selectedFeature && provider.coverage.features[i] === selectedFeature;
                    return (
                        <Polygon
                            positions={polygon}
                            color={PRODUCT_HIGHLIGHT_COLOR}
                            // color={providerColor.toHex()}
                            fillOpacity={.4}
                            stroke={!!isSelected}
                            weight={2}
                            key={i} />
                    );
                })}
                {selectedZoneIds?.map(zoneId => {
                    const polygon = zonePolygons[zoneId];
                    return (
                        <Polygon
                            positions={polygon}
                            color={FARE_PART_HIGHLIGHT_COLOR}
                            fillOpacity={0.2}
                            stroke={true}
                            key={zoneId} />
                    );
                })}
            </TKUIMapView>
        </TKRoot>
    );
}

export default ProviderMap;