import React from "react";
import View from "./View";

type ViewType = typeof View;

const ViewRestyled: ViewType = (props) => {
    return (
        <View
            {...props}
            styles={(theme, defaultThemed) => ({
                header: {
                    ...defaultThemed.header,
                    borderBottom: 'none',
                    marginBottom: '0'
                }
            })}
        />
    );
}

export default ViewRestyled;