import React, { FunctionComponent } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { locIconStyleOverride } from "./BookingViewHelpers";
import { TKRoot } from "tripkit-react";
import { adminProfile } from "../account/AdminProfile";
import { geocodingConfig, mapConfig } from "../app/App";
import { Theme } from "../css/Theme";
import { scheduleBookingConfirmJss } from "./ScheduleBookingConfirm.jss";
import { useTheme } from "react-jss";
import TKUIFromTo from "tripkit-react/dist/booking/TKUIFromTo";
import FormatUtil from "../util/FormatUtil";
import { ScheduleBookingFormData } from "./ScheduleBookingForm";
import DateTimeUtil from "../util/DateTimeUtil";

type IStyle = ReturnType<typeof scheduleBookingConfirmJss>;

interface IProps extends WithClasses<IStyle> {
    relatedBookingsData: ScheduleBookingFormData[];
    onConfirm: () => void;
    onRequestClose: () => void;
}

const ScheduleBookingConfirm: FunctionComponent<IProps> = (props: IProps) => {
    const { relatedBookingsData, onConfirm, onRequestClose, classes, appClasses } = props;
    const theme = useTheme() as Theme;
    const config = {
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        i18n: {
            locale: adminProfile.locale,
            translations: {} as any
        },
        TKUIMapView: {
            props: () => mapConfig()
        },
        TKUIMapLocationIcon: {
            styles: locIconStyleOverride
        },
        geocoding: geocodingConfig,
        theme: {
            colorPrimary: theme.colorPrimary,
            fontFamily: theme.fontFamily
        },
        isDarkMode: false
    };
    const skippedAll = relatedBookingsData.every(data => data.skip);
    return (
        <TKRoot config={config}>
            <div className={classes.main}>
                {skippedAll ?
                    <div className={classes.empty}>
                        No booking selected to schedule
                    </div> :
                    <div className={classes.reviews}>
                        {relatedBookingsData.filter(data => !data.skip).map((data, i) =>
                            <div className={classes.review} key={i}>
                                <div className={classes.type}>
                                    {FormatUtil.toFirstUpperCase(data.type)}
                                </div>
                                <TKUIFromTo
                                    from={data.booking.from}
                                    to={data.booking.to}
                                    startTime={data.booking.departMoment.format()}
                                    endTime={data.booking.arriveMoment.format()}
                                    showDate={true}
                                    showGMT={DateTimeUtil.isOnDifferentTimezone(data.booking.timezone)}
                                    timezone={data.booking.timezone}
                                    key={i}
                                />
                            </div>
                        )}
                    </div>}
                <div className={classes.footer}>
                    <button
                        className={appClasses.buttonCancel}
                        onClick={() => onRequestClose()}>
                        {"Back"}
                    </button>
                    <button className={appClasses.buttonAdd}
                        onClick={() => onConfirm()}
                        disabled={skippedAll}
                    >
                        {"Confirm"}
                    </button>
                </div>
            </div>
        </TKRoot >
    );
}

export default withStyles(ScheduleBookingConfirm, scheduleBookingConfirmJss);