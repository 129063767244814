import {Theme} from "../css/Theme";
import {genJss} from "../css/gen.jss";

export const profileViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        height: '100%'
    },
    form: {
        ...genJss.flex,
        ...genJss.column,
        margin: '20px 40px',
        '& div': {
            ...genJss.spaceBetween,
            '& label': {
                width: 'initial'
            }
        }
    },
    logout: {
        marginTop: 'auto'
    }
});