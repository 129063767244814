import { JsonObject, JsonProperty } from "json2typescript/src/json2typescript/json-convert-decorators";
import Provider, { AWSJSONConverter } from "./Provider";
import Organization from "./Organization";
import { Client } from "./Client";
import { Any } from "json2typescript/src/json2typescript/any";
import { Features } from "../account/AdminProfile";

export const ADMIN_USER_ID_FIELD = "Id";
export const ADMIN_USER_EMAIL_FIELD = "email";
export const ADMIN_USER_FIRST_NAME_FIELD = "firstName";
export const ADMIN_USER_LAST_NAME_FIELD = "lastName";
export const ADMIN_USER_CREATED_AT_FIELD = "createdAt";
export const ADMIN_USER_IS_SKEDGO_DEV_FIELD = "isSkedgoDev";
export const ADMIN_USER_ROLE_FIELD = "roles";
export const ADMIN_USER_ORGANIZATIONS_FIELD = "organizations";
export const ADMIN_USER_ORG_RELATIONS_FIELD = "orgRelations";
export const ADMIN_USER_PROVIDERS_FIELD = "providers";
export const ADMIN_USER_ADMIN_MANAGEMENT_FIELD = "adminManagement";
export const ADMIN_USER_FEATURES = "features";

export function clientAppsForOrganizations(orgs: Organization[], allClients: Client[]): Client[] {
    return orgs.find(o => o.isRoot) ? allClients : [...new Set(orgs.map(o => o.clientId))].map(clientId => allClients.find(client => client.clientID === clientId)).filter(c => !!c) as Client[];
}

export function clientAppsForProviders(providers: Provider[], allClients: Client[]): Client[] {
    return [...new Set(providers.map(o => o.clientId))].map(clientId => allClients.find(client => client.clientID === clientId)).filter(c => !!c) as Client[];
}

export function clientAppsForAdmin(adminUser: AdminUser, allClients: Client[]): Client[] {
    const { isTSP } = adminUser;
    return isTSP ? clientAppsForProviders(adminUser.providers, allClients) : clientAppsForOrganizations(adminUser.organizations, allClients);
}

export function clientAppOrgsForOrganizations(orgs: Organization[], allOrgs: Organization[]): Organization[] {
    const clientAppOrgs = allOrgs.filter(o => o.isClientApp);
    return [...new Set(orgs.map(o => clientAppOrgs.find(ca => o.path.startsWith(ca.path))))].filter(c => !!c) as Organization[];
}

export function clientAppOrgsForProviders(provider: Provider[], allOrgs: Organization[]): Organization[] {
    const clientAppOrgs = allOrgs.filter(o => o.isClientApp);
    return [...new Set(provider.map(p => clientAppOrgs.find(ca => p.clientId === ca.clientId)))].filter(c => !!c) as Organization[];
}

export function clientAppOrgsForAdmin(adminUser: AdminUser, allOrgs: Organization[]): Organization[] {
    const { isTSP, isOrg } = adminUser;
    return isOrg ? clientAppOrgsForOrganizations(adminUser.organizations, allOrgs) :
        isTSP ? clientAppOrgsForProviders(adminUser.providers, allOrgs) :
            adminUser.organizations;
}

interface OrgAdminUser {
    orgId: string;
    notify: boolean;
}
@JsonObject
class AdminUser {
    @JsonProperty(ADMIN_USER_ID_FIELD, String, true)
    public readonly id: string = "";
    @JsonProperty(ADMIN_USER_EMAIL_FIELD, String, true)
    public email: string = "";
    @JsonProperty(ADMIN_USER_FIRST_NAME_FIELD, String, true)
    public firstName: string = "";
    @JsonProperty(ADMIN_USER_LAST_NAME_FIELD, String, true)
    public lastName: string = "";
    @JsonProperty(ADMIN_USER_CREATED_AT_FIELD, String, true)
    public createdAt: string = "";
    @JsonProperty(ADMIN_USER_IS_SKEDGO_DEV_FIELD, Boolean, true)
    public isSkedGoDev: boolean = false;
    @JsonProperty(ADMIN_USER_ROLE_FIELD, [String], true)
    public roles: string[] = [];
    @JsonProperty(ADMIN_USER_ORGANIZATIONS_FIELD, [Organization], true)
    public organizations: Organization[] = [];
    @JsonProperty(ADMIN_USER_ORG_RELATIONS_FIELD, [Any], true)
    public orgRelations: OrgAdminUser[] = [];
    @JsonProperty(ADMIN_USER_PROVIDERS_FIELD, [Provider], true)
    public providers: Provider[] = [];
    @JsonProperty(ADMIN_USER_ADMIN_MANAGEMENT_FIELD, Boolean, true)
    public adminManagement: boolean = false;
    /**
     * This allows to control enabled / disabled features from the BE, either on a per-user basis or globally.
     */
    @JsonProperty(ADMIN_USER_FEATURES, AWSJSONConverter, true)
    public features: Partial<Features> = {};

    get name(): string {
        return [this.firstName, this.lastName].join(" ").trim();
    }

    get role(): string {
        return this.roles[0] === "tsp_admin_2" ? "tsp_admin" : this.roles[0];   // Remove once there's no more "tsp_admin_2" in the DB.
    }

    set role(value: string) {
        this.roles = [value];
    }

    get isAdmin(): boolean {
        return this.role === "admin";
    }

    get isTSP(): boolean {
        return this.role === "tsp_admin";
    }

    get isOrg(): boolean {
        return this.role === "org_admin";
    }
}

export default AdminUser;