import React, { useRef } from "react";
import { Theme } from "../css/Theme";
import {
    bookingStatusValues,
    redemptionStatusValues,
    TransStatus,
    transStatusToDisplayString,
    TransType
} from "../model/Transaction";
import { WithClasses, withProps, withStyles, withStylesO } from "../css/StyleHelper";
import Select, { components } from 'react-select';
import classNames from "classnames";
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import { useTheme } from 'react-jss';
import { defaultOptionStyle, selectOptionJss, statusSelectJss } from "./StatusSelect.jss";
import { transTypeOptionStyle } from "../user/UserView.jss";
import FormatUtil from "../util/FormatUtil";
import { adminProfile } from "../account/AdminProfile";

export const SelectOption = withStyles((props: any) => {
    const { isFocused, isSelected, classes, data, innerProps, content = <components.Option {...props} /> } = props;
    return (
        <div className={classNames(classes.option,
            isSelected && classes.selected, !isSelected && isFocused && classes.focused)} {...innerProps}
            data-testid={data.label}
        >
            {content}
        </div>
    );
}, selectOptionJss);

export const ALL_OPTIONS_VALUE = "All";

const SelectDownArrow = (props: any) => <IconTriangleDown style={{ width: '9px', height: '9px', marginLeft: '10px' }} />;

type IStyle = ReturnType<typeof statusSelectJss>;

export interface StatusSelectOption<T> {
    value: T;
    label: string;
}

export interface StatusSelectProps extends WithClasses<IStyle> {
    value?: any | any[];
    onChange?: (value?: any) => void;
    onChangeOption?: (option?: StatusSelectOption<any>) => void;
    placeholder?: string;
    forFilter?: boolean;
    allowNoValue?: boolean;
    optionStyle: (theme: Theme, optionValue?: any) => any;
    allOptionsLabel?: string;
    options: StatusSelectOption<any>[];
    isDisabled?: boolean;
    isMulti?: boolean;
    isSearchable?: boolean;
    menuShouldScrollIntoViewWithDelay?: boolean;
    components?: {
        IndicatorsContainer?: any;
        Option?: any;
        SingleValue?: any;
    }
}

export const StatusSelect: React.FunctionComponent<StatusSelectProps> = (props: StatusSelectProps) => {
    const theme = useTheme() as Theme;
    const selectRef = useRef(null);
    const { onChange, onChangeOption, menuShouldScrollIntoViewWithDelay, classes, components, injectedStyles } = props;
    const jssStyles = statusSelectJss(theme);
    const options = props.forFilter ? [{ value: ALL_OPTIONS_VALUE, label: props.allOptionsLabel || ALL_OPTIONS_VALUE }].concat(props.options) : props.options;
    const value = props.value;
    let selectValue;
    if (props.isMulti) {
        if (!Array.isArray(value)) {
            throw "If multi we always expect an array as value, but comes: " + value;
        }
        selectValue = value.map((singleValue) => options.find((statusOption) => statusOption.value === singleValue));
    } else {
        // If value is undefined select the first selectValue, since
        selectValue = value !== undefined ? options.find((statusOption) => statusOption.value === value) :
            props.allowNoValue ? undefined : options[0];
    }
    const optionChange = (optionUpdate) => {
        onChangeOption?.(optionUpdate)
        if (onChange) {
            let valueUpdate;
            if (props.isMulti) {
                valueUpdate = Array.isArray(optionUpdate) ? optionUpdate.map((singleOption) => singleOption.value) : [];
            } else {
                valueUpdate = props.forFilter && optionUpdate.value === ALL_OPTIONS_VALUE ? undefined : optionUpdate.value;
            }
            onChange(valueUpdate);
        }
    };
    return (
        <Select
            options={options}
            placeholder={props.placeholder}
            styles={{
                control: (styles: any, props: any) => ({ ...styles, ...jssStyles.control }),
                menu: (styles: any) => injectedStyles.menu,
                option: (style, optProps) =>
                    props.optionStyle(theme, optProps.data.value === ALL_OPTIONS_VALUE ? undefined : optProps.data.value),
                singleValue: () => ({ ...jssStyles.singleValue(props) }),
                placeholder: (styles: any) => ({ ...styles, whiteSpace: 'nowrap' }),
                valueContainer: (styles: any) => ({ ...styles, flexWrap: 'nowrap' }),
            }}
            components={{
                IndicatorsContainer: SelectDownArrow,
                Option: SelectOption,
                ...components
            }}
            value={selectValue ?? null}
            onChange={optionChange}
            // menuIsOpen={true}
            className={classes.select}
            classNamePrefix={"select"}
            isSearchable={!!props.isSearchable}
            isDisabled={props.isDisabled}
            isMulti={props.isMulti}
            ref={selectRef}
            {...menuShouldScrollIntoViewWithDelay && {
                onMenuOpen: () => {
                    setTimeout(() => (selectRef.current as any)?.select?.menuListRef?.scrollIntoViewIfNeeded?.(), 100);
                }
            }}
        />
    );
};

const transStatusOptions = redemptionStatusValues.map((status: string) =>
    ({ value: status, label: status.charAt(0).toUpperCase() + status.slice(1).toLowerCase() }))
    .sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));

const TransStatusSelectDisabler = (props: StatusSelectProps) => {
    const options = (props.isDisabled || props.forFilter) ? props.options : props.options.filter((option) => option.value !== TransStatus.REVERTED);
    return <StatusSelect {...props} options={options} />
};

// Need to cast added props object since if not all added props are inferred as required (not undefined)
// and so the type of first argument of withProps needs to have them also as required, which causes
// a type error for optional props (as allOptionsLabel).
export const TransStatusSelect = withProps(withStyles(TransStatusSelectDisabler, statusSelectJss),
    {
        allOptionsLabel: "All statuses", options: transStatusOptions,
        optionStyle: defaultOptionStyle
    } as
    Pick<StatusSelectProps, "allOptionsLabel" | "options" | "optionStyle">);

export const bookingStatusOptions = bookingStatusValues.map((status: TransStatus) =>
    ({ value: status, label: transStatusToDisplayString(status) }))
    .sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));

export const BookingStatusSelect = withProps(withStyles(TransStatusSelectDisabler, statusSelectJss),
    {
        allOptionsLabel: "All", options: bookingStatusOptions,
        optionStyle: defaultOptionStyle
    } as
    Pick<StatusSelectProps, "allOptionsLabel" | "options" | "optionStyle">);

export const getTransTypeOptions = () =>
    Object.values(TransType)
        .filter((type: TransType) => type !== TransType.BALANCE)
        .filter((type: TransType) => adminProfile.features.rewards ||
            (type !== TransType.REWARD && type !== TransType.REDEMPTION))
        .map((type: string) => ({ value: type, label: FormatUtil.toFirstUpperCase(type) }));

export const TransTypeSelect = withProps(withStyles(StatusSelect, statusSelectJss),
    { allOptionsLabel: "All types", optionStyle: transTypeOptionStyle } as Pick<StatusSelectProps, "allOptionsLabel" | "optionStyle">);

export const DefaultSelect = withProps(withStylesO(StatusSelect, statusSelectJss),
    { optionStyle: defaultOptionStyle });