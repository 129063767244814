import React, { useEffect } from "react";
import SideView from "../view/SideView";
import { AppContext, geocodingConfig, mapConfig } from "../app/App";
import GenTable from "../view/Table";
import User from "../model/User";
import { WithClasses, withStyles } from "../css/StyleHelper";
import classnames from "classnames";
import { genJss } from "../css/gen.jss";
import AdminProfile from "../account/AdminProfile";
import { locIconStyleOverride } from "../booking/BookingViewHelpers";
import { appGlobalJss } from "../css/app.jss";
import { tKUIButtonDefaultStyle } from "tripkit-react/dist/buttons/TKUIButton.css";
import TKRoot from "tripkit-react/dist/config/TKRoot";
import TKUIEditFavouriteView from "tripkit-react/dist/favourite/TKUIEditFavouriteView";
import { CardPresentation } from "tripkit-react/dist/card/TKUICard";
import { overrideClass } from "tripkit-react/dist/jss/StyleHelper";
import { RoutingResultsContext } from "tripkit-react/dist/trip-planner/RoutingResultsProvider";
import { default as TKRegionsData } from "tripkit-react/dist/data/RegionsData";
import UIUtil from "../util/UIUtil";
import { useBeforeInitialRender } from "../util/usehooks";
import { i18n } from "../i18n/TKI18nConstants";
import { favoritesViewJss } from "./FavoritesView.jss";
import { SignInStatus, TKAccountContext } from "tripkit-react/dist/account/TKAccountContext";
import TKUserAccount from "tripkit-react/dist/account/TKUserAccount";
import Loading from "../view/Loading";
import { TKFavouritesContext } from "tripkit-react/dist/favourite/TKFavouritesProvider";
import TripGoApi from "tripkit-react/dist/api/TripGoApi";
import { TKError } from "tripkit-react/dist/error/TKError";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { PART_DETAIL_VIEW_PATH } from "./UserViewConstants";
import withAsycnData from "../data/WithAsyncData";
import UsersData from "../data/UsersData";
import FavouriteLocation from "tripkit-react/dist/model/favourite/FavouriteLocation";
import Favourite from "tripkit-react/dist/model/favourite/Favourite";
import { useTheme } from "react-jss";
import { Theme } from "../css/Theme";

type IStyle = ReturnType<typeof favoritesViewJss>;

export interface IProps extends WithClasses<IStyle> {
    user?: User;
    onRequestClose: () => void,
}

export function requestUserToken({ user, adminProfile, selectedOrgId }: { user: User, adminProfile: AdminProfile, selectedOrgId?: string }): Promise<string> {
    const adminTokenEndpoint = adminProfile.appMetadata?.tripgoApiUrl + "/booking/admin/token";
    return fetch(adminTokenEndpoint, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'X-Account-Access-Token': adminProfile.appMetadata?.tripgoApiAccountAccessToken || ""
        },
        body: JSON.stringify({
            userID: user!.id,
            orgID: selectedOrgId
        })
    })
        .then(response => response.json())
        .then(tokenData => {
            return tokenData.result;
        })
}

const FavoritesView: React.FunctionComponent<IProps> = (props) => {
    const { user = new User(), onRequestClose, classes, appClasses } = props;
    const [editingFav, setEditingFav] = React.useState<any>(undefined);
    const [isCreatingFav, setIsCreatingFav] = React.useState<boolean>(false);
    const { profile: adminProfile, setWaiting } = React.useContext(AppContext);
    const { onViewportChange } = React.useContext(RoutingResultsContext);
    const { isLoadingFavourites, favouriteList, onAddFavourite: onAddFavouriteOrig, onUpdateFavourite, onRemoveFavourite } = React.useContext(TKFavouritesContext);
    const onAddFavourite = (favourite: Favourite) => {
        onAddFavouriteOrig(favourite);
        UsersData.instance.invalidateFetchCache("/favorite");
    };
    useBeforeInitialRender(() => {
        // Set the client id to that one of the user.        
        TripGoApi.clientID = user.clientId;
    });
    useEffect(() => {
        TKRegionsData.instance.requireRegions().then(() => {
            const defaultCityId = user.clientId && adminProfile.appMetadata?.clientsData?.[user.clientId]?.defaultCity;
            console.log(defaultCityId)
            let defaultCityLatLng;
            if (defaultCityId) {
                defaultCityLatLng = TKRegionsData.instance.getCities()?.find(city => city.identifier === defaultCityId);
            }
            if (!defaultCityLatLng) {   // This shouldn't be necessary anymore if I properly set the previous parameter
                defaultCityLatLng = TKRegionsData.instance.getCities()?.[0];
            }
            console.log(defaultCityLatLng);
            // defaultCityLatLng && setViewport(defaultCityLatLng, 13);
            defaultCityLatLng && onViewportChange({ center: defaultCityLatLng, zoom: 13 });
        });
    }, []);
    const handleEditClose = async (update?: any) => {
        if (update) {
            try {
                setWaiting(true);
                await (isCreatingFav ? onAddFavourite(update) : onUpdateFavourite(update));
            } catch (e) {
                UIUtil.errorMessage(new TKError(isCreatingFav ? "Failed to create favourite." : "Failed to update favourite."));
            } finally {
                setWaiting(false);
            }
        }
        setEditingFav(undefined);
        setIsCreatingFav(false);
    };
    const handleRemoveFavourite = (favourite: Favourite) => {
        UIUtil.confirmMsg({
            title: "Delete",
            message: "Are you sure you want to delete this favourite?",
            onConfirm: async () => {
                try {
                    setWaiting(true);
                    await onRemoveFavourite(favourite);
                } catch (e) {
                    UIUtil.errorMessage(new TKError("Failed to delete favourite."));
                } finally {
                    setWaiting(false);
                }
            }
        });
    }
    const content = isLoadingFavourites ?
        <Loading /> :
        <>
            <GenTable
                tableId="favorites_table"
                items={favouriteList}
                contentSpec={[
                    {
                        id: "name",
                        name: "Name",
                        label: "Name",
                        cellValue: item => item.name,
                        width: '40%',
                        // style: { paddingLeft: 0, maxWidth: '100%' }
                    },
                    {
                        id: "address",
                        name: "Address",
                        label: "Address",
                        cellValue: item => (item as FavouriteLocation).location.address,
                        width: '60%',
                        // style: { paddingLeft: 0, maxWidth: '100%' }
                    },
                    {
                        id: "edit",
                        name: "Edit",
                        label: "",
                        cellValue: item =>
                            <button
                                className={appClasses.buttonOk}
                                onClick={() => {
                                    setEditingFav(item);
                                }}>
                                {"Edit"}
                            </button>,
                    },
                    {
                        id: "delete",
                        name: "Delete",
                        label: "",
                        cellValue: item =>
                            <button
                                className={appClasses.buttonAdd}
                                onClick={() => {
                                    handleRemoveFavourite(item);
                                }}
                            >
                                {"Delete"}
                            </button>,
                    }
                ]}
            />
            <button className={classnames(appClasses.buttonOk, classes.addFavoriteBtn)} onClick={() => setIsCreatingFav(true)}>
                {"Add Favorite"}
            </button>
            <div className={classes.footer}>
                <button className={appClasses.buttonCancel} onClick={() => onRequestClose()}>{"Close"}</button>
            </div>
        </>;
    return (
        <>
            <SideView
                title={`Favorites for ${user.name}`}
                onRequestClose={onRequestClose}
            >
                <div className={classes.main}>
                    {content}
                </div>
            </SideView>
            {(editingFav || isCreatingFav) &&
                <TKUIEditFavouriteView
                    title={isCreatingFav ? i18n.t("Add.favorite") : i18n.t("Edit.favorite")}
                    value={editingFav}
                    onRequestClose={handleEditClose}
                    cardPresentation={CardPresentation.MODAL}
                    slideUpOptions={{ draggable: false }}
                />}
        </>
    );
}

const FavoritesViewWithRoot = (props: IProps) => {
    const { profile: adminProfile } = React.useContext(AppContext);
    const theme = useTheme() as Theme;
    const config = {
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        i18n: {
            locale: adminProfile.locale,
            translations: {} as any     // TODO: support translations as in tripgo-web/src/white_labels/feonix/src/app/TripPlannerApp.tsx
        },
        TKUIMapView: {
            props: () => mapConfig()
        },
        TKUIMapLocationIcon: {
            styles: locIconStyleOverride
        },
        geocoding: geocodingConfig,
        theme: {
            colorPrimary: theme.colorPrimary,
            fontFamily: theme.fontFamily
        },
        isDarkMode: false,
        TKUIButton: {
            styles: tkTheme => ({
                main: {},
                primary: appGlobalJss(theme).buttonAdd,
                secondary: appGlobalJss(theme).buttonCancel,
                link: defaultStyle => ({
                    ...tKUIButtonDefaultStyle(tkTheme).main,
                    ...defaultStyle
                })
            })
        },
        TKUICard: {
            styles: {
                modalContent: overrideClass({
                    background: 'none',
                    border: 'none',
                    padding: '5px',
                    transform: 'translate(-50%, 0)',
                    left: '50%',
                    width: '720px'
                })
            }
        },
        TKUIEditFavouriteView: {
            styles: {
                map: overrideClass({
                    height: '550px'
                }),
                formGroup: defaultStyle => ({
                    ...defaultStyle,
                    ...genJss.flex,
                    ...genJss.alignCenter,
                    ...genJss.row,
                    '& label': {
                        ...theme.textWeightSemibold,
                        marginRight: '20px',
                        width: '100px',
                        textAlign: 'right'
                    },
                    '& input': {
                        ...defaultStyle['& input'],
                        ...genJss.grow
                    }
                })
            }
        }
    };
    const [signInStatus, setSignInStatus] = React.useState(SignInStatus.loading);
    const { user } = props;
    useEffect(() => {
        requestUserToken({ user: user!, adminProfile }).then(token => {
            TripGoApi.userToken = token;
            setSignInStatus(SignInStatus.signedIn);
        });
    }, []);
    return (
        <TKAccountContext.Provider
            value={{
                accountsSupported: true,
                status: signInStatus,
                login: () => Promise.resolve(),
                logout: () => { },
                finishInitLoadingPromise: Promise.resolve(SignInStatus.signedOut),
                resetUserToken: () => { },
                refreshUserProfile: () => Promise.resolve(new TKUserAccount())
            }}
        >
            <TKRoot config={config}>
                <FavoritesView {...props} />
            </TKRoot>
        </TKAccountContext.Provider>
    );
}

const FavoritesViewStyled = withStyles(FavoritesViewWithRoot, favoritesViewJss);

const FavouritesViewWithData = withAsycnData(FavoritesViewStyled,
    (query: { userId: string }) => UsersData.instance.get(query.userId)
        .then((user: User | undefined) => ({ user }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ user?: User, error?: Error }>);


export const FAVORITES_VIEW: IViewRouteConfig<{ userId: string }> =
{
    path: PART_DETAIL_VIEW_PATH.map(path => path + "/favorites")
        .concat(["*/favorites/:userId"]),
    propsFromMatch: (match: any) => {
        return ({ userId: match.params.userId ?? match.params.id });
    },
    propsToPath: ({ userId }) => "/favorites" + (userId ? `/${userId}` : ""),
    navLabel: () => "Favorites",
    render: ({ viewProps, navHistory }) => {
        return (
            <FavouritesViewWithData
                userId={viewProps.userId}
                onRequestClose={() => {
                    navHistory.pop();
                }}
                renderWhenData={true}
                undefineOnUpdate={false}
            />
        );
    },
    isModal: true
};