import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const bundlesViewJss = (theme: Theme) => ({
    main: {
        margin: "30px"
    },

    tableContainer: {
        ...genJss.flex,
        ...genJss.column,
        marginTop: "20px",
        // #scrollx
        overflowX: 'auto'
    },

    active: {
        color: theme.colorPrimary + "!important"
    },

    numOfStaffLink: {
        ...genJss.flex,
        ...genJss.alignCenter,
        paddingRight: '48px'
    },

    filters: {
        ...genJss.flex,
        ...genJss.alignStart,
        marginBottom: '20px',
        zIndex: 2,  // To be on top of table sticky header
        '& > *': {
            marginRight: '40px'
        }
    },
    search: {
        width: '300px'
    },
    filterSelect: {
        '&>*': {
            width: 'initial!important',
            minWidth: '200px'
        }
    }
});