import React from "react";
import Select from 'react-select';
import { pageSizeSelectJss } from "./PageSizeSelect.jss";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { useTheme } from 'react-jss';
import { Theme } from "../css/Theme";
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import { appGlobalJss } from "../css/app.jss";

const pageSizeOptions = [10, 20, 50].map((pageSize: number) => ({ value: pageSize, label: pageSize }));

const SelectDownArrow = (props: any) => <IconTriangleDown style={{ width: '9px', height: '9px' }} />;

type IStyle = ReturnType<typeof pageSizeSelectJss>;

interface IProps extends WithClasses<IStyle> {
    value: number;
    onChange: (pageSize: number) => void;
}

// TODO: generalize this to a DashSelect (or just Select), specially the styling part. See how to re-use in StatusSelect.

const PageSizeSelect: React.FunctionComponent<IProps> = (props: IProps) => {
    const theme = useTheme() as Theme;
    const jssStyles = pageSizeSelectJss(theme);
    return (
        <Select
            options={pageSizeOptions}
            value={pageSizeOptions.find((option) => option.value === props.value) || pageSizeOptions[0]}
            onChange={(option) => props.onChange(option.value)}
            styles={{
                control: (styles: any, props: any) => ({
                    ...styles,
                    ...jssStyles.control,
                    ...appGlobalJss(theme).focusGlow
                }),
                menu: (styles: any) => jssStyles.menu,
                menuList: (styles: any) => ({}),
                option: (style, optProps) => ({
                    ...jssStyles.option,
                    ...optProps.isFocused && jssStyles.optionFocused,
                    ...optProps.isSelected && jssStyles.optionSelected,
                }),
                singleValue: () => jssStyles.singleValue
            }}
            components={{
                IndicatorsContainer: SelectDownArrow,
            }}
            className={props.classes.select}
            classNamePrefix={"select"}
            isSearchable={false}
        // menuIsOpen={true}
        />
    );
};

const PageSizeSelectStyled = withStyles(PageSizeSelect, pageSizeSelectJss);

export default PageSizeSelectStyled;