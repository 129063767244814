import {Theme} from "../css/Theme";
import {black, genJss, resetJss} from "../css/gen.jss";
import UIUtil from "../util/UIUtil";

export const rewardsViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.grow
    },
    sideBar: {
        ...genJss.flex,
        ...genJss.column,
        width: '280px',
        borderRight: '1px solid ' + black(4),
        padding: '20px',
        ...genJss.noShrink
    },
    menuButton: {
        ...resetJss.button,
        ...theme.textSizeBody,
        whiteSpace: 'nowrap',
        ...genJss.flex,
        ...genJss.alignCenter,
        color: '#292A2B',
        padding: '8px',
        ...genJss.borderRadius(4),
        '& svg': {
            width: '20px',
            height: '20px',
            marginRight: '14px'
        },
        '& path': {
            fill: '#292A2B'
        }
    },
    selected: {
        background: UIUtil.colorWithOpacity(theme.colorPrimary, .12),
        ...theme.textWeightSemibold,
        '& path': {
            fill: theme.colorPrimary
        }
    },
    container: {
        ...genJss.grow,
        ...genJss.flex
    }
});