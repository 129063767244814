import React from "react";
import Transaction, { TransStatus } from "../model/Transaction";
import DateTimeUtil from "../util/DateTimeUtil";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import UIUtil from "../util/UIUtil";
import TransactionsData from "../data/TransactionsData";
import { default as UsersData } from "../data/UsersData";
import withAsycnData from "../data/WithAsyncData";
import { BOOKING_DETAIL_VIEW_PATH } from "./TransactionsViewConstants";
import { BOOKING_SCHEDULE_VIEW } from "./ScheduleBookingView";
import { adminProfile } from "../account/AdminProfile";
import { BOOKING_CANCEL_VIEW } from "./CancelBookingView";
import { overrideClass } from "tripkit-react";
import { MONEY_TRANSACTION_VIEW } from "./MoneyTransactionView";
import { EDIT_STATUS_VIEW } from "./EditStatusViewHelpers";
import { userViewId } from "../user/UserView";
import { PRICE_CHANGE_VIEW, PriceChangeActionType } from "./PriceChangeViewHelpers";
import { EDIT_BOOKING_INITIATIVE_VIEW } from "./EditBookingInitiativeView";
import BookingView from "./BookingView";

export const canCancel = (booking: Transaction): { value: boolean, hide?: boolean, actionLabel?: "Cancel" | "Decline", withRelated?: boolean } => {
    // Assume cancelation actions will come from BE for completed trips. Should be able to remove this once dashUserType is sent to the BE.
    if (booking.status === TransStatus.COMPLETED && !adminProfile.isSuperAdmin) {
        return { value: false };
    }
    const cancelActions = booking.actions.filter(action => action.action === "CANCEL");
    if (cancelActions.length === 0) {
        return { value: false, hide: adminProfile.isSuperAdmin };   // Directly hide for super admin since they shouldn't be able to cancel under any circumstances.
    }
    if (adminProfile.tspMode && adminProfile.tspMode !== booking.mode) {
        return { value: false };
    }
    const actionLabel = cancelActions.length === 1 && cancelActions[0].newStatus === TransStatus.PROVIDER_DECLINED ? "Decline" : "Cancel";
    const withRelated = cancelActions.length === 1 && !!cancelActions[0].withRelated;
    return { value: true, actionLabel, withRelated };
};

export function canEditBookingStatus(selectedBooking: Transaction): boolean {
    if (!adminProfile.features.bookingStatuses20489) {
        return false;
    }
    return selectedBooking.actions
        .filter(action => action.changes === "STATUS" && action.action === "UPDATE")
        .length > 0;
}

export const locIconStyleOverride = (theme) => ({
    iconPin: overrideClass({
        '& path': {
            fill: (props) => {
                return props.from ?
                    (adminProfile.app === "feonix-miride" ? '#e27338' : theme.colorPrimary) :
                    theme.colorError;
            }
        }
    })
});

// noinspection JSUnusedLocalSymbols
const BookingViewWithData = withAsycnData(BookingView,
    (query: { id: string }) =>
        TransactionsData.instance.get(query.id).then((booking: Transaction | undefined) => { return { booking } })
            .catch(UIUtil.errorMessage) as Promise<{ booking?: Transaction }>);

// noinspection JSUnusedLocalSymbols
const BookingViewNavWithData = withAsycnData(((props: { booking?: Transaction }) => {
    const bookingDate = props.booking && DateTimeUtil.momentTZTime(props.booking.depart * 1000, props.booking.timezone);
    return <>{bookingDate ? "Trip on " + bookingDate.format(DateTimeUtil.dayMonthFormat()) + " at " + bookingDate.format(DateTimeUtil.timeFormat()) : ""}</>
}),
    (query: { id: string }) => {
        return TransactionsData.instance.get(query.id).then((booking: Transaction | undefined) => { return { booking }; }) as Promise<{ booking?: Transaction; }>;
    });

export const bookingViewId = "BOOKING_VIEW";

export const BOOKING_DETAIL_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: BOOKING_DETAIL_VIEW_PATH,
    propsFromMatch: (match: any) => ({ id: match.params.id || match.params.tripId }),
    propsToPath: (params: { id: string }) => "/id/" + params.id,
    navLabel: ({ viewProps }) => <BookingViewNavWithData id={viewProps.id} />,
    render: ({ viewProps, navHistory, waitFor, profile, selectedClientID, clients }) => {
        const id = viewProps.id;
        const onSchedule = () => navHistory.push(BOOKING_SCHEDULE_VIEW, { id });
        const onCancel = bookingId => navHistory.push(BOOKING_CANCEL_VIEW, { id: bookingId });
        const onRemove = (booking: Transaction) =>
            UIUtil.confirmMsg({
                title: 'Confirm to delete',
                onConfirm: () => waitFor(TransactionsData.instance.delete(viewProps.id, booking.clientId)
                    .then(() => {
                        TransactionsData.instance.invalidateTransCache();
                        UsersData.instance.invalidateFetchCache();
                        return navHistory.pop();
                    }))
            });
        const onOpenPayment = paymentId => navHistory.push(MONEY_TRANSACTION_VIEW, { id: paymentId });
        const onEditBookingStatus = (booking: Transaction) =>
            navHistory.push(EDIT_STATUS_VIEW, { bookingId: booking.id });
        const onEditBookingInitiative = (booking: Transaction) =>
            navHistory.push(EDIT_BOOKING_INITIATIVE_VIEW, { bookingId: booking.id });
        function onPriceAction(booking: Transaction, priceActionType?: PriceChangeActionType) {
            navHistory.push(PRICE_CHANGE_VIEW, { bookingId: booking.id, priceAction: priceActionType });
        }
        function onSelectRelated(bookingId: string) {
            navHistory.viewAt(-1)?.id === userViewId ?
                navHistory.replaceS(`/tripId/${bookingId}`) :
                navHistory.replace2(BOOKING_DETAIL_VIEW, { id: bookingId });
        }
        const bookingViewRef = React.createRef<any>();
        async function onRefreshExternal(booking: Transaction) {
            await waitFor(TransactionsData.instance.updateExternalBooking(booking.id, booking.clientId!));
            TransactionsData.instance.invalidateTransCache();
            booking.relatedBookings.forEach(rb =>
                TransactionsData.instance.invalidateTransCacheById(rb.bookingId));
            booking.userId && UsersData.instance.invalidateUserCache(booking.userId);
            bookingViewRef.current?.refresh(true);
        }
        return (
            <BookingViewWithData
                {...viewProps}
                onSelectRelated={onSelectRelated}
                onSchedule={onSchedule}
                onCancel={onCancel}
                onRemove={onRemove}
                onOpenPayment={onOpenPayment}
                onEditStatus={onEditBookingStatus}
                onEditInitiative={onEditBookingInitiative}
                onPriceAction={onPriceAction}
                undefineOnUpdate={false}
                // Need this so after returning from cancel view it refreshes.
                // 24/12/2024: Disadvantage: when opening edit note view it resets the scroll to the top, making the notes section not visible. So put in false again.
                // Previous issue on cancel not happening anymore.                
                renderWhenData={true}   // We need to wait for the booking so the client ID is set on componentWillMount.                
                selectedClientID={selectedClientID}
                clients={clients}
                onRefreshExternal={onRefreshExternal}
                ref={bookingViewRef}
                key={viewProps.id}  // Force re-render when id changes.
            />
        );
    },
    id: bookingViewId
};