import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const addMoneyViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.spaceBetween,
        height: '100%',
        '& form': {
            flexGrow: 1
        }
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '40px'
    },
    form: {
        minHeight: '250px',
        paddingBottom: '30px',
        height: '100%'
    },
    reviewPanel: {
        marginTop: 'auto',
        borderTop: '1px solid #212a331f',
        paddingTop: '20px'
    }
});