import React, { useState, Fragment } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import ModalView from "../view/ModalView";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Transaction from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import { BOOKING_DETAIL_VIEW_PATH } from "./TransactionsViewConstants";
import withAsycnData from "../data/WithAsyncData";
import { moneyTransactionViewJss } from "./MoneyTransactionView.jss";
import { PaymentStatusPill } from "./TransactionsTable";
import FormatUtil from "../util/FormatUtil";
import { ReactComponent as ExternalLink } from '../images/ic-external-link.svg'
import DateTimeUtil from "../util/DateTimeUtil";
import UIUtil from "../util/UIUtil";
import classNames from "classnames";
import Loading from "../view/Loading";

type IStyle = ReturnType<typeof moneyTransactionViewJss>;

interface IProps extends WithClasses<IStyle> {
    refresh?: (undefineOnUpdate?: boolean) => Promise<void>
    transaction?: Transaction;
    bookingId?: string;
    onRequestClose?: () => void;
}

const MoneyTransactionView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { transaction, bookingId, onRequestClose, classes, appClasses, refresh } = props;
    const [waiting, setWaiting] = useState<boolean>(false);
    return (
        <ModalView
            title={"Payment"}
            onRequestClose={onRequestClose}
        >
            {!transaction ? <Loading overlay={true} /> :
                <Fragment>
                    {waiting && <Loading overlay={true} />}
                    <div className={classes.detailForm} style={{ margin: '20px' }}>
                        <div className={classes.section}>
                            {transaction.amount >= 0 &&
                                <div className={classes.entry}>
                                    <div className={classes.field}>Amount</div>
                                    <div className={classes.value}>
                                        {FormatUtil.toMoney(transaction.amount, { nInCents: true })}
                                    </div>
                                </div>}
                            <div className={classes.entry}>
                                <div className={classes.field}>Date</div>
                                <div className={classes.value}>{transaction.timestampMoment.format(DateTimeUtil.dateFormat() + ", " + DateTimeUtil.timeFormat())}</div>
                            </div>
                            {transaction.paymentStatus &&
                                <div className={classes.entry}>
                                    <div className={classes.field}>Status</div>
                                    <div className={classNames(classes.value, classes.statusValue)}>
                                        <PaymentStatusPill status={transaction.paymentStatus} />
                                        {transaction.paymentStatus === 'requires_capture' &&
                                            <button
                                                onClick={() => {
                                                    UIUtil.confirmMsg({
                                                        message: "Confirm to charge the user?",
                                                        onConfirm: () => {
                                                            setWaiting(true);
                                                            TransactionsData.instance.pay(transaction.id, transaction.clientId)
                                                                .then(() => {
                                                                    // Remove the payment and booking transactions from the cache, so payment status is refreshed 
                                                                    // in current view and booking details view.
                                                                    TransactionsData.instance.invalidateTransCacheById(transaction.id);
                                                                    bookingId && TransactionsData.instance.invalidateTransCacheById(bookingId);
                                                                    // Force a refresh of the view (refresh is inserted by WithAsyncData) so payment status is updated.
                                                                    refresh?.()
                                                                        .then(() => {
                                                                            setWaiting(false);
                                                                        });
                                                                })
                                                                .catch(e => {
                                                                    UIUtil.errorMessage(e);
                                                                    setWaiting(false);
                                                                });
                                                        }
                                                    })
                                                }}
                                                className={appClasses.buttonAdd}
                                            >
                                                Charge
                                            </button>}
                                    </div>
                                </div>}
                            {transaction.stripeUrl &&
                                <div className={classes.entry}>
                                    <div className={classes.field}>
                                        More info
                                    </div>
                                    <button
                                        onClick={() => { window.open(transaction.stripeUrl, "_black"); }}
                                        className={appClasses.buttonLink}
                                    >
                                        Open in Stripe
                                        <ExternalLink className={classes.externalIcon} />
                                    </button>
                                </div>}
                        </div>
                    </div>
                </Fragment>
            }
        </ModalView>
    );
};

const MoneyTransactionViewStyled = withStyles(MoneyTransactionView, moneyTransactionViewJss);

// noinspection JSUnusedLocalSymbols
const MoneyTransactionViewWithData = withAsycnData(MoneyTransactionViewStyled,
    (query: { id: string, bookingId?: string }) => TransactionsData.instance.get(query.id)
        .then(transaction => ({ transaction, bookingId: query.bookingId })) as Promise<{ transaction?: Transaction, bookingId?: string }>);

// noinspection JSUnusedLocalSymbols
export const MONEY_TRANSACTION_VIEW: IViewRouteConfig<{ id: string, bookingId?: string }> =
{
    path: BOOKING_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/payment/:paymentId")),
    propsFromMatch: (match: any) => ({ id: match.params.paymentId, bookingId: match.params.id || match.params.tripId }),
    propsToPath: (params: { id: string }) => `/payment/${params.id}`,
    navLabel: (routeProps: { viewProps: { id: string, bookingId?: string } }) => "Payment",
    render: ({ viewProps, navHistory, waitFor }) => {
        return <MoneyTransactionViewWithData
            {...viewProps}
            onRequestClose={() => {
                navHistory.pop();
            }}
        />;
    },
    isModal: true
};