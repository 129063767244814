import React, { useRef } from "react";
import TableSortLabel from "@mui/material/TableSortLabel";
import { default as Table, ITableColSpec } from "../view/Table";
import User from "../model/User";
import { ReactComponent as IconAdd } from '../images/ic-add.svg';
import Filter, { SearchField, SortOrder } from "../data/Filter";
import Util from "../util/Util";
import FormatUtil from "../util/FormatUtil";
import ExportButton, { ExportFormat } from "../export/ExportButton";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { usersViewJss } from "./UsersView.jss";
import Paginate from "../paginate/Paginate";
import Loading from "../view/Loading";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import ItemsResult from "../model/ItemsResult";
import { IFieldAutocomplete, IViewRouteConfig } from "../nav/IViewRouteConfig";
import { default as UsersData } from "../data/UsersData";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable, from } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { PART_SEARCH_VIEW } from "../search/SearchView";
import { PART_DETAIL_VIEW } from "./UserView";
import { PARTICIPANTS_VIEW_PATH } from "./UserViewConstants";
import ViewRestyled from "../view/ViewRestyled";
import SearchBox, { Item } from "../search/SearchBox";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import Tooltip from "../uiutil/Tooltip";
import {
    AVAILABLE_REWARDS_TOOLTIP,
    LIFETIME_REWARDS_TOOLTIP,
    MONEY_BALANCE_TOOLTIP,
    REWARDS_BALANCE_TOOLTIP
} from "./BalanceTable";
import PageSizeSelect from "../paginate/PageSizeSelect";
import AdminProfile, { adminProfile, ModelOperation, ModelType } from "../account/AdminProfile";
import { getClientIDPath, getOrgIDPath } from "../app/App";
import { genClassNames } from "tripkit-react";
import { ADD_TO_ORG_VIEW, UserSearchPurpose } from "./AddToOrgView";
import { descendentOrg, isRootOrg, orgFromId } from "../app/OrgSelectorHelpers";
import MemoScroll, { MemoScrollRef } from "../uiutil/MemoScroll";
import { Client } from "../model/Client";
import { i18n } from "../i18n/TKI18nConstants";
import { CheckboxStyled } from "../booking/BookingsView";
import { ShortIdCell } from "../booking/BookingsTable";
import FilterPopup, { FilterConfig } from "../FilterPopup";
import { DefaultSelect } from "../rewards/StatusSelect";
import { useInitiativeOptions } from "../data/InitiativesData";

type IStyle = ReturnType<typeof usersViewJss>;

export interface IProps extends WithClasses<IStyle> {
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    usersResult?: ItemsResult<User>;
    onSelect?: (id: string) => void;
    onCreate?: () => void;
    onAddToOrg?: () => void;
    waiting?: boolean;
    onExportData?: (filter: Filter, format: ExportFormat) => Promise<void>;
    onRefreshClick?: () => void;
    selectedClientID?: string;
    clients?: Client[];
    selectedOrgID?: string;
}

export const EXPORT_USERS_TOOLTIP = (formats: ExportFormat[] = ["json", "csv"]) => {
    const formatsText = formats.length > 1 ?
        formats.slice(0, formats.length - 1).map(format => format.toUpperCase()).join(", ") + " or " + formats[formats.length - 1].toUpperCase() + " formats" :
        formats[0].toUpperCase() + " format";
    return `Export users data in ${formatsText}, considering the applied filter.`;
};

type UsersTableColId = "short_id" | "profile_picture" | "name" | "ada_id" | "email" | "phone" | "bundle" | "money_balance" | "rewards_balance" | "available_rewards" | "lifetime_rewards" | "client_app" | "orgs" | "initiatives";

/**
 * Order is relevant, too. Notice the order of charge column changes depending on the tableId.
 * No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(adminProfile: AdminProfile): UsersTableColId[] {
    const insIf = (field: UsersTableColId, cond: boolean) => Util.insertIf<UsersTableColId>(cond, field);
    const dafaultColumns: UsersTableColId[] = [
        "profile_picture",
        "name",
        ...insIf("email", !adminProfile.features.bundles),
        "phone", "bundle", "money_balance", "rewards_balance", "available_rewards", "lifetime_rewards", "client_app", "orgs"
    ];
    return dafaultColumns;
}

const UsersView: React.FunctionComponent<IProps> = (props: IProps) => {

    const { usersResult, selectedClientID, clients, selectedOrgID, onFilterChange, onSelect, onCreate, onAddToOrg, onExportData, onRefreshClick, classes, appClasses, filter } = props;

    const initiativeOptions = useInitiativeOptions(selectedClientID);

    function handlePageClick(selectedItem: { selected: number }) {
        if (onFilterChange) {
            memoScrollRef.current?.reset();
            const filterUpdate = Util.iAssign(props.filter, { page: selectedItem.selected + 1 });
            onFilterChange(filterUpdate);
        }
    }

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = props.filter.sortOrder;
            const filterUpdate = Util.iAssign(props.filter,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate)
        }
    }
    const memoScrollRef = useRef<MemoScrollRef>(null);
    const selectedOrg = selectedOrgID && adminProfile.orgs ? orgFromId(selectedOrgID, adminProfile.orgs) : undefined;
    const page = filter && filter.page ? filter.page : 1;
    const pageCount = usersResult ? usersResult.pageCount + (usersResult.more ? 1 : 0) : undefined;
    let body;
    if (!usersResult || (usersResult.count === 0 && usersResult.waitingMore)) {
        body = <Loading />;
    } else if (usersResult.count === 0) {
        body =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        const tableContent: ITableColSpec<User, UsersTableColId>[] = [
            {
                id: "profile_picture",
                name: "Profile Picture",
                label: null,
                cellValue: item => item.pictureSmall && <img src={item.pictureSmall} />,
                cellClass: classes.pictureCell
            },
            {
                id: "name",
                name: "Name",
                label: (
                    <TableSortLabel
                        active={true}
                        direction={filter.sortOrder || SortOrder.ASC}
                        onClick={handleSortOrderClick}
                    >
                        Name
                    </TableSortLabel>
                ),
                cellValue: (item: User) => item.name
            },
            {
                id: "ada_id",
                name: "ADA ID",
                cellValue: (item: User) => item.appData?.externalId,
            },
            {
                id: "email",
                name: "Email",
                cellValue: (item: User) => item.email
            },
            {
                id: "phone",
                name: "Phone",
                cellValue: (item: User) => item.phoneNumber,
            },
            {
                id: "bundle",
                name: i18n.t("Bundle"),
                cellValue: (item: User) => item.currentBundle && item.currentBundle.name,
                available: adminProfile.features.bundles
            },
            {
                id: "money_balance",
                name: i18n.t("Money.balance"),
                label: (
                    <Tooltip title={MONEY_BALANCE_TOOLTIP}>
                        {i18n.t("Money.balance")}
                    </Tooltip>
                ),
                cellValue: (item: User) => item.balance && item.balance.rawBalance !== undefined &&
                    FormatUtil.toMoney(item.balance.rawBalance, { nInCents: true }),
                available: adminProfile.features.bundles
            },
            {
                id: "rewards_balance",
                name: "Rewards balance",
                label: (
                    <Tooltip title={REWARDS_BALANCE_TOOLTIP}>
                        Rewards balance
                    </Tooltip>
                ),
                cellValue: (item: User) => item.balance && item.balance.rewardsBalance !== undefined
                    && FormatUtil.toPoints(item.balance.rewardsBalance),
                available: adminProfile.features.rewards
            },
            {
                id: "available_rewards",
                name: "Available rewards",
                label: (
                    <Tooltip title={AVAILABLE_REWARDS_TOOLTIP}>
                        Available rewards
                    </Tooltip>
                ),
                cellValue: (item: User) => item.balance && item.balance.availableRewardsBalance !== undefined
                    && FormatUtil.toPoints(item.balance.availableRewardsBalance),
                available: adminProfile.features.rewards
            },
            {
                id: "lifetime_rewards",
                name: "Lifetime rewards",
                label: (
                    <Tooltip title={LIFETIME_REWARDS_TOOLTIP}>
                        Lifetime rewards
                    </Tooltip>
                ),
                cellValue: (item: User) => item.balance && item.balance.lifetimeRewardsBalance !== undefined
                    && FormatUtil.toPoints(item.balance.lifetimeRewardsBalance),
                available: adminProfile.features.rewards
            },
            {
                id: "client_app",
                name: i18n.t("Client.app"),
                cellValue: item => item.clientIds.map(clientId => clients?.find(client => client.clientID === clientId)?.clientName ?? clientId).join(", "),
                available: adminProfile.isSuperApp,
                visible: !selectedClientID
            },
            {
                id: "orgs",
                name: i18n.t("Organizations"),
                available: adminProfile.features.organizations
                    && !(adminProfile.isOrgUser && adminProfile.orgs && adminProfile.orgs.filter(o => o.fullAccess).length === 1),    // Not available for org users that have access to a single organization.
                cellValue: user => {
                    const orgNames = user.appData?.organizations
                        ?.map(orgId => adminProfile.orgs?.find(o => o.id === orgId)?.name) // Map to organization names (or `undefined` if not accessible by the admin).
                        ?.filter(o => o !== undefined); // Filter orgs not accessible by the admin.                        
                    const joined = orgNames?.join(", ");
                    return orgNames && orgNames.length > 1 ?
                        <Tooltip title={joined!}>
                            <div className={classes.orgsCell}>{joined}</div>
                        </Tooltip> : joined;
                },
                visible: !selectedOrgID ||
                    (adminProfile.orgs && adminProfile.orgs.some(o => o.id !== selectedOrgID && orgFromId(selectedOrgID, adminProfile.orgs!) && descendentOrg(o, orgFromId(selectedOrgID, adminProfile.orgs!)!))),
                width: 140,
                cellClass: classes.orgsCell,
                style: { whiteSpace: 'nowrap!important' }
            },
            {
                id: "initiatives",
                name: i18n.t("Initiatives"),
                available: adminProfile.features.trackTripInitiative22828,
                cellValue: user => {
                    const joined = user.initiatives
                        .map(initiative => initiative.title)
                        .join(", ");
                    return user.initiatives && user.initiatives.length > 1 ?
                        <Tooltip title={joined!}>
                            <div className={classes.orgsCell}>{joined}</div>
                        </Tooltip> : joined;
                },
                width: 140,
                cellClass: classes.orgsCell
            },
            {
                id: "short_id",
                name: "ID",
                cellValue: (value: User) =>
                    value.shortId &&
                    <ShortIdCell shortId={value.shortId} />,
                width: 145
            }
        ];
        body =
            <React.Fragment>
                <MemoScroll id={"usersList"} className={classes.tableContainer} ref={memoScrollRef} hasData={usersResult && !usersResult.waitingMore}>
                    <Table
                        tableId={adminProfile.app + "-" + usersViewId}
                        contentSpec={tableContent}
                        defaultColumns={getDefaultColumns(adminProfile)}
                        items={usersResult.items}
                        onClick={(item: User) => onSelect?.(item.id)}
                        configurable={true}
                    />
                    {usersResult && usersResult.waitingMore &&
                        <div className={classes.waitingMore}><Loading /></div>}
                </MemoScroll>
            </React.Fragment>
    }
    const isAllAppsView = adminProfile.isSuperApp && !selectedClientID;
    const hasAccessToAllOrgs = !adminProfile.features.organizations || adminProfile.orgs?.find(o => isRootOrg(o))?.fullAccess;
    const canAddUser = !isAllAppsView && (!adminProfile.isOrgUser || hasAccessToAllOrgs || (selectedOrg && selectedOrg.fullAccess));
    let addUserBtn = adminProfile.itemAuth(ModelType.User, ModelOperation.create) &&
        <button onClick={hasAccessToAllOrgs ? onCreate : onAddToOrg} className={appClasses.buttonOk} disabled={!canAddUser}>
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            {i18n.t("Add.user")}
        </button>;
    if (isAllAppsView) {
        addUserBtn = addUserBtn &&
            <Tooltip title={"Select a client app on the left nav bar to be able to add a user."}>
                <div>
                    {addUserBtn}
                </div>
            </Tooltip>
    }
    const filterPopupConfigs: FilterConfig[] = [
        ...Util.insertIf(adminProfile.features.trackTripInitiative22828,
            {
                label: "Initiative",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={initiativeOptions ?? []}
                            value={filter.initiativeId}
                            onChange={value => {
                                const newFilter = Util.iAssign(filter, { initiativeId: value, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel="All"
                            isSearchable
                            isDisabled={!initiativeOptions}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.initiativeId && initiativeOptions?.find(option => option.value === filter.initiativeId)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { initiativeId: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            }),
        ...Util.insertIf(false && adminProfile.features.suspendUser21819,
            {
                label: FormatUtil.toFirstUpperCase(i18n.t("suspended")) + " only",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterCheckbox}>
                        <CheckboxStyled
                            checked={filter.userDisabled}
                            onChange={e => {
                                onFilterChange?.(Util.iAssign(filter, { userDisabled: e.target.checked }));
                            }}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.userDisabled ? FormatUtil.toFirstUpperCase(i18n.t("suspended")) + " only" : undefined,
                showLabelInSummary: false,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { userDisabled: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            })
    ];
    return (
        <ViewRestyled
            title={i18n.t("Rider.users")}
            right={
                <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} style={{ marginRight: '120px' }}>
                    {addUserBtn}
                    {/* {addUserToOrgBtn} */}
                </div>
            }
        >
            <div className={classes.filters}>
                {filterPopupConfigs.length > 0 &&
                    <FilterPopup
                        filter={props.filter}
                        onFilterChange={props.onFilterChange!}
                        filterConfigs={filterPopupConfigs}
                    />}
                <div className={classes.searchbox}>
                    <SearchBox />
                </div>
                {adminProfile.features.suspendUser21819 &&
                    <div className={classes.disabledFilter}>
                        <label>{FormatUtil.toFirstUpperCase(i18n.t("suspended")) + " only"}</label>
                        <CheckboxStyled
                            checked={filter.userDisabled}
                            onChange={e => {
                                onFilterChange?.(Util.iAssign(filter, { userDisabled: e.target.checked }));
                            }}
                        />
                    </div>}
                {onExportData &&
                    <div className={classes.export}>
                        <ExportButton filter={filter} onExportData={onExportData} helpTooltip={EXPORT_USERS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />
                    </div>}
                <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                    <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                        <label className={genStyles.charSpace} style={{ whiteSpace: 'nowrap' }}>{i18n.t("Page.size")}</label>
                        <PageSizeSelect
                            value={filter.pageSize}
                            onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                        />
                    </div>
                    {onRefreshClick &&
                        <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                            onClick={onRefreshClick}
                        >
                            <IconSpin />
                        </button>}
                </div>
            </div>
            {body}
            <div className={appClasses.paginatePanel}>
                {!!pageCount &&
                    <Paginate
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        page={page}
                    />}
            </div>
        </ViewRestyled>
    );
}

const UsersViewStyled = withStyles(UsersView, usersViewJss);

// noinspection JSUnusedLocalSymbols
const UsersViewWithData = withAsyncDataObs(UsersViewStyled,
    (query: { filter: Filter }) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
         * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
         * All this won't be necessary if orgs come with the admin profile at the login.
         */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>
            UsersData.instance.watchQuery(query.filter)
                .pipe(map((result?: ItemsResult<User>) => ({ usersResult: result }))) as Observable<{ usersResult?: ItemsResult<User> }>))
            .pipe(concatAll() as any) as any
);

// Simpler version with Promises, since it seems Observers are not required since are not being used
// to make gql queries.
// const UsersViewWithData = withAsycnData(UsersViewStyled,
//     (query: { filter: Filter }) => {
//         return (adminProfile.features.organizations ? adminProfile.orgsP.then(orgs => { console.log(orgs); return orgs; }) : Promise.resolve())
//             .then(() => new Promise<{ usersResult?: ItemsResult<User>; }>((resolve, reject) => {
//                 UsersData.instance.watchQuery(query.filter).subscribe({
//                     next(value) {
//                         resolve({ usersResult: value });
//                     },
//                     error(err) {
//                         reject(err);
//                     },
//                 });
//             })
//             );
//     }
// )

const usersViewId = "USERS_VIEW";

export const PARTICIPANTS_VIEW: IViewRouteConfig<{ filter?: Filter }> = {
    path: PARTICIPANTS_VIEW_PATH,
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(usersViewId) || 10,
                page: 1,
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { filter: filter };
    },
    propsToPath: (props: { filter?: Filter }) => "/parts" + (props.filter ? "/filter/" + Util.stringifyFilter(props.filter) : ""),
    navLabel: () => i18n.t("Rider.users"),
    render: ({ viewProps, navHistory, profile, onProfileChange, selectedClientID, clients, selectedOrgID }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(usersViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(PARTICIPANTS_VIEW,
                Util.iAssign(viewProps, { filter: update }))
        };
        const onSelect = (id: string) => {
            return navHistory.push(PART_DETAIL_VIEW, { id: id });
        };
        const onCreate = () => navHistory.push<{ purpose: UserSearchPurpose }>(ADD_TO_ORG_VIEW, { purpose: "searchBeforeAdd" });
        const onAddToOrg = () => navHistory.push<{ purpose: UserSearchPurpose }>(ADD_TO_ORG_VIEW, { purpose: "addToOrg" });
        const onExport: (filter: Filter, format: ExportFormat) => Promise<void> = (filter, format) => {
            return UsersData.instance.getExportData(filter, { limit: 500 })
                .then((users: User[]) => {
                    const usersJSON = Util.serialize(users);
                    const usersJSONForCSV = Util.serialize(users)
                        .map(userJSON => {
                            userJSON = {
                                "Rider ID": userJSON.shortId,
                                ...userJSON
                            };
                            delete userJSON.Id;
                            delete userJSON.shortId;
                            // Transform balance fields in cents to dollars.
                            userJSON.balance && Object.keys(userJSON.balance).forEach(key => {
                                if (key.toLowerCase().includes("balance") && typeof userJSON.balance[key] === 'number') {
                                    userJSON.balance[key] = FormatUtil.fromCents(userJSON.balance[key]);
                                }
                            });
                            return userJSON;
                        });
                    switch (format) {
                        case "json": return FormatUtil.downloadObjectAsJson(usersJSON, "users");
                        case "csv": return FormatUtil.downloadObjectAsCSV(usersJSONForCSV, { exportName: "users" });
                    }
                });
        };
        // Reset page number after a reload.
        if (filter.page > 1 && UsersData.instance.isEmpty()) {
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const usersViewRef = React.createRef<any>();
        const onRefresh = () => {
            UsersData.instance.invalidateUserListCaches();
            usersViewRef.current.refresh(true);
        };
        return (
            <UsersViewWithData
                filter={filter!}
                onFilterChange={onFilterChange}
                onSelect={onSelect}
                onCreate={onCreate}
                onAddToOrg={onAddToOrg}
                onExportData={onExport}
                onRefreshClick={onRefresh}
                ref={usersViewRef}
                selectedClientID={selectedClientID}
                clients={clients}
                selectedOrgID={selectedOrgID}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, profile, selectedClientID }) => {
        const filter = viewProps.filter!;
        const fields: IFieldAutocomplete[] = [];
        // if (profile.features.bundles && profile.itemAuth(ModelType.Bundle, ModelOperation.read)) {
        //     fields.push({ field: SearchField.BUNDLE, predictor: bundlePredictor });
        //     fields.push({ field: SearchField.UPCOMING_BUNDLE, predictor: bundlePredictor });
        // }
        return {
            fields: fields,
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                const searchQuery = filter.search;
                if (searchQuery && (searchQuery.field === SearchField.ID)) {
                    if (searchQuery.label !== "") {
                        navHistory.push(PART_SEARCH_VIEW, { id: searchQuery.label });
                        // TODO: re-enable
                        // if (this.searchBoxRef) {
                        //     this.searchBoxRef.setValue("");
                        // }
                    }
                    return;
                }
                navHistory.replace(PARTICIPANTS_VIEW, { filter });
            },
            tooltip: "Search by name, user ID, email or phone number"
        }
    }
};