import React, { useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import ModalView from "../view/ModalView";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Transaction, { TransactionNote } from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import UsersData from "../data/UsersData";
import { BOOKING_DETAIL_VIEW_PATH } from "./TransactionsViewConstants";
import { cancelBookingViewJss } from "./CancelBookingView.jss";
import Util from "../util/Util";
import withAsycnData from "../data/WithAsyncData";
import UIUtil from "../util/UIUtil";
import DateTimeUtil from "../util/DateTimeUtil";
import { adminProfile } from "../account/AdminProfile";
import { CheckboxStyled } from "./BookingsView";
import FormatUtil from "../util/FormatUtil";
import { ReactComponent as IconLocked } from '../images/ic-locked.svg';
import Tooltip from "../uiutil/Tooltip";
import { canCancel } from "./BookingViewHelpers";

type IStyle = ReturnType<typeof cancelBookingViewJss>;

interface IProps extends WithClasses<IStyle> {
    booking?: Transaction;
    onRequestClose?: (data?: { update?: Transaction }) => void;
}

export const CancelBookingView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { booking = new Transaction(), classes, appClasses, onRequestClose } = props;
    const [externalNote, setExternalNote] = useState<string>("");
    const [internalNote, setInternalNote] = useState<string>("");
    const bookingType = booking.relatedBookings.find(rb => rb.type === "OUTBOUND") ? "RETURN" : booking?.relatedBookings.find(rb => rb.type === "RETURN") ? "OUTBOUND" : undefined;
    const cancelActions = booking.actions.filter(action => action.action === "CANCEL");
    const cancelAction = cancelActions[0];
    const [cancelRelated, setCancelRelated] = useState<boolean>((!!cancelAction?.withRelated && bookingType === "OUTBOUND") || cancelAction?.withRelated?.onlyChecked === true);    // Checked by default to cancel 'Return' along with 'Outbound'.    
    if (cancelActions.length === 0) {    // This should never happen, since if so the booking couldn't be canceled.
        return null;
    }
    const relatedAlert = cancelAction?.withRelated && (cancelRelated ? cancelAction.withRelated.alertChecked : cancelAction.withRelated.alertUnchecked);
    const updateBooking = () => {
        const update = Util.iAssign(props.booking!, { status: cancelAction.newStatus });
        let internalTransNote: TransactionNote | undefined;
        if (internalNote) {
            internalTransNote = Util.iAssign(new TransactionNote(), {
                text: internalNote,
                timestamp: DateTimeUtil.getNow().utc().toISOString(),
                creatorEmail: adminProfile.email,
                internal: true,
                providerMode: adminProfile.tspMode
            });
            update.notes = [internalTransNote].concat(update.notes);
        }
        let externalTransNote: TransactionNote | undefined;
        if (externalNote) {
            externalTransNote = Util.iAssign(new TransactionNote(), {
                text: externalNote,
                timestamp: DateTimeUtil.getNow().utc().toISOString(),
                creatorEmail: adminProfile.email,
                internal: false,
                providerMode: adminProfile.tspMode
            });
            update.notes = [externalTransNote].concat(update.notes);
        }
        update.updateRelated = cancelRelated;
        onRequestClose?.({ update })
    };

    return (
        <ModalView
            title={`${canCancel(booking).actionLabel ?? "Cancel"}${bookingType ? " " + FormatUtil.toFirstUpperCase(bookingType) : ""} booking?`}
            onRequestClose={props.onRequestClose}
        >
            <div className={appClasses.form}>
                {cancelAction?.alert &&
                    <div className={appClasses.formGroup} style={{ marginTop: '10px' }}>
                        <label htmlFor="note">⚠️</label>
                        <div>
                            {cancelAction.alert}
                        </div>
                    </div>}
                {cancelAction?.withRelated && cancelAction?.withRelated?.onlyChecked === false &&
                    <div className={appClasses.formGroup}>
                        <label>{`Also ${canCancel(booking).actionLabel?.toLowerCase() ?? "cancel"} ` + booking!.relatedBookings.map(rb => FormatUtil.toFirstUpperCase(rb.type)).join(", ")}</label>
                        <CheckboxStyled
                            checked={cancelRelated}
                            onChange={e => {
                                setCancelRelated(e.target.checked);
                            }}
                        />
                    </div>}
                {relatedAlert &&
                    <div className={appClasses.formGroup} style={{ marginTop: '10px' }}>
                        <label htmlFor="note">⚠️</label>
                        <div>
                            {relatedAlert}
                        </div>
                    </div>}
                <div className={appClasses.formGroup} style={{ marginRight: '30px' }}>
                    <Tooltip className={appClasses.cursorHelp} title={"Note to be delivered to the rider."}>
                        <label htmlFor="externalNote">Note to rider</label>
                    </Tooltip>
                    <textarea
                        name="externalNote"
                        value={externalNote}
                        onChange={(e) => setExternalNote(e.currentTarget.value)}
                        placeholder="Enter a note to the rider here..."
                    />
                </div>
                <div className={appClasses.formGroup} style={{ marginTop: '10px' }}>
                    <label htmlFor="note">💡</label>
                    <div>
                        This note will be shown to the user within the app and also informed via email notification.
                    </div>
                </div>
                {(adminProfile.features.internalTripAndRiderNotes22953 ? adminProfile.isSuperAdmin : true) &&
                    <div className={appClasses.formGroup} style={{ marginRight: '30px' }}>
                        <Tooltip className={appClasses.cursorHelp}
                            title={"Note just visible to other admins, not to the rider."}>
                            <label htmlFor="internalNote">Internal note</label>
                        </Tooltip>
                        <textarea name="internalNote"
                            value={internalNote}
                            onChange={(e) => {
                                setInternalNote(e.currentTarget.value);
                            }}
                            placeholder="Enter an internal note here..."
                        />
                        <Tooltip title={"Internal note, not visible to the rider."} className={appClasses.cursorHelp}>
                            <IconLocked className={classes.lock} />
                        </Tooltip>
                    </div>}
                <div className={classes.footer}>
                    <button className={appClasses.buttonCancel}
                        onClick={() => props.onRequestClose?.()}>No
                    </button>
                    <button className={appClasses.buttonAdd}
                        onClick={updateBooking}>
                        Yes
                    </button>
                </div>
            </div>
        </ModalView >
    );
};

const CancelBookingViewStyled = withStyles(CancelBookingView, cancelBookingViewJss);

// noinspection JSUnusedLocalSymbols
const CancelBookingViewWithData = withAsycnData(CancelBookingViewStyled,
    async (query: { id: string }) => {
        const booking = await TransactionsData.instance.get(query.id);
        return ({ booking } as { booking?: Transaction });
    });

// noinspection JSUnusedLocalSymbols
export const BOOKING_CANCEL_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: ["*/cancel/:cancelId"].concat(BOOKING_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/cancel"))),
    propsFromMatch: (match: any) => ({ id: match.params.cancelId ?? match.params.id ?? match.params.tripId }),
    propsToPath: (params: { id: string }) => `/cancel/${params.id}`,
    navLabel: (routeProps: { viewProps: { id: string } }) => "Cancel booking",
    render: ({ viewProps, navHistory, waitFor }) => {
        return (
            <CancelBookingViewWithData
                id={viewProps.id}
                onRequestClose={({ update } = {}) => {
                    if (update) {
                        waitFor(TransactionsData.instance.update(update!)
                            .then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                update.relatedBookings.forEach(rb =>
                                    TransactionsData.instance.invalidateTransCacheById(rb.bookingId));
                                // To refresh user balance when we are on UserView.
                                // TODO: check if this change still triggers a re-render of UserView if
                                // WithAsyncData.componentDidUpdate does a proper props compare.
                                update.userId && UsersData.instance.invalidateUserCache(update.userId);
                            }))
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            });
                    } else {
                        navHistory.pop();
                    }
                }}
                // Since on constructor it determines if it's an editing or a creation based on if the user (data) is
                // present or not (undefined). Also avoids the need to display the editing view in a waiting state.
                renderWhenData={true}
                // Avoid re-construction of view after save clicked (notice WithAsyncData updates on any url update due
                // to strong props compare).
                undefineOnUpdate={false}
            />
        );
    },
    isModal: true
};