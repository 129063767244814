import { genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const editTransViewJss = (theme: Theme) => ({
    form: {
        '& label': {
            width: '150px',
            textAlign: 'left'
        },
        '& input, .LocationBox, $select .select__control, textarea': {
            ...genJss.borderRadius(5),
            border: 'none',
            backgroundColor: '#f6f6f6',
            lineHeight: '40px',
            fontSize: '16px',
            padding: '0 10px'
        },
        '& .LocationBox input': {
            lineHeight: '40px!important',
            fontSize: '16px',
            paddingLeft: '0'
        },
        '& button.LocationBox-btnClear': {
            background: 'none',
            border: 'none',
            padding: '0',
            height: '17px',
            minWidth: 'initial'
        },
        '& input$priceInput': {
            padding: '0 25px',
            width: '140px'
        }
    },

    priceInput: {

    },

    currency: {
        position: 'absolute',
        marginLeft: '7px',
        lineHeight: '40px'
    },

    row: {
        marginTop: '30px',
        position: 'relative',
        maxWidth: '800px'
    },

    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },

    departDateInput: {
        width: '170px',
        marginRight: '59px'
    },

    arriveDateInput: {
        width: '170px',
        marginRight: '59px'
    },

    fade: {
        opacity: '.5'
    },

    select: {
        width: '300px',
        '& .select__value-container': {
            padding: '0',
            display: 'flex'
        },
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        }
    },

    locContainer: {
        '& > div': {
            width: '100%'
        }
    },

    noteSection: {
        '& textarea': {
            padding: '10px',
            lineHeight: '30px',
            textAlign: 'left'
        }
    },

    charHeight: {
        minHeight: '19px'
    },

    another: {
        marginRight: '40px'
    }

});