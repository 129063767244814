import React, { useContext, useState } from "react";
import genStyles from "../css/general.module.css";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import classNames from "classnames";
import Filter, { SearchField } from "../data/Filter";
import Util from "../util/Util";
import Bundle, { BundleStatus } from "../model/Bundle";
import BundleTable from "./BundleTable";
import FormatUtil from "../util/FormatUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bundleViewJss } from "./BundleView.jss";
import View from "../view/View";
import Loading from "../view/Loading";
import { identityPredictor, IViewRouteConfig } from "../nav/IViewRouteConfig";
import { BUNDLE_COPY_VIEW, BUNDLE_EDIT_VIEW, PURCHASE_ONCE_LABEL } from "./EditBundleView";
import DateTimeUtil from "../util/DateTimeUtil";
import BundlesData from "../data/BundlesData";
import * as moment from 'moment-timezone';
import withAsycnData from "../data/WithAsyncData";
import { BUNDLES_VIEW } from "./BundlesView";
import { BookingsViewWithData, FiltersVisibility, onBookingsExport, transViewFromType } from "../booking/BookingsView";
import { Item } from "../search/SearchBox";
import { TransType } from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import Transaction from "../model/Transaction";
import UIUtil from "../util/UIUtil";
import BundleConfigsTable from "./BundleConfigsTable";
import { ModelOperation, ModelType, adminProfile } from "../account/AdminProfile";
import { Client } from "../model/Client";
import { i18n } from "../i18n/TKI18nConstants";
import { AppContext } from "../app/App";
import { ReactComponent as IconAddImage } from "../images/ic-add-photo.svg";
import ViewTitleWithId from "../view/ViewTitleWithId";

type IStyle = ReturnType<typeof bundleViewJss>;

export interface IProps extends WithClasses<IStyle> {
    bundle?: Bundle;
    onEdit?: () => void;
    onCopy?: () => void;
    onRemove?: () => void;
    renderTrans?: () => React.ReactNode;
    selectedClientID?: string;
    clients?: Client[];
    refresh?: (undefineOnUpdate?: boolean) => Promise<void>;
}

const BundleView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { classes, appClasses, bundle, selectedClientID, clients } = props;
    const { navHistory } = useContext(AppContext);
    const bundleDetail = bundle && bundle.tmodes && bundle.tmodes.length !== 0 &&
        <div className={classNames(genStyles.flex, genStyles.column)} style={{ maxWidth: '1100px', marginLeft: '-16px' }}>
            <BundleTable bundle={bundle} />
            <div style={{ marginTop: '30px' }} />
            <BundleConfigsTable value={bundle} />
        </div>;

    if (!bundle) {
        return <Loading />;
    }

    const subtitleTexts: string[] = [];
    if (bundle.currentUserCount !== undefined) {
        subtitleTexts.push(bundle.currentUserCount + " Users");
    }
    if (bundle.futureUserCount !== undefined && bundle.futureUserCount !== 0) {
        subtitleTexts.push(bundle.futureUserCount + " Upcoming Users");
    }
    if (bundle.futureUserCount !== undefined && bundle.futureUserCount !== 0) {
        subtitleTexts.push(bundle.futureUserCount + " Upcoming Users");
    }
    subtitleTexts.push(bundle.status === BundleStatus.ACTIVE ? "Active" : "Inactive");
    if (bundle.restrictedOnlyTo !== undefined) {
        subtitleTexts.push(bundle.restrictedOnlyTo.length === 0 ? "Visible to nobody" :
            "Visible only to " + bundle.restrictedOnlyTo.reduce((listS, type, i) =>
                listS + (i > 0 ? ", " : "") + FormatUtil.toFirstUpperCase(type), ""));
    }
    if (bundle.subscriptionPurchaseOnce) {
        subtitleTexts.push(PURCHASE_ONCE_LABEL);
    }
    if (adminProfile.isSuperApp && !selectedClientID) {
        const clientAppName = clients?.find(client => client.clientID === bundle.clientId)?.clientName;
        clientAppName && subtitleTexts.push(clientAppName);
    }
    const subtitle =
        <span>
            {subtitleTexts.reduce((elems: React.ReactElement[], text: string, i: number) => {
                i > 0 && elems.push(<span className={classNames(genStyles.charSpace, genStyles.charSpaceLeft)} key={i + "a"}>⋅</span>);
                elems.push(<span key={i + "b"}>{text}</span>);
                return elems;
            }, [])}
        </span>;
    return (
        <View
            title={bundle.shortId ? <ViewTitleWithId title={bundle.name} shortId={bundle.shortId} /> : bundle.name}
            subtitle={subtitle}
        >
            <div className={classNames(genStyles.flex, classes.fieldsGrid)}>
                {bundle.note &&
                    <div className={classNames(classes.entry, classes.noteSection, genStyles.flex, genStyles.column, genStyles.alignStart)}>
                        <div className={classes.field}>Description</div>
                        <div className={classes.value}>
                            {bundle.note}
                        </div>
                    </div>}
                {adminProfile.features.trackTripInitiative22828 &&
                    <div className={classes.entry}>
                        <div className={classes.field}>{i18n.t("Initiative")}</div>
                        <div className={classes.value}>
                            {bundle.initiative?.title || 'None'}
                        </div>
                    </div>}
                {adminProfile.features.bundleImg20922 &&
                    <div className={classNames(classes.noteSection, genStyles.flex, genStyles.column, genStyles.alignStart)} style={{ marginLeft: 'auto' }}>
                        <div className={classes.field}>Sponsor Image</div>
                        <div className={classNames(classes.value, classes.sponsorValue)}>
                            {!bundle.sponsorImageUrl ?
                                <button className={classes.sponsorImgPlaceholder} onClick={() => navHistory.push(BUNDLE_EDIT_VIEW, { id: bundle.id!, section: 'sponsor' })}>
                                    <IconAddImage />
                                </button>
                                :
                                <div className={classes.sponsorImgContainer}>
                                    <img src={bundle.sponsorImageUrl} alt="Sponsor" className={classes.sponsorImg} />
                                    <button className={classes.addImgBtn} onClick={() => navHistory.push(BUNDLE_EDIT_VIEW, { id: bundle.id!, section: 'sponsor' })}>
                                        <IconEdit />
                                    </button>
                                </div>}
                            <div style={{ marginTop: '10px' }}>
                                {bundle.sponsorTitle}
                            </div>
                        </div>
                    </div>}
            </div>
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}>
                {bundleDetail}
                {props.renderTrans && props.renderTrans()}
            </div>
            {
                !bundle.isPayGo() &&
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    {props.onEdit && adminProfile.itemAuth(ModelType.Bundle, ModelOperation.update) &&
                        <button onClick={props.onEdit} className={appClasses.buttonOk}>
                            <IconEdit className={classNames(classes.iconEdit, genStyles.svgPathFillCurrColor)} />
                            {i18n.t("Edit.bundle")}
                        </button>}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                        {props.onCopy && adminProfile.itemAuth(ModelType.Bundle, ModelOperation.create) &&
                            <button onClick={props.onCopy} className={appClasses.buttonAdd}>
                                {i18n.t("Copy.bundle")}
                            </button>}
                        {props.onRemove && bundle.userCount === 0 && adminProfile.itemAuth(ModelType.Bundle, ModelOperation.delete) &&
                            <button
                                className={classNames(appClasses.buttonDelete, genStyles.separationLeft)}
                                onClick={props.onRemove}
                            >
                                {i18n.t("Remove.bundle")}
                            </button>
                        }
                    </div>
                </div>
            }
        </View >
    );
}

const BundleViewStyled = withStyles(BundleView, bundleViewJss);

// noinspection JSUnusedLocalSymbols
const BundleViewNavWithData = withAsycnData(((props: { bundle?: Bundle }) => <>{props.bundle && props.bundle.name ? props.bundle.name : ""}</>),
    (query: { id: string }) => BundlesData.instance.get(query.id).then((bundle: Bundle | undefined) => { return { bundle } }) as Promise<{ bundle?: Bundle }>);

const BundleViewWithData = withAsycnData(BundleViewStyled,
    (query: { id?: string }) => query.id ? BundlesData.instance.get(query.id).then((bundle: Bundle | undefined) => { return { bundle } }) as Promise<{ bundle?: Bundle }> : Promise.resolve({ bundle: undefined }));

const bundleViewId = "BUNDLE_VIEW";

export const BUNDLE_DETAIL_VIEW: IViewRouteConfig<{ id?: string, filter?: Filter }> =
{
    path: BUNDLES_VIEW.path.reduce((acc: string[], path: string) => {
        return acc.concat([
            path + "/bundleId/:bundleId/filterTrips/:filterTrips",
            path + "/bundleId/:bundleId",
            path + "/id/:id/filterTrips/:filterTrips",
            path + "/id/:id"
        ]);
    }, []),
    propsFromMatch: (match, profile) => {
        const id = match?.params.bundleId ?? match?.params.id;
        const filter = Util.deserialize(match !== null && match.params.filterTrips && !Util.isEmpty(match.params.filterTrips) ?
            JSON.parse(match.params.filterTrips) :
            {
                pageSize: profile.pageSizeByView.get(bundleViewId) || 10,
                page: 1,
                range: moment.range(DateTimeUtil.getNowDate().subtract(2, 'months'), DateTimeUtil.getNowDate()),
                bundleId: id,
                type: TransType.BOOKING
            }, Filter);
        return { id: id, filter: filter }
    },
    propsToPath: (props: { id?: string, filter?: Filter }) => "/id/" + props.id +
        (props.filter ? "/filterTrips/" + JSON.stringify(Util.serialize(props.filter)) : ""),
    navLabel: ({ viewProps }) => {
        return <BundleViewNavWithData id={viewProps.id!} />
    },
    render: ({ viewProps, navHistory, waitFor, profile, onProfileChange, selectedClientID, clients }) => {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.read)) {
            return null;
        }
        const isAllAppsView = adminProfile.isSuperApp && !selectedClientID;
        const onEdit = () => navHistory.push(BUNDLE_EDIT_VIEW, { id: viewProps.id! });
        const onCopy = () => navHistory.push(BUNDLE_COPY_VIEW, { id: viewProps.id! });
        const onRemove = () =>
            UIUtil.confirmMsg({
                title: 'Confirm to delete',
                onConfirm: () =>
                    waitFor(BundlesData.instance.delete(viewProps.id!)
                        .then(() => {
                            BundlesData.instance.invalidateBundlesCaches();
                            navHistory.pop();
                        }))
            });
        const bundleId = viewProps.id;
        const filter = viewProps.filter!;
        const onSelect = (transaction: Transaction) => {
            const view = transViewFromType(transaction.type);
            view && navHistory.pushS("/tripId/" + transaction.id);
        };
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(bundleViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(BUNDLE_DETAIL_VIEW, Util.iAssign(viewProps, { filter: update }))
        };
        const bookingsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(true);
        };
        // Reset page number after a reload.
        if (filter.page > 1 && TransactionsData.instance.isEmpty()) {
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const showFilters: FiltersVisibility = {
            status: true,
            providers: true,
            groupBy: true,
            dateRange: true,
            paymentStatus: true,
            priceChange: true
        }
        const renderTrans = () =>
            <BookingsViewWithData
                filter={filter!}
                showFilters={showFilters}
                onSelect={onSelect}
                onFilterChange={onFilterChange}
                asView={false}
                showEmployee={true}
                onRefreshClick={onRefresh}
                onExportData={onBookingsExport}
                ref={bookingsViewRef}
                parentViewId={"bundle"}
            />;
        return (
            <BundleViewWithData
                id={bundleId}
                onEdit={onEdit}
                onCopy={onCopy}
                onRemove={onRemove}
                renderTrans={renderTrans}
                selectedClientID={selectedClientID}
                clients={clients}
                // Avoid re-construction of EditSponsorView after save clicked, causing title and image preview to be lost 
                // (notice WithAsyncData updates on any url update due to strong props compare).
                undefineOnUpdate={false}
            />
        )
    },
    searchProps: ({ viewProps, navHistory }) => {
        const filter = viewProps.filter!;
        return {
            fields: [
                { field: SearchField.USER_ID, predictor: identityPredictor },
            ],
            value: filter.search,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace(BUNDLE_DETAIL_VIEW, { id: viewProps.id, filter: filter })
            }
        }
    }
};