import messages_en from "./i18n_en-US.json";
// import messages_en from "./i18n_en-US_bundle.json";

export type I18nKeys = keyof typeof messages_en;
export type TKI18nMessages = Record<I18nKeys, string>;

export type TranslationFunction = (
    /** The key of the phrase to translate. */
    phrase: I18nKeys,
    /** The options accepted by `polyglot.t`. */
    options?: any
) => string

// To facilitate access from static methods (helpers / utils).
export const i18n: { t: TranslationFunction, locale: string, distanceUnit: () => "metric" | "imperial" } = {
    t: (key, params) => messages_en[key],
    locale: "en-US",
    distanceUnit: () => i18n.locale === "en-US" ? "imperial" : "metric"
}