import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { providerProductJss } from "./ProviderProduct.jss";
import { IProviderPricingRule, IProviderProduct } from "../model/Provider";
import Color from "../util/Color";
import Table from "../view/Table";
import classNames from "classnames";
import { colorWithOpacity } from "tripkit-react";
import { FARE_PART_HIGHLIGHT_COLOR } from "./ProviderView";

export interface IProps extends WithClasses<IStyle> {
    product: IProviderProduct;
    pricingRules: IProviderPricingRule[];
    providerColor: Color;
    highlightPart?: IProviderPricingRule["fare"]["parts"][0];
    onHighlightPart?: (part: IProviderPricingRule["fare"]["parts"][0] | undefined) => void;
}

type IStyle = ReturnType<typeof providerProductJss>;

const allWeekdaysLong = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const allWeekdays = allWeekdaysLong.map(weekday => weekday.substring(0, 3));

const formatTime = minutes => {
    const date = new Date(minutes * 60 * 1000);
    const dateUtc = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return dateUtc.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
}

const formatMoney = amount => amount === 0 ? "Free" : "USD " + parseFloat(amount).toFixed(2);

const dayToString = day => allWeekdaysLong[allWeekdays.indexOf(day)];

const dayInRange = (day, rangeS) => {
    const [start, end] = rangeS.split("-").slice(0, 2);
    let days = allWeekdays.concat(allWeekdays);
    days = days.slice(days.indexOf(start));
    days = days.slice(0, days.indexOf(end) + 1);
    return days.includes(day);
}

const dayInWeekdays = (day, weekdaysS) => {
    const weekdays = allWeekdays.filter(day =>
        weekdaysS.split(",").some(dayOrRange => dayOrRange.includes("-") ? dayInRange(day, dayOrRange) : dayOrRange === day)
    );
    return weekdays.includes(day);
}

function getDayRules({ day, rules }) {
    const result = {
        workingHours: [],
        prebooking: {}
    } as any;
    rules.forEach(rule => {
        if (dayInWeekdays(day, rule.weekdays)) {
            result.workingHours.push(...(rule.workingHours ?? []));
            if (rule.prebooking) {
                result.prebooking = {
                    ...result.prebooking,
                    ...rule.prebooking
                };
            }
        }
    });
    if (Object.keys(result.prebooking).length === 0) {
        delete result.prebooking;
    }
    return result;
}

function getGlobalPrebooking({ rules }) {
    const daysRules = allWeekdays.map(day => getDayRules({ day, rules }))
        .filter(dayRules => dayRules.workingHours.length > 0 || dayRules.prebooking);  // Filter out "empty" days
    return daysRules.length > 0 && daysRules.every(dayRules => JSON.stringify(dayRules.prebooking) === JSON.stringify(daysRules[0].prebooking)) ?
        daysRules[0].prebooking : undefined;
}

const ProviderProduct: React.FunctionComponent<IProps> = (props: IProps) => {
    const { product, pricingRules, providerColor, highlightPart, onHighlightPart, classes } = props;
    const rules = product.bookingOption?.bookingRules ?? [];
    const globalPrebooking = getGlobalPrebooking({ rules });
    let preBookingInfoTable: React.ReactNode = null;
    if (globalPrebooking) {
        const { minHoursBefore, maxDaysBefore, minWeekdayBefore, minTimeDayBefore } = globalPrebooking;
        const conditions: string[] = [];
        // Max conditions                        
        if (maxDaysBefore) {
            conditions.push(`At most ${maxDaysBefore} days before`);
        }
        // Min conditions            
        if (minHoursBefore) {
            conditions.push(`At least ${minHoursBefore > 48 ? Math.floor(minHoursBefore / 24) + " days" : minHoursBefore + " hs"} in advance`);
        } else if (minWeekdayBefore) {
            let minConditionS = "Before " + minWeekdayBefore;
            if (minTimeDayBefore) {
                minConditionS += " " + minTimeDayBefore + "hs"
            }
            conditions.push(minConditionS);
        }
        preBookingInfoTable = globalPrebooking && (
            <Table
                tableId="provider-prebooking"
                items={conditions}
                contentSpec={[
                    {
                        id: "condition",
                        name: "Condition",
                        label: "Prebooking",
                        cellValue: condition => condition,
                        class: classes.header,
                        style: {
                            position: 'initial'
                        }
                    }
                ]}
                configurable={false}
                rowClass={rowClass => classNames(rowClass, classes.row)}
            />
        );
    }
    const workingHoursTable = (
        <Table
            tableId="provider-workingHours"
            items={allWeekdays}
            contentSpec={[
                {
                    id: "day",
                    name: "Day",
                    label: "Working Hours",
                    cellValue: (day) => dayToString(day),
                    width: "auto",
                    colSpan: 4,
                    cellStyle: { width: '90%' },
                    class: classes.header,
                    style: {
                        position: 'initial'
                    }
                },
                {
                    id: "workingHoursFrom",
                    name: "Working Hours",
                    label: null,
                    cellValue: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            dayRules.workingHours.push({ type: "ALLDAY" })
                        }
                        return dayRules.workingHours.map((hours, i) =>
                            hours.type === "ALLDAY" ?
                                <div className={classes.allDay} key={i}>
                                    All day
                                </div>
                                :
                                <div className={classes.from} key={i}>{formatTime(hours.type === "HOUR" ? hours.from * 60 : hours.from)}</div>,
                        )
                    },
                    style: { display: 'none' },
                    cellColSpan: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            return 3;
                        }
                        return undefined;
                    }
                },
                {
                    id: "workingHoursDash",
                    name: "Working Hours",
                    label: null,
                    cellValue: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            dayRules.workingHours.push({ type: "ALLDAY" })
                        }
                        return dayRules.workingHours.map((hours, i) =>
                            hours.type === "ALLDAY" ?
                                null
                                :
                                <div className={classes.dash} key={i}>-</div>
                        )
                    },
                    style: { display: 'none' },
                    cellStyle: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            return ({ display: 'none' });
                        }
                        return undefined;
                    }
                },
                {
                    id: "workingHoursTo",
                    name: "Working Hours",
                    label: null,
                    cellValue: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            dayRules.workingHours.push({ type: "ALLDAY" })
                        }
                        return dayRules.workingHours.map((hours, i) =>
                            hours.type === "ALLDAY" ?
                                null
                                :
                                <div className={classes.to} key={i}>{formatTime(hours.type === "HOUR" ? hours.to * 60 : hours.to)}</div>
                        )
                    },
                    style: { display: 'none' },
                    cellStyle: (day) => {
                        const dayRules = getDayRules({ day, rules });
                        if (dayRules.workingHours.length === 0 && dayRules.prebooking) {
                            return ({ display: 'none' });
                        }
                        return undefined;
                    }
                }
            ]}
            configurable={false}
            rowClass={rowClass => classNames(rowClass, classes.row)}
        />
    );
    const pricingRulesSorted = pricingRules.slice()
    // .sort((rule1, rule2) => rule2.fare.bookingFee - rule1.fare.bookingFee);
    const pricingRuleParts = pricingRulesSorted.reduce((parts, rule) => {
        rule.fare.parts.forEach(part => {
            parts.push({ rule, part });
        });
        return parts;
    }, [] as { rule: IProviderPricingRule, part: IProviderPricingRule["fare"]["parts"][0] }[]);
    function isFirstPart({ rule, part }) {
        return rule.fare.parts?.[0] === part;
    }
    const pricingRulesTable = (
        <Table
            tableId="provider-pricing"
            items={pricingRuleParts}
            contentSpec={[
                {
                    id: "name",
                    name: "Name",
                    label: "Pricing",
                    cellValue: ({ rule }) => {
                        return (
                            <div>
                                <div className={classes.pricingRuleName}>
                                    {rule.name}
                                </div>
                                <div className={classes.pricingRuleDescription}>
                                    {rule.description}
                                </div>
                            </div>
                        );
                    },
                    width: "auto",
                    cellStyle: ({ rule, part }) => ({ width: '45%', ...!isFirstPart({ rule, part }) ? { display: 'none' } : {} }),
                    colSpan: 3,
                    cellRowSpan: ({ rule }) => rule.fare.parts?.length,
                    class: classes.header,
                    style: {
                        position: 'initial'
                    }
                },
                {
                    id: "fare-names",
                    name: "Fare Names",
                    label: null,
                    style: { display: 'none' },
                    cellValue: ({ part }) => {
                        const partName = part.name &&
                            <div className={classes.fareName}>
                                {part.name}
                            </div>;
                        const partScales = part.scales && part.scales.length > 0 &&
                            <div className={classes.fareScales}>
                                {part.scales.map(scale => scale.from + "-" + scale.to + " " + scale.type.toLowerCase() + "s,")}
                            </div>;
                        const partUnit = part.units && part.unitType && "Per " + (part.units && part.units > 1 ? part.units + " " + part.unitType.toLowerCase() + "s" : part.unitType.toLowerCase());
                        return (
                            <>
                                {partName}
                                {(partScales || partUnit) &&
                                    <div className={classes.fareSacalesAndUnit}>
                                        {partScales}
                                        {partUnit}
                                    </div>}
                            </>
                        );
                    },
                    cellProps: ({ part }) => ({
                        onMouseOver: part.zoneIds && part.zoneIds.length > 0 ? () => onHighlightPart?.(part) : undefined,
                        onMouseOut: part.zoneIds && part.zoneIds.length > 0 ? () => onHighlightPart?.(undefined) : undefined
                    }),
                    cellStyle: ({ part }) => ({
                        width: '45%',
                        cursor: 'pointer',
                        ...part === highlightPart ? {
                            backgroundColor: colorWithOpacity(FARE_PART_HIGHLIGHT_COLOR, .1),
                            borderTop: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`,
                            borderLeft: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`,
                            borderBottom: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`
                        } : {
                            borderTop: `2px solid white`,
                            borderLeft: `2px solid white`,
                            borderBottom: `2px solid white`
                        }
                    })
                },
                {
                    id: "fare-amounts",
                    name: "Fare Amounts",
                    label: null,
                    style: { display: 'none' },
                    cellValue: ({ part }) =>
                        <div className={classes.fareAmount}>
                            {formatMoney(part.amount)}
                        </div>,
                    cellStyle: ({ part }) => ({
                        minWidth: '190px',
                        cursor: 'pointer',
                        ...part === highlightPart ? {
                            backgroundColor: colorWithOpacity(FARE_PART_HIGHLIGHT_COLOR, .1),
                            borderTop: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`,
                            borderRight: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`,
                            borderBottom: `2px solid ${FARE_PART_HIGHLIGHT_COLOR}`
                        } : {
                            borderTop: `2px solid white`,
                            borderRight: `2px solid white`,
                            borderBottom: `2px solid white`
                        }
                    }),
                    cellProps: ({ part }) => ({
                        onMouseOver: part.zoneIds && part.zoneIds.length > 0 ? () => onHighlightPart?.(part) : undefined,
                        onMouseOut: part.zoneIds && part.zoneIds.length > 0 ? () => onHighlightPart?.(undefined) : undefined
                    })
                }
            ]}
            configurable={false}
            tableClass={classes.pricingTable}
            rowClass={rowClass => classNames(rowClass, classes.row)}
        />
    );
    return (
        <div className={classes.main}>
            <div className={classes.title}>
                {product.title}
            </div>
            {preBookingInfoTable}
            {workingHoursTable}
            {pricingRulesTable}
        </div >
    )
}

export default withStyles(ProviderProduct, providerProductJss);