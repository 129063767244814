import React, { useMemo } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { providerViewJss } from "./ProviderView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import View from "../view/View";
import withAsycnData from "../data/WithAsyncData";
import Loading from "../view/Loading";
import Provider, { IProviderCoverageProperties, IProviderPricingRule, IProviderProduct } from "../model/Provider";
import ProvidersData, { useMobilityOptions } from "../data/ProvidersData";
import ProviderProduct from "./ProviderProduct";
import Color from "../util/Color";
import Util from "../util/Util";
import ProviderMap from "./ProviderMap";
import { Feature, MultiPolygon } from "tripkit-react/node_modules/@types/geojson";
import { adminProfile } from "../account/AdminProfile";
import ActionsMenuButton from "../nav/ActionsMenuButton";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import ViewTitleWithId from "../view/ViewTitleWithId";
import { PROVIDER_EDIT_VIEW } from "./EditProviderView";
import { black } from "../css/gen.jss";

export const PRODUCT_HIGHLIGHT_COLOR = "#FF9800";
export const FARE_PART_HIGHLIGHT_COLOR = "#800080";
export interface IProps extends WithClasses<IStyle> {
    provider?: Provider;
    refresh?: (undefineOnUpdate?: boolean) => Promise<void>
    onEdit?: () => void;
}

type IStyle = ReturnType<typeof providerViewJss>;

const ProviderView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { provider, onEdit, classes, appClasses } = props;
    const [selectedFeature, setSelectedFeature] = React.useState<Feature<MultiPolygon, IProviderCoverageProperties> | undefined>(undefined);
    const [selectedPart, setSelectedPart] = React.useState<IProviderPricingRule["fare"]["parts"][0] | undefined>(undefined);
    const allMobilityOptions = useMobilityOptions();
    const supportedMobilityOptions = provider?.mobilityOptions;
    const unsupportedMobilityOptions = useMemo(() => {
        if (!allMobilityOptions) {
            return undefined;
        }
        if (!provider?.mobilityOptions) {    // Supports all mobility options
            return [];
        }
        return allMobilityOptions.filter(mo => !provider.mobilityOptions!.find(mo2 => mo2.id === mo.id));
    }, [allMobilityOptions, provider]);

    if (!provider) {
        return <Loading />;
    }
    if (!provider.coverage.features) {
        return (
            <View title={provider.name}>
                <div className={appClasses.noResults}>
                    {"No data available"}
                </div>
            </View>
        );
    }
    const websiteS = provider.info.companyInfo?.website;
    let websiteURL: URL | undefined;
    try {
        websiteURL = (new URL(websiteS));
    } catch (e) {
        console.log(e);
    }
    const phone = provider.info.companyInfo?.phone;
    const address = provider.info.companyInfo?.address;
    const coverage = provider.coverage;
    const products = coverage.features
        .reduce((products, feature) => products.concat(feature.properties?.products ?? []), [] as IProviderProduct[]);
    const pricingRules = coverage.features
        .reduce((pricingRules, feature) => pricingRules.concat(feature.properties?.pricingRules ?? []), [] as IProviderPricingRule[]);
    const providerColor = Util.deserialize(provider.info.companyInfo.color ?? { red: 0, blue: 0, green: 0 }, Color);
    const productsUI = products.map((product, i) => {
        const planIds = product.planIds ?? [product.planId];
        const productPricingRules = pricingRules.filter(rule => planIds.includes(rule.planId));
        return (
            <div
                onMouseOver={() => setSelectedFeature(coverage.features.find(feature => feature.properties?.products?.includes(product)))}
                onMouseOut={() => setSelectedFeature(undefined)}
                key={i}
            >
                <ProviderProduct
                    product={product}
                    providerColor={providerColor}
                    pricingRules={productPricingRules}
                    highlightPart={selectedPart}
                    onHighlightPart={(part) => setSelectedPart(part)}
                    key={i}
                />
            </div>
        );
    });
    const actions = [
        ...Util.insertIf(!!onEdit && adminProfile.features.editProvider23380, {
            label: "Edit",
            renderIcon: ({ className }) => <IconEdit className={className} style={{ padding: '3px', boxSizing: 'border-box' }} />,
            onClick: onEdit!
        })
    ];
    const actionsMenuBtn = actions.length > 0 &&
        <div style={{ marginLeft: '50px' }}>
            <ActionsMenuButton
                actions={actions}
            />
        </div>;
    return (
        <div className={classes.main}>
            <div className={classes.leftContainer}>
                <View
                    title={
                        <ViewTitleWithId
                            title={
                                <div className={classes.title}>
                                    <div className={classes.providerColor} style={{ background: providerColor.toHex() }} />
                                    <div className={classes.providerName}>{provider.name}</div>
                                </div>}
                            more={actionsMenuBtn}
                        />
                    }
                    subtitle={provider.disabled ? <div className={classes.disabled}>Disabled</div> : undefined}
                    styles={(_theme, defaultStyles) => ({
                        main: {
                            ...defaultStyles.main,
                            height: '100%'
                        },
                        header: {
                            ...defaultStyles.header,
                            marginBottom: 0,
                        }
                    })}
                >
                    <div className={classes.detail}>
                        <div className={classes.fieldsGrid}>
                            <div className={classes.entry}>
                                <div className={classes.field}>Website</div>
                                <div className={classes.value}>
                                    {websiteURL ? <a href={websiteURL.toString()} className={classes.link}>{websiteURL.hostname}</a> : websiteS}
                                </div>
                            </div>
                            {phone &&
                                <div className={classes.entry}>
                                    <div className={classes.field}>Phone</div>
                                    <div className={classes.value}>
                                        <a href={`tel:${phone}`} className={classes.link}>{phone}</a>
                                    </div>
                                </div>}
                            {address &&
                                <div className={classes.entry}>
                                    <div className={classes.field}>Address</div>
                                    <div className={classes.value}>
                                        {address}
                                    </div>
                                </div>}
                            {adminProfile.features.providerMobilityOptions22827 &&
                                <div className={classes.entry} style={{ gridColumn: '1 / span 2' }}>
                                    <div className={classes.field}>Mobility Options</div>
                                    <div className={classes.value}>
                                        <div className={classes.supported}>
                                            {!supportedMobilityOptions ? "Any" :
                                                supportedMobilityOptions.length === 0 ? "None" :
                                                    supportedMobilityOptions.map((option, i) =>
                                                        <div key={i}>
                                                            <span style={{ color: i % 2 === 1 ? '#03645b' : '#795548' }}>
                                                                {option.name}
                                                            </span>
                                                            {i < supportedMobilityOptions.length ? <span style={{ marginRight: '1ch' }}>,</span> : null}
                                                        </div>)}
                                        </div>
                                        <div className={classes.unsupported}>
                                            {unsupportedMobilityOptions && unsupportedMobilityOptions.length > 0 &&
                                                <>
                                                    <div className={classes.unsupportedTitle}>Unsupported:</div>
                                                    {unsupportedMobilityOptions.map((option, i) =>
                                                        <div key={i}>
                                                            <span style={{ color: i % 2 === 1 ? '#212a33d9' : black(1) }}>
                                                                {option.name}
                                                            </span>
                                                            {i < unsupportedMobilityOptions.length - 1 ? <span style={{ marginRight: '1ch' }}>,</span> : null}
                                                        </div>)}
                                                </>}
                                        </div>
                                    </div>
                                </div>}
                            {adminProfile.features.providerMobilityOptions22827 &&
                                <div className={classes.entry} style={{ gridColumn: '1 / span 2' }}>
                                    <div className={classes.field}>Purposes</div>
                                    <div className={classes.value}>
                                        {!provider.purposes ? "Any" :
                                            provider.purposes.length === 0 ? "None" :
                                                provider.purposes!.map((option, i) =>
                                                    <span key={i}>
                                                        <span style={{ color: i % 2 === 1 ? '#03645b' : '#795548' }}>
                                                            {option.name}
                                                        </span>
                                                        {i < provider.purposes!.length - 1 ? <span style={{ marginRight: '1ch' }}>,</span> : null}
                                                    </span>)}
                                    </div>
                                </div>}
                        </div>
                        <div className={classes.entry}>
                            <div className={classes.field}>Service Details</div>
                            {/* <div className={classes.value}>
                                        {address}
                                    </div> */}
                        </div>
                        <div className={classes.products}>
                            {productsUI}
                        </div>
                    </div>
                </View >
            </div>
            <div className={classes.mapContainer}>
                <ProviderMap provider={provider} selectedFeature={selectedFeature} selectedZoneIds={selectedPart?.zoneIds} />
            </div>
        </div>
    );
}

const ProviderViewStyled = withStyles(ProviderView, providerViewJss);

const ProviderViewWithData = withAsycnData(ProviderViewStyled,
    (query: ProviderViewProps) =>
        ProvidersData.instance.get(query.id)
            .then((provider: Provider) => ({ provider })) as Promise<{ provider?: Provider }>);

const ProviderViewNavWithData = withAsycnData(({ provider }) => <>{provider?.name}</>,
    (query: ProviderViewProps) =>
        ProvidersData.instance.get(query.id)
            .then((provider: Provider) => ({ provider })) as Promise<{ provider?: Provider }>);

export interface ProviderViewProps {
    id: string;
}

export const PROVIDER_VIEW: IViewRouteConfig<ProviderViewProps> =
{
    path: ["*/providerId/:id"],
    propsToPath: ({ id }) => "/providerId/" + id,
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: ({ viewProps }) => <ProviderViewNavWithData id={viewProps.id} />,
    render: ({ viewProps, navHistory }) => {
        return (
            <ProviderViewWithData
                id={viewProps.id}
                undefineOnUpdate={false}
                onEdit={() => navHistory.push(PROVIDER_EDIT_VIEW, { id: viewProps.id })}
            />
        );
    }
};