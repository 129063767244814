import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";

export const editStatusViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.spaceBetween,
        height: '100%'
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '40px'
    },
    form: {
        minHeight: '250px',
        paddingBottom: '30px'
    },
    statusValue: {
        whiteSpace: 'nowrap'
    },
    lock: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginRight: '-25px',
        ...genJss.noShrink,
        '& path': {
            fill: black(2)
        }
    }
});