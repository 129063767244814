import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { walletsViewJss } from "./WalletsView.jss";
import ViewRestyled from "../view/ViewRestyled";
import classNames from "classnames";
import { AppContext } from "../app/App";
import { Client } from "../model/Client";
import Filter from "../data/Filter";
import Transaction, { TransType } from "../model/Transaction";
import ExportButton, { ExportFormat } from "../export/ExportButton";
import ItemsResult from "../model/ItemsResult";
import Util from "../util/Util";
import Loading from "../view/Loading";
import TransactionsData from "../data/TransactionsData";
import genStyles from "../css/general.module.css";
import Paginate from "../paginate/Paginate";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable, from } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { adminProfile } from "../account/AdminProfile";
import PageSizeSelect from "../paginate/PageSizeSelect";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import DateRangePicker from "../time/DateRangePicker";
import { DefaultSelect } from "../rewards/StatusSelect";
import * as moment from 'moment-timezone';
import DateTimeUtil from "../util/DateTimeUtil";
import MoneyTransactionsTable from "./MoneyTransactionsTable";
import { black, genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { EXPORT_TRANSACTIONS_TOOLTIP } from "./BookingsView";
import MemoScroll, { MemoScrollRef } from "../uiutil/MemoScroll";


const selectOptionJss = (theme: Theme) => ({
    option: (props) => ({
        ...genJss.flex,
        ...genJss.grow,
        padding: '8px',
        ...genJss.borderRadius(6),
        whiteSpace: 'nowrap',
        '&$selected': {
            backgroundColor: '#ebebeb'
        },
        '&$focused': {
            backgroundColor: black(4)
        },
        '& path, polygon': {
            fill: 'gray'
        }
    }),
    selected: {},
    focused: {},
    optionRow: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.spaceBetween
    },
    walletName: {
        ...theme.textWeightBold,
        maxWidth: '160px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    range: {
        ...theme.textSizeCaption,
        ...genJss.flex,
        ...genJss.alignCenter,
        marginLeft: '20px',
        // minWidth: '153px',
        '& div:first-child, div:last-child': {
            ...genJss.grow,
            minWidth: '72px',
            // textAlign: 'center'
        },
        '& div:nth-child(2)': {
            width: '20px',
            textAlign: 'center'
        }
    }
});

export const WalletSelectOption = withStyles(props => {
    const { isFocused, isSelected, classes, data, innerProps } = props as any;
    const wallet: Transaction = data.value;
    const startDate = wallet.wallet?.appliedTimestamp && DateTimeUtil.isoToMomentTimezone(wallet.wallet.appliedTimestamp, wallet.timezone)
        .format("M/DD/YYYY");
    const expirationDate = wallet.wallet?.expirationTimestamp && DateTimeUtil.isoToMomentTimezone(wallet.wallet.expirationTimestamp, wallet.timezone);
    const endDate = expirationDate && expirationDate.valueOf() <= DateTimeUtil.getNow().valueOf() ? expirationDate.format("M/DD/YYYY") : "Now";
    return (
        <div className={classNames(classes.option,
            isSelected && classes.selected, !isSelected && isFocused && classes.focused)} {...innerProps}>
            <div className={classes.optionRow}>
                <div className={classes.walletName}>
                    {wallet.wallet?.name}
                </div>
                <div className={classes.range}>
                    <div>{startDate}</div><div>-</div><div>{endDate}</div>
                </div>
            </div>
        </div>
    );
}, selectOptionJss);
export interface WalletsViewProps {
    data?: ItemsResult<Transaction>;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onSelect?: (value: Transaction) => void;
    onExportData?: (filter: Filter, format: ExportFormat) => Promise<void>;
    onRefreshClick?: () => void;
    selectedClientID?: string;
    clients?: Client[];
    showFilters?: boolean;
    showDateRangePicker?: boolean;
    asView?: boolean;
}

export interface IProps extends WalletsViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof walletsViewJss>;

const WalletsView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const memoScrollRef = useRef<MemoScrollRef>(null);

    const { data, appClasses, classes, filter, showFilters = true, showDateRangePicker = true, onSelect, onFilterChange, asView = true, onRefreshClick } = props;
    const { profile: adminProfile, clients } = useContext(AppContext);
    const [walletPeriods, setWalletPeriods] = useState<Transaction[] | undefined>();

    const walletPeriodOptions = useMemo(() => walletPeriods
        ?.map(walletPeriod => ({
            value: walletPeriod,
            label: walletPeriod.wallet?.name + " - " +
                DateTimeUtil.isoToMomentTimezone(walletPeriod.wallet?.appliedTimestamp!, walletPeriod.timezone).format("M/DD/YYYY") + " - " +
                DateTimeUtil.isoToMomentTimezone(walletPeriod.wallet?.expirationTimestamp!, walletPeriod.timezone).format("M/DD/YYYY")
        })), [walletPeriods]);

    useEffect(() => {
        TransactionsData.instance.getBookings({
            type: TransType.BALANCE,
            userId: filter.userId,
            sortAsc: false
        }).then((balances) => {
            setWalletPeriods(balances);
            if (balances.length > 0) {
                handleWalletSelected(balances[0]);
            }
        })
    }, []);

    const handleWalletSelected = (value: Transaction) => {
        props.onFilterChange?.(Util.iAssign(filter, {
            balanceID: value.id,
            page: 1,
            range: showDateRangePicker ? moment.range(DateTimeUtil.momentTZTime(value.timestamp * 1000), DateTimeUtil.momentTZTime(value.timestamp * 1000).add(30, 'days')) : undefined
        }))
    };

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            memoScrollRef.current?.reset();
            const filterUpdate = Util.iAssign(props.filter, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    function handleDateRangeChange(range: Range) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter,
                {
                    range: range,
                    page: 1
                }
            );
            props.onFilterChange(filterUpdate)
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }

    if (!walletPeriods) {
        return <Loading />;
    } else if (walletPeriods.length === 0) {
        return <div className={appClasses.noResults}>No wallets found</div>;
    }

    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    let transactions;
    if (!data) {
        transactions = <Loading />;
    } else if (data.count === 0) {
        transactions =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        transactions =
            <MoneyTransactionsTable
                tableId={adminProfile.app + "-" + walletsViewId}
                values={data.items}
                filter={filter}
                onFilterChange={onFilterChange}
                onSelect={onSelect}
            />
    }
    const selectedWalletPeriod = walletPeriods && filter.balanceID && walletPeriods?.find(option => option.id === filter.balanceID);
    const content =
        <MemoScroll id={"walletsList"} style={{ overflowY: 'auto', flexGrow: 1 }} ref={memoScrollRef}>
            <div className={classes.transHeader}>
                <div className={classes.filterContainer}>
                    {showFilters && walletPeriodOptions && filter.balanceID &&
                        <div className={classes.filters}>
                            <div className={classes.filterSelect}>
                                <DefaultSelect
                                    options={walletPeriodOptions}
                                    value={selectedWalletPeriod}
                                    onChange={handleWalletSelected}
                                    components={{
                                        Option: WalletSelectOption,
                                        SingleValue: WalletSelectOption
                                    }}
                                    styles={(_theme, defaultStyle) => ({
                                        menu: {
                                            ...defaultStyle.menu,
                                            padding: '4px 20px 4px 6px',
                                            '& *[class^="option-"], div[class*=" option-"]': {
                                                padding: '8px 20px 8px 14px',
                                            }
                                        }
                                    })}
                                // isSearchable
                                />
                            </div>
                        </div>}
                    {props.onExportData &&
                        <ExportButton filter={props.filter} onExportData={props.onExportData} helpTooltip={EXPORT_TRANSACTIONS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />}
                    {showDateRangePicker && selectedWalletPeriod &&
                        <div className={classes.dateRangePicker}>
                            <DateRangePicker
                                value={filter.range}
                                onChange={handleDateRangeChange}
                                min={DateTimeUtil.momentTZTime(selectedWalletPeriod.timestamp * 1000)}
                            />
                        </div>}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>Page size</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => props.onFilterChange &&
                                    props.onFilterChange(Util.iAssign(filter, { pageSize, page: 1 }))} />
                        </div>
                        {onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
            </div>
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.spaceBetween)}>
                <div className={classNames(classes.transContainer, !!pageCount.current && (pageCount.current > 1) && classes.minHeight)}>
                    {transactions}
                    {data && data.waitingMore &&
                        <div className={classes.waitingMore}><Loading /></div>}
                </div>
                <div className={classNames(appClasses.paginatePanel, classes.paginatePanel)}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page} />}
                </div>
            </div>
        </MemoScroll>;
    return (!asView ? content :
        <ViewRestyled
            title={"Wallets"}
            className={classes.view}
        >
            {content}
        </ViewRestyled >
    );
}

const WalletsViewStyled = withStyles(WalletsView, walletsViewJss);

export const WalletsViewWithData = withAsyncDataObs(WalletsViewStyled,
    (query: { filter: Filter }) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
                                * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
                                    * All this won't be necessary if orgs come with the admin profile at the login.
                                    */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>  // Check if we need organizations for tickets.
            TransactionsData.instance.watchQuery({ ...query.filter, type: TransType.MONEY })
                .pipe(map((result?: ItemsResult<Transaction>) => ({ data: result }))) as Observable<{ data?: ItemsResult<Transaction> }>))
            .pipe(concatAll() as any) as any
);

export const walletsViewId = "WALLET_VIEW";