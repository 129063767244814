import {Theme} from "../css/Theme";
import {black, genJss} from "../css/gen.jss";

export const editRedemptionViewJss = (theme: Theme) => ({
    main: {

    },
    imageLabel: {
        textAlign: 'left!important',
        marginBottom: '13px'
    },
    dnDImage: {
        ...genJss.flex,
        width: '100%',
        height: '400px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        '&>*:not(:last-child)': {
            marginRight: '20px'
        },
        marginTop: '20px'
    },
    updateMsgs: {
        borderTop: '1px solid ' + black(4),
        marginTop: '15px',
        paddingTop: '15px'
    },
    msgsHistory: {
        ...genJss.grow,
        ...genJss.flex,
        ...genJss.column
    },
    msg: {
        '&:not(:last-child)': {
            ...theme.divider,
            paddingBottom: '5px',
            marginBottom: '5px'
        }
    },
    msgTimestamp: {
        ...theme.textSizeCaption,
        ...theme.textColorGray
    }
});