import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const editUserViewJss = (theme: Theme) => ({
    narrowForm: {
        maxWidth: '600px'
    },

    narrow: {
        width: '250px'
    },

    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },

    another: {
        marginRight: '40px'
    },

    bundleTable: {
        width: 'initial!important',
        '& th:nth-child(1)': {
            width: '300px'
        },
        '& th:nth-child(2)': {
            width: '100px'
        }
    },

    changeBundleBtn: {
        marginLeft: '30px',
        padding: '15px 0!important'
    },

    removeBundleBtn: {
        marginLeft: '30px',
        color: 'red!important',
        padding: '15px 0!important'
    },

    changeBundleView: {
        marginTop: '30px'
    },

    active: {
        color: theme.colorPrimary + "!important"
    },

    selectValue: {
        ...genJss.grow,
        '& > div': {
            ...genJss.grow,
            width: 'initial'
        }
    }
});