import gql from "graphql-tag";

export const graphqlSchema = gql`

directive @auth(rules: [AuthRule!]!) on OBJECT

input AuthRule {
	roles: [Role]
	operations: [ModelOperation]
	# fields that can be targeted by the operation.
	fields: [String]
	# conditions on current object for the operation to be authorized.
	conditions: [FieldCondition]
}

enum Role {
  admin tsp_admin org_admin
}

enum ModelOperation {
	create update delete read
}

input FieldCondition {
	field: String
	values: [String]
	valueTokenField: String
}

type User
@auth(rules: [
{
	roles: [admin, tsp_admin]
},
{
	roles: [org_admin],
	operations: [read, create, delete]
},
{
	roles: [org_admin],
	operations: [update],
	fields: ["name"]
}
]) 
{
	Id: String!
	name: String
	email: String
	externalId: String
	cardNumber: String
	currentBundle: Bundle
	futureBundle: Bundle
	balance: Balance
	givenName:String
	surname:String
	appData:AppData
}

type Balance {
	rawBalance: Int
	rewardsBalance: Int
	userBalance: Int
	availableRewardsBalance: Int
	lifetimeRewardsBalance: Int
}

type UserConnection {
	items: [User]
	nextToken: String
}

type Bundle 
@auth(rules: [
	{
		roles: [admin]
	},
	{
		roles: [org_admin],
		operations: []
	}
])
{
	Id: String!
	name: String
	transportModes: [TMode]
	#status:  (active or inactive)
	status: String
	#(timestamp of application to a given user, only defined to each user instance of the bundle)
	appliedTimestamp: Int
	#(timestamp to be applied to a given user, only defined to each user instance of the bundle)
	toBeAppliedTimestamp: Int
	#(in cents, how much will be charged to the user every month)
	subscriptionFee : Int
	#(number of user having this bundle, only for generic bundles, not user applied ones)
#	in days, how long it will last, when present. No value means one month
	subscriptionDuration: Int
#	whether it will auto-renew at the end of the subscription or not
	subscriptionAutoRenew: Boolean
	userCount: Int
	currentUserCount: Int
	futureUserCount: Int
	note: String
#	When this bundle will expire (in millis). Only for bundle applied to user
	expirationTimestamp: Int
	restrictedOnlyTo: [String]
}


input CreateBundleInput {
	name: String!
	transportModes: [TModeInput]!
	status: String!
	subscriptionFee : Int!
	note: String
	restrictedOnlyTo: [String]
}

input UpdateBundleInput {
	Id: String!
	name: String
	transportModes: [TModeInput]
	status: String
	subscriptionFee : Int
	note: String
	restrictedOnlyTo: [String]
}

input DeleteBundleInput {
	Id: String!
}

type TMode {
	mode: String
	pointsPerCost: Float
	discountPoints: Int
	rewardPerCost: Float
	hideCost: Int
	symbolicRewardPerCost: Int
	notificationEmail: String
	offerDescription: String
}

input TModeInput {
	mode: String
	pointsPerCost: Float
	discountPoints: Int
	rewardPerCost: Float
	hideCost: Int
	symbolicRewardPerCost: Int
	notificationEmail: String
	offerDescription: String
}

type Transaction 
@auth(rules: [
{
	roles: [admin, org_admin],
	operations: [read],
	conditions: [
		{
			field: "type",
			values: ["BOOKING"]
		}
	]
},
{
	roles: [tsp_admin],
	operations: [read],
	conditions: [
		{
			field: "type",
			values: ["BOOKING"]
		},
		{
			field: "mode",
			valueTokenField: "tspMode"
		}
	]
},
{
	roles: [admin, org_admin],
	operations: [update],
	conditions: [
		{
			field: "type",
			values: ["BOOKING"]
		}
	],
	fields: [
		"status"
	]
},
{
	roles: [tsp_admin],
	operations: [update],
	conditions: [
		{
			field: "mode",
			valueTokenField: "tspMode"
		},
		{
			field: "type",
			values: ["BOOKING"]
		}
	],
	fields: [
		"status"
	]
}
])
{
	Id: String!
	timestamp: Int
	userID: String
	mode: String
	userName: String
	userExternalId: String
	from: Location
	to: Location
	depart: Int
	arrive: Int
	price: Int
	budgetPoints: Int
	note: String
#	new fields
	type: TransactionType
	amount : Int
	productName: String
	rewardPoints: Int
	phone:String
	transportID: String
	confirmationInput: ConfirmationInputType

#	for reward & redemption
	points: Int

#Booking and redemption
	status: StatusType

#	only Redemption
	rewardID: String
	title: String
	description: String
	updateMessages: [UpdateMessage]
	fixedStatus :Boolean

#	only reward
	moneyAmount:Int
}

type ConfirmationInputType {
	notes: String,
	mobility_options: [String]
	purpose: String
}

enum StatusType {
#	Redemption
	PENDING
	APPROVED
	REJECTED
	REVERTED

# 	Booking
	PROCESSING,
	PROVIDER_ACCEPTED,
	USER_ACCEPTED,
	ARRIVING,
	IN_PROGRESS,
	PROVIDER_CANCELED,
	USER_CANCELED,
	COMPLETED,
	ENABLED,
}

enum TransactionType {
	BOOKING
	MONEY
	BALANCE
	REWARD
	REDEMPTION
}

type TransactionConnection {
	items: [Transaction]
	nextToken: String
}


type Location {
	address: String
	lat: Float
	lng: Float
}

input UpdateRedemptionInput {
	Id: String!
	status: StatusType
	points:  Int
	note: String
	updateMessages: [UpdateMessageInput]
#	if present waits for balance update
	userID: String
}

input UpdateBookingInput{
	Id: String!
	status: StatusType
	price: Int
	depart: Int
	arrive: Int
	mode: String
	phone: String
	externalID: String
	transportID: String
}

enum messageWriter {
	CLIENT
	ADMIN
}

type UpdateMessage{
	timestamp: String
	user: messageWriter
	message: String
}

input UpdateMessageInput{
	timestamp: String
	user: messageWriter
	message: String
}

input LocationInput {
	address: String
	lat: Float
	lng: Float
}

input UpdateUserInput{
	Id:String!
	givenName:String
	surname:String
	appData: AppDataInput
}

input CreateUserInput{
	username: String!
	givenName:String!
	surname:String!
	password: String!
	appData: AppDataInput!
}

type AppData{
	externalID: String
	userType: String
	mobilityOptions: [MobilityOptions]
	enabledModes: [String]
}

input AppDataInput{
	externalID: String
	userType: String
	mobilityOptions: [MobilityOptions]
	enabledModes: [String]
}

type Mutation {
	# it creates a new bundle with the given values
	createBundle(input: CreateBundleInput!): Bundle
	# It updates a bundle, if bundle to be updated has been assigned to any user (as current or future)  will only update bundle note,
	# otherwise all values will be updated.
	updateBundle(input: UpdateBundleInput!): Bundle
	# It deletes the bundle with the given Id
	deleteBundle(input: DeleteBundleInput!): Bundle
	# this operation is just for  test purposes.
	# It will remove user with the given Id and all extra information related to that user in tables ExternalAuth, Email, UserTrips, UserTokens and UserTransactions
	# It returns user object filled with Id
	deleteUser(Id: String!): User
	updateUser(input: UpdateUserInput!):User
	# It updates the redemption with the given Id.
	updateRedemption(input: UpdateRedemptionInput!): Transaction
	updateBooking(input: UpdateBookingInput!): Transaction
	createUser(input: CreateUserInput!):User
}

type Query {
	# return the user data with the given Id
	getUser(Id: String!): User
	# return the user data with the given external id
	getUserByExternalId(externalId: String!): User
	# arguments:
		# name: name to search (filter as contains)
		# currentBundle: current bundle id
		# futureBundle: future bundle id
		# search: user external id.  (matches begin with)
	# return the list of users which matches with given arguments
	listUsers(name: String, currentBundleId:String, futureBundleId:String, search: String, sortAsc: Boolean, limit: Int, nextToken: String): UserConnection
	# return the bundle with the given id
	getBundle(Id: String!): Bundle
	# return the list of bundles
	listBundles(filter: TableBundleFilterInput, limit: Int, nextToken: String): BundleConnection
	# return the transaction with the given Id (it could be BOOKING, MONEY, BALANCE, REWARD or REDEMPTION)
	getBooking(Id: String!): Transaction
	# arguments:
		# dateInit: (required) start range date
		# dateEnd: (required) end range date
		# userId: user id
		# mode: transaction mode
		# type: transaction  type (BOOKING, MONEY, BALANCE, REWARD or REDEMPTION)
		# status:  transaction status (PENDING, APPROVED, REJECTED)
	# return list of transactions which matchs to the given arguments
	listTransactions(
		dateInit: Int!,
		dateEnd: Int!,
		userId: String,
		mode: String,
		bundleId: String,
		type: TransactionType,
		status: StatusType,
		limit: Int,
		nextToken: String
	): TransactionConnection
}

input TableBundleFilterInput {
	Id: TableStringFilterInput
}

type BundleConnection {
	items: [Bundle]
	nextToken: String
}



input TableStringFilterInput {
	ne: String
	eq: String
	le: String
	lt: String
	ge: String
	gt: String
	contains: String
	notContains: String
	between: [String]
	beginsWith: String
}

input TableBookingFilterInput {
	Id: TableStringFilterInput
	timestamp: TableIntFilterInput
	userID: TableStringFilterInput
}

input TableIntFilterInput {
	ne: Int
	eq: Int
	le: Int
	lt: Int
	ge: Int
	gt: Int
	contains: Int
	notContains: Int
	between: [Int]
}

enum MobilityOptions{
	guest
	braces
	canePhysicalAid
	caneVisualAid
	crutches
	doorToDoor
	hearingAid
	bracesLeg
	liftNeeded
	messageBoard
	oxygen
	personalCareAssistant
	prosthesis
	reasonableModifications
	scooter
	scooterLarge
	segway
	serviceAnimal
	stroller
	walker
	wheelchair
	wheelchairElectric
	wheelchairLarge
}

schema {
	query: Query
	mutation: Mutation
}

`;