import React, { useContext, useRef, useState } from "react";
import Initiative from "../model/Initiative";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { editInitiativeViewJss } from "./EditInitiativeView.jss";
import Util from "../util/Util";
import View from "../view/View";
import { i18n } from "../i18n/TKI18nConstants";
import classNames from "classnames";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import genStyles from "../css/general.module.css";
import withAsycnData from "../data/WithAsyncData";
import InitiativesData from "../data/InitiativesData";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import UIUtil from "../util/UIUtil";
import { AppContext } from "../app/App";

type IStyle = ReturnType<typeof editInitiativeViewJss>;

export interface IProps extends WithClasses<IStyle> {
    initiative?: Initiative;
    onRequestClose: (update?: Initiative) => void;
}

const EditInitiativeView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { initiative, onRequestClose, classes, appClasses } = props;
    const { selectedClientID } = useContext(AppContext);
    const isCreate = initiative === undefined;
    const [update, setUpdate] = useState<Initiative>(initiative ? Util.deepClone(initiative) : Util.iAssign(new Initiative(), { clientId: selectedClientID }));
    const formRef = useRef<any>(null);
    return (
        <View
            title={isCreate ? i18n.t("New.initiative") : i18n.t("Edit.initiative")}
        >
            <div className={classNames(appClasses.form, classes.narrowForm)}>
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.formGroup, classes.narrowFormGroup)}>
                        <label>Title</label>
                        <Validator
                            value={update?.title}
                            name="title"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <input
                                        // placeholder="e.g. John"
                                        className={genStyles.grow}
                                        value={update.title}
                                        onChange={e => setUpdate(Util.iAssign(update, { title: e.target.value }))}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label htmlFor="description">Description</label>
                        <textarea name="description"
                            value={update.description}
                            onChange={(e) => {
                                setUpdate(Util.iAssign(update, { description: e.target.value }));
                            }}
                            placeholder="Enter a description here..."
                        />
                    </div>
                    <div className={classNames(appClasses.formGroup, classes.narrowFormGroup)}>
                        <label>{i18n.t("Billing.code")}</label>
                        <div className={classNames(appClasses.value, genStyles.grow)}>
                            <input
                                className={genStyles.grow}
                                value={update.billingCode}
                                onChange={e => setUpdate(Util.iAssign(update, { billingCode: e.target.value }))}
                            />
                        </div>
                    </div>
                </ValidatorForm>
            </div>
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button
                    onClick={() => onRequestClose()}
                    className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <button
                    onClick={async () => {
                        const valid = await formRef.current.isFormValid(false)
                        if (valid) {
                            onRequestClose(update);
                        }
                    }}
                    className={appClasses.buttonAdd}
                >
                    Save
                </button>
            </div>
        </View>
    );
};

const EditInitiativeViewStyled = withStylesO(EditInitiativeView, editInitiativeViewJss);

export const INITIATIVE_NEW_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/newInitiative"],
    propsToPath: ({ }) => "/newInitiative",
    propsFromMatch: () => {
        return {};
    },
    navLabel: () => i18n.t("New.initiative"),
    render: ({ navHistory, waitFor, refreshOrgs }) => {
        return (
            <EditInitiativeViewStyled
                onRequestClose={async (update?: Initiative) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        await waitFor(InitiativesData.instance.create(update));
                        InitiativesData.instance.invalidateCache();
                        navHistory.pop();
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};
export interface InitiativeViewProps {
    id: string;
}

const EditInitiativeWithData = withAsycnData(EditInitiativeViewStyled,
    async (query: InitiativeViewProps) => {
        const initiative = await InitiativesData.instance.get(query.id);
        return ({ initiative }) as { initiative?: Initiative; };
    });


export const INITIATIVE_EDIT_VIEW: IViewRouteConfig<InitiativeViewProps> =
{
    path: ["*/initiativeId/:id/edit"],
    propsToPath: ({ id }) => `/initiativeId/${id}/edit`,
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: () => i18n.t("Edit.initiative"),
    render: ({ viewProps, navHistory, waitFor }) => {
        return (
            <EditInitiativeWithData
                id={viewProps.id}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (update?: Initiative) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        await waitFor(InitiativesData.instance.update(update));
                        InitiativesData.instance.invalidateCache();
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        navHistory.pop();
                    }
                }}
            />
        );
    },
    accessible: ({ profile }) => profile.remote.userData.adminManagement
};