import { genStyles } from "tripkit-react";
import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";

export const LEFT_BAR_WIDTH = 240;

export const appJss = (theme: Theme) => ({
    main: {
        width: '100%'
    },

    leftBar: {
        width: LEFT_BAR_WIDTH + 'px',
        '& nav': {
            ...genJss.flex,
            ...genJss.column,
            ...genJss.grow,
            marginTop: '110px'
        },
        backgroundColor: '#2029310a',
        position: 'relative',
        '& > *:first-child': {
            zIndex: 3
        },
        '& > nav > *:first-child': {
            zIndex: 2
        },
        '& > nav > *:nth-child(2)': {
            zIndex: 1
        }
    },

    leftBarHeader: {
        ...genJss.flex,
        ...genJss.alignCenter,
        padding: '30px'
    },

    rightPanel: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.scrollableY,
        ...genJss.relative,
        // #scrollx
        // needed for horizontal scroll of tables.
        width: 'calc(100% - ' + LEFT_BAR_WIDTH + 'px)'
    },

    appName: {
        fontSize: '20px'
    },

    logo: {
        height: '40px',
        marginRight: '10px'
    },

    navLink: {
        ...theme.textColorDefault,
        ...genJss.borderRadius(4),
        padding: '7px 10px',
        margin: '1px 15px',
        textDecoration: 'none',
        '&.active': {
            backgroundColor: black(4)
        }
    },

    navSummary: {
        ...theme.textColorDefault,
        ...genStyles.borderRadius(4),
        margin: '1px 0px',
        padding: '7px 13px',
        cursor: 'pointer'
    },

    navSub: {
        paddingLeft: '25px'
    },

    navLabelWithBadge: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& div': {
            margin: '-3px 0 0 10px'
        }
    },

    container: {
        boxShadow: '1px 1px 10px #e4e4e4',
        minHeight: '100%',
        // #scrollx
        width: '100%',
        '& > div': {
            width: '100%'
        }
    },

    waiting: {
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, .5)',
        zIndex: '1100'  // To be above everything, including modals.
    },

    iconLoading: {
        margin: '0 5px',
        width: '25px',
        height: '25px',
        ...genJss.animateSpin
    },

    accountPanel: {
        position: 'absolute',
        right: '0'
    },
    '@global': {    // To make react-confirm-alert alert be above everything, including modals.
        ".react-confirm-alert-overlay": {
            zIndex: '1100!important'
        }
    },
    clientSelector: {
        minHeight: '73px',
        // margin: '10px 35px 25px 32px'
    },
    links: {
        marginTop: 'auto'
    },
    termsLink: {
        color: theme.colorPrimary,
        cursor: 'pointer',
        ...genJss.flex,
        margin: '15px 15px',
        padding: '8px 0',
        textDecoration: 'underline',
        '& svg': {
            height: '18px',
            width: '18px',
            marginRight: '8px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    }

    // :global(.notification-container) {
    //     top: 140px!important;
    //     /*top: 180px!important;*/
    //     /*right: 40px!important;*/
    // }
    //
    // :global(.notification-info) {
    //     background-color: #592c82d1!important
    // }
    //
    // :global(.notification-error) {
    //     background-color: #000000d4!important;
    // }
});


