import Filter from "./Filter";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import Util from "../util/Util";
import { default as AppSync } from "./AppSync";
import ItemsResult from "../model/ItemsResult";
import ItemGroup from "../model/ItemGroup";

interface TokenBasedPage {
    itemCount: number;
    nextToken?: string;
    requestLimit: number;
}

/**
 * Convert the BehaviourSubject to a Subject so it doesn't emmit it's initial value.
 */
function subjectFromBehaviorSubject<T>(bsubject: BehaviorSubject<T>): Subject<T> {
    const subject = new Subject<T>();
    let firstValue = true;
    bsubject.subscribe({
        next(value: any) {
            if (firstValue) {
                firstValue = false;
            } else {
                subject.next(value);
            }
        }
    });
    return subject;
}

class ItemsData<T> {

    private tokenBasedPages: TokenBasedPage[] = [];
    private currentFilter = new Filter();
    private prefetch = true;   // Disable for now. Parameterize.

    gqlQuery: (filter: Filter, limit: number, nextToken?: string) => string;
    private processGqlResult: (data: any) => { items?: T[], nextToken?: string, error?: string };

    constructor(gqlQuery: (filter: Filter, limit: number, nextToken?: string) => string,
        processGqlResult: (data: any) => { items?: T[], nextToken?: string, error?: string }) {
        this.gqlQuery = gqlQuery;
        this.processGqlResult = processGqlResult;
    }

    private static compatibleFilters(filter1, filter2): boolean {
        // Page size changes don't turen filter incompatible, since the pagination mechanism reuses all (token-based)
        // pages already requested, independently of it's size (request limit).
        return Util.stringify(Util.iAssign(filter1, { page: -1, pageSize: 0, groupBy: undefined }))
            === Util.stringify(Util.iAssign(filter2, { page: -1, pageSize: 0, groupBy: undefined }))
    }

    public resetPagination() {
        this.tokenBasedPages = [];
        // Page is set to 1 externally (by App.tsx)
    }

    public watchQuery(filter: Filter): Observable<ItemsResult<T> | undefined> {
        // If filters differ in any attribute other than page or force refresh property.
        if (!ItemsData.compatibleFilters(filter, this.currentFilter)) {
            this.resetPagination();
        }
        this.currentFilter = filter;
        const queryBehaviorSubject = new BehaviorSubject<ItemsResult<T> | undefined>(undefined);
        this.requestPage(filter, queryBehaviorSubject, !filter.search ? (more: boolean) => {
            // Prefetch next page. // Don't prefetch if filter changed
            this.prefetch && more && ItemsData.compatibleFilters(filter, this.currentFilter) &&
                this.requestPage(Util.iAssign(filter, { page: filter.page + 1 }), new BehaviorSubject<ItemsResult<T> | undefined>(undefined));
        } : undefined);
        // Convert the BehaviourSubject to Subject so it doesn't emmit it's initial value, since this initial value is
        // undefined and it triggers an undesired refresh (and re-construction) of views using WithAsyncData.
        return subjectFromBehaviorSubject(queryBehaviorSubject);
    }

    private requestPage(filter: Filter,
        queryBehaviorSubject: BehaviorSubject<ItemsResult<T> | undefined>,
        onCompletedPage?: (more: boolean) => void) {
        // To avoid an exception on a reload with page > 1 in the url (Also see code in PARTICIPANTS_VIEW.render)
        if (filter.page > 1 && this.tokenBasedPages.length === 0) {
            return;
        }
        const page0 = filter.page - 1;  // switch to 0-based page index for cleaner calculations.
        const prevResult = queryBehaviorSubject.getValue();
        const pageItems = prevResult ? prevResult.items.length : 0;
        const nextItemIndex0 = page0 * filter.pageSize + pageItems;
        const tokenBasedPageForNextItemIndex = this.tokenBasedPageForItemIndex(nextItemIndex0);
        // Token corresponding to results that contain next item (possibly undefined for the first token-based page).
        const tokenNextItem = tokenBasedPageForNextItemIndex !== 0 ?
            this.tokenBasedPages[tokenBasedPageForNextItemIndex - 1].nextToken : undefined;
        // Index of the first item coming for the token (sum of counts of all previous token-based pages).
        const tokenFirstItemIndex = tokenNextItem === undefined ? 0 :
            this.itemCountSum(this.tokenBasedPages.slice(0, tokenBasedPageForNextItemIndex));
        // Add 1 to pageSize to decouple user page size (numeric page) of token-based page size to reduce the
        // possibility of a next button enabled that brings leads to an empty page.
        // TODO: possibly use a greater value for search queries.
        // If already requested a (token based) page with that item, then use that same limit, so graphql query
        // is exactly the same, and it hits local (apollo) cache. If not, use pageSize + 1.
        // This makes changes of page size efficient, avoiding requests of items that were already requested.
        // E.g. from pageSize = 50 to pageSize = 10 will reuse all requests of 50, and start doing requests of
        // size 10 when following items are required.
        const limit = tokenBasedPageForNextItemIndex < this.tokenBasedPages.length ?
            this.tokenBasedPages[tokenBasedPageForNextItemIndex].requestLimit :
            filter.pageSize + 1;

        const query = this.gqlQuery(filter, limit, tokenNextItem);
        if (!query) {
            return;
        }
        AppSync.query({
            query: query,
            fetchPolicy: "cache-first"
        }).then((data: any) => {
            // AppSync.watchQuery({
            //     query: this.gqlQuery(filter, limit, tokenNextItem),
            //     fetchPolicy: "cache-first"
            // }, (data: any) => {
            if (!data.data) {   // TODO: see what does this mean, and what to do in this case.
                return
            }

            if (!ItemsData.compatibleFilters(filter, this.currentFilter)) {
                // Don't process result if filter changed
                return;
            }

            const { items, nextToken, error } = this.processGqlResult(data);

            if (!items) {
                queryBehaviorSubject.next(Util.iAssign(new ItemsResult<T>(), { error }));
                return;
            }
            if (nextToken || items.length > 0) {
                this.tokenBasedPages[tokenBasedPageForNextItemIndex] = {
                    nextToken: nextToken,
                    itemCount: items.length,
                    requestLimit: limit
                };
            } else if (page0 > 0) { // Is the last page (and not the first) and is empty, so should't display it.
                // Shouldn't happen too much given limit = filter.pageSize + 1;
                // Undefining the nextToken of previous page doesn't work, since it's overriden again with the token
                // when request (go back to) the previous page.
                // this.tokenBasedPages[tokenBasedPageForNextItemIndex - 1].nextToken = undefined;
            }

            const prevResult = queryBehaviorSubject.getValue();
            const result = prevResult ? Util.clone(prevResult) : new ItemsResult<T>();
            const itemsToPush = items.slice(nextItemIndex0 - tokenFirstItemIndex);
            // Replace items at proper position (using splice) instead of simply pushing them to consider that
            // watchQuery can return multiple times due to cache update. However if the length of itemsToPush changed
            // then this probably won't work well. Maybe switch to the scheme of query.
            result.items.push(...itemsToPush);
            // result.users.splice(pageItems, itemsToPush.length, ...itemsToPush);
            result.items.splice(filter.pageSize);   // Remove elements exceeding page size.
            result.waitingMore = result.items.length < filter.pageSize && !!nextToken;
            result.more = !result.waitingMore && !!nextToken;
            result.pageCount = Math.ceil(this.itemCountSum(this.tokenBasedPages) / filter.pageSize);
            // This is a workaround since the last page can be empty, to avoid pageCount to be less than the current
            // page.
            result.pageCount = Math.max(result.pageCount, filter.page);
            result.count = this.itemCountSum(this.tokenBasedPages);
            queryBehaviorSubject.next(result);
            if (result.waitingMore
                && ItemsData.compatibleFilters(filter, this.currentFilter)) { // Don't request more if filter changed
                this.requestPage(filter, queryBehaviorSubject, onCompletedPage); // Recursive call.
            } else {
                onCompletedPage && onCompletedPage(result.more);
            }

        });
    }

    /**
     *
     * @param {number} itemIndex
     * @returns {number} the index of the token based page that contains that index. If itemIndex is not (yet) included
     * then it returns this.tokenBasedPages.length, which is the index of the next page to request, that will hopefully
     * contain the index. In particular, if this.tokenBasedPages.length === 0 (no pages yet) then 0 will be returned.
     * Notice we will need to use the previous page's nextToken to get the desired item, whereas if the returned
     * value is 0, such token doesn't exist (first request).
     */
    private tokenBasedPageForItemIndex(itemIndex: number): number {
        let itemCount = 0;
        for (let i = 0; i < this.tokenBasedPages.length; i++) {
            itemCount += this.tokenBasedPages[i].itemCount;
            if (itemIndex < itemCount) {
                return i;
            }
        }
        return this.tokenBasedPages.length;
    }

    private itemCountSum(tokenBasedPages: TokenBasedPage[]): number {
        return tokenBasedPages
            .reduce((itemCountSum: number, page: TokenBasedPage) => itemCountSum + page.itemCount, 0);
    }

    public isEmpty(): boolean {
        return this.tokenBasedPages.length === 0;
    }

    public getExportData(filter: Filter, options: { limit?: number } = {}): Promise<T[]> {
        const { limit = 50 } = options;
        return this.requestExportPage(filter, limit);
    }

    private requestExportPage(filter: Filter, limit: number, nextToken?: string): Promise<T[]> {
        return AppSync.query({
            query: this.gqlQuery(filter, limit, nextToken),
            fetchPolicy: "network-only"
        }).then(((data: any) => {
            const { items, nextToken } = this.processGqlResult(data);
            if (!items) {
                return [];
            }
            if (nextToken) {
                return this.requestExportPage(filter, limit, nextToken)
                    .then((nextUsers: T[]) => items.concat(nextUsers));
            } else {
                return items;
            }
        }
        ));
    }

    public getItemGroups<T>(items: T[], getGroupTitle: (item: T) => string): ItemGroup<T>[] {
        const groups: ItemGroup<T>[] = [];
        let currentGroupTitle: string | undefined = undefined;
        let currentGroupTrans;
        for (const trans of items) {
            const groupTitle = getGroupTitle(trans);
            if (groupTitle !== currentGroupTitle) {
                currentGroupTitle = groupTitle;
                currentGroupTrans = [];
                groups.push(new ItemGroup(currentGroupTrans, groupTitle));
            }
            currentGroupTrans.push(trans);
        }
        return groups;
    }

}

export default ItemsData