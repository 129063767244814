import { Theme } from "../css/Theme";
import { black, genJss, resetJss, white } from "../css/gen.jss";

export const scheduleBookingFormJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.column
    },
    body: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.spaceBetween,
        position: 'relative'
    },
    formContainer: {
        ...genJss.flex,
        ...genJss.column,
        width: '45%',
        '& form': {
            ...genJss.grow
        }
    },
    mapContainer: {
        width: '50%',
        // To avoid the map to grow with the form to the left.
        // TODO: maybe imitate the behaviour of booking detail view, where
        // the left panel scrolls content, while the map occupies 100% of height.
        height: 'calc(100vh - 254px)'
    },
    imageLabel: {
        textAlign: 'left!important',
        marginBottom: '13px'
    },
    dnDImage: {
        ...genJss.flex,
        width: '100%',
        height: '400px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        marginTop: '20px',
        '&>*:not(:last-child)': {
            marginRight: '20px'
        }
    },
    durationInput: {
        textAlign: 'right',
        '&::placeholder': {
            textAlign: 'left',
        }
    },
    lockPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.alignSelfStretch,
        margin: '22px 0'
    },
    lockLines: {
        ...genJss.alignSelfStretch,
        width: '20px',
        borderTop: '1px solid ' + black(2),
        borderRight: '1px solid ' + black(2),
        borderBottom: '1px solid ' + black(2),
    },
    lockButton: {
        ...resetJss.button,
        width: '20px',
        height: '20px',
        marginLeft: '7px',
        '& svg': {
            width: '100%',
            height: '100%',
            '& path': {
                fill: black(2)
            }
        }
    },
    schedulingModeWarn: {
        ...theme.textWeightSemibold,
        ...theme.textSizeBody,
        ...theme.divider,
        margin: '55px 10px 0 10px',
        paddingBottom: '15px'
    },
    lock: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginRight: '-25px',
        ...genJss.noShrink,
        '& path': {
            fill: black(2)
        }
    },
    fogShield: {
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100%',
        background: white(1),
        zIndex: 1
    },
    syncFromTo: {
        border: '1px solid #212a330a',
        background: '#212a330a',
        ...genJss.borderRadius(4),
        padding: '20px 10px 20px 0'
    },
    free: {
        background: 'none',
        border: '1px solid ' + black(5)
    },
    timezone: {
        color: black(1),
        marginLeft: '10px'
    }
});