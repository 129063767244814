import React, {useState, useEffect} from "react";
import Reward from "../model/Reward";
import RewardsAPI from "./RewardsAPI";

interface IRewardsMap {
    [key: string]: Reward;
}

interface IRewardsContext {
    rewards?: IRewardsMap;
    selected?: Reward;
    save: (reward: Reward) => Promise<void>;
    remove: (reward: Reward) => Promise<void>;
}

export const RewardsContext = React.createContext<IRewardsContext>({
    save: (reward: Reward) => Promise.resolve(),
    remove: (reward: Reward) => Promise.resolve()
});

interface IProps {
    id?: string
}

function saveReward(reward: Reward, rewards: IRewardsMap | undefined, setRewards: (rewards: IRewardsMap) => void): Promise<void> {
    return RewardsAPI.saveReward(reward).then((reward: Reward) => {
        const rewardsUpdate = {...rewards};
        rewardsUpdate[reward.id!] = reward;
        setRewards(rewardsUpdate);
    });
}

function removeReward(reward: Reward, rewards: IRewardsMap | undefined, setRewards: (rewards: IRewardsMap) => void): Promise<void> {
    RewardsAPI.deleteReward(reward.id!).then(() => {
        const rewardsUpdate = {...rewards};
        delete rewardsUpdate[reward.id!];
        setRewards(rewardsUpdate);
    });
    return Promise.resolve();
}

const RewardsProvider: React.SFC<IProps> = (props) => {
    const [rewards, setRewards] = useState<IRewardsMap | undefined>(undefined);
    const [selected, setSelected] = useState<Reward | undefined>(undefined);

    useEffect(() => {
        RewardsAPI.loadRewards().then((rewards: Reward[]) =>
            setRewards(rewards.reduce((rewardsMap: IRewardsMap, reward: Reward) => {
                rewardsMap[reward.id!] = reward;
                return rewardsMap;
            }, {})));
    }, []);

    useEffect(() => {
        if (props.id === undefined) {
            setSelected(undefined);
            return;
        }
        const reward = rewards && rewards[props.id];
        if (reward) {
            setSelected(reward);
        } else {
            RewardsAPI.getReward(props.id).then(setSelected)
        }
    }, [props.id]);

    const save = (reward: Reward) => saveReward(reward, rewards, setRewards);

    const remove = (reward: Reward) => removeReward(reward, rewards, setRewards);

    return (
        <RewardsContext.Provider value={{rewards, selected, save, remove}}>
            {props.children}
        </RewardsContext.Provider>
    );
};

export default RewardsProvider;