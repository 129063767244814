import React, { useContext, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { addOrgToAdminViewJss } from "./AddOrgToAdminView.jss";
import { AppContext } from "../app/App";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import AdminUser from "../model/AdminUser";
import { LinkAdminUserToOrgInput } from "../data/AdminUsersData";
import ModalView from "../view/ModalView";
import { DefaultSelect } from "../rewards/StatusSelect";
import { OrgSelectOption } from "./EditOrgView";
import { isRootOrg } from "../app/OrgSelectorHelpers";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import { CheckboxStyled } from "../booking/BookingsView";
import Organization from "../model/Organization";
import { i18n } from "../i18n/TKI18nConstants";

export interface IProps extends WithClasses<IStyle> {
    admin: AdminUser;
    org?: Organization; // If provided, this view allows to edit the relation.
    onRequestClose: (input?: LinkAdminUserToOrgInput) => void;
}

type IStyle = ReturnType<typeof addOrgToAdminViewJss>;

ValidatorForm.addValidationRule('cantAddAllWhenSpecific', ({ selectedOrg, adminOrgs }) => {
    if (isRootOrg(selectedOrg) && adminOrgs.length > 0) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('cantAddSpecificWhenAll', ({ selectedOrg, adminOrgs }) => {
    if (!isRootOrg(selectedOrg) && adminOrgs.some(o => isRootOrg(o))) {
        return false;
    }
    return true;
});

const AddOrgToAdminView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { admin, org, onRequestClose, classes, appClasses } = props;
    const isUpdate = !!org;
    const [organizationId, setOrganizationId] = useState<string | undefined>(org?.id);
    const [notify, setNotify] = useState<boolean>(org ? admin.orgRelations.find(or => or.orgId === org.id)?.notify ?? false : false);
    const { profile: adminProfile } = useContext(AppContext);
    const allOrgs = useMemo(() => {
        let allOrgs = adminProfile.orgs?.filter(o =>
            o.fullAccess
            && (isUpdate || !admin?.organizations.some(ao => ao.id === o.id))   // Just filter out already assigned organizations if it's a create.
        ) ?? [];
        if (admin.isAdmin) {
            allOrgs = allOrgs.filter(o => o.isClientApp || o.isRoot);
        } else {
            allOrgs = allOrgs.filter(o =>
                !o.isClientApp && !o.isRoot
            );
        }
        allOrgs = allOrgs.sort((o1, o2) => {
            return isRootOrg(o1) ? -1 : isRootOrg(o2) ? 1 :
                o1.isClientApp !== o2.isClientApp ?
                    (o1.isClientApp ? -1 : 1) :
                    o1.name.localeCompare(o2.name);
        });
        return allOrgs;
    }, [adminProfile.orgs, admin]);
    const orgOptions = useMemo(() => allOrgs?.map(org => ({ value: org, label: isRootOrg(org) ? "All" : org.name, organization: org })), [allOrgs]);
    const organization = allOrgs?.find(o => o.id === organizationId);
    const formRef = useRef<any>(null);
    return (
        <ModalView
            title={admin.isAdmin ? (isUpdate ? "Edit Client App Assignment" : "Add Client app") : isUpdate ? i18n.t("Edit.organization.assignment") : i18n.t("Add.organization")}
            subtitle={<div style={{ marginTop: '16px' }}>{`to ${admin.name || admin.email || "Admin"}`}</div>}
            onRequestClose={() => onRequestClose()}
        >
            <div className={classes.main}>
                <div className={classNames(appClasses.form, genStyles.grow)}>
                    <ValidatorForm
                        instantValidate={false}
                        ref={formRef}
                        onSubmit={() => { }} // To avoid warning that onSubmit is required.
                    >
                        <div className={appClasses.formGroup}>
                            <label>{admin.isAdmin ? "Client App" : i18n.t("Organization")}</label>
                            <div className={classNames(appClasses.value, classes.orgSelectValue)}>
                                <DefaultSelect
                                    options={orgOptions}
                                    value={organization}
                                    onChange={(value) => {
                                        setOrganizationId(value.id)
                                    }}
                                    placeholder={"Select"}
                                    allowNoValue={true}
                                    isSearchable
                                    components={{
                                        Option: OrgSelectOption
                                    }}
                                    isDisabled={isUpdate}
                                />
                                <Validator
                                    value={organizationId}
                                    name="newStatus"
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </Validator>
                                <Validator
                                    value={{ selectedOrg: organization, adminOrgs: admin.organizations }}
                                    name="newStatus"
                                    validators={isUpdate ? [] : ['cantAddAllWhenSpecific', 'cantAddSpecificWhenAll']}
                                    errorMessages={isUpdate ? [] : ["remove access to specific client apps to be able to give access to 'All'", "remove access to 'All' to be able to add access to specific client apps"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </Validator>
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label>Subscribe to notifications</label>
                            <CheckboxStyled
                                checked={notify}
                                onChange={e => {
                                    setNotify(e.target.checked)
                                }}
                            />
                        </div>
                    </ValidatorForm>
                </div>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button
                        onClick={() => onRequestClose()}
                        className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    <button
                        onClick={async () => {
                            const valid = await formRef.current.isFormValid(false)
                            if (valid) {
                                onRequestClose({ adminUserId: admin.id, orgId: organizationId!, notify, clientId: organization?.clientId! })
                            }
                        }}
                        className={appClasses.buttonAdd}
                    >
                        Save
                    </button>
                </div>
            </div>
        </ModalView >
    );
}

export default withStyles(AddOrgToAdminView, addOrgToAdminViewJss);