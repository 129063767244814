import React, { useContext, useEffect } from "react";
import User from "../model/User";
import genStyles from "../css/general.module.css";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import classNames from "classnames";
import Util from "../util/Util";
import DateTimeUtil from "../util/DateTimeUtil";
import 'react-tabs/style/react-tabs.css';
import Transaction, { TransType } from "../model/Transaction";
import BalanceTable from "./BalanceTable";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { BookingsViewWithData, FiltersVisibility, onBookingsExport, transViewFromType } from "../booking/BookingsView"
import Filter, { SortOrder } from "../data/Filter";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { userViewJss } from "./UserView.jss";
import Loading from "../view/Loading";
import TransactionsData from "../data/TransactionsData";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import FormatUtil from "../util/FormatUtil";
import { default as UsersData } from "../data/UsersData";
import * as moment from 'moment-timezone';
import withAsycnData from "../data/WithAsyncData";
import { TRANS_EDIT_VIEW } from "../booking/EditTransView";
import { PART_DETAIL_VIEW_MODAL_PATH_BUILDER, PART_DETAIL_VIEW_PATH } from "./UserViewConstants";
import { REDEMPTION_EDIT_VIEW } from "../rewards/EditRedemptionView";
import UIUtil from "../util/UIUtil";
import { INTERNAL_RIDER_NOTES_LABEL, PART_EDIT_VIEW, PUBLIC_RIDER_NOTES_LABEL } from "./EditUserView";
import { BUNDLE_DURATION_TOOLTIP, durationStringFromDays } from "../bundle/EditBundleView";
import Tooltip from "../uiutil/Tooltip";
import AdminProfile, { adminProfile, ModelOperation, ModelType } from "../account/AdminProfile";
import View from "../view/View";
import { TRANSACTION_TYPE_FIELD } from "../data/TransactionsSchema";
import SideView from "../view/SideView";
import { NEW_BOOKING_CREATE_VIEW } from "../booking/NewCreateBookingView";
import { ASSIGN_BUNDLE_VIEW } from "./AssignBundleView";
import { Client } from "../model/Client";
import { ReactComponent as IconAddMoney } from '../images/ic-add-money.svg';
import { ReactComponent as IconRemoveMoney } from '../images/ic-remove-money.svg';
import { ADD_MONEY_VIEW, REMOVE_MONEY_VIEW } from "./AddMoneyView";
import { MONEY_TRANSACTIONS_VIEW, MoneyTransactionsViewWithData } from "../booking/MoneyTransactionsView";
import { AppContext, appPathUpdate } from "../app/App";
import { ReactComponent as ShowIcon } from "../images/ic-external-link.svg";
import { ReactComponent as IconStop } from "../images/ic-pause.svg";
import { ReactComponent as IconPlay } from "../images/ic-play.svg";
import { i18n } from "../i18n/TKI18nConstants";
import { EDIT_STATUS_VIEW } from "../booking/EditStatusViewHelpers";
import { TICKETS_VIEW_PATH, TicketsViewWithData } from "../ticket/TicketsView";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import TicketsData from "../data/TicketsData";
import { BOOKING_DETAIL_VIEW_PATH, TRANSACTIONS_VIEW_PATH } from "../booking/TransactionsViewConstants";
import ActionsMenuButton from "../nav/ActionsMenuButton";
import { FAVORITES_VIEW } from "./FavoritesView";
import ViewTitleWithId from "../view/ViewTitleWithId";
import { WalletsViewWithData } from "../booking/WalletsView";
import { orgFromId } from "../app/OrgSelectorHelpers";
import { ExportFormat } from "../export/ExportButton";
import { useProviders } from "../data/ProvidersData";

type IStyle = ReturnType<typeof userViewJss>;

export interface IProps extends WithClasses<IStyle> {
    tab?: UserTab;
    onTabChange?: (tab: UserTab) => void;
    user?: User;
    onEdit?: () => void;
    onDisableChange?: (user: User) => void;
    onRemove?: (user: User) => void;
    onCreateBooking?: () => void;
    onAssignBundle?: (future?: boolean) => void;
    onRemoveBundle?: (user: User, future?: boolean) => void;
    onAddMoney?: () => void;
    onRemoveMoney?: () => void;
    error?: Error;
    renderTrans?: (user: User) => React.ReactNode;
    renderTickets?: () => React.ReactNode;
    renderMoney?: () => React.ReactNode;
    renderWallets?: () => React.ReactNode;
    waitFor: (promise: Promise<any>) => Promise<any>;
    isModal?: boolean;
    isReadonly?: boolean;
    onRequestClose?: () => void;
    selectedClientID?: string;
    clients?: Client[];
}

export interface CanCreateBookingData {
    value: boolean;
    tooltip?: string;
    hide?: boolean;
}

export const canCreateBooking = (props: { user?: User, selectedClientID?: string, selectedOrgID?: string, adminProfile: AdminProfile }): CanCreateBookingData => {
    const { user, selectedClientID, selectedOrgID, adminProfile } = props;
    const clientId = user?.clientId ?? selectedClientID
    if (!adminProfile.features.createBooking) {
        return {
            value: false,
            hide: true
        };
    }
    if (adminProfile.isTSPUser) {
        return {
            value: false,
            hide: true
        };
    }
    if (adminProfile.isOrgUser) {
        if (!selectedOrgID) {
            return {
                value: false,
                tooltip: "Select an organization to be able to create a booking."
            };
        } else if (!adminProfile.orgs) {
            return {
                value: false,
                tooltip: "Disabled while getting organizations data..."
            };
        } else {
            const selectedOrg = adminProfile.orgs && orgFromId(selectedOrgID, adminProfile.orgs);
            if (!selectedOrg?.fullAccess) {     // No creation rights for the selected org
                return {
                    value: false,
                    tooltip: "No permissions to create a booking for the selected organization: " + selectedOrg?.name
                };
            }
        }
    }
    if (!clientId) {
        return {
            value: false,
            tooltip: "Select a client app on the left nav bar to be able to create a booking."
        }
    }
    if (user?.disabled) {
        return {
            value: false,
            tooltip: `Cannot create a booking for the user since he / she is ${i18n.t("suspended")}.`
        };
    }
    return {
        value: true
    };
}

const canAddBundle = (props: { user: User, future?: boolean }): { value: boolean, tooltip?: string, hide?: boolean } => {
    const { user, future } = props;
    if (!adminProfile.fieldAuth(ModelType.User, ModelOperation.update, future ? "futureBundle" : "currentBundle")) {
        return {
            value: false,
            hide: true
        };
    }
    if ((!future && user.currentBundle) || (future && (!user.currentBundle || user.futureBundle))) {
        return {
            value: false,
            hide: true
        };
    }
    if (user?.disabled) {
        return {
            value: false,
            tooltip: `Cannot assign a ${i18n.t("bundle")} to the user since he / she is ${i18n.t("suspended")}.`
        };
    }
    return {
        value: true
    };
}

const canAddMoney = (props: { user: User }): { value: boolean, tooltip?: string, hide?: boolean } => {
    const { user } = props;
    if (!adminProfile.features.addMoney || !adminProfile.isSuperAdmin || !user.currentBundle) {
        return {
            value: false,
            hide: true
        }
    }
    if (user?.disabled) {
        return {
            value: false,
            tooltip: `Cannot add money to the user's ${i18n.t("bundle")} since he / she is ${i18n.t("suspended")}.`
        };
    }
    return {
        value: true
    };
}

const userTabValues = ["GENERAL", "BOOKINGS", "USER_TICKETS", "MONEY", "WALLETS"] as const;
type UserTab = typeof userTabValues[number];

const UserView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { user, selectedClientID, clients, waitFor, tab: selectedTab = "GENERAL", onTabChange: setSelectedTab = () => { }, classes, appClasses, isModal, isReadonly, error, onAssignBundle, onRemoveBundle, onAddMoney, onRemoveMoney, onRequestClose } = props;
    const { navHistory, setWaiting, selectedOrgID, profile: adminProfile } = useContext(AppContext);
    const providers = useProviders();

    // To display global loading until user is loaded and so the model is opened.
    useEffect(() => {
        if (isModal) {
            setWaiting(!user)
        }
        return () => {
            isModal && !user && setWaiting(false);
        };
    }, [user?.id]);

    if (error) {
        return (
            <div className={appClasses.noResults}>
                {error.message}
            </div>
        );
    } else if (!user) {
        return isModal ? null : <Loading />;
    }
    const bundle = user.currentBundle;
    const balance = user.balance;
    const balanceTable = bundle && <BalanceTable bundle={bundle} balance={balance} />;

    const futureBundle = adminProfile.features.bundles ? user.futureBundle : undefined;
    const futureBundleStart = futureBundle && futureBundle.toBeAppliedMoment;
    const subscriptionDurationInDays = futureBundle?.bundleConfig?.[0]?.subscriptionDurationInDays;
    const futureBundleRender = futureBundle &&
        <Table className={classNames(appClasses.table, classes.bundleTable, genStyles.alignSelfStart)}>
            <TableHead>
                <TableRow>
                    <TableCell className={appClasses.field}>{i18n.t("Upcoming.Bundle")}</TableCell>
                    <TableCell className={appClasses.field}>
                        <Tooltip title={BUNDLE_DURATION_TOOLTIP}>
                            <div className={appClasses.cursorHelp}>
                                Subscription <br /> duration
                            </div>
                        </Tooltip>
                    </TableCell>
                    <TableCell className={appClasses.field}>Start</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell className={classNames(appClasses.cell, classes.bundleName)}>
                        <a
                            className={appClasses.detailLinkIconOnHover}
                            href={`#${appPathUpdate({ path: `/bundles/id/${futureBundle.id}` })}`}
                        >
                            {futureBundle.name}
                            <ShowIcon />
                        </a>
                    </TableCell>
                    <TableCell className={appClasses.cell}>
                        {subscriptionDurationInDays && durationStringFromDays(subscriptionDurationInDays)}
                    </TableCell>
                    <TableCell className={appClasses.cell}>
                        {futureBundleStart && futureBundleStart.format(DateTimeUtil.dayMonthFormat())}
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>;

    const tabs = !isModal &&
        <div className={classes.tabs}>
            <Tabs value={selectedTab} onChange={(_event, value) => setSelectedTab(value)} aria-label="text formatting">
                <Tab value={"GENERAL"} label={FormatUtil.toFirstUpperCase("GENERAL")} disableFocusRipple disableTouchRipple key={"GENERAL"} />
                <Tab value={"BOOKINGS"} label={FormatUtil.toFirstUpperCase("BOOKINGS")} disableFocusRipple disableTouchRipple key={"BOOKINGS"} />
                <Tab value={"USER_TICKETS"} label={FormatUtil.toFirstUpperCase("TICKETS")} disableFocusRipple disableTouchRipple key={"USER_TICKETS"} />
                {adminProfile.features.walletAuditing20675 &&
                    <Tab value={"MONEY"} label={FormatUtil.toFirstUpperCase("MONEY")} disableFocusRipple disableTouchRipple key={"Money"} />}
                {adminProfile.features.walletAuditing20675 &&
                    <Tab value={"WALLETS"} label={FormatUtil.toFirstUpperCase("WALLETS")} disableFocusRipple disableTouchRipple key={"WALLETS"} />}
            </Tabs>
        </div >;

    const subtitleTexts: React.ReactNode[] = [];
    if (user?.disabled) {
        subtitleTexts.push(<div className={classes.suspended}>{FormatUtil.toFirstUpperCase(i18n.t("suspended"))}</div>)
    }
    // if (user?.appData?.userType) {
    //     subtitleTexts.push(FormatUtil.toFirstUpperCase(user.appData.userType));
    // }
    // if (user?.cardNumber !== undefined) {
    //     subtitleTexts.push("Opal card: " + user.cardNumber);
    // }
    // if (user && user.appData && user.appData.oS) {
    //     subtitleTexts.push(user.appData.oS);
    // }
    const onResetPassword = () => {
        UIUtil.confirmMsg({
            message: 'Confirm to reset password',
            onConfirm: () => waitFor(UsersData.instance.resetPassword(user.email!, user.clientId))
                .then(({ result }) => {
                    UIUtil.infoMessage(result);
                })
                .catch(UIUtil.errorMessage)    // Catch error and show error message;
        });
    };
    const openInNewWindow = navHistory.viewAt(-1) === NEW_BOOKING_CREATE_VIEW;
    let title: React.ReactNode = user.name;
    if (isModal) {
        title =
            <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                {title}
                <a
                    className={classes.openFullBtn}
                    href={`#${appPathUpdate({ path: "/parts/id/" + user.id })}`}
                    {...openInNewWindow ? {
                        target: "_blank"
                    } : undefined}
                >
                    {/* TODO: maybe use other icon when opening in the same window */}
                    <ShowIcon />
                </a>
            </div>
    }
    const canCreateBookingInfo = canCreateBooking({ user, selectedClientID, selectedOrgID, adminProfile });
    const actionsMenuButton = !isModal &&
        <ActionsMenuButton
            actions={[
                ...Util.insertIf(!!(adminProfile.itemAuth(ModelType.User, ModelOperation.update) && props.onEdit),
                    {
                        label: "Edit user",
                        renderIcon: ({ className }) => <IconEdit className={className} style={{ boxSizing: 'border-box', padding: '3px' }} />,
                        onClick: props.onEdit!
                    }),
                ...Util.insertIf(adminProfile.features.suspendUser21819 && !!(adminProfile.itemAuth(ModelType.User, ModelOperation.update) && props.onDisableChange),
                    {
                        label: user.disabled ? "Enable user" : "Suspend user",
                        renderIcon: ({ className }) => {
                            const Icon = user.disabled ? IconPlay : IconStop;
                            return <Icon className={className} />;
                        },
                        onClick: () => { props.onDisableChange!(user); }
                    }),
                {
                    label: "Reset password",
                    onClick: onResetPassword
                },
                ...Util.insertIf(props.onCreateBooking && !canCreateBookingInfo.hide,
                    {
                        label: "Create Booking",
                        onClick: props.onCreateBooking!,
                        disabled: !canCreateBookingInfo.value,
                        tooltip: canCreateBookingInfo.tooltip
                    })
            ]} />;
    title = <ViewTitleWithId title={title} shortId={user.shortId} more={actionsMenuButton && <div style={{ marginLeft: '50px' }}>{actionsMenuButton}</div>} />;
    const subtitle =
        <div>
            <div style={{ marginBottom: '12px', marginTop: '-10px' }}>
                {subtitleTexts.reduce((elems: React.ReactElement[], text: React.ReactNode, i: number) => {
                    i > 0 && elems.push(<span className={classNames(genStyles.charSpace, genStyles.charSpaceLeft)} key={i + "a"}>⋅</span>);
                    elems.push(<span key={i + "b"}>{text}</span>);
                    return elems;
                }, [])}
            </div>
        </div>;
    const right =
        <div className={classes.rightPanel}>
            {user.pictureLarge && <img src={user.pictureLarge} className={classes.picture} />}
        </div>;
    const canAddCurrentBundleInfo = canAddBundle({ user });
    let addCurrentBundleBtn = onAssignBundle && !isReadonly && !canAddCurrentBundleInfo.hide &&
        <button
            className={classNames(appClasses.buttonAdd, genStyles.alignSelfStart)}
            onClick={() => onAssignBundle()}
            disabled={!canAddCurrentBundleInfo.value}
        >
            {i18n.t("Add.bundle")}
        </button>;
    if (canAddCurrentBundleInfo.tooltip) {
        addCurrentBundleBtn = addCurrentBundleBtn &&
            <Tooltip title={canAddCurrentBundleInfo.tooltip}>
                <div className={genStyles.alignSelfStart}>
                    {addCurrentBundleBtn}
                </div>
            </Tooltip>
    }
    const canAddFutureBundleInfo = canAddBundle({ user, future: true });
    let addFutureBundleBtn = onAssignBundle && !isReadonly && !canAddFutureBundleInfo.hide &&
        <button
            className={classNames(appClasses.buttonAdd, genStyles.alignSelfStart)}
            onClick={() => onAssignBundle(true)}
            disabled={!canAddFutureBundleInfo.value}
        >
            {i18n.t("Add.future.bundle")}
        </button>;
    if (canAddFutureBundleInfo.tooltip) {
        addFutureBundleBtn = addFutureBundleBtn &&
            <Tooltip title={canAddFutureBundleInfo.tooltip}>
                <div className={genStyles.alignSelfStart}>
                    {addFutureBundleBtn}
                </div>
            </Tooltip>;
    }
    const canAddMoneyInfo = canAddMoney({ user });
    let addMoneyButton = onAddMoney && !isReadonly && !canAddMoneyInfo.hide &&
        <button
            className={classNames(appClasses.buttonDelete, classes.buttonAddMoney)}
            onClick={() => onAddMoney()}
            disabled={!canAddMoneyInfo.value}
        >
            <IconAddMoney className={genStyles.svgPathFillCurrColor} style={{ marginRight: '5px' }} />
            {i18n.t("Add.money")}
        </button>;
    if (canAddMoneyInfo.tooltip) {
        addMoneyButton = addMoneyButton &&
            <Tooltip title={canAddMoneyInfo.tooltip}>
                <div>
                    {addMoneyButton}
                </div>
            </Tooltip>;
    }
    let removeFundsButton = onRemoveMoney && !isReadonly && !canAddMoneyInfo.hide &&
        <button
            className={classNames(appClasses.buttonDelete, classes.buttonAddMoney)}
            onClick={() => onRemoveMoney()}
            disabled={!canAddMoneyInfo.value}
            style={{ marginLeft: '16px' }}
        >
            <IconRemoveMoney className={genStyles.svgPathFillCurrColor} style={{ marginRight: '5px' }} />
            {i18n.t("Remove.money")}
        </button>;
    if (canAddMoneyInfo.tooltip) {
        removeFundsButton = removeFundsButton &&
            <Tooltip title={canAddMoneyInfo.tooltip}>
                <div>
                    {removeFundsButton}
                </div>
            </Tooltip>;
    }
    const general =
        <div style={{ overflowY: 'auto', flexGrow: 1 }}>
            <div className={classes.fieldsGrid}>
                <div className={classes.entry}>
                    <div className={classes.field}>ADA ID</div>
                    <div className={classes.value}>
                        {user.appData?.externalId || "-"}
                    </div>
                </div>
                <div className={classes.entry}>
                    <div className={classes.field}>Email</div>
                    <div className={classes.value}>
                        {user.email || "-"}
                    </div>
                </div>
                <div className={classes.entry}>
                    <div className={classes.field}>Phone</div>
                    <div className={classes.value}>
                        {user.phoneNumber || "-"}
                    </div>
                </div>
                {adminProfile.role === "admin" ?
                    <div className={classes.entry}>
                        <Tooltip title={"Exclusive modes available to this particular user in the apps. Non-exclusive modes are available to all users."} className={appClasses.cursorHelp}>
                            <div className={classes.field}>Exclusive modes enabled</div>
                        </Tooltip>
                        <div className={classes.value}>
                            {providers &&
                                (user.appData?.enabledModes?.map((mode: string) => providers?.find(p => p.id === mode)?.modeName || "unknown").join(", ") || 'None')}
                        </div>
                    </div> :
                    adminProfile.tspMode && providers?.find(p => p.id === adminProfile.tspMode)?.isExclusive &&
                    <div className={classes.entry}>
                        <div className={classes.field}>{providers.find(p => p.id === adminProfile.tspMode)?.name + " mode"}</div>
                        <div className={classes.value}>
                            {user.appData?.enabledModes?.includes(adminProfile.tspMode) ? "Enabled" : "Disabled"}
                        </div>
                    </div>}
                {adminProfile.isSuperApp && !selectedClientID &&
                    <div className={classes.entry}>
                        <div className={classes.field}>{i18n.t("Client.app")}</div>
                        <div className={classes.value}>
                            {user.clientIds.map(clientId => clients?.find(client => client.clientID === clientId)?.clientName ?? clientId).join(", ")}
                        </div>
                    </div>}
                {adminProfile.features.organizations &&
                    !(adminProfile.isOrgUser && adminProfile.orgs && adminProfile.orgs.filter(o => o.fullAccess).length === 1) &&    // Hide for org users that have (full) access to a single organization.
                    <div className={classes.entry}>
                        <div className={classes.field}>{i18n.t("Organizations")}</div>
                        <div className={classes.value}>
                            {user.appData?.organizations
                                ?.map(orgId => adminProfile.orgs?.find(o => o.id === orgId)?.name)
                                ?.filter(o => o !== undefined) // Filter orgs not accessible by the admin.
                                .join(", ")
                                || 'None'}
                        </div>
                    </div>}
                {adminProfile.features.favorites22590 &&
                    <div className={classes.entry}>
                        <div className={classes.field}>Favorite Addresses</div>
                        <div className={classes.value}>
                            <button className={appClasses.buttonOk} onClick={() => navHistory.push(FAVORITES_VIEW, { userId: user.id })}>{"Manage"}</button>
                        </div>
                    </div>}
                {adminProfile.features.trackTripInitiative22828 &&
                    <div className={classes.entry}>
                        <div className={classes.field}>{i18n.t("Initiatives")}</div>
                        <div className={classes.value}>
                            {user.initiatives
                                .map(initiative => initiative.title)
                                .join(", ")
                                || 'None'}
                        </div>
                    </div>}
                {isModal && user.pictureLarge && <img src={user.pictureLarge} className={classes.pictureInGrid} />}
                {(balanceTable && adminProfile.fieldAuth(ModelType.User, ModelOperation.read, "currentBundle") ||
                    futureBundleRender && adminProfile.fieldAuth(ModelType.User, ModelOperation.read, "futureBundle") ||
                    !isReadonly && (onAssignBundle || onRemoveBundle) && adminProfile.fieldAuth(ModelType.User, ModelOperation.update, "currentBundle")) &&
                    <div className={classes.entry} style={{ gridColumn: '1 / span 3' }}>
                        <div className={classes.field}>{i18n.t("Bundle")}</div>
                        <div className={classes.bundles}>
                            {addCurrentBundleBtn}
                            <div className={classNames(genStyles.flex)} style={{ marginLeft: '-16px', marginBottom: '30px' }}>
                                {balanceTable}
                                <div className={classNames(genStyles.flex, genStyles.column, genStyles.alignStart, genStyles.alignSelfCenter)} style={{ marginLeft: '40px' }}>
                                    <div style={{ display: 'flex' }}>
                                        {addMoneyButton}
                                        {removeFundsButton}
                                    </div>
                                    {onRemoveBundle && !isReadonly && user.currentBundle && adminProfile.fieldAuth(ModelType.User, ModelOperation.update, "currentBundle") &&
                                        <button
                                            className={classNames(appClasses.buttonDelete)}
                                            style={{ marginTop: '15px' }}
                                            onClick={() => onRemoveBundle(user)}
                                        >
                                            {i18n.t("Remove.bundle")}
                                        </button>}
                                </div>
                            </div>
                            {addFutureBundleBtn}
                            <div className={classNames(genStyles.flex)} style={{ marginLeft: '-16px' }}>
                                {futureBundleRender}
                                {onRemoveBundle && !isReadonly && user.futureBundle && adminProfile.fieldAuth(ModelType.User, ModelOperation.update, "futureBundle") &&
                                    <button
                                        className={classNames(appClasses.buttonDelete, genStyles.alignSelfCenter)}
                                        onClick={() => onRemoveBundle(user, true)}
                                    >
                                        {i18n.t("Remove.future.bundle")}
                                    </button>}
                            </div>
                        </div>
                    </div>}
                {adminProfile.features.internalTripAndRiderNotes22953 && user.publicNote &&
                    <div className={classes.entry} style={{ gridColumn: '1 / span 3' }}>
                        <div className={classes.field}>{PUBLIC_RIDER_NOTES_LABEL}</div>
                        <div className={classes.value}>
                            {user.publicNote}
                        </div>
                    </div>}
                {adminProfile.features.internalTripAndRiderNotes22953 && adminProfile.isSuperAdmin && user.internalNote &&
                    <div className={classes.entry} style={{ gridColumn: '1 / span 3' }}>
                        <div className={classes.field}>{INTERNAL_RIDER_NOTES_LABEL}</div>
                        <div className={classes.value}>
                            {user.internalNote}
                        </div>
                    </div>}
            </div>
        </div>
    const content =
        <>
            {tabs}
            <div style={{
                minHeight: '600px',
                paddingTop: '10px',
                display: 'flex'
            }}>
                {selectedTab === "GENERAL" && general}
                {!isModal && selectedTab === "BOOKINGS" && props.renderTrans?.(user)}
                {!isModal && selectedTab === "USER_TICKETS" && props.renderTickets?.()}
                {!isModal && selectedTab === "MONEY" && props.renderMoney?.()}
                {!isModal && selectedTab === "WALLETS" && props.renderWallets?.()}
            </div>
        </>
    return (
        isModal ?
            <SideView
                title={title}
                subtitle={subtitle}
                onRequestClose={onRequestClose}
            >
                {content}
            </SideView>
            :
            <View
                title={title}
                subtitle={subtitle}
                right={right}
                styles={(_theme, defaultStyle) => ({
                    header: {
                        ...defaultStyle.header,
                        borderBottom: 'none',
                        marginBottom: 0
                    },
                    subtitle: {
                        ...defaultStyle.subtitle,
                        marginBottom: 0
                    }
                })}
            >
                {content}
            </View>
    );
}

const UserViewStyled = withStyles(UserView, userViewJss);

const UserViewWithData = withAsycnData(UserViewStyled,
    (query: { id?: string }) => query.id ? UsersData.instance.get(query.id)
        .then((user: User | undefined) => ({ user }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ user?: User, error?: Error }> :
        Promise.resolve({ user: undefined, error: undefined }));

// noinspection JSUnusedLocalSymbols
const UserViewNavWithData = withAsycnData(((props: { user?: User, error?: Error }) =>
    <>{props.user && props.user.name ? props.user.name :
        props.error ? props.error.message : ""}</>),
    (query: { id?: string }) => query.id ? UsersData.instance.get(query.id)
        .then((user: User | undefined) => ({ user }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ user?: User, error?: Error }> :
        Promise.resolve({ user: undefined, error: undefined }));

export const userViewId = "USER_VIEW";
const userViewAndTabId = (tab: UserTab) => `${userViewId}-${tab}`;

export const PART_DETAIL_VIEW_BUILDER: (options?: { isModal?: boolean, isReadonly?: boolean }) => IViewRouteConfig<{ id?: string, tab?: UserTab, userFilters?: { [K in UserTab]?: Filter } }> = ({ isModal, isReadonly } = {}) =>
({
    // More specific paths need to come first so NavStack component gets proper links.
    path: isModal ?
        PART_DETAIL_VIEW_MODAL_PATH_BUILDER(NEW_BOOKING_CREATE_VIEW.path)
            .concat(PART_DETAIL_VIEW_MODAL_PATH_BUILDER(MONEY_TRANSACTIONS_VIEW.path)
                .concat(PART_DETAIL_VIEW_MODAL_PATH_BUILDER(TICKETS_VIEW_PATH)))
            .concat(PART_DETAIL_VIEW_MODAL_PATH_BUILDER(BOOKING_DETAIL_VIEW_PATH, true))
            .concat(PART_DETAIL_VIEW_MODAL_PATH_BUILDER(TRANSACTIONS_VIEW_PATH, true))
            .concat("*/userId/:userId") :
        PART_DETAIL_VIEW_PATH,
    propsFromMatch: (match, profile) => {
        const id = match?.params?.userId ?? match.params?.id;
        const transTypeValues = adminProfile.fieldCondValues(ModelType.Transaction, ModelOperation.read, TRANSACTION_TYPE_FIELD);
        const tab = match.params.tab ?? "GENERAL";
        const userFilters = userTabValues.reduce((acc, tab) => {
            if (match !== null && match.params.userFilters && JSON.parse(match.params.userFilters)[tab]) {
                acc[tab] = Util.deserialize(JSON.parse(match.params.userFilters)[tab], Filter);
            } else {
                const initFilter = tab === "BOOKINGS" ?
                    {
                        pageSize: profile.pageSizeByView.get(userViewAndTabId("BOOKINGS")) || 10,
                        page: 1,
                        range: moment.range(DateTimeUtil.getNowDate(), DateTimeUtil.getNowDate().add(15, 'days')),
                        userId: id,
                        // Set filter to booking transactions by default.
                        type: transTypeValues && transTypeValues.length === 1 ? transTypeValues[0] : TransType.BOOKING,
                        groupBy: "Day"
                    }
                    : tab === "USER_TICKETS" ?
                        {
                            pageSize: profile.pageSizeByView.get(userViewAndTabId("USER_TICKETS")) ?? 10,
                            page: 1,
                            userId: id,
                            range: moment.range(DateTimeUtil.getNowDate().add(-15, 'days'), DateTimeUtil.getNowDate()),
                            sortOrder: SortOrder.DESC
                        }
                        : tab === "MONEY" ?
                            {
                                pageSize: profile.pageSizeByView.get(userViewAndTabId("MONEY")) ?? 10,
                                page: 1,
                                userId: id,
                                range: moment.range(DateTimeUtil.getNowDate().add(-15, 'days'), DateTimeUtil.getNowDate()),
                                sortOrder: SortOrder.DESC
                            }
                            :
                            {
                                pageSize: profile.pageSizeByView.get(userViewAndTabId("WALLETS")) ?? 10,
                                page: 1,
                                userId: id, // Can alternatively pass as parameter to the view, since it makes no sense to list wallets for all users.                            
                                sortOrder: SortOrder.ASC
                            };
                acc[tab] = Util.deserialize(initFilter, Filter);
            }
            return acc;
        }, {} as { [K in UserTab]: Filter });
        return { id, tab, userFilters }
    },
    propsToPath: (props: { id?: string, tab?: UserTab, userFilters?: { [K in UserTab]?: Filter } }) => {
        const userFiltersStr = props.userFilters ?
            JSON.stringify(userTabValues.reduce((acc, tab) => {
                if (props.userFilters![tab]) {
                    acc[tab] = Util.serialize(props.userFilters![tab]);
                }
                return acc;
            }, {} as { [K in UserTab]?: string })) : undefined;
        return "/id/" + props.id +
            (props.tab ? "/tab/" + props.tab : "") +
            (userFiltersStr ? "/userFilters/" + userFiltersStr : "");
    },
    navLabel: ({ viewProps }) => {
        return <UserViewNavWithData id={viewProps.id!} />
    },
    render: ({ selectedClientID, selectedOrgID, clients, viewProps, navHistory, waitFor, profile, onProfileChange }) => {
        const onFilterChange = (tab: UserTab, update?: Filter) => {
            if (update && userFilters[tab]!.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(userViewAndTabId(tab), update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(PART_DETAIL_VIEW,
                Util.iAssign(viewProps, { userFilters: { ...userFilters, [tab]: update } }))
        };
        const onEdit = () => navHistory.push(PART_EDIT_VIEW, { id: viewProps.id! });
        const onRemove = (user: User) => {
            UIUtil.confirmMsg({
                title: 'Confirm to delete',
                message: 'All data related to the user will be permanently deleted, including any ' +
                    'transaction associated to her/him.',
                onConfirm: () => waitFor(UsersData.instance.delete(viewProps.id!, user.clientId)
                    .then(() => {
                        UsersData.instance.invalidateUserListCaches();
                        navHistory.pop();
                    }))
            });
        };
        const onDisableChange = (user: User) => {
            UIUtil.confirmMsg({
                message: `Confirm to ${user.disabled ? "enable" : "suspend"} the user?`,
                onConfirm: async () => {
                    const update = Util.iAssign(user, { disabled: !user.disabled });
                    try {
                        await waitFor(UsersData.instance.update(update))
                        UsersData.instance.invalidateUserCache(viewProps.id!);
                        UsersData.instance.invalidateUserListCaches();
                    } catch (error) {
                        UIUtil.errorMessage(error as Error);    // See if this is safe.
                    }
                }
            });
        }
        const onCreateBooking = () => {
            navHistory.push(NEW_BOOKING_CREATE_VIEW, { userId: viewProps.id! });
        };
        const onAssignBundle = (future?: boolean) => {
            navHistory.push(ASSIGN_BUNDLE_VIEW, { userId: viewProps.id!, ...{ future } });
        };
        const onAddMoney = () => {
            navHistory.push(ADD_MONEY_VIEW, { userId: viewProps.id! });
        };
        const onRemoveMoney = () => {
            navHistory.push(REMOVE_MONEY_VIEW, { userId: viewProps.id! });
        };
        const onRemoveBundle = (user: User, future?: boolean) => {
            UIUtil.confirmMsg({
                message: `Confirm to remove ${future ? "future" : ""} ${i18n.t("bundle")} from user?`,
                onConfirm: () => {
                    // Do this since if I call userViewRef.current inside the .then below it's null, and still don't know why. 
                    const refresh = userViewRef.current?.refresh;
                    waitFor(
                        UsersData.instance.removeBundle({ userID: viewProps.id!, clientID: user.clientId!, future: future })
                            .then(() => {
                                UsersData.instance.invalidateUserCache(viewProps.id!);
                                TransactionsData.instance.invalidateTransCache();
                                refresh?.(false);
                            })
                            .catch(UIUtil.errorMessage)
                    );
                }
            });
        };
        const userId = viewProps.id;
        const userFilters = viewProps.userFilters!;
        const onSelect = (transaction: Transaction) => {
            const view = transViewFromType(transaction.type);
            // TODO: un-hardcode, avoid need of pushS, maybe create USER_BOOKING_DETAIL_VIEW =
            // {...BOOKING_DETAIL_VIEW, propsToPath: (params: {id: string }) => "/tripId/" + params.id}
            // Search for uses of BOOKING_DETAIL_VIEW which may suggest required uses of USER_BOOKING_DETAIL_VIEW.
            view && navHistory.pushS("/tripId/" + transaction.id);
        };
        // this.navHistory.push(this.BOOKING_DETAIL_VIEW, {id: id});
        const onEditTrans = (trans: Transaction) =>
            navHistory.push(trans.type === TransType.REDEMPTION ? REDEMPTION_EDIT_VIEW as IViewRouteConfig<{ id: string }> :
                TRANS_EDIT_VIEW, { id: trans.id });
        const bookingsViewRef = React.createRef<any>();
        const ticketsViewRef = React.createRef<any>();
        const userViewRef = React.createRef<any>();
        const onRefreshBookings = () => {
            TransactionsData.instance.invalidateTransCache();
            viewProps.id && UsersData.instance.invalidateUserCache(viewProps.id);
            bookingsViewRef.current.refresh(true);
            userViewRef.current.refresh(false);
        };
        const onRefreshTickets = () => {
            TicketsData.instance.invalidateCache();
            viewProps.id && UsersData.instance.invalidateUserCache(viewProps.id);
            ticketsViewRef.current.refresh(true);
            userViewRef.current.refresh(false);
        };
        // Reset page number after a reload.        
        if (userFilters["BOOKINGS"]!.page > 1 && TransactionsData.instance.isEmpty()) { // TODO: consider TIKETS, maybe do for just for current tab?
            onFilterChange("BOOKINGS", Util.iAssign(userFilters["BOOKINGS"]!, { page: 1 }));
        }
        const transTypeValues = adminProfile.fieldCondValues(ModelType.Transaction, ModelOperation.read, TRANSACTION_TYPE_FIELD);
        const onBackgroundRefreshBookings = () => {
            if (userFilters["BOOKINGS"]!.page !== 1) {
                return;     // Avoid refresh when page !== 1, since "silent" refresh don't work in that case (trips are cleared and reloaded).
            }
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(false);
        };
        const onScheduleBooking = (booking: Transaction) =>
            navHistory.pushS("/bookingSchedule/" + booking.id);
        const onEditBookingStatus = (booking: Transaction) =>
            navHistory.push(EDIT_STATUS_VIEW, { bookingId: booking.id });
        const showFilters: FiltersVisibility = {
            type: !transTypeValues || transTypeValues.length > 1,
            status: true,
            providers: true,
            groupBy: true,
            dateRange: true,
            paymentStatus: true,
            priceChange: true
        }
        const renderTrans = (user: User) => (
            <BookingsViewWithData
                filter={userFilters["BOOKINGS"]!}
                showFilters={showFilters}
                onSelect={onSelect}
                onEditTrans={onEditTrans}
                onScheduleBooking={onScheduleBooking}
                onEditBookingStatus={onEditBookingStatus}
                onFilterChange={(update?: Filter) => onFilterChange("BOOKINGS", update)}
                onExportData={onBookingsExport}
                asView={false}
                showEmployee={false}
                onRefreshClick={onRefreshBookings}
                ref={bookingsViewRef}
                onBackgroundRefresh={profile.pollRefreshBookings ? onBackgroundRefreshBookings : undefined}
                parentViewId={userViewId}
                selectedClientID={user.clientId}
                onCreate={onCreateBooking}
                canCreateBookingData={canCreateBooking({ user: user, selectedClientID, selectedOrgID, adminProfile: profile })} />
        );
        const renderTickets = () =>
            <TicketsViewWithData
                filter={userFilters["USER_TICKETS"]!}
                // onSelect={onSelect}                                            
                onFilterChange={(update?: Filter) => onFilterChange("USER_TICKETS", update)}
                onExportData={onBookingsExport}
                asView={false}
                onRefreshClick={onRefreshTickets}
                ref={ticketsViewRef}
            />;
        const moneyViewRef = React.createRef<any>();
        const onRefreshMoney = () => {
            TransactionsData.instance.invalidateTransCache();
            moneyViewRef.current.refresh(true);
        };
        const onExportMoney: (filter: Filter, format: ExportFormat) => Promise<void> = (filter, format) => {
            const csvFormat = adminProfile.appMetadata?.export?.moneyTrans?.csvFormat;
            const csvFields = csvFormat?.map(field => {
                if (typeof field === 'object' && field.trans) {
                    return { label: field.label ?? field.value, value: FormatUtil.csvTransFunction(field.value, field.trans) }
                }
                return field;
            })
            return TransactionsData.instance.getExportData({ ...filter, type: TransType.MONEY }, { limit: 500 }).then((transactions: Transaction[]) => {
                const transactionsJSON = Util.serialize(transactions);
                switch (format) {
                    case "json": return FormatUtil.downloadObjectAsJson(transactionsJSON, "moneyTransactions");
                    case "csv": return FormatUtil.downloadObjectAsCSV(transactionsJSON,
                        {
                            exportName: "moneyTransactions",
                            fields: csvFields
                        });
                }

            });
        };
        const renderMoney = () =>
            <MoneyTransactionsViewWithData
                filter={userFilters["MONEY"]!}
                onFilterChange={(update?: Filter) => onFilterChange("MONEY", update)}
                ref={moneyViewRef}
                onRefreshClick={onRefreshMoney}
                asView={false}
                onExportData={onExportMoney}
            />;
        const walletsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TransactionsData.instance.invalidateTransCache();
            walletsViewRef.current.refresh(true);
        };
        const onExportWalletTransactions: (filter: Filter, format: ExportFormat) => Promise<void> = (filter, format) => {
            const csvFormat = adminProfile.appMetadata?.export?.moneyTrans?.csvFormat;
            const csvFields = csvFormat?.map(field => {
                if (typeof field === 'object' && field.trans) {
                    return { label: field.label ?? field.value, value: FormatUtil.csvTransFunction(field.value, field.trans) }
                }
                return field;
            })
            return TransactionsData.instance.getExportData({ ...filter, type: TransType.MONEY }, { limit: 500 }).then((transactions: Transaction[]) => {
                const transactionsJSON = Util.serialize(transactions);
                switch (format) {
                    case "json": return FormatUtil.downloadObjectAsJson(transactionsJSON, "moneyTransactions");
                    case "csv": return FormatUtil.downloadObjectAsCSV(transactionsJSON,
                        {
                            exportName: "moneyTransactions",
                            fields: csvFields
                        });
                }

            });
        };
        const renderWallets = () =>
            <WalletsViewWithData
                filter={userFilters["WALLETS"]!}
                asView={false}
                onFilterChange={(update?: Filter) => onFilterChange("WALLETS", update)}
                ref={walletsViewRef}
                onRefreshClick={onRefresh}
                showDateRangePicker={false}
                onExportData={onExportWalletTransactions}
            />;
        const tabChangeHandler = (tab: UserTab) => navHistory.replace(PART_DETAIL_VIEW, Util.iAssign(viewProps, { tab }));
        return (
            <UserViewWithData
                id={userId}
                tab={viewProps.tab}
                onTabChange={tabChangeHandler}
                onEdit={onEdit}
                onRemove={onRemove}
                onDisableChange={onDisableChange}
                onCreateBooking={onCreateBooking}
                onAssignBundle={onAssignBundle}
                onAddMoney={onAddMoney}
                onRemoveMoney={onRemoveMoney}
                onRemoveBundle={onRemoveBundle}
                renderTrans={renderTrans}
                renderTickets={renderTickets}
                renderMoney={renderMoney}
                renderWallets={renderWallets}
                ref={userViewRef}
                // To avoid turning view to loading after closing a transaction editing view that cleared the user
                // caché after saving change.
                undefineOnUpdate={false}
                waitFor={waitFor}
                isModal={isModal}
                isReadonly={isReadonly}
                onRequestClose={() => navHistory.pop()}
                selectedClientID={selectedClientID}
                clients={clients}
            />
        )
    },
    // searchProps: ({viewProps, navHistory}) => {
    //     const filter = viewProps.filter!;
    //     // Disable, mode filtering through select box for now.
    //     const fields = false && (!filter.type || filter.type === TransType.BOOKING) ? [
    //         { field: SearchField.MODE, predictor: modePredictor },
    //     ] : [];
    //     return fields.length > 0 ? {
    //         fields: fields,
    //         value: filter.search,
    //         onChange: (value?: Item) => {
    //             filter.search = value;
    //             filter.page = 1;
    //             navHistory.replace(PART_DETAIL_VIEW, { id: viewProps.id, filter: filter })
    //         }
    //     } : undefined;
    // },
    isModal: isModal,
    id: userViewId
});

export const PART_DETAIL_VIEW: IViewRouteConfig<{ id?: string, filter?: Filter }> =
    PART_DETAIL_VIEW_BUILDER();

export const PART_DETAIL_VIEW_MODAL: IViewRouteConfig<{ id?: string, filter?: Filter }> =
    PART_DETAIL_VIEW_BUILDER({ isModal: true, isReadonly: true });    