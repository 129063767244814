import { genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const favoritesViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        height: '100%'
    },
    addFavoriteBtn: {
        ...genJss.alignSelfStart,
        margin: '30px 0 0 45px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.center,
        marginTop: 'auto'
    }
})