import { DependencyList, useEffect, useState } from "react";

export const useBeforeInitialRender = (fn: () => void) => {
    useState(fn);
}

export function useAsyncValue<T>(asyncFc: () => Promise<T>, deps?: DependencyList): T | undefined {
    const [result, setResult] = useState<T | undefined>(undefined);
    useEffect(() => {
        setResult(undefined);
        asyncFc().then(setResult)
    }, deps);
    return result;
}