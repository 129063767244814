import React, {useContext, useState} from "react";
import {WithClasses, withStyles} from "../css/StyleHelper";
import ModalView from "../view/ModalView";
import {IViewRouteConfig} from "../nav/IViewRouteConfig";
import Util from "../util/Util";
import {genJss} from "../css/gen.jss";
import classNames from "classnames";
import {ValidatorForm} from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import genStyles from "../css/general.module.css";
import DateTimeUtil from "../util/DateTimeUtil";
import {editRedemptionViewJss} from "./EditRedemptionView.jss";
import Transaction, {TransStatus, UpdateMessage} from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import withAsycnData from "../data/WithAsyncData";
import {REDEMPTIONS_VIEW_PATH} from "./RedemptionsViewConstants";
import {TransStatusSelect} from "./StatusSelect";
import {PART_DETAIL_VIEW_PATH} from "../user/UserViewConstants";
import {TRANSACTIONS_VIEW_PATH} from "../booking/TransactionsViewConstants";
import UsersData from "../data/UsersData";

type IStyle = ReturnType<typeof editRedemptionViewJss>;

interface IProps extends WithClasses<IStyle> {
    redemption?: Transaction;
    onRequestClose?: (update?: Transaction) => void;
}

interface IState {
    update: Transaction;
    updateMessage?: string;
}

class EditRedemptionView extends React.Component<IProps, IState> {

    private formRef = React.createRef<any>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            update: props.redemption ? Util.deepClone(props.redemption) : new Transaction()
        };
    }

    private updateRewardState = (key, value) => {
        const updatedReward = Util.iAssign(this.state.update, { [key]: value });
        this.setState({
            update: updatedReward
        })
    };

    private handleChange = event => {
        this.updateRewardState(event.currentTarget.name, event.currentTarget.value);
    };

    private updateReward = () => {
        this.formRef.current.isFormValid(false)
            .then((valid: boolean) => {
                if (valid && this.props.onRequestClose) {
                    const update = Util.deepClone(this.state.update);
                    if (this.state.updateMessage) {
                        update.updateMessages =
                            [Util.iAssign(new UpdateMessage(), {
                                    message: this.state.updateMessage,
                                    timestamp: DateTimeUtil.getNow().format()
                            })].concat(update.updateMessages);
                    }
                    this.props.onRequestClose(update);
                }
            });
    };

    render(): React.ReactNode {
        const props = this.props;
        const classes = this.props.classes;
        const appClasses = this.props.appClasses;
        const isCreate = !props.redemption;
        const update = this.state.update;
        const requestTime = update.timestampMoment;
        return (
            <ModalView
                title={this.props.redemption ? "Edit event" : "Add event"}
                onRequestClose={() => props.onRequestClose && props.onRequestClose()}
            >
                <ValidatorForm
                    onSubmit={() => {}}
                    instantValidate={false}
                    ref={this.formRef}
                    className={genJss.grow}
                >
                    <div className={appClasses.form}>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="title">Request date</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                {requestTime.format(DateTimeUtil.dayMonthFormat() + ", YYYY") + " at " + requestTime.format(DateTimeUtil.TIME_FORMAT)}
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="title">Requester</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                {update.userName}
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="title">Description</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                {update.rewardTitle + ". " + update.description}
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="title">Reference #</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                {update.id}
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label>Points deducted</label>
                            <Validator
                                value={update.points}
                                name="points"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    return (
                                        <div
                                            className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.relative, genStyles.grow)}>
                                            <input
                                                type={"number"}
                                                step={"1"}
                                                min={"0"}
                                                ref={params.inputRefCallback}
                                                value={params.value}
                                                onChange={(event) => {
                                                    const update = Util.deepClone(this.state.update);
                                                    const parsed = parseFloat(event.target.value);
                                                    update.points = event.target.value === "" ? undefined :
                                                        isNaN(parsed) ? 0 : parsed;
                                                    this.setState({update: update});
                                                }}
                                                // placeholder={"0"}
                                                key="1"
                                            />
                                            {params.errorMessage &&
                                            <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                        </div>
                                    )
                                }}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="status">Status</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                <TransStatusSelect
                                    value={update.status}
                                    onChange={(status?: TransStatus) => this.updateRewardState("status", status)}
                                    isDisabled={update.fixedStatus}
                                />
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label htmlFor="note">Note</label>
                            <textarea name="note"
                                      onChange={this.handleChange}
                                      value={update.note} placeholder="Enter your note here..."/>
                        </div>
                        <div className={classes.updateMsgs}>
                            <div className={appClasses.formGroup}>
                                <label htmlFor="update message">Update message</label>
                                <textarea name="update message"
                                          onChange={(e) => this.setState({updateMessage: e.currentTarget.value || undefined})}
                                          value={this.state.updateMessage} placeholder="Enter a message associated to this update..."/>
                            </div>
                            {update.updateMessages.length > 0 &&
                            <div className={appClasses.formGroup}>
                                <label htmlFor="update message">Messages history</label>
                                <div className={classes.msgsHistory}>
                                    {update.updateMessages.map((updateMsg: UpdateMessage, i: number) =>
                                        <div className={classes.msg} key={i}>
                                            {updateMsg.message}
                                            <div className={classes.msgTimestamp}>
                                                {DateTimeUtil.momentDefaultTZ(updateMsg.timestamp).format(DateTimeUtil.dateFormat() +
                                                    " " + DateTimeUtil.timeFormat())}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                        </div>
                        <div className={classes.footer}>
                            <button className={appClasses.buttonCancel}
                                    onClick={() => this.props.onRequestClose && this.props.onRequestClose()}>Cancel
                            </button>
                            <button className={appClasses.buttonAdd}
                                    onClick={() => this.updateReward()}>{isCreate ? "Add" : "Save"}</button>
                        </div>
                    </div>
                </ValidatorForm>
            </ModalView>
        );
    }
}

const EditRedemptionViewStyled = withStyles(EditRedemptionView, editRedemptionViewJss);

// noinspection JSUnusedLocalSymbols
const EditRedemptionViewWithData = withAsycnData(EditRedemptionViewStyled,
    (query: {id?: string}) => (query.id ? TransactionsData.instance.get(query.id)
        .then((redemption: Transaction) => ({redemption})) : Promise.resolve({})) as Promise<{redemption?: Transaction}>);

export const REDEMPTION_EDIT_VIEW: IViewRouteConfig<{id?: string}> =
    {   // Just editing enabled for now, but left prepared for creation.
        path: REDEMPTIONS_VIEW_PATH.map((basePath: string) => basePath.concat("/redId/:redId/edit"))
            .concat(REDEMPTIONS_VIEW_PATH.map((basePath: string) => basePath.concat("/new")))
            .concat(TRANSACTIONS_VIEW_PATH.map((basePath: string) => basePath.concat("/redId/:redId/edit")))
            .concat(PART_DETAIL_VIEW_PATH.map((basePath: string) => basePath.concat("/redId/:redId/edit"))),
        propsFromMatch: (match: any) => ({id: match.params.redId}),
        propsToPath: (props: {id?: string}) => props.id ? "/redId/" + props.id + "/edit" : "/new",
        navLabel: (routeProps: {viewProps: {id?: string}}) => routeProps.viewProps.id ? "Edit event" : "New event",
        render: ({viewProps, navHistory, waitFor}) => {
            return <EditRedemptionViewWithData
                id={viewProps.id}
                onRequestClose={(update?: Transaction) => {
                    if (update) {
                        waitFor(viewProps.id ? TransactionsData.instance.update(update) :
                            TransactionsData.instance.create(update))
                            .then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                // To refresh user balance when we are on UserView.
                                // TODO: check if this change still triggers a re-render of UserView if
                                // WithAsyncData.componentDidUpdate does a proper props compare.
                                update.userId && UsersData.instance.invalidateUserCache(update.userId);
                                navHistory.pop();
                            });
                    } else {
                        navHistory.pop();
                    }
                }}
                // Since on constructor it determines if it's an editing or a creation based on if the user (data) is
                // present or not (undefined). Also avoids the need to display the editing view in a waiting state.
                renderWhenData={true}
                // Avoid re-construction of view after save clicked (notice WithAsyncData updates on any url update due
                // to strong props compare).
                undefineOnUpdate={false}
            />;
        },
        isModal: true
    };