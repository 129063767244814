import { Theme } from "../css/Theme";
import { appGlobalJss } from "../css/app.jss";
import { genJss } from "../css/gen.jss";

export const tableConfigJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignCenter,
        height: '100%'
    },
    columnSelector: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.alignStart,
        marginTop: '20px'
    },
    select: {
        width: '150px',
        minHeight: '200px',
        padding: '4px 0',
        '& option': {
            padding: '2px 6px'
        }
    },
    visibleSelector: {
        '&>*': {
            marginLeft: '15px'
        }
    },
    buttons: {
        '&>*:not(:first-child)': {
            marginTop: '5px'
        },
        '& button': {
            ...appGlobalJss(theme).buttonCancel,
            padding: '5px 15px'
        }
    },
    resetBtn: {
        marginTop: '10px',
        ...appGlobalJss(theme).buttonCancel,
        padding: '5px 15px',
        ...theme.textSizeCaption
    },
    footer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        ...genJss.alignSelfStretch,
        margin: 'auto 40px 20px',
        '&>*:not(:first-child)': {
            marginLeft: '15px'
        }
    }
});