import React, { useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { addMoneyViewJss } from "./AddMoneyView.jss";
import Loading from "../view/Loading";
import SideView from "../view/SideView";
import { PART_DETAIL_VIEW_PATH } from "./UserViewConstants";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import FormatUtil from "../util/FormatUtil";
import withAsycnData from "../data/WithAsyncData";
import UsersData from "../data/UsersData";
import User from "../model/User";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import UIUtil from "../util/UIUtil";
import { i18n } from "../i18n/TKI18nConstants";
import TransactionsData from "../data/TransactionsData";
import { black } from "../css/gen.jss";

type IStyle = ReturnType<typeof addMoneyViewJss>;

interface IProps extends WithClasses<IStyle> {
    user?: User;
    onRequestClose: (updateData?: { user: User, amount: number, note?: string }) => void;
    isRemoveFunds?: boolean;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

const AddMoneyView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { user, onRequestClose, isRemoveFunds, classes, appClasses } = props;
    const [waiting, setWaiting] = useState<boolean>(false);
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [note, setNote] = useState<string>("");
    const formRef = useRef<any>(null);
    const bundle = user?.currentBundle;
    return (
        <SideView
            title={isRemoveFunds ? i18n.t("Remove.money") : i18n.t("Add.money")}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        {bundle && user.balance?.rawBalance !== undefined &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Current Balance
                                </label>

                                <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                    {FormatUtil.toMoney(user.balance.rawBalance, { nInCents: true })}
                                </div>
                            </div>}
                        {bundle &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Amount
                                </label>
                                <Validator
                                    value={amount}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                            <div className={appClasses.currency}>$</div>
                                            <input
                                                type={"number"}
                                                step={"0.1"}
                                                value={amount === undefined ? "" : FormatUtil.truncateToDecimals(amount / 100, 2)}
                                                onChange={(event) => {
                                                    const parsed = parseFloat(event.target.value);
                                                    setAmount(isNaN(parsed) ? undefined : parsed * 100);
                                                }}
                                            />
                                            {errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                        </div>}
                                </Validator>
                            </div>}
                        {bundle &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Note
                                </label>
                                <div className={classNames(appClasses.value)} style={{ width: '100%' }}>
                                    <textarea
                                        className={genStyles.grow}
                                        style={{ alignSelf: 'stretch' }}
                                        value={note}
                                        onChange={event => {
                                            setNote(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>}
                        {bundle && user.balance?.rawBalance !== undefined &&
                            <div className={classes.reviewPanel} style={amount === undefined ? { color: black(1) } : {}}>
                                <div className={appClasses.formGroup}>
                                    <label>
                                        New Balance
                                    </label>

                                    <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                        {FormatUtil.toMoney(amount !== undefined ? user.balance.rawBalance + Math.floor(amount!) * (isRemoveFunds ? -1 : 1) : user.balance.rawBalance, { nInCents: true })}
                                    </div>
                                </div>
                            </div>}
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    <button onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                if (valid) {
                                    onRequestClose({ user: user!, amount: Math.floor(amount!) * (isRemoveFunds ? -1 : 1), note });
                                    setWaiting(true);
                                }
                            })
                    }} className={appClasses.buttonAdd} disabled={!bundle}>
                        {i18n.t("Confirm")}
                    </button>
                </div>
            </div>
        </SideView >
    );
};

const AddMoneyViewStyled = withStyles(AddMoneyView, addMoneyViewJss);

const AddMoneyViewData = withAsycnData(AddMoneyViewStyled,
    (query: { userId: string }) => UsersData.instance.get(query.userId)
        .then((user: User | undefined) => ({ user }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ user?: User, error?: Error }>);

// noinspection JSUnusedLocalSymbols
const UPDATE_MONEY_VIEW_BUILDER: (isRemove: boolean) => IViewRouteConfig<{ userId: string }> = (isRemove: boolean) =>
({
    path: PART_DETAIL_VIEW_PATH.reduce((acc: string[], path: string) => {
        return acc.concat([
            path + (isRemove ? "/removeMoney" : "/addMoney")
        ]);
    }, []),
    propsFromMatch: (match: any) => {
        return ({ userId: match.params.id });
    },
    propsToPath: _props => isRemove ? "/removeMoney" : "/addMoney",
    navLabel: () => isRemove ? i18n.t("Remove.money") : i18n.t("Add.money"),
    render: ({ viewProps, navHistory }) => {
        return (
            <AddMoneyViewData
                userId={viewProps.userId}
                onRequestClose={updateData => {
                    if (updateData) {
                        const { user, amount, note } = updateData;
                        UsersData.instance.addMoney({
                            userID: viewProps.userId,
                            clientID: user.clientId!,
                            amount,
                            note
                        })
                            .then(() => {
                                UsersData.instance.invalidateUserCache(viewProps.userId);
                                TransactionsData.instance.invalidateTransCache();
                            })
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            })
                    } else {
                        navHistory.pop();
                    }
                }}
                isRemoveFunds={isRemove}
                renderWhenData={true}
            />
        );
    },
    isModal: true
});

export const ADD_MONEY_VIEW = UPDATE_MONEY_VIEW_BUILDER(false);
export const REMOVE_MONEY_VIEW = UPDATE_MONEY_VIEW_BUILDER(true);