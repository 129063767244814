import NetworkUtil from "../util/NetworkUtil";
import Reward from "../model/Reward";
import Util from "../util/Util";

class RewardsAPI {

    public static loadRewards(): Promise<Reward[]> {
        return NetworkUtil.apiCallTs("data/reward/array", NetworkUtil.MethodType.GET, Reward);
    }

    public static getReward(id: string): Promise<Reward> {
        return NetworkUtil.apiCallT("data/reward/" + id, NetworkUtil.MethodType.GET, Reward);
    }

    public static saveReward(reward: Reward): Promise<Reward> {
        if (reward.image) {
            reward = Util.iAssign(reward, { imageUrl: undefined });
        }
        return (reward.id ?
            NetworkUtil.apiCallTOpts("data/reward/" + reward.id, Reward,
                {
                    method: NetworkUtil.MethodType.PUT,
                    body: JSON.stringify(Util.serialize(reward)),
                }) :
            NetworkUtil.apiCallTOpts("data/reward", Reward,
                {
                    method: NetworkUtil.MethodType.POST,
                    body: JSON.stringify(Util.serialize(reward)),
                }))
            .then((savedReward: Reward) => {
                if (reward.image) {
                    const formData = new FormData();
                    formData.append("image", reward.image);
                    const tgApiHeaders = NetworkUtil.getTGApiHeaders();
                    delete tgApiHeaders['Content-Type'];
                    return NetworkUtil
                        .apiCallTOpts(`data/reward/${savedReward.id}/image`, Reward,
                            {
                                method: NetworkUtil.MethodType.POST,
                                body: formData,
                                headers: tgApiHeaders
                            });
                } else {
                    return savedReward;
                }
            });
    }

    public static deleteReward(id: string): Promise<void> {
        return NetworkUtil.apiCall(`data/reward/${id}`, { method: NetworkUtil.MethodType.DELETE });
    }

}

export default RewardsAPI;

