import React from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { Theme } from "../css/Theme";
import { genJss, white } from "../css/gen.jss";
import { ReactComponent as IconSpin } from '../images/ic-refresh.svg';

const loadingViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.center
    },

    mainOverlay: {
        ...genJss.flex,
        ...genJss.center,
        ...genJss.alignCenter,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: '0',
        background: white(1),
        zIndex: 1
    },

    iconLoading: {
        ...genJss.alignSelfCenter,
        ...genJss.selfCenter,
        ...genJss.animateSpin,
        margin: '0 5px',
        width: '25px',
        height: '25px',

    }
});

type IStyle = ReturnType<typeof loadingViewJss>

interface IProps extends WithStyles<IStyle> {
    overlay?: boolean;
}

const Loading: React.FunctionComponent<IProps> = (props: IProps) => {
    const { overlay, classes } = useStyles(props, loadingViewJss);
    return (
        <div className={overlay ? classes.mainOverlay : classes.main}>
            <IconSpin className={classes.iconLoading} focusable="false" />
        </div>
    );
}

export default Loading;