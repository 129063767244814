import React, { useMemo, useRef, useState } from "react";
import { useStyles, withProps, WithStyles, withStyles } from "../css/StyleHelper";
import { editStatusViewJss } from "./EditStatusView.jss";
import Loading from "../view/Loading";
import SideView from "../view/SideView";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import FormatUtil from "../util/FormatUtil";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import Transaction, { BookingAction, NotificationType, TransactionNote, transStatusToDisplayString } from "../model/Transaction";
import { StatusSelect } from "../rewards/StatusSelect";
import { defaultOptionStyle, statusSelectJss } from "../rewards/StatusSelect.jss";
import Util from "../util/Util";
import DateTimeUtil from "../util/DateTimeUtil";
import { adminProfile } from "../account/AdminProfile";
import Tooltip from "../uiutil/Tooltip";
import { ReactComponent as IconLocked } from '../images/ic-locked.svg';
import { CheckboxStyled } from "./BookingsView";
import { TransStatusPill } from "./StatusPill";

type IStyle = ReturnType<typeof editStatusViewJss>;

const BookingStatusSelect = withProps(withStyles(StatusSelect, statusSelectJss),
    {
        optionStyle: defaultOptionStyle
    }
);

interface IProps extends WithStyles<IStyle> {
    booking?: Transaction;
    onRequestClose: (updateData?: { booking: Transaction }) => void;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

function notificationTargetString(notification: NotificationType) {
    switch (notification) {
        case "TSP":
            return "TSP";
        default:
            return FormatUtil.upperCaseToSpaced(notification).toLowerCase();
    }
}

const NOTIFICATION_REMINDER_TEXT = (notifications: NotificationType[]) => {
    const notificationTargetsText = notifications.length > 1 ?
        notifications.slice(0, notifications.length - 1).map(notification => notificationTargetString(notification)).join(", ") + " and " + notificationTargetString(notifications[notifications.length - 1]) :
        notificationTargetString(notifications[0]);
    return `${FormatUtil.toSentenceCase(notificationTargetsText)} will be notified about the status change.`;
};

const EditStatusView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { booking = new Transaction(), onRequestClose, classes, appClasses } = useStyles(props, editStatusViewJss); // booking is never undefined since we assume renderWhenData is true
    const [waiting, setWaiting] = useState<boolean>(false);
    const [selectedAction, setSelectedAction] = useState<BookingAction | undefined>(undefined);
    const [externalNote, setExternalNote] = useState<string>("");
    const [internalNote, setInternalNote] = useState<string>("");
    const [updateRelated, setUpdateRelated] = useState<boolean | undefined>(true);
    const formRef = useRef<any>(null);
    const statusOptions = useMemo(() => booking!.actions
        .filter(action => action.changes === "STATUS" && action.action === "UPDATE")
        .map(action => ({ value: action, label: transStatusToDisplayString(action.newStatus) }))
        .sort((a, b) => a.label.localeCompare(b.label)), [booking]) as { value: any, label: string }[];
    const relatedAlert = selectedAction?.withRelated && (updateRelated ? selectedAction.withRelated.alertChecked : selectedAction.withRelated.alertUnchecked);
    return (
        <SideView
            title={"Edit Booking Status"}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        <div className={appClasses.formGroup}>
                            <label>
                                Current status
                            </label>
                            <div className={classNames(appClasses.value, classes.statusValue)}>
                                <TransStatusPill status={booking!.status!} />
                            </div>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label>
                                New status
                            </label>
                            <Validator
                                value={selectedAction}
                                name="newStatus"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {({ errorMessage }) =>
                                    <div className={classNames(appClasses.value)} data-testid="New status">
                                        <BookingStatusSelect
                                            options={statusOptions}
                                            value={selectedAction}
                                            onChange={(status?: BookingAction) => {
                                                setSelectedAction(status);
                                            }}
                                            allowNoValue={true}
                                        />
                                        {errorMessage &&
                                            <div className={appClasses.validationError}>{errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>
                        {selectedAction?.withRelated && selectedAction?.withRelated?.onlyChecked === false &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    {"Also update " + booking!.relatedBookings.map(rb => FormatUtil.toFirstUpperCase(rb.type)).join(", ")}
                                </label>
                                <div className={classNames(appClasses.value)}>
                                    <CheckboxStyled
                                        checked={updateRelated}
                                        onChange={e => {
                                            setUpdateRelated(e.target.checked);
                                        }}
                                    />
                                </div>
                            </div>}
                        <div className={appClasses.formGroup} style={{ marginRight: '30px' }}>
                            <Tooltip className={appClasses.cursorHelp} title={"Note to be delivered to the rider."}>
                                <label htmlFor="externalNote">Note to rider</label>
                            </Tooltip>
                            <textarea
                                name="externalNote"
                                value={externalNote}
                                onChange={(e) => setExternalNote(e.currentTarget.value)}
                                placeholder="Enter a note to the rider here..."
                            />
                        </div>
                        <div className={appClasses.formGroup} style={{ marginRight: '30px' }}>
                            <Tooltip className={appClasses.cursorHelp}
                                title={"Note just visible to other admins, not to the rider."}>
                                <label htmlFor="internalNote">Internal note</label>
                            </Tooltip>
                            <textarea name="internalNote"
                                value={internalNote}
                                onChange={(e) => {
                                    setInternalNote(e.currentTarget.value);
                                }}
                                placeholder="Enter an internal note here..."
                            />
                            <Tooltip title={"Internal note, not visible to the rider."} className={appClasses.cursorHelp}>
                                <IconLocked className={classes.lock} />
                            </Tooltip>
                        </div>
                        {selectedAction && selectedAction.notifications.length > 0 &&
                            <div className={appClasses.formGroup}>
                                <label htmlFor="note">💡</label>
                                <div>
                                    {NOTIFICATION_REMINDER_TEXT(selectedAction.notifications)}
                                </div>
                            </div>}
                        {relatedAlert &&
                            <div className={appClasses.formGroup}>
                                <label htmlFor="note">⚠️</label>
                                <div>
                                    {relatedAlert}
                                </div>
                            </div>}
                        {selectedAction?.alert &&
                            <div className={appClasses.formGroup}>
                                <label htmlFor="note">⚠️</label>
                                <div>
                                    {selectedAction.alert}
                                </div>
                            </div>}
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    <button onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                if (valid) {
                                    const update = Util.iAssign(booking!, { status: selectedAction!.newStatus });
                                    update.updateRelated = selectedAction?.withRelated && updateRelated;
                                    let internalTransNote: TransactionNote | undefined;
                                    if (internalNote) {
                                        internalTransNote = Util.iAssign(new TransactionNote(), {
                                            text: internalNote,
                                            timestamp: DateTimeUtil.getNow().utc().toISOString(),
                                            creatorEmail: adminProfile.email,
                                            internal: true,
                                            providerMode: adminProfile.tspMode
                                        });
                                        update.notes = [internalTransNote].concat(update.notes);
                                    }
                                    let externalTransNote: TransactionNote | undefined;
                                    if (externalNote) {
                                        externalTransNote = Util.iAssign(new TransactionNote(), {
                                            text: externalNote,
                                            timestamp: DateTimeUtil.getNow().utc().toISOString(),
                                            creatorEmail: adminProfile.email,
                                            internal: false,
                                            providerMode: adminProfile.tspMode
                                        });
                                        update.notes = [externalTransNote].concat(update.notes);
                                    }
                                    onRequestClose({ booking: update });
                                    setWaiting(true);
                                }
                            })
                    }} className={appClasses.buttonAdd}>
                        Update
                    </button>
                </div>
            </div >
        </SideView >
    );
};

export default EditStatusView;