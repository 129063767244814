import { gql } from "graphql-tag";
import Organization, { ORGANIZATION_ID_FIELD, ORGANIZATION_NAME_FIELD, ORGANIZATION_CLIENT_ID_FIELD, ORGANIZATION_PATH_FIELD, ORGANIZATION_PARENT_ID_FIELD, ORGANIZATION_IS_CLIENT_APP_FIELD, ORGANIZATION_INITIATIVES_FIELD, ORGANIZATION_INITIATIVE_IDS_FIELD } from "../model/Organization";
import NetworkUtil from "../util/NetworkUtil";
import Filter, { SortOrder } from "./Filter";
import Util from "../util/Util";
import ItemsData from "./ItemsData";
import AppSync from "./AppSync";
import GQLError from "../util/GQLError";
import { adminProfile } from "../account/AdminProfile";
import { INITIATIVE_SCHEMA_BUILDER } from "../model/Initiative";

export const ORGANIZATION_SCHEMA_BUILDER = () => `
    {
        ${ORGANIZATION_ID_FIELD}
        ${ORGANIZATION_NAME_FIELD}
        ${ORGANIZATION_CLIENT_ID_FIELD}
        ${ORGANIZATION_PATH_FIELD}
        ${ORGANIZATION_IS_CLIENT_APP_FIELD}
        ${adminProfile.features.trackTripInitiative22828 ?
        `${ORGANIZATION_INITIATIVES_FIELD} ${INITIATIVE_SCHEMA_BUILDER()}` : ""}         
    }
`;

export const GET_ORGANIZATIONS_QUERY = "getOrganizations";
export const getOrganizations = (organizationIds: string[]) => {
    return gql`
    query getOrganizations {
        ${GET_ORGANIZATIONS_QUERY}(organizationIds: ${JSON.stringify(organizationIds)}) {
        items ${ORGANIZATION_SCHEMA_BUILDER()}
        nextToken
        }
    }
    `;
};

export interface ICreateOrganizationInput {
    name: string;
    clientId: string;
    parentId: string;
}

export const organizationCreateFieldsBuilder = () => [
    ORGANIZATION_NAME_FIELD,
    ORGANIZATION_IS_CLIENT_APP_FIELD,
    ORGANIZATION_CLIENT_ID_FIELD,
    ORGANIZATION_PARENT_ID_FIELD,
    ORGANIZATION_INITIATIVE_IDS_FIELD
];

export const GET_ORGANIZATION_QUERY = "getOrganization";
export const getOrganization = (id: string) => gql`
query getOrganization {
    ${GET_ORGANIZATION_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ id })})    
        ${ORGANIZATION_SCHEMA_BUILDER()}
}
`;

export type OtherCreateInput = { parentId?: string };

export const CREATE_ORGANIZATION_QUERY = "createOrganization";
export const createOrganization = (value: Organization, otherInput: OtherCreateInput = {}) => {
    const orgObj = Util.serialize(value);
    orgObj[ORGANIZATION_INITIATIVE_IDS_FIELD] = value.initiatives.map(i => i.id);
    const query = { ...Util.filterKeys(orgObj, organizationCreateFieldsBuilder()), ...otherInput };
    return gql`
    mutation createOrganization {
        ${CREATE_ORGANIZATION_QUERY} (
            input: ${Util.graphqlStringify(query)}
        ) ${ORGANIZATION_SCHEMA_BUILDER()}
    }
    `;
};


export const organizationUpdateFieldsBuilder = () => [
    ORGANIZATION_ID_FIELD,
    ...organizationCreateFieldsBuilder()
];

export const UPDATE_ORGANIZATION_QUERY = "updateOrganization";
export const updateOrganization = (value: Organization, otherInput: OtherCreateInput = {}) => {
    const orgObj = Util.serialize(value);
    orgObj[ORGANIZATION_INITIATIVE_IDS_FIELD] = value.initiatives.map(i => i.id);
    const query = { ...Util.filterKeys(orgObj, organizationUpdateFieldsBuilder()), ...otherInput };
    return gql`
    mutation updateOrganization {
        ${UPDATE_ORGANIZATION_QUERY} 
        (
            input: ${Util.graphqlStringify(query)}            
        ) ${ORGANIZATION_SCHEMA_BUILDER()}
    }
`
};

export const DELETE_ORGANIZATION_QUERY = "disableOrganization";
export const deleteOrganization = (id: string, clientId: string) => {
    return gql`
    mutation deleteOrganization {
        ${DELETE_ORGANIZATION_QUERY} 
        (            
            id: "${id}",
            clientId: "${clientId}"
        ) 
        {
            result
        }
    }
`
};

export interface IListOrganizationsQuery {
    search?: string;
    limit?: number;
    clientId?: string;
    ancestorId?: string;
    sortAsc?: boolean;
    initiativeId?: string;
    nextToken?: string;
}

export const LIST_ORGANIZATIONS_QUERY = "listOrganizations";
export const listOrganizations = (query: IListOrganizationsQuery) => {
    return gql`
        query listOrganizations {
            ${LIST_ORGANIZATIONS_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
                items ${ORGANIZATION_SCHEMA_BUILDER()}           
                nextToken
        }
    }
    `
};

function queryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const queryParams: IListOrganizationsQuery = {
        search: filter.search?.label,
        clientId: filter.clientId,
        ancestorId: filter.orgId,
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        initiativeId: filter.initiativeId,
        limit: limit,
        nextToken: nextToken
    };
    return listOrganizations(queryParams);
}

function processResults({ data, errors }) {
    if (!data?.[LIST_ORGANIZATIONS_QUERY]) {
        return { error: errors?.[0]?.message ?? "This data is currently unavailable" };
    }
    const queryResult = data[LIST_ORGANIZATIONS_QUERY];
    const items = queryResult.items
        .map((itemJson: any) => Util.deserialize(itemJson, Organization));
    return { items, nextToken: queryResult.nextToken }
}

class OrganizationsData extends ItemsData<Organization> {
    private static _instance: OrganizationsData;
    public static get instance(): OrganizationsData {
        if (!this._instance) {
            this._instance = new OrganizationsData(queryFromFilter, processResults);
        }
        return this._instance;
    }

    public invalidateCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_ORGANIZATIONS_QUERY) && cache.data.delete(key);
        }
        );
    }

    public getAll(): Promise<Organization[]> {
        return AppSync.query({
            query: listOrganizations({ limit: 5000 })
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                const queryResult = data[LIST_ORGANIZATIONS_QUERY];
                return (queryResult?.items)
                    .map((itemJson: any) => Util.deserialize(itemJson, Organization));
            });
    }

    public get(id: string): Promise<Organization> {
        return AppSync.query({
            query: getOrganization(id)
        }).then(({ data }) => {
            const organizationJSON = data[GET_ORGANIZATION_QUERY];
            if (!organizationJSON) {
                throw new GQLError('Booking not found for id: ' + id, undefined, true);
            }
            return Util.deserialize(organizationJSON, Organization);
        });
    }

    public create(organization: Organization, otherInput?: OtherCreateInput): Promise<Organization | undefined> {
        return AppSync.mutate({
            mutation: createOrganization(organization, otherInput)
        }).then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const resultJson = data.data[CREATE_ORGANIZATION_QUERY];
                return resultJson && Util.deserialize(Util.undefineNulls(resultJson, true), Organization);
            });
    }

    public update(organization: Organization, otherInput?: OtherCreateInput): Promise<void> {
        return AppSync.mutate({
            mutation: updateOrganization(organization, otherInput)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public delete(organization: Organization): Promise<void> {
        return AppSync.mutate({
            mutation: deleteOrganization(organization.id, organization.clientId!)
        });
    }
}

export const getOrganizationCacheRedirect = (_, args, { getCacheKey }) => {
    return getCacheKey({ __typename: 'Organization', Id: args.id });
};

export default OrganizationsData;