import React, { DragEvent, ChangeEvent, useState } from "react";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { dnDImageJss } from "./DnDImage.jss";
import classNames from "classnames";


type IStyle = ReturnType<typeof dnDImageJss>

interface IProps extends WithClasses<IStyle> {
    imageUrl?: string;
    onImageChange: (file?: any, url?: string) => void;
    accept?: string;
    placeholder?: React.ReactNode;
}

const DnDImage: React.FunctionComponent<IProps> = (props: IProps) => {
    const { imageUrl, onImageChange, accept, placeholder, classes, appClasses } = props;
    const [inDropZone, setInDropZone] = useState<boolean>(false);

    function onDragEnter(e: DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        setInDropZone(true);
    }

    function onDragOver(e: DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
    }

    function onDragLeave(e: DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        setInDropZone(false);
    }

    function onDrop(e: DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        const dt = e.dataTransfer;
        const files = dt.files;
        handleFiles(files);
        setInDropZone(false);
    }

    function handleFiles(files: FileList) {
        if (files.length !== 1 || !files[0].type.startsWith('image/')) {
            return;
        }
        const imageFile = files[0];
        const reader = new FileReader();
        reader.onload = (e: any) => onImageChange(imageFile, e.target.result);
        reader.readAsDataURL(imageFile);
    }
    const uploadInput =
        <input
            id={"upload-btn"}
            type="file"
            accept={accept ?? "image/*"}
            hidden
            onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && handleFiles(e.target.files)}
        />;
    return (
        <div className={classes.main}>
            {imageUrl ?
                <div className={classes.preview}>
                    <div className={classes.imgContainer}>
                        <img className={classes.image} src={imageUrl} />
                    </div>
                    <div className={classes.footer}>
                        {uploadInput}
                        <label htmlFor={"upload-btn"} className={classes.editButton}>
                            Change image
                        </label>
                        <label onClick={() => onImageChange()} className={classes.removeButton}>
                            Clear image
                        </label>
                    </div>
                </div>
                :
                <div className={classNames(classes.dropZone, inDropZone && classes.inDropZone)}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragOver}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                >
                    Drop image or
                    {uploadInput}
                    <label htmlFor={"upload-btn"} className={classes.uploadButton}>Select image file</label>
                    {placeholder &&
                        <div className={classes.placeholder}>{placeholder}</div>}
                </div>}
        </div>
    );

}

export default withStylesO(DnDImage, dnDImageJss);