import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import Loading from "../view/Loading";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import withAsycnData from "../data/WithAsyncData";
import { default as UsersData } from "../data/UsersData";
import User from "../model/User";
import NavHistory from "../nav/NavHistory";

interface IProps extends WithClasses<{}> {
    error?: string;
}

class SearchView extends React.Component<IProps, {}> {

    public render(): React.ReactNode {
        const appStyles = this.props.appClasses;
        return this.props.error ?
            <div className={appStyles.noResults}>
                {this.props.error}
            </div> : <Loading />;
    }

}

const SearchViewStyled = withStyles(SearchView, theme => { });


const SearchUserViewWithData = withAsycnData(SearchViewStyled as any,
    (query: { id: string, navHistory: NavHistory }) => UsersData.instance.get(query.id)
        .then((user: User) => {
            // TODO: re-enable
            // query.navHistory.replace(this.PART_DETAIL_VIEW, {id: user.id});
            return {};
        })
        .catch((error: Error) => ({ error: error.message }))
);

export const PART_SEARCH_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: ["*/searchItem/:sid"],
    propsFromMatch: match => ({ id: match.params.sid }),
    propsToPath: (props: { id: string }) => "/searchItem/" + props.id,
    navLabel: () => "Searching",
    render: ({ viewProps, navHistory }) => <SearchUserViewWithData id={viewProps.id} navHistory={navHistory} />
};