import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import classNames from "classnames";
import NavHistory from "./NavHistory";
import { navStackJss } from "./NavStack.jss";
import { useStyles, WithStyles } from "../css/StyleHelper";
import AdminProfile from "../account/AdminProfile";

type IStyle = ReturnType<typeof navStackJss>;

interface IProps extends WithStyles<IStyle> {
    navHistory: NavHistory;
    profile: AdminProfile;
    className?: string;
}

const NavStack: FunctionComponent<IProps> = props => {
    const { navHistory, profile, classes } = useStyles(props, navStackJss);
    const render = () => {
        const matchToView = navHistory.getMatchToViewMap();
        const sortedMatches = Array.from(matchToView.keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length)
            .filter(match => !matchToView.get(match)?.isModal); // Check this, which I've added to avoid showing the parent view on the stack when opening a modal.
        const stackLength = sortedMatches.length;
        return (
            <div className={classes.main}>
                {sortedMatches.map((match: any, i: number) => {
                    const view = matchToView.get(match)!;
                    return [
                        i > 0 ? <span key={"sep-" + i}> {">"} </span> : null,
                        i < sortedMatches.length - 1 ?  // Don't show link for current view.
                            <span onClick={() => (i - stackLength + 1) !== 0 && navHistory.pop(i - stackLength + 1)} key={i}
                                className={classNames(classes.link, i === stackLength - 1 ? "active" : undefined)}>
                                {view.navLabel({ viewProps: view.propsFromMatch(match, profile) })}
                            </span> : undefined
                    ]
                })}
            </div>
        )
    };
    return (
        <Route
            path={"/*"}
            exact={true}
            render={render}
        />
    );
}

export default NavStack;