import React, { useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { editProviderViewJss } from "./EditProviderView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import View from "../view/View";
import { DefaultSelect } from "../rewards/StatusSelect";
import { ProviderViewProps } from "./ProviderView";
import withAsycnData from "../data/WithAsyncData";
import Provider from "../model/Provider";
import ProvidersData, { UpdateProviderInput, useMobilityOptionsForSelect, usePurposesForSelect } from "../data/ProvidersData";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import AdminUsersData from "../data/AdminUsersData";
import UIUtil from "../util/UIUtil";

function sameElements(a: string[], b: string[]): boolean {
    if (a.length !== b.length) {
        return false;
    }
    return a.every((v) => b.includes(v));
}
export interface EditProviderViewProps {
    provider?: Provider;
    onRequestClose?: (update?: UpdateProviderInput) => void;
}

export interface IProps extends EditProviderViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof editProviderViewJss>;

const EditProviderView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { provider = new Provider(), onRequestClose, classes, appClasses } = props;
    const formRef = useRef<any>(null);
    const [update, setUpdate] = useState<UpdateProviderInput>({
        id: provider.id,
        clientId: provider.clientId,
        mobilityOptions: provider.mobilityOptions?.map(mo => mo.id),
        purposes: provider.purposes?.map(p => p.id)
    });
    const mobilityOptions = useMobilityOptionsForSelect();
    const purposes = usePurposesForSelect();
    return (
        <View
            title={"Edit Provider"}
        >
            <div className={classNames(appClasses.form, classes.narrowForm)}>
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={appClasses.formGroup}>
                        <label>Mobility options</label>
                        <Validator
                            value={update.mobilityOptions}
                            resetOnValid
                        >
                            {(params: { errorMessage: any }) =>
                                <div className={appClasses.value}>
                                    <DefaultSelect
                                        options={mobilityOptions ?? []}
                                        value={update.mobilityOptions ?? mobilityOptions?.map(mo => mo.value)}  // undefined means all
                                        onChange={(value) => {
                                            const newUpdate = structuredClone(update);
                                            newUpdate.mobilityOptions = value;
                                            // newUpdate.mobilityOptions = sameElements(value, (mobilityOptions ?? [])?.map(mo => mo.value)) ? undefined : value; // Handle the "all as undefined" case. It doesn't work since by sending undefined or null the endpoint ignores it.
                                            setUpdate(newUpdate);
                                        }}
                                        placeholder={"None"}
                                        isMulti
                                        isSearchable
                                        isDisabled={mobilityOptions === undefined}
                                    />
                                    {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label>Purposes</label>
                        <Validator
                            value={update.purposes}
                            resetOnValid
                        >
                            {(params: { errorMessage: any }) =>
                                <div className={appClasses.value}>
                                    <DefaultSelect
                                        options={purposes ?? []}
                                        value={update.purposes ?? purposes?.map(p => p.value)}  // undefined means all
                                        onChange={(value) => {
                                            const newUpdate = structuredClone(update);
                                            newUpdate.purposes = value;
                                            // newUpdate.purposes = sameElements(value, (purposes ?? [])?.map(p => p.value)) ? undefined : value; // Handle the "all as undefined" case. It doesn't work since by sending undefined or null the endpoint ignores it.
                                            console.log(newUpdate.purposes);
                                            setUpdate(newUpdate);
                                        }}
                                        placeholder={"None"}
                                        isMulti
                                        isSearchable
                                        isDisabled={purposes === undefined}
                                    />
                                    {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                </ValidatorForm>
            </div>
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button onClick={() => onRequestClose?.()} className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <button
                    onClick={async () => {
                        const valid = await formRef.current.isFormValid(false)
                        if (valid) {
                            onRequestClose?.(update);
                        }
                    }}
                    className={appClasses.buttonAdd}
                >
                    Save
                </button>
            </div>
        </View >
    );
}

const EditProviderViewStyled = withStyles(EditProviderView, editProviderViewJss);

const EditProviderViewWithData = withAsycnData(EditProviderViewStyled,
    (query: ProviderViewProps) =>
        ProvidersData.instance.get(query.id)
            .then((provider: Provider) => ({ provider })) as Promise<{ provider?: Provider }>);


export const PROVIDER_EDIT_VIEW: IViewRouteConfig<ProviderViewProps> =
{
    path: ["*/providerId/:id/edit"],
    propsToPath: () => "/edit",
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: () => "Edit Provider",
    render: ({ viewProps, navHistory, waitFor }) => {
        return (
            <EditProviderViewWithData
                id={viewProps.id}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (update?: UpdateProviderInput) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        await waitFor(ProvidersData.instance.update(update));
                        AdminUsersData.instance.invalidateCache();
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};