import {genJss} from "../css/gen.jss";
import {Theme} from "../css/Theme";
import Util from "../util/Util";

export const rewardsAdminViewJss = (theme: Theme) => ({
    main: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 500px)',
        // To avoid cells (cards) to shrink in Safari, when embedded on 3scale (couldn't find why it just happens when embedded).
        ...Util.isSafari() && {gridTemplateRows: 'repeat(1000, max-content)'},
        ...genJss.grow,
        flexWrap: 'wrap',
        ...genJss.spaceAround,
        '& > *': {
            margin: '20px'
        }
    }
});