import React from 'react';
import { genJss, resetJss } from '../css/gen.jss';
import { useStyles, WithStyles } from '../css/StyleHelper';
import { Client } from '../model/Client';
import Transaction from '../model/Transaction';
import { ReactComponent as IconUserDetails } from "../images/ic-info.svg";
import { adminProfile } from '../account/AdminProfile';
import classNames from 'classnames';
import genStyles from "../css/general.module.css";

const bookingSubtitleJss = theme => ({
    main: {
        ...genJss.flex,
        ...genJss.alignCenter
    },
    userName: {
        ...theme.textColorDefault,
        ...theme.textWeightSemibold,
        ...genJss.flex,
        ...genJss.alignCenter,
        '& button': {
            ...resetJss.button,
            ...theme.textColorDefault,
            ...theme.textWeightSemibold,
            fontSize: '16px'
        },
        '& > *': {
            marginLeft: '5px'
        }
    }
});

interface BookingSubtitleProps extends WithStyles<ReturnType<typeof bookingSubtitleJss>> {
    booking: Transaction, selectedClientID?: string, clients?: Client[], onOpenUserDetails?: (userId: string) => void;
};
export const BookingSubtitle: React.FunctionComponent<BookingSubtitleProps> =
    (props: BookingSubtitleProps) => {
        const { booking, selectedClientID, clients, onOpenUserDetails, classes, appClasses } = useStyles(props, bookingSubtitleJss);
        const subtitleTexts: React.ReactNode[] = [];
        if (booking.userName) {
            subtitleTexts.push(
                <div className={classes.userName} >
                    By
                    {onOpenUserDetails && booking.userId ?
                        <button className={appClasses.detailLink} onClick={e => { e.preventDefault(); onOpenUserDetails(booking.userId!); }}>
                            {booking.userName}
                            <IconUserDetails />
                        </button> : <div>{booking.userName}</div >}
                </div>
            );
        }
        if (booking.userPhone) {
            subtitleTexts.push("Phone: " + booking.userPhone);
        }
        if (adminProfile.isSuperApp && !selectedClientID) {
            const clientAppName = clients?.find(client => client.clientID === booking.clientId)?.clientName;
            clientAppName && subtitleTexts.push(clientAppName);
        }
        return (
            <div className={classes.main} >
                {
                    subtitleTexts.reduce((elems: React.ReactElement[], text: React.ReactNode, i: number) => {
                        i > 0 && elems.push(<div className={classNames(genStyles.charSpace, genStyles.charSpaceLeft)
                        } key={i + "a"} >⋅</div>);
                        elems.push(<div key={i + "b"}> {text} </div>);
                        return elems;
                    }, [])}
            </div>
        )
    };