import React, { useContext, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { addToOrgViewJss } from "./AddToOrgView.jss";
import Util from "../util/Util";
import { PARTICIPANTS_VIEW_PATH } from "./UserViewConstants";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import withAsycnData from "../data/WithAsyncData";
import UsersData from "../data/UsersData";
import User from "../model/User";
import UIUtil from "../util/UIUtil";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import { adminProfile } from "../account/AdminProfile";
import Organization from "../model/Organization";
import View from "../view/View";
import { PART_NEW_VIEW, getOrgSelectOptions } from "./EditUserView";
import { AppContext, getOrgIDPath } from "../app/App";
import { genClassNames } from "tripkit-react";
import AppData from "../model/AppData";
import Loading from "../view/Loading";
import { orgFromId } from "../app/OrgSelectorHelpers";
import { i18n } from "../i18n/TKI18nConstants";
import { PART_DETAIL_VIEW } from "./UserView";

type IStyle = ReturnType<typeof addToOrgViewJss>;

interface IProps extends WithClasses<IStyle> {
    purpose: "addToOrg" | "searchBeforeAdd";
    orgs?: Organization[];
    onRequestClose: (data?: { user: User, orgId: string }) => void;
    onCreateUser: () => void;
    onOpenUser: (user: User) => void;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

ValidatorForm.addValidationRule('doesntBelongToOrg', ({ user, orgId }) => {
    return !(user && orgId && user.appData?.organizations?.includes(orgId));
});

const AddToOrgView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { purpose = "searchBeforeAdd", orgs = [], onRequestClose, onCreateUser, classes, appClasses } = props;
    const isAddToOrg = purpose === "addToOrg";
    const formRef = useRef<any>(null);
    const selectedOrgId = getOrgIDPath();
    const fullAccessOrgs = orgs.filter(o => o.fullAccess);  // Since renderWhenData={true} orgs is defined at this point
    const orgOptions = useMemo(() => getOrgSelectOptions(fullAccessOrgs), []);
    const [orgId, setOrgId] = useState<string | undefined>( // Default: current organization (OrgSelector), if admin has full access to it.
        selectedOrgId && fullAccessOrgs.find(o => o.id === selectedOrgId) ? selectedOrgId : undefined
    );
    const [searchQuery, setSearchQuery] = useState<string>("");
    // const [searchQuery, setSearchQuery] = useState<string>("mauro+mi@skedgo.com");
    const { selectedClientID } = useContext(AppContext);
    const [searchResult, setSearchResult] = useState<User | undefined | null>(undefined);
    const [waiting, setWaiting] = useState<boolean>(false);

    function onUserSearch() {
        setWaiting(true);
        UsersData.instance.search(searchQuery, { clientId: selectedClientID, orgId: null })
            .then(users => users?.find(user => user.email?.toLowerCase() === searchQuery.toLowerCase() || user.phoneNumber === searchQuery))
            .then(user => setSearchResult(user ?? null))
            .finally(() => setWaiting(false));
    }
    const selectedOrg = orgFromId(selectedOrgId!, orgs)!;

    const userPreview = searchResult &&
        <>
            <div className={classNames(genClassNames.flex, genClassNames.column, genClassNames.grow)}>
                <div className={appClasses.formGroup}>
                    <label>
                        Name
                    </label>
                    <div className={classNames(appClasses.value)}>
                        {searchResult.name}
                    </div>
                </div>
                {/* <div className={appClasses.formGroup}>
                                <label>
                                    Email
                                </label>
                                <div className={classNames(appClasses.value)}>
                                    {searchResult.email}
                                </div>
                            </div>
                            <div className={appClasses.formGroup}>
                                <label>
                                    Phone
                                </label>
                                <div className={classNames(appClasses.value)}>
                                    {searchResult.phoneNumber}
                                </div>
                            </div> */}
                {/* <div className={appClasses.formGroup}>
                                <label>
                                    {i18n.t("Organizations")}
                                </label>
                                <div className={classNames(appClasses.value)}>
                                    {searchResult.appData?.organizations?.map(orgId => orgs?.find(o => o.id === orgId)?.name ?? "unknown").join(", ")
                                        || 'None'}
                                </div>
                            </div> */}
            </div>
            {searchResult.pictureLarge &&
                <img src={searchResult.pictureLarge} className={classes.picture} />}
        </>;

    let searchResultUI;
    if (waiting) {
        searchResultUI = <Loading />;
    } else if (searchResult === undefined) {
        searchResultUI = null;
    } else {
        if (isAddToOrg) {
            searchResultUI =
                <>
                    <div className={classes.userResultWrapper}>
                        <div className={classes.userResult}>
                            {searchResult === null ?
                                <div className={classes.noUserResult}>
                                    {`No user found with email or phone: "${searchQuery}". Try another search, or `}
                                    <button
                                        className={classNames(appClasses.buttonLink, classes.createUserBtn)}
                                        onClick={() => onCreateUser()}
                                    >
                                        create a new user
                                    </button>.
                                </div> : userPreview}
                        </div>
                    </div>
                    {searchResult &&
                        <button
                            onClick={() => {
                                formRef.current.isFormValid(false)
                                    .then((valid: boolean) => {
                                        if (valid) {
                                            onRequestClose({ user: searchResult, orgId: orgId! });
                                        }
                                    })
                            }}
                            className={classNames(appClasses.buttonAdd, classes.addToOrgBtn)}>
                            {i18n.t("Add.to.organization")}
                        </button>}
                </>
        } else {
            searchResultUI =
                <>
                    <div className={classes.userResultWrapper}>
                        {searchResult !== null &&
                            <div style={{ marginBottom: '16px' }}>{`A user with that ${searchQuery.includes("@") ? "email" : "phone"} already exists:`}</div>}
                        <div className={classes.userResult}>
                            {searchResult === null ?
                                <div className={classes.noUserResult}>
                                    {`No user found with email or phone: "${searchQuery}".`}
                                </div> : userPreview}
                        </div>
                    </div>
                    {
                        searchResult === null ?
                            <button
                                onClick={() => onCreateUser()}
                                className={classNames(appClasses.buttonAdd, classes.addToOrgBtn)}
                            >
                                {i18n.t("Create.a.new.user")}
                            </button> :
                            <button
                                onClick={() => {
                                    formRef.current.isFormValid(false)
                                        .then((valid: boolean) => {
                                            if (valid) {
                                                props.onOpenUser(searchResult);
                                            }
                                        })
                                }}
                                className={classNames(appClasses.buttonAdd, classes.addToOrgBtn)}
                            >
                                {i18n.t("Open.user.details")}
                            </button>
                    }
                </>
        }
    }

    return (
        <View
            title={isAddToOrg ? `${i18n.t("Add.user")} to ${selectedOrg.name}` : i18n.t("Add.user")}
        >
            <div className={classes.main}>
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        <div className={appClasses.formGroup}>
                            <label>
                                Search User
                            </label>
                            <div className={classNames(appClasses.value, classes.searchValue)}>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={e => {
                                        setSearchQuery(e.target.value);
                                        setSearchResult(undefined); // Reset search result.                                        
                                    }}
                                    placeholder={"Enter email or phone"}
                                />
                                <button
                                    className={appClasses.buttonOk}
                                    onClick={e => {
                                        onUserSearch();
                                        e.preventDefault();
                                    }}
                                    disabled={!searchQuery}>
                                    Search
                                </button>
                                <Validator
                                    value={{ user: searchResult, orgId }}
                                    validators={isAddToOrg ? ["doesntBelongToOrg"] : []}
                                    errorMessages={isAddToOrg ? ["the user already belongs to this organization"] : []}
                                    resetOnValid
                                >
                                    {({ errorMessage }) => errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                </Validator>
                            </div>
                        </div>
                        {searchResultUI}
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                </div>
            </div >
        </View >
    );
};

const AddToOrgViewStyled = withStyles(AddToOrgView, addToOrgViewJss);

const AddToOrgViewData = withAsycnData(AddToOrgViewStyled,
    (query: {}) => adminProfile.orgsP
        .then(orgs => ({ orgs }))
        .catch(e => console.log(e)) as Promise<{ orgs?: Organization[], error?: Error }>);

export type UserSearchPurpose = "addToOrg" | "searchBeforeAdd";

// noinspection JSUnusedLocalSymbols
export const ADD_TO_ORG_VIEW: IViewRouteConfig<{ purpose: UserSearchPurpose }> =
{
    path: PARTICIPANTS_VIEW_PATH.map(path => path + "/addToOrg")
        .concat(PARTICIPANTS_VIEW_PATH.map(path => path + "/searchBeforeAdd")),
    propsFromMatch: (match: any) => ({ purpose: match.path.includes("/addToOrg") ? "addToOrg" : "searchBeforeAdd" }),
    propsToPath: (props) => "/" + props.purpose,
    navLabel: (props) => props.viewProps.purpose === "addToOrg" ? "Add to Org" : "Search",
    render: ({ viewProps, navHistory, waitFor, selectedClientID }) => {
        return (
            <AddToOrgViewData
                purpose={viewProps.purpose}
                onRequestClose={data => {
                    if (data) {
                        const { user, orgId } = data;
                        const update = Util.deepClone(user);
                        if (!update.appData) {
                            update.appData = new AppData();
                        };
                        update.appData.organizations = [...update.appData.organizations ?? [], orgId];
                        waitFor(UsersData.instance.update(update)
                            .then(() => {
                                UsersData.instance.invalidateUserListCaches();
                            })
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            }));
                    } else {
                        navHistory.pop();
                    }
                }}
                onCreateUser={() => {
                    navHistory.replace(PART_NEW_VIEW, {});
                }}
                onOpenUser={user => {
                    navHistory.replace(PART_DETAIL_VIEW, { id: user.id });
                }}
                renderWhenData={true}
                undefineOnUpdate={false}
            />
        );
    }
};