import gql from "graphql-tag";
import { default as User } from "../model/User";
import Util from "../util/Util";
import NetworkUtil from "../util/NetworkUtil";
import {
    BUNDLE_APPLIED_TIMESTAMP_FIELD,
    BUNDLE_EXPIRATION_TIMESTAMP_FIELD,
    BUNDLE_FIELDS_BUILDER, BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD
} from "./BundlesSchema";
import { adminProfile } from "../account/AdminProfile";
import { INITIATIVE_SCHEMA_BUILDER } from "../model/Initiative";

export const getUserCacheRedirect = (_, args, { getCacheKey }) => {
    // args: arguments of getUser query, i.e. getUser(Id:"${id}")
    // getCacheKey uses dataIdFromObject function to map (artificially created) object
    // {__typename: 'User', Id: args.Id} to the cache id.
    return getCacheKey({ __typename: 'User', Id: args.Id });
};

export const USER_ID_FIELD = "Id";
export const USER_SHORT_ID_FIELD = "shortId";
export const USER_NAME_FIELD = "name";
export const USER_GIVEN_NAME_FIELD = "givenName";
export const USER_SURNAME_FIELD = "surname";
export const USER_EMAIL_FIELD = "email";
export const USER_PASSWORD_FIELD = "password";
export const USER_EXTERNAL_ID_FIELD = "externalID";
export const USER_CARD_NUMBER_FIELD = "cardNumber";
export const USER_CURRENT_BUNDLE_FIELD = "currentBundle";
export const USER_FUTURE_BUNDLE_FIELD = "futureBundle";
export const USER_BALANCE_FIELD = "balance";
export const USER_APP_DATA_FIELD = "appData";
export const USER_TYPE_FIELD = "userType";
export const USER_PHONE_FIELD = "phoneNumber";
export const MOBILITY_OPTIONS_FIELD = "mobilityOptions";
export const ENABLED_MODES_FIELD = "enabledModes";
export const RESET_PASSWORD_RESULT = "result";
export const USER_APP_DATA_OS_FIELD = "OS";
export const USER_PICTURE_SMALL = "pictureSmall";
export const USER_PICTURE_LARGE = "pictureLarge";
export const USER_CLIENT_ID_FIELD = "clientId";
export const USER_ORGANIZATIONS_FIELD = "organizations";
export const USER_DISABLED_FIELD = "disabled";
export const USER_INITIATIVES_FIELD = "initiatives";
export const USER_INITIATIVE_IDS_FIELD = "initiativeIds";
export const USER_PUBLIC_NOTE_FIELD = "publicRiderNote";
export const USER_INTERNAL_NOTE_FIELD = "internalRiderNote";

// Need to define a different schema, apart from BUNDLE_SCHEMA, to avoid apollo considering it the same object in the
// caché, bundles and applied bundles should be handled as different objects.
export const APPLIED_BUNDLE_SCHEMA_BUILDER = () => `
    {
        ${BUNDLE_FIELDS_BUILDER()}
        ${BUNDLE_APPLIED_TIMESTAMP_FIELD}
        ${BUNDLE_EXPIRATION_TIMESTAMP_FIELD}       
        ${BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD}       
    }
`;


export const USER_BALANCE_RAW_FIELD = "rawBalance";
export const USER_BALANCE_REWARDS_FIELD = "rewardsBalance";
export const USER_BALANCE_USER_FIELD = "userBalance";
export const USER_BALANCE_AVAILABLE_REWARDS_FIELD = "availableRewardsBalance";
export const USER_BALANCE_LIFETIME_REWARDS_FIELD = "lifetimeRewardsBalance";
export const USER_BALANCE_CURRENT_BALANCE_FIELD = "currentBalance";
export const USER_BALANCE_INITIAL_BALANCE_FIELD = "initialBalance";
export const USER_BALANCE_FINAL_BALANCE_FIELD = "finalBalance";

export const USER_BALANCE_SCHEMA = () => `
    {
        ${USER_BALANCE_RAW_FIELD}
        ${USER_BALANCE_REWARDS_FIELD}
        ${USER_BALANCE_USER_FIELD}
        ${USER_BALANCE_AVAILABLE_REWARDS_FIELD}
        ${USER_BALANCE_LIFETIME_REWARDS_FIELD}
        ${adminProfile.features.walletAuditing20675 ?
        `
        ${USER_BALANCE_CURRENT_BALANCE_FIELD}
        ${USER_BALANCE_INITIAL_BALANCE_FIELD}
        ${USER_BALANCE_FINAL_BALANCE_FIELD}
        `
        : ""}
    }
`;

const APP_DATA_SCHEMA_BUILDER = () => `
    {
        ${USER_EXTERNAL_ID_FIELD}      
        ${USER_TYPE_FIELD}
        ${USER_APP_DATA_OS_FIELD}
        ${MOBILITY_OPTIONS_FIELD}
        ${ENABLED_MODES_FIELD}
        ${adminProfile.features.organizations ? USER_ORGANIZATIONS_FIELD : ""}
    }
`;

const USER_SCHEMA_BUILDER = () => `
    {
        ${USER_ID_FIELD}
        ${USER_SHORT_ID_FIELD}
        ${USER_NAME_FIELD}       
        ${USER_GIVEN_NAME_FIELD}       
        ${USER_SURNAME_FIELD}       
        ${USER_EMAIL_FIELD}
        ${USER_PHONE_FIELD}
        ${USER_CARD_NUMBER_FIELD}
        ${USER_CURRENT_BUNDLE_FIELD} ${APPLIED_BUNDLE_SCHEMA_BUILDER()}
        ${USER_FUTURE_BUNDLE_FIELD} ${APPLIED_BUNDLE_SCHEMA_BUILDER()}
        ${USER_BALANCE_FIELD} ${USER_BALANCE_SCHEMA()}
        ${USER_APP_DATA_FIELD} ${APP_DATA_SCHEMA_BUILDER()}
        ${USER_PICTURE_SMALL}
        ${USER_PICTURE_LARGE}
        ${adminProfile.isSuperApp ? USER_CLIENT_ID_FIELD : ""}
        ${adminProfile.features.suspendUser21819 ? USER_DISABLED_FIELD : ""}
        ${adminProfile.features.trackTripInitiative22828 ?
        `${USER_INITIATIVES_FIELD} ${INITIATIVE_SCHEMA_BUILDER()}` : ""}
        ${adminProfile.features.internalTripAndRiderNotes22953 ? USER_PUBLIC_NOTE_FIELD : ""}
        ${adminProfile.features.internalTripAndRiderNotes22953 ? USER_INTERNAL_NOTE_FIELD : ""}
    }
`;

export interface IListUsersQuery {
    name?: string;
    currentBundleId?: string;
    futureBundleId?: string;
    search?: string,
    limit?: number,
    sortAsc?: boolean,
    nextToken?: string,
    clientId?: string,
    organizationIds?: string[],
    disabled?: boolean,
    initiativeId?: string
}

export const LIST_USERS_QUERY = "listUsers";
export const listUsers = (query: IListUsersQuery) => {
    if (!adminProfile.features.suspendUser21819) {
        query = { ...query };
        delete query["disabled"];
    }
    return gql`
    query listUsers {
        ${LIST_USERS_QUERY}(${NetworkUtil.getGQLParamsFromQuery(query)}) {
        items ${USER_SCHEMA_BUILDER()}
        nextToken
        }
    }
    `;
};

export const GET_USER_QUERY = "getUser";
export const getUser = (id: string) => gql`
  query getUser {
    ${GET_USER_QUERY}(Id:"${id}")
    ${USER_SCHEMA_BUILDER()}
  }
`;

export const GET_USER_BY_EXTERNAL_ID_QUERY = "getUserByExternalId";
export const getUserByExternalId = (id: string) => gql`
  query getUser {
    ${GET_USER_BY_EXTERNAL_ID_QUERY}(externalId:"${id}")
    ${USER_SCHEMA_BUILDER()}
  }
`;


const userCreateFieldsBuilder = () => ([
    USER_EMAIL_FIELD,
    ...!adminProfile.features.editUserInfo23131 ? ["username"] : [],
    USER_PASSWORD_FIELD,
    USER_GIVEN_NAME_FIELD,
    USER_SURNAME_FIELD,
    USER_PHONE_FIELD,
    USER_APP_DATA_FIELD,
    ...adminProfile.isSuperApp ? [USER_CLIENT_ID_FIELD] : [],
    ...adminProfile.features.trackTripInitiative22828 ? [USER_INITIATIVE_IDS_FIELD] : [],
    ...adminProfile.features.internalTripAndRiderNotes22953 ? [USER_PUBLIC_NOTE_FIELD] : [],
    ...adminProfile.features.internalTripAndRiderNotes22953 ? [USER_INTERNAL_NOTE_FIELD] : []
]);

export const CREATE_USER_QUERY = "createUser";
export const createUser = (user: User) => {
    const userObj = Util.serialize(user);
    userObj[USER_INITIATIVE_IDS_FIELD] = user.initiatives.map(initiative => initiative.id);
    if (!adminProfile.features.editUserInfo23131) {
        userObj["username"] = user.email;
    }
    return gql`
    mutation createUser {
        ${CREATE_USER_QUERY}(input: ${Util.removeQuotesValue(Util.removeQuotesValue(Util.stringifyObj(userObj, userCreateFieldsBuilder()), MOBILITY_OPTIONS_FIELD), USER_APP_DATA_OS_FIELD)})
        ${USER_SCHEMA_BUILDER()}
}
`;
};

const userUpdateFieldsBuilder = () => ([
    USER_ID_FIELD,
    USER_GIVEN_NAME_FIELD,
    USER_SURNAME_FIELD,
    ...adminProfile.features.editUserInfo23131 ? [USER_EMAIL_FIELD] : [],
    USER_PHONE_FIELD,
    USER_APP_DATA_FIELD,
    ...adminProfile.isSuperApp ? [USER_CLIENT_ID_FIELD] : [],
    ...adminProfile.features.suspendUser21819 ? [USER_DISABLED_FIELD] : [],
    ...adminProfile.features.trackTripInitiative22828 ? [USER_INITIATIVE_IDS_FIELD] : [],
    ...adminProfile.features.internalTripAndRiderNotes22953 ? [USER_PUBLIC_NOTE_FIELD] : [],
    ...adminProfile.features.internalTripAndRiderNotes22953 ? [USER_INTERNAL_NOTE_FIELD] : []
]);

export const UPDATE_USER_QUERY = "updateUser";
export const updateUser = (user: User) => {
    const userObj = Util.serialize(user);
    userObj[USER_INITIATIVE_IDS_FIELD] = user.initiatives.map(initiative => initiative.id);
    return gql`
    mutation updateUser {
        ${UPDATE_USER_QUERY}(input: ${Util.removeQuotesValue(Util.removeQuotesValue(Util.stringifyObj(userObj, userUpdateFieldsBuilder()), MOBILITY_OPTIONS_FIELD), USER_APP_DATA_OS_FIELD)})
        ${USER_SCHEMA_BUILDER()}
}
`;
};

export const SET_CURRENT_BUNDLE = "setAsCurrentBundle";
export const SET_FUTURE_BUNDLE = "setAsFutureBundle";
export const setBundle = ({ userID, bundleID, future = false, clientID, amount, note, freshStart = false }: { userID: string, bundleID: string, future?: boolean, clientID: string, amount?: number, note?: string, freshStart?: boolean }) => gql`
    mutation setBundle {
        ${future ? SET_FUTURE_BUNDLE : SET_CURRENT_BUNDLE}(input: ${Util.stringifyJustValues({ userID: userID, bundleID: bundleID, clientId: clientID, amount, note, freshStart })})
        { result }
}
`;

export const ADD_MONEY = "addMoney";
export const addMoney = ({ userID, clientID, amount, note }: { userID: string, clientID: string, amount: number, note?: string }) => gql`
    mutation addMoney {
        ${ADD_MONEY}(${NetworkUtil.getGQLParamsFromQuery({ userID: userID, clientId: clientID })}, moneyInput: ${Util.stringifyJustValues({ amount, note })})
        { result }
}
`;

export const REMOVE_CURRENT_BUNDLE = "removeCurrentBundle";
export const REMOVE_FUTURE_BUNDLE = "removeFutureBundle";
export const removeBundle = ({ userID, future = false, clientID }: { userID: string, future?: boolean, clientID: string }) => gql`
    mutation removeBundle {
        ${future ? REMOVE_FUTURE_BUNDLE : REMOVE_CURRENT_BUNDLE}(userID:"${userID}", clientId:"${clientID}")
        { result }
}
`;

export const DELETE_USER_QUERY = "deleteUser";
export const deleteUser = (id: string, clientId?: string) => gql`
    mutation deleteUser {
        ${DELETE_USER_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ Id: id, clientId })})
        ${USER_SCHEMA_BUILDER()}
}
`;

export const RESET_PASSWORD_QUERY = "resetPassword";
export const resetPassword = (email: string, clientId?: string) => gql`
  query resetPassword {
    ${RESET_PASSWORD_QUERY}(${NetworkUtil.getGQLParamsFromQuery({ email, clientId })})
    {
        ${RESET_PASSWORD_RESULT}
    }
  }
`;