import * as React from 'react'
import Validator from "../validator/Validator.jsx";
import { ValidatorForm } from "react-form-validator-core";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import Bundle from "../model/Bundle";
import Select from 'react-select';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import BundleTable from "../bundle/BundleTable";
import DateTimeUtil from "../util/DateTimeUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { changeBundleViewJss } from "./ChangeBundleView.jss";
import { i18n } from '../i18n/TKI18nConstants';

type IStyle = ReturnType<typeof changeBundleViewJss>;

interface IProps extends WithClasses<IStyle> {
    bundle?: string;
    bundles: Bundle[];
    onRequestClose?: (update?: string) => void;
    className?: string;
}

interface IState {
    update?: string;
}

class ChangeBundleView extends React.Component<IProps, IState> {

    private selectOptions: { value: string, label: string }[] = [];
    private formRef: any;

    constructor(props: IProps) {
        super(props);
        this.state = {
            update: this.props.bundle
        };
        this.selectOptions = props.bundles.map((bundle: Bundle) => ({ value: bundle.id, label: bundle.name || "" }));
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    private onSaveClick(another?: boolean) {
        this.formRef.isFormValid(false)
            .then((valid: boolean) => {
                if (valid && this.props.onRequestClose) {
                    this.props.onRequestClose(this.state.update);
                }
            });
    }

    private onCancelClick() {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    public render(): React.ReactNode {
        const styles = this.props.classes;
        const appStyles = this.props.appClasses;
        const updateBundleObject = this.state.update ?
            this.props.bundles.find((bundle: Bundle) => this.state.update === bundle.id)! : undefined;
        const futureBundleStart = DateTimeUtil.getNowDate().add(1, 'month').set('date', 1);
        return (
            <div className={classNames(genStyles.flex, this.props.className, genStyles.column)}>
                <ValidatorForm
                    onSubmit={() => {
                        console.log("callback");
                    }}
                    instantValidate={false}
                    ref={(ref: any) => this.formRef = ref}
                    className={classNames(styles.form)}
                >
                    <Validator
                        value={this.state.update}
                        name="bundle"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        resetOnValid
                    >
                        {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                            const value = this.selectOptions.find((option: any) => option.value === params.value);
                            return (
                                <div className={classNames(genStyles.flex, genStyles.row)}>
                                    <div className={classNames(genStyles.flex,
                                        genStyles.alignStart,
                                        genStyles.relative)}>

                                        <Table className={classNames(appStyles.table, styles.bundleTable)}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={appStyles.field}>{i18n.t("New.bundle")}</TableCell>
                                                    <TableCell className={appStyles.field}>Subs. fee</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell className={appStyles.cell}>
                                                        <Select
                                                            options={this.selectOptions}
                                                            // components={{ Option: ModeOption }}
                                                            value={value ? value : null}
                                                            onChange={(option) => {
                                                                this.setState({ update: option.value });
                                                            }}
                                                            className={styles.select}
                                                            classNamePrefix={"select"}
                                                        >
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell className={appStyles.cell}>
                                                        {/* {updateBundleObject &&
                                                        FormatUtil.toMoney(updateBundleObject.subscriptionFee, {nInCents: true})} */}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={styles.startWarnRow}>
                                                    <TableCell className={classNames(appStyles.cell)}>
                                                        <div className={classNames(appStyles.highlightText, styles.startWarn)}>
                                                            {value &&
                                                                "will be applied on " + futureBundleStart.format(DateTimeUtil.dateFormat())}
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        {params.errorMessage &&
                                            <div className={appStyles.validationError} key="2">{params.errorMessage}</div>}
                                    </div>
                                    {
                                        // updateBundleObject &&
                                        <BundleTable
                                            bundle={updateBundleObject}
                                            className={classNames(genStyles.grow, styles.bundleTable)}
                                        />}
                                </div>
                            )
                        }}
                    </Validator>
                </ValidatorForm>
                <div
                    className={classNames(styles.buttonsPanel, genStyles.flex,
                        genStyles.alignCenter, genStyles.spaceBetween, genStyles.alignSelfEnd)}>
                    <button onClick={() => this.onSaveClick()} className={appStyles.buttonAdd}>
                        Apply
                    </button>
                    <button onClick={this.onCancelClick} className={appStyles.buttonCancel}>
                        Discard
                    </button>
                </div>
            </div>
        );
    }

}

export default withStyles(ChangeBundleView, changeBundleViewJss);