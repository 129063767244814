import {TKLocation as Location} from "tripkit-react";
import {LatLng} from "tripkit-react";

export interface PredictionSubstring {
    length: number;
    offset: number;
}
interface AutocompleteStructuredFormatting {
    main_text: string;
    main_text_matched_substrings: PredictionSubstring[];
    secondary_text: string;
    secondary_text_matched_substrings?: PredictionSubstring[];
}

class LocationUtil {

    public static getMainText(loc: Location): string {
        const address = loc.address || "";
        return address.includes(",") ? address.substr(0, address.indexOf(",")) : address;
    }

    public static getSecondaryText(loc: Location): string | null {
        const address = loc.address || "";
        return address.includes(",") ? address.substr(address.indexOf(",") + 1, address.length) : null;
    }

    public static equal<T extends LatLng>(loc1: T | null, loc2: T | null) {
        return loc1 === null ? loc2 === null :
            (loc2 !== null && loc1.getKey() === loc2.getKey());
    }

    public static computeLevenshteinDistance(str1: string, str2: string): number {
        const distance: number[][] = new Array(str1.length + 1);
        for (let i = 0; i < str1.length + 1; i++) {
            distance[i] = new Array(str2.length + 1);
        }
        for (let i = 0; i <= str1.length; i++) {
            distance[i][0] = i;
        }
        for (let j = 1; j <= str2.length; j++) {
            distance[0][j] = j;
        }
        for (let i = 1; i <= str1.length; i++) {
            for (let j = 1; j <= str2.length; j++) {
                distance[i][j] = Math.min(
                    distance[i - 1][j] + 1,
                    distance[i][j - 1] + 1,
                    distance[i - 1][j - 1] + ((str1.charAt(i - 1) === str2.charAt(j - 1)) ? 0 : 1));}
        }
        return distance[str1.length][str2.length];
    }

    public static relevance(query: string, targetText: string, options: { preferShorter?: boolean, allQueryWordsMatch?: boolean } = {}): number {
        return this.match(query, targetText, options).relevance;
    }

    public static match(query: string, targetText: string, options: { preferShorter?: boolean, fillStructuredFormatting?: boolean, allQueryWordsMatch?: boolean } = {}): { relevance: number, structuredFormatting?: AutocompleteStructuredFormatting } {
        query = query.toLowerCase().trim();
        const result: { relevance: number, structuredFormatting?: AutocompleteStructuredFormatting } = { relevance: 0, structuredFormatting: undefined };
        try {
            const { preferShorter, fillStructuredFormatting, allQueryWordsMatch } = options;
            const mainText = targetText.includes(",") ? targetText.substr(0, targetText.indexOf(",")) : targetText;
            const secondaryText = targetText.includes(",") ? targetText.substr(targetText.indexOf(",") + 1, targetText.length) : undefined;
            const targetMainText = mainText.toLowerCase();
            const targetSecondaryText = secondaryText?.toLowerCase();            
            targetText = targetText.toLowerCase();
            if (fillStructuredFormatting) {
                result.structuredFormatting = { main_text: mainText, main_text_matched_substrings: [], secondary_text: secondaryText || "", secondary_text_matched_substrings: [] };
            }
            if (query === targetText) { // query equals to search result
                if (fillStructuredFormatting) {
                    result.structuredFormatting!.main_text_matched_substrings = [{ offset: 0, length: targetMainText.length }];
                    result.structuredFormatting!.secondary_text_matched_substrings = targetSecondaryText ? [{ offset: 0, length: targetSecondaryText.length }] : undefined;
                }
                result.relevance = 1;
                return result;
            }
            if (query === targetMainText) { // query equals to main text
                if (fillStructuredFormatting) {
                    result.structuredFormatting!.main_text_matched_substrings = [{ offset: 0, length: targetMainText.length }];
                }
                result.relevance = .9;
                return result;
            }
            const targetTextWords = targetText.split(" ");
            const mainTextWords = targetMainText.split(" ");
            const secondaryTextWords = targetSecondaryText?.split(" ");
            if (targetMainText.startsWith(query)) {   // query is a prefix of main text
                if (fillStructuredFormatting) {
                    result.structuredFormatting!.main_text_matched_substrings = [{ offset: 0, length: query.length }];
                }
                result.relevance = .85 * (preferShorter ? 40 / (40 + targetTextWords.length) : 1);    // reduce weight if the result is longer (has more words)           
                return result;
            }            
            const queryWords = query.split(" ");
            if (allQueryWordsMatch) {
                if (!queryWords.every(qw => targetTextWords.find(w => w === qw || w.startsWith(qw) || w.includes(qw)))) {
                    return result;
                }
            }
            let relevance = 0;            
            for (const queryWord of queryWords) {
                const matchingMainWord = mainTextWords.find(w => w === queryWord || w.startsWith(queryWord) || w.includes(queryWord));
                if (matchingMainWord) {
                    if (matchingMainWord === queryWord) {
                        relevance += .8 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.main_text_matched_substrings!.push({ offset: targetMainText.indexOf(matchingMainWord), length: queryWord.length });
                        }
                    } else if (matchingMainWord.startsWith(queryWord)) {
                        relevance += .7 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.main_text_matched_substrings!.push({ offset: targetMainText.indexOf(matchingMainWord), length: queryWord.length });
                        }
                    } else {
                        relevance += .6 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.main_text_matched_substrings!.push({ offset: targetMainText.indexOf(matchingMainWord) + matchingMainWord.indexOf(queryWord), length: queryWord.length });
                        }
                    }
                    mainTextWords.splice(mainTextWords.indexOf(matchingMainWord), 1);
                    continue;
                }
                const matchingSecondaryWord = secondaryTextWords?.find(w => w === queryWord || w.startsWith(queryWord) || w.includes(queryWord));
                if (matchingSecondaryWord) {
                    if (matchingSecondaryWord === queryWord) {
                        relevance += .7 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.secondary_text_matched_substrings!.push({ offset: targetSecondaryText!.indexOf(matchingSecondaryWord), length: queryWord.length });
                        }
                    } else if (matchingSecondaryWord.startsWith(queryWord)) {
                        relevance += .6 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.secondary_text_matched_substrings!.push({ offset: targetSecondaryText!.indexOf(matchingSecondaryWord), length: queryWord.length });
                        }
                    } else {
                        relevance += .5 / queryWords.length;
                        if (fillStructuredFormatting) {
                            result.structuredFormatting!.secondary_text_matched_substrings!.push({ offset: targetSecondaryText!.indexOf(matchingSecondaryWord) + matchingSecondaryWord.indexOf(queryWord), length: queryWord.length });
                        }
                    }
                    secondaryTextWords!.splice(secondaryTextWords!.indexOf(matchingSecondaryWord), 1);
                    continue;
                }
                let minDistance = Number.MAX_VALUE;
                for (const searchResultWord of targetTextWords) {
                    minDistance = Math.min(minDistance, LocationUtil.computeLevenshteinDistance(queryWord, searchResultWord));
                }
                relevance += .5 / (queryWords.length + minDistance);
            }
            result.structuredFormatting?.main_text_matched_substrings?.sort((s1, s2) => s1.offset - s2.offset);
            if (result.structuredFormatting?.secondary_text_matched_substrings) {
                if (result.structuredFormatting?.secondary_text_matched_substrings.length === 0) {
                    delete result.structuredFormatting.secondary_text_matched_substrings;
                } else {
                    result.structuredFormatting.secondary_text_matched_substrings.sort((s1, s2) => s1.offset - s2.offset);
                }
            }
            result.relevance = relevance * (preferShorter ? 40 / (40 + targetTextWords.length) : 1);
            return result
        } catch (e) {
            console.log(e);
            console.log(result);
            return result;
        }
    }

    private static readonly earthRadius = 6371000;
    private static readonly radians = 3.14159/180;

    /* This is the Equirectangular approximation. It's a little slower than the Region.distanceInMetres() formula. */
    public static distanceInMetres(c1: LatLng, c2: LatLng): number {
        let lngDelta = Math.abs(c1.lng - c2.lng);
        if (lngDelta > 180) {
            lngDelta = 360 - lngDelta;
        }
        const p1 = lngDelta * Math.cos(0.5 * this.radians * (c1.lat + c2.lat));
        const p2 = (c1.lat - c2.lat);
        return this.earthRadius * this.radians * Math.sqrt(p1*p1 + p2*p2);
    }

}

export default LocationUtil;