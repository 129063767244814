// import React from "react";
import { ValidatorComponent } from "react-form-validator-core";
// TODO: make a .tsx wrapper for Validator so it's interface becomes clear.

/**
 * @augments {React.Component<*, *>}
 */

class Validator extends ValidatorComponent {
    renderValidatorComponent() {
        const { children, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        const errorMessage = !this.state.isValid ? this.getErrorMessage() : null;
        const inputRefCallback = (inputRef) => this.input = inputRef;
        return (
            children({ errorMessage, errorMessages, validators, requiredError, validatorListener, inputRefCallback, ...rest })
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.resetOnChange && this.props.value !== prevProps.value) {
            this.makeValid();
        }
        if (this.props.resetOnValid && this.props.value !== prevProps.value && !this.isValid()) {
            this.validate(this.props.value);
        }
    }

}

const ValidatorStyled = props =>
    <Validator
        {...props}
        containerProps={{ style: { flexGrow: 1 } }}
    />;

export default ValidatorStyled;