import {JsonProperty, JsonObject, Any} from "json2typescript";

@JsonObject
class ItemsResult<T> {

    @JsonProperty("items", [Any])
    public readonly items: T[] = [];
    @JsonProperty("pageCount")
    public pageCount: number = 0;
    @JsonProperty("count")
    public count: number = 0;
    /*
     * true when there's an unexplored next token, so there are potentially more pages than pageCount.
     * Used to enable 'next' button on pagination component
     */
    @JsonProperty("more", Boolean, true)
    public more: boolean = false;

    @JsonProperty("waitingMore", Boolean, true)
    public waitingMore: boolean = false;

    @JsonProperty("error", String, true)
    public error?: string = undefined;

}

export default ItemsResult;