import { IViewRouteConfig } from "./IViewRouteConfig";
import { matchPath } from "react-router-dom";
import { appPath } from "../app/App";

/**
 * Provides methods for app navigation through browser's url history, mainly for push, pop, and replace (refresh) views.
 */
class NavHistory {

    private viewConfigs: IViewRouteConfig<any>[];
    private browserHistory: boolean;

    constructor(viewConfigs: IViewRouteConfig<any>[], browserHistory: boolean = true) {
        this.viewConfigs = viewConfigs;
        this.browserHistory = browserHistory;
    }

    public push<T>(view: IViewRouteConfig<T>, params: T) {
        // Maybe each view has an id and pass view id instead of view. Advantage of this is explicit matching between view and patams type.
        const viewPath = view.propsToPath!(params);
        const pushPath = window.location + viewPath;
        this.browserHistory ? window.location.assign(pushPath) : window.location.replace(pushPath);
    }

    public pushS(viewPath: string) {
        const pushPath = window.location + viewPath;
        this.browserHistory ? window.location.assign(pushPath) : window.location.replace(pushPath);
    }

    public pop(offset: number = -1) {
        const sortedMatches = Array.from(this.getMatchToViewMap().keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        const stackLength = sortedMatches.length;
        const popPath = sortedMatches[stackLength + offset - 1]!.url;
        this.browserHistory ? window.location.assign(popPath) : window.location.replace(popPath);
    }

    /**
     * @deprecated     
     */
    public replace<T>(view: IViewRouteConfig<T>, params: T, pushHistory: boolean = false) {
        const sortedMatches = Array.from(this.getMatchToViewMap().keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        const stackLength = sortedMatches.length;
        const replaceUrl = stackLength < 2 ? `#${appPath(view.propsToPath!(params))}` : sortedMatches[stackLength - 2].url + view.propsToPath!(params);
        this.browserHistory && pushHistory ? window.location.assign(replaceUrl) : window.location.replace(replaceUrl);
    }

    /**
     * Similar to replace, but supports `view` not being the view at the top, updating just the relevant part of the url.
     * Use this for new views to test it, once sure it handles well all cases, then switch everyting to use this one.
     * It just works when we want to replace a specific view with another instance of the same view, but it doesn't work if we want to replace the view at the top with a new one
     * (replace works for this case).
     */
    public replace2<T>(view: IViewRouteConfig<T>, params: T, pushHistory: boolean = false) {
        const sortedMatches = Array.from(this.getMatchToViewMap().keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        const indexOfViewInSortedMatches = sortedMatches.findIndex(match => view.path.includes(match.path));
        const fullUrl: string = sortedMatches[sortedMatches.length - 1].url;
        const originalUrlForView = sortedMatches[indexOfViewInSortedMatches].url;
        const newUrlForView = indexOfViewInSortedMatches < 1 ? `#${appPath(view.propsToPath!(params))}` : sortedMatches[indexOfViewInSortedMatches - 1].url + view.propsToPath!(params);
        const newFullUrl = fullUrl.replace(originalUrlForView, newUrlForView);
        this.browserHistory && pushHistory ? window.location.assign(newFullUrl) : window.location.replace(newFullUrl);
    }

    public replaceS(viewPath: string, pushHistory: boolean = false) {
        const sortedMatches = Array.from(this.getMatchToViewMap().keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        const stackLength = sortedMatches.length;
        const replaceUrl = stackLength < 2 ? `#${appPath(viewPath)}` : sortedMatches[stackLength - 2].url + viewPath;
        this.browserHistory && pushHistory ? window.location.assign(replaceUrl) : window.location.replace(replaceUrl);
    }

    public flatten(views: IViewRouteConfig<any>[]) {
        const flatten = views.reduce((flatList: IViewRouteConfig<any>[], view: IViewRouteConfig<any>) => {
            flatList.push(view, ...this.flatten(view.subViews || []));
            return flatList
        }, []);
        // Remove repeated views, which can happen if a given
        // view is subview of two different views.
        return flatten.filter((view: IViewRouteConfig<any>, i: number) => flatten.indexOf(view) === i);
    }

    public getMatchToViewMap(): Map<any, IViewRouteConfig<any>> {
        // console.log(window.location.hash);
        const locationPath = decodeURI(window.location.hash);
        const matchToView = new Map<any, IViewRouteConfig<any>>();
        for (const view of this.flatten(this.viewConfigs)) {
            const match = matchPath(locationPath, {
                path: view.path,
                exact: false
            });
            if (match) {
                matchToView.set(match, view);
            }
        }
        return matchToView;
    }

    public getMatchFromViewMap(): Map<IViewRouteConfig<any>, any> {
        const locationPath = decodeURI(window.location.hash);
        const matchFromView = new Map<IViewRouteConfig<any>, any>();
        for (const view of this.flatten(this.viewConfigs)) {
            const match = matchPath(locationPath, {
                path: view.path,
                exact: false
            });
            if (match) {
                matchFromView.set(view, match);
            }
        }
        return matchFromView;
    }

    public viewAt(offset: number = 0): IViewRouteConfig<any> {
        const matchToViewMap = this.getMatchToViewMap();
        const sortedMatches = Array.from(matchToViewMap.keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        return matchToViewMap.get(sortedMatches[sortedMatches.length - 1 + offset])!;
    }

    public viewsStack(): IViewRouteConfig<any>[] {
        const matchToViewMap = this.getMatchToViewMap();
        const sortedMatches = Array.from(matchToViewMap.keys()).sort((el1: any, el2: any) => el1.url.length - el2.url.length);
        return sortedMatches.map((match: any) => matchToViewMap.get(match)!);
    }

}

export default NavHistory;