import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";
import {
    dropdownMenu,
    filterWrapper
} from "../rewards/StatusSelect.jss";
export const searchBoxJss = (theme: Theme) => ({
    inputWrapper: {
        ...filterWrapper,
        padding: '6px 10px',
        '& input': {
            background: 'none',
            border: 'none',
            lineHeight: '28px',
            color: black(),
            fontSize: '15px',
            boxShadow: 'none',
            outline: 'none',
            ...genJss.grow,
            ...theme.textSizeBody,
            padding: 0
        },
        '& input::placeholder': {
            color: black(2)
        }
    },
    btnClear: {
        background: 'none',
        border: 'none',
        padding: '0',
        height: '20px',
        minWidth: 'initial'
    },

    iconClear: {
        margin: '0 5px',
        width: '17px',
        height: '17px',
        cursor: 'pointer'
    },

    iconGlass: {
        margin: '0 5px',
        '& path': {
            fill: black(2)
        }
    },

    item: {
        ...genJss.flex,
        padding: '8px',
        ...genJss.borderRadius(6),
        '&$highlighted': {
            backgroundColor: '#ebebeb'
        }
    },

    highlighted: {},

    typed: {
        display: 'none!important'
    },

    resultField: {
        marginRight: '.5em',
        ...theme.textWeightSemibold
    },

    menu: dropdownMenu
});