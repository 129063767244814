import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";
import { alpha } from '@mui/material/styles';

export const paginateJss = (theme: Theme) => ({
    main: {
        display: 'inline-block',
        paddingLeft: '0',
        margin: '20px 0',
        ...genJss.borderRadius(4),
        '& > li': {
            display: 'inline'
        },
        '& > li > a, > li > span': {
            position: 'relative',
            float: 'left',
            padding: '6px 12px',
            marginLeft: '-1px',
            lineHeight: '1.42857143',
            color: alpha(theme.colorSecondary, .81),
            textDecoration: 'none',
            backgroundColor: '#fff',
            cursor: 'pointer'
        },
        '& > li:first-child > a, > li:first-child > span': {
            marginLeft: '0',
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px'
        },
        '& > li:last-child > a, > li:last-child > span': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
        },
        '& > li > a:hover, > li > span:hover, > li > a:focus, > li > span:focus': {
            zIndex: '2',
            color: theme.colorSecondary
        },
        '& > $active > a, > $active > span, > $active > a:hover, > $active > span:hover, > $active > a:focus, > $active > span:focus': {
            color: theme.colorSecondary,
            fontWeight: 'bold',
            cursor: 'default'
        },
        '& > .disabled > span, > .disabled > span:hover, > .disabled > span:focus, > .disabled > a, > .disabled > a:hover, > .disabled > a:focus': {
            color: 'rgba(119, 119, 119, 0.51)',
            cursor: 'default',
            backgroundColor: '#fff',
            borderColor: '#ddd'
        }
    },

    active: {}

});