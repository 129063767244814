import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const organizationsViewJss = (theme: Theme) => ({
    filters: {
        ...genJss.flex,
        ...genJss.alignStart,
        zIndex: 2,  // To be on top of table sticky header
        marginBottom: '20px',
        '& > *': {
            marginRight: '40px'
        }
    },
    filterSelect: {
        '&>*': {
            width: 'initial!important',
            minWidth: '200px'
        }
    },
    searchbox: {
        width: '300px'
    }
});