import React, { useContext, useEffect, useMemo, useRef } from "react";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import DateRangePicker, { invalidRange } from "../time/DateRangePicker";
import { Range } from "moment-timezone";
import Filter, { SortOrder } from "../data/Filter";
import Util from "../util/Util";
import BookingsTable from "./BookingsTable";
import Transaction, { PriceChangeStatus, TransStatus, TransType, paymentStatusValues, priceChangeStatusLabel, priceChangeStatusValues, transStatusToDisplayString } from "../model/Transaction";
import ExportButton, { ExportFormat } from "../export/ExportButton";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bookingsViewJss } from "./BookingsView.jss";
import Paginate from "../paginate/Paginate";
import Loading from "../view/Loading";
import ItemsResult from "../model/ItemsResult";
import TransactionsData from "../data/TransactionsData";
import { BOOKING_DETAIL_VIEW } from "./BookingViewHelpers";
import BundlesData from "../data/BundlesData";
import { IFieldAutocomplete, IViewRouteConfig } from "../nav/IViewRouteConfig";
import FormatUtil from "../util/FormatUtil";
import DateTimeUtil from "../util/DateTimeUtil";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable, from } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { Item } from "../search/SearchBox";
import {
    BookingStatusSelect,
    DefaultSelect,
    getTransTypeOptions,
    SelectOption,
    TransTypeSelect
} from "../rewards/StatusSelect";
import TransactionsTable from "./TransactionsTable";
import { REDEMPTION_EDIT_VIEW } from "../rewards/EditRedemptionView";
import { TRANS_EDIT_VIEW } from "./EditTransView";
import { TRANSACTIONS_VIEW_PATH } from "./TransactionsViewConstants";
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { black } from "../css/gen.jss";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import PageSizeSelect from "../paginate/PageSizeSelect";
import FilterPopup, { FilterConfig } from "../FilterPopup";
import { adminProfile, ModelOperation, ModelType } from "../account/AdminProfile";
import { TRANSACTION_TYPE_FIELD } from "../data/TransactionsSchema";
import ItemGroup from "../model/ItemGroup";
import { ReactComponent as IconAdd } from '../images/ic-add.svg';
import { NEW_BOOKING_CREATE_VIEW } from "./NewCreateBookingView";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import Tooltip from "../uiutil/Tooltip";
import MemoScroll, { MemoScrollRef } from "../uiutil/MemoScroll";
import { Client } from "../model/Client";
import UsersData from "../data/UsersData";
import AutocompleteBox, { AutocompleteBoxType } from "../autocomplete/AutocompleteBox";
import User from "../model/User";
import AutocompleteResult from "../autocomplete/AutocompleteResult";
import Bundle from "../model/Bundle";
import { i18n } from "../i18n/TKI18nConstants";
import { EDIT_STATUS_VIEW } from "./EditStatusViewHelpers";
import { PRICE_CHANGE_VIEW, PriceChangeActionType } from "./PriceChangeViewHelpers";
import { canCreateBooking, CanCreateBookingData } from "../user/UserView";
import { useInitiativeOptions } from "../data/InitiativesData";
import { useProviderOptions } from "../data/ProvidersData";
import { components } from 'react-select';
import TransportUtil from "../util/TransportUtil";
import ViewRestyled from "../view/ViewRestyled";

export const CheckboxStyled = (props: CheckboxProps) => <Checkbox color="default" sx={{ color: black(2), '&.Mui-checked': { color: black(1) } }} {...props} />;

const providerOptionJss = () => ({
    option: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: '10px',
            padding: '1px',
            width: '24px',
            height: '24px',
            boxSizing: 'border-box',
            opacity: '.5'
        },
        '& .select__option': {
            whiteSpace: 'normal'
        }
    },
    right: {
        display: 'flex',
        flexDirection: 'column'
    },
    disabled: {
        color: 'gray',
        fontStyle: 'italic'
    }
});

export const ProviderOption = withStyles(
    (props: any) => {
        const { data, classes } = props as any;
        const provider = data.provider;
        const isDisabled = provider?.disabled;
        return (
            <SelectOption
                {...props}
                content={
                    <div className={classes.option}>
                        {provider &&
                            <img src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />}
                        <div className={classes.right}>
                            <components.Option {...props} />
                            {isDisabled ? <div className={classes.disabled}>disabled</div> : null}
                        </div>
                    </div>
                }
            />
        );
    }, providerOptionJss);

export interface FiltersVisibility {
    type?: boolean;
    status?: boolean;
    transStatus?: boolean;
    providers?: boolean;
    paymentStatus?: boolean;
    search?: boolean;
    userSearch?: boolean;
    bundleSearch?: boolean;
    groupBy?: boolean;
    dateRange?: boolean;
    priceChange?: boolean;
    initiative?: boolean;
}
export interface BookingsViewProps {
    title?: string;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    bookingsResult?: ItemsResult<Transaction>;
    onSelect?: (value: Transaction) => void;
    onEditTrans?: (trans: Transaction) => void;
    onScheduleBooking?: (trans: Transaction) => void;
    onEditBookingStatus?: (trans: Transaction) => void;
    onOpenPriceChange?: (trans: Transaction, priceAction?: PriceChangeActionType) => void;
    onExportData?: (filter: Filter, format: ExportFormat) => Promise<void>;
    onRefreshClick?: () => void;
    onBackgroundRefresh?: () => void;
    error?: Error;
    asView?: boolean;
    showEmployee?: boolean;
    showFilters?: FiltersVisibility;
    onEditedTrans?: (update: Transaction) => Promise<void>;
    showNav?: boolean;
    onCreate?: () => void;
    canCreateBookingData?: CanCreateBookingData;
    selectedClientID?: string;
    clients?: Client[];
    parentViewId?: string;
}

export interface IProps extends BookingsViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof bookingsViewJss>;

export const EXPORT_TRANSACTIONS_TOOLTIP = (formats: ExportFormat[] = ["json", "csv"]) => {
    const formatsText = formats.length > 1 ?
        formats.slice(0, formats.length - 1).map(format => format.toUpperCase()).join(", ") + " or " + formats[formats.length - 1].toUpperCase() + "formats" :
        formats[0].toUpperCase() + " format";
    return `Export transactions data in ${formatsText}, considering the applied filter.`;
};

export enum TransGroups {
    DAY = "Day",
    MONTH = "Month",
    SOON_AND_LATER = "Distance to Now"
}

export const getGroupByOptions = () =>
    Object.values(TransGroups).map((type: string) => ({ value: type, label: type }));


const UserAutocompleteBox = AutocompleteBox as AutocompleteBoxType<User>;
const BundleAutocompleteBox = AutocompleteBox as AutocompleteBoxType<Bundle>;

// TODO: rename to TransactionsView
const BookingsView: React.FunctionComponent<IProps> = (props) => {
    const { showFilters, canCreateBookingData, asView = true, classes, appClasses } = props;
    const { profile: adminProfile, selectedClientID } = useContext(AppContext);

    const memoScrollRef = useRef<MemoScrollRef>(null);

    const initiativeOptions = useInitiativeOptions(selectedClientID);

    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            memoScrollRef.current?.reset();
            const filterUpdate = Util.iAssign(props.filter, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    function handleDateRangeChange(range: Range) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter,
                {
                    range: range,
                    page: 1
                }
            );
            props.onFilterChange(filterUpdate)
        }
    }


    const tableId = adminProfile.app + "-" + transactionsViewId + "-" + props.parentViewId;

    function renderTransactions(trans: Transaction[], showConfigBtn?: boolean) {
        if (trans.length === 0) {
            return (
                <div className={props.classes.noResults}>
                    None
                </div>
            );
        }
        const typeFilter = props.filter.type;
        return typeFilter === TransType.BOOKING ?
            <BookingsTable
                tableId={tableId}
                values={trans}
                onSelect={props.onSelect}
                filter={props.filter}
                onFilterChange={props.onFilterChange}
                onSchedule={props.onScheduleBooking}
                onEditStatus={props.onEditBookingStatus}
                onOpenPriceChange={props.onOpenPriceChange}
                selectedClientID={props.selectedClientID}
                clients={props.clients}
                showConfigBtn={showConfigBtn}
            /> :
            <TransactionsTable
                values={trans}
                isSelectable={(transaction: Transaction) => transaction.type === TransType.BOOKING}
                onSelect={props.onSelect}
                isEditable={(transaction: Transaction) => transaction.type === TransType.REDEMPTION}
                onEdit={props.onEditTrans}
                showEmployee={props.showEmployee}
                showTime={typeFilter === undefined}
                showType={typeFilter === undefined}
                showMoney={typeFilter === TransType.MONEY || typeFilter === undefined}
                showPoints={adminProfile.features.rewards &&
                    (typeFilter === TransType.REWARD || typeFilter === TransType.REDEMPTION || typeFilter === undefined)}
                pointsAsDeducted={typeFilter === TransType.REDEMPTION}
                showStatus={typeFilter === TransType.REDEMPTION}
                onEditedTrans={props.onEditedTrans}
            />
    }

    useEffect(() => {
        const refreshInterval = setInterval(() => props.onBackgroundRefresh && props.onBackgroundRefresh(), 30000);
        return () => {
            clearInterval(refreshInterval);
        }
    }, [props.onBackgroundRefresh]);

    const providerOptions = useProviderOptions({ clientId: selectedClientID, adminProfile, undefineOnUpdate: true });

    const providerOptionsDisabledAtTheEnd = useMemo(() => providerOptions
        ?.sort((a, b) => a.provider?.disabled ? 1 : b.provider.disabled ? -1 : a.label.localeCompare(b.label)), [providerOptions]);


    if (props.error) {
        return (
            <div className={appClasses.noResults}>
                {props.error.message}
            </div>
        );
    }

    const filter = props.filter;
    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (props.bookingsResult) {
        pageCount.current = props.bookingsResult.pageCount + (props.bookingsResult.more ? 1 : 0);
    }

    let transactions;
    const typeFilter = props.filter.type;
    if (props.filter.range && invalidRange(props.filter.range)) {
        transactions =
            <div className={appClasses.noResults}>
                Invalid date range
            </div>;
    } else if (!props.bookingsResult) {
        transactions = <Loading />;
    } else if (props.bookingsResult.count === 0) {
        transactions =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        const transactionItems = props.bookingsResult.items;
        // Use for the titles of the groups the local timezone of the admin, if available, otherwise adminProfile.timezone.
        const titleTimezone = adminProfile.localTimezone ?? adminProfile.timezone;
        if (props.filter.groupBy) {
            let groupTitleFc;
            const getTransactionMoment = (transaction: Transaction) => {
                return DateTimeUtil.momentTZTime(transaction.transactionTime * 1000, titleTimezone);
                // **TODO:** re-enable when sorting by price change createdAt?
                // return props.parentViewId === "price-change-requests" ?
                //     DateTimeUtil.momentTZTime(DateTimeUtil.isoToMomentDefaultTimezone(transaction.priceChange!.createdAt!).valueOf(), titleTimezone) :
                //     DateTimeUtil.momentTZTime(transaction.transactionTime * 1000, titleTimezone);
            }
            switch (props.filter.groupBy) {
                case TransGroups.DAY: groupTitleFc = (item: Transaction) => {
                    const transactionMoment = getTransactionMoment(item);
                    return transactionMoment.format("DD MMMM YYYY") === DateTimeUtil.getNow(titleTimezone).format("DD MMMM YYYY") ?
                        "Today " + transactionMoment.format("ddd DD") :
                        transactionMoment.format("MMMM YYYY") === DateTimeUtil.getNow(titleTimezone).format("MMMM YYYY") ?
                            transactionMoment.format("ddd DD") :
                            transactionMoment.format("YYYY") === DateTimeUtil.getNow(titleTimezone).format("YYYY") ?
                                transactionMoment.format(DateTimeUtil.dayMonthFormat()) : transactionMoment.format(DateTimeUtil.dayMonthFormat() + ", YYYY");
                };
                    break;
                case TransGroups.MONTH: groupTitleFc = item => {
                    const transactionMoment = getTransactionMoment(item);
                    return transactionMoment.get('year') === DateTimeUtil.getNow().get('year') ?
                        transactionMoment.format("MMMM") : transactionMoment.format("MMMM YYYY");
                };
                    break;
                default: groupTitleFc = item => {
                    const transactionMoment = getTransactionMoment(item);
                    const ageInHours = moment.duration(transactionMoment.diff(DateTimeUtil.getNow())).asHours();
                    // ageInHours < -0.01 insead of < 0 to give a tolerance to put a trip on the past, instead of in Departing soon
                    // This avoids an issue with dummyNowTrans falling into Recent category, while we prefer it to fall in "Departing soon".
                    return ageInHours < -3 ? "More than 3hs ago" : ageInHours < -0.01 ? "Recent" : (ageInHours < 3 ? "Departing soon" : "In more than 3hs");
                };
            }
            let itemGroups = TransactionsData.instance.getItemGroups(
                props.bookingsResult.items,
                groupTitleFc);
            // Add an empty group that would contain a booking departing now if it's not already present.
            if (filter.page === 1) {
                const dummyNowTrans = new Transaction();
                dummyNowTrans.type = TransType.BOOKING;
                dummyNowTrans.depart = DateTimeUtil.getNow().valueOf() / 1000;
                const sortAscending = !filter.sortOrder || filter.sortOrder === SortOrder.ASC;
                for (let i = 0; i < itemGroups.length; i++) {
                    if (itemGroups[i].items.length > 0 &&
                        (sortAscending ?
                            itemGroups[i].items[0].depart > dummyNowTrans.depart :   // First trans after now
                            itemGroups[i].items[0].depart < dummyNowTrans.depart)) { // First trans before now
                        if (itemGroups[i].title !== groupTitleFc(dummyNowTrans) &&   // Group that would contain Now not present
                            (i === 0 || itemGroups[i - 1].title !== groupTitleFc(dummyNowTrans))) {
                            itemGroups.splice(i, 0, new ItemGroup([], groupTitleFc(dummyNowTrans))); // So add it.
                        }
                        break;
                    }
                }
            }
            const firstNonEmptyIndex = itemGroups.findIndex(group => group.items.length > 0);
            transactions =
                <div>
                    {itemGroups.map((bookingsGroup: ItemGroup<Transaction>, i: number) => {
                        return (
                            <div className={classes.group} key={i}>
                                <h3>{bookingsGroup.title}</h3>
                                {renderTransactions(bookingsGroup.items, i === firstNonEmptyIndex ? true : undefined)}
                            </div>
                        );
                    })}
                </div>
        } else {
            transactions = renderTransactions(transactionItems, true);
        }
    }

    const paymentStatusOptions = paymentStatusValues
        .map(status => ({ value: status, label: FormatUtil.upperCaseToSpaced(status) }))
        .sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));

    const priceChangeStatusOptions = [
        { value: "ANY", label: "Any status" },
        ...priceChangeStatusValues
            .map((status: PriceChangeStatus) => ({ value: status, label: priceChangeStatusLabel(status) }))
            .sort((el1: any, el2: any) => el1.label.localeCompare(el2.label))
    ];

    let createBookingBtn = props.onCreate && canCreateBookingData && !canCreateBookingData.hide &&
        <button
            onClick={props.onCreate}
            className={appClasses.buttonOk}
            style={{ marginRight: asView ? '120px' : '30px' }}
            disabled={!canCreateBookingData.value}
            name="create-booking-btn"
        >
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            {i18n.t("Create.booking")}
        </button>;

    if (canCreateBookingData?.tooltip) {
        createBookingBtn = createBookingBtn &&
            <Tooltip title={canCreateBookingData.tooltip}>
                <div>
                    {createBookingBtn}
                </div>
            </Tooltip>
    }

    const groupByDefault = props.parentViewId === "bundle" ? undefined : TransGroups.DAY;
    const filterPopupConfigs: FilterConfig[] = [
        ...Util.insertIf(showFilters?.priceChange,
            {
                label: "Status",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={priceChangeStatusOptions}
                            value={filter.priceChange}
                            onChange={(status) => {
                                const newFilter = Util.iAssign(filter, { priceChange: status, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            allowNoValue={false}
                        />
                    </div>
            }),
        ...Util.insertIf(showFilters?.type,
            {
                label: "Type",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <TransTypeSelect
                        options={getTransTypeOptions()}
                        value={filter.type}
                        onChange={(type?: TransType) => {
                            // Reset search since it depends on trans type.
                            const newFilter = Util.iAssign(filter,
                                { type: type, page: 1, search: undefined });
                            onFilterChange(newFilter);
                        }}
                        forFilter={true} />
            }),
        ...Util.insertIf(showFilters?.status,
            {
                label: "Status",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <BookingStatusSelect
                            value={filter.status}
                            onChange={(status?: TransStatus) => {
                                const newFilter = Util.iAssign(filter, { status: status, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            isSearchable />
                    </div>,
                valueSummary: (filter: Filter) => filter.status && transStatusToDisplayString(filter.status),
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { status: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            }),
        ...Util.insertIf(showFilters?.providers && (!providerOptions || providerOptions.length > 1), // if modes is restricted to just one value, then hide mode select
            {
                label: "Provider",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={providerOptionsDisabledAtTheEnd ?? []}
                            value={filter.mode}
                            onChange={(mode?: string) => {
                                const newFilter = Util.iAssign(filter, { mode: mode, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel={"All"}
                            isSearchable
                            isDisabled={!providerOptions}
                            components={{ Option: ProviderOption }}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.mode && providerOptions?.find(option => option.value === filter.mode)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { mode: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            }),
        ...Util.insertIf(showFilters?.paymentStatus,
            {
                label: "Payment Status",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={paymentStatusOptions}
                            value={filter.paymentStatus}
                            onChange={(status) => {
                                const newFilter = Util.iAssign(filter, { paymentStatus: status, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel="All"
                            isSearchable />
                    </div>,
                valueSummary: (filter: Filter) => filter.paymentStatus && paymentStatusOptions.find(option => option.value === filter.paymentStatus)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { paymentStatus: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            }),
        ...Util.insertIf(showFilters?.groupBy,
            {
                label: "Group by",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={getGroupByOptions()}
                            value={filter.groupBy}
                            onChange={(groupCriterion) => {
                                const newFilter = Util.iAssign(filter, { groupBy: groupCriterion });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel={'None'} />
                    </div>,
                valueSummary: (filter: Filter) => filter.groupBy === groupByDefault ? undefined : filter.groupBy ?? 'None',
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { groupBy: groupByDefault });
                    onFilterChange(newFilter);
                }
            }),
        ...Util.insertIf(showFilters?.initiative,
            {
                label: "Initiative",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={initiativeOptions ?? []}
                            value={filter.initiativeId}
                            onChange={value => {
                                const newFilter = Util.iAssign(filter, { initiativeId: value, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel="All"
                            isSearchable
                            isDisabled={!initiativeOptions}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.initiativeId && initiativeOptions?.find(option => option.value === filter.initiativeId)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { initiativeId: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            }),
    ];
    const content =
        <MemoScroll id={"bookingsList"} style={{ overflowY: 'auto', flexGrow: 1 }} ref={memoScrollRef}>
            <div className={classes.transHeader}>
                {showFilters && Object.values(showFilters).some(filterValue => !!filterValue) &&
                    <div className={classes.filterContainer}>
                        <div className={classes.filters}>
                            <FilterPopup
                                filter={props.filter}
                                onFilterChange={props.onFilterChange!}
                                filterConfigs={filterPopupConfigs}
                            />
                            {showFilters.userSearch &&
                                <div className={classes.userSearch}>
                                    <UserAutocompleteBox
                                        value={filter.user ? Util.iAssign(new User(), filter.user) : undefined}
                                        onChange={(user) => {
                                            const newFilter = Util.iAssign(filter, { user: user ? { id: user.id, name: user.name } : undefined });
                                            props.onFilterChange?.(newFilter);
                                        }}
                                        toText={(user: User) => user.name ?? ""}
                                        resultsFor={query => {
                                            if (query === "") {
                                                return Promise.resolve([]);
                                            }
                                            return UsersData.instance.search(query, { limit: 10, clientId: getClientIDPath(), orgId: getOrgIDPath() });
                                        }}
                                        renderResult={(user: User, isHighlighted) => {
                                            const infos: string[] = [];
                                            if (user.email) {
                                                infos.push(user.email);
                                            }
                                            // if (user.phoneNumber) {
                                            //     infos.push(user.phoneNumber);
                                            // }
                                            return (
                                                <AutocompleteResult
                                                    title={user.name ?? ""}
                                                    subtitle={infos.join(" ⋅ ")}
                                                    isHighlighted={isHighlighted}
                                                    key={user.id}
                                                />
                                            );
                                        }}
                                        // placeholder="User"
                                        // placeholder="All users"
                                        placeholder="Search user"
                                        debounce={300}
                                        reserveSpaceForRemoveIcon={true}
                                    />
                                </div>}
                            {showFilters.bundleSearch &&
                                <div className={classes.userSearch}>
                                    <BundleAutocompleteBox
                                        value={filter.bundle ? Util.iAssign(new Bundle(), filter.bundle) : undefined}
                                        onChange={(bundle) => {
                                            const newFilter = Util.iAssign(filter, { bundle: bundle ? { id: bundle.id, name: bundle.name } : undefined });
                                            props.onFilterChange?.(newFilter);
                                        }}
                                        toText={(bundle: Bundle) => bundle.name ?? ""}
                                        resultsFor={query => {
                                            if (query === "") {
                                                return Promise.resolve([]);
                                            }
                                            return BundlesData.instance.search(query, { limit: 10, clientId: getClientIDPath() });
                                        }}
                                        renderResult={(bundle: Bundle, isHighlighted) => {
                                            const infos: string[] = [];
                                            // if (bundle.email) {
                                            //     infos.push(bundle.email);
                                            // }
                                            // if (user.phoneNumber) {
                                            //     infos.push(user.phoneNumber);
                                            // }
                                            return (
                                                <AutocompleteResult
                                                    title={bundle.name ?? ""}
                                                    subtitle={infos.join(" ⋅ ")}
                                                    isHighlighted={isHighlighted}
                                                    key={bundle.id}
                                                />
                                            );
                                        }}
                                        placeholder={"Search " + i18n.t("bundle")}
                                        debounce={0}
                                        reserveSpaceForRemoveIcon={true}
                                    />
                                </div>}
                        </div>
                        {showFilters.dateRange &&
                            <DateRangePicker
                                value={filter.range}
                                onChange={handleDateRangeChange}
                                min={DateTimeUtil.momentDefaultTZ("2022-01-01", DateTimeUtil.HTML5_DATE_FORMAT)}
                            />}
                    </div>}
                <div className={classes.subFilterContainer}>
                    {props.onExportData &&
                        <ExportButton filter={props.filter} onExportData={props.onExportData} helpTooltip={EXPORT_TRANSACTIONS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        {!asView && createBookingBtn}
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>Page size</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => props.onFilterChange &&
                                    props.onFilterChange(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {props.onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={props.onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
            </div>
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.spaceBetween)}>
                <div className={classNames(classes.transContainer, !!pageCount.current && (pageCount.current > 1) && classes.minHeight)}>
                    {transactions}
                    {props.bookingsResult && props.bookingsResult.waitingMore &&
                        <div className={classes.waitingMore}><Loading /></div>}
                </div>
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </MemoScroll>;

    // noinspection PointlessBooleanExpressionJS
    return (
        !asView ? content :
            <ViewRestyled
                title={props.title || "Transactions"}
                showNav={props.showNav}
                right={createBookingBtn}
            >
                {content}
            </ViewRestyled>
    );
}

const BookingsViewStyled = withStyles(BookingsView, bookingsViewJss);

export const BookingsViewWithData = withAsyncDataObs(BookingsViewStyled,
    (query: { filter: Filter }) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
         * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
         * All this won't be necessary if orgs come with the admin profile at the login.
        */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>
            TransactionsData.instance.watchQuery(query.filter)
                .pipe(map((result?: ItemsResult<Transaction>) => ({ bookingsResult: result }))) as Observable<{ bookingsResult?: ItemsResult<Transaction> }>))
            .pipe(concatAll() as any) as any
);

// noinspection JSValidateJSDoc
/**
 * Map each trans type to it's corresponding detailed view. For now just bookings have detailed view.
 * @param {TransType} type
 * @returns {IViewRouteConfig<{id: string}> | undefined}
 */
export function transViewFromType(type: TransType): IViewRouteConfig<{ id: string }> | undefined {
    switch (type) {
        case TransType.BOOKING: return BOOKING_DETAIL_VIEW;
        default: return undefined;
    }
}

export function onBookingsExport(filter: Filter, format: ExportFormat): Promise<void> {
    const csvFormat = adminProfile.appMetadata?.export?.booking?.csvFormat;
    // Hardcoding for testing without updating the auth0 rule.
    // const appID: any = "feonix-catcharide";
    // const csvFormat =
    //     [
    //         { label: 'id', value: 'Id' },
    //         { label: 'user name', value: 'userName' },
    //         { label: 'user phone', value: 'userPhoneNumber' },
    //         { label: 'product name', value: 'productName' },
    //         { label: 'provider phone', value: 'phone' },
    //         { label: 'from address', value: 'from.address' },
    //         { label: 'to address', value: 'to.address' },
    //         { value: 'depart', label: 'depart date', trans: 'toISODate' },
    //         { value: 'depart', label: 'depart time', trans: 'toISOTime' },
    //         { value: 'arrive', label: 'arrive date', trans: 'toISODate' },
    //         { value: 'arrive', label: 'arrive time', trans: 'toISOTime' },
    //         { value: 'price', trans: 'centsToDollars' },
    //         'status',
    //         { label: 'mobility options', value: 'confirmationInput.mobilityOptions' },
    //         { label: 'confirmation notes', value: 'confirmationInput.notes' },
    //         { label: 'return trip', value: 'confirmationInput.returnTrip' },
    //         { label: 'payment status', value: 'paymentStatus' },
    //         { label: 'charge automatically', value: 'chargeAutomatically' },
    //         { label: 'cba', value: 'organizationId', trans: 'toOrganizationName' },
    //     ]
    //         .concat((appID === "feonix-waupaca" || appID === "feonix-catcharide") ? [{ label: 'purpose', value: 'confirmationInput.purpose' }] : []);
    const csvFields = csvFormat?.map(field => {
        if (typeof field === 'object') {
            return { label: field.label ?? field.value, value: FormatUtil.csvTransFunction(field.value, field.trans) }
        } else {
            return { label: field, value: FormatUtil.csvTransFunction(field) }
        }
    })
    const fileName = i18n.t("transactions.export.file.name"); // We plan to support changing wording on a per client basis, allowing to customize export file name by client.
    return TransactionsData.instance.getExportData(filter, { limit: 500 }).then((transactions: Transaction[]) => {
        const transactionsJSON = Util.serialize(transactions);
        switch (format) {
            case "json": return FormatUtil.downloadObjectAsJson(transactionsJSON, fileName);
            case "csv": return FormatUtil.downloadObjectAsCSV(transactionsJSON,
                {
                    exportName: fileName,
                    fields: csvFields
                });
        }

    });
};

const transactionsViewId = "TRANSACTIONS_VIEW";
type SubView = "all" | "next" | "charge" | "price-change-requests";

function subViewTitle(subview: SubView): string {
    switch (subview) {
        case "all": return "Bookings";
        case "next": return "Next Bookings";
        case "charge": return "Charge Bookings";
        case "price-change-requests": return "Price Change Requests";
    }
}

export const TRANSACTIONS_VIEW: IViewRouteConfig<{ sub: SubView, filter?: Filter }> =
{
    path: TRANSACTIONS_VIEW_PATH,
    propsToPath: ({ sub, filter }) => "/trans" + "/" + sub +
        (filter ? "/filter/" + JSON.stringify(Util.serialize(filter)) : ""),
    propsFromMatch: (match, profile) => {
        const transTypeValues = adminProfile.fieldCondValues(ModelType.Transaction, ModelOperation.read, TRANSACTION_TYPE_FIELD);
        const sub: SubView = match.params.sub;
        const filter = Util.deserialize(match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            JSON.parse(match.params.filter) :
            {
                pageSize: profile.pageSizeByView.get(transactionsViewId) || 10,
                page: 1,
                range: sub === "price-change-requests" ?
                    moment.range(DateTimeUtil.getNowDate().add(-15, 'days'), DateTimeUtil.getNowDate()) :
                    sub !== "next" ?
                        moment.range(DateTimeUtil.getNowDate(), DateTimeUtil.getNowDate().add(15, 'days')) : undefined,
                // sortOrder: sub === "price-change-requests" ? SortOrder.DESC : undefined, // **TODO:** re-enable when GQL supports sorting by when sorting by price change createdAt?
                startFromNow: sub === "next" ? -3 * 60 : undefined, // 3 minutes before now, so to include trips that have just gone to the past.
                endFromNow: sub === "next" ? 15 * 24 * 60 * 60 : undefined, // 15 days from now
                // Set filter to booking transactions by default.
                type: transTypeValues && transTypeValues.length === 1 ? transTypeValues[0] : TransType.BOOKING,
                groupBy: sub === "next" ? "Distance to Now" : sub === "charge" ? undefined : "Day",
                charge: sub === "charge" || undefined,
                priceChange: sub === "price-change-requests" ? "ANY" : undefined
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { sub: sub, filter: filter };
    },
    navLabel: ({ viewProps }) => FormatUtil.toSentenceCase(FormatUtil.kebabCaseToSpaced(viewProps.sub)),
    render: ({ viewProps, navHistory, profile, onProfileChange, justData, selectedClientID, selectedOrgID, clients }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(transactionsViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(TRANSACTIONS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        const onSelect = (transaction: Transaction) => {
            const view = transViewFromType(transaction.type);
            view && navHistory.push(view, { id: transaction.id });
        };
        // Reset page number after a reload. Avoid reset if just data, since it means other view is on top, and the filter update will break the url.
        if (filter.page > 1 && TransactionsData.instance.isEmpty() && !justData) { // !justData, or alternatively, navHistory.viewAt() === TRANSACTIONS_VIEW
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const onEditTrans = (trans: Transaction) =>
            navHistory.push(trans.type === TransType.REDEMPTION ? REDEMPTION_EDIT_VIEW as IViewRouteConfig<{ id: string }> :
                TRANS_EDIT_VIEW, { id: trans.id });
        const bookingsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(true);
        };
        const onBackgroundRefresh = () => {
            if (filter.page !== 1) {
                return;     // Avoid refresh when page !== 1, since "silent" refresh don't work in that case (trips are cleared and reloaded).
            }
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(false);
        };
        const transTypeValues = adminProfile.fieldCondValues(ModelType.Transaction, ModelOperation.read, TRANSACTION_TYPE_FIELD);
        const onScheduleBooking = (booking: Transaction) =>
            navHistory.pushS("/bookingSchedule/" + booking.id);
        const onEditBookingStatus = (booking: Transaction) =>
            navHistory.push(EDIT_STATUS_VIEW, { bookingId: booking.id });
        const onCreate = () => navHistory.push(NEW_BOOKING_CREATE_VIEW, {});
        const onOpenPriceChange = (booking, priceAction) => navHistory.push(PRICE_CHANGE_VIEW, { bookingId: booking.id, priceAction });
        const typeFilter = filter.type;
        const showFilters: FiltersVisibility = {
            type: !transTypeValues || transTypeValues.length > 1,
            status: typeFilter === TransType.BOOKING && viewProps.sub !== "price-change-requests" && viewProps.sub !== "charge",
            transStatus: typeFilter === TransType.REDEMPTION && viewProps.sub !== "charge",
            providers: viewProps.sub !== "charge",
            groupBy: viewProps.sub !== "next" && viewProps.sub !== "charge",
            dateRange: viewProps.sub !== "next" && viewProps.sub !== "charge",
            paymentStatus: typeFilter === TransType.BOOKING && adminProfile.features.payments && viewProps.sub !== "charge",
            priceChange: viewProps.sub === "price-change-requests",
            search: viewProps.sub !== "charge",
            userSearch: adminProfile.features.newSearch20645 && !filter.userId && !filter.bundleId // If user or bundle is fixed (e.g. in UserView and BundleView), don't show the search box.
                && viewProps.sub !== "charge",
            bundleSearch: adminProfile.features.newSearch20645 && !adminProfile.isTSPUser && !filter.userId && !filter.bundleId // If user or bundle is fixed (e.g. in UserView and BundleView), don't show the search box.
                && viewProps.sub !== "charge",
            initiative: adminProfile.features.trackTripInitiative22828 && viewProps.sub !== "charge"
        }
        return (
            <BookingsViewWithData
                title={subViewTitle(viewProps.sub)}
                filter={filter}
                showFilters={showFilters}
                onFilterChange={onFilterChange}
                onSelect={viewProps.sub === "price-change-requests" ?
                    (booking) => onOpenPriceChange(booking, booking.actions.some(action => action.changes === "PRICE" && action.action === "RESPOND") ? "RESPOND" : undefined)
                    : onSelect}
                onEditTrans={onEditTrans}
                onScheduleBooking={onScheduleBooking}
                onEditBookingStatus={onEditBookingStatus}
                onOpenPriceChange={onOpenPriceChange}
                onExportData={onBookingsExport}
                onRefreshClick={onRefresh}
                onBackgroundRefresh={profile.pollRefreshBookings ? onBackgroundRefresh : undefined}
                ref={bookingsViewRef}
                // Avoid spinner on update if filter didn't changed, since it may be that the filter involves a range
                // specified relative to now (through startFromNow and endFromNow), which will cause a request of new
                // data, but we want it to happen silently and update on arrive. E.g. when returning from Schedule Trip
                // view.
                shouldUndefineOnUpdate={(prevProps, props) => !Util.equals(props.filter, prevProps.filter)
                    || props.filter.clientId !== prevProps.filter.clientId
                    || props.filter.orgId !== prevProps.filter.orgId
                }
                shouldRefresh={(prevProps, props) =>
                    navHistory.viewAt() === TRANSACTIONS_VIEW &&    // This avoids graphql requests, in Next view, when pushing
                    props !== prevProps}                            // other views while Next view is still visible, or it's data.
                justData={justData}
                onCreate={onCreate}
                canCreateBookingData={canCreateBooking({ selectedClientID, selectedOrgID, adminProfile: profile })}
                selectedClientID={selectedClientID}
                clients={clients}
                parentViewId={viewProps.sub}
            />
        );
    },
    renderDataIfMatch: true,
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        const fields: IFieldAutocomplete[] = [];
        return {
            fields: fields,
            value: filter.search,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace(TRANSACTIONS_VIEW, { sub: viewProps.sub, filter: filter });
            },
            clientId: selectedClientID
        };
    }
};