import { Theme } from "../css/Theme";
import { black, genJss, resetJss } from "../css/gen.jss";
import { appGlobalJss } from "../css/app.jss";

export const dnDImageJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.grow
    },

    dropZone: {
        ...genJss.grow,
        ...genJss.flex,
        ...genJss.column,
        ...genJss.center,
        ...genJss.alignCenter,
        border: '2px dashed ' + black(4),
        ...genJss.borderRadius(5),
        fontSize: '20px'
    },

    inDropZone: {
        border: '2px dashed ' + black(1),
        background: black(5),
        color: '#292a2b'
    },

    imgContainer: {
        ...genJss.grow,
        ...genJss.borderRadius(5),
        overflow: 'hidden',
        '& img': {

        }
    },

    image: {
        ...genJss.flex,
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        objectFit: 'contain'
    },

    uploadButton: {
        ...appGlobalJss(theme).buttonAdd,
        width: 'initial!important',
        margin: '23px 0!important'
    },

    preview: {
        ...genJss.flex,
        ...genJss.column,
        width: '100%'
    },

    footer: {
        ...genJss.flex,
        ...genJss.alignStart,
        ...genJss.center,
        marginTop: '13px',
        '& > label:not(:last-child)': {
            marginRight: '50px'
        }
    },

    editButton: {
        ...resetJss.button,
        ...theme.textSizeBody,
        ...theme.textWeightSemibold,
        color: black(0),
        opacity: .8,
        '&:hover': {
            opacity: 1
        }
    },
    removeButton: {
        ...resetJss.button,
        ...theme.textSizeBody,
        ...theme.textWeightSemibold,
        color: '#E34040',
        opacity: .8,
        '&:hover': {
            opacity: 1
        }
    },
    placeholder: {
        color: black(1),
    }

});