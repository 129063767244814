import React, { useContext } from "react";
import { IViewRouteConfig } from "./IViewRouteConfig";
import { Route } from "react-router-dom";
import { AppContext } from "../app/App";


interface IProps {
    views: IViewRouteConfig<any>[]
}

/**
 * Renders the view in props.views that matches browser's url, using react-router-dom library.
 */

const ViewsRenderer: React.FunctionComponent<IProps> = (props: IProps) => {
    const appContext = useContext(AppContext);
    return (
        <React.Fragment>
            {props.views.map((conf: IViewRouteConfig<any>, i: number) => {
                const render = ({ match }) => {
                    const navHistory = appContext.navHistory;
                    const viewsStack = appContext.navHistory.viewsStack();
                    const shouldRender =
                        // It's the view at the top
                        match.isExact ||
                        // Showing sub view
                        conf.subViews && navHistory.flatten(conf.subViews)
                            .some((view) => navHistory.getMatchFromViewMap().get(view)) ||
                        // Every view stacked over this view is modal.
                        viewsStack.slice(viewsStack.indexOf(conf) + 1).every((view: IViewRouteConfig<any>) => !!view.isModal);
                    const accessible = conf.accessible?.(appContext) ?? true;
                    if (!accessible && (shouldRender || conf.renderDataIfMatch) && conf.render!!) {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, fontSize: '24px' }}>
                                No permissions to access this view
                            </div>
                        );
                    }
                    return (shouldRender || conf.renderDataIfMatch) && conf.render &&
                        conf.render({
                            match: match !== null,
                            viewProps: conf.propsFromMatch!(match, appContext.profile),
                            justData: !shouldRender,    // if !shouldRender it means that conf.renderDataIfMatch
                            ...appContext
                        });
                };
                const renderAlways = !!conf.renderAlways;
                return (
                    <Route
                        path={conf.path}
                        exact={false}    // Leave the decision if render or not to 'render' function above.
                        render={!renderAlways ? render : undefined}
                        children={renderAlways ? render : undefined}
                        key={i}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default ViewsRenderer;