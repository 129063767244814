import gql from "graphql-tag";
import NetworkUtil from "../util/NetworkUtil";
import ItemsData from "./ItemsData";
import Ticket, { TICKET_CLIENT_ID_FIELD, TICKET_CONNECTOR_CODE_FIELD, TICKET_CURRENCY_FIELD, TICKET_DESCRIPTION_FIELD, TICKET_ID_FIELD, TICKET_PAYMENT_METHOD_FIELD, TICKET_PRICE_FIELD, TICKET_PURCHASED_TIMESTAMP_FIELD, TICKET_REUSABLE_FIELD, TICKET_STATUS_FIELD, TICKET_TRANSACTION_ID_FIELD, TICKET_USER_ID_FIELD, TICKET_USER_NAME_FIELD } from "../model/Ticket";
import Filter, { SortOrder } from "./Filter";
import Util from "../util/Util";
import AppSync from "./AppSync";
import * as moment from 'moment-timezone';

/*
type Ticket{
    Id: String,
    transactionId: String,
    purchasedTimestamp: String,
    connectorCode: String,
    reusable: Boolean,
    status: String,
    price: Int,
    currency: String,
    description: String,
    paymentMethod:String
}
*/

export const TICKETS_SCHEMA_BUILDER = () => `
{
    ${TICKET_ID_FIELD}
    ${TICKET_TRANSACTION_ID_FIELD}
    ${TICKET_USER_ID_FIELD}
    ${TICKET_USER_NAME_FIELD}
    ${TICKET_CLIENT_ID_FIELD}
    ${TICKET_PURCHASED_TIMESTAMP_FIELD}
    ${TICKET_CONNECTOR_CODE_FIELD}
    ${TICKET_REUSABLE_FIELD}
    ${TICKET_STATUS_FIELD}
    ${TICKET_PRICE_FIELD}
    ${TICKET_CURRENCY_FIELD}
    ${TICKET_DESCRIPTION_FIELD}
    ${TICKET_PAYMENT_METHOD_FIELD}
}
`;

/*
 getTickets(
        userId: String,
        clientId: String,
        status: String,
        paymentMethod: String,
        limit: Int,
        sortAsc: Boolean,
        nextToken: String
    ): TicketConnection
 */

export interface IGetTicketsQuery {
    dateInit: number;
    dateEnd: number;
    userId?: string;
    clientId?: string;
    status?: string;
    paymentMethod?: string;
    limit?: number;
    sortAsc?: boolean;
    nextToken?: string;
}

export const GET_TICKETS_QUERY = "getTickets";
export const getTickets = (query: IGetTicketsQuery) => {
    return gql`
    query getTickets {
        ${GET_TICKETS_QUERY} ( ${NetworkUtil.getGQLParamsFromQuery(query)}) {
            items ${TICKETS_SCHEMA_BUILDER()}           
            nextToken
    }
}
`
};

function queryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    let startMoment;
    let endMoment;
    if (filter.range) {
        startMoment = moment(filter.range.start);
        endMoment = moment(filter.range.end);
        if (endMoment.get("hour") === 0 && endMoment.get("minute") === 0) {
            endMoment.set("hour", 23).set("minute", 59);
        }
    }
    const queryParams: IGetTicketsQuery = {
        dateInit: Math.floor(startMoment.valueOf() / 1000),
        dateEnd: Math.floor(endMoment.valueOf() / 1000),
        userId: filter.userId ?? filter.user?.id,
        clientId: filter.clientId,
        status: filter.status,  // TODO: it's probably other status???
        paymentMethod: filter.paymentMethod,
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        limit: limit,
        nextToken: nextToken
    };
    return getTickets(queryParams);
}

function processResults({ data, errors }) {
    if (!data?.[GET_TICKETS_QUERY]) {
        return { error: errors?.[0]?.message ?? "This data is currently unavailable" };
    }
    const queryResult = data[GET_TICKETS_QUERY];
    const items = queryResult.items
        .map((itemJson: any) => Util.deserialize(itemJson, Ticket));
    return { items, nextToken: queryResult.nextToken }
}

class TicketsData extends ItemsData<Ticket> {
    private static _instance: TicketsData;
    public static get instance(): TicketsData {
        if (!this._instance) {
            this._instance = new TicketsData(queryFromFilter, processResults);
        }
        return this._instance;
    }

    public invalidateCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(GET_TICKETS_QUERY) && cache.data.delete(key);
        }
        );
    }
}

export default TicketsData;