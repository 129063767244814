import React, { useState } from 'react';
import { HashRouter, NavLink } from 'react-router-dom';
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { PARTICIPANTS_VIEW } from "../user/UsersView";
import { PART_DETAIL_VIEW, PART_DETAIL_VIEW_MODAL } from "../user/UserView";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { BOOKING_DETAIL_VIEW } from "../booking/BookingViewHelpers";
import { TRANSACTIONS_VIEW } from "../booking/BookingsView";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import NavHistory from "../nav/NavHistory";
import { TRANS_EDIT_VIEW, TRANS_NEW_VIEW } from "../booking/EditTransView";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import WaiAriaUtil from "../util/WaiAriaUtil";
import AppSync from "../data/AppSync";
import { ThemeProvider } from 'react-jss';
import { Subtract } from 'utility-types';
import { appJss } from "./App.jss";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { REWARDS_VIEW } from "../rewards/RewardsView";
import ViewsRenderer from "../nav/ViewsRenderer";
import { BUNDLE_COPY_VIEW, BUNDLE_EDIT_VIEW, BUNDLE_NEW_VIEW } from "../bundle/EditBundleView";
import { BUNDLES_VIEW } from "../bundle/BundlesView";
import { BUNDLE_DETAIL_VIEW } from "../bundle/BundleView";
import Modal from 'react-modal';
import { PART_SEARCH_VIEW } from "../search/SearchView";
import { PART_EDIT_VIEW, PART_NEW_VIEW } from "../user/EditUserView";
import { REDEMPTION_EDIT_VIEW } from "../rewards/EditRedemptionView";
import { REWARD_EDIT_VIEW } from "../rewards/EditRewardView";
import RewardsProvider from "../rewards/RewardsProvider";
import { NotificationManager } from 'react-notifications';
import AdminProfile, { adminProfile, ModelOperation, ModelType, orgsPResolve, RemoteProfile } from "../account/AdminProfile";
import LocalStorageItem from "../data/LocalStorageItem";
// import {makeExecutableSchema} from "@graphql-tools/schema";
// import {getDirectives} from "@graphql-tools/utils";
// import {graphqlSchema} from "../data/schema";
// import {GraphQLSchema} from "graphql";
import AccountBtn from "../account/AccountBtn";
import { LocalProfile, setAdminProfile } from "../account/AdminProfile";
import ProfileView from "../account/ProfileView";
import { defaultTheme, Theme } from "../css/Theme";
import { BOOKING_SCHEDULE_VIEW } from "../booking/ScheduleBookingView";
import { BOOKING_CANCEL_VIEW } from "../booking/CancelBookingView";
import { TRANSACTION_TYPE_FIELD } from "../data/TransactionsSchema";
import FormatUtil from "../util/FormatUtil";
import { TKPeliasGeocoder, TKGeocodingOptions, RegionsData, TripGoApi } from "tripkit-react";
import AppSwitcher from "./AppSwitcher";
import TKGoogleLayer from 'tripkit-react/dist/map/TKGoogleLayer';
import TKMapboxGLLayer from 'tripkit-react/dist/map/TKMapboxGLLayer';
import TKGoogleGeocoder from 'tripkit-react/dist/geocode/TKGoogleGeocoder';
import { APP_ID_REGEX as APP_ID_REGEXP, getAppIdPath } from '../account/WithAuth';
import { MONEY_TRANSACTION_VIEW } from '../booking/MoneyTransactionView';
import { BOOKING_CREATE_SIMILAR_VIEW, NEW_BOOKING_CREATE_VIEW } from '../booking/NewCreateBookingView';
import ClientSelector from './ClientSelector';
import Color from 'tripkit-react/dist/model/trip/Color';
import { ASSIGN_BUNDLE_VIEW } from '../user/AssignBundleView';
import { GET_CLIENTS_QUERY, getClients } from '../data/OthersSchema';
import Util from '../util/Util';
import Organization from '../model/Organization';
import { descendentOrg, orgSelectorTree } from './OrgSelectorHelpers';
import OrgSelector from './OrgSelector';
import { ADD_TO_ORG_VIEW } from '../user/AddToOrgView';
import { ADMIN_EDIT_VIEW, ADMIN_NEW_VIEW } from '../settings/EditAdminView';
import { ADMIN_VIEW } from '../settings/AdminView';
import { ORGANIZATION_EDIT_VIEW, ORGANIZATION_NEW_VIEW } from '../settings/EditOrgView';
import { Client } from '../model/Client';
import { ADD_MONEY_VIEW, REMOVE_MONEY_VIEW } from '../user/AddMoneyView';
import { MONEY_TRANSACTIONS_VIEW } from '../booking/MoneyTransactionsView';
import { i18n } from '../i18n/TKI18nConstants';
import { EDIT_STATUS_VIEW } from '../booking/EditStatusViewHelpers';
import { default as TKFormatUtil } from "tripkit-react/dist/util/FormatUtil";
import { ReactComponent as ExternalLink } from '../images/ic-external-link.svg'
import { TICKETS_VIEW } from '../ticket/TicketsView';
import { PRICE_CHANGE_VIEW } from '../booking/PriceChangeViewHelpers';
import { LIST_ORGANIZATIONS_QUERY, listOrganizations } from '../data/OrganizationsData';
import { ADMIN_USERS_VIEW } from '../settings/AdminUsersView';
import { ORGANIZATIONS_VIEW } from '../settings/OrganizationsView';
import AdminUser, { clientAppsForAdmin } from '../model/AdminUser';
import UIUtil from '../util/UIUtil';
import GQLError from '../util/GQLError';
import GlobalSearch from '../search/GlobalSearch';
import { FAVORITES_VIEW } from '../user/FavoritesView';
import { INITIATIVES_VIEW } from '../settings/InitiativesView';
import { INITIATIVE_EDIT_VIEW, INITIATIVE_NEW_VIEW } from '../settings/EditInitiativeView';
import { EDIT_BOOKING_INITIATIVE_VIEW } from '../booking/EditBookingInitiativeView';
import { ReactComponent as IconInfo } from "../images/ic-info.svg";
import { CANCELLATION_POLICY_VIEW, STATUS_DEFINITIONS_VIEW } from './StatusDefinitionsView';
import { PROVIDERS_VIEW } from '../providers/ProvidersView';
import { PROVIDER_VIEW } from '../providers/ProviderView';
import { ADD_BOOKING_NOTE_VIEW, EDIT_BOOKING_NOTE_VIEW } from '../booking/EditNoteView';
import { PROVIDER_EDIT_VIEW } from '../providers/EditProviderView';

type IStyle = ReturnType<typeof appJss>;

interface IProps extends WithClasses<IStyle> {
    remoteProfile: RemoteProfile;
    logout: () => void;
    onClientThemeOverride?: (themeOverride: Partial<Theme>) => void;
}

interface IState {
    waiting: boolean;
    superAdmin: boolean;
    profile: AdminProfile;
    showProfile: boolean;
    selectedClientID?: string;
    clients?: Client[];
    selectedOrgID?: string;
    orgs?: Organization[];
}

/**
 * App properties to be accessible to any component below.
 */
export interface AppContextProps {
    navHistory: NavHistory;
    waitFor: (promise: Promise<any>) => Promise<any>;
    setWaiting: (waiting: boolean) => void;
    superAdmin: boolean;
    profile: AdminProfile;
    onProfileChange: (profile: AdminProfile) => void;
    logout: () => void;
    selectedClientID?: string;
    clients?: Client[];
    selectedOrgID?: string;
    refreshOrgs: () => void;
}

export const AppContext = React.createContext<AppContextProps>({
    navHistory: new NavHistory([]),
    waitFor: (promise: Promise<any>) => Promise.resolve(),
    setWaiting: (waiting: boolean) => { },
    superAdmin: false,
    profile: new AdminProfile({ userData: new AdminUser() } as any, new LocalProfile()),
    onProfileChange: (profile: AdminProfile) => { },
    logout: () => { },
    refreshOrgs: () => { }
});

// Workaround to currency coming from BE with value "USD" instead of "$".
const originalToMoney = FormatUtil.toMoney;
FormatUtil.toMoney = (n, options: any) => originalToMoney(n, { ...options, currency: "$" });
const originalTKToMoney = TKFormatUtil.toMoney;
TKFormatUtil.toMoney = (n, options) => originalTKToMoney(n, { ...options, currency: "$" });

export const mapConfig = () => {
    const locale = adminProfile.locale ?? 'en';
    const profileMapConfig =
        // process.env.NODE_ENV === 'development' ? { provider: 'google', apiKey: "AIza___" } :    // See in credentials.ts
        adminProfile.appMetadata?.map ??
        (adminProfile.appMetadata?.geocoding?.provider === "google" ? // If unspecified, pick google by default if geocoding provider is google, otherwise, mapbox
            { provider: 'google', apiKey: adminProfile.appMetadata!.geocoding!.apiKey } :
            { provider: 'mapbox', apiKey: "pk.eyJ1IjoibWdvbWV6bHVjZXJvIiwiYSI6ImNsbXJ1Y2c4MzA5ajgya3BncmlmOXdvZHYifQ.9ogoBJWetHkMG4V0QL9zPw" });
    return ({
        renderLayer: profileMapConfig.provider === 'google' ?
            () =>
                <TKGoogleLayer
                    googleMapsLoaderConf={{
                        KEY: profileMapConfig.apiKey,
                        LANGUAGE: locale,
                        LIBRARIES: ['places'],
                        REGION: locale === 'ja' ? 'jp' : undefined
                    }}
                /> :
            () =>
                <TKMapboxGLLayer
                    accessToken={profileMapConfig.apiKey}
                    style={"mapbox://styles/mgomezlucero/ckjliu0460xxh1aqgzzb2bb34"}
                    attribution={"<a href='http://osm.org/copyright' tabindex='-1'>OpenStreetMap</a>"}
                />
    });
}

export const geocodingConfig = () => {
    const locale = adminProfile.locale ?? 'en';
    const profileGeocodingConfig = adminProfile.appMetadata?.geocoding ??
        { provider: 'geocode_earth', apiKey: "ge-1943f52b819ee339" };   // Default to geocode.earth
    if (profileGeocodingConfig.provider === 'google') {
        // API key will be set on Google maps (notice that Google geocoder implies Google maps, due to Google's ToS)
        const googleGeocoder = new TKGoogleGeocoder({
            restrictToBounds: true,
            resultsLimit: 5,
            lang: locale
        });
        return ({
            geocoders: {
                'google': googleGeocoder
            },
            reverseGeocoderId: 'google',
            restrictToCoverageBounds: true
        }) as Partial<TKGeocodingOptions>;
    } else {
        const geocodeEarth = new TKPeliasGeocoder({
            server: "https://api.geocode.earth/v1",
            apiKey: profileGeocodingConfig.apiKey,
            restrictToBounds: true,
            resultsLimit: 5,
            lang: locale
        });
        return ({
            geocoders: {
                'geocodeEarth': geocodeEarth
            },
            reverseGeocoderId: 'geocodeEarth'
        }) as Partial<TKGeocodingOptions>;
    }
}

export function appPath(path): string {
    return appPathUpdate({ path });
}

export function appPathUpdate(props: { path?: string, appId?: string, clientId?: string | null, orgId?: string | null } = {}): string {
    const { path = getViewPath(), appId = getAppIdPath(), clientId = getClientIDPath(), orgId = getOrgIDPath() } = props;
    const appPath = appId ? `/${appId}` : ""; // It should never be "".
    const clientIdPath = clientId ? `/client/${clientId}` : "";
    const orgIdPath = orgId ? `/org/${orgId}` : "";
    return `${appPath}${clientIdPath}${orgIdPath}${path}`;
}

const CLIENT_ID_REGEXP = "\/client\/([^\/]*)";
/**
 * This is the source of truth for the client ID, taking precedence over the client stored in LS.
 */
export function getClientIDPath(): string | undefined {
    return window.location.hash.substring(2).match(new RegExp(CLIENT_ID_REGEXP))?.[1] ?? undefined;
}

const ORG_ID_REGEXP = "\/org\/([^\/]*)";
/**
 * This is the source of truth for the client ID, taking precedence over the client stored in LS.
 */
export function getOrgIDPath(): string | undefined {
    return window.location.hash.substring(2).match(new RegExp(ORG_ID_REGEXP))?.[1] ?? undefined;
}

const VIEW_PATH_REGEXP = `${APP_ID_REGEXP}(?:${CLIENT_ID_REGEXP})?(?:${ORG_ID_REGEXP})?(.*)`;
export function getViewPath(): string | undefined {
    return window.location.hash.substring(2).match(new RegExp(VIEW_PATH_REGEXP))?.[4];
}

export function filterClientOrgs(orgs: Organization[], clientID?: string): Organization[] {
    return orgs.filter(org => !clientID || !org.clientId || org.clientId === "1409623403931" // Feonix client id
        || org.clientId === clientID
        || org.isRoot);
}

/**
 * @throws {Error} If no organization is available for the selected client (BE error).
 */
function defaultClientOrg(orgs: Organization[], clientId?: string): Organization {
    // Orgs that the admin has direct access, or descendents of those. So, exclude orgs that are just parents of direct access orgs.
    const fullAccessOrgs = filterClientOrgs(orgs.filter(o => o.fullAccess), clientId);
    if (fullAccessOrgs.length === 0) {  // Should not happen. Captures the case that no org is available for the selected client, which causes issues.
        throw new GQLError("No organization available for the selected client.", undefined, true);
    } else if (adminProfile.isOrgUser && fullAccessOrgs.length === 1) {  // It doesn't make sense to display a tree, but just a singe organization, so select that one.
        // This just makes sense for org users, with access to just one org.
        // TODO: Need to exclude admins from this logic since I want to keep all departments selected for them, event if just have access to one client app org with no descendents.
        return fullAccessOrgs[0];
    } else {
        // First filter orgs by client, and then build the tree, so if the user has access to some subtree for a client, but has no restriction for anoter,
        // then the tree will have Feonix as root (so, no restriction for the client). See how to model the case of no access to any organization for a given client.
        const clientOrgsSubtree = orgSelectorTree(filterClientOrgs(orgs, clientId));
        return clientOrgsSubtree.org;
    }
}
class App extends React.Component<IProps, IState> {

    private appRef = React.createRef<HTMLDivElement>();

    private viewRouteConfigs: IViewRouteConfig<any>[] = [
        PARTICIPANTS_VIEW,
        PART_DETAIL_VIEW,
        PART_NEW_VIEW,
        PART_EDIT_VIEW,
        TRANSACTIONS_VIEW,
        BOOKING_DETAIL_VIEW,
        // BOOKING_NEW_VIEW,
        NEW_BOOKING_CREATE_VIEW,
        BOOKING_CREATE_SIMILAR_VIEW,
        TRANS_NEW_VIEW,
        TRANS_EDIT_VIEW,
        MONEY_TRANSACTIONS_VIEW,
        TICKETS_VIEW,
        BUNDLES_VIEW,
        BUNDLE_DETAIL_VIEW,
        BUNDLE_NEW_VIEW,
        BUNDLE_EDIT_VIEW,
        BUNDLE_COPY_VIEW,
        PART_SEARCH_VIEW,
        REWARDS_VIEW,
        ADD_TO_ORG_VIEW,
        ADMIN_NEW_VIEW,
        ADMIN_EDIT_VIEW,
        ADMIN_VIEW,
        ORGANIZATION_NEW_VIEW,
        ORGANIZATION_EDIT_VIEW,
        // Modal views, also go here at the root level.
        REDEMPTION_EDIT_VIEW,
        REWARD_EDIT_VIEW,
        BOOKING_SCHEDULE_VIEW,
        BOOKING_CANCEL_VIEW,
        MONEY_TRANSACTION_VIEW,
        PART_DETAIL_VIEW_MODAL,
        ASSIGN_BUNDLE_VIEW,
        ADD_MONEY_VIEW,
        REMOVE_MONEY_VIEW,
        EDIT_STATUS_VIEW,
        PRICE_CHANGE_VIEW,
        ADMIN_USERS_VIEW,
        ORGANIZATIONS_VIEW,
        FAVORITES_VIEW,
        STATUS_DEFINITIONS_VIEW,
        CANCELLATION_POLICY_VIEW,
        INITIATIVES_VIEW,
        INITIATIVE_EDIT_VIEW,
        INITIATIVE_NEW_VIEW,
        EDIT_BOOKING_INITIATIVE_VIEW,
        PROVIDERS_VIEW,
        PROVIDER_VIEW,
        PROVIDER_EDIT_VIEW,
        ADD_BOOKING_NOTE_VIEW,
        EDIT_BOOKING_NOTE_VIEW
    ];

    private navHistory = new NavHistory(this.viewRouteConfigs);
    private profileLS = new LocalStorageItem<LocalProfile>(LocalProfile, "ADMIN_PROFILE");

    constructor(props: IProps) {
        super(props);
        const adminProfile = new AdminProfile(props.remoteProfile, this.profileLS.get());
        // If just one client app is available for the user, then select that client app
        // by reflecting the client ID in the path before anything else, so even initial requests
        // include the client ID.
        const accessibleClientIds = adminProfile.clientIds;
        if (accessibleClientIds && accessibleClientIds.length === 1) {
            window.location.replace(`#${appPathUpdate({ clientId: accessibleClientIds[0] })}`);
        }
        const clientIDInPath = getClientIDPath();
        adminProfile.selectedClientID = clientIDInPath;
        const isSuperApp = adminProfile.isSuperApp;
        this.state = {
            waiting: false,
            superAdmin: false,
            profile: adminProfile,
            showProfile: false,
            selectedClientID: clientIDInPath,
            selectedOrgID: getOrgIDPath()
        };
        setAdminProfile(adminProfile);
        TripGoApi.clientID = clientIDInPath;

        // Open by default (no view specified) to bookings list view.
        if (!getViewPath()) {
            window.location.hash = `#${appPath("/trans/all")}`;
        }

        if (isSuperApp) {
            AppSync.queryWithCache({
                // Send empty list, insted of admin accessible org ids, to request the entire tree and filter it below,
                // since it doesn't filter as I need.
                query: getClients(),
                fetchPolicy: 'network-only' // Need this since otherwise sometimes the newtork request is not performed (it seems that AppSync client sometimes use its own caché?)
            }, {
                shouldCacheResponse: (data) => !!data?.data?.[GET_CLIENTS_QUERY] && !data?.errors // To avoid caching when the response is invalid.
            })
                .subscribe({
                    next: response => {
                        console.log("clients", response);
                        if (!response?.data?.[GET_CLIENTS_QUERY] || response.errors) {
                            return;
                        }
                        let clients = response.data[GET_CLIENTS_QUERY] as Client[];
                        clients = clientAppsForAdmin(adminProfile.remote.userData, clients);
                        if (clients.length === 0) {
                            UIUtil.confirmAlert({
                                message: "You don't have access to any client app. Please login with a different account.",
                                buttons: [
                                    {
                                        label: 'Ok',
                                        onClick: () => {
                                            this.props.logout();
                                        }
                                    }
                                ],
                                closeOnEscape: true,
                                closeOnClickOutside: true
                            });
                            return;
                        }
                        this.setState({ clients });
                        // If there is only one client, then select it. Though there's a similar logic above (line 310), this is for the case
                        // when the admin metadata specifies more than one client, but just one of them comes from getClients (the other ones
                        // are not actually valid according to the BE).
                        if (clients.length === 1 && clientIDInPath === undefined) {
                            this.onClientSelected(clients[0]);
                        }
                        if (clientIDInPath) {
                            const clientFromResults = clients.find(client => client.clientID === clientIDInPath);
                            if (clientFromResults) {
                                this.onClientSelected(clientFromResults);
                            } else {
                                UIUtil.confirmAlert({
                                    message: `The client id is invalid: ${clientIDInPath}. Will reset to the "All clients" view.`,
                                    buttons: [
                                        {
                                            label: 'Ok',
                                            onClick: () => {
                                                window.location.hash = `#${appPathUpdate({ clientId: null, path: "/trans/all" })}`;
                                                window.location.reload();
                                            }
                                        }
                                    ],
                                    closeOnEscape: true,
                                    closeOnClickOutside: true
                                });
                            }
                        }
                    }
                });
        }

        adminProfile.features.organizations &&
            this.refreshOrgs({ init: true });

        WaiAriaUtil.addTabbingDetection();
        this.waitFor = this.waitFor.bind(this);
        this.onProfileChange = this.onProfileChange.bind(this);
        this.onClientSelected = this.onClientSelected.bind(this);
        this.setSelectedOrg = this.setSelectedOrg.bind(this);

        // const executableSchema: GraphQLSchema = makeExecutableSchema({
        //     typeDefs: graphqlSchema
        // });
        //
        // const transactionType = executableSchema.getType('Transaction');
        // console.log(getDirectives(executableSchema, transactionType!));
        // const givenNameField = userType && (userType as any).getFields().givenName;
        // console.log(getDirectives(executableSchema, givenNameField));
        // const listTransactionsQuery = (executableSchema as any).getType('Query').getFields().listTransactions;
        // console.log(listTransactionsQuery);
        // const listTransactionsType = listTransactionsQuery.type;
        // console.log(listTransactionsType);
        // const modelReturnType = listTransactionsType.getFields().items.type.ofType;
        // console.log(modelReturnType);
        // console.log(getDirectives(executableSchema, modelReturnType));
    }

    private refreshOrgs(props: { init?: boolean }) {
        const { init } = props;
        return AppSync.queryWithCache({
            query: listOrganizations({ limit: 5000 }),
            fetchPolicy: 'network-only'
        }, { shouldCacheResponse: (data) => !!data?.data?.[LIST_ORGANIZATIONS_QUERY] && !data?.errors })    // To avoid caching when the response is invalid.
            .subscribe({
                next: response => {
                    console.log("orgs", response);
                    if (!response?.data?.[LIST_ORGANIZATIONS_QUERY] || response.errors) {
                        return;
                    }
                    // NetworkUtil.rejectOnGQLError(response);
                    const queryResult = response.data[LIST_ORGANIZATIONS_QUERY];
                    const orgs = (queryResult?.items)
                        .map((itemJson: any) => Util.deserialize2(itemJson, Organization));
                    orgs.sort((org1, org2) => org1.path.localeCompare(org2.path)); // Sort by path lexicographically, which ensures tree nodes are in pre-order    
                    const adminProfileUpdate = Util.deepClone(adminProfile);
                    adminProfileUpdate.allOrgs = orgs;
                    const adminOrgs = adminProfile.orgIds ? adminProfile.orgIds.map(oId => orgs.find(o => o.id === oId)!) : orgs; // Could be just the list with the root org, instead of orgs.                                                            
                    // Filter the relevant part of the tree, that is, those nodes that are ancestors or descendents of admin orgs.
                    const adminRelevantOrgs = orgs.filter(o => adminOrgs.some(ao => descendentOrg(o, ao) || descendentOrg(ao, o)));
                    adminRelevantOrgs.forEach(o => o.fullAccess = adminOrgs.some(ao => descendentOrg(o, ao)))
                    adminProfileUpdate.orgs = adminRelevantOrgs;
                    orgsPResolve(adminRelevantOrgs); // Notice that adminProfile.orgs update does not trigger a state update.                
                    this.setState({ orgs: adminRelevantOrgs });
                    this.onProfileChange(adminProfileUpdate);
                    if (init && !getOrgIDPath()) {    // Don't do default org selection if org is already in the path.                    
                        try {
                            this.setSelectedOrg(defaultClientOrg(adminRelevantOrgs, this.state.selectedClientID));
                        } catch (e) {
                            UIUtil.errorMessage(e as Error, {
                                onClose: () => { this.onClientSelected(undefined) }
                            });
                        }
                    }
                },
                error: UIUtil.errorMessage
            })
    }

    private setSelectedOrg(value?: Organization, options: { resetToRootPath?: boolean } = {}) {
        const { resetToRootPath } = options;
        const valueId = (!value || value.path.length === 4) ? undefined : value.id;
        const viewPath = getViewPath();
        const orgIdUpdate = valueId ?? null;
        let pathUpdate: string | undefined;
        if (resetToRootPath) {
            if (viewPath?.startsWith("/trans/all/new")) {
                pathUpdate = "/trans/all";
            } else if (viewPath?.startsWith("/parts/addToOrg")) {
                pathUpdate = "/parts";
            }
        }
        window.location.replace(`#${appPathUpdate({ orgId: orgIdUpdate, path: pathUpdate })}`);
        this.setState({ selectedOrgID: valueId });  // Put this after window location change to make re-render to happen after url was updated, so NavLinks take the updated values.
    }

    /**
     * Show blocking spinner until promise resolves.
     */
    private waitFor(promise: Promise<any>): Promise<any> {
        this.setState({ waiting: true });
        promise.then(() => this.setState({ waiting: false }))
            .catch(() => this.setState({ waiting: false }));
        return promise; // Notice I return the original promise, not the catched one.
    }

    private onProfileChange(profile: AdminProfile) {
        this.profileLS.save(profile.local);
        this.setState({ profile });
        setAdminProfile(profile);
    }

    private transactionsViewTitle(): string {
        const transTypeValues = adminProfile.fieldCondValues(ModelType.Transaction, ModelOperation.read, TRANSACTION_TYPE_FIELD);
        const title = transTypeValues && transTypeValues.length === 1 ? FormatUtil.toFirstUpperCase(transTypeValues[0]) + "s" : undefined;
        return title || "Transactions";
    }

    private onClientSelected(update?: Client, options: { resetToRootPath?: boolean } = {}) {
        try {
            const { resetToRootPath } = options;
            this.setState({ selectedClientID: update?.clientID });
            adminProfile.selectedClientID = update?.clientID;
            this.props.onClientThemeOverride?.(update ? { colorPrimary: Color.fromJSON(update?.appColors.tintColor).toHex() } : {});
            const viewPath = getViewPath();
            const clientIdUpdate = update?.clientID ?? null;
            const pathUpdate = resetToRootPath ?
                ["/trans/all", "trans/next", "/parts", "/bundles", "/moneytrans", "/tickets",
                    ADMIN_USERS_VIEW.propsToPath({}), ORGANIZATIONS_VIEW.propsToPath({}), INITIATIVES_VIEW.propsToPath({}), PROVIDERS_VIEW.propsToPath({})].find(path => viewPath?.startsWith(path)) ?? "/trans/all"
                : undefined;
            window.location.replace(`#${appPathUpdate({ clientId: clientIdUpdate, path: pathUpdate })}`);
            RegionsData.reset();
            TripGoApi.clientID = update?.clientID;
        } catch (e) {
            console.log(e);
            UIUtil.confirmAlert({
                message: (e as any).stack,
                buttons: [
                    {
                        label: 'Close',
                        onClick: (() => { })
                    }
                ],
                closeOnEscape: true,
                closeOnClickOutside: true
            })
        }
        if (this.state.orgs) {
            try {
                this.setSelectedOrg(defaultClientOrg(this.state.orgs, update?.clientID));
            } catch (e) {
                UIUtil.errorMessage(e as Error, {
                    onClose: () => { this.onClientSelected(undefined) }
                });
            }
        }
    }

    public render(): React.ReactNode {
        const classes = this.props.classes;
        const profile = this.state.profile;
        const isSuperApp = adminProfile.isSuperApp;
        const client = this.state.selectedClientID ?
            this.state.clients?.find(client => client.clientID === this.state.selectedClientID) : undefined;
        const clientOrgs = this.state.orgs && filterClientOrgs(this.state.orgs, this.state.selectedClientID);
        const org = this.state.selectedOrgID ?
            clientOrgs?.find(org => org.id === this.state.selectedOrgID) : undefined;
        const settingsExpanded = getViewPath()?.startsWith(ADMIN_USERS_VIEW.propsToPath({}))
            || getViewPath()?.startsWith(ORGANIZATIONS_VIEW.propsToPath({})) || getViewPath()?.startsWith(INITIATIVES_VIEW.propsToPath({}))
            || getViewPath()?.startsWith(PROVIDERS_VIEW.propsToPath({}));
        return (
            <AppContext.Provider
                value={{
                    navHistory: this.navHistory,
                    waitFor: this.waitFor,
                    setWaiting: (waiting) => this.setState({ waiting }),
                    superAdmin: this.state.superAdmin,
                    profile: profile,
                    onProfileChange: this.onProfileChange,
                    logout: this.props.logout,
                    selectedClientID: this.state.selectedClientID,
                    clients: this.state.clients,
                    selectedOrgID: this.state.selectedOrgID,
                    refreshOrgs: () => this.refreshOrgs({})
                }}
            >
                <HashRouter>
                    <div className={classNames(classes.main, genStyles.flex, "app-style")} ref={this.appRef}>
                        <div className={classNames(classes.leftBar, genStyles.flex, genStyles.column, genStyles.noShrink)}>
                            <AppSwitcher
                                apps={profile.appMetadata!.apps!}
                                value={profile.appMetadata!.app!}
                                onChange={appId => { }}
                            />
                            <nav>
                                {/* {<div style={{ margin: '0 14px 20px' }}>
                                        <GlobalSearch />
                                    </div>} */}
                                {isSuperApp && Object.keys(adminProfile.appMetadata?.clientsData ?? {}).length > 1 && // If not, then it's a single client super app.
                                    <div className={classes.clientSelector}>
                                        {this.state.clients &&
                                            <ClientSelector
                                                values={this.state.clients}
                                                value={client}
                                                onChange={client => this.onClientSelected(client, { resetToRootPath: true })}
                                            />}
                                    </div>
                                }
                                {!profile.isTSPUser &&
                                    <div className={classes.clientSelector}>
                                        {clientOrgs && clientOrgs.length > 0 &&
                                            <OrgSelector
                                                values={clientOrgs}
                                                value={org}
                                                onChange={value => this.setSelectedOrg(value, { resetToRootPath: true })}
                                            />}
                                    </div>}
                                {<div style={{ margin: '0 15px 20px', display: 'flex' }}>
                                    <GlobalSearch />
                                </div>}
                                {false &&
                                    <NavLink to={appPath("/overview")}
                                        replace={true}
                                        className={classes.navLink}>
                                        Overview
                                    </NavLink>}
                                <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, height: '1px' }}>
                                    <details open={true}>
                                        <summary className={classes.navSummary}>
                                            {this.transactionsViewTitle()}
                                        </summary>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <NavLink to={appPath(TRANSACTIONS_VIEW.propsToPath({ sub: "all" }))}
                                                replace={true}
                                                className={classNames(classes.navLink, classes.navSub)}>
                                                {TRANSACTIONS_VIEW.navLabel({ viewProps: { sub: "all" } })}
                                            </NavLink>
                                            <NavLink to={appPath(TRANSACTIONS_VIEW.propsToPath({ sub: "next" }))}
                                                replace={true}
                                                className={classNames(classes.navLink, classes.navSub)}>
                                                {TRANSACTIONS_VIEW.navLabel({ viewProps: { sub: "next" } })}
                                            </NavLink>
                                            {/* {adminProfile.isSuperAdmin && adminProfile.features.chargeWarns &&
                                                <NavLink to={appPath(TRANSACTIONS_VIEW.propsToPath({ sub: "charge" }))}
                                                    replace={true}
                                                    className={classNames(classes.navLink, classes.navSub)}>
                                                    <div className={classes.navLabelWithBadge}>
                                                        {TRANSACTIONS_VIEW.navLabel({ viewProps: { sub: "charge" } })}
                                                        <ChargeBadge />
                                                    </div>
                                                </NavLink>} */}
                                            {adminProfile.features.priceChanges21702 &&
                                                <NavLink
                                                    to={appPath(TRANSACTIONS_VIEW.propsToPath({ sub: "price-change-requests" }))}
                                                    replace={true}
                                                    className={classNames(classes.navLink, classes.navSub)}>
                                                    {TRANSACTIONS_VIEW.navLabel({ viewProps: { sub: "price-change-requests" } })}
                                                </NavLink>}
                                        </div>
                                    </details>
                                    {adminProfile.features.moneyTransactions &&
                                        <NavLink
                                            to={appPath(MONEY_TRANSACTIONS_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classes.navLink}>
                                            Money Transactions
                                        </NavLink>}
                                    {adminProfile.features.tickets20717 &&
                                        <NavLink
                                            to={appPath(TICKETS_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classes.navLink}>
                                            Tickets
                                        </NavLink>}
                                    {!adminProfile.isTSPUser &&
                                        <NavLink to={appPath(PARTICIPANTS_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classes.navLink}>
                                            {i18n.t("Rider.users")}
                                        </NavLink>}
                                    {profile.features.bundles && adminProfile.itemAuth(ModelType.Bundle, ModelOperation.read) &&
                                        <NavLink to={appPath(BUNDLES_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classes.navLink}>
                                            {i18n.t("Mobility.bundles")}
                                        </NavLink>}
                                    {profile.features.rewards &&
                                        <NavLink to={appPath(REWARDS_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classes.navLink}>
                                            Rewards
                                        </NavLink>}
                                    {adminProfile.features.providerSettings22829 &&
                                        <NavLink
                                            to={appPath(PROVIDERS_VIEW.propsToPath({}))}
                                            replace={true}
                                            className={classNames(classes.navLink)}>
                                            {i18n.t("Providers")}
                                        </NavLink>}
                                    {false &&
                                        <NavLink to={appPath("/payments")}
                                            replace={true}
                                            className={classes.navLink}>
                                            Payments
                                        </NavLink>}
                                    {adminProfile.features.management && adminProfile.remote.userData.adminManagement &&
                                        <details open={settingsExpanded}>
                                            <summary className={classes.navSummary}>
                                                Settings
                                            </summary>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <NavLink
                                                    to={appPath(ADMIN_USERS_VIEW.propsToPath({}))}
                                                    replace={true}
                                                    className={classNames(classes.navLink, classes.navSub)}>
                                                    Admin Users
                                                </NavLink>
                                                <NavLink
                                                    to={appPath(ORGANIZATIONS_VIEW.propsToPath({}))}
                                                    replace={true}
                                                    className={classNames(classes.navLink, classes.navSub)}>
                                                    {i18n.t("Organizations")}
                                                </NavLink>
                                                {adminProfile.features.trackTripInitiative22828 &&
                                                    <NavLink
                                                        to={appPath(INITIATIVES_VIEW.propsToPath({}))}
                                                        replace={true}
                                                        className={classNames(classes.navLink, classes.navSub)}>
                                                        {i18n.t("Initiatives")}
                                                    </NavLink>}
                                            </div>
                                        </details>
                                    }
                                    {adminProfile.features.providerSettings22829 && adminProfile.isTSPUser &&
                                        <NavLink
                                            to={appPath(PROVIDER_VIEW.propsToPath({ id: adminProfile.remote.userData.providers[0].id }))}
                                            replace={true}
                                            className={classes.navLink}
                                        >
                                            Provider Info
                                        </NavLink>}
                                    <div className={classes.links}>
                                        {adminProfile.features.cancellationPolicy22860 &&
                                            <a target='_blank' className={classes.termsLink} onClick={() => this.navHistory.push(CANCELLATION_POLICY_VIEW, {})}>
                                                <IconInfo />
                                                Cancellation Policy
                                            </a>}
                                        <a target='_blank' className={classes.termsLink} onClick={() => this.navHistory.push(STATUS_DEFINITIONS_VIEW, {})}>
                                            <IconInfo />
                                            Trip Status Definitions
                                        </a>
                                        <a href="https://feonix.org/tos/" target='_blank' className={classes.termsLink}>
                                            <ExternalLink />
                                            Terms of Service
                                        </a>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        <NotificationContainer />
                        <div className={classes.rightPanel}>
                            <div className={classNames(classes.container, genStyles.flex)}>
                                {profile.features.rewards ?
                                    <RewardsProvider>
                                        <ViewsRenderer views={this.viewRouteConfigs} />
                                    </RewardsProvider> :
                                    <ViewsRenderer views={this.viewRouteConfigs} />}
                            </div>
                        </div>
                        <div className={classes.accountPanel}>
                            <AccountBtn adminProfile={profile} onClick={() => this.setState({ showProfile: true })} />
                        </div>
                        {this.state.showProfile && <ProfileView onRequestClose={() => this.setState({ showProfile: false })} />}
                        {this.state.waiting &&
                            <div className={classNames(classes.waiting, genStyles.flex, genStyles.center)}>
                                <IconSpin
                                    className={classNames(classes.iconLoading, genStyles.alignSelfCenter, genStyles.justifySelfCenter, "sg-animate-spin")}
                                    focusable="false" />
                            </div>}
                    </div>
                </HashRouter>
            </AppContext.Provider>
        );
    }

    public componentDidMount(): void {
        // Cleanup appsync cache on load.
        setTimeout(() => {
            // Need this timeout since at the beginning the cache object is empty.
            const cache = AppSync.getClient().cache;
            Object.keys(cache.data.data).forEach(key => {
                cache.data.delete(key);
            });
        }, 100);
        Modal.setAppElement(this.appRef.current);
        // Close / go back on esc.
        window.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.keyCode === 27 && this.navHistory.getMatchFromViewMap().size > 1) {
                this.navHistory.pop();
            }
        });
        // Enable / disable super admin mode.
        window.addEventListener("keydown", (zEvent: any) => {
            if (zEvent.shiftKey && zEvent.metaKey && zEvent.key === ".") {
                this.setState((prevState: IState) => {
                    const superAdmin = !prevState.superAdmin;
                    NotificationManager.info((superAdmin ? "Enabled" : 'Disabled'), "Super Admin mode:", 5000);
                    return ({ superAdmin: superAdmin });
                });
                zEvent.preventDefault();
            }
            if (zEvent.shiftKey && zEvent.metaKey && zEvent.key === ",") {
                this.setState({ showProfile: true });
                zEvent.preventDefault();
            }
        });
    }

}

const WithStyles = withStyles(App, appJss);
export default (props: Subtract<IProps, WithClasses<IStyle>>) => {
    const [clientThemeOverride, setClientThemeOverride] = useState<Partial<Theme>>();
    return (
        <ThemeProvider theme={{ ...defaultTheme, ...props.remoteProfile.appData.theme, ...clientThemeOverride }}>
            <WithStyles {...props} onClientThemeOverride={(themeOverride) => setClientThemeOverride(themeOverride)} />
        </ThemeProvider>
    );
};