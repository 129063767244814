import Util from "./Util";
import { adminProfile } from "../account/AdminProfile";
import { confirmAlert } from 'react-confirm-alert';
import GQLError from "./GQLError";

enum MethodType {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

type ApiCallOptions = {
    method: string, body?: any, headers?: { [key: string]: any }
}

class NetworkUtil {

    public static MethodType = MethodType;

    public static status(response: any): Promise<any> {
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(new Error(response.statusText))
        }
    }

    public static json(response: any): Promise<any> {
        if (response.status === 204) {  // No content (so, no response.json()).
            return Promise.resolve({});
        }
        return response.text().then(text => { // To handle not json responses.
            try {
                return JSON.parse(text);
            } catch (err) {
                return undefined;
            }
        });
    }

    public static jsonCallback(response: any): Promise<any> {
        return NetworkUtil.status(response)
            .then(NetworkUtil.json);
    }

    public static deserializer<T>(classRef: { new(): T }): (json: any) => Promise<T> {
        return (json: any) => {
            return Promise.resolve(Util.deserialize(json, classRef));
        }
    }

    public static loadCss(url: string, callback?: () => void) {
        // Adding the script tag to the head as suggested before
        const head: any = document.getElementsByTagName('head')[0];
        const link: any = document.createElement('link');
        link.id = 'actTPStyle';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = url;
        link.media = 'all';
        link.onreadystatechange = callback;
        link.onload = callback;
        // Fire the loading
        head.appendChild(link);
    }

    public static makeCancelable(promise: Promise<any>): any {
        let hasCanceled = false;

        const wrappedPromise = new Promise((resolve, reject) => {
            promise.then(
                val => hasCanceled ? reject({ isCanceled: true }) : resolve(val),
                error => hasCanceled ? reject({ isCanceled: true }) : reject(error)
            )
        });

        return {
            promise: wrappedPromise,
            cancel() {
                hasCanceled = true;
            },
        };
    };

    public static getServer(): string {
        return adminProfile.tripgoApiUrl;
    }

    public static getSatappUrl(endpoint: string): string {
        const server = this.getServer();
        return server + "/" + endpoint;
    }

    public static apiCallTOpts<T>(endpoint: string, resultClassRef: { new(): T }, options: ApiCallOptions): Promise<T> {
        return this.apiCall(endpoint, options)
            .then(NetworkUtil.deserializer(resultClassRef));
    }

    public static apiCallT<T>(endpoint: string, method: string, resultClassRef: { new(): T }, body?: any): Promise<T> {
        return this.apiCall(endpoint,
            {
                method: method,
                body: JSON.stringify(body)
            })
            .then(NetworkUtil.deserializer(resultClassRef));
    }

    public static apiCallTs<T>(endpoint: string, method: string, resultClassRef: { new(): T }, body?: any): Promise<T[]> {
        return this.apiCall(endpoint,
            {
                method: method,
                body: JSON.stringify(body)
            })
            .then((jsonArray: any[]) => jsonArray.map((json: any) => Util.deserialize(json, resultClassRef)));
    }

    /**
     *
     * @returns {Promise<any>}. It returns Promise<undefined> if response is not a json.
     */
    public static apiCall(endpoint: string, options: ApiCallOptions): Promise<any> {
        const url = this.getSatappUrl(endpoint);
        return this.apiCallUrl(url, options);
    }

    public static apiCallUrl(url: string, options: ApiCallOptions): Promise<any> {
        return fetch(url, {
            method: options.method,
            headers: options.headers || this.getTGApiHeaders(),
            body: options.body
        })
            .then(NetworkUtil.jsonCallback);
    }

    public static getTGApiHeaders(): { [key: string]: any } {
        return {
            'X-Account-Access-Token': adminProfile.tripgoApiAccountAccessToken,
            'X-TripGo-Key': adminProfile.tripgoApiKey,
            'Accept': 'application/json',
            'userToken': 'any',
            'Content-Type': 'application/json'
        };
    }

    public static getGQLParamsFromQuery(query: any, dontQuoteFields: string[] = []) {
        let queryParams = "";
        for (const key of Object.keys(query)) {
            if (query[key] !== undefined) {
                let value = (typeof query[key] === 'string' && !dontQuoteFields.includes(key)) ?
                    "\"" + query[key] + "\"" : Array.isArray(query[key]) ? JSON.stringify(query[key]) // Use JSON.stringify so arrays are properly formatted.
                        : query[key];
                // Need to double escape backslashes for gql`, which can be introduced by search text. Do it only for search just in case. If backslashes could
                // be introduced in other places I can do it on queryParams result (at the end).
                if (key === "search" || key === "name") {
                    value = value.replace(/\\/g, "\\\\")
                }
                queryParams += key + ":" + value + ","
            }
        }
        queryParams = queryParams.substring(0, queryParams.length - 1);
        return queryParams;
    }

    public static rejectOnGQLError(response) {
        // Maybe create a custom error type with more info, e.g. if user error or internal error, or just for usererror=true
        // preserve the message as is, while for internal error put a generic message like 'Something went wrong'.
        if (response.errors) {
            if (response.errors.length === 1) {
                const message = response.errors[0].message;
                let errorInfo;
                try {
                    errorInfo = JSON.parse(message);
                } catch (e) { }
                if (errorInfo?.error) {
                    const gqlError = new GQLError(errorInfo.error, errorInfo.errorCode, errorInfo.usererror);
                    if (errorInfo.title) {
                        gqlError.title = errorInfo.title;
                    }
                    throw gqlError;
                }
            }
            throw new GQLError(response.errors
                .reduce((msg, error) => (!msg ? "" : (msg.endsWith(".") ? " " : ". ")) + error.message, ""), "", false);
        }
        return response;
    }

    public static delayPromise<T>(duration: number): ((data: T) => Promise<T>) {
        return (data) => new Promise(resolve => setTimeout(() => resolve(data), duration));
    }
}

export default NetworkUtil;