import React from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import Tooltip from "../uiutil/Tooltip";
import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";
import FormatUtil from "../util/FormatUtil";
import { TransStatus, transStatusToDisplayString } from "../model/Transaction";
import { ReactComponent as IconWarning } from '../images/ic-hazard.svg';
import * as CSS from 'csstype';

export const statusPillJssBuilder = (statusToColor: (status: string, theme: Theme) => string, statusToIcon: (status: string) => React.ReactNode) =>
    (theme: Theme) => ({
        main: props => ({
            ...genJss.flex,
            ...genJss.alignCenter,
            '& svg': {
                ...genJss.noShrink,
                width: '24px',
                height: '24px',
                marginRight: '2px',
                ...props.noSpaceForIcon && { marginLeft: '-26px' } // 24px icon + 2px margin
            }
        }),
        status: props => ({
            ...theme.textSizeBody,
            ...props.status && theme.textWeightSemibold,
            ...genJss.flex,
            ...genJss.borderRadius(12),
            color: black(),
            background: props.status && statusToColor(props.status, theme) ? statusToColor(props.status, theme) : 'none',
            padding: props.status && (statusToColor(props.status, theme) || statusToIcon(props.status)) ? '0 8px' : 0,
            flexBasis: 'min-content'
        })
    });

const defaultStatusColor = (status: string, theme: Theme) => undefined;
const defaultStatusToDIsplayString = (status: string) => FormatUtil.upperCaseToSpaced(status);
const defaultStatusToIcon = (status: string) => null;

export const statusPillBuilder = <T extends string,>({ statusToDisplayString = defaultStatusToDIsplayString, statusToColor: statusColor = defaultStatusColor, statusToIcon = defaultStatusToIcon }: {
    statusToDisplayString?: (status: T) => string,
    statusToColor?: (status: T, theme: Theme) => string | undefined,
    statusToIcon?: (status: T) => React.ReactNode
} = {}) =>
    (props: { status: T, observation?: string, showIcon?: boolean, noSpaceForIcon?: boolean } & WithStyles<ReturnType<ReturnType<typeof statusPillJssBuilder>>>) => {
        const { status, observation, showIcon = true, classes, appClasses } = useStyles(props, statusPillJssBuilder(statusColor as any, statusToIcon as any));
        let statusElem =
            <div className={classes.status}>
                {statusToDisplayString(status) + (observation ? " *" : "")}
            </div>;
        if (showIcon) {
            statusElem =
                <div className={classes.main}>
                    {statusToIcon(status)}
                    {statusElem}
                </div>
        }
        return observation ?
            <Tooltip title={observation} className={appClasses.cursorHelp}>
                {statusElem}
            </Tooltip> : statusElem;
    };


function transStatusToIcon(status: TransStatus): React.ReactNode {
    switch (status) {
        case TransStatus.USER_CANCELED:
        case TransStatus.USER_CANCELED_LATE:
            return <IconWarning />;
    }
    return null;
}

export function transStatusToColor(status: TransStatus, theme: Theme): CSS.Color | undefined {
    switch (status) {
        case TransStatus.REJECTED: return theme.colorError;
        case TransStatus.APPROVED: return theme.colorSuccess;
        case TransStatus.REVERTED: return black(1);
        case TransStatus.PENDING: return theme.colorInfo;
        case TransStatus.PROCESSING: return "#fbf090";
        case TransStatus.URGENT_ACTION_REQUIRED:
        case TransStatus.PROVIDER_CANCELED:
        case TransStatus.PROVIDER_DECLINED:
            return "#fb9091";
        default: return undefined;
    }
}

export const TransStatusPill = statusPillBuilder({
    statusToDisplayString: transStatusToDisplayString as any,
    statusToColor: transStatusToColor as any,
    statusToIcon: transStatusToIcon as any
});