import { adminProfile } from "../account/AdminProfile";
import { orgFromId } from "../app/OrgSelectorHelpers";
import { TRANSACTION_CLIENT_ID_FIELD } from "../data/TransactionsSchema";
import { i18n } from "../i18n/TKI18nConstants";
import { transStatusToDisplayString } from "../model/Transaction";
import DateTimeUtil from "./DateTimeUtil";
import { Parser, transforms as transformsJson2Csv } from 'json2csv';
// This corresponds to a new version of the library, but couldn't get it
// working because their code throws a reference error, don't know how to avoid it yet.
// import { Parser } = from '@json2csv/plainjs';
// import { flatten } = from '@json2csv/transforms';

class FormatUtil {

    public static toMoney(n: number, options: { currency?: string, nInCents?: boolean, round?: boolean, forceDecimals?: boolean } = {}): string {
        let nInDollars = options.nInCents ? n / 100 : n;
        if (options.round) {
            nInDollars = Math.round(nInDollars);
            options.forceDecimals = false;
        }
        const toFixed2 = nInDollars.toFixed(2);
        const moneyS = (options.forceDecimals || !toFixed2.endsWith("00")) ? toFixed2 : nInDollars.toFixed();
        return (options.currency ?? "$") + moneyS;
    }

    public static toDistance(distanceInMetres: number, { briefUnit = false }: { briefUnit?: boolean } = {}): string {
        if (i18n.distanceUnit() === "imperial") {
            const distanceInMiles = (distanceInMetres * 3.28084) / 5280;
            return Math.round(distanceInMiles * 10) / 10 + (briefUnit ? " mi" : " miles");
        } else {
            const distanceInKm = distanceInMetres / 1000;
            return Math.round(distanceInKm * 10) / 10 + (briefUnit ? " km" : " kilometers");;
        }
    }

    public static fromCents(n: number): number {
        return this.truncateToDecimals(n / 100, 2);
    }

    public static toCents(n: number): number {
        return this.truncateToDecimals(n * 100, 0);
    }

    public static toPoints(n: number, unit = true, space = true): string {
        return n + (unit ? (space ? " " : "") + "pts" : "")
    }

    public static toConsumption(n: number, total: number): string {
        return this.toPoints(n, false) + " / " + this.toPoints(total, true, true);
    }

    public static truncateToDecimals(num: number, decimals: number): number {
        return Math.round(num * (Math.pow(10, decimals))) / Math.pow(10, decimals);
    }

    public static formatDiscountPercent(pointsPerCost: number, options: { decimals?: number } = {}): number {
        const { decimals = 0 } = options;
        return this.truncateToDecimals((1 - pointsPerCost) * 100, decimals);
    }

    public static formatRewardPercent(rewardPerCost): number {
        return Math.round(rewardPerCost * 100);
    }

    public static formatDiscountPoints(discountPoints): number {
        return FormatUtil.truncateToDecimals(discountPoints / 100, 2);
    }

    public static downloadObjectAsJson(exportObj: any, exportName: string) {
        const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    public static downloadObjectAsCSV(exportObj: any, options: { exportName?: string, fields?: (string | object)[] } = {}) {
        const { exportName, fields } = options;
        // Apply flatten transformation since in csvTransFunction below I need to access the value associated to a field, and that
        // field may have been specified with the dot notation, e.g. 'confirmationInput.returnTrip'.
        const transforms = [transformsJson2Csv.flatten()];
        // const transforms = [];
        const opts = { fields, transforms };
        try {
            const parser = new Parser(opts);
            const csvString = "data:text/csv;charset=utf-8," + encodeURIComponent(parser.parse(exportObj));
            const downloadAnchorNode = document.createElement('a');
            downloadAnchorNode.setAttribute("href", csvString);
            downloadAnchorNode.setAttribute("download", (exportName ?? "export") + ".csv");
            document.body.appendChild(downloadAnchorNode); // required for firefox
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * @param fieldPath path in dot notation, e.g. 'from.lat', or basic path 'from'.
     */
    public static csvTransFunction(fieldPath: string, trans?: string): (row: any) => any {
        switch (trans) {
            case "toDateTime":
                return row => {
                    const timezone = adminProfile.getTimezone(row[TRANSACTION_CLIENT_ID_FIELD]);    // This will work just for objects that have clientId (e.g. transactions).
                    const momentValue = typeof row[fieldPath] === 'number' ? DateTimeUtil.momentTZTime(row[fieldPath] * 1000, timezone) : DateTimeUtil.isoToMomentTimezone(row[fieldPath], timezone);
                    return momentValue.format(DateTimeUtil.HTML5_DATE_FORMAT + " " + DateTimeUtil.timeFormat());
                };
            case "toDate":
            case "toISODate":   // Deprecated, remove once rule is updateed to use toDate.
                return row => {
                    const timezone = adminProfile.getTimezone(row[TRANSACTION_CLIENT_ID_FIELD]);    // This will work just for objects that have clientId (e.g. transactions).
                    const momentValue = typeof row[fieldPath] === 'number' ? DateTimeUtil.momentTZTime(row[fieldPath] * 1000, timezone) : DateTimeUtil.isoToMomentTimezone(row[fieldPath], timezone);
                    return momentValue.format(DateTimeUtil.HTML5_DATE_FORMAT);
                };
            case "toTime":
            case "toISOTime":   // Deprecated, remove once rule is updateed to use toTime.
                return row => {
                    const timezone = adminProfile.getTimezone(row[TRANSACTION_CLIENT_ID_FIELD]);    // This will work just for objects that have clientId (e.g. transactions).
                    const momentValue = typeof row[fieldPath] === 'number' ? DateTimeUtil.momentTZTime(row[fieldPath] * 1000, timezone) : DateTimeUtil.isoToMomentTimezone(row[fieldPath], timezone);
                    return momentValue.format(DateTimeUtil.timeFormat());
                };
            case "centsToDollars":
                return row => row[fieldPath] !== undefined ? this.fromCents(row[fieldPath]) : undefined;
            case "toOrganizationName":
                return row => row[fieldPath] ? (adminProfile.orgs && orgFromId(row[fieldPath], adminProfile.orgs)?.name) ?? row[fieldPath] : undefined;
            case "distance":
                return row => row[fieldPath] ? this.toDistance(row[fieldPath], { briefUnit: true }) : undefined;
        }
        switch (fieldPath) {
            case "status":
                return row => transStatusToDisplayString(row[fieldPath]);
        }
        return (row) => row[fieldPath];
    }

    public static addPeriod(sentence: string): string {
        return sentence.trim().endsWith(".") ? sentence : sentence + ".";
    }

    public static toFirstUpperCase(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    public static toSentenceCase(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public static camelCaseToSpaced(text: string): string {
        return text
            // insert a space before all caps
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); })
    }

    public static kebabCaseToSpaced(text: string): string {
        return text
            // insert a space before all caps
            .replace(/(-[a-z])/g, (str) => ' ' + str.substring(1).toUpperCase())
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); })
    }

    public static upperCaseToSpaced(text: string): string {
        return text.toLowerCase()
            // insert a space before all caps
            .replace(/(_[a-z])/g, (str) => ' ' + str.substring(1).toUpperCase())
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); })
    }

    public static lowdashedCaseToSpaced(text: string): string {
        return text
            // insert a space before all caps
            .replace(/(_[a-z])/g, (str) => ' ' + str.substring(1).toUpperCase())
            // uppercase the first character
            .replace(/^./, function (str) { return str.toUpperCase(); })
    }

}

export default FormatUtil;