import { Theme } from "../css/Theme";
import { black, genJss, important, resetJss } from "../css/gen.jss";
import { appGlobalJss } from "../css/app.jss";

export const dateRangePickerJss = (theme: Theme) => ({
    button: {
        ...appGlobalJss(theme).button,
        padding: '16px 20px 0px',   // Make room above for "Today" label
        ...genJss.flex,
        ...genJss.alignCenter,
        color: 'black!important',
        // Trying to make dynamic refresh work without success. See StyleHelper
        // color: (props: BookingsViewProps) => props.filter.search ? 'red!important' : 'black!important',
        border: 'none!important',
        cursor: 'pointer',
        outline: 'none!important',
        background: 'none!important'
    },
    invalidRange: {
        color: 'red!important'
    },
    calendarBtn: {
        ...resetJss.button,
        height: '20px',
        width: '20px',
        marginLeft: '10px'
    },
    rangePickerTooltip: {
        opacity: '1',
        backgroundColor: 'white!important',
        '& .rc-tooltip-inner': {
            ...genJss.flex,
            maxHeight: '400px'
        },
        '& .rc-tooltip-inner > *': {
            ...genJss.grow
        },
        '& .rc-tooltip-arrow': {
            display: 'none'
        }
    },
    separator: {
        margin: "0 13px"
    },
    dateInput: {
        ...resetJss.button,
        fontSize: '16px',
        '&::-webkit-calendar-picker-indicator': {
            display: 'none',
            '-webkit-appearance': 'none'
        },
        outline: 'none!important',
        border: '1px solid ' + black(3),
        padding: '8px 2px 7px',
        ...genJss.borderRadius(6)
    },
    inputContainer: {
        position: `relative`
    },
    today: {
        position: 'absolute',
        left: '15px',
        top: '-18px',
        fontSize: '14px'
    },
    rangeCalendars: {
        ...important(genJss.flex)
    }
});