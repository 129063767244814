import React from "react";
import { confirmAlert } from 'react-confirm-alert';
import Color from "./Color";
import GQLError from "./GQLError";

export interface MsgOptions {
    title?: string,
    message?: string,
    onConfirm: () => void,
    onCancel?: () => void,
    confirmLabel?: string,
    cancelLabel?: string,
    closeOnEscape?: boolean,
    closeOnClickOutside?: boolean
}

class UIUtil {

    public static confirmAlert = confirmAlert

    public static confirmMsg(options: MsgOptions) {
        // noinspection PointlessBooleanExpressionJS
        confirmAlert({
            title: options.title,
            message: options.message,
            buttons: [
                {
                    label: options.confirmLabel ? options.confirmLabel : 'Confirm',
                    onClick: () => {
                        options.onConfirm();
                    }
                },
                {
                    label: options.cancelLabel ? options.cancelLabel : 'Cancel',
                    onClick: () => {
                        if (!options.onCancel) {
                            return;
                        }
                        options.onCancel();
                    }
                }
            ],
            closeOnEscape: options.closeOnEscape,
            closeOnClickOutside: options.closeOnClickOutside
        });
    }

    public static infoMessage(message: string) {
        confirmAlert({
            message: message,
            buttons: [
                {
                    label: 'Close',
                    onClick: () => { }
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true
        });
    }

    public static errorMessage(error: Error, { onClose }: { onClose?: () => void } = {}) {
        const usererror = (error as GQLError).usererror;
        const errorMessage = usererror ? error.message : "Something went wrong, please contact SkedGo support.";
        confirmAlert({
            title: (error as GQLError).title,
            message: errorMessage,
            buttons: [
                {
                    label: 'Close',
                    onClick: onClose ?? (() => { })
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true
        });
    }

    public static inputTypeSupported(type: string): boolean {
        const i = document.createElement("input");
        i.setAttribute("type", type);
        return i.type === type;
    }

    public static colorWithOpacity(colorS: string, opacity: number): string {
        return Color.createFromString(colorS).toRGBA(opacity);
    }

}

export default UIUtil;