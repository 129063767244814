import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const scheduleBookingViewJss = (theme: Theme) => ({
    subtitle: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.column,
        ...genJss.alignCenter,
        marginRight: '290px',
        '&>*:first-child': {
            margin: '10px 0 20px'
        }
    }
});