import { jss } from "react-jss";

const keyframesStyle = {

    '@keyframes spin': {
        from: {
            transform: 'rotate(0deg)'
        },
        to: {
            transform: 'rotate(360deg)'
        }
    },

    '@keyframes rightSlideIn': {
        from: {
            transform: 'translate3d(100%, 0, 0)'
        },
        to: {
            transform: 'translate3d(0, 0, 0)'
        }
    },
    '@keyframes rightSlideOut': {
        from: {
            transform: 'translate3d(0, 0, 0)'
        },
        to: {
            transform: 'translate3d(100%, 0, 0)'
        }
    },
};

export const keyFramesStyles = jss.createStyleSheet(keyframesStyle as any).attach();

export const genJss = {

    flex: {
        ...{ display: '-webkit-flex' },
        display: 'flex'
    },

    row: {
        flexDirection: 'row',
        WebkitFlexDirection: 'row'
    },

    column: {
        flexDirection: 'column',
        WebkitFlexDirection: 'column'
    },

    center: {
        justifyContent: 'center',
        WebkitJustifyContent: 'center'
    },

    spaceBetween: {
        justifyContent: 'space-between',
        WebkitJustifyContent: 'space-between'
    },

    spaceAround: {
        justifyContent: 'space-around',
        WebkitJustifyContent: 'space-around'
    },

    justifyStart: {
        justifyContent: 'flex-start',
        WebkitJustifyContent: 'flex-start'
    },

    justifyEnd: {
        justifyContent: 'flex-end',
        WebkitJustifyContent: 'flex-end'
    },

    selfCenter: {
        justifySelf: 'center',
        WebkitJustifySelf: 'center'
    },

    alignCenter: {
        alignItems: 'center',
        WebkitAlignItems: 'center'
    },

    alignStart: {
        alignItems: 'flex-start',
        WebkitAlignItems: 'flex-start'
    },

    alignEnd: {
        alignItems: 'flex-end',
        WebkitAlignItems: 'flex-end'
    },

    alignStretch: {
        alignItems: 'stretch',
        WebkitAlignItems: 'stretch'
    },

    alignSelfCenter: {
        alignSelf: 'center',
        WebkitAlignSelf: 'center'
    },

    alignSelfStart: {
        alignSelf: 'flex-start',
        WebkitAlignSelf: 'flex-start'
    },

    alignSelfEnd: {
        alignSelf: 'flex-end',
        WebkitAlignSelf: 'flex-end'
    },

    alignSelfStretch: {
        alignSelf: 'stretch',
        WebkitAlignSelf: 'stretch'
    },

    grow: {
        flexGrow: 1,
        WebkitFlexGrow: 1
    },

    noShrink: {
        flexShrink: 0,
        WebkitFlexShrink: 0
    },

    wrap: {
        flexWrap: 'wrap',
        WebkitFlexWrap: 'wrap'
    },

    relative: {
        position: 'relative'
    },

    hidden: {
        visibility: 'hidden'
    },

    displayNone: {
        display: 'none'
    },

    svgFillCurrColor: {
        '& path': {
            fill: 'currentColor'
        },
        '& rect': {
            fill: 'currentColor'
        },
        '& circle': {
            fill: 'currentColor'
        },
        '& polygon': {
            fill: 'currentColor'
        }
    },

    svgPathFillCurrColor: {
        '& path': {
            fill: 'currentColor'
        }
    },

    scrollableY: {
        overflowY: 'auto'
    },

    rotate180: {
        WebkitTransform: 'rotate(180deg)',
        MozTransform: 'rotate(180deg)',
        OTransform: 'rotate(180deg)',
        transform: 'rotate(180deg)'
    },

    overflowEllipsis: {
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block'
    },

    fontXL: {
        fontSize: '25px',
        lineHeight: '36px'
    },

    fontL: {
        fontSize: '20px',
        lineHeight: '28px'
    },

    fontM: {
        fontSize: '16px',
        lineHeight: '24px'
    },

    fontMImp: {
        fontSize: '16px!important',
        lineHeight: '24px!important'
    },

    fontS: {
        fontSize: '14px',
        lineHeight: '20px'
    },

    fontSM: {
        fontSize: '12px',
        lineHeight: '16px'
    },

    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
        opacity: '.8',
        '&:hover': {
            opacity: '1'
        }
    },

    borderRadius: (radius: number, unit: string = 'px') => ({
        borderRadius: radius + unit,
        WebkitBorderRadius: radius + unit,
        MozBorderRadius: radius + unit
    }),

    animateSpin: {
        animation: keyFramesStyles.keyframes.spin + ' 1.5s linear infinite'
    }

};

export const resetJss = {
    button: {
        background: 'initial',
        fontSize: '14px',
        color: 'unset',
        textTransform: 'initial',
        textAlign: 'center',
        lineHeight: 'initial',
        fontFamily: 'unset',
        letterSpacing: 'initial',
        textDecoration: 'initial',
        WebkitAppearance: 'initial',
        MozAppearance: 'initial',
        appearance: 'initial',
        cursor: 'pointer',
        border: 'none',
        padding: 'initial',
        // ...DeviceUtil.isIE && {
        //     background: 'none',  // 'initial' value is not supported by IE
        //     padding: '0'
        // }
    },
    select: {
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        MsAppearance: 'none',
        OAppearance: 'none',
        background: 'none!important',
        border: 'none!important',
        WebkitBoxShadow: 'none!important',
        MozBoxShadow: 'none!important',
        boxShadow: 'none!important',
        borderRadius: 'initial',
        WebkitBorderRadius: 'initial',
        MozBorderRadius: 'initial',
        margin: 'initial'
    },
    input: {
        border: 'none',
        background: 'initial',
        appearance: 'none',
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        MsAppearance: 'none',
        OAppearance: 'none',
        WebkitBoxShadow: 'none!important',
        MozBoxShadow: 'none!important',
        boxShadow: 'none!important',
        margin: 'initial',
        height: 'initial',
        lineHeight: 'normal',
        ...genJss.borderRadius(0, ''),
        // ...DeviceUtil.isIE && {
        //     background: 'none'  // 'initial' value is not supported by IE
        // }
    }
};

export const colors = {
    black: '#212A33',
    black1: '#212a33a6', // Prev value ('#20293199') don't pass min-contrast requirement on white background,
    black2: '#212a334d',
    black3: '#212a332e',
    black4: '#212a331f',
    black5: '#20293114',
    white: '#ffffff',
    white1: '#fff9',
    white2: '#ffffff4d',
    white3: '#ffffff2e',
    white4: '#ffffff1f',
    white5: '#ffffff14'
};

export function black(n: 0 | 1 | 2 | 3 | 4 | 5 = 0, dual: boolean = false): string {
    return dual ? white(n) : colors[Object.keys(colors)[n]];
}

export function white(n: 0 | 1 | 2 | 3 | 4 | 5 = 0, dual: boolean = false): string {
    return dual ? black(n) : colors[Object.keys(colors)[n + 6]];
}

export function important(style: any): any {
    const styleImportant = { ...style };
    for (const key of Object.keys(style)) {
        if (styleImportant[key].includes('!important')) {
            continue;
        }
        styleImportant[key] = styleImportant[key] + '!important';
    }
    return styleImportant;
}