import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert, Any } from "json2typescript";
import { Range } from "moment-timezone";
import * as moment from 'moment-timezone';
import DateTimeUtil from "../util/DateTimeUtil";
import { TransStatus, TransStatusConverter, TransType, TransTypeConverter } from "../model/Transaction";
import * as Transaction from "../model/Transaction";
import { Item } from "../search/SearchBox";
@JsonConverter
class RangeConverter implements JsonCustomConvert<Range> {
    public serialize(value: Range): any {
        return { "start": value.start.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT), "end": value.end.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT) };
    }
    public deserialize(json: any): Range {
        // Deserialize to default timezone.
        // TODO check how to un-hardcode.
        return moment.range(DateTimeUtil.momentDefaultTZ(json.start), DateTimeUtil.momentDefaultTZ(json.end));
    }
}

export enum SearchField {
    ID = "id",
    USER_ID = "user id",
    BUNDLE = "wallet",
    UPCOMING_BUNDLE = "upcoming wallet",
    MODE = "mode",
    NAME = "name",
    USER = "user",
}

export enum SortOrder {
    ASC = "asc",
    DESC = "desc"
}

@JsonConverter
export class SortOrderConverter implements JsonCustomConvert<SortOrder> {
    public serialize(sortOrder: SortOrder): any {
        return sortOrder;
    }
    public deserialize(sortOrderS: any): SortOrder {
        return sortOrderS;
    }
}

interface ISearchQuery {
    field?: SearchField;
    text: string;
}

export interface UserFilterValue {
    id?: string;
    name?: string;
}

export interface BundleFilterValue {
    id?: string;
    name?: string;
}

type PriceChangeFilter = Transaction.PriceChangeStatus | "ANY";

@JsonObject
class Filter {
    @JsonProperty("search", Any, true)
    public search: Item | undefined = undefined;
    @JsonProperty("sortOrder", SortOrderConverter, true)
    public sortOrder: SortOrder | undefined = undefined;
    @JsonProperty("page")
    public page: number = -1;
    @JsonProperty("pageSize")
    public readonly pageSize: number = -1;
    @JsonProperty("range", RangeConverter, true)
    public readonly range: Range | undefined = undefined;
    @JsonProperty("startFromNow", Number, true)
    public readonly startFromNow: number | undefined;
    @JsonProperty("endFromNow", Number, true)
    public readonly endFromNow: number | undefined;
    @JsonProperty("id", String, true)
    public readonly id: string | undefined = undefined;
    @JsonProperty("userId", String, true)   // maybe define BookingsFilter extending Filter with this field.
    public userId: string | undefined = undefined;
    @JsonProperty("user", Any, true)
    public user: UserFilterValue | undefined = undefined;
    @JsonProperty("userDisabled", Boolean, true)    // Remove this in favor of the more general disabled field.
    public userDisabled?: boolean = false;
    @JsonProperty("type", TransTypeConverter, true)   // maybe define BookingsFilter extending Filter with this field.
    public type: TransType | undefined = undefined;
    @JsonProperty("status", TransStatusConverter, true)   // maybe define BookingsFilter extending Filter with this field.
    public status: TransStatus | undefined = undefined;
    @JsonProperty("mode", String, true)   // maybe define BookingsFilter extending Filter with this field.
    public mode?: string = undefined;
    @JsonProperty("paymentStatus", String, true)
    public paymentStatus?: Transaction.PaymentStatus = undefined;
    @JsonProperty("paymentMethod", String, true)
    public paymentMethod?: Transaction.PaymentMethod = undefined;
    @JsonProperty("bundleId", String, true)
    public readonly bundleId: string | undefined = undefined;
    @JsonProperty("bundle", Any, true)
    public readonly bundle: BundleFilterValue | undefined = undefined;
    @JsonProperty("groupBy", String, true)
    public readonly groupBy: string | undefined = undefined;
    @JsonProperty("charge", Boolean, true)
    public readonly charge: boolean | undefined = undefined;
    @JsonProperty("priceChange", String, true)
    public readonly priceChange?: PriceChangeFilter = undefined;
    @JsonProperty("balanceID", String, true)
    public readonly balanceID: string | undefined = undefined;
    @JsonProperty("disabled", Boolean, true)
    public disabled?: boolean = false;
    @JsonProperty("initiativeId", String, true)
    public initiativeId: string | undefined = undefined;

    // Do not serialize / deserialize these values since I don't want them to be explicitly in the url,
    // since the url already includes these values, and they may become inconsistent.
    public clientId: string | undefined = undefined;
    public orgId: string | undefined = undefined;
}

export default Filter;