import { Theme } from "../css/Theme";
import { genJss, resetJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const adminViewJss = (theme: Theme) => ({
    fieldsGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridRowGap: '50px',
        gridColumnGap: '20px',
        marginBottom: '50px'
    },
    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },
    value: {
        ...theme.textColorGray
    },
    orgsValue: {
        ...genJss.flex,
        ...genJss.alignStart,
        '& button': {
            marginLeft: '60px',
            marginTop: '-10px'
        }
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: 'auto'
    },
    iconEdit: {
        height: '16px',
        width: '16px',
        marginRight: '1ch',
        cursor: 'pointer',
        '& path': {
            fill: theme.colorPrimary
        }
    },
    buttonEditOrgs: {
        ...resetJss.button,
        marginLeft: '20px',
        '& svg': {
            height: '16px',
            width: '16px',
        },
        '& svg path': {
            fill: theme.colorPrimary
        }
    },
    buttonsRight: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& *': {
            marginLeft: '15px'
        }
    },
    orgSelectValue: {
        ...genJss.grow,
        '& > *': {
            ...genJss.grow,
            margin: '-3px'
        }
    },
    tableContainer: {
        '& table th': {
            border: 'none',
            padding: '0!important'
        },
        '& table tr:last-child': {
            border: 'none'
        }
    }
});