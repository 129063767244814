import { withStyles } from "../css/StyleHelper";
import Filter from "../data/Filter";
import FilterPopup from "./FilterPopup";
import { filterPopupJss } from "./FilterPopup.jss";

export interface FilterConfig {
    label?: string;
    renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) => React.ReactNode;
    valueSummary?: (filter: Filter) => string | undefined;
    showLabelInSummary?: boolean;
    onClear?: (filter: Filter, onFilterChange: (filter: Filter) => void) => void;
    className?: string;
}

export default withStyles(FilterPopup, filterPopupJss);