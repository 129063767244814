import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Util from "../util/Util";
import { genJss, resetJss } from "../css/gen.jss";
import { ValidatorForm } from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import DateTimeUtil from "../util/DateTimeUtil";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import DateTimePicker from "../time/DateTimePicker";
import View from "../view/View";
import { TKUILocationBox, TKLocation, LatLng, overrideClass, black, RegionsData, Region, TKRegionsData, CardPresentation, TripGoApi, TKError, TKUtil } from "tripkit-react";
import { adminProfile } from "../account/AdminProfile";
import { AppContext, appPathUpdate, filterClientOrgs, getOrgIDPath } from "../app/App";
import TKUIMapView, { TKUIMapViewClass, TKUIMapViewHelpers } from "tripkit-react/dist/map/TKUIMapView";
import { DefaultSelect } from "../rewards/StatusSelect";
import RoutingQuery, { TimePreference } from "tripkit-react/dist/model/RoutingQuery";
import User from "../model/User";
import TKUIRoutingResultsView, { TKUIRoutingResultsViewHelpers } from "tripkit-react/dist/trip/TKUIRoutingResultsView";
import TKUITicketSelect from "tripkit-react/dist/stripekit/TKUITicketSelect";
import { useTKState } from "tripkit-react/dist/config/TKStateProvider";
import TKUITripOverviewView from "tripkit-react/dist/trip/TKUITripOverviewView";
import TKUIFromTo from "tripkit-react/dist/booking/TKUIFromTo";
import BookingInfo, { AvailableProviderOption, BookingField, BookingFieldOption, ProviderOptionsForm } from "tripkit-react/dist/model/trip/BookingInfo";
import { default as TKDateTimeUtil } from "tripkit-react/dist/util/DateTimeUtil";
import BookingReview from "tripkit-react/dist/model/trip/BookingReview";
import PaymentOption from "tripkit-react/dist/model/trip/PaymentOption";
import TKUIBookingReview from "tripkit-react/dist/stripekit/TKUIBookingReview";
import TKUICheckoutView from "tripkit-react/dist/stripekit/TKUICheckoutView";
import TKLoading from 'tripkit-react/dist/card/TKLoading';
import TKUIUtil from 'tripkit-react/dist/util/UIUtil';
import { TripSort } from "tripkit-react/dist/model/trip/TripSort";
import { ReactComponent as IconUserDetails } from "../images/ic-info.svg";
import UsersData from "../data/UsersData";
import AutocompleteBox, { AutocompleteBoxType } from "../autocomplete/AutocompleteBox";
import AutocompleteResult from "../autocomplete/AutocompleteResult";
import { descendentOrg, orgFromId } from "../app/OrgSelectorHelpers";
import UIUtil from "../util/UIUtil";
import { getOrgSelectOptions } from "../user/EditUserView";
import { TKRenderOverride, TKStyleOverride } from "tripkit-react/dist/config/TKConfigHelper";
import { TKTripCostType } from "tripkit-react/dist/trip/TKUITripRow";
import { i18n } from "../i18n/TKI18nConstants";
import MapUtil from "tripkit-react/dist/util/MapUtil";
import { ConfirmationInput } from "../model/Transaction";
import { ReactComponent as IconExternalLink } from "../images/ic-external-link.svg";
import { useBeforeInitialRender } from "../util/usehooks";
import TKUIEditFavouriteView from "tripkit-react/dist/favourite/TKUIEditFavouriteView";
import FavouriteLocation from "tripkit-react/dist/model/favourite/FavouriteLocation";
import { ReactComponent as AlertIcon } from "tripkit-react/dist/images/ic-alert.svg.js";
import { TKFavouritesContext } from "tripkit-react/dist/favourite/TKFavouritesProvider";
import { SignInStatus, TKAccountContext } from "tripkit-react/dist/account/TKAccountContext";
import Tooltip from "../uiutil/Tooltip";
import Features from "tripkit-react/dist/env/Features";
import { BookingPaymentForm } from "tripkit-react/dist/model/payment/BookingPaymentForm";
import { getProvidersP } from "../data/ProvidersData";
import FoldableNote from "./FoldableNote";
import TKUIBookingProviderOptions from "tripkit-react/dist/booking/TKUIBookingProviderOptions";
import TKUIProviderTicketsForm from "tripkit-react/dist/stripekit/TKUIProviderTicketsForm";
import TicketOption from "tripkit-react/dist/model/trip/TicketOption";
import Trip from "tripkit-react/dist/model/trip/Trip";
import { FAVORITES_GEOCODER_ID, NewCreateBookingViewProps, RECENT_GEOCODER_ID } from "./NewCreateBookingViewHelpers";
import { useStyles } from "../css/StyleHelper";
import { newCreateBookingViewJss } from "./NewCreateBookingView.jss";
import Favourite from "tripkit-react/dist/model/favourite/Favourite";

const timePrefOptions = [{ value: TimePreference.LEAVE, label: "pick up" }, { value: TimePreference.ARRIVE, label: "drop off" }]

const ONE_WAY_ONLY = ConfirmationInput.ONE_WAY_ONLY;

const UserAutocompleteBox = AutocompleteBox as AutocompleteBoxType<User>;

const RoutingQueryForm = ({
    query, onQueryChange, setPreFrom, setPreTo, user, setUser,
    defaultRegion, defaultCityLatLng,
    onRequestClose, onSubmit, onOpenUserDetails,
    classes, appClasses
}: {
    query: RoutingQuery,
    onQueryChange: (value: RoutingQuery) => void,
    setPreFrom: (value?: TKLocation) => void,
    setPreTo: (value?: TKLocation) => void,
    user?: User,
    setUser?: (value?: User) => void,
    defaultRegion?: Region,
    defaultCityLatLng?: LatLng,
    onRequestClose: () => void,
    onSubmit: () => void,
    onOpenUserDetails?: (user: User) => void,
    classes: any,
    appClasses: any
}) => {

    function updateQuery(update: Partial<RoutingQuery>) {
        onQueryChange(Util.iAssign(query, update));
    }
    const formRef = useRef<any>(null);
    const fromInputRef = useRef<any>();
    const toInputRef = useRef<any>();
    const [fromInputTooltip, setFromInputTooltip] = useState<string | undefined>();
    const [toInputTooltip, setToInputTooltip] = useState<string | undefined>();
    const { selectedClientID: selectedClientIDApp, setWaiting } = useContext(AppContext);
    const selectedClientID = user?.clientId ?? selectedClientIDApp;
    const { status } = useContext(TKAccountContext);
    const { onAddFavourite: onAddFavouriteOrig, isLoadingFavourites, favouriteList } = useContext(TKFavouritesContext);
    const onAddFavourite = (favourite: Favourite) => {
        onAddFavouriteOrig(favourite);
        UsersData.instance.invalidateFetchCache("/favorite");
    };
    const timezone = selectedClientID ? adminProfile.getTimezone(selectedClientID) :
        query.from ? RegionsData.instance.getCloserRegion(query.from)?.timezone : undefined;
    const [addingFav, setAddingFav] = React.useState<any>(undefined);
    useEffect(() => {
        if (!isLoadingFavourites && !fromInputRef.current?.state?.inputText) {
            fromInputRef.current?.refreshDisplayingResults();
            toInputRef.current?.refreshDisplayingResults();
        }
        // This is to contemplate the case that favorites arrive after from/to are set,
        // and casually are favorites. The matching between favorite and from/to location is done using the address,
        // since coordinates may have changed by our routing when building the booking.
        // This may happen specially in 'create similar booking' flow, where the query is already set from the beggining,
        // and the reference booking doesn't record if the from/to are favorites.
        if (!isLoadingFavourites) {
            let fromFavLocation: TKLocation | undefined;
            if (query.from && query.from.source !== FAVORITES_GEOCODER_ID) {
                const fromAsFavorite = favouriteList.find(fav => (fav instanceof FavouriteLocation) && fav.location.address === query.from?.address);
                if (fromAsFavorite) {
                    fromFavLocation = TKUtil.deepClone((fromAsFavorite as FavouriteLocation).location);
                    if (fromAsFavorite.name) {
                        fromFavLocation.name = fromAsFavorite.name;
                    }
                    fromFavLocation.suggestion = fromAsFavorite;
                    fromFavLocation.source = FAVORITES_GEOCODER_ID;
                }
            }
            let toFavLocation: TKLocation | undefined;
            if (query.to && query.to.source !== FAVORITES_GEOCODER_ID) {
                const toAsFavorite = favouriteList.find(fav => (fav instanceof FavouriteLocation) && fav.location.address === query.to?.address);
                if (toAsFavorite) {
                    toFavLocation = TKUtil.deepClone((toAsFavorite as FavouriteLocation).location);
                    if (toAsFavorite.name) {
                        toFavLocation.name = toAsFavorite.name;
                    }
                    toFavLocation.suggestion = toAsFavorite;
                    toFavLocation.source = FAVORITES_GEOCODER_ID;
                }
            }
            if (fromFavLocation || toFavLocation) {
                // Need to update from and to at once to avoid race condition between values. TODO: improve this by making the updateQuery to receive the current query.
                updateQuery({
                    ...fromFavLocation && { from: fromFavLocation },
                    ...toFavLocation && { to: toFavLocation }
                });
            }
        }
    }, [isLoadingFavourites]);
    const autocompleteResultOverridePropsBuilder = (isFrom: boolean) => ({
        componentKey: "TKUIAutocompleteResult",
        renderOverride: (props, _configRender, defaultRender) => {
            const defaultRendered = defaultRender(props);
            const inputRef = isFrom ? fromInputRef : toInputRef;
            const isFirstFavorite = inputRef.current?.state.items.find(item => item.query === '' && item.location.source === FAVORITES_GEOCODER_ID)?.location === props.location;
            const isFirstRecent = inputRef.current?.state.items.find(item => item.query === '' && item.location.source === RECENT_GEOCODER_ID)?.location === props.location;
            if (isFirstFavorite || isFirstRecent) {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ fontWeight: 'bold', fontSize: '13px', marginLeft: '24px', lineHeight: '18px', marginTop: '10px' }}>
                            {isFirstFavorite ? "Favorites" : "Recent"}
                        </div>
                        {defaultRendered}
                    </div>
                );
            } else {
                return defaultRendered;
            }
        }
    });
    return (
        <ValidatorForm
            onSubmit={() => { }}
            instantValidate={false}
            ref={formRef}
            className={genStyles.grow}
        >
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.spaceBetween, genStyles.height100)}>
                <div className={appClasses.form}>
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, appClasses.formGroup)}>
                        <div className={genStyles.grow}>
                            <div className={classNames(appClasses.formGroup, classes.formGroup)}>
                                <label>User</label>
                                <Validator
                                    value={user}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        <div className={classNames(appClasses.value, genStyles.grow)}>
                                            <UserAutocompleteBox
                                                className={appClasses.input}
                                                value={user}
                                                onChange={(value?: User) => {
                                                    const resetFrom = query.from?.source === FAVORITES_GEOCODER_ID || query.from?.source === RECENT_GEOCODER_ID;
                                                    const resetTo = query.to?.source === FAVORITES_GEOCODER_ID || query.to?.source === RECENT_GEOCODER_ID;
                                                    if (resetFrom || resetTo) {
                                                        updateQuery({
                                                            ...resetFrom ? { from: null } : {},
                                                            ...resetTo ? { to: null } : {}
                                                        });
                                                        const clearAddressTextFc = (recent?: boolean) => `User ${recent ? "Recent" : "Favorite"} address has been cleared`;
                                                        if (resetFrom) {
                                                            setFromInputTooltip(clearAddressTextFc(query.from?.source === RECENT_GEOCODER_ID));
                                                            setTimeout(() => setFromInputTooltip(undefined), 3000);
                                                        }
                                                        if (resetTo) {
                                                            setToInputTooltip(clearAddressTextFc(query.to?.source === RECENT_GEOCODER_ID));
                                                            setTimeout(() => setToInputTooltip(undefined), 3000);
                                                        }
                                                    }
                                                    setUser?.(value);
                                                }}
                                                toText={(user: User) => user.name ?? ""}
                                                resultsFor={query => {
                                                    if (query === "") {
                                                        return Promise.resolve([]);
                                                    }
                                                    return UsersData.instance.search(query, { limit: 10, clientId: selectedClientID, orgId: getOrgIDPath() });
                                                }}
                                                renderResult={(user: User, isHighlighted) => {
                                                    const infos: string[] = [];
                                                    if (user.email) {
                                                        infos.push(user.email);
                                                    }
                                                    if (user.phoneNumber) {
                                                        infos.push(user.phoneNumber);
                                                    }
                                                    return (
                                                        <AutocompleteResult
                                                            title={user.name ?? ""}
                                                            subtitle={infos.join(" ⋅ ")}
                                                            isHighlighted={isHighlighted}
                                                            key={user.id}
                                                        />
                                                    );
                                                }}
                                                placeholder="User"
                                                disabled={!setUser}
                                                name="user"
                                            />
                                            {errorMessage &&
                                                <div className={appClasses.validationError}>{errorMessage}</div>}
                                            {adminProfile.features.favorites22590 && user && isLoadingFavourites &&
                                                <div className={appClasses.validationError} style={{ color: 'initial', bottom: '-26px' }}>
                                                    <div className={classes.loadingText}>Loading favorites</div>
                                                </div>}
                                        </div>}
                                </Validator>
                                {onOpenUserDetails && user &&
                                    <button className={classes.iconUserDetails} onClick={e => { e.preventDefault(); onOpenUserDetails(user); }}>
                                        <IconUserDetails />
                                    </button>}
                            </div>
                            <div className={classNames(appClasses.formGroup, classes.formGroup)}>
                                <label htmlFor="depart">From</label>
                                <Validator
                                    value={query.from}
                                    name="user"
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        <div className={classNames(appClasses.value, genStyles.grow)}>
                                            <Tooltip title={fromInputTooltip ?? ""} open={!!fromInputTooltip} placement="right">
                                                <div className={appClasses.input}>
                                                    <TKRenderOverride {...autocompleteResultOverridePropsBuilder(true)}>
                                                        <TKUILocationBox
                                                            value={query.from}
                                                            onChange={(value: TKLocation | null) => {
                                                                updateQuery({ from: value });
                                                                setPreFrom(undefined);
                                                            }}
                                                            onResultHighlight={(value: TKLocation | null) => {
                                                                setPreFrom(value ?? undefined);
                                                            }}
                                                            showCurrLoc={false}
                                                            bounds={defaultRegion?.bounds}
                                                            focus={defaultCityLatLng}
                                                            placeholder={"From"}
                                                            styles={{
                                                                input: overrideClass({
                                                                    color: black()
                                                                }),
                                                                menu: overrideClass({
                                                                    top: '41px',
                                                                    left: '-10px',
                                                                    width: 'calc(100% + 20px)'
                                                                })
                                                            }}
                                                            onRef={(ref) => { fromInputRef.current = ref }}
                                                            name="from"
                                                        />
                                                    </TKRenderOverride>
                                                </div>
                                            </Tooltip>
                                            <div style={{ position: 'absolute', bottom: '-29px', right: '0' }}>
                                                {adminProfile.features.favorites22590 && user && status === SignInStatus.signedIn && !isLoadingFavourites && query.from && query.from.source !== FAVORITES_GEOCODER_ID &&
                                                    <button
                                                        className={appClasses.buttonCancel}
                                                        style={{ padding: '2px 8px' }}
                                                        onClick={() => setAddingFav(FavouriteLocation.create(query.from!))}
                                                    >
                                                        Save As Favorite
                                                    </button>}
                                            </div>
                                            {errorMessage &&
                                                <div className={appClasses.validationError}>{errorMessage}</div>}
                                        </div>}
                                </Validator>
                            </div>
                            <div className={classNames(appClasses.formGroup, classes.formGroup)}>
                                <label htmlFor="depart">To</label>
                                <Validator
                                    value={query.to}
                                    name="user"
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        <div className={classNames(appClasses.value, genStyles.grow)}>
                                            <Tooltip title={toInputTooltip ?? ""} open={!!toInputTooltip} placement="right">
                                                <div className={appClasses.input}>
                                                    <TKRenderOverride {...autocompleteResultOverridePropsBuilder(false)}>
                                                        <TKUILocationBox
                                                            value={query.to}
                                                            onChange={(value: TKLocation | null) => {
                                                                updateQuery({ to: value });
                                                                setPreTo(undefined);
                                                            }}
                                                            onResultHighlight={(value: TKLocation | null) => {
                                                                setPreTo(value ?? undefined);
                                                            }}
                                                            showCurrLoc={false}
                                                            bounds={defaultRegion?.bounds}
                                                            focus={defaultCityLatLng}
                                                            placeholder={"To"}
                                                            styles={{
                                                                input: overrideClass({
                                                                    color: black()
                                                                }),
                                                                menu: overrideClass({
                                                                    top: '41px',
                                                                    left: '-10px',
                                                                    width: 'calc(100% + 20px)'
                                                                })
                                                            }}
                                                            onRef={(ref) => { toInputRef.current = ref }}
                                                            name="to"
                                                        />
                                                    </TKRenderOverride>
                                                </div>
                                            </Tooltip>
                                            <div style={{ position: 'absolute', bottom: '-29px', right: '0' }}>
                                                {adminProfile.features.favorites22590 && user && status === SignInStatus.signedIn && !isLoadingFavourites && query.to && query.to.source !== FAVORITES_GEOCODER_ID &&
                                                    <button
                                                        className={appClasses.buttonCancel}
                                                        style={{ padding: '2px 8px' }}
                                                        onClick={() => setAddingFav(FavouriteLocation.create(query.to!))}
                                                    >
                                                        Save As Favorite
                                                    </button>}
                                            </div>
                                            {errorMessage &&
                                                <div className={appClasses.validationError}>{errorMessage}</div>}
                                        </div>}
                                </Validator>
                            </div>
                            <div className={classNames(appClasses.formGroup, classes.formGroup)}>
                                <label htmlFor="depart">Time</label>
                                <Validator
                                    // When no selected client, which can happen when creating a copy of a booking, need to explicitly send the timezone (based on the query.from) since if not it will take the dafault admin timezone                                    
                                    value={DateTimeUtil.html5FromMillis(query.time.valueOf(), timezone)}
                                    // validators={["isInTheFuture"]}
                                    validators={[]}
                                    errorMessages={["should be a date in the future"]}
                                    resetOnValid
                                >
                                    {(params: { errorMessage: any, value: any, inputRefCallback: any }) =>
                                        <div className={classNames(appClasses.value, genStyles.grow)}>
                                            <div className={classes.timePrefSelect}>
                                                <DefaultSelect
                                                    options={timePrefOptions}
                                                    value={query.timePref}
                                                    onChange={timePref => {
                                                        updateQuery({ timePref });
                                                    }}
                                                />
                                            </div>
                                            <DateTimePicker
                                                value={params.value}
                                                onChange={(value) => {
                                                    const newDepart = DateTimeUtil.html5ToMillis(value, timezone);
                                                    updateQuery({ time: DateTimeUtil.momentTZTime(newDepart) })
                                                }}
                                            />
                                            {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                        </div>}
                                </Validator>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <button
                        className={appClasses.buttonCancel}
                        onClick={e => {
                            e.preventDefault();
                            onRequestClose?.();
                        }}>
                        Cancel
                    </button>
                    <button className={appClasses.buttonAdd}
                        onClick={() => formRef.current.isFormValid(false).then(valid => valid && onSubmit?.())}
                        disabled={false}
                        name="calculate-trips-btn"
                    >
                        {"Continue"}
                    </button>
                </div>
                {addingFav &&
                    <TKUIEditFavouriteView
                        title={i18n.t("Add.favorite")}
                        value={addingFav}
                        onRequestClose={async (update) => {
                            if (update) {
                                try {
                                    setWaiting(true);
                                    await (onAddFavourite(update));
                                    // Update the query with the new favourite location, so that the from/to form displays the location as a favorite
                                    // (e.g. hides the "Save as Favorite" button)
                                    (update as FavouriteLocation).location.source = FAVORITES_GEOCODER_ID;
                                    if (addingFav.location === query.from) {
                                        const fromFavLocation = Util.iAssign((update as FavouriteLocation).location, { name: update.name });
                                        updateQuery({ from: fromFavLocation });
                                    } else if (addingFav.location === query.to) {
                                        const toFavLocation = Util.iAssign((update as FavouriteLocation).location, { name: update.name });
                                        updateQuery({ to: toFavLocation });
                                    }
                                } catch (e) {
                                    UIUtil.errorMessage(new TKError("Failed to create favourite."));
                                } finally {
                                    setWaiting(false);
                                }
                            }
                            setAddingFav(undefined)
                        }}
                        cardPresentation={CardPresentation.MODAL}
                        slideUpOptions={{ draggable: false }}
                    />}
            </div>
        </ValidatorForm>
    )
}

const ComputingTrips = ({ onContinue }) => {
    const { waiting, trips } = useTKState();
    // Display all trips at once (notice we should pass trips to TKUIRoutingResultsView, too).
    // Then put a parameter in tripkit to request all trips with just one query.    
    const tripsComputed = !waiting && trips;
    useEffect(() => {
        if (tripsComputed) {
            onContinue(trips);
        }
    }, [tripsComputed]);
    return null;
}

const RoutingResultsView = ({ onSubmit, onRequestClose, classes, appClasses, tripsOptions }) => {
    const { selectedTrip, onSelectedTripChange, waiting, trips: stateTrips } = useTKState();
    // Display all trips at once (notice we should pass trips to TKUIRoutingResultsView, too).
    // Then put a parameter in tripkit to request all trips with just one query.
    const trips = waiting ? [] : stateTrips;
    const [tripDetailsView, setTripDetailsView] = useState<boolean>(false);
    const justOneResult = trips?.length === 1;
    return (
        <div className={classNames(genStyles.flex, genStyles.column, genStyles.spaceBetween, genStyles.height100)}>
            {((tripDetailsView || justOneResult) && selectedTrip) ?
                <div className={classNames(genStyles.flex, genStyles.grow)} style={{ height: '1px', padding: '24px', backgroundColor: '#2029310a', ...genJss.borderRadius(4) }}>
                    <TKUITripOverviewView
                        value={selectedTrip}
                        cardProps={{
                            // presentation: CardPresentation.CONTENT
                            presentation: CardPresentation.NONE,
                            // renderHeader: () => <></>,
                            // renderSubHeader: () => <></>,
                            styles: {
                                main: {
                                    ...genJss.flex,
                                    ...genJss.column as any,
                                    ...genJss.grow
                                }
                            }
                        }}
                        styles={{
                            main: overrideClass({
                                ...genJss.flex,
                                ...genJss.column as any,
                                ...genJss.grow,
                                ...genJss.scrollableY,
                                height: '100%'
                            })
                        }}
                        actions={(trip) =>
                            trip.availabilityInfo ?
                                [
                                    <div className={classes.availabilityInfo} key={"trip-availability"}>
                                        <AlertIcon />
                                        {trip.availabilityInfo}
                                    </div>
                                ] : []}
                        segmentActions={() => []}
                    />
                </div>
                :
                <div className={classNames(genStyles.flex, genStyles.grow)} style={{ height: '1px', padding: '24px', backgroundColor: '#2029310a', ...genJss.borderRadius(4) }}>
                    <TKUIRoutingResultsViewHelpers.TKStateProps>
                        {stateProps =>
                            <TKStyleOverride
                                componentKey="TKUITripRow"
                                stylesOverride={{
                                    unavailableAlternative: overrideClass({
                                        opacity: 1
                                    }),
                                    availabilityInfo: overrideClass({
                                        color: '#AE6500',
                                        '& path': {
                                            fill: '#AE6500'
                                        }
                                    })
                                }}
                            >
                                <TKUIRoutingResultsView
                                    {...stateProps}
                                    values={trips}
                                    onDetailsClicked={() => setTripDetailsView(true)}
                                    onSortChange={undefined}
                                    cardPresentation={CardPresentation.CONTENT}
                                    styles={{
                                        main: {
                                            ...genJss.flex,
                                            ...genJss.column as any,
                                            ...genJss.grow,
                                            ...genJss.scrollableY,
                                            height: '100%'
                                        }
                                    }}
                                    onTripSegmentSelected={undefined}   // To hide Book button on TKUITripRow                                
                                    automaticTripSelection={false}
                                    errorActions={() => []}
                                    tripMetricsToShow={[TKTripCostType.price]}
                                    tripSortingsToShow={[TripSort.OVERALL]}
                                    tripBadgesToShow={[]}
                                />
                            </TKStyleOverride>}
                    </TKUIRoutingResultsViewHelpers.TKStateProps>
                </div>}
            <div className={classes.footer}>
                {tripDetailsView && selectedTrip && !justOneResult ?
                    <button className={appClasses.buttonCancel} onClick={() => setTripDetailsView(false)}>
                        Back to results
                    </button>
                    :
                    <button className={appClasses.buttonCancel} onClick={() => onRequestClose?.()}>
                        Back
                    </button>}
                <button className={appClasses.buttonAdd}
                    onClick={() => onSubmit?.()}
                    disabled={!selectedTrip}
                    name="book-trip-btn"
                >
                    {"Book trip"}
                </button>
            </div>
        </div>
    );
}

const BookingForm = ({ value, onChange, trip, onSubmit, onRequestClose, classes, appClasses }) => {
    const formRef = useRef<any>(null);
    const segment = trip!.segments.find(segment => segment.booking);
    if (!value) {
        return null;
    }
    const inputFields = value.input;
    function optionsSortFc(el1: any, el2: any) {
        // The 'other' option should be the last one.
        if (el1.value === 'other') {
            return 1;
        }
        if (el2.value === 'other') {
            return -1;
        }
        return el1.label.localeCompare(el2.label);
    };
    function renderInputField(inputField: BookingField, onChangeInputFieldValue: (value: any) => void) {
        let valueElem: React.ReactNode = undefined;
        if (inputField.type === "SINGLE_CHOICE") {
            const options = inputField.options
                ?.map((option: BookingFieldOption) => ({
                    value: option.id,
                    label: option.title
                }))
                ?.sort(optionsSortFc)
                ?? [];  // inputField.options shouldn't be undefined for "SINGLE_CHOICE" type
            valueElem =
                <DefaultSelect
                    options={options}
                    value={inputField.value}
                    onChange={update => onChangeInputFieldValue(update)}
                    allowNoValue={true}
                    placeholder={"Select"}
                    isSearchable
                />;
        } else if (inputField.type === "MULTIPLE_CHOICE") {
            const multiSelectOptions = inputField.options
                ?.map((option: BookingFieldOption) => ({
                    value: option.id,
                    label: option.title
                }))
                ?.sort(optionsSortFc)
                ?? [];  // inputField.options shouldn't be undefined for "MULTIPLE_CHOICE" type
            valueElem =
                <DefaultSelect
                    options={multiSelectOptions}
                    isMulti
                    value={inputField.values}
                    onChange={update => // update is null if no option is selected.
                        onChangeInputFieldValue(update)}
                    placeholder={"Select"}
                    isSearchable
                />;
        } else if (inputField.type === "LONG_TEXT") {
            valueElem =
                <textarea
                    value={inputField.value}
                    onChange={e => onChangeInputFieldValue(e.target.value)}
                    placeholder={"Enter text here"}
                    style={{ height: '100px' }}
                />
        } else if (inputField.type === "NUMBER") {
            valueElem =
                <input
                    type='number'
                    value={inputField.value ?? inputField.minValue ?? 1}
                    min={inputField.minValue ?? 1}
                    max={inputField.maxValue ?? 10}
                    onChange={e => onChangeInputFieldValue(e.target.value)}
                    className={classes.numberInput}
                />
        } else if (inputField.type === "RETURN_TRIP" && segment) {
            const ROUND_TRIP = "Round trip";
            const options = [
                { value: ONE_WAY_ONLY, label: "One-way only" },
                { value: ROUND_TRIP, label: "Round trip" }
            ];
            const valueType = value =>
                // Leave undefined when required to force the user to explicitly pick an option,
                // or default to "One-way only" when field is optional (since placeholder makes no sense in that case.)
                value === "" ? (inputField.required ? undefined : ONE_WAY_ONLY) :
                    value === ONE_WAY_ONLY ? ONE_WAY_ONLY : ROUND_TRIP;
            valueElem =
                <div className={classes.returnTripInput}>
                    <DefaultSelect
                        options={options}
                        value={valueType(inputField.value)}
                        onChange={update => onChangeInputFieldValue(update === ONE_WAY_ONLY ? ONE_WAY_ONLY :
                            TKDateTimeUtil.isoFromSeconds(segment.endTimeSeconds, segment.to.timezone))}
                        placeholder={"Select"}
                        allowNoValue={true}
                    />
                    {valueType(inputField.value) === ROUND_TRIP &&
                        <DateTimePicker
                            value={DateTimeUtil.isoToHtml5(inputField.value!)}
                            onChange={(update) => {
                                onChangeInputFieldValue(DateTimeUtil.momentTZ(update, segment.to.timezone).format());
                            }}
                        />}
                </div>;
        }
        return (valueElem &&
            <div className={appClasses.formGroup} key={inputField.id}>
                <label className={inputField.type === "LONG_TEXT" ? classes.longTextLabel : undefined}>
                    {inputField.title}
                </label>
                <div className={classNames(appClasses.value, genStyles.grow)} id={inputField.id}>
                    {inputField.required ?
                        <Validator
                            value={inputField.value ?? inputField.values}
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <>
                                    {valueElem}
                                    {errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                </>}
                        </Validator> : valueElem}
                </div>
            </div>
        );
    };

    return (
        <div className={classNames(appClasses.form, genStyles.height100)}>
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.scrollableY)} style={{ height: '1px' }}>
                <TKUIFromTo
                    from={segment.from}
                    to={segment.to}
                    startTime={segment.startTime}
                    endTime={segment.endTime}
                    timezone={segment.from.timezone}
                    styles={theme => ({
                        main: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridTemplateColumns: '1fr .5fr 1fr',
                                columnGap: '30px',
                                marginLeft: '36px'
                            }
                        }),
                        fromToTrack: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridArea: '1 / 2 / 1 / 2',
                                order: '2',
                                flexDirection: 'row'
                            }
                        }),
                        line: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                borderTop: '1px solid ' + theme.colorPrimary
                            }
                        }),
                        pickupLabel: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '1',
                                order: '1'
                            }
                        }),
                        pickupTime: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '1',
                                order: '4'
                            }
                        }),
                        pickupAddress: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '1',
                                order: '6'
                            }
                        }),
                        dropoffLabel: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '3',
                                order: '3'
                            }
                        }),
                        dropoffTime: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '3',
                                order: '5'
                            }
                        }),
                        dropoffAddress: overrideClass({
                            ['@media (max-height: ' + 800 + 'px)']: {
                                gridColumnStart: '3',
                                order: '7'
                            }
                        })
                    })}
                />
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    className={genStyles.grow}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div style={{ marginTop: '50px' }}>
                        {value.tickets && value.tickets?.length > 0 &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Tickets
                                </label>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <TKStyleOverride
                                        componentKey="TKUIButton"
                                        stylesOverride={{
                                            main: overrideClass({
                                                ...resetJss.button
                                            })
                                        }}
                                    >
                                        <TKUITicketSelect
                                            tickets={value.tickets}
                                            onChange={update => onChange!(Util.iAssign(value, { tickets: update }))}
                                            styles={{
                                                main: overrideClass({
                                                    ...genJss.grow
                                                })
                                            }}
                                        />
                                    </TKStyleOverride>
                                    <Validator
                                        value={!value.tickets || value.tickets.length === 0 || value.tickets.some(ticket => ticket.value > 0) ? true : undefined}
                                        validators={["required"]}
                                        errorMessages={["selecting at least one ticket is required"]}
                                        resetOnValid
                                    >
                                        {({ errorMessage }) =>
                                            <>
                                                {errorMessage &&
                                                    <div className={appClasses.validationError}>{errorMessage}</div>}
                                            </>}
                                    </Validator>
                                </div>
                            </div>}
                        {value.input.map((inputField: BookingField, i) =>
                            renderInputField(inputField, valueUpdate => {
                                const inputFieldsUpdate = inputFields.slice();
                                const fieldUpdate = Util.clone(inputFields[i]);
                                inputFieldsUpdate[i] = fieldUpdate;
                                if (Array.isArray(valueUpdate)) {
                                    fieldUpdate.values = valueUpdate;
                                } else {
                                    fieldUpdate.value = valueUpdate;
                                }
                                onChange!(Util.iAssign(value, { input: inputFieldsUpdate }));
                            }))}
                    </div>
                </ValidatorForm>
            </div>
            <div className={classes.footer}>
                <button
                    className={appClasses.buttonCancel}
                    onClick={e => {
                        e.preventDefault();
                        onRequestClose?.();
                    }}>
                    Back
                </button>
                <button className={appClasses.buttonAdd}
                    onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                valid && onSubmit?.();
                            })
                    }}
                    disabled={false}
                    name={"book-btn"}
                >
                    {"Book"}
                </button>
            </div>
        </div>
    );
}

const BookingReviewView = ({ reviews, paymentOptions, onContinue, onRequestClose }) => {
    return (
        <TKUIBookingReview
            reviews={reviews}
            paymentOptions={paymentOptions}
            onContinue={onContinue}
            onClose={() => onRequestClose(false)}
            cancelText={"Back"}
        />
    );
}

TripGoApi.equivalentTrips = (trip1, trip2) => {
    return false;
}

const NewCreateBookingView: React.FunctionComponent<NewCreateBookingViewProps> = (props: NewCreateBookingViewProps) => {

    const { user, setUser, onOpenUserDetails, classes, appClasses, onRequestClose, options } = useStyles(props, newCreateBookingViewJss);

    type Screens = "QUERY" | "COMPUTING_TRIPS" | "TRIPS" | "BOOKING" | "PROVIDER_OPTIONS" | "TICKETS" | "REVIEW" | "PAYMENT";
    const [screensStack, setScreensStack] = useState<Screens[]>([
        "QUERY"
    ]);
    const [waiting, setWaiting] = useState<boolean>(false);
    const { selectedOrgID, selectedClientID: selectedClientIDApp, profile } = useContext(AppContext);
    const selectedClientID = user?.clientId ?? selectedClientIDApp;

    useBeforeInitialRender(() => {
        // Set the client id to that one of the booking.
        // Use client ID in path as fallback, but client ID should always come for super app bookings, and also
        // when no client is selected ("All apps") then there won't be any client ID on path, so it's fundamental that
        // the one in booking comes.
        TripGoApi.clientID = options?.referenceBooking?.clientId ?? selectedClientID;
    });

    // QUERY, TRIPS
    const [defaultRegion, setDefaultRegion] = useState<Region | undefined>();
    const [defaultCityLatLng, setDefaultCityLatLng] = useState<LatLng | undefined>();
    const {
        query,
        onQueryChange,
        clearTrips,
        preFrom,
        onPreChange,
        onComputeTripsForQuery,
        onSelectedTripChange,
        selectedTrip,
        trips,
        onTripJsonUrl,
        userProfile,
        region,
        mapAsync
    } = useTKState();

    const { finishInitLoadingPromise } = useContext(TKAccountContext);

    // BOOKING screen data
    const [bookingForm, setBookingForm] = useState<BookingInfo | undefined>(undefined);

    // PROVIDER_OPTIONS screens data
    const [providerOptionsForm, setProviderOptionsForm] = useState<ProviderOptionsForm | undefined>(undefined);
    const [selectedProviderIndex, setSelectedProviderIndex] = useState<number | undefined>(undefined);
    const selectedProvider: AvailableProviderOption | undefined = selectedProviderIndex !== undefined ? providerOptionsForm?.availableList[selectedProviderIndex!] : undefined;

    // TICKETS screens data
    function isSingleFareAndSingleTicket(provider: AvailableProviderOption): boolean {
        return provider.fares?.length === 1 && provider.fares[0].max === 1;
    }
    async function onSubmitTickets(selectedProvider: AvailableProviderOption) {
        setWaiting(true);
        try {
            const bookingResult = await TripGoApi.submitProviderAndFares(selectedProvider!);
            setWaiting(false);
            setBookingResult(bookingResult);
            const { reviews } = bookingResult;
            if (reviews) {
                // Add timezone to review's origin and destination since it's needed to pass it to TKUIFromTo.
                reviews.forEach((review: BookingReview) => {
                    const timezone = selectedTrip!.segments[0].from.timezone;
                    if (review.origin) {
                        review.origin.timezone = timezone;
                    }
                    if (review.destination) {
                        review.destination.timezone = timezone;
                    }
                })
                pushScreen("REVIEW");
            }
        } catch (error) {
            TKUIUtil.errorMsg(error as Error);
        } finally {
            setWaiting?.(false);
        }
    }

    // REVIEW, PAYMENT
    const [bookingResult, _setBookingResult] = useState<BookingPaymentForm | undefined>(undefined);
    const setBookingResult = (bookingResult: BookingPaymentForm | undefined) => {
        if (bookingResult) {
            // Remove invoice option for TSP users (#20732)
            bookingResult.paymentOptions = bookingResult.paymentOptions
                ?.filter(option => !adminProfile.isTSPUser || option.paymentMode !== "INVOICE");
            // Remove initiative options for Organization users
            if (adminProfile.isOrgUser) {
                bookingResult.initiative = undefined;
            }
        }
        _setBookingResult(bookingResult);
    }
    const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption | undefined>(undefined);

    function setMockData() {
        Features.instance.realtimeEnabled = false;
        setScreensStack([]);
        // Set my user's token
        TripGoApi.userToken = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJnYWxheGllcyIsInVzZXJJRCI6IjViYWIzOWE1LWViYjctNGJlZS05ZjU4LTI4YTIxNmUzZTA1NyIsImlhdCI6MTY4NDI2NjgwMiwiZXhwIjoxNjg0MzUzMjAyfQ.755AzzWtN1burJFqRn7OVq9xTsRirZmvzt6-mRE-lhE"
        // const routingResultsJSON = require("../mock/data/routing-drt.json");
        const routingResultsJSON = require("../mock/data/routing-one-result.json");
        onTripJsonUrl(JSON.stringify(routingResultsJSON))
            .then(trips => {
                onSelectedTripChange(trips?.[0]);
                // setScreensStack(["TRIPS", "QUERY"]);
                // setBookingForm(require("../mock/data/bookingInfo.json")
                setBookingForm(require("../mock/data/bookingInfo-microtransit.json")
                    .map(infoJson => TripGoApi.deserializeBookingInfo(infoJson))[0]);
                setScreensStack(["BOOKING", "TRIPS", "QUERY"]);
                setBookingResult(TripGoApi.deserializeBookingResult(require("../mock/data/bookingResult-microtransit.json")));
                setScreensStack(["REVIEW", "BOOKING", "TRIPS", "QUERY"]);
            });
    }

    async function setMockData2() {
        if (process.env.NODE_ENV === 'development') {
            const initQuery = RoutingQuery.create(TKLocation.create(LatLng.createLatLng(42.96336, -85.66809), "addr1", "addr1", "addr1"), TKLocation.create(LatLng.createLatLng(100, 100), "addr2", "addr2", "addr2"), TimePreference.LEAVE);
            console.log("Query", query);
            console.log("Init query", initQuery);
            await RegionsData.instance.requireRegions;
            onQueryChange(initQuery);
        }
    }

    // Important: this requires enabling mock of /pay request
    function setMockData3() {
        Features.instance.realtimeEnabled = false;
        setScreensStack([]);
        // Set my user's token
        TripGoApi.userToken = "eyJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJnYWxheGllcyIsInVzZXJJRCI6IjViYWIzOWE1LWViYjctNGJlZS05ZjU4LTI4YTIxNmUzZTA1NyIsImlhdCI6MTY4NDI2NjgwMiwiZXhwIjoxNjg0MzUzMjAyfQ.755AzzWtN1burJFqRn7OVq9xTsRirZmvzt6-mRE-lhE"
        const routingResultsJSON = require("../mock/data/createBooking/routing_single_result.json");
        onTripJsonUrl(JSON.stringify(routingResultsJSON))
            .then(trips => {
                onSelectedTripChange(trips?.[0]);
                setScreensStack(["TRIPS", "QUERY"]);
                setBookingForm(require("../mock/data/createBooking/quick.json")
                    .map(infoJson => TripGoApi.deserializeBookingInfo(infoJson))[0]);
                setScreensStack(["BOOKING", "TRIPS", "QUERY"]);
                setBookingResult(TripGoApi.deserializeBookingResult(require("../mock/data/createBooking/quick_1_b.json")));
                setScreensStack(["REVIEW", "BOOKING", "TRIPS", "QUERY"]);
                setScreensStack(["PAYMENT", "REVIEW", "BOOKING", "TRIPS", "QUERY"]);
            });
    }

    function pushScreen(screen: Screens) {
        setScreensStack(screensStack => [screen].concat(screensStack));
    }

    function popScreen() {
        setScreensStack(screensStack => screensStack.slice(1));
    }

    function topScreen(): Screens {
        return screensStack[0];
    }

    const mapRef = useRef<TKUIMapViewClass>(null);

    useEffect(() => {
        // value in HTML5 format (like ISO date-time but without timezone)
        ValidatorForm.addValidationRule('isInTheFuture', (value) => {
            // Since value is in HTML5 format, and so has no timezone, DateTimeUtil.momentDefaultTZ(value) creates
            // a moment in default timezone, preserving the date time in that timezone (so
            // DateTimeUtil.momentDefaultTZ(value).format().slice(0, 16) === value).
            const valueMoment = DateTimeUtil.momentDefaultTZ(value);
            const nowDateMoment = DateTimeUtil.getNow();
            return valueMoment.isAfter(nowDateMoment);
        });
        TKRegionsData.instance.requireRegions().then(() => {
            let defaultCityLatLng;
            if (adminProfile.defaultCity) {
                defaultCityLatLng = TKRegionsData.instance.getCities()?.find(city => city.identifier === adminProfile.defaultCity);
            }
            if (!defaultCityLatLng) {   // This shouldn't be necessary anymore if I properly set the previous parameter
                defaultCityLatLng = TKRegionsData.instance.getCities()?.[0];
            }
            const defaultRegion = defaultCityLatLng && RegionsData.instance.getRegion(defaultCityLatLng);
            defaultRegion && setDefaultRegion(defaultRegion);
            defaultCityLatLng && setDefaultCityLatLng(defaultCityLatLng);
            if (options?.QUERY?.query && options.QUERY.query.from && options.QUERY.query.to) {  // Pre-filled query
                mapAsync.then((map) => { map.fitBounds(MapUtil.createBBoxArray([options.QUERY!.query!.from!, options.QUERY!.query!.to!])) });
                // Workaround since after upgrading react-scripts to v5 the map doesn't fit the bounds properly the first time (like it's not ready yet)
                setTimeout(() => {
                    mapRef.current?.fitMapFromTo();
                }, 500);
            } else {
                mapRef.current?.setViewport(defaultCityLatLng, 13);
            }
        }).catch(() => {
            UIUtil.errorMessage(new TKError("Bookings cannot be created due to a temporary issue in our system. Please try again later.", undefined, true), { onClose: () => onRequestClose?.() });
        });

        if (options?.QUERY?.query) {    // Pre-filled query            
            onQueryChange(options.QUERY.query);
        } else {
            onQueryChange(Util.iAssign(query, { timePref: TimePreference.LEAVE }));
        }

        if (process.env.NODE_ENV === 'development') {
            // setMockData();
            // setMockData2();
            // setMockData3();
        }

    }, []);

    const viewHasMap = topScreen() === "QUERY" || topScreen() === "COMPUTING_TRIPS" || topScreen() === "TRIPS" || topScreen() === "BOOKING";

    const orgOptions = useMemo(() => {
        let relevantOrgs = adminProfile.orgs
            ?.filter(o => !adminProfile.orgs!.some(o1 => o1 !== o && descendentOrg(o1, o)) && !o.isClientApp) ?? [];  // Only include leaf orgs, and that are not client apps.
        relevantOrgs = filterClientOrgs(relevantOrgs, selectedClientID);
        return getOrgSelectOptions(relevantOrgs);
    }, [adminProfile.orgs]);

    const internalNote = adminProfile.features.internalTripAndRiderNotes22953 && !!user?.internalNote &&
        <div style={{
            position: 'relative',
            height: '40px',
            flexGrow: 1
        }}>
            <div style={{
                position: 'absolute',
                width: '100%',
                zIndex: 5,
                background: 'rgb(250, 250, 250)',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
                padding: '10px',
                borderRadius: '8px',
                top: 0,
                boxSizing: 'border-box'
            }}>
                <FoldableNote
                    text={user.internalNote}
                />
            </div>
        </div>;

    let subtitle = options?.subtitle ?? (selectedOrgID && profile.orgs ? `For ${orgFromId(selectedOrgID, profile.orgs)?.name}` : undefined);
    subtitle = subtitle && internalNote ? <div className={classes.viewSubtitle}>{subtitle} {internalNote}</div> : subtitle ?? internalNote;

    function shouldSkipSingleRoutingResult(trips?: Trip[]): boolean {
        // return false;    // Disable this feature.
        return trips?.length === 1 && !!trips[0].segments.find(segment => segment.booking)
    };

    return (
        <View
            title={options?.title ?? i18n.t("Create.booking")}
            subtitle={subtitle}
            onBack={options?.onRequestClose}
            styles={(_theme, defaultStyle) => ({
                left: {
                    ...defaultStyle.left,
                    alignItems: 'initial',
                    WebkitAlignItems: 'initial',
                    flexGrow: 1
                }
            })}
        >
            <div className={classNames(classes.main, viewHasMap && (topScreen() === "BOOKING" ? classes.displayMapWhenRoom : classes.displayMap))}>
                <div
                    className={classes.leftContainer}
                // style={{ ...viewHasMap ? { width: '530px' } : { width: '100%' }, minWidth: '400px', maxWidth: '1100px' }}
                >
                    {waiting &&
                        <div className={classes.loadingPanel}>
                            <TKLoading />
                        </div>}
                    {(topScreen() === "QUERY" || topScreen() === "COMPUTING_TRIPS") &&
                        <RoutingQueryForm
                            query={query}
                            onQueryChange={onQueryChange}
                            setPreFrom={loc => onPreChange?.(true, loc)}
                            setPreTo={loc => onPreChange?.(true, loc)}
                            user={user}
                            setUser={setUser && ((value?: User) => {
                                // Reset user mode rules if user changes.                                        
                                userProfile.resetUserModeRules();
                                setUser(value);
                            })}
                            defaultRegion={defaultRegion}
                            defaultCityLatLng={defaultCityLatLng}
                            classes={classes}
                            appClasses={appClasses}
                            onSubmit={async () => {
                                setWaiting(true);
                                const providers = await getProvidersP({ clientId: selectedClientID, adminProfile });
                                if (region && providers.some(provider => provider.isExclusive)) {  // Check if exclusive modes feature is enabled                                                                        
                                    await finishInitLoadingPromise;
                                    // Cleanup once providerMobilityOptions22827 reaches prod.
                                    if (!adminProfile.features.providerMobilityOptions22827) {
                                        await userProfile.getUserModeRulesByRegionP(region.name);
                                    }
                                }
                                onComputeTripsForQuery(true);
                                // Need to push "COMPUTING_TRIPS" screen after triggering trips computation, since the view then detects when trip computation
                                // is done to automatically continue to the next screen.
                                pushScreen("COMPUTING_TRIPS");
                            }}
                            onRequestClose={() => {
                                onRequestClose?.();
                            }}
                            onOpenUserDetails={onOpenUserDetails}
                        />}
                    {topScreen() === "COMPUTING_TRIPS" &&
                        <ComputingTrips
                            onContinue={(trips) => {
                                popScreen();    // Remove itself from view stack, since we don't want to go back to it.                                
                                const tripsOptions = options?.TRIPS;
                                if (trips && trips.length > 0) {    // After all trips arrived
                                    if (tripsOptions?.defaultMode) {    // Auto select default trip                
                                        onSelectedTripChange(trips.find(trip => !!trip.segments.find(segment => segment.modeInfo?.identifier === tripsOptions.defaultMode)) ?? trips[0]);
                                    } else {    // Auto select first trip
                                        onSelectedTripChange(trips[0]);
                                    }
                                }
                                if (shouldSkipSingleRoutingResult(trips)) {
                                    const bookingSegment = trips![0].segments.find(segment => segment.booking);
                                    const booking = bookingSegment!.booking!;
                                    const bookingInfosUrl = booking.quickBookingsUrl!;
                                    TripGoApi.requestBookingOptions(bookingInfosUrl)
                                        .then(bookingInfos => {
                                            pushScreen("BOOKING");
                                            setBookingForm(options?.BOOKING?.formPrefillFc?.(bookingInfos[0], query) ?? bookingInfos[0]);
                                            setWaiting(false);
                                        })
                                        .catch(TKUIUtil.errorMsg)
                                        .finally(() => setWaiting(false));
                                } else {
                                    setWaiting(false);
                                    pushScreen("TRIPS");
                                }
                            }}
                        />}
                    {topScreen() === "TRIPS" &&
                        <RoutingResultsView
                            onSubmit={() => {
                                setWaiting(true);
                                const bookingSegment = selectedTrip!.segments.find(segment => segment.booking);
                                if (!bookingSegment) {
                                    // Woraround for when the trip comes without `booking` field, which is an intermitent BE issue.
                                    const error = new TKError("Something.went.wrong.");
                                    error.title = "There was a temporary issue that prevents booking this trip";
                                    error.subtitle = "Please retry your query";
                                    TKUIUtil.errorMsg(error, {
                                        onClose: () => {
                                            popScreen();
                                            setWaiting(false);
                                        }
                                    });
                                    return;
                                }
                                const booking = bookingSegment!.booking!;
                                const bookingInfosUrl = booking.quickBookingsUrl!;
                                TripGoApi.requestBookingOptions(bookingInfosUrl)
                                    .then(bookingInfos => {
                                        pushScreen("BOOKING");
                                        setBookingForm(options?.BOOKING?.formPrefillFc?.(bookingInfos[0], query) ?? bookingInfos[0]);
                                        setWaiting(false);
                                    })
                                    .catch(TKUIUtil.errorMsg)
                                    .finally(() => setWaiting(false));
                            }}
                            onRequestClose={() => {
                                popScreen();
                                onComputeTripsForQuery(false);
                                onSelectedTripChange(undefined);
                            }}
                            classes={classes}
                            appClasses={appClasses}
                            tripsOptions={options?.TRIPS}
                        />}
                    {topScreen() === "BOOKING" && bookingForm && selectedTrip &&
                        <BookingForm
                            value={bookingForm}
                            onChange={setBookingForm}
                            trip={selectedTrip!}
                            appClasses={appClasses}
                            onSubmit={async () => {
                                setWaiting(true);
                                if (bookingForm.bookingResponseType === "OPTIONS") {
                                    try {
                                        const providerOptions = await TripGoApi.submitBookingOptionToGetProviderOptions(bookingForm!);
                                        setWaiting(false);
                                        setProviderOptionsForm(providerOptions);
                                        // Only a single available option and no unavailable ones => Straight to ticket selection
                                        if (providerOptions.availableList.length === 1 && providerOptions.unavailableList.length === 0 &&
                                            // Except that single provider option has single fare and single ticket, in which case we prefer
                                            // to show the provider option and skip ticket selection.
                                            !(isSingleFareAndSingleTicket(providerOptions.availableList[0]))) {
                                            setSelectedProviderIndex(0);
                                            pushScreen("TICKETS");
                                        } else {
                                            pushScreen("PROVIDER_OPTIONS");
                                        }
                                    } catch (error) {
                                        TKUIUtil.errorMsg(error as Error);
                                    } finally {
                                        setWaiting?.(false);
                                    }
                                } else if (adminProfile.features.providerMobilityOptions22827 ? bookingForm.bookingResponseType === "REVIEW" : true) {
                                    try {
                                        const bookingResult = await TripGoApi.submitBookingOption(bookingForm!);
                                        setWaiting(false);
                                        setBookingResult(bookingResult);
                                        const { reviews } = bookingResult;
                                        if (reviews) {
                                            // Add timezone to review's origin and destination since it's needed to pass it to TKUIFromTo.
                                            reviews.forEach((review: BookingReview) => {
                                                const timezone = selectedTrip!.segments[0].from.timezone;
                                                if (review.origin) {
                                                    review.origin.timezone = timezone;
                                                }
                                                if (review.destination) {
                                                    review.destination.timezone = timezone;
                                                }
                                            })
                                            pushScreen("REVIEW");
                                        }
                                    } catch (error) {
                                        TKUIUtil.errorMsg(error as Error);
                                    } finally {
                                        setWaiting?.(false);
                                    }
                                } else if (bookingForm.bookingResponseType === "DIRECT") {
                                    try {
                                        const bookingResult = await TripGoApi.submitBookingOptionAndFinish(bookingForm!)
                                        onRequestClose?.(true, { ...bookingResult, userId: user!.id });
                                    } catch (error) {
                                        TKUIUtil.errorMsg(error as Error);     // TODO: is that ok?                                    
                                    } finally {
                                        setWaiting?.(false);
                                    }
                                }
                            }}
                            onRequestClose={() => {
                                popScreen();
                                setBookingForm(undefined);
                                if (shouldSkipSingleRoutingResult(trips)) {
                                    clearTrips();
                                    onComputeTripsForQuery(false);
                                    onSelectedTripChange(undefined);
                                }
                            }}
                            classes={classes}
                        />}
                    {topScreen() === "PROVIDER_OPTIONS" &&
                        <TKUIBookingProviderOptions
                            form={providerOptionsForm!}
                            onProviderSelected={(value: AvailableProviderOption) => {
                                setSelectedProviderIndex(providerOptionsForm!.availableList.indexOf(value));
                                if (isSingleFareAndSingleTicket(value)) {
                                    onSubmitTickets(value);
                                } else {
                                    pushScreen("TICKETS");
                                }
                            }}
                            renderProviderAction={adminProfile.features.providerSettings22829 ?
                                (providerCode: string) => {
                                    return (
                                        <a
                                            className={classes.openFullBtn}
                                            href={`#${appPathUpdate({ path: "/providers/providerId/" + providerCode })}`}
                                            target="_blank"
                                            onClick={(e) => { e.stopPropagation(); }}
                                        >
                                            <IconExternalLink />
                                        </a>
                                    )
                                } : undefined}
                            onClose={() => {
                                popScreen();
                            }}
                            cancelText={"Back"}
                        />}
                    {topScreen() === "TICKETS" &&
                        <TKUIProviderTicketsForm
                            provider={selectedProvider!}
                            onChange={(tickets: TicketOption[]) => {
                                const providerOptionsFormUpdate = TKUtil.deepClone(providerOptionsForm!);
                                providerOptionsFormUpdate.availableList[providerOptionsForm!.availableList.indexOf(selectedProvider!)!].fares = tickets;
                                setProviderOptionsForm(providerOptionsFormUpdate);
                            }}
                            onSubmit={() => onSubmitTickets(selectedProvider!)}
                            onClose={() => {
                                popScreen();
                            }}
                            cancelText={"Back"}
                        />}
                    {topScreen() === "REVIEW" &&
                        <BookingReviewView
                            reviews={bookingResult!.reviews}
                            paymentOptions={bookingResult!.paymentOptions}
                            onContinue={() => {
                                if (bookingResult!.paymentOptions?.[0]?.paymentMode === "FREE") {
                                    const payOption = bookingResult!.paymentOptions?.[0];
                                    setWaiting(true);
                                    TripGoApi.apiCallUrl(payOption.url, payOption.method)
                                        .then(result => {
                                            setWaiting(false);
                                            // Check that result comes with either updateURL or refreshURLForSourceObject, so we can
                                            // then get booking id. 
                                            onRequestClose?.(true, result);
                                        })
                                        .catch(TKUIUtil.errorMsg)
                                        .finally(() => setWaiting(false));
                                    return;
                                }
                                pushScreen("PAYMENT");
                            }}
                            onRequestClose={() => popScreen()}
                        />}
                    {topScreen() === "PAYMENT" &&
                        <TKRenderOverride
                            componentKey="TKUISelect"
                            renderOverride={(props) =>
                                <div className={classes.selectContainer}>
                                    <DefaultSelect
                                        {...props}
                                        options={props.options}
                                        value={props.value?.value}
                                        onChange={update => props.onChange(props.options.find(o => o.value === update))}
                                        allowNoValue={true}
                                        isSearchable
                                        menuShouldScrollIntoViewWithDelay={true}
                                    />
                                </div>
                            }
                        >
                            <TKUICheckoutView
                                bookingPaymentForm={bookingResult!}
                                setWaiting={setWaiting}
                                onSubmit={data => {
                                    onRequestClose?.(true, { ...data, userId: user!.id });
                                }}
                                onClose={() => {
                                    popScreen();
                                }}
                                organizationOptions={orgOptions}
                                defaultOrganizationOption={options?.PAYMENT?.defaultInvoiceOrganizationId ? orgOptions.find(o => o.value === options.PAYMENT?.defaultInvoiceOrganizationId) :
                                    orgOptions.length === 1 ? orgOptions[0] :
                                        (user?.appData?.organizations && user.appData.organizations.length === 1) ? orgOptions.find(o => o.value === user.appData!.organizations![0]) :
                                            undefined}
                                defaultPaymentMethodFc={options?.PAYMENT?.defaultPaymentMethodFc}
                            />
                        </TKRenderOverride>}
                </div>
                {viewHasMap &&
                    <div className={classes.mapContainer}>
                        <TKUIMapViewHelpers.TKStateProps>
                            {({ ...stateProps }) =>
                                <TKUIMapView
                                    {...stateProps}
                                    from={(preFrom || query.from) ?? undefined}
                                    // to={preTo || to || undefined}
                                    // onFromChange={location => setFrom(location ?? undefined)}
                                    // onToChange={location => setTo(location ?? undefined)}
                                    // trip={selectedTrip}
                                    mapClickBehaviour="SET_FROM_TO"
                                    rightClickMenu={[
                                        { label: "Select as From", effect: "SET_FROM" },
                                        { label: "Select as To", effect: "SET_TO" }
                                    ]}
                                    // Possibly use this logic as the default one in tripkit, and in that case
                                    // remove this property (maybe there's a better way to provide customizable fit
                                    // map conditions / behaviour).
                                    shouldFitMap={(from: TKLocation | undefined, to: TKLocation | undefined, preFrom: TKLocation | undefined, preTo: TKLocation | undefined) => {
                                        const fitFrom = !!from && from.isResolved() && !from.isDroppedPin() && (!preFrom ||
                                            JSON.stringify(Util.transerialize(from, LatLng)) !== JSON.stringify(Util.transerialize(preFrom, LatLng)));
                                        const fitTo = !!to && to.isResolved() && !to.isDroppedPin() && (!preTo ||
                                            JSON.stringify(Util.transerialize(to, LatLng)) !== JSON.stringify(Util.transerialize(preTo, LatLng)));
                                        return fitFrom || fitTo;
                                    }}
                                    showCurrLocBtn={false}
                                    hideLocations={true}
                                    onResize={() => {
                                        mapRef.current?.fitMapFromTo();
                                    }}
                                    ref={mapRef}
                                />}
                        </TKUIMapViewHelpers.TKStateProps>
                    </div>}
            </div>
        </View>
    );
}

// Force spacing in time and duration formatting.
const originalTimeFormat = TKDateTimeUtil.timeFormat;
TKDateTimeUtil.timeFormat = (_spaced: boolean = true) => {
    return originalTimeFormat(true);
};
const originalDurationToBriefString = TKDateTimeUtil.durationToBriefString;
TKDateTimeUtil.durationToBriefString = (durationInMinutes: number, space: boolean = true, decimal: boolean = false) => {
    return originalDurationToBriefString(durationInMinutes, true, decimal);
}

export default NewCreateBookingView;