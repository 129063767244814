import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { editAdminViewJss } from "./EditAdminView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import View from "../view/View";
import { DefaultSelect } from "../rewards/StatusSelect";
import { AppContext } from "../app/App";
import { CheckboxStyled } from "../booking/BookingsView";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import { ADMIN_VIEW as ADMIN_USER_VIEW, AdminViewProps } from "./AdminView";
import withAsycnData from "../data/WithAsyncData";
import AdminUsersData, { OtherCreateInput } from "../data/AdminUsersData";
import AdminUser from "../model/AdminUser";
import Util from "../util/Util";
import Organization from "../model/Organization";
import { isRootOrg } from "../app/OrgSelectorHelpers";
import UIUtil from "../util/UIUtil";
import Provider from "../model/Provider";
import ProvidersData from "../data/ProvidersData";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import { i18n } from "../i18n/TKI18nConstants";

export interface EditAdminViewProps {
    admin?: AdminUser;
    onRequestClose?: (update?: AdminUser, otherInput?: OtherCreateInput) => void;
}

export interface IProps extends EditAdminViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof editAdminViewJss>;

function isClientAppOrg(o: Organization) {
    return o.path.length === 8 && o.clientId !== undefined;
}

export function roleToLabel(role: string) {
    switch (role) {
        case "admin":
            return "Feonix Admin";
        case "org_admin":
            return i18n.t("Organization.Admin");
        case "tsp_admin":
            return "TSP Admin";
        default:
            return role;
    }
}

export function getOrgSelectOptions(orgs: Organization[]) {
    return orgs
        ?.map(org => ({ value: org, label: org.isRoot ? "All" : org.name }))
        ?.sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));
}

const EditAdminView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { admin, onRequestClose, classes, appClasses } = props;
    const formRef = useRef<any>(null);
    const isCreate = admin === undefined;
    const { profile: adminProfile, navHistory } = useContext(AppContext);
    const [allProviders, setAllProviders] = useState<Provider[]>([]);
    useEffect(() => {
        ProvidersData.instance.getAll()
            .then(providers => providers.sort((a, b) => a.name.localeCompare(b.name)))
            .then(providers => setAllProviders(providers));
    }, []);
    const clientAppOptions = useMemo(() => getOrgSelectOptions(adminProfile.orgs?.filter(o => o.fullAccess && (o.isClientApp || o.isRoot)) ?? []), [adminProfile.orgs]);
    const orgOptions = useMemo(() => getOrgSelectOptions(adminProfile.orgs?.filter(o => o.fullAccess && !o.isClientApp && !o.isRoot) ?? []), [adminProfile.orgs]);
    const providerOptions = useMemo(() => allProviders
        .map(provider => ({ value: provider.id, label: provider.name })) ?? [], [allProviders]);
    const [update, setUpdate] = useState<AdminUser>(admin ? Util.deepClone(admin) : Util.iAssign(new AdminUser(), { role: "admin" }));
    // Maintain in separate state props so the value is preserved if user type is changed between Feonix Admin and Organization Admin.
    const [clientApps, setClientApps] = useState<Organization[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [providers, setProviders] = useState<Provider[]>(update.providers);
    // TODO: cleanup inferred fallback value when role starts coming from BE.
    const role = update.role ?? (update.providers.length > 0 ? "tsp_admin" : update.organizations.length > 0 && update.organizations.some(o => o.path.length > 8) ? "org_admin" : "admin");
    const feonixOrg = adminProfile.orgs?.find(o => isRootOrg(o));
    return (
        <View
            title={isCreate ? "New Admin" : "Edit Admin"}
        >
            <div className={classNames(appClasses.form, classes.narrowForm)}>
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={appClasses.formGroup}>
                        <label>Email</label>
                        <Validator
                            value={update.email}
                            name="newStatus"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <input
                                        placeholder="e.g. johndoe@mail.com"
                                        className={genStyles.grow}
                                        value={update.email}
                                        onChange={e => setUpdate(Util.iAssign(update, { email: e.target.value }))}
                                        disabled={!isCreate}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label>First Name</label>
                        <Validator
                            value={update.firstName}
                            name="newStatus"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <input
                                        placeholder="e.g. John"
                                        className={genStyles.grow}
                                        value={update.firstName}
                                        onChange={e => setUpdate(Util.iAssign(update, { firstName: e.target.value }))}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label>Last Name</label>
                        <Validator
                            value={update.lastName}
                            name="newStatus"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <input
                                        placeholder="e.g. Doe"
                                        className={genStyles.grow}
                                        value={update.lastName}
                                        onChange={e => setUpdate(Util.iAssign(update, { lastName: e.target.value }))}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={classNames(appClasses.formGroup, classes.radioGroup)}>
                        <label>User type</label>
                        {isCreate ?
                            <div className={appClasses.value}>
                                <RadioGroup
                                    value={role}
                                    onChange={(_event, value) => {
                                        setUpdate(Util.iAssign(update, { role: value, ...value === "tsp_admin" ? { adminManagement: false } : {} }));
                                    }}
                                >
                                    <FormControlLabel value="admin" control={<Radio />} label={roleToLabel("admin")} />
                                    <FormControlLabel value="org_admin" control={<Radio />} label={roleToLabel("org_admin")} />
                                    <FormControlLabel value="tsp_admin" control={<Radio />} label={roleToLabel("tsp_admin")} />
                                </RadioGroup>
                            </div>
                            :
                            <div className={appClasses.value}>
                                {roleToLabel(role)}
                            </div>}
                    </div>
                    {role === "admin" && isCreate &&
                        <div className={appClasses.formGroup}>
                            <label>Client Apps</label>
                            {isCreate ? // TODO: clean this condition and else value if just hide
                                <div className={classNames(appClasses.value, classes.orgsSelect)}>
                                    <DefaultSelect
                                        options={clientAppOptions}
                                        value={clientApps}
                                        onChange={value => {
                                            setClientApps(value);
                                        }}
                                        placeholder={"None"}
                                        isMulti
                                        isSearchable
                                    />
                                </div> :
                                <div className={appClasses.value}>
                                    {update.organizations.map(o => o.name).join(", ")}
                                </div>}
                        </div>}
                    {role === "org_admin" && isCreate &&
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Organizations")}</label>
                            {isCreate ? // TODO: clean this condition and else value if just hide
                                <div className={classNames(appClasses.value, classes.orgsSelect)}>
                                    <DefaultSelect
                                        options={orgOptions}
                                        value={organizations}
                                        onChange={value => {
                                            setOrganizations(value)
                                        }}
                                        placeholder={"None"}
                                        isMulti
                                        isSearchable
                                    />
                                </div> :
                                <div className={appClasses.value}>
                                    {update.organizations.map(o => o.name).join(", ")}
                                </div>}
                        </div>}
                    {role === "tsp_admin" &&
                        <div className={appClasses.formGroup}>
                            <label className={appClasses.cursorHelp}>Transport Service Provider</label>
                            <DefaultSelect
                                options={providerOptions}
                                value={providers[0]?.id}
                                onChange={(value) => {
                                    setProviders([allProviders.find(p => p.id === value)!]);
                                }}
                                allowNoValue
                                placeholder={"None"}
                                isSearchable
                            />
                        </div>}
                    {!update.isTSP && !update.isOrg &&
                        <div className={appClasses.formGroup}>
                            <label>Give admin management permissions</label>
                            <CheckboxStyled
                                checked={update.adminManagement}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setUpdate(Util.iAssign(update, { adminManagement: checked }));
                                }}
                            />
                        </div>}
                    {adminProfile.isSkedGoDev &&
                        <div className={appClasses.formGroup}>
                            <label>Is SkedGo Dev</label>
                            <CheckboxStyled
                                checked={update.isSkedGoDev}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setUpdate(Util.iAssign(update, { isSkedGoDev: checked }));
                                }}
                            />
                        </div>}
                </ValidatorForm>
            </div>
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button onClick={() => onRequestClose?.()} className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <button
                    onClick={async () => {
                        const valid = await formRef.current.isFormValid(false)
                        if (valid) {
                            onRequestClose?.(update,
                                {
                                    ...(clientApps.length > 0 || organizations.length > 0) ? { organizations: [...clientApps, ...organizations] } : {},
                                    ...providers.length > 0 ? { provider: providers[0] } : {}
                                });
                        }
                    }}
                    className={appClasses.buttonAdd}
                >
                    Save
                </button>
            </div>
        </View >
    );
}

const EditAdminViewStyled = withStyles(EditAdminView, editAdminViewJss);

const EditAdminViewWithData = withAsycnData(EditAdminViewStyled,
    (query: AdminViewProps) =>
        AdminUsersData.instance.get(query.id)
            .then((admin: AdminUser) => ({ admin })) as Promise<{ admin?: AdminUser }>);

export const ADMIN_NEW_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/newAdmin"],
    propsToPath: ({ }) => "/newAdmin",
    propsFromMatch: () => {
        return {};
    },
    navLabel: () => "New Admin",
    render: ({ navHistory, waitFor }) => {
        return (
            <EditAdminViewStyled
                onRequestClose={async (update?: AdminUser, otherInput: OtherCreateInput = {}) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        const newAdminUser = await waitFor(AdminUsersData.instance.create(update, otherInput));
                        AdminUsersData.instance.invalidateCache();
                        if (otherInput.organizations && otherInput.organizations.length > 0 || otherInput.provider) {
                            AdminUsersData.instance.invalidateCacheById(newAdminUser.id);   // This is needed if we linked orgs or providers.
                        }
                        navHistory.replace(ADMIN_USER_VIEW, { id: newAdminUser.id });
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};

export const ADMIN_EDIT_VIEW: IViewRouteConfig<AdminViewProps> =
{
    path: ["*/adminId/:id/edit"],
    propsToPath: () => "/edit",
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: () => "Edit Admin",
    render: ({ viewProps, navHistory, waitFor }) => {
        return (
            <EditAdminViewWithData
                id={viewProps.id}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (update?: AdminUser, otherInput: OtherCreateInput = {}) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        await waitFor(AdminUsersData.instance.update(update, otherInput));
                        AdminUsersData.instance.invalidateCache();
                        if (otherInput.provider) {
                            AdminUsersData.instance.invalidateCacheById(update.id);   // This is needed if we linked / unlinked the provider.
                        }
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};