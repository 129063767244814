import React, { ComponentType, useState } from "react";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Filter from "../data/Filter";
import Transaction, { TransType } from "../model/Transaction";
import TransactionsData from "../data/TransactionsData";
import { Observable, pipe, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import Util from "../util/Util";
import DateTimeUtil from "../util/DateTimeUtil";
import * as moment from 'moment-timezone';
import { REDEMPTION_EDIT_VIEW } from "./EditRedemptionView";
import { REDEMPTIONS_VIEW_PATH } from "./RedemptionsViewConstants";
import { BookingsViewWithData, FiltersVisibility } from "../booking/BookingsView";
import FormatUtil from "../util/FormatUtil";
import UsersData from "../data/UsersData";

const redemptionsViewId = "REDEMPTIONS_VIEW";

export const REDEMPTIONS_VIEW: IViewRouteConfig<{ filter?: Filter }> =
{
    path: REDEMPTIONS_VIEW_PATH,
    propsFromMatch: (match, profile) => {
        const filter = Util.deserialize(match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            JSON.parse(match.params.filter) :
            {
                pageSize: profile.pageSizeByView.get(redemptionsViewId) || 10,
                page: 1,
                range: moment.range(DateTimeUtil.getNowDate().subtract(2, 'months'), DateTimeUtil.getNowDate()),
                type: TransType.REDEMPTION
            }, Filter);
        return { filter: filter };
    },
    propsToPath: (params: { filter?: Filter }) => "/redemptions" + (params.filter ? "/filter/" + JSON.stringify(Util.serialize(params.filter)) : ""),
    navLabel: () => "Redemption events",
    render: ({ viewProps, navHistory, profile, onProfileChange }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(redemptionsViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(REDEMPTIONS_VIEW,
                Util.iAssign(viewProps, { filter: update }))
        };
        const bookingsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(true);
        };
        const onEditTrans = (transaction: Transaction) => navHistory.push(REDEMPTION_EDIT_VIEW, { id: transaction.id });
        const onExport: (filter: Filter) => Promise<void> = (filter: Filter) => {
            return TransactionsData.instance.getExportData(filter).then((transactions: Transaction[]) =>
                FormatUtil.downloadObjectAsJson(Util.serialize(transactions), "transactions"));
        };
        const onEditedTrans = (update: Transaction) => {
            // To refresh user balance when we go to 'Participants' view. If I do it after update succeeds and user
            // switches to participants view before that (so, before invalidation), then refresh is never triggered.
            update.userId && UsersData.instance.invalidateUserCache(update.userId);
            return TransactionsData.instance.update(update)
                // Don't undefine data, so we avoid bookings view to turn into loading state and then table to be
                // re-constructed, which makes temporal update state of booking row to be lost.
                .then(() => bookingsViewRef.current.refresh(false))
        };
        // Reset page number after a reload.
        if (filter.page > 1 && TransactionsData.instance.isEmpty()) {
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const showFilters: FiltersVisibility = {
            status: true,
            providers: true,
            groupBy: true,
            dateRange: true,
            paymentStatus: true
        }
        return (
            <BookingsViewWithData
                title={"Redemption events"}
                filter={filter!}
                showFilters={showFilters}
                onEditTrans={onEditTrans}
                onFilterChange={onFilterChange}
                onExportData={onExport}
                onRefreshClick={onRefresh}
                onEditedTrans={onEditedTrans}
                ref={bookingsViewRef}
                showNav={false}
            />
        );
    }
};