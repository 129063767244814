import React, { useContext, useRef } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { moneyTransactionsViewJss } from "./MoneyTransactionsView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import ViewRestyled from "../view/ViewRestyled";
import classNames from "classnames";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import FormatUtil from "../util/FormatUtil";
import { Client } from "../model/Client";
import Filter, { SortOrder } from "../data/Filter";
import Transaction, { TransType } from "../model/Transaction";
import ExportButton, { ExportFormat } from "../export/ExportButton";
import ItemsResult from "../model/ItemsResult";
import Util from "../util/Util";
import * as moment from 'moment-timezone';
import DateTimeUtil from "../util/DateTimeUtil";
import MoneyTransactionsTable from "./MoneyTransactionsTable";
import Loading from "../view/Loading";
import { EXPORT_TRANSACTIONS_TOOLTIP, TransGroups, getGroupByOptions } from "./BookingsView";
import TransactionsData from "../data/TransactionsData";
import ItemGroup from "../model/ItemGroup";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import genStyles from "../css/general.module.css";
import Paginate from "../paginate/Paginate";
import MemoScroll, { MemoScrollRef } from "../uiutil/MemoScroll";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable, from } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { adminProfile } from "../account/AdminProfile";
import { DefaultSelect } from "../rewards/StatusSelect";
import DateRangePicker, { invalidRange } from "../time/DateRangePicker";
import PageSizeSelect from "../paginate/PageSizeSelect";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import { i18n } from "../i18n/TKI18nConstants";

export interface MoneyTransactionsViewProps {
    transactionsResult?: ItemsResult<Transaction>;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onSelect?: (value: Transaction) => void;
    onExportData?: (filter: Filter, format: ExportFormat) => Promise<void>;
    onRefreshClick?: () => void;
    selectedClientID?: string;
    clients?: Client[];
    showFilters?: boolean;
    showGroupBy?: boolean;
    showDateRangePicker?: boolean;
    asView?: boolean;
}

export interface IProps extends MoneyTransactionsViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof moneyTransactionsViewJss>;

const MoneyTransactionsView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const memoScrollRef = useRef<MemoScrollRef>(null);

    const { transactionsResult, appClasses, classes, filter, onSelect, onFilterChange, showFilters = true, showGroupBy = true, showDateRangePicker = true, asView = true } = props;
    const { profile: adminProfile, clients } = useContext(AppContext);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            memoScrollRef.current?.reset();
            const filterUpdate = Util.iAssign(props.filter, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    function handleDateRangeChange(range: Range) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter,
                {
                    range: range,
                    page: 1
                }
            );
            props.onFilterChange(filterUpdate)
        }
    }

    function renderTransactions(trans: Transaction[], showConfigBtn?: boolean) {
        if (trans.length === 0) {
            return (
                <div className={classes.noResults}>
                    None
                </div>
            );
        }
        return (
            <MoneyTransactionsTable
                tableId={adminProfile.app + "-" + moneyTransactionsViewId}
                values={trans}
                filter={filter}
                onFilterChange={onFilterChange}
                onSelect={onSelect}
                clients={clients}
                showConfigBtn={showConfigBtn}
            />
        );
    }

    // const organizations = profile.orgs?.filter(o => o.fullAccess && !isRootOrg(o)) ?? [];        
    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (transactionsResult) {
        pageCount.current = transactionsResult.pageCount + (transactionsResult.more ? 1 : 0);
    }

    let transactions;
    if (props.filter.range && invalidRange(props.filter.range)) {
        transactions =
            <div className={appClasses.noResults}>
                Invalid date range
            </div>;
    } else if (!transactionsResult) {
        transactions = <Loading />;
    } else if (transactionsResult.count === 0) {
        transactions =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        const transactionItems = transactionsResult.items;
        if (props.filter.groupBy) {
            let groupTitleFc;
            switch (props.filter.groupBy) {
                case TransGroups.DAY: groupTitleFc = item =>
                    item.transactionMoment.format("MMMM YYYY") === DateTimeUtil.getNow().format("MMMM YYYY") ?
                        item.transactionMoment.format("DD") === DateTimeUtil.getNow().format("DD") ?
                            "Today " + item.transactionMoment.format("ddd DD") :
                            item.transactionMoment.format("ddd DD") : item.transactionMoment.format(DateTimeUtil.dayMonthFormat());
                    break;
                case TransGroups.MONTH: groupTitleFc = item =>
                    item.transactionMoment.get('year') === DateTimeUtil.getNow().get('year') ?
                        item.transactionMoment.format("MMMM") : item.transactionMoment.format("MMMM YYYY");
                    break;
                default: groupTitleFc = item => {
                    const ageInHours = moment.duration(item.transactionMoment.diff(DateTimeUtil.getNow())).asHours();
                    // ageInHours < -0.01 insead of < 0 to give a tolerance to put a trip on the past, instead of in Departing soon
                    // This avoids an issue with dummyNowTrans falling into Recent category, while we prefer it to fall in "Departing soon".
                    return ageInHours < -3 ? "More than 3hs ago" : ageInHours < -0.01 ? "Recent" : (ageInHours < 3 ? "Departing soon" : "In more than 3hs");
                };
            }
            let itemGroups = TransactionsData.instance.getItemGroups(
                transactionsResult.items,
                groupTitleFc);
            // Add an empty group that would contain a booking departing now if it's not already present.
            if (filter.page === 1) {
                const dummyNowTrans = new Transaction();
                dummyNowTrans.timestamp = DateTimeUtil.getNow().valueOf() / 1000;
                const sortAscending = !filter.sortOrder || filter.sortOrder === SortOrder.ASC;
                for (let i = 0; i < itemGroups.length; i++) {
                    if (itemGroups[i].items.length > 0 &&
                        (sortAscending ?
                            itemGroups[i].items[0].timestamp > dummyNowTrans.timestamp :   // First trans after now
                            itemGroups[i].items[0].timestamp < dummyNowTrans.timestamp)) { // First trans before now
                        if (itemGroups[i].title !== groupTitleFc(dummyNowTrans) &&   // Group that would contain Now not present
                            (i === 0 || itemGroups[i - 1].title !== groupTitleFc(dummyNowTrans))) {
                            itemGroups.splice(i, 0, new ItemGroup([], groupTitleFc(dummyNowTrans))); // So add it.
                        }
                        break;
                    }
                }
            }
            const firstNonEmptyIndex = itemGroups.findIndex(group => group.items.length > 0);
            transactions =
                <div>
                    {itemGroups.map((bookingsGroup: ItemGroup<Transaction>, i: number) => {
                        return (
                            <div className={classes.group} key={i}>
                                <h3>{bookingsGroup.title}</h3>
                                {renderTransactions(bookingsGroup.items, i === firstNonEmptyIndex ? true : undefined)}
                            </div>
                        );
                    })}
                </div>
        } else {
            transactions = renderTransactions(transactionItems, true);
        }
    }
    const content = (
        <MemoScroll id={"moneyTransList"} style={{ overflowY: 'auto', flexGrow: 1 }} ref={memoScrollRef}>
            <div className={classes.transHeader}>
                {showFilters &&
                    <div className={classes.filterContainer}>
                        <div className={classes.filters}>
                            {showGroupBy &&
                                <div className={classes.groupBy}>
                                    <label htmlFor="group" className={classNames(genStyles.charSpace, genStyles.nowrap)}>Group by</label>
                                    <DefaultSelect
                                        options={getGroupByOptions()}
                                        value={filter.groupBy}
                                        onChange={(groupCriterion) => {
                                            const newFilter = Util.iAssign(filter, { groupBy: groupCriterion });
                                            props.onFilterChange && props.onFilterChange(newFilter);
                                        }}
                                        forFilter={true}
                                        allOptionsLabel={'None'}
                                    />
                                </div>}
                        </div>
                        {showDateRangePicker &&
                            <DateRangePicker
                                value={filter.range}
                                onChange={handleDateRangeChange}
                            />}
                    </div>}
                <div className={classes.subFilterContainer}>
                    {props.onExportData &&
                        <ExportButton filter={props.filter} onExportData={props.onExportData} helpTooltip={EXPORT_TRANSACTIONS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => props.onFilterChange &&
                                    props.onFilterChange(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {props.onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={props.onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
            </div>
            <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.spaceBetween)}>
                <div className={classNames(classes.transContainer, !!pageCount.current && (pageCount.current > 1) && classes.minHeight)}>
                    {transactions}
                    {transactionsResult && transactionsResult.waitingMore &&
                        <div className={classes.waitingMore}><Loading /></div>}
                </div>
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </MemoScroll>
    );
    return (!asView ? content :
        <ViewRestyled
            title={"Money Transactions"}
        >
            {content}
        </ViewRestyled >
    );
}

const MoneyTransactionsViewStyled = withStyles(MoneyTransactionsView, moneyTransactionsViewJss);

export const MoneyTransactionsViewWithData = withAsyncDataObs(MoneyTransactionsViewStyled,
    (query: { filter: Filter }) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
                    * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
                        * All this won't be necessary if orgs come with the admin profile at the login.
                        */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>
            TransactionsData.instance.watchQuery({ ...query.filter, type: TransType.MONEY })
                .pipe(map((result?: ItemsResult<Transaction>) => ({ transactionsResult: result }))) as Observable<{ transactionsResult?: ItemsResult<Transaction> }>))
            .pipe(concatAll() as any) as any
);

const moneyTransactionsViewId = "MONEY_TRANSACTIONS_VIEW";

export const MONEY_TRANSACTIONS_VIEW_PATH = ["*/moneytrans/filter/:filter", "*/moneytrans"];

export const MONEY_TRANSACTIONS_VIEW: IViewRouteConfig<{ filter?: Filter }> =
{
    path: MONEY_TRANSACTIONS_VIEW_PATH,
    propsToPath: ({ filter }) => "/moneytrans" +
        (filter ? "/filter/" + JSON.stringify(Util.serialize(filter)) : ""),
    propsFromMatch: (match, profile) => {
        const filter = Util.deserialize(match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            JSON.parse(match.params.filter) :
            {
                pageSize: profile.pageSizeByView.get(moneyTransactionsViewId) ?? 10,
                page: 1,
                range: moment.range(DateTimeUtil.getNowDate().add(-15, 'days'), DateTimeUtil.getNowDate()),
                // Set filter to booking transactions by default.
                type: TransType.MONEY,
                groupBy: "Day",
                sortOrder: SortOrder.DESC
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { filter: filter };
    },
    navLabel: () => "Money Transactions",
    render: ({ viewProps, navHistory, profile, onProfileChange, justData }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(moneyTransactionsViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(MONEY_TRANSACTIONS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        // Reset page number after a reload. 
        // ~~Avoid reset if just data, since it means other view is on top, and the filter update will break the url.~~ Implemented navHistory.replace2
        // which replace the view passed, preserving the url part of those on top. Also, when PART_DETAIL_VIEW_MODAL dialog is on top, then justData is false
        // since the view is still rendered (the dialog doesn't cover the view completly).        
        if (filter.page > 1 && TransactionsData.instance.isEmpty() && !justData) { // !justData, or alternatively, navHistory.viewAt() === MONEY_TRANSACTIONS_VIEW            
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const onExport: (filter: Filter, format: ExportFormat) => Promise<void> = (filter, format) => {
            const csvFormat = adminProfile.appMetadata?.export?.moneyTrans?.csvFormat;
            const csvFields = csvFormat?.map(field => {
                if (typeof field === 'object' && field.trans) {
                    return { label: field.label ?? field.value, value: FormatUtil.csvTransFunction(field.value, field.trans) }
                }
                return field;
            })
            return TransactionsData.instance.getExportData(filter, { limit: 500 }).then((transactions: Transaction[]) => {
                const transactionsJSON = Util.serialize(transactions);
                switch (format) {
                    case "json": return FormatUtil.downloadObjectAsJson(transactionsJSON, "transactions");
                    case "csv": return FormatUtil.downloadObjectAsCSV(transactionsJSON,
                        {
                            exportName: "transactions",
                            fields: csvFields
                        });
                }

            });
        };
        const bookingsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TransactionsData.instance.invalidateTransCache();
            bookingsViewRef.current.refresh(true);
        };
        return (
            <MoneyTransactionsViewWithData
                filter={filter}
                // onSelect={onSelect}
                onFilterChange={onFilterChange}
                onExportData={onExport}
                onRefreshClick={onRefresh}
                ref={bookingsViewRef}
                justData={justData}
            />
        );
    },
    renderDataIfMatch: true
};