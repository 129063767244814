import React, { useContext } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { initiativesTableJss } from "./InitiativesTable.jss";
import Table from "../view/Table";
import Initiative from "../model/Initiative";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import { i18n } from "../i18n/TKI18nConstants";
import { AppContext } from "../app/App";
import { TableSortLabel } from "@mui/material";
import Filter, { SortOrder } from "../data/Filter";
import Util from "../util/Util";

export interface InitiativesTableProps {
    items: Initiative[];
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onEdit?: (org: Initiative) => void;
    onRemove?: (org: Initiative) => void;
}

export interface IProps extends InitiativesTableProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof initiativesTableJss>;

const InitiativesTable: React.FunctionComponent<IProps> = (props: IProps) => {
    const { items, filter, onFilterChange, onEdit, onRemove, classes, appClasses } = props;
    const { clients, selectedClientID, profile: adminProfile } = useContext(AppContext);
    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = props.filter.sortOrder;
            const filterUpdate = Util.iAssign(props.filter,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate)
        }
    }
    return (
        <Table
            tableId={"initiatives"}
            contentSpec={[
                {
                    id: "title",
                    name: "Title",
                    label: (
                        <TableSortLabel
                            active={true}
                            direction={filter.sortOrder || SortOrder.ASC}
                            onClick={handleSortOrderClick}
                        >
                            Name
                        </TableSortLabel>
                    ),
                    cellValue: (value) => value.title
                },
                {
                    id: "description",
                    name: "Description",
                    cellValue: (value) => value.description
                },
                {
                    id: "billingCode",
                    name: i18n.t("Billing.code"),
                    cellValue: (value) => value.billingCode
                },
                {
                    id: "client_app",
                    name: i18n.t("Client.app"),
                    cellValue: item => clients?.find(client => client.clientID === item.clientId)?.clientName ?? item.clientId,
                    available: adminProfile.isSuperApp,
                    visible: !selectedClientID
                },
                {
                    id: "edit",     // I don't want this to be controlled by table config, think how to exclude from there.
                    name: "Edit",
                    label: null,
                    cellValue: (value: Initiative) =>
                        <IconEdit
                            className={classes.iconEdit}
                            onClick={(e) => {
                                onEdit?.(value);
                                e.stopPropagation();
                            }}
                        />,
                    visible: !!onEdit,
                    width: 40
                },
                {
                    id: "remove",     // I don't want this to be controlled by table config, think how to exclude from there.
                    name: "Remove",
                    label: null,
                    cellValue: (value: Initiative) =>
                        value.deletedAt ? null :    // Just allow to remove (disable) if it was not disabled.
                            <IconRemove
                                className={classes.iconEdit}
                                onClick={(e) => {
                                    onRemove?.(value);
                                    e.stopPropagation();
                                }}
                            />,
                    visible: !!onRemove,
                    width: 40
                }
            ]}
            items={items}
            configurable={false}
        />
    );
}

export default withStyles(InitiativesTable, initiativesTableJss);