import React, { useContext, useRef } from "react";
import ItemsResult from "../model/ItemsResult";
import Initiative from "../model/Initiative";
import Filter, { SortOrder } from "../data/Filter";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import Util from "../util/Util";
import Loading from "../view/Loading";
import { ReactComponent as IconAdd } from '../images/ic-add.svg';
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { i18n } from "../i18n/TKI18nConstants";
import ViewRestyled from "../view/ViewRestyled";
import SearchBox, { Item } from "../search/SearchBox";
import PageSizeSelect from "../paginate/PageSizeSelect";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import Paginate from "../paginate/Paginate";
import { initiativesViewJss } from "./InitiativesView.jss";
import { withAsyncDataObs } from "../data/WithAsyncData";
import InitiativesData from "../data/InitiativesData";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import UIUtil from "../util/UIUtil";
import InitiativesTable from "./InitiativesTable";
import { INITIATIVE_EDIT_VIEW, INITIATIVE_NEW_VIEW } from "./EditInitiativeView";
import Tooltip from "../uiutil/Tooltip";
import FormatUtil from "../util/FormatUtil";
import { CheckboxStyled } from "../booking/BookingsView";

export interface InitiativesViewProps {
    data?: ItemsResult<Initiative>;
    filter?: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onCreate?: () => void;
    onEdit?: (org: Initiative) => void;
    onRemove?: (org: Initiative) => void;
    onRefreshClick?: () => void;
}

export interface IProps extends InitiativesViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof initiativesViewJss>;

const InitiativesView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const { data, filter, onFilterChange, onCreate, onEdit, onRemove, onRefreshClick, classes, appClasses } = props;
    const { profile: adminProfile, navHistory, selectedClientID } = useContext(AppContext);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter!, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    const indent = false;
    let content;
    if (!data) {
        content = <Loading />;
    } else if (data.count === 0) {
        content =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        content = <InitiativesTable items={data.items} filter={filter} onFilterChange={onFilterChange} onEdit={onEdit} onRemove={onRemove} />;
    }

    const isAllAppsView = adminProfile.isSuperApp && !selectedClientID;
    const canAdd = !isAllAppsView;
    let addBtn = onCreate &&
        <button onClick={onCreate} className={appClasses.buttonOk} disabled={!canAdd}>
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            {i18n.t("Add.initiative")}
        </button>;
    if (isAllAppsView) {
        addBtn = addBtn &&
            <Tooltip title={"Select a client app on the left nav bar to be able to add an initiative."}>
                <div>
                    {addBtn}
                </div>
            </Tooltip>
    }

    return (
        <ViewRestyled
            title={i18n.t("Initiatives")}
            right={
                <div className={classNames(genStyles.flex, genStyles.alignCenter)} style={{ marginRight: '120px' }}>
                    {addBtn}
                </div>
            }
        >
            <div
                className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}
                style={{ overflow: 'auto' }}
            >
                <div className={classes.filters}>
                    <SearchBox />
                    <div className={classes.disabledFilter}>
                        <label>{FormatUtil.toFirstUpperCase(i18n.t("suspended")) + " only"}</label>
                        <CheckboxStyled
                            checked={filter.disabled}
                            onChange={e => {
                                onFilterChange?.(Util.iAssign(filter, { disabled: e.target.checked }));
                            }}
                        />
                    </div>
                    {/* {onExportData &&
                    <ExportButton filter={filter} onExportData={onExportData} helpTooltip={EXPORT_USERS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />} */}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
                {content}
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </ViewRestyled>
    );
}

const InitiativesViewStyled = withStyles(InitiativesView, initiativesViewJss);

export interface InitiativesViewProps {
    filter?: Filter;
}

export const InitiativesViewWithData = withAsyncDataObs(InitiativesViewStyled,
    (query: Required<Pick<InitiativesViewProps, "filter">>) => {
        return InitiativesData.instance.watchQuery(query.filter)
            .pipe(map((result?: ItemsResult<Initiative>) => ({ data: result }))) as Observable<{ data?: ItemsResult<Initiative>; }>;
    }
);

const initiativesViewId = "INITIATIVES";

export const INITIATIVES_VIEW: IViewRouteConfig<InitiativesViewProps> =
{
    path: ["*/initiatives/filter/:filter", "*/initiatives"],
    propsToPath: ({ filter }) => "/initiatives" +
        (filter ? "/filter/" + Util.stringifyFilter(filter) : ""),
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(initiativesViewId) ?? 10,
                page: 1,
                sortOrder: SortOrder.ASC
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { filter: filter };
    },
    navLabel: () => i18n.t("Initiatives"),
    render: ({ viewProps, navHistory, profile, onProfileChange, setWaiting, refreshOrgs }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(initiativesViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(INITIATIVES_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        const initiativesViewRef = React.createRef<any>();
        const onRefresh = () => {
            InitiativesData.instance.invalidateCache();
            initiativesViewRef.current.refresh(true);
        };
        const onCreate = () => navHistory.push(INITIATIVE_NEW_VIEW, {});
        const onEdit = (initiative: Initiative) => navHistory.push(INITIATIVE_EDIT_VIEW, { id: initiative.id });
        const onRemove = (initiative: Initiative) => {
            UIUtil.confirmMsg({
                message: 'Confirm to suspend the initiative?',
                onConfirm: async () => {
                    try {
                        setWaiting(true);
                        await InitiativesData.instance.delete(initiative);
                        InitiativesData.instance.invalidateCache();
                        refreshOrgs();
                        await initiativesViewRef.current?.refresh(false);
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        setWaiting(false);
                    }
                }
            });
        }
        return (
            <InitiativesViewWithData
                filter={filter}
                onFilterChange={onFilterChange}
                onCreate={onCreate}
                onEdit={onEdit}
                onRemove={onRemove}
                onRefreshClick={onRefresh}
                ref={initiativesViewRef}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        return {
            fields: [],
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace2(INITIATIVES_VIEW, { filter });
            }
        }
    },
    accessible: ({ profile }) => profile.remote.userData.adminManagement
};