import genStyles from "tripkit-react/dist/css/GenStyle.css";
import { genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const moneyTransactionViewJss = (theme: Theme) => ({
    main: {},
    /**
     * Move styles below to app.jss.ts. It's already being reused by BookingView.jss.ts
     * Nest section, gridSection, entry, field and value below detailForm.
     */
    detailForm: {
        '& > *': {
            marginBottom: '40px'
        }
    },
    section: {
        ...genStyles.flex,
        ...genStyles.column,
        '& > *:not(:last-child)': {
            marginBottom: '40px'
        }
    },
    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },
    value: {
        '&$gridSection': {
            gridRowGap: '30px'
        },
        '&$gridSection, section': {
            marginTop: '10px'
        },
        '& $entry': {
            margin: '0 30px 0 0'
        },
        '& $field': {
            ...theme.textSizeBody,
            ...theme.textColorGray
        },
        '& $value': {
            ...theme.textColorDefault
        }
    },
    externalIcon: {
        height: '18px',
        width: '18px',
        marginLeft: '15px',
        '& path': {
            fill: theme.colorPrimary
        }
    },
    statusValue: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& div': {
            ...genJss.grow
        },
        '& button': {
            marginLeft: '40px'
        }
    }
});