import { Theme } from "../css/Theme";
import { black, genJss, resetJss } from "../css/gen.jss";
import UIUtil from "../util/UIUtil";
import { TransStatus } from "../model/Transaction";
import * as CSS from 'csstype';


function statusColor(status: TransStatus, theme: Theme): CSS.Color {
    switch (status) {
        case TransStatus.REJECTED: return theme.colorError;
        case TransStatus.APPROVED: return theme.colorSuccess;
        case TransStatus.REVERTED: return black(1);
        case TransStatus.PENDING: return theme.colorInfo;
        default: return black();
    }
}

export const statusOptionStyle = (theme: Theme, status?: TransStatus) => ({
    ...theme.textSizeBody,
    ...status && theme.textWeightSemibold,
    ...genJss.flex,
    ...genJss.borderRadius(12),
    color: status ? statusColor(status, theme) : black(),
    background: status ? UIUtil.colorWithOpacity(statusColor(status, theme), .18) : 'none',
    padding: '0 8px'
});

export const ticketsTableJss = (theme: Theme) => ({
    tableContainer: {
        ...genJss.flex,
        '& table': {
            tableLayout: 'fixed',
            width: '100%'
        },
        '& th': {
            boxSizing: 'border-box'
        },
        '& td': {
            wordWrap: 'break-word',
            boxSizing: 'border-box',
            whiteSpace: 'pre-wrap!important'
        }
    },

    iconEdit: {
        ...genJss.svgPathFillCurrColor,
        height: '16px',
        width: '16px',
        cursor: 'pointer',
        color: theme.colorPrimary
    },

    scheduleButton: {
        ...resetJss.button,
        position: 'relative',
        '&:disabled $iconSchedule': {
            opacity: .5
        }
    },

    iconSchedule: {
        ...genJss.svgPathFillCurrColor,
        height: '22px',
        width: '22px',
        color: theme.colorPrimary,
        '& polygon': {
            fill: theme.colorPrimary
        },
        '&:not(:last-child)': {
            background: 'white',
            ...genJss.borderRadius(5)
        },
        '&:not(:first-child)': {
            position: 'absolute',
            top: '5px',
            left: '5px',
            zIndex: -1
        }
    },

    returnButton: {
        ...resetJss.button,
        cursor: 'default',
        height: '40px',
        width: '70px',
        '& svg': {
            height: '22px',
            width: '22px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },

    relatedButton: {
        ...resetJss.button,
        cursor: 'default',
        height: '40px',
        width: '70px',
        '& svg': {
            height: '22px',
            width: '22px',
            '& path': {
                fill: black(1)
            }
        }
    },

    outbound: {
        '& svg': {
            transform: 'rotate(180deg)'
        }
    },

    relatedHighlight: {
        '& svg': {
            width: '26px',
            height: '26px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },

    loading: {
        width: '30px',
        height: '30px'
    },

    error: {
        width: '15px',
        height: '15px',
        '& path': {
            fill: theme.colorError
        }
    },

    statusInDescription: {
        display: 'inline-block'
    },

    transport: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.wrap,
        whiteSpace: 'pre-wrap',
        '& img': {
            marginLeft: '20px'
        }
    },
    charge: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& svg': {
            marginLeft: '5px'
        },
        '& path': {
            fill: theme.colorWarning
        }
    },
    tableSettingsBtn: {
        ...resetJss.button,
        '& svg': {
            height: '20px',
            width: '20px'
        }
    },
    relatedTooltip: {
        padding: '10px',
        '&>*:not(:first-child)': {
            marginTop: '5px'
        }
    },
    descriptionCell: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '&:nth-child(2)': {
            marginLeft: '10px'
        }
    }
});