import React, { Fragment, useContext, useMemo, useRef } from 'react'
import User from "../model/User";
import Util from "../util/Util";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { ValidatorForm } from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import Bundle, { BundleStatus } from "../model/Bundle";
import ChangeBundleView from "./ChangeBundleView";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { editUserViewJss } from "./EditUserView.jss";
import View from "../view/View";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import UIUtil from "../util/UIUtil";
import TransactionsData from "../data/TransactionsData";
import { default as UsersData } from "../data/UsersData";
import { PART_DETAIL_VIEW } from "./UserView";
import withAsycnData from "../data/WithAsyncData";
import BundlesData from "../data/BundlesData";
import { NotificationManager } from 'react-notifications';
import { PART_DETAIL_VIEW_PATH, PARTICIPANTS_VIEW_PATH } from "./UserViewConstants";
import Filter from "../data/Filter";
import ItemsResult from "../model/ItemsResult";
import AppData, { OS } from "../model/AppData";
import { DefaultSelect } from "../rewards/StatusSelect";
import FormatUtil from "../util/FormatUtil";
import { adminProfile } from "../account/AdminProfile";
import { v4 as uuidv4 } from 'uuid';
import { genJss } from '../css/gen.jss';
import Tooltip from '../uiutil/Tooltip';
import { CheckboxStyled } from '../booking/BookingsView';
import { AppContext, getClientIDPath, getOrgIDPath } from '../app/App';
import { descendentOrg, isRootOrg, orgFromId } from '../app/OrgSelectorHelpers';
import Organization from '../model/Organization';
import { i18n } from '../i18n/TKI18nConstants';
import { useInitiativeOptions } from '../data/InitiativesData';
import { useMobilityOptionsForSelect, useProviderOptions, useProviders } from '../data/ProvidersData';

type IStyle = ReturnType<typeof editUserViewJss>;

interface IProps extends WithClasses<IStyle> {
    user?: User;
    bundles?: Bundle[];
    onRequestClose?: (update?: User, another?: boolean, origUser?: User, reset?: () => void) => void;
    appClasses: any;
}

export function getOrgSelectOptions(orgs: Organization[]) {
    return orgs.filter(o => !isRootOrg(o))
        ?.map(org => ({ value: org.id, label: org.name, organization: org }))
        ?.sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));
}

ValidatorForm.addValidationRule('emailOrPhoneRequired', (user: User) => user.email || user.phoneNumber);

export const PUBLIC_RIDER_NOTES_LABEL = "Public Rider Notes";
export const INTERNAL_RIDER_NOTES_LABEL = "Internal Rider Notes";

const EditUserView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { user, bundles, onRequestClose, classes, appClasses } = props;
    const { selectedClientID } = useContext(AppContext);

    const [update, setUpdate] = React.useState<User>(() => {
        const update = user ? Util.deepClone(user) : new User();
        if (!user) {
            update.password = uuidv4();
            update.appData = Util.iAssign(new AppData(), { oS: OS.iOS })
            const selectedOrg = adminProfile.orgs && getOrgIDPath() && orgFromId(getOrgIDPath(), adminProfile.orgs);
            // Add by default the currently selected organization.            
            if (selectedOrg && selectedOrg.fullAccess) {
                update.appData.organizations = [selectedOrg.id];
            }
        }
        return update;
    });
    const [editingFutureBundle, setEditingFutureBundle] = React.useState<boolean>(false);

    const formRef = useRef<any>(null);
    const userTypeOptions = useMemo(() => adminProfile.userTypes.map((type: string) => ({ value: type, label: FormatUtil.toFirstUpperCase(type) })), []);
    const mobilityOptions = useMobilityOptionsForSelect();
    const orgOptions = useMemo(() => getOrgSelectOptions(adminProfile.orgs
        ?.filter(o => o.fullAccess && (!o.clientId || o.clientId === (update.clientId ?? getClientIDPath())) && !adminProfile.orgs?.some(o1 => o1 !== o && descendentOrg(o1, o))) ?? []), []);
    const oSOptions = Object.values(OS).map((value: string) => ({ value: value, label: value }));
    const exclusiveModeOptions = useProviderOptions({ clientId: user?.clientId ?? selectedClientID, modeLabel: true })
        ?.filter(option => option.provider.isExclusive);
    function onSaveClick(another?: boolean) {
        formRef.current.isFormValid(false)
            .then((valid: boolean) => {
                if (valid) {
                    onRequestClose?.(update, another, user, reset);
                }
            });
    }

    function onCancelClick() {
        onRequestClose?.();
    }

    function onEditFutureBundle(edit: boolean) {
        setEditingFutureBundle(edit);
    }

    function reset() {
        const user = new User();
        user.name = "";
        user.email = "";
        user.password = uuidv4();
        user.cardNumber = "";
        user.appData = new AppData();
        user.appData.externalId = "";
        user.appData.oS = OS.iOS;
        user.futureBundle = null;
        setUpdate(user);
        setEditingFutureBundle(false);
    }

    const newUser = !user;
    const currentBundle = bundles!.find((bundle: Bundle) => !!update.currentBundle && update.currentBundle.id === bundle.id)!;
    const providers = useProviders();
    const openModeOptions = useProviderOptions({ clientId: user?.clientId ?? selectedClientID, adminProfile, modeLabel: true })
        ?.filter(option => !option.provider.isExclusive);
    const initiativeOptions = useInitiativeOptions(user?.clientId ?? selectedClientID);
    return (
        <View
            title={(user ? i18n.t("Edit.user") : i18n.t("New.user"))}
        >
            <ValidatorForm
                onSubmit={() => {
                    console.log("callback");
                }}
                instantValidate={false}
                ref={formRef}
                className={genStyles.grow}
            >
                <div className={classNames(appClasses.form, classes.narrowForm)}>
                    {/*<h2>Personal Info</h2>*/}
                    <div className={appClasses.formGroup}>
                        <label>First Name</label>
                        <Validator
                            value={update.givenName}
                            name="firstName"
                            validators={[]}
                            errorMessages={[]}
                            resetOnValid
                        >
                            {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                return (
                                    <div className={classNames(appClasses.value, genStyles.grow)}>
                                        <input
                                            ref={params.inputRefCallback}
                                            value={params.value || ""}
                                            onChange={(event) => {
                                                setUpdate(Util.iAssign(update, { givenName: event.target.value || null }));
                                            }}
                                            placeholder="e.g. John"
                                            className={genStyles.grow}
                                            key="1"
                                        />
                                        {params.errorMessage && <div className={appClasses.validationError} key="2">{params.errorMessage}</div>}
                                    </div>
                                )
                            }}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label>Last Name</label>
                        <Validator
                            value={update.surname}
                            name="lastName"
                            validators={[]}
                            errorMessages={[]}
                            resetOnValid
                        >
                            {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                return (
                                    <div className={classNames(appClasses.value, genStyles.grow)}>
                                        <input
                                            ref={params.inputRefCallback}
                                            value={params.value || ""}
                                            onChange={(event) => {
                                                setUpdate(Util.iAssign(update, { surname: event.target.value || null }));
                                            }}
                                            placeholder="e.g. Doe"
                                            className={genStyles.grow}
                                            key="1"
                                        />
                                        {params.errorMessage && <div className={appClasses.validationError}
                                            key="2">{params.errorMessage}</div>}
                                    </div>
                                )
                            }}
                        </Validator>
                    </div>
                    {userTypeOptions.length > 0 &&
                        <div className={appClasses.formGroup}>
                            <label>User type</label>
                            <DefaultSelect
                                options={userTypeOptions}
                                value={update.appData && update.appData.userType || adminProfile.userTypes[0]}
                                onChange={(type) => {
                                    const newUpdate = Util.deepClone(update);
                                    if (!newUpdate.appData) {
                                        newUpdate.appData = new AppData();
                                    }
                                    newUpdate.appData.userType = type;
                                    setUpdate(newUpdate);
                                }}
                            />
                        </div>}
                    {(adminProfile.features.editUserInfo23131 ? true : !!newUser) &&
                        <div className={appClasses.formGroup}>
                            <label>Email</label>
                            <div className={classNames(appClasses.value, genStyles.grow)}>
                                <input
                                    value={update.email}
                                    onChange={(event) => {
                                        setUpdate(Util.iAssign(update, { email: event.target.value || undefined }));    // "" -> undefined, so the field is not sent.
                                    }}
                                    placeholder="johndoe@example.com"
                                    className={genStyles.grow}
                                />
                                <Validator
                                    name="email"
                                    value={update}
                                    validators={["emailOrPhoneRequired"]}
                                    errorMessages={["email or phone is required"]}
                                    resetOnValid
                                >
                                    {(params1: { errorMessage: any }) =>
                                        <>
                                            <Validator
                                                value={update.email}
                                                validators={update?.email ? ["isEmail"] : []}
                                                errorMessages={update?.email ? ["email is not valid"] : []}
                                                resetOnValid
                                            >
                                                {(params2: { errorMessage: any }) => {
                                                    return <div className={appClasses.validationError}>{params1.errorMessage ?? params2.errorMessage}</div>
                                                }}
                                            </Validator>
                                        </>
                                    }
                                </Validator>
                            </div>
                        </div>}
                    <div className={appClasses.formGroup}>
                        <label htmlFor="phone">Phone number</label>
                        <div className={appClasses.value}>
                            <input name="phone"
                                type="tel"
                                value={update.phoneNumber || ""}   // #avoid_uncontrolled_to_controlled
                                onChange={(event) => {
                                    setUpdate(Util.iAssign(update, { phoneNumber: event.target.value || null }));
                                }}
                                disabled={adminProfile.features.editUserInfo23131 ? false : (!newUser && !user.email)}
                            />
                            {adminProfile.features.emailOrPhoneRequired &&
                                <Validator
                                    value={update}
                                    name="phone"
                                    validators={["emailOrPhoneRequired"]}
                                    errorMessages={["email or phone is required"]}
                                    resetOnValid
                                >
                                    {(params: { errorMessage: any }) => {
                                        return <div className={appClasses.validationError}>{params.errorMessage}</div>
                                    }}
                                </Validator>}
                        </div>
                    </div>
                    {adminProfile.features.userOS &&
                        <div className={appClasses.formGroup}>
                            <label>OS</label>
                            <DefaultSelect
                                options={oSOptions}
                                value={update.appData && update.appData.oS}
                                onChange={(value) => {
                                    const newUpdate = Util.deepClone(update);
                                    if (!newUpdate.appData) {
                                        newUpdate.appData = new AppData();
                                    }
                                    newUpdate.appData.oS = value;
                                    setUpdate(newUpdate);
                                }}
                                // If editing user, and value is undefined, then leave select empty.
                                allowNoValue={!newUser}
                            />
                        </div>}
                    {false &&
                        <div className={appClasses.formGroup}>
                            <label>Opal card number</label>
                            <Validator
                                value={update.cardNumber}
                                name="cardNumber"
                                validators={[]}
                                errorMessages={[]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    return (
                                        <div className={classNames(appClasses.value, classes.narrow)}>
                                            <input
                                                ref={params.inputRefCallback}
                                                value={params.value}
                                                onChange={(event) => {
                                                    const cardNumberUpdate = event.target.value ? event.target.value : null;
                                                    setUpdate(Util.iAssign(update, { cardNumber: cardNumberUpdate }));
                                                }}
                                                className={genStyles.grow}
                                                key="1"
                                                type="search"
                                            />
                                            {params.errorMessage &&
                                                <div className={appClasses.validationError}
                                                    key="2">{params.errorMessage}</div>}
                                        </div>
                                    )
                                }}
                            </Validator>
                        </div>}
                    {(user?.appData?.mobilityOptions ?? []).length > 0 &&
                        <div className={appClasses.formGroup}>
                            <label>Mobility options</label>
                            <Validator
                                value={(update.appData?.mobilityOptions || []).length > 0 ? update.appData?.mobilityOptions : undefined}
                                validators={adminProfile.features.mobilityOptionsRequired ? ["required"] : []}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any }) =>
                                    <div className={appClasses.value}>
                                        <DefaultSelect
                                            options={mobilityOptions ?? []}
                                            value={update.appData?.mobilityOptions || []}
                                            onChange={(value) => {
                                                const newUpdate = Util.deepClone(update);
                                                if (!newUpdate.appData) {
                                                    newUpdate.appData = new AppData();
                                                }
                                                newUpdate.appData.mobilityOptions = value;
                                                setUpdate(newUpdate);
                                            }}
                                            placeholder={"None"}
                                            isMulti
                                            isSearchable
                                            isDisabled={mobilityOptions === undefined}
                                        />
                                        {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>}
                    {adminProfile.role === "admin" ?
                        <>
                            <div className={appClasses.formGroup}>
                                <Tooltip title={"Exclusive modes available to this particular user in the apps"} className={appClasses.cursorHelp}>
                                    <label className={appClasses.cursorHelp}>Exclusive modes enabled</label>
                                </Tooltip>
                                <div className={classNames(appClasses.value, classes.selectValue)}>
                                    <DefaultSelect
                                        options={exclusiveModeOptions ?? []}
                                        value={update.appData?.enabledModes ?? []}
                                        onChange={(value) => {
                                            const newUpdate = Util.deepClone(update);
                                            if (!newUpdate.appData) {
                                                newUpdate.appData = new AppData();
                                            }
                                            newUpdate.appData.enabledModes = value;
                                            setUpdate(newUpdate);
                                        }}
                                        placeholder={"None"}
                                        isMulti
                                        isSearchable
                                    />
                                </div>
                            </div>
                            {openModeOptions && openModeOptions.length > 0 &&
                                <div className={appClasses.formGroup} style={{ width: 'calc(100vw - 300px)' }}>
                                    <label className={appClasses.cursorHelp} style={genJss.noShrink}></label>
                                    <div style={{ fontSize: '14px' }}>
                                        {"The following non-exclusive modes are enabled to all users: "}
                                        {openModeOptions.map((mode, i) => <Fragment key={i}>{i > 0 ? <span style={{ marginRight: '1ch' }}>,</span> : ""}<span style={{ color: i % 2 === 1 ? '#03645b' : '#795548' }}>{mode.label}</span></Fragment>)}
                                    </div>
                                </div>}
                        </>
                        :
                        adminProfile.isTSPUser && adminProfile.tspMode && providers?.find(p => p.id === adminProfile.tspMode)?.isExclusive &&
                        <div className={appClasses.formGroup}>
                            <Tooltip title={"States if the mode is available to the user in the apps"} className={appClasses.cursorHelp}>
                                <label className={appClasses.cursorHelp}>{providers?.find(p => p.id === adminProfile.tspMode)!.modeName + " mode enabled"}</label>
                            </Tooltip>
                            {exclusiveModeOptions &&
                                <CheckboxStyled
                                    checked={update.appData?.enabledModes?.includes(adminProfile.tspMode)}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        const newUpdate = Util.deepClone(update);
                                        if (!newUpdate.appData) {
                                            newUpdate.appData = new AppData();
                                        }
                                        newUpdate.appData.enabledModes = exclusiveModeOptions.map(modeOpt => modeOpt.value).filter(mode => mode === adminProfile.tspMode ? checked : newUpdate.appData?.enabledModes?.includes(mode));
                                        setUpdate(newUpdate);
                                    }}
                                />}
                        </div>
                    }
                    {adminProfile.features.organizations && !adminProfile.isOrgUser &&
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Organizations")}</label>
                            <div className={classNames(appClasses.value, classes.selectValue)}>
                                <DefaultSelect
                                    options={orgOptions}
                                    value={update.appData?.organizations || []}
                                    onChange={value => {
                                        const prevValue = update.appData?.organizations ?? [];
                                        // Preserve organizations that are not controlled by the admin
                                        const updateValue = prevValue
                                            .filter(o => value.includes(o) || !orgOptions.find(opt => opt.value === o))
                                            .concat(value.filter(o => !prevValue.includes(o)));
                                        const newUpdate = Util.deepClone(update);
                                        if (!newUpdate.appData) {
                                            newUpdate.appData = new AppData();
                                        }
                                        newUpdate.appData.organizations = updateValue;
                                        setUpdate(newUpdate);
                                    }}
                                    placeholder={"None"}
                                    isMulti
                                    isSearchable
                                />
                                {adminProfile.isOrgUser && // Requerido para CBAs
                                    <Validator
                                        value={update.appData?.organizations && update.appData?.organizations.length > 0 ? update.appData?.organizations : undefined}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        resetOnValid
                                    >
                                        {({ errorMessage }) => errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                    </Validator>}
                            </div>
                        </div>}
                    {adminProfile.features.trackTripInitiative22828 &&
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Initiatives")}</label>
                            <div className={classNames(appClasses.value, classes.selectValue)}>
                                <DefaultSelect
                                    options={initiativeOptions ?? []}
                                    value={update.initiatives.map(initiative => initiative.id)}
                                    onChange={value => {
                                        setUpdate(Util.iAssign(update, { initiatives: value.map(initiativeId => initiativeOptions?.find(io => io.value === initiativeId)?.initiative) }));
                                    }}
                                    isSearchable
                                    isDisabled={!initiativeOptions}
                                    isMulti
                                />
                            </div>
                        </div>}
                </div>
                {adminProfile.features.internalTripAndRiderNotes22953 &&
                    <div className={appClasses.form}>
                        <div className={appClasses.formGroup} style={{ marginTop: '50px' }}>
                            {/* <Tooltip className={appClasses.cursorHelp} title={"Note to be delivered to the rider."}> */}
                            <label htmlFor="externalNote" className={genStyles.alignSelfStart}>{PUBLIC_RIDER_NOTES_LABEL}</label>
                            {/* </Tooltip> */}
                            <textarea name="externalNote"
                                value={update.publicNote}
                                onChange={(e) => {
                                    setUpdate(Util.iAssign(update, { publicNote: e.currentTarget.value }));
                                }}
                            // placeholder="Enter a note to the rider here..."
                            />
                        </div>
                        {adminProfile.isSuperAdmin &&
                            <div className={appClasses.formGroup}>
                                {/* <Tooltip className={appClasses.cursorHelp}
                            title={"Note just visible to other admins, not to the rider."}> */}
                                <label htmlFor="internalNote" className={genStyles.alignSelfStart}>{INTERNAL_RIDER_NOTES_LABEL}</label>
                                {/* </Tooltip> */}
                                <textarea name="internalNote"
                                    value={update.internalNote}
                                    onChange={(e) => {
                                        setUpdate(Util.iAssign(update, { internalNote: e.currentTarget.value }));
                                    }}
                                // placeholder="Enter an internal note here..."
                                />
                            </div>}
                    </div>}
            </ValidatorForm>
            {editingFutureBundle &&
                <ChangeBundleView
                    bundle={!!update.futureBundle ? update.futureBundle.id : undefined}
                    bundles={bundles!.filter((bundle: Bundle) =>
                        bundle !== currentBundle && bundle.status === BundleStatus.ACTIVE)}
                    onRequestClose={(bundleUpdate?: string) => {
                        onEditFutureBundle(false);
                    }}
                    className={classes.changeBundleView}
                />
            }
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button onClick={onCancelClick} className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <div className={genStyles.flex}>
                    {!user &&
                        <button onClick={() => onSaveClick(true)} className={classNames(appClasses.buttonOk, classes.another)}>
                            Save and add another
                        </button>}
                    <button onClick={() => onSaveClick()} className={appClasses.buttonAdd}>
                        Save
                    </button>
                </div>
            </div>
        </View>
    );
}

const EditUserViewStyled = withStyles(EditUserView, editUserViewJss);

// noinspection JSUnusedLocalSymbols
const NewUserViewWithBundleData = withAsycnData(EditUserViewStyled,
    () => BundlesData.instance.getList(Util.deserialize({ pageSize: 10, page: 1, clientId: getClientIDPath() }, Filter))
        .then((bundles: ItemsResult<Bundle>) => ({ bundles: bundles.items }))
        .catch(() => ({ bundles: [] })) as Promise<any>); // Since it causes an exception: Uncaught (in promise) Unauthorized access when calling BundlesData.instance.getList.
// TODO: clean bundles property, since it's not required on user editing view (check in ODIN). 

export const PART_NEW_VIEW: IViewRouteConfig<{}> =
{
    path: PARTICIPANTS_VIEW_PATH.map(path => path + "/new"),
    propsFromMatch: (match: any) => ({}),
    propsToPath: (props: {}) => "/new",
    navLabel: () => "New User",
    render: ({ navHistory, waitFor, selectedClientID }) => {
        return (
            <NewUserViewWithBundleData
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={(update?: User, another?: boolean, orig?: User, reset?: () => void) => {
                    if (update && !update.id) {
                        update.clientId = selectedClientID;
                        waitFor(UsersData.instance.create(update)
                            .then((newUser: User) => {
                                UsersData.instance.invalidateUserListCaches();
                                if (another) {
                                    reset && reset();
                                } else {
                                    navHistory.replace(PART_DETAIL_VIEW, { id: newUser.id })
                                }
                            }))
                            .catch(UIUtil.errorMessage)    // Catch error and show error message;
                    } else {
                        navHistory.pop();
                    }
                }}
            />
        )
    }
};

const EditUserViewWithUserData = withAsycnData(EditUserViewStyled,
    (query: { id: string }) => UsersData.instance.get(query.id).then((user: User | undefined) => { return { user } }) as Promise<{ user?: User }>);

// noinspection JSUnusedLocalSymbols
const EditUserViewWithData = withAsycnData(EditUserViewWithUserData,
    // () => BundlesData.instance.getList().then((bundles: BundlesResult) => ({bundles})) as Promise<{bundles: Bundle[]}>);
    () => BundlesData.instance.getList(Util.deserialize({ pageSize: 10, page: 1, clientId: getClientIDPath() }, Filter))
        .then((bundles: ItemsResult<Bundle>) => ({ bundles: bundles.items }))
        .catch(() => ({ bundles: [] })) as Promise<any>);   // Catch rejection due to unauthorized access to bundles. TODO: show error.


export const PART_EDIT_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: PART_DETAIL_VIEW_PATH.map(path => path + "/edit"),
    propsFromMatch: (match: any) => ({ id: match.params.id }),
    propsToPath: (props: { id: string }) => "/edit",
    navLabel: () => i18n.t("Edit.user"),
    render: ({ viewProps, navHistory, waitFor }) => {
        return (
            <EditUserViewWithData
                {...viewProps}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={(update?: User, another?: boolean, origUser?: User) => {
                    if (update) {
                        waitFor(UsersData.instance.update(update).then(() => {
                            navHistory.pop();
                            if (update.cardNumber === null && origUser!.cardNumber) { // removed card number
                                UIUtil.confirmMsg({
                                    title: "Remove trips for Opal card",
                                    message: "You removed Opal card '" + origUser!.cardNumber + "' from user " + update.name +
                                        ". Do you want to also remove all user trips associated with the card?",
                                    onConfirm: () => {
                                        UsersData.instance.removeOpalTripsForUser(origUser!.id)
                                            .then(() => {
                                                NotificationManager.info("Opal card trips for " + update.name + " successfully removed", "", 5000);
                                                TransactionsData.instance.invalidateTransCache();
                                                UsersData.instance.invalidateFetchCache();
                                            })
                                            .catch(() => {
                                                NotificationManager.error("Error removing Opal card trips for " + update.name, "", 5000);
                                            });
                                    },
                                    confirmLabel: "Remove trips",
                                    cancelLabel: "Keep trips",
                                    closeOnEscape: false,
                                    closeOnClickOutside: false
                                });

                            }
                        }))
                            .catch(UIUtil.errorMessage)    // Catch error and show error message;
                    } else {
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};