import React from "react";
import Transaction from "../model/Transaction";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bookingTableJss } from "./BookingsTable.jss";
import { Theme } from "../css/Theme";
import { TransactionRow } from "./TransactionRow";
import { black } from "../css/gen.jss";
import FormatUtil from "../util/FormatUtil";
import { statusPillBuilder } from "./StatusPill";
import UIUtil from "../util/UIUtil";

type IStyle = ReturnType<typeof bookingTableJss>;

export interface IProps extends WithClasses<IStyle> {
    values: Transaction[];
    isSelectable?: (transaction: Transaction) => boolean;
    onSelect?: (value: Transaction) => void;
    isEditable?: (transaction: Transaction) => boolean;
    onEdit?: (trans: Transaction) => void;
    showEmployee?: boolean;
    showTime?: boolean;
    showType?: boolean;
    showMoney?: boolean;
    showPoints?: boolean;
    pointsAsDeducted?: boolean;
    showProduct?: boolean;
    showStatus?: boolean;
    onEditedTrans?: (update: Transaction) => Promise<void>;
}

const paymentStatusToDisplayString: (status: string) => string = status => FormatUtil.lowdashedCaseToSpaced(status);

const paymentStatusColor = (status: string, theme: Theme) => {
    switch (status) {
        case 'succeeded': return UIUtil.colorWithOpacity(theme.colorSuccess, .2);
        case 'failed': return UIUtil.colorWithOpacity(theme.colorError, .2);
        case 'requires_capture': return UIUtil.colorWithOpacity(theme.colorInfo, .2);
        default: return black(5);
    }
}

export const PaymentStatusPill = statusPillBuilder({
    statusToDisplayString: paymentStatusToDisplayString,
    statusToColor: paymentStatusColor
});

class TransactionsTable extends React.Component<IProps, {}> {

    public static defaultProps = {
        showEmployee: true,
        showAdjustedCost: true,
        showTime: true
    };

    public render(): React.ReactNode {
        const appClasses = this.props.appClasses;
        return (
            <Table className={appClasses.table}>
                <colgroup>
                    <col width="7%" />
                    {this.props.showTime &&
                        <col width="10%" />}
                    {this.props.showEmployee &&
                        <col width="15%" />}
                    {this.props.showType &&
                        <col width="10%" />}
                    <col width="30%" />
                    {this.props.showProduct &&
                        <col width="8%" />}
                    <col width="7%" />
                    {this.props.showMoney &&
                        <col width="8%" />}
                    {this.props.showPoints &&
                        <col width="7%" />}
                    {this.props.showStatus &&
                        <col width="3%" />}
                    <col width="7%" />
                </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell className={appClasses.field}>Date</TableCell>
                        {this.props.showTime &&
                            <TableCell className={appClasses.field}>Time</TableCell>}
                        {this.props.showEmployee &&
                            <TableCell className={appClasses.field}>User</TableCell>}
                        {this.props.showType &&
                            <TableCell className={appClasses.field}>Type</TableCell>}
                        <TableCell className={appClasses.field}>Description</TableCell>
                        {this.props.showProduct &&
                            <TableCell className={appClasses.field}>Product</TableCell>}
                        {this.props.showMoney &&
                            <TableCell className={appClasses.field}>
                                $
                                <span style={{ fontWeight: 'normal', marginLeft: '1ch' }}>(estimate)</span>
                            </TableCell>}
                        {this.props.showPoints &&
                            <TableCell className={appClasses.field}>{"Points" + (this.props.pointsAsDeducted ? " deducted" : "")}</TableCell>}
                        {this.props.showStatus &&
                            <TableCell className={appClasses.field}>Status</TableCell>}
                        <TableCell className={appClasses.field} />
                        <TableCell className={appClasses.field} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.values.map((transaction: Transaction, i: number) => {
                        // noinspection TypescriptExplicitMemberType
                        const { values, ...rowProps } = this.props;
                        return <TransactionRow value={transaction} {...rowProps} key={i} />;
                    })}
                </TableBody>
            </Table>
        );
    }

}

export default withStyles(TransactionsTable, bookingTableJss);