import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const ticketsViewJss = (theme: Theme) => ({
    view: {
        paddingBottom: '0!important',
        '& h1': {
            marginBottom: '10px'
        }
    },

    transHeader: {
        ...genJss.flex,
        ...genJss.column,
        position: 'sticky',
        left: 0,
        zIndex: 2
    },

    filters: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.wrap,
        '& > *': {
            marginRight: '20px',
            width: '300px'
        }
    },

    groupBy: {
        ...genJss.flex,
        ...genJss.alignCenter,
        width: '230px'
    },

    filterContainer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '3px',
        '& > *': {
            paddingTop: '13px'
        }
    },

    subFilterContainer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        margin: '20px 14px 0 10px'
    },

    filterSelect: {
        '&>*': {
            width: 'initial!important',
            minWidth: '200px'
        }
    },

    transContainer: {
        ...genJss.flex,
        ...genJss.column,
        marginTop: '20px'
    },

    minHeight: {
        // minHeight: '685px'  // Fixed table container min height to try to avoid page navigator to change position
    },                      // between pages. This is hardcoded for pageSize = 10. Part of 'uniforming table' strategy.

    group: {
        ...genJss.flex,
        ...genJss.column,
        '&:not(:first-child)': {
            marginTop: '20px'
        }
    },

    waitingMore: {
        ...genJss.alignSelfCenter,
        marginTop: '20px'
    },

    noResults: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.alignCenter,
        marginLeft: '15px',
        ...theme.textColorGray
    },

    paginatePanel: {
        position: 'sticky',
        left: 0
    }
});