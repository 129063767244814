import React from "react";
import TransactionsData from "../data/TransactionsData";
import withAsycnData from "../data/WithAsyncData";
import Transaction, { ActionType } from "../model/Transaction";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import PriceChangeView from "./PriceChangeView";
import { PRICE_CHANGE_VIEW_PATH } from "./TransactionsViewConstants";

export type PriceChangeActionType = Extract<ActionType, "UPDATE" | "REQUEST" | "RESPOND">;

const PriceChangeViewData = withAsycnData(PriceChangeView,
    (query: { bookingId: string }) => TransactionsData.instance.get(query.bookingId)
        .then((booking: Transaction | undefined) => ({ booking }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ booking?: Transaction, error?: Error }>);

type PriceChangeViewProps = {
    bookingId: string;
    priceAction?: PriceChangeActionType;
};

// noinspection JSUnusedLocalSymbols
export const PRICE_CHANGE_VIEW: IViewRouteConfig<PriceChangeViewProps> =
{
    path: PRICE_CHANGE_VIEW_PATH,
    propsFromMatch: (match: any) => {
        return ({ bookingId: match.params.bookingId, priceAction: match.params.priceAction });
    },
    propsToPath: ({ bookingId, priceAction }) => `/priceChange/${bookingId}` + (priceAction ? `/${priceAction}` : ""),
    navLabel: () => "Price Change",
    render: ({ viewProps, navHistory }) => {
        return (
            <PriceChangeViewData
                bookingId={viewProps.bookingId}
                priceActionType={viewProps.priceAction}
                onRequestClose={() => {
                    navHistory.pop();
                }}
                renderWhenData={true}
            />
        );
    },
    isModal: true
};