import React, { useContext, useRef } from "react";
import ItemsResult from "../model/ItemsResult";
import Filter, { SortOrder } from "../data/Filter";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { AppContext, getClientIDPath } from "../app/App";
import Util from "../util/Util";
import Loading from "../view/Loading";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { i18n } from "../i18n/TKI18nConstants";
import ViewRestyled from "../view/ViewRestyled";
import SearchBox, { Item } from "../search/SearchBox";
import PageSizeSelect from "../paginate/PageSizeSelect";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import Paginate from "../paginate/Paginate";
import { providersViewJss } from "./ProvidersView.jss";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import ProvidersData from "../data/ProvidersData";
import Provider from "../model/Provider";
import Table from "../view/Table";
import TableSortLabel from "@mui/material/TableSortLabel/TableSortLabel";
import { PROVIDER_VIEW } from "./ProviderView";
import AdminProfile from "../account/AdminProfile";

export interface ProvidersViewProps {
    data?: ItemsResult<Provider>;
    filter?: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onRefreshClick?: () => void;
}

export interface IProps extends ProvidersViewProps, WithClasses<IStyle> { }

type ProvidersTableColId = "title" | "client_app" | "address" | "phone" | "website";

/**
 * Order is relevant, too. Notice the order of charge column changes depending on the tableId.
 * No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(adminProfile: AdminProfile): ProvidersTableColId[] {
    const dafaultColumns: ProvidersTableColId[] = [
        "title",
        "client_app",
        "address",
        "phone",
        "website"
    ];
    return dafaultColumns;
}

type IStyle = ReturnType<typeof providersViewJss>;

const ProvidersView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const { data, filter, onFilterChange, onRefreshClick, classes, appClasses } = props;
    const { profile: adminProfile, selectedClientID, clients, navHistory } = useContext(AppContext);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter!, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = filter!.sortOrder;
            const filterUpdate = Util.iAssign(props.filter!,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate)
        }
    }

    let content;
    if (!data) {
        content = <Loading />;
    } else if (data.count === 0) {
        content =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        content =
            <Table
                tableId={"providers"}
                contentSpec={[
                    {
                        id: "title",
                        name: "Title",
                        label: (
                            <TableSortLabel
                                active={true}
                                direction={filter.sortOrder || SortOrder.ASC}
                                onClick={handleSortOrderClick}
                            >
                                Name
                            </TableSortLabel>
                        ),
                        cellValue: (value) => (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                {value.name}
                                {value.disabled ? <div style={{ color: 'gray', fontStyle: 'italic' }}>disabled</div> : null}
                            </div>
                        )
                    },
                    {
                        id: "client_app",
                        name: i18n.t("Client.app"),
                        cellValue: item => clients?.find(client => client.clientID === item.clientId)?.clientName ?? item.clientId,
                        available: adminProfile.isSuperApp,
                        visible: !selectedClientID
                    },
                    {
                        id: "address",
                        name: "Address",
                        cellValue: (value) => value.info.companyInfo?.address
                    },
                    {
                        id: "phone",
                        name: "Phone",
                        cellValue: (value) => {
                            const phone = value.info.companyInfo?.phone;
                            return phone &&
                                <a href={`tel:${phone}`} className={appClasses.link}>{phone}</a>;
                        }
                    },
                    {
                        id: "website",
                        name: "Website",
                        cellValue: (value) => {
                            const websiteS = value.info.companyInfo?.website;
                            let websiteURL: URL | undefined;
                            try {
                                websiteURL = (new URL(websiteS));
                            } catch (e) {
                                console.log(e);
                            }
                            return websiteURL ? <a href={websiteURL.toString()} className={appClasses.link}>{websiteURL.hostname}</a> : websiteS;
                        }
                    }
                ]}
                defaultColumns={getDefaultColumns(adminProfile)}
                items={data.items}
                configurable={true}
                onClick={admin => {
                    navHistory.push(PROVIDER_VIEW, { id: admin.id });
                }}
            />;
    }

    return (
        <ViewRestyled
            title={i18n.t("Providers")}
        >
            <div
                className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}
                style={{ overflow: 'auto' }}
            >
                <div className={classes.filters}>
                    <SearchBox />
                    {/* {onExportData &&
                    <ExportButton filter={filter} onExportData={onExportData} helpTooltip={EXPORT_USERS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />} */}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
                {content}
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </ViewRestyled>
    );
}

const ProvidersViewStyled = withStyles(ProvidersView, providersViewJss);

export interface ProvidersViewProps {
    filter?: Filter;
}

export const ProvidersViewWithData = withAsyncDataObs(ProvidersViewStyled,
    (query: Required<Pick<ProvidersViewProps, "filter">>) => {
        return ProvidersData.instance.watchQuery(query.filter)
            .pipe(map((result?: ItemsResult<Provider>) => ({ data: result }))) as Observable<{ data?: ItemsResult<Provider>; }>;
    }
);

const providersViewId = "PROVIDERS";

export const PROVIDERS_VIEW: IViewRouteConfig<ProvidersViewProps> =
{
    path: ["*/providers/filter/:filter", "*/providers"],
    propsToPath: ({ filter }) => "/providers" +
        (filter ? "/filter/" + Util.stringifyFilter(filter) : ""),
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(providersViewId) ?? 10,
                page: 1,
                sortOrder: SortOrder.ASC
            }, Filter);
        const clientID = getClientIDPath();
        if (clientID) {
            filter.clientId = clientID;
        }
        return { filter: filter };
    },
    navLabel: () => i18n.t("Providers"),
    render: ({ viewProps, navHistory, profile, onProfileChange, setWaiting, refreshOrgs }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(providersViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(PROVIDERS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        const providersViewRef = React.createRef<any>();
        const onRefresh = () => {
            ProvidersData.instance.invalidateCache();
            providersViewRef.current.refresh(true);
        };
        return (
            <ProvidersViewWithData
                filter={filter}
                onFilterChange={onFilterChange}
                onRefreshClick={onRefresh}
                ref={providersViewRef}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        return {
            fields: [],
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace2(PROVIDERS_VIEW, { filter });
            }
        }
    }
};