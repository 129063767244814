import { Theme } from "../css/Theme";
import { black, genJss, resetJss } from "../css/gen.jss";

export const cancelBookingViewJss = (theme: Theme) => ({
    main: {

    },
    imageLabel: {
        textAlign: 'left!important',
        marginBottom: '13px'
    },
    dnDImage: {
        ...genJss.flex,
        width: '100%',
        height: '400px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        marginTop: '20px',
        '&>*:not(:last-child)': {
            marginRight: '20px'
        }
    },
    durationInput: {
        textAlign: 'right',
        '&::placeholder': {
            textAlign: 'left',
        }
    },
    lockPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.alignSelfStretch,
        margin: '22px 0'
    },
    lockLines: {
        ...genJss.alignSelfStretch,
        width: '20px',
        borderTop: '1px solid ' + black(2),
        borderRight: '1px solid ' + black(2),
        borderBottom: '1px solid ' + black(2),
    },
    lockButton: {
        ...resetJss.button,
        width: '20px',
        height: '20px',
        marginLeft: '7px',
        '& svg': {
            width: '100%',
            height: '100%',
            '& path': {
                fill: black(2)
            }
        }
    },
    lock: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginRight: '-25px',
        ...genJss.noShrink,
        '& path': {
            fill: black(2)
        }
    },
    noteFormGroup: {
        marginRight: '30px'
    }
});