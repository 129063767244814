import React from "react";
import TransactionsData from "../data/TransactionsData";
import UsersData from "../data/UsersData";
import withAsycnData from "../data/WithAsyncData";
import Transaction from "../model/Transaction";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import UIUtil from "../util/UIUtil";
import EditStatusView from "./EditStatusView";
import { EDIT_STATUS_VIEW_PATH } from "./TransactionsViewConstants";

const EditStatusViewData = withAsycnData(EditStatusView,
    (query: { bookingId: string }) => TransactionsData.instance.get(query.bookingId)
        .then((booking: Transaction | undefined) => ({ booking }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ booking?: Transaction, error?: Error }>);

// noinspection JSUnusedLocalSymbols
export const EDIT_STATUS_VIEW: IViewRouteConfig<{ bookingId: string }> =
{
    path: EDIT_STATUS_VIEW_PATH,
    propsFromMatch: (match: any) => {
        return ({ bookingId: match.params.bookingId });
    },
    propsToPath: ({ bookingId }) => `/editStatus/${bookingId}`,
    navLabel: () => "Edit status",
    render: ({ viewProps, navHistory }) => {
        return (
            <EditStatusViewData
                bookingId={viewProps.bookingId}
                onRequestClose={updateData => {
                    if (updateData) {
                        const { booking } = updateData;
                        TransactionsData.instance.update(booking!)
                            .then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                booking.relatedBookings.forEach(rb =>
                                    TransactionsData.instance.invalidateTransCacheById(rb.bookingId));
                                // BE bug: the updateBooking always come without the withRelated, though it should come with it when the booking and its related have the same status.                                
                                // const parentView = navHistory.viewAt(-1);
                                // if (parentView.id === userViewId) {
                                //     const userViewMatch = navHistory.getMatchFromViewMap().get(parentView);
                                //     const userId = userViewMatch && parentView.propsFromMatch(userViewMatch, adminProfile).id;
                                //     UsersData.instance.invalidateUserCache(userId)
                                // }
                                booking.userId && UsersData.instance.invalidateUserCache(booking.userId);
                            })
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            })
                    } else {
                        navHistory.pop();
                    }
                }}
                renderWhenData={true}
            />
        );
    },
    isModal: true
};