import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { Theme } from "../css/Theme";
import classNames from "classnames";
import DateTimeUtil from "../util/DateTimeUtil";
import Transaction from "../model/Transaction";
import { genClassNames } from "tripkit-react/dist/css/GenStyle.css";
import LocationUtil from "../util/LocationUtil";
import { genJss } from "../css/gen.jss";
import TransportUtil from "../util/TransportUtil";
import { useProviders } from "../data/ProvidersData";

export const bookingAutocompleteResultJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column
    },
    fromTo: {
        ...genJss.flex,
        ...genJss.column
    },
    iconFromTo: {
        width: '26px',
        height: '26px',
        opacity: '0.6',
        transform: 'rotate(90deg)',
        ...genJss.noShrink,
    },
    timeMode: {
        ...genJss.flex,
        ...genJss.alignCenter,
        whiteSpace: 'nowrap',
        '& > *': {
            ...genJss.noShrink
        }
    }
});

type IStyle = ReturnType<typeof bookingAutocompleteResultJss>;

interface IProps extends WithClasses<IStyle> {
    booking: Transaction;
}

const BookingAutocompleteResult: React.FunctionComponent<IProps> = (props: IProps) => {
    const { booking, classes, appClasses } = props;
    const showGMT = false;
    const depart = booking.departMoment;
    const currentYear = depart.get('year') === DateTimeUtil.getNow().get('year');
    const departS = depart.format((currentYear ? DateTimeUtil.dayMonthFormat() : DateTimeUtil.dateFormat()) + " " + DateTimeUtil.timeFormat() + (showGMT ? " " + DateTimeUtil.toGMTString(depart) : ""))
    const provider = useProviders({ clientId: booking.clientId })?.find(provider => provider.id === booking.mode);
    return (
        <div className={classes.main}>
            <div className={classes.fromTo}>
                <div className={classNames(genClassNames.overflowEllipsis, genClassNames.flex)}>
                    {LocationUtil.getMainText(booking.from)}
                </div>
                <div className={classNames(genClassNames.overflowEllipsis, genClassNames.flex)}>
                    {LocationUtil.getMainText(booking.to)}
                </div>
            </div>
            <div className={classes.timeMode}>
                <div className={classNames(genClassNames.flex, genClassNames.alignCenter)}>
                    {departS}
                </div>
                {provider &&
                    <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} style={{ marginLeft: '10px' }}>
                        <img className={appClasses.modeIcon} style={{ opacity: 0.7, marginRight: '7px', width: '20px', height: '20px' }}
                            src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />
                        {provider.modeName}
                    </div>}
            </div>
        </div>
    );
}

export default withStyles(BookingAutocompleteResult, bookingAutocompleteResultJss);