import React, { useContext, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { adminViewJss } from "./AdminView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import View from "../view/View";
import { AppContext } from "../app/App";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import UIUtil from "../util/UIUtil";
import withAsycnData from "../data/WithAsyncData";
import AdminUser, { clientAppOrgsForAdmin } from "../model/AdminUser";
import AdminUsersData, { LinkAdminUserToOrgInput } from "../data/AdminUsersData";
import { ADMIN_EDIT_VIEW, roleToLabel } from "./EditAdminView";
import SideView from "../view/SideView";
import Loading from "../view/Loading";
import Organization from "../model/Organization";
import AddOrgToAdminView from "./AddOrgToAdminView";
import { adminProfile } from "../account/AdminProfile";
import Tooltip from "../uiutil/Tooltip";
import { i18n } from "../i18n/TKI18nConstants";
import ActionsMenuButton from "../nav/ActionsMenuButton";
import Util from "../util/Util";
import ViewTitleWithId from "../view/ViewTitleWithId";
import Table from "../view/Table";
export interface IProps extends WithClasses<IStyle> {
    admin?: AdminUser;
    onEdit?: () => void;
    onRemove?: () => void;
    onResetPassword?: (admin: AdminUser) => void;
    refresh?: (undefineOnUpdate?: boolean) => Promise<void>
}

type IStyle = ReturnType<typeof adminViewJss>;

const AdminView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { admin, onEdit, onRemove, onResetPassword, classes, appClasses, refresh } = props;
    const [showUserOrgsView, setShowUserOrgsView] = useState<boolean>(false);
    const [showAddOrgView, setShowAddOrgView] = useState<boolean>(false);
    const [editingOrgRelView, setEditingOrgRelView] = useState<Organization | undefined>(undefined);
    const { setWaiting, clients } = useContext(AppContext);
    if (!admin) {
        return <Loading />;
    }
    const onRemoveOrgRel = (org: Organization) => {
        UIUtil.confirmMsg({
            title: 'Confirm to delete',
            onConfirm: async () => {
                try {
                    setWaiting(true);
                    await AdminUsersData.instance.unlinkToOrg({ adminUserId: admin.id, orgId: org.id, clientId: org.clientId! })
                    AdminUsersData.instance.invalidateCacheById(admin.id);
                    await refresh?.(false);
                } catch (e) {
                    console.log(e);
                    UIUtil.errorMessage(e as Error);
                } finally {
                    setShowAddOrgView(false);
                    setWaiting(false);
                }
            }
        });
    }
    const onEditOrgRel = (org: Organization) => {
        setEditingOrgRelView(org);
        setShowAddOrgView(true);
    };
    const addDisabled = admin.organizations.some(org => org.isRoot);
    let addButton =
        <button
            className={appClasses.buttonAdd}
            style={{ marginTop: '20px', marginLeft: '12px' }}
            onClick={() => setShowAddOrgView(true)}
            disabled={addDisabled}
        >
            {"Add"}
        </button>;
    if (addDisabled) {
        addButton =
            <Tooltip title={'Remove access to "All" to be able to add specific client apps.'}>
                {addButton}
            </Tooltip>
    }
    const userOrgsView = showUserOrgsView &&
        <SideView
            title={`${admin.isAdmin ? "Client Apps" : i18n.t("Organizations")}`}
            subtitle={<div style={{ marginTop: '16px' }}>{`of ${admin.name || admin.email || "Admin"}`}</div>}
            onRequestClose={() => setShowUserOrgsView(false)}
        >
            {admin.organizations.length > 0 ?
                <div className={classes.tableContainer}>
                    <Table
                        tableId={"admin-orgs"}
                        contentSpec={[
                            {
                                id: "name",
                                name: "Name",
                                cellValue: (value) => value.isRoot ? "All" : value.name
                            },
                            {
                                id: "notifications",
                                name: "Notifications",
                                cellValue: (value) => admin.orgRelations.find(rel => rel.orgId === value.id)?.notify ? "yes" : "no"
                            },
                            {
                                id: "edit",
                                name: "Edit",
                                label: null,
                                cellValue: (org: Organization) =>
                                    <IconEdit
                                        className={classes.iconEdit}
                                        onClick={(e) => {
                                            onEditOrgRel(org);
                                            e.stopPropagation();
                                        }}
                                    />,
                                visible: !!onEdit,
                                width: 40
                            },
                            {
                                id: "remove",     // I don't want this to be controlled by table config, think how to exclude from there.
                                name: "Remove",
                                label: null,
                                cellValue: (org: Organization) =>
                                    <IconRemove
                                        className={classes.iconEdit}
                                        onClick={(e) => {
                                            onRemoveOrgRel(org);
                                            e.stopPropagation();
                                        }}
                                    />,
                                visible: !!onRemove,
                                width: 40
                            }
                        ]}
                        items={admin.organizations}
                        configurable={false}
                    />
                </div> : <div style={{ padding: '12px 16px' }}>None</div>}
            {addButton}
        </SideView>

    async function linkAdminUserToOrgHandler(update?: LinkAdminUserToOrgInput) {
        const isUpdate = !!editingOrgRelView;
        setEditingOrgRelView(undefined);
        if (!update) {
            setShowAddOrgView(false);
            return;
        }
        try {
            setWaiting(true);
            setShowAddOrgView(false);
            await (isUpdate ? AdminUsersData.instance.updateLinkToOrg(update) : AdminUsersData.instance.linkToOrg(update));
            AdminUsersData.instance.invalidateCacheById(update.adminUserId);
            await refresh?.(false);
        } catch (e) {
            console.log(e);
            UIUtil.errorMessage(e as Error);
        } finally {
            // TODO: When clicking "Save" on edit relation view (AddOrgToAdminView) the call to setWaiting causes AdminView to be re-mounted,
            // losing the editingOrgRelView value, so resetting inputs in AddOrgToAdminView.
            // Workaround for now: close the edit before the waiting (comment next line). Better solution: Move the AddOrgToAdminView to the IViewRouteConfig scheme.
            // setShowAddOrgView(false);
            setWaiting(false);
        }
    }
    const adminClientAppOrgs = clientAppOrgsForAdmin(admin, adminProfile.allOrgs ?? []);
    const addOrgView = showAddOrgView &&
        <AddOrgToAdminView
            admin={admin}
            org={editingOrgRelView}
            onRequestClose={linkAdminUserToOrgHandler}
        />;
    const actionsMenuBtn =
        <div style={{ marginLeft: '50px' }}>
            <ActionsMenuButton
                actions={[
                    ...Util.insertIf(!!onEdit, {
                        label: "Edit",
                        renderIcon: ({ className }) => <IconEdit className={className} style={{ padding: '3px', boxSizing: 'border-box' }} />,
                        onClick: onEdit!
                    }),
                    ...Util.insertIf(!!onResetPassword,
                        {
                            label: "Reset Password",
                            onClick: () => {
                                onResetPassword!(admin);
                            }
                        }),
                    ...Util.insertIf(!!onRemove,
                        {
                            label: "Remove",
                            onClick: onRemove!
                        })
                ]}
            />
        </div>;
    return (
        <View
            title={<ViewTitleWithId title={admin.name || admin.email || "Admin"} more={actionsMenuBtn} />}
            subtitle={roleToLabel(admin.role)}
        >
            <div className={classes.fieldsGrid}>
                <div className={classes.entry}>
                    <div className={classes.field}>Email</div>
                    <div className={classes.value}>
                        {admin.email}
                    </div>
                </div>
                <div className={classes.entry}>
                    <div className={classes.field}>{admin.isAdmin ? "Client Apps" : "Client App"}</div>
                    <div className={classes.value}>
                        {adminClientAppOrgs.map(o => o.isRoot ? "All" : o.name).join(", ")}
                        {admin.isAdmin &&
                            <button className={classes.buttonEditOrgs} onClick={() => setShowUserOrgsView(true)}>
                                <IconEdit className={classes.iconEdit} />
                            </button>}
                    </div>
                </div>
                {admin.isOrg &&
                    <div className={classes.entry}>
                        <div className={classes.field}>{i18n.t("Organizations")}</div>
                        <div className={classes.value}>
                            {admin.organizations.map(org => org.name).join(", ")}
                            <button className={classes.buttonEditOrgs} onClick={() => setShowUserOrgsView(true)}>
                                <IconEdit className={classes.iconEdit} />
                            </button>
                        </div>
                    </div>}
                {admin.isTSP &&
                    <div className={classes.entry}>
                        <div className={classes.field}>Service Provider</div>
                        <div className={classes.value}>
                            {admin.providers.map(provider => provider.name).join(", ")}
                        </div>
                    </div>}
                <div className={classes.entry}>
                    <div className={classes.field}>Admin Management</div>
                    <div className={classes.value}>
                        {admin.adminManagement ? "Yes" : "No"}
                    </div>
                </div>
            </div>
            {userOrgsView}
            {addOrgView}
        </View >
    );
}

const AdminViewStyled = withStyles(AdminView, adminViewJss);

const AdminViewWithData = withAsycnData(AdminViewStyled,
    (query: AdminViewProps) =>
        AdminUsersData.instance.get(query.id)
            .then((admin: AdminUser) => ({ admin })) as Promise<{ admin?: AdminUser }>);

const AdminViewNavWithData = withAsycnData(({ admin }) => <>{admin?.name}</>,
    (query: AdminViewProps) =>
        AdminUsersData.instance.get(query.id)
            .then((admin: AdminUser) => ({ admin })) as Promise<{ admin?: AdminUser }>);

export interface AdminViewProps {
    id: string;
}

export const ADMIN_VIEW: IViewRouteConfig<AdminViewProps> =
{
    path: ["*/adminId/:id"],
    propsToPath: ({ id }) => "/adminId/" + id,
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: ({ viewProps }) => <AdminViewNavWithData id={viewProps.id} />,
    render: ({ viewProps, navHistory, waitFor }) => {
        const onRemove = () => {
            UIUtil.confirmMsg({
                message: 'Confirm to delete',
                onConfirm: () => {
                    waitFor(AdminUsersData.instance.delete(viewProps.id))
                        .then(() => {
                            AdminUsersData.instance.invalidateCache();
                            navHistory.pop();
                        })
                        .catch((error) => {
                            console.log(error);
                            UIUtil.errorMessage(error);
                        });
                }
            });
        };
        const onResetPassword = (admin: AdminUser) => {
            UIUtil.confirmMsg({
                message: 'Confirm to reset password',
                onConfirm: () => waitFor(AdminUsersData.instance.resetPassword(admin.email!))
                    .then(({ result }) => {
                        UIUtil.infoMessage(result);
                    })
                    .catch(UIUtil.errorMessage)    // Catch error and show error message;
            });
        };
        return (
            <AdminViewWithData
                id={viewProps.id}
                onEdit={() => navHistory.push(ADMIN_EDIT_VIEW, { id: viewProps.id })}
                onRemove={onRemove}
                onResetPassword={onResetPassword}
                undefineOnUpdate={false}
            />
        );
    }
};