import React, { useContext } from "react";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { rewardsAdminViewJss } from "./RewardsAdminView.jss";
import { RewardsContext } from "./RewardsProvider";
import { AppContext } from "../app/App";
import { REWARD_EDIT_VIEW } from "./EditRewardView";
import ViewRestyled from "../view/ViewRestyled";

type IStyle = ReturnType<typeof rewardsAdminViewJss>;
interface IProps extends WithClasses<IStyle> { }

const RewardsAdminView: React.FunctionComponent<IProps> = (props: IProps) => {
    const classes = props.classes;
    const appClasses = props.appClasses;
    const { rewards, remove } = useContext(RewardsContext);
    const { navHistory, waitFor } = useContext(AppContext);
    return (
        <ViewRestyled
            title={"Rewards"}
            right={
                <button onClick={() => navHistory.push(REWARD_EDIT_VIEW, {})} className={appClasses.buttonAdd}>
                    Add reward
                </button>
            }
            showNav={false}
        >
            {/* {!rewards ? <Loading /> :
                <div className={classes.main}>
                    {Object.keys(rewards).map((id: string) =>
                        <RewardView reward={rewards[id]}
                            onEditClicked={() => navHistory.push(REWARD_EDIT_VIEW, { id: id })}
                            onRemoveClicked={() =>
                                UIUtil.confirmMsg({
                                    title: 'Confirm to delete',
                                    onConfirm: () => waitFor(remove(rewards[id]))
                                })
                            }
                            key={id}
                        />)}
                </div>} */}
        </ViewRestyled>
    )
};

const RewardsAdminViewStyled = withStyles(RewardsAdminView, rewardsAdminViewJss);


export const REWARDS_ADMIN_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/rewards/admin"],
    propsFromMatch: (match: any) => ({}),
    propsToPath: (props: {}) => "/admin",
    navLabel: () => "Manage rewards",
    render: () => <RewardsAdminViewStyled />
};