import React, { useEffect } from "react";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { default as ReactDateRangePicker } from '../ext_lib/react-daterange-picker/src';
import { Range, Moment } from "moment-timezone";
import classNames from "classnames";
import 'react-daterange-picker/dist/css/react-calendar.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import DateTimeUtil from "../util/DateTimeUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { dateRangePickerJss } from "./DateRangePicker.jss";
import { ReactComponent as CalendarIcon } from "../images/ic-calendar.svg";
import * as moment from 'moment-timezone';
import { black } from "../css/gen.jss";

type IStyle = ReturnType<typeof dateRangePickerJss>;

const TooltipStyled = (props: TooltipProps) =>
    <Tooltip
        {...props}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: "preventOverflow",
                        options: {
                            boundary: "window",
                        },
                    },
                ],
            },
            tooltip: {
                sx: {
                    maxWidth: "initial",
                    color: "red", // Text color
                    backgroundColor: "white", // Background color
                    fontSize: "16px", // Font size
                    border: `1px solid ${black(2)}`, // Optional: border for visibility
                    marginTop: '4px!important',
                    padding: '8px 10px'
                },
            }
        }}
    />;

interface IProps extends WithClasses<IStyle> {
    value: Range;
    min?: Moment;
    onChange: (range: Range) => void;
    renderButton?: (value: Range) => JSX.Element;
}

export function invalidRange(value: Range): boolean {
    return value.start.isAfter(value.end);
}

const DateRangePicker: React.FunctionComponent<IProps> = (props) => {
    const { value, onChange, min = DateTimeUtil.momentTZTime(0), classes, appClasses } = props;
    useEffect(() => {
        function handleClickElsewhere(event: MouseEvent) {
            setVisible(false);
        }
        // Shouldn't be necessary as long as now we cannot get to such a date through the UI. Remove if it causes any issue.
        if (value.start.isBefore(min)) {
            handleRangeChange(moment.range(min, value.end));
        }
        window.addEventListener('click', handleClickElsewhere);
        return () => window.removeEventListener('click', handleClickElsewhere);
    }, []);
    function handleClickInside(event) {
        event.stopPropagation();
    }
    const [visible, setVisible] = React.useState<boolean>(false);
    const [startInputValue, setStartInputValue] = React.useState<string>(value.start.format(DateTimeUtil.HTML5_DATE_FORMAT));
    const [endInputValue, setEndInputValue] = React.useState<string>(value.end.format(DateTimeUtil.HTML5_DATE_FORMAT));
    // Switch input range to browser's timezone while preserving it's formatted string (i.e. both dates will
    // display the same when formatted, but the transformed one is in browser's timezone), since
    // react-daterange-picker seem to be hardcoded to browser's timezone, and so passing dates in other timezones
    // cause, for instance, that range end date is not included in the painted range.
    const valueTimezone = moment.range(
        DateTimeUtil.moment(value.start.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT)),
        DateTimeUtil.moment(value.end.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT))
    );
    const handleRangeChange = (range: Range) => {
        setStartInputValue(range.start.format(DateTimeUtil.HTML5_DATE_FORMAT));
        setEndInputValue(range.end.format(DateTimeUtil.HTML5_DATE_FORMAT));
        onChange(range);
    }
    useEffect(() => {
        // If the value changes (from outside, e.g. click on 'All' bookings, which resets the filter), update the input values.
        if (value.start.format(DateTimeUtil.HTML5_DATE_FORMAT) !== startInputValue) {
            setStartInputValue(value.start.format(DateTimeUtil.HTML5_DATE_FORMAT));
        }
        if (value.end.format(DateTimeUtil.HTML5_DATE_FORMAT) !== endInputValue) {
            setEndInputValue(value.end.format(DateTimeUtil.HTML5_DATE_FORMAT));
        }
    }, [value.start.valueOf(), value.end.valueOf()]);
    const isValidRange = !invalidRange(value);
    const calendarBtn =
        <button
            onClick={(e) => {
                setVisible(!visible);
                handleClickInside(e);
            }}
            className={classes.calendarBtn}
        >
            <CalendarIcon />
        </button>;
    const isStartToday = DateTimeUtil.moment(startInputValue).format("DD/MM/YYYY") === DateTimeUtil.getNow().format("DD/MM/YYYY");
    const isEndToday = DateTimeUtil.moment(endInputValue).format("DD/MM/YYYY") === DateTimeUtil.getNow().format("DD/MM/YYYY");
    const inputs =
        <div className={classNames(classes.button, !isValidRange && classes.invalidRange)}>
            <div className={classes.inputContainer}>
                <input
                    type={"date"}
                    value={startInputValue}
                    onChange={(event) => {
                        const valueS = event.target.value;
                        setStartInputValue(valueS);
                        const valueMoment = DateTimeUtil.momentDefaultTZ(valueS);
                        if (valueMoment.isValid() && !valueMoment.isBefore(min)) {
                            handleRangeChange(moment.range(
                                valueMoment,
                                DateTimeUtil.momentDefaultTZ(value.end.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT))
                            ));
                        }
                    }}
                    onFocus={() => setVisible(true)}
                    onClick={handleClickInside}
                    onBlur={() => setStartInputValue(value.start.format(DateTimeUtil.HTML5_DATE_FORMAT))}
                    className={classNames(classes.dateInput, appClasses.focusGlow)}
                />
                {isStartToday && <div className={classes.today}>Today</div>}
            </div>
            <div className={classes.separator}>
                -
            </div>
            <div className={classes.inputContainer}>
                <input
                    type={"date"}
                    value={endInputValue}
                    onChange={(event) => {
                        const valueS = event.target.value;
                        setEndInputValue(valueS);
                        const valueMoment = DateTimeUtil.momentDefaultTZ(valueS);
                        if (valueMoment.isValid()) {
                            handleRangeChange(moment.range(
                                DateTimeUtil.momentDefaultTZ(value.start.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT)),
                                valueMoment
                            ));
                        }
                    }}
                    onFocus={() => setVisible(true)}
                    onClick={handleClickInside}
                    onBlur={() => setEndInputValue(value.end.format(DateTimeUtil.HTML5_DATE_FORMAT))}
                    className={classNames(classes.dateInput, appClasses.focusGlow)}
                />
                {isEndToday && <div className={classes.today}>Today</div>}
            </div>
            {calendarBtn}
        </div >
    return (
        <TooltipStyled
            placement="bottom"
            title={
                <div onClick={handleClickInside}>
                    <ReactDateRangePicker
                        numberOfCalendars={2}
                        value={valueTimezone}
                        onSelect={(range: Range) => {
                            // ReactDateRangePicker returns moment in browser timezone, instead of the input moment.
                            // TODO: un-hardcode.
                            range.start = DateTimeUtil.momentDefaultTZ(range.start.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT));
                            range.end = DateTimeUtil.momentDefaultTZ(range.end.format(DateTimeUtil.HTML5_DATE_TIME_FORMAT));
                            handleRangeChange(range);
                            setVisible(false);
                        }}
                        className={classes.rangeCalendars}
                    />
                </div>
            }
            open={visible}
        >
            {inputs}
        </TooltipStyled>
    );
}

export default withStyles(DateRangePicker, dateRangePickerJss);