import React, { useContext, useMemo, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { editOrgViewJss } from "./EditOrgView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import View from "../view/View";
import { DefaultSelect } from "../rewards/StatusSelect";
import { AppContext } from "../app/App";
import Organization from "../model/Organization";
import Util from "../util/Util";
import { i18n } from "../i18n/TKI18nConstants";
import { isRootOrg } from "../app/OrgSelectorHelpers";
import { ReactComponent as IconCity } from "../images/ic-city.svg";
import { black, genJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import OrganizationsData, { OtherCreateInput } from "../data/OrganizationsData";
import UIUtil from "../util/UIUtil";
import withAsycnData from "../data/WithAsyncData";
import { CheckboxStyled } from "../booking/BookingsView";
import { OrganizationPath } from "./OrganizationsTable";
import { adminProfile } from "../account/AdminProfile";
import { useInitiativeOptions } from "../data/InitiativesData";

const selectOptionJss = (theme: Theme) => ({
    option: (props) => ({
        ...genJss.flex,
        padding: '8px',
        ...genJss.borderRadius(6),
        '&$selected': {
            backgroundColor: '#ebebeb'
        },
        '&$focused': {
            backgroundColor: black(4)
        },
        '& path, polygon': {
            fill: 'gray'
        }
    }),
    selected: {},
    focused: {}
});

export const OrgSelectOption = withStyles(props => {
    const { isFocused, isSelected, classes, data, innerProps } = props as any;
    return (
        <div className={classNames(classes.option,
            isSelected && classes.selected, !isSelected && isFocused && classes.focused)} {...innerProps}>
            <div>
                <OrganizationPath value={data.organization} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {data.value.isClientApp && <IconCity style={{ marginRight: '10px' }} />}
                    {data.label}
                </div>
            </div>
        </div>
    );
}, selectOptionJss);
export interface EditOrgViewProps {
    org?: Organization;
    onRequestClose: (update?: Organization, otherInput?: OtherCreateInput) => void;
}

export interface IProps extends EditOrgViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof editOrgViewJss>;

const EditOrgView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { org, onRequestClose, classes, appClasses } = props;
    const isCreate = org === undefined;
    const { profile: adminProfile, clients, selectedClientID } = useContext(AppContext);
    const formRef = useRef<any>(null);
    const orgOptions = useMemo(() => {
        let allOrgs = adminProfile.orgs?.filter(o => o.fullAccess) ?? [];
        // Just allow SkedGo devs to create an organization as child of the root org.
        if (!adminProfile.isSkedGoDev && isCreate) {
            allOrgs = allOrgs.filter(o => !isRootOrg(o))
        }
        allOrgs = allOrgs.sort((o1, o2) => {
            return isRootOrg(o1) ? -1 : isRootOrg(o2) ? 1 :
                o1.isClientApp !== o2.isClientApp ?
                    (o1.isClientApp ? -1 : 1) :
                    o1.name.localeCompare(o2.name);
        });
        return allOrgs?.map(org => ({ value: org.id, label: isRootOrg(org) ? org.name + " (root)" : org.name, organization: org }));
    }, [adminProfile.orgs]);
    const [update, setUpdate] = useState<Organization>(org ? Util.deepClone(org) : new Organization());
    const [parent, setParent] = useState<Organization | undefined>((isCreate && org === undefined) ? undefined : adminProfile.allOrgs?.find(o => o.path === org?.path.substring(0, org.path.length - 4)));  // Temporary, then model using update.path        
    const clientOptions = useMemo(() =>
        clients
            // Filter out clients that already have an organization that represents them, except the organization is the current one (this is for editing).
            ?.filter(client => !adminProfile.orgs?.some(o => org?.id !== o.id && o.isClientApp && o.clientId === client.clientID))
            ?.map(client => ({ value: client.clientID, label: client.clientName })) ?? [],
        [clients, parent]);
    const initiativeOptions = useInitiativeOptions(org?.clientId ?? selectedClientID);
    return (
        <View
            title={isCreate ? i18n.t("New.organization") : i18n.t("Edit.organization")}
        >
            <div className={classNames(appClasses.form, classes.narrowForm)}>
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={appClasses.formGroup}>
                        <label>Name</label>
                        <Validator
                            value={update.name}
                            name="newStatus"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, genStyles.grow)}>
                                    <input
                                        // placeholder="e.g. John"
                                        className={genStyles.grow}
                                        value={update.name}
                                        onChange={e => setUpdate(Util.iAssign(update, { name: e.target.value }))}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    <div className={appClasses.formGroup}>
                        <label>Parent</label>
                        <Validator
                            value={parent}
                            name="newStatus"
                            validators={["required"]}
                            errorMessages={["this field is required"]}
                            resetOnValid
                        >
                            {({ errorMessage }) =>
                                <div className={classNames(appClasses.value, classes.orgSelectValue)}>
                                    <DefaultSelect
                                        options={orgOptions}
                                        value={parent?.id}
                                        onChange={orgId => {
                                            const org = orgOptions.find(o => o.value === orgId)?.organization!;
                                            setParent(org);
                                            setUpdate(Util.iAssign(update, {
                                                clientId: org.clientId,
                                                ...!isRootOrg(org) ? { isClientApp: false } : {}    // Reset isClientApp flag if a non root org is selected.
                                            }))
                                        }}
                                        placeholder={"Select"}
                                        allowNoValue={true}
                                        isSearchable
                                        components={{
                                            Option: OrgSelectOption
                                        }}
                                        isDisabled={!isCreate && org?.isClientApp && !adminProfile.isSkedGoDev}
                                    />
                                    {errorMessage &&
                                        <div className={appClasses.validationError}>{errorMessage}</div>}
                                </div>}
                        </Validator>
                    </div>
                    {adminProfile.isSkedGoDev && parent && isRootOrg(parent) &&
                        <div className={appClasses.formGroup}>
                            <label>Is a client app?</label>
                            <CheckboxStyled
                                checked={update.isClientApp}
                                onChange={e => {
                                    const checked = e.target.checked;
                                    setUpdate(Util.iAssign(update, {
                                        isClientApp: checked,
                                        ...!checked ? { clientId: parent.clientId } : {}  // Reset clientId if unchecked.
                                    }));
                                }}
                            />
                        </div>}
                    {adminProfile.isSkedGoDev && parent && isRootOrg(parent) && update.isClientApp &&
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Client.app")}</label>
                            <Validator
                                value={update.clientId}
                                name="clientApp"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {({ errorMessage }) =>
                                    <div className={appClasses.value}>
                                        <DefaultSelect
                                            options={clientOptions}
                                            value={clients?.find(client => client.clientID === update.clientId)?.clientID}
                                            onChange={client => {
                                                setUpdate(Util.iAssign(update, { clientId: client }));
                                            }}
                                            placeholder={"None"}
                                            allowNoValue={true}
                                            isSearchable
                                        />
                                        {errorMessage &&
                                            <div className={appClasses.validationError}>{errorMessage}</div>}
                                    </div>}
                            </Validator>
                        </div>}
                    {/* <div className={appClasses.formGroup}>
                        <label htmlFor="description">Description</label>
                        <textarea
                            name="description"
                        />
                    </div> */}
                    {adminProfile.features.trackTripInitiative22828 &&
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Initiatives")}</label>
                            <div className={classNames(appClasses.value, classes.selectValue)}>
                                <DefaultSelect
                                    options={initiativeOptions ?? []}
                                    value={update.initiatives.map(initiative => initiative.id)}
                                    onChange={value => {
                                        setUpdate(Util.iAssign(update, { initiatives: value.map(initiativeId => initiativeOptions?.find(io => io.value === initiativeId)?.initiative) }));
                                    }}
                                    isSearchable
                                    isDisabled={!initiativeOptions}
                                    isMulti
                                />
                            </div>
                        </div>}
                </ValidatorForm>
            </div>
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button
                    onClick={() => onRequestClose()}
                    className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <button
                    onClick={async () => {
                        const valid = await formRef.current.isFormValid(false)
                        if (valid) {
                            onRequestClose(update, { parentId: parent!.id });
                        }
                    }}
                    className={appClasses.buttonAdd}
                >
                    Save
                </button>
            </div>
        </View>
    );
}

const EditOrgViewStyled = withStyles(EditOrgView, editOrgViewJss);

export const ORGANIZATION_NEW_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/newOrg"],
    propsToPath: ({ }) => "/newOrg",
    propsFromMatch: () => {
        return {};
    },
    navLabel: () => i18n.t("New.organization"),
    render: ({ navHistory, waitFor, refreshOrgs }) => {
        return (
            <EditOrgViewStyled
                onRequestClose={async (update?: Organization, otherInput: OtherCreateInput = {}) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        waitFor(OrganizationsData.instance.create(update, otherInput));
                        OrganizationsData.instance.invalidateCache();
                        refreshOrgs();
                        navHistory.pop();
                        // navHistory.replace(ORGANIZATION_VIEW, { id: newOrganization.id });                        
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                        navHistory.pop();
                    }
                }}
            />
        );
    }
};

export interface OrganizationViewProps {
    id: string;
}

const EditOrganizationWithData = withAsycnData(EditOrgViewStyled,
    async (query: OrganizationViewProps) => {
        const org = await OrganizationsData.instance.get(query.id);
        await adminProfile.orgsP;   // Wait until organizations arrive to render the view.
        return ({ org }) as { org?: Organization; };
    });


export const ORGANIZATION_EDIT_VIEW: IViewRouteConfig<OrganizationViewProps> =
{
    path: ["*/orgId/:id/edit"],
    propsToPath: ({ id }) => `/orgId/${id}/edit`,
    propsFromMatch: (match) => ({ id: match.params.id }),
    navLabel: () => i18n.t("Edit.organization"),
    render: ({ viewProps, navHistory, waitFor, refreshOrgs }) => {
        return (
            <EditOrganizationWithData
                id={viewProps.id}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (update?: Organization, otherInput: OtherCreateInput = {}) => {
                    if (!update) {
                        navHistory.pop();
                        return;
                    }
                    try {
                        await waitFor(OrganizationsData.instance.update(update, otherInput));
                        refreshOrgs();
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        navHistory.pop();
                    }
                }}
            />
        );
    },
    accessible: ({ profile }) => profile.remote.userData.adminManagement
};