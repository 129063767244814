import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const editNoteViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.spaceBetween,
        height: '100%'
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '40px'
    },
    form: {
        minHeight: '250px',
        paddingBottom: '30px'
    },
    radioGroup: {
        '& label': {
            ...genJss.alignSelfStart,
            lineHeight: '42px'
        }
    }
});