import { Theme } from "../css/Theme";
import { appGlobalJss } from "../css/app.jss";
import UIUtil from "../util/UIUtil";
import { genJss } from "../css/gen.jss";

export const exportButtonJss = (theme: Theme) => ({
    exportBtn: {
        ...appGlobalJss(theme).button,
        color: theme.colorPrimary + '!important',
        border: 'none!important',
        background: 'none',
        fontSize: '18px!important',
        height: 'initial!important',
        padding: '0!important',
        '& svg': {
            height: '20px',
            width: '20px'
        }
    },
    tooltip: {
        padding: '0!important',
        background: 'none!important'
    },
    popper: {
        zIndex: 1
    },
    menu: {
        background: 'white',
        border: '1px solid lightgray',
        ...genJss.borderRadius(4),
        minWidth: '70px',
        textAlign: 'center'
    },
    menuItem: {
        padding: '10px',
        cursor: 'pointer',
        color: 'black',
        fontFamily: theme.fontFamily,
        ...theme.textSizeCaption,
        '&:hover': {
            background: UIUtil.colorWithOpacity(theme.colorPrimary, .08)
        }
    }
});
