import ModeIdentifier from "../model/ModeIdentifier";
import Provider from "../model/Provider";
import NetworkUtil from "./NetworkUtil";

class TransportUtil {

    public static readonly SATAPP = "https://api.tripgo.com/v1";

    public static getTransportIconModeId(modeIdentifier: ModeIdentifier, isRealtime = false, onDark = false): string {
        if (modeIdentifier.icon) {
            return NetworkUtil.getServer() + "/modeicons/icon-mode-" + modeIdentifier.icon + ".svg";
        }
        return this.getTransportIconLocal(this.modeIdToIconS(modeIdentifier.identifier), isRealtime, onDark);
    }

    public static getTransportIconFromProvider(provider: Provider): string {
        return this.getTransportIconLocal(this.modeIdToIconS(provider.id), false, false);
    }

    public static getTransportIconLocal(iconS: string, isRealtime = false, onDark = false): string {
        return "/images/modeicons/" + (onDark ? "ondark/" : "") +
            "ic-" + iconS + (isRealtime ? "-realtime" : "") + "-24px.svg";
    }

    public static modeIdToIconS(modeId: string): string {
        switch (modeId) {
            case "pt_pub_bus":
                return "bus";
            case "pt_pub_ferry":
                return "ferry";
            case "pt_pub_metro":
                return "subway";
            case "pt_pub":
                return "publicTransport";
            case ModeIdentifier.SCHOOLBUS_ID:
                return "school-bus";
            case "cy_bic":
                return "bicycle";
            case "wa_wal":
                return "walk";
            case "ps_shu":
                return "shuttle";
            case "me_car-r":
                return "car-share";
            case "ps_tnc_UBER":
                return "car-share";
            case "me_car-s_FLX":
                return "flexicar";
        }
        if (modeId.startsWith("ps_tnc_")) {
            return "car-ride-share";
        }
        if (modeId.startsWith("ps_shu_")) {
            return "shuttle";
        }
        if (modeId.startsWith("ps_drt_")) {
            return "shuttle";
        }
        if (modeId.startsWith("ps_tax")) {
            return "taxi";
        }
        if (modeId.startsWith("cy_bic-s")) {
            return "bicycle-share";
        }
        return "wait";
    }

}

export default TransportUtil;