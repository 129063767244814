import { Theme } from "../css/Theme";
import { appGlobalJss } from "../css/app.jss";
import { black, genJss } from "../css/gen.jss";
import { filterWrapper } from "../rewards/StatusSelect.jss";

// noinspection JSUnusedLocalSymbols
export const bookingsViewJss = (theme: Theme) => ({
    count: {
        color: '#9d9d9d'
    },

    spent: {
        fontSize: '18px'
    },

    subtitle: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& > *': {
            marginRight: '15px'
        }
    },

    filters: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.wrap,
        '& > *': {
            marginRight: '20px',
            marginBottom: '10px'
        }
    },

    groupBy: {
        ...genJss.flex,
        ...genJss.alignCenter,
        width: '240px'
    },

    rangePickerContainer: {
        marginLeft: 'auto',
        marginRight: '0'
    },

    transHeader: {
        ...genJss.flex,
        ...genJss.column,
        position: 'sticky',
        left: 0,
        zIndex: 2
    },

    filterContainer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        ...genJss.alignStart,
        marginTop: '3px',
        '& > *': {
            paddingTop: '16px'
        }
    },

    subFilterContainer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        margin: '20px 14px 0 10px'
    },

    transContainer: {
        ...genJss.flex,
        ...genJss.column,
        marginTop: '20px'
    },

    minHeight: {
        minHeight: '685px'  // Fixed table container min height to try to avoid page navigator to change position
    },                      // between pages. This is hardcoded for pageSize = 10. Part of 'uniforming table' strategy.

    group: {
        ...genJss.flex,
        ...genJss.column,
        '&:not(:first-child)': {
            marginTop: '20px'
        }
    },

    waitingMore: {
        ...genJss.alignSelfCenter,
        marginTop: '20px'
    },

    noResults: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.alignCenter,
        marginLeft: '15px',
        ...theme.textColorGray
    },

    filterSelect: {
        '&>*': {
            width: 'initial!important',
            minWidth: '200px'
        }
    },

    userSearch: {
        ...appGlobalJss(theme).focusGlow,
        ...filterWrapper,
        padding: '6px 10px',
        ...genJss.alignSelfStart,
        '& input': {
            background: 'none',
            border: 'none',
            lineHeight: '28px',
            color: black(),
            fontSize: '15px',
            boxShadow: 'none',
            outline: 'none',
            ...genJss.grow,
            ...theme.textSizeBody,
            padding: 0
        },
        '& input::placeholder': {
            color: black(2)
        }
    }
});