import React from "react";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { modalViewJss } from "./ModalView.jss";
import { ReactComponent as IconRemove } from '../images/ic-cross2.svg';
import Modal from 'react-modal';

interface IProps extends WithClasses<IStyle> {
    title?: string;
    subtitle?: React.ReactNode;
    onRequestClose?: () => void;
    children: React.ReactNode;
}

type IStyle = ReturnType<typeof modalViewJss>;


const ModalView: React.FunctionComponent<IProps> = (props: IProps) => {
    const {
        classes,
        injectedStyles
    } = props;
    return (
        <Modal
            isOpen={true}
            style={{
                content: injectedStyles.modal
            }}
            shouldCloseOnEsc={true}
            onRequestClose={() => props.onRequestClose && props.onRequestClose()}
        >
            <div className={classes.main}>
                {(props.title || props.subtitle || props.onRequestClose) &&
                    <div className={classes.header}>
                        <div className={classes.headerTop}>
                            <div className={classes.title}>
                                {props.title}
                            </div>
                            {props.onRequestClose &&
                                <button onClick={() => props.onRequestClose && props.onRequestClose()} className={classes.btnClear}>
                                    <IconRemove aria-hidden={true}
                                        className={classes.iconClear}
                                        focusable="false" />
                                </button>}
                        </div>
                        {props.subtitle &&
                            <div className={classes.subtitle}>
                                {props.subtitle}
                            </div>}
                    </div>}
                <div className={classes.body}>
                    {props.children}
                </div>
            </div>
        </Modal>
    );
};


export default withStylesO(ModalView, modalViewJss);