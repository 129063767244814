import { black, genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { filterWrapper } from "../rewards/StatusSelect.jss";

export const globalSearchJss = (theme: Theme) => ({
    main: {
        ...filterWrapper,
        padding: '6px 10px',
        '& input': {
            background: 'none',
            border: 'none',
            lineHeight: '28px',
            color: black(),
            fontSize: '15px',
            boxShadow: 'none',
            outline: 'none',
            ...genJss.grow,
            ...theme.textSizeBody,
            padding: 0
        },
        '& input::placeholder': {
            color: black(2)
        }
    },
    iconFromTo: {
        width: '24px',
        height: '24px',
        opacity: '0.6',
        transform: 'rotate(90deg)',
        marginTop: '10px',
        ...genJss.noShrink
    }
});