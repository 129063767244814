import React, { useContext, useRef } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { adminUsersViewJss } from "./AdminUsersView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { i18n } from "../i18n/TKI18nConstants";
import ItemsResult from "../model/ItemsResult";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { from } from "rxjs/internal/observable/from";
import AdminUsersData from "../data/AdminUsersData";
import Filter, { SortOrder } from "../data/Filter";
import AdminUser, { clientAppOrgsForAdmin } from "../model/AdminUser";
import Util from "../util/Util";
import { adminProfile } from "../account/AdminProfile";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { concatAll } from "rxjs/internal/operators/concatAll";
import Table, { ITableColSpec } from "../view/Table";
import Loading from "../view/Loading";
import { ADMIN_VIEW } from "./AdminView";
import SearchBox, { Item } from "../search/SearchBox";
import PageSizeSelect from "../paginate/PageSizeSelect";
import Paginate from "../paginate/Paginate";
import ViewRestyled from "../view/ViewRestyled";
import { ReactComponent as IconAdd } from '../images/ic-add.svg';
import { ADMIN_NEW_VIEW, roleToLabel } from "./EditAdminView";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import { ReactComponent as IconCity } from "../images/ic-city.svg";
import { ReactComponent as IconOrg } from '../images/ic-org.svg';
import TransportUtil from "../util/TransportUtil";
import { Theme } from "../css/Theme";
import { black } from "../css/gen.jss";

const adminUserAccessCellJss = (theme: Theme) => ({
    main: {
        display: 'flex',
        alignItems: 'center'
    },
    icon: {
        marginRight: '10px',
        flexShrink: 0,
        height: '24px',
        width: '24px',
        '& path': {
            fill: black(1)
        }
    }
});

export const AdminUserAccessCell = withStyles(({ value, classes, appClasses }: { value: AdminUser } & WithClasses<ReturnType<typeof adminUserAccessCellJss>>) => {
    const { role, isAdmin, isOrg, isTSP, organizations, providers } = value;
    if (isAdmin) {
        const clientAppOrgs = clientAppOrgsForAdmin(value, adminProfile.allOrgs ?? []);
        return clientAppOrgs.length > 0 ?
            <div className={classes.main}>
                <IconCity className={classes.icon} />
                {clientAppOrgs?.map(org => org.isRoot ? "All" : org.name).join(", ")}
            </div> : null;
    } else if (isOrg) {
        return organizations.length > 0 ?
            <div className={classes.main}>
                <IconOrg className={classes.icon} />
                {organizations?.map(org => org.name).join(", ")}
            </div> : null;
    } else if (isTSP) {
        const provider = providers[0];
        return providers.length > 0 ?
            <div className={classes.main}>
                {provider &&
                    <img className={appClasses.modeIcon}
                        src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />}
                {providers?.map(provider => provider.name).join(", ")}
            </div> : null;
    }
    return null;
}, adminUserAccessCellJss);

export interface AdminUsersViewProps {
    data?: ItemsResult<AdminUser>;
    filter?: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onCreate?: () => void;
    onRefreshClick?: () => void;
}

export interface IProps extends AdminUsersViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof adminUsersViewJss>;

type AdminUsersTableColId = "name" | "email" | "type" | "access" | "client_app" | "organization" | "tsp";

export function getDefaultColumns(): AdminUsersTableColId[] {
    const insIf = (field: AdminUsersTableColId, cond: boolean) => Util.insertIf<AdminUsersTableColId>(cond, field);
    const dafaultColumns: AdminUsersTableColId[] = [
        "name", "email", "type", "access",
        // "client_app", "organization", "tsp"
    ];
    return dafaultColumns;
}

const AdminUsersView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const { data, filter, onFilterChange, onCreate, onRefreshClick, classes, appClasses } = props;
    const { profile: adminProfile, navHistory } = useContext(AppContext);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter!, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    let content;
    if (!data) {
        content = <Loading />;
    } else if (data.count === 0) {
        content =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        const tableSpec: ITableColSpec<AdminUser, AdminUsersTableColId>[] = [
            {
                id: "name",
                name: "Name",
                // cellValue: ({ givenName, surname }) => givenName + " " + surname
                cellValue: ({ name }) => name,
                width: 250
            },
            {
                id: "email",
                name: "Email",
                cellValue: ({ email }) => email
            },
            {
                id: "type",
                name: "Type",
                cellValue: ({ role }) => roleToLabel(role)
            },
            {
                id: "access",
                name: "Access",
                cellValue: (value) =>
                    <AdminUserAccessCell value={value} />
            },
            {
                id: "client_app",
                name: "Client App",
                cellValue: (value) => {
                    const clientAppOrgs = clientAppOrgsForAdmin(value, adminProfile.allOrgs ?? []);
                    return clientAppOrgs?.map(org => org.isRoot ? "All" : org.name).join(", ");
                }
            },
            {
                id: "organization",
                name: i18n.t("Organization"),
                cellValue: ({ isOrg, organizations }) => isOrg ? organizations?.map(org => org.name).join(", ") : null,
                width: 200
            },
            {
                id: "tsp",
                name: "Service Provider",
                cellValue: ({ providers }) => providers?.map(provider => provider.name).join(", ")
            }
        ];
        content =
            <Table
                tableId={adminProfile.app + "-" + adminUsersViewId}
                contentSpec={tableSpec}
                defaultColumns={getDefaultColumns()}
                items={data.items}
                onClick={admin => {
                    navHistory.push(ADMIN_VIEW, { id: admin.id });
                }}
                configurable={true}
            />;
    }
    const canAddUser = true;
    const addBtn = onCreate &&
        <button onClick={onCreate} className={appClasses.buttonOk} disabled={!canAddUser}>
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            Add Admin
        </button>;
    return (
        <ViewRestyled
            title={"Admin Users"}
            right={
                <div className={classNames(genStyles.flex, genStyles.alignCenter)} style={{ marginRight: '120px' }}>
                    {addBtn}
                </div>
            }
        >
            <div
                className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}
                style={{ overflow: 'auto' }}
            >
                <div className={classes.filters}>
                    <SearchBox />
                    {/* {onExportData &&
                        <ExportButton filter={filter} onExportData={onExportData} helpTooltip={EXPORT_USERS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />} */}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
                {content}
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </ViewRestyled>
    );
}

const AdminUsersViewStyled = withStyles(AdminUsersView, adminUsersViewJss);

export interface AdminUsersViewProps {
    filter?: Filter;
}

export const AdminUsersViewWithData = withAsyncDataObs(AdminUsersViewStyled,
    (query: Required<Pick<AdminUsersViewProps, "filter">>) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
         * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
         * All this won't be necessary if orgs come with the admin profile at the login.
        */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>  // Check if we need organizations for tickets.
            AdminUsersData.instance.watchQuery(query.filter)
                .pipe(map((result?: ItemsResult<AdminUser>) => ({ data: result }))) as Observable<{ data?: ItemsResult<AdminUser> }>))
            .pipe(concatAll() as any) as any
);

const adminUsersViewId = "ADMIN_USERS";

export const ADMIN_USERS_VIEW: IViewRouteConfig<AdminUsersViewProps> =
{
    path: ["*/adminUsers/filter/:filter", "*/adminUsers"],
    propsToPath: ({ filter }) => "/adminUsers" +
        (filter ? "/filter/" + Util.stringifyFilter(filter) : ""),
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(adminUsersViewId) ?? 10,
                page: 1,
                sortOrder: SortOrder.DESC
            }, Filter);
        const clientAppId = getClientIDPath();
        filter.orgId = getOrgIDPath() ?? (clientAppId && adminProfile.allOrgs?.find(org => org.isClientApp && org.clientId === clientAppId)?.id);
        return { filter: filter };
    },
    navLabel: ({ viewProps }) => "Admin Users",
    render: ({ viewProps, navHistory, profile, onProfileChange }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(adminUsersViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(ADMIN_USERS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        const onCreate = () => navHistory.push(ADMIN_NEW_VIEW, {});
        const adminUsersViewRef = React.createRef<any>();
        const onRefresh = () => {
            AdminUsersData.instance.invalidateCache();
            adminUsersViewRef.current.refresh(true);
        };
        return (
            <AdminUsersViewWithData
                filter={filter}
                onFilterChange={onFilterChange}
                onCreate={onCreate}
                onRefreshClick={onRefresh}
                ref={adminUsersViewRef}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        return {
            fields: [],
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace2(ADMIN_USERS_VIEW, { filter });
            }
        }
    },
    accessible: ({ profile }) => profile.remote.userData.adminManagement
};