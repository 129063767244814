import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import classNames from "classnames";
import { ScheduleBookingFormData } from "./ScheduleBookingForm";
import { scheduleBookingTrackJss } from "./ScheduleBookingTrack.jss";
import FormatUtil from "../util/FormatUtil";
import { TransStatus } from "../model/Transaction";

type IStyle = ReturnType<typeof scheduleBookingTrackJss>;

interface IProps extends WithClasses<IStyle> {
    relatedBookingsData: ScheduleBookingFormData[];
    selectedBookingId?: string;
}

const ScheduleBookingTrack: React.FunctionComponent<IProps> = (props: IProps) => {
    const { relatedBookingsData, selectedBookingId, classes, appClasses } = props;
    const step = ({ label, selected, past, skip, scheduled, i }: { label: string, selected: boolean, past?: boolean, skip?: boolean, scheduled?: boolean, i: number }) => (
        <div className={classNames(classes.step, selected && classes.current, past && classes.past, skip && classes.skip, scheduled && classes.scheduled)} key={i}>
            <div className={classes.lineCircle}>
                <div className={classes.line} />
                <div className={classes.circle} />
                <div className={classes.line} />
            </div>
            <div className={classes.label}>{label}</div>
        </div>
    )
    const selectedI = selectedBookingId ? relatedBookingsData.findIndex(data => selectedBookingId === data.bookingId) : relatedBookingsData.length;
    return (
        <div className={classNames(classes.main)}>
            {relatedBookingsData.map((data, i) => {
                return step({
                    label: FormatUtil.toFirstUpperCase(data.type),
                    selected: selectedBookingId === data.bookingId,
                    past: i < selectedI,
                    skip: data.skip,
                    scheduled: data.booking.status === TransStatus.PROVIDER_ACCEPTED,
                    i
                });
            })}
            {step({ label: "Confirm", selected: selectedBookingId === undefined, i: relatedBookingsData.length })}
        </div>
    );
};

export default withStyles(ScheduleBookingTrack, scheduleBookingTrackJss)