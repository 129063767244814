import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { organizationsTableJss } from "./OrganizationsTable.jss";
import { adminProfile } from "../account/AdminProfile";
import Table from "../view/Table";
import Organization from "../model/Organization";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import { ReactComponent as IconAngleRight } from "../images/ic-angle-right.svg";
import { isRootOrg } from "../app/OrgSelectorHelpers";
import { i18n } from "../i18n/TKI18nConstants";
import Tooltip from "../uiutil/Tooltip";

export const OrganizationPath: React.FunctionComponent<{ value: Organization, includeSelf?: boolean }> = ({ value, includeSelf }) => {
    const ancestors = adminProfile.allOrgs?.filter(org => !isRootOrg(org) && value.path.startsWith(org.path) && (includeSelf || org.path !== value.path)) ?? [];
    return (
        <div style={{ display: 'flex', alignItems: 'center', color: 'gray', fontSize: '14px' }}>
            {ancestors.map((ancestor) =>
                <div style={{ display: 'flex', alignItems: 'center' }} key={ancestor.id}>
                    {ancestor.name}
                    <IconAngleRight style={{ margin: '0 6px', height: '10px', width: 'auto' }} />
                </div>)}
        </div>
    );
}

export interface OrganizationsTableProps {
    items: Organization[];
    onEdit?: (org: Organization) => void;
    onRemove?: (org: Organization) => void;
    organizationLabel?: (org: Organization) => string;
}

export interface IProps extends OrganizationsTableProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof organizationsTableJss>;

const OrganizationsTable: React.FunctionComponent<IProps> = (props: IProps) => {
    const { items, onEdit, onRemove, organizationLabel, classes } = props;
    const indent = false;
    return (
        <Table
            tableId={"organizations"}
            contentSpec={[
                {
                    id: "name",
                    name: "Name",
                    // label: null,                    
                    cellValue: (value) => {
                        const { name, path } = value;
                        return (
                            <div
                                style={indent ? {
                                    paddingLeft: 15 * ((path.length / 4) - 2)
                                } : undefined}
                                className={classes.nameCell}
                            >
                                <OrganizationPath value={value} />
                                {organizationLabel?.(value) ?? name}
                            </div>
                        );
                    }
                },
                // {
                //     id: "description",
                //     name: "Description",
                //     cellValue: (value) => value.description
                // },                
                {
                    id: "initiatives",
                    name: i18n.t("Initiatives"),
                    available: adminProfile.features.trackTripInitiative22828,
                    cellValue: org => {
                        const joined = org.initiatives
                            .map(initiative => initiative.title)
                            .join(", ");
                        return org.initiatives && org.initiatives.length > 1 ?
                            <Tooltip title={joined!}>
                                <div className={classes.orgsCell}>{joined}</div>
                            </Tooltip> : joined;
                    },
                    width: 140,
                    cellClass: classes.orgsCell
                },
                {
                    id: "edit",     // I don't want this to be controlled by table config, think how to exclude from there.
                    name: "Edit",
                    label: null,
                    cellValue: (org: Organization) =>
                        <IconEdit
                            className={classes.iconEdit}
                            onClick={(e) => {
                                onEdit?.(org);
                                e.stopPropagation();
                            }}
                        />,
                    visible: !!onEdit,
                    width: 40
                },
                {
                    id: "remove",     // I don't want this to be controlled by table config, think how to exclude from there.
                    name: "Remove",
                    label: null,
                    cellValue: (org: Organization) =>
                        <IconRemove
                            className={classes.iconEdit}
                            onClick={(e) => {
                                onRemove?.(org);
                                e.stopPropagation();
                            }}
                        />,
                    visible: !!onRemove,
                    width: 40
                }
            ]}
            items={items}
            configurable={false}
        />
    );
}

export default withStyles(OrganizationsTable, organizationsTableJss);