import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { GET_BOOKING_QUERY, getBookingCacheRedirect } from "./TransactionsSchema";
import { GET_USER_QUERY, getUserCacheRedirect } from "./UsersSchema";
import {
    BUNDLE_APPLIED_TIMESTAMP_FIELD,
    BUNDLE_EXPIRATION_TIMESTAMP_FIELD, BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD,
    GET_BUNDLE_QUERY,
    getBundleCacheRedirect
} from "./BundlesSchema";
import { GET_ADMIN_USER_QUERY, getAdminUserCacheRedirect } from "./AdminUsersData";
import { GET_ORGANIZATION_QUERY, getOrganizationCacheRedirect } from "./OrganizationsData";
import { appCredentials, IAppCredentials } from "../account/appData";
import { GET_INITIATIVE_QUERY, getInitiativeCacheRedirect } from "./InitiativesData";
import { Observable } from "rxjs";
import { adminProfile } from "../account/AdminProfile";
import { GET_PROVIDER_QUERY, getProviderCacheRedirect } from "./ProvidersData";

const getCustomCachedKey = (obj: any) => {
    // TODO
    // This solves the issue of applied bundles with the same id to be treated the same by the caché.
    // Switch to better solution using typePolicies, but it's supported by a newer version of apollo-cache-inmemory
    // which is used by aws-appsync. Update aws-appsync to get apollo-cache-inmemory updated.
    return obj.__typename === 'Bundle' ?
        obj.Id +
        (obj[BUNDLE_APPLIED_TIMESTAMP_FIELD] || "") +
        (obj[BUNDLE_EXPIRATION_TIMESTAMP_FIELD] || "") +
        (obj[BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD] || "")
        : obj.Id ?? obj.id;     // obj.id is used for Provider type, which has an id instead of Id.
};

class AppSync {

    /**
     * TODO: Cleanup, multiple clients not needed anymore.
     * Maintains all clients created due to app switching so to reuse a former client if go to another app and return to
     * a previous one. Maintaining just the current has the problem that somehow a client remains alive when creating
     * another one on app switch, so returning to a previous app triggers the error:
     * "The keyPrefix XYZ is already in use. Multiple clients cannot share the same keyPrefix. Provide a different
     * keyPrefix in the offlineConfig object." since we try to create a client again or the same key.
     */
    private static clients = {};

    public static cacheKey = (adminProfile: IAppCredentials) => adminProfile.appId + "-" + adminProfile.gqlApiKey;

    // Set by WithAuth before rendering the app.
    public static token: string;

    public static getClient() {
        if (!this.clients[this.cacheKey(appCredentials)]) {
            this.clients[this.cacheKey(appCredentials)] = new AWSAppSyncClient({
                url: appCredentials.gqlApiUrl,
                region: appCredentials.gqlApiRegion,
                auth: {
                    type: AUTH_TYPE.OPENID_CONNECT,
                    jwtToken: async () => this.token,                    // Pass the Auth0 JWT token                    
                },
                cacheOptions: {
                    dataIdFromObject: getCustomCachedKey,
                    cacheRedirects: {
                        Query: {
                            [GET_USER_QUERY]: getUserCacheRedirect,
                            [GET_BOOKING_QUERY]: getBookingCacheRedirect,
                            [GET_BUNDLE_QUERY]: getBundleCacheRedirect,
                            [GET_ADMIN_USER_QUERY]: getAdminUserCacheRedirect,
                            [GET_ORGANIZATION_QUERY]: getOrganizationCacheRedirect,
                            [GET_INITIATIVE_QUERY]: getInitiativeCacheRedirect,
                            [GET_PROVIDER_QUERY]: getProviderCacheRedirect
                        },
                    },
                    // See in doc: https://www.apollographql.com/docs/react/caching/cache-configuration/#configuration-options
                    // typePolicies: {
                    //     ['Bundle']: {
                    //         keyFields: false
                    //     }
                    // }
                },
                offlineConfig: {
                    // Use appsync api key as offline cache local storage prefix to avoid different dashboard
                    // environments (e.g. beta and prod) deployed at the same host (e.g. tripgo.3scale.net) to
                    // accidentally / incorrectly share the offline caché.
                    keyPrefix: this.cacheKey(appCredentials)
                },
                disableOffline: true    // Did this since it may be causing issues.
            }, {
                connectToDevTools: process.env.NODE_ENV !== 'production'    // To connect to Apollo dev tools (https://github.com/apollographql/apollo-client-devtools/issues/213#issuecomment-1268433943).                
            });
        }
        return this.clients[this.cacheKey(appCredentials)];
    }

    public static resetLSCaches() {
        Object.keys(this.clients).forEach(clientKey => this.clients[clientKey].resetStore());
    }

    public static query(queryData: any): Promise<any> {
        return this.getClient().query({
            errorPolicy: "all", // set to "all" by default, but can be overriden by queryData.
            ...queryData
        }).then((response: any) => {
            this.handleErrors(response.errors);
            return response;
        }).catch((error: any) => {
            const statusCode = error.networkError?.statusCode;
            // If token is invalid, reload the page to force a new login.
            if (statusCode === 401 || statusCode === 403) {
                window.location.reload();
            }
        });
    }

    public static watchQuery(queryData: any, next: (response: any) => void) {
        return this.getClient().watchQuery({
            errorPolicy: "all",
            ...queryData
        }).subscribe({
            next: (response: any) => {
                this.handleErrors(response.errors);
                next(response);
            }
        });
    }

    public static clearCacheStorage() {
        caches.keys().then((cacheNames) => {
            cacheNames.forEach((cacheName) => {
                caches.delete(cacheName);
            });
            console.log('All cache storage cleared.');
        });
    }

    public static mutate(mutateData: any): Promise<any> {
        return this.getClient().mutate({
            errorPolicy: "all", // set to "all" by default, but can be overriden by mutateData.
            ...mutateData
        }).then((response: any) => {
            this.handleErrors(response.errors);
            return response;
        });
    }

    private static handleErrors(errors) {
        if (errors) {
            try {
                errors.forEach((error: any) => console.error("** GraphQL ERROR **: " + error.message));
            } catch (e) {
                // To ensure previous code will no break execution.
            }
        }
    }

    public static queryWithCache(queryData: any, options: { shouldCacheResponse?: (data: any) => boolean } = {}): Observable<any> {
        return new Observable((async (observer) => {
            // Check if the response is already cached
            const cache = await caches.open("graphql-cache");
            const cacheKey = `${appCredentials.gqlApiUrl}?query=${encodeURIComponent(queryData.query.loc?.source.body ?? "")}&user=${adminProfile.email}`;
            const cachedResponse = await cache.match(cacheKey);
            let responseJson;
            if (cachedResponse) {
                responseJson = await cachedResponse.json();
                observer.next(responseJson);
            }
            // Fetch the data from the server
            const data = await this.query(queryData)
            if (JSON.stringify(responseJson) === JSON.stringify(data)) {
                return; // If the response is the same as the cached one, don't return a response.
            }
            // Cache the response, except if the shouldCacheResponse function was provided and returns false
            if (!options.shouldCacheResponse || options.shouldCacheResponse(data)) {
                cache.put(cacheKey, new Response(JSON.stringify(data)));
            }
            observer.next(data);
            observer.complete();
        }) as any)
    }
}

export default AppSync;

