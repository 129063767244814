import React, { useContext, useEffect, useRef, useState } from 'react';
import { useStyles, WithStyles } from "../css/StyleHelper";
import { black, genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { AppContext } from './App';
import { Envs, adminProfile } from '../account/AdminProfile';

const appSwitcherJss = (theme: Theme) => ({
    wrapper: {
        position: 'absolute',
        zIndex: 1,
        ...genJss.flex,
        ...genJss.column,
        width: '100%'
    },
    popupOpen: {
        '&$wrapper': {
            height: '100%'
        }
    },
    main: {
        ...resetJss.button,
        width: '100%',
        padding: '35px 25px 25px',
        marginBottom: '10px',
        ...genJss.flex,
        alignItems: adminProfile.environment === Envs.PROD ? 'center' : undefined,
        textAlign: 'left'
    },
    cursorDefault: {
        cursor: 'default'
    },
    open: {
        borderRight: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards'

    },
    '@global': {
        '@keyframes toWhiteBackground': {
            '0%': { background: '#f6f7f7' },
            '100%': { background: 'white' }
        }
    },
    appName: {
        fontSize: '20px'
    },

    environment: {
        ...theme.textColorGray
    },

    logo: {
        height: '40px',
        marginRight: '10px'
    },
    angleDown: {
        ...genJss.noShrink,
        padding: '3px',
        marginLeft: '3px',
        width: '10px',
        height: '10px'
    },
    up: {
        transform: 'rotate(180deg)'
    },
    popup: {
        width: '100%',
        height: 'calc(100% - 111px)',
        overflowY: 'auto',
        padding: '10px 0',
        marginTop: '-20px',
        borderRight: '1px solid ' + black(5),
        borderBottom: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards',
        ...genJss.grow
    },
    option: {
        ...genJss.flex,
        ...genJss.alignCenter,
        padding: '10px 25px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: black(4)
        }
    }
});

type IStyle = ReturnType<typeof appSwitcherJss>

interface IProps extends WithStyles<IStyle> {
    apps: { id: string; name: string, logoUrl: string }[];
    value: string;
    onChange: (value: string) => void;
}

const AppSwitcher: React.FunctionComponent<IProps> = (props: IProps) => {
    const { classes } = useStyles(props, appSwitcherJss);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const app = props.apps.find(app => app.id === props.value)!;
    const apps = props.apps.filter(app => app.id !== props.value);
    const { profile } = useContext(AppContext);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onClick = ({ target }: MouseEvent): void => {
            if (wrapperRef.current && !wrapperRef.current.contains(target as Node)) {
                setShowPopup(false);
            }
        };
        window.addEventListener('click', onClick);
        return () => window.removeEventListener('click', onClick);
    }, []);
    return (
        <div className={classNames(classes.wrapper, showPopup && classes.popupOpen)} ref={wrapperRef}>
            <button className={classNames(classes.main, showPopup && classes.open, apps.length === 0 && classes.cursorDefault)}
                onClick={apps.length > 0 ? (() => setShowPopup(!showPopup)) : undefined}>
                <img className={classes.logo} src={app.logoUrl} />
                <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}>
                    <div className={classes.appName}>
                        {<div dangerouslySetInnerHTML={{ __html: app.name }} />}
                    </div>
                    <div className={classes.environment}>
                        {profile.environment === Envs.BETA ? " beta" : profile.environment === Envs.STAGING ? " staging" : ""}
                    </div>
                </div>
                {apps.length > 0 &&
                    <IconTriangleDown className={classNames(classes.angleDown, showPopup && classes.up)} />}
            </button>
            {showPopup &&
                <div className={classes.popup}>
                    {apps.map(app => (
                        <div className={classes.option} onClick={() => props.onChange(app.id)} key={app.id}>
                            <img className={classes.logo} src={app.logoUrl} />
                            <div dangerouslySetInnerHTML={{ __html: app.name }} />
                        </div>
                    ))}
                </div>
            }
        </div>
    )
};

export default AppSwitcher;