import TKDefaultGeocoderNames from "tripkit-react/dist/geocode/TKDefaultGeocoderNames";
import { newCreateBookingViewJss } from "./NewCreateBookingView.jss";
import { WithStyles } from "../css/StyleHelper";
import User from "../model/User";
import Transaction from "../model/Transaction";
import RoutingQuery from "tripkit-react/dist/model/RoutingQuery";
import BookingInfo from "tripkit-react/dist/model/trip/BookingInfo";
import { SGPaymentMethod } from "tripkit-react/dist/stripekit/TKUIPaymentMethodSelect";

export const FAVORITES_GEOCODER_ID = TKDefaultGeocoderNames.favourites + "-2";
export const RECENT_GEOCODER_ID = TKDefaultGeocoderNames.recent + "-2";

type IStyle = ReturnType<typeof newCreateBookingViewJss>;

interface IProps extends WithStyles<IStyle> {
    userId?: string;
    user?: User;
    setUser?: (value?: User) => void,   // If passed, then user can be selected through RoutingQueryForm.
    onOpenUserDetails?: (user: User) => void;
    onRequestClose?: (success?: boolean, data?: { updateURL?: string, refreshURLForSourceObject?: string, userId: string }) => void;
    options?: CreateOptions;
}

export interface CreateOptions {
    title?: string;
    subtitle?: React.ReactNode;
    onRequestClose?: () => void;
    referenceBooking?: Transaction;
    "QUERY"?: {
        query?: RoutingQuery;
        skip?: boolean;
    };
    "TRIPS"?: {
        defaultMode?: string;
        skip?: boolean;
    };
    "BOOKING"?: {
        formPrefillFc?: (bookingForm: BookingInfo, query: RoutingQuery) => BookingInfo;
        skip?: boolean;
    };
    "PROVIDER_OPTIONS"?: {
        defaultProvider?: string;
        skip?: boolean;
    };
    "REVIEW"?: {
        skip?: boolean;
    };
    "PAYMENT"?: {
        // Need to allow passing a default payment option to TKUICheckoutView? Or maybe pass a defaultPaymentMethodFc?: (paymentMethods: SGPaymentMethod[]) => SGPaymentMethod; and pass this to TKUICheckoutView to pick the default PM, including organization, etc!!!.
        defaultPaymentMethodFc?: (paymentMethods: SGPaymentMethod[]) => SGPaymentMethod | undefined;
        defaultInvoiceOrganizationId?: string;
        skip?: boolean;
    };
}

export type NewCreateBookingViewProps = IProps;
export type NewCreateBookingViewStyle = IStyle;