import { JsonObject, JsonProperty } from "json2typescript/src/json2typescript/json-convert-decorators";

/*
type Ticket{
    Id: String,
    transactionId: String,
    purchasedTimestamp: String,
    connectorCode: String,
    reusable: Boolean,
    status: String,
    price: Int,
    currency: String,
    description: String,
    paymentMethod:String
}
*/

export const TICKET_ID_FIELD = "Id";
export const TICKET_TRANSACTION_ID_FIELD = "transactionId";
export const TICKET_USER_ID_FIELD = "userID";
export const TICKET_USER_NAME_FIELD = "userName";
export const TICKET_CLIENT_ID_FIELD = "clientId";
export const TICKET_PURCHASED_TIMESTAMP_FIELD = "purchasedTimestamp";
export const TICKET_CONNECTOR_CODE_FIELD = "connectorCode";
export const TICKET_REUSABLE_FIELD = "reusable";
export const TICKET_STATUS_FIELD = "status";
export const TICKET_PRICE_FIELD = "price";
export const TICKET_CURRENCY_FIELD = "currency";
export const TICKET_DESCRIPTION_FIELD = "description";
export const TICKET_PAYMENT_METHOD_FIELD = "paymentMethod";

@JsonObject
class Ticket {
    @JsonProperty(TICKET_ID_FIELD, String, true)
    public readonly id: string = "";
    @JsonProperty(TICKET_TRANSACTION_ID_FIELD, String, true)
    public transactionId: string = "";
    @JsonProperty(TICKET_USER_ID_FIELD, String, true)
    public userId: string = "";
    @JsonProperty(TICKET_USER_NAME_FIELD, String, true)
    public userName: string = "";
    @JsonProperty(TICKET_CLIENT_ID_FIELD, String, true)
    public clientId: string = "";
    @JsonProperty(TICKET_PURCHASED_TIMESTAMP_FIELD, String, true)
    public purchasedTimestamp: string = "";
    @JsonProperty(TICKET_CONNECTOR_CODE_FIELD, String, true)
    public connectorCode: string = "";
    @JsonProperty(TICKET_REUSABLE_FIELD, Boolean, true)
    public reusable: boolean = false;
    @JsonProperty(TICKET_STATUS_FIELD, String, true)
    public status: string = "";
    @JsonProperty(TICKET_PRICE_FIELD, Number, true)
    public price: number = 0;
    @JsonProperty(TICKET_CURRENCY_FIELD, String, true)
    public currency: string = "";
    @JsonProperty(TICKET_DESCRIPTION_FIELD, String, true)
    public description: string = "";
    @JsonProperty(TICKET_PAYMENT_METHOD_FIELD, String, true)
    public paymentMethod: string = "";
}

export default Ticket;