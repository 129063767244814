import React, { useEffect } from "react";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import { Theme } from "../css/Theme";
import { WithClasses, withStyles } from "../css/StyleHelper";
import UIUtil from "../util/UIUtil";
import DateTimeUtil from "../util/DateTimeUtil";

const dateTimePickerJss = (theme: Theme) => ({});

type IStyle = ReturnType<typeof dateTimePickerJss>;

interface IProps extends WithClasses<IStyle> {
    value: string;
    onChange: (value: string) => void;
}

const dateTimeInputSupported = UIUtil.inputTypeSupported("datetime-local");

/**
 * Using HTML5 datetime-local input, with fallback to date input + time input, with fallback to custom date/time pickers.
 * datetime-local input is supported in Chrome, and Safari from 14.1. It's not supported by Firefox.
 * date and time inputs are supported by Chrome, Safari from 14.1 and Firefox.
 * So, for Safari <14.1 I need to provide a fallback to custom date/time pickers.
 */

const DateTimePicker: React.FunctionComponent<IProps> = (props: IProps) => {
    const [dateTimeInputValue, setDateTimeInputValue] = React.useState<string>(props.value);
    useEffect(() => {
        if (props.value !== dateTimeInputValue) {
            setDateTimeInputValue(props.value);
        }
    }, [props.value]);
    function handleDateTimeInputChange(value: string) {
        setDateTimeInputValue(value);
        const valueMoment = DateTimeUtil.momentDefaultTZ(value);
        if (valueMoment.isValid()) {
            props.onChange(value);
        }
    }
    return dateTimeInputSupported ?
        <input
            name="depart"
            type="datetime-local"
            value={dateTimeInputValue}
            onChange={(e) => handleDateTimeInputChange(e.currentTarget.value)}
            onBlur={() => setDateTimeInputValue(props.value)}
        /> :
        <div className={props.appClasses.input}>
            <DatePicker
                value={props.value.substring(0, 10)}
                onChange={(date) => props.onChange(date + "T" + props.value.substring(11))}
            />
            <TimePicker
                value={props.value.substring(11)}
                onChange={(time) => props.onChange(props.value.substring(0, 10) + "T" + time)}
            />
        </div>
};

export default withStyles(DateTimePicker, dateTimePickerJss);