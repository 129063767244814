import { Theme } from "../css/Theme";
import { genJss, important } from "../css/gen.jss";

export const assignBundleViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.spaceBetween,
        height: '100%'
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '40px'
    },
    form: {
        minHeight: '250px',
        paddingBottom: '30px'
    },
    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart,
        marginTop: '30px',
        width: '350px',
        boxSizing: 'border-box'
    },

    field: {
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },

    value: {
        ...theme.textColorGray
    },
    bundleValue: {
        width: '350px',
        flexGrow: '0!important',
        boxSizing: 'border-box',
        '&>*': {
            ...genJss.grow
        }
    },
    durationValue: {
        '& > *:first-child': {
            width: '350px',
            padding: '0!important',
            ...important(genJss.borderRadius(4))
        },
        '& > * > *': {
            ...important(genJss.borderRadius(4))
        }
    },
    details: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.grow,
        background: '#2029310a',
        borderRadius: '6px',
        padding: '20px',
        width: 'initial!important',
        marginTop: '0!important'
    },
    tableHeader: {
        ...theme.textWeightRegular
    }

});