import { createUseStyles } from 'react-jss'
import { Theme } from "./Theme";
import { black, genJss, resetJss } from "./gen.jss";
import { alpha } from '@mui/material/styles';

const button = (theme: Theme) => ({
    color: 'white',
    ...genJss.borderRadius(4),
    fontSize: '16px',
    padding: '10px 20px',
    textDecoration: 'none',
    cursor: 'pointer',
    outlineColor: theme.colorPrimary,
    border: 'none',
    ...genJss.flex,
    ...genJss.alignCenter,
    ...genJss.center,
    '&:disabled': {
        backgroundColor: black(4),
        color: '#88939b',
        cursor: 'initial'
    },
});

export const appGlobalJss = (theme: Theme) => ({

    button: button(theme),

    buttonOk: {
        ...button(theme),
        backgroundColor: theme.colorSecondary
    },

    buttonAdd: {
        ...button(theme),
        backgroundColor: theme.colorPrimary
    },

    buttonDelete: {
        ...button(theme),
        backgroundColor: theme.colorSecondary
    },

    buttonCancel: {
        ...button(theme),
        background: 'none',
        color: black(),
        border: '1px solid ' + black(3)
    },

    buttonLink: {
        ...button(theme),
        color: theme.colorPrimary,
        border: 'none!important',
        background: 'none',
        outline: 'none',
        fontSize: 'initial',
        opacity: '.9',
        height: 'initial',
        minWidth: 'initial',
        padding: 'initial',
        '&:hover': {
            opacity: 1
        }
    },

    noResults: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.center,
        ...genJss.alignCenter,
        fontSize: '22px',
        ...theme.textColorGray as any
    },

    form: {
        ...genJss.flex,
        ...genJss.column,
        maxWidth: '1100px',
        '& label': {
            width: '150px',
            textAlign: 'right',
            marginRight: '20px',
            ...theme.textWeightSemibold as any,
            ...theme.textSizeBody as any,
            ...genJss.noShrink
        },
        '& input, textarea, $input': {
            ...genJss.borderRadius(4),
            ...genJss.grow,
            border: '1px solid ' + black(3),
            backgroundColor: 'none',
            lineHeight: '40px!important',
            fontSize: '16px',
            padding: '0 10px!important',
            fontFamily: theme.fontFamily
        },
        '& .select__input input': {
            padding: '0!important'
        },
        '& $input input[type=text]': {
            padding: '0!important'  // To avoid adding padding to both $input wrapper and an input inside (e.g. for TKUILocationBox)
        },
        '& $currencyValue': {
            '& > $currency': {
                position: 'absolute',
                marginLeft: '7px',
                lineHeight: '40px',
                ...theme.textColorGray as any
            },
            '& input': {
                padding: '0 10px 0 25px',
                textAlign: 'right',
            }
        },
        '& details': {
            '&:not(:first-child)': {
                marginTop: '30px',
            },
            borderRadius: '4px'
        },
        '& summary': {
            padding: '20px 18px',
            fontWeight: '600',
            background: '#f9f9f9',
            cursor: 'pointer'
        }
    },

    input: {
        '& input, select': {
            border: 'none',
            padding: '0'
        },
        '& input:focus, select:focus': {
            outline: 'none'
        },
        '&:focus-within': {
            boxShadow: '0 0 0 2px rgb(0, 95, 204)'
        }
    },

    formGroup: {
        ...genJss.flex,
        ...genJss.alignCenter,
        marginTop: '30px',
        position: 'relative',
        '&:first-child': {
            marginTop: 0
        }
    },

    value: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.relative
    },

    valueMinWidth: {
        '&$value, & $value': {
            minWidth: '250px'
        }
    },

    // #scrollx
    scrollX: {
        '&$table $cell': {
            whiteSpace: 'nowrap'
        }
    },

    table: {
        fontFamily: 'inherit!important',
        '& $cell': {
            padding: '12px 16px',
            ...theme.textColorGray as any,
            fontSize: '16px',
            border: 'none'
        },
        '& $cellTwoLines': {
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: '2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            padding: '0',
            margin: '12px 16px'
        },
        '& $cellFit': { // Cell with less top / bottom padding. Part of 'uniforming table' strategy.
            padding: '2px 16px'
        },
        '& $cellCenter': { // Cell with less top / bottom padding. Part of 'uniforming table' strategy.
            textAlign: 'center'
        },
        '& $field': {
            ...theme.textSizeBody as any,
            ...theme.textWeightSemibold as any,
            color: '#3e3e3e',
            padding: '12px 16px',
            borderBottomColor: '#ececec',
            verticalAlign: 'top'
        },
        '& $fieldSecondary': {
            ...theme.textSizeBody as any,
            color: '#3e3e3e',
            padding: '12px 16px',
            borderBottomColor: '#ececec',
            verticalAlign: 'top'
        },
        '& $row': {
            height: '55px', // Fixed (min) height to try to avoid variation among rows. Part of 'uniforming table' strategy.
            '&$clickableRow:hover': {
                backgroundColor: 'rgba(83, 37, 133, 0.09)!important',
                '& $cell': {
                    color: theme.colorPrimary + '!important',
                },
                '& $cellHiddenBtn': {
                    display: 'block',
                    marginRight: '-23px',
                    marginLeft: '5px'
                },
                '& $cellHiddenBtnContainer': {
                    paddingRight: 0
                }
            },
            borderBottom: '1px solid ' + black(5),
            '& $cellHiddenBtn': {
                display: 'none',
                ...resetJss.button,
                ...genJss.noShrink,
                width: '18px',
                height: '18px',
                '& svg': {
                    width: '100%',
                    height: '100%',
                    '& path': {
                        fill: theme.colorPrimary
                    }
                },
                opacity: .6,
                '&:hover': {
                    opacity: 1
                }
            }
        }
    },

    tableHead: {
        '& $field': {
            borderBottom: '2px solid ' + black(5)
        }
    },

    field: {},

    fieldSecondary: {},

    cell: {},

    cellTwoLines: {},

    cellFit: {},

    cellCenter: {},

    row: {},

    clickableRow: {},

    cellHiddenBtn: {},

    cellHiddenBtnContainer: {
        ...genJss.flex,
        ...genJss.alignCenter,
        paddingRight: '38px'
    },

    currencyValue: {},

    currency: {},

    rightUnit: {
        marginLeft: '7px',
        lineHeight: '40px',
        ...theme.textColorGray as any
    },

    modeIcon: {
        width: '24px',
        height: '24px',
        marginRight: '10px',
        opacity: '.5'
    },

    link: {
        color: theme.colorPrimary,
        cursor: 'pointer'
    },

    textLight: {
        color: 'grey'
    },

    highlightText: {
        color: theme.colorPrimary
    },

    hidePlaceholderOnFocus: {
        '&:focus::placeholder': {
            color: 'transparent'
        }
    },

    validationError: {
        color: 'red',
        position: 'absolute',
        bottom: '-22px',
        padding: '0 10px',
        textAlign: 'left',
        whiteSpace: 'nowrap'
    },

    refreshBtn: {
        ...resetJss.button,
        width: '38px',
        height: '38px',
        border: '1px solid ' + black(3),
        ...genJss.borderRadius(6),
        padding: '7px'
    },

    tooltip: {
        ...theme.textSizeBody as any
    },

    paginatePanel: {
        textAlign: 'center'
    },

    cursorHelp: {
        cursor: 'help'
    },

    detailLink: {
        ...genJss.flex,
        ...genJss.alignCenter,
        cursor: 'pointer',
        '& svg': {
            ...genJss.noShrink,
            marginLeft: '10px',
            height: '18px',
            width: '18px',
            '& path': {
                fill: theme.colorPrimary
            }
        },
        '&:hover': {
            textDecoration: 'underline'
        }
    },

    detailLinkIconOnHover: {
        ...genJss.flex,
        ...genJss.alignCenter,
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'inherit',
        '& > svg:last-child': {
            ...genJss.noShrink,
            marginLeft: '10px',
            height: '18px',
            width: '18px',
            '& path': {
                fill: theme.colorPrimary
            },
            visibility: 'hidden'
        },
        '&:hover': {
            textDecoration: 'underline',
        },
        '&:hover > svg:last-child': {
            visibility: 'visible'
        }
    },

    focusGlow: {
        '&:focus-within': {
            borderColor: 'rgb(35, 119, 203)',
            boxShadow: '0 0 4px rgba(35, 119, 203, 0.3)!important'
        }
    },

    '@global': {
        '.DateRangePicker__CalendarSelection--is-pending': {
            backgroundColor: alpha(theme.colorSecondary, .75)
        },

        '.DateRangePicker__CalendarSelection': {
            backgroundColor: theme.colorSecondary + "!important",
            border: '1px solid ' + theme.colorSecondary + "!important"
        },

        '.DateRangePicker__CalendarHighlight--single': {
            backgroundColor: '#fff',
            border: '1px solid ' + theme.colorSecondary,
            ...genJss.borderRadius(5),
            left: '5px',
            right: '5px'
        },

        'body:not(.user-is-tabbing) .app-style *': {
            boxShadow: 'none',     /* To avoid glow effect on focus */
            outline: 'none!important'  /* To avoid glow effect on focus */
        },

        'h2': {
            fontSize: '28px',
            textAlign: 'left'
        }
    }
});

const useAppGlobalStyles = createUseStyles(appGlobalJss);

export { useAppGlobalStyles };