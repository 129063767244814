import React, { useContext, useMemo } from "react";
import DateTimeUtil from "../util/DateTimeUtil";
import FormatUtil from "../util/FormatUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { ticketsTableJss } from "./TicketsTable.jss";
import TableSortLabel from "@mui/material/TableSortLabel";
import Filter, { SortOrder } from "../data/Filter";
import Util from "../util/Util";
import AdminProfile from "../account/AdminProfile";
import Table, { ITableColSpec } from "../view/Table";
import { AppContext } from "../app/App";
import Ticket from "../model/Ticket";
import { statusPillBuilder } from "../booking/StatusPill";
import genStyles from "../css/general.module.css";
import { PART_DETAIL_VIEW_MODAL, userViewId } from "../user/UserView";
import { ReactComponent as IconInfo } from "../images/ic-info.svg";

type IStyle = ReturnType<typeof ticketsTableJss>;

interface IProps extends WithClasses<IStyle> {
    values: Ticket[];
    onSelect?: (transaction: Ticket) => void;
    tableId: string;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
}

type TableColId = "description" | "user" | "client_app" | "status" | "purchaseTime" | "reusable" | "price" | "paymentMethod";

/**
 * Order is relevant, too. No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(tableId: string, adminProfile: AdminProfile): TableColId[] {
    const dafaultColumns: TableColId[] = [
        "description", "user", "client_app", "status", "purchaseTime", "reusable", "price", "paymentMethod"
    ];
    return dafaultColumns;
}

const TicketStatusPill = statusPillBuilder();
const PaymentMethodPill = statusPillBuilder();

const TicketsTable: React.FunctionComponent<IProps> = (props: IProps) => {

    const { values, filter, tableId, onSelect, onFilterChange, classes, appClasses } = props;

    const { profile: adminProfile, selectedOrgID, selectedClientID, clients, navHistory } = useContext(AppContext);

    const isUnderUserView = useMemo(() => navHistory.viewsStack().some(view => view.id === userViewId && !view.isModal), []);

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = filter.sortOrder;
            const filterUpdate = Util.iAssign(filter,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate);
        }
    }

    const tableContent: ITableColSpec<Ticket, TableColId>[] = [
        {
            id: "purchaseTime",
            name: "Purchase Time",
            label: (
                <TableSortLabel
                    active={true}
                    direction={filter.sortOrder || SortOrder.DESC}
                    onClick={handleSortOrderClick}
                >
                    Purchase Time
                </TableSortLabel>
            ),
            cellValue: (item: Ticket) => {
                const timezone = adminProfile.getTimezone(selectedClientID); // TODO: use item.clientId when available
                const showGMT = DateTimeUtil.isOnDifferentTimezone(timezone);
                const time = DateTimeUtil.isoToMomentTimezone(item.purchasedTimestamp, timezone);
                return DateTimeUtil.formatRelativeDay(time, DateTimeUtil.dateFormatWithDay() +
                    ", " + DateTimeUtil.timeFormat(), { partialReplace: DateTimeUtil.dateFormatWithDay(), timezone }) + (showGMT ? " " + DateTimeUtil.toGMTString(time) : "");
            },
            width: 180
        },
        {
            id: "user",
            name: "User",
            cellValue: (item: Ticket) =>
                <a
                    className={appClasses.detailLinkIconOnHover}
                    onClick={(e) => {
                        navHistory.push(PART_DETAIL_VIEW_MODAL, { id: item.userId })
                        e.stopPropagation();
                    }}
                >
                    {item.userName}
                    <IconInfo />
                </a>,
            width: 200,
            available: !isUnderUserView
        },
        {
            id: "description",
            name: "Description",
            cellValue: (item: Ticket) =>
                <div className={classes.descriptionCell}>
                    {item.description}
                </div>,
            width: 300
        },
        {
            id: "status",
            name: "Status",
            cellValue: (item: Ticket) =>
                <div className={genStyles.flex}>
                    <TicketStatusPill status={item.status} />
                </div>,
            width: 150
        },
        {
            id: "price",
            name: "Price",
            cellValue: (item: Ticket) => FormatUtil.toMoney(item.price, { currency: item.currency, nInCents: true }),
            width: 100
        },
        {
            id: "paymentMethod",
            name: "Payment Method",
            available: !!adminProfile.features.payments,
            cellValue: (item: Ticket) =>
                <div className={genStyles.flex}>
                    <PaymentMethodPill status={item.paymentMethod} />
                </div>,
            width: 150
        },
        {
            id: "client_app",
            name: "Client app",
            available: adminProfile.isSuperApp,
            cellValue: (item: Ticket) => clients?.find(client => client.clientID === item.clientId)?.clientName,
            visible: !selectedClientID,
            width: 140
        },
        {
            id: "reusable",
            name: "Reusable",
            cellValue: (item: Ticket) => item.reusable ? "Yes" : "No",
            width: 110
        }
    ];

    return (
        <div className={classes.tableContainer}>
            <Table
                tableId={tableId}
                contentSpec={tableContent}
                defaultColumns={getDefaultColumns(tableId, adminProfile)}
                items={values}
                tableClass={appClasses.scrollX}
                onClick={booking => onSelect?.(booking)}
                configurable={true}
            />
        </div>
    );
}

export default withStyles(TicketsTable, ticketsTableJss);