import React, { ChangeEvent, useState, useRef, useMemo, useContext } from 'react';
import Util from "../util/Util";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { ValidatorForm } from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import Bundle, { BundleConfig, BundleStatus, TMode } from "../model/Bundle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from 'react-select';
import TransportUtil from "../util/TransportUtil";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import FormatUtil from "../util/FormatUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { editBundleViewJss } from "./EditBundleView.jss";
import View from "../view/View";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import BundlesData from "../data/BundlesData";
import withAsycnData from "../data/WithAsyncData";
import { BUNDLE_DETAIL_VIEW } from "./BundleView";
import { BUNDLES_VIEW } from "./BundlesView";
import { CheckboxStyled, ProviderOption } from "../booking/BookingsView";
import Tooltip from "../uiutil/Tooltip";
import { DefaultSelect } from "../rewards/StatusSelect";
import { ModelOperation, ModelType, adminProfile } from "../account/AdminProfile";
import BundleConfigsTable from './BundleConfigsTable';
import { i18n } from '../i18n/TKI18nConstants';
import DnDImage from '../rewards/DnDImage';
import UIUtil from '../util/UIUtil';
import { useInitiativeOptions } from '../data/InitiativesData';
import { AppContext } from '../app/App';
import { useProvider, useProviderOptions, useProviders } from '../data/ProvidersData';
import Provider from '../model/Provider';
import { components } from 'react-select';
import { genJss } from '../css/gen.jss';

const modeOptionJss = () => ({
    option: {
        padding: '0 20px',
        '&$selected': {
            backgroundColor: '#02b5cc'
        },
        '&$focused': {
            backgroundColor: 'rgba(2, 181, 204, 0.2)'
        },
        '& > div': {
            ...genJss.flex,
            backgroundColor: 'none'
        },
        '& > img': {
            width: '24px',
            height: '24px',
            opacity: '.7'
        }
    },
    selected: {},
    focused: {}
});

export const ModeOption = withStyles(
    (props: any) => {
        const { data, isFocused, isSelected, classes } = props as any;
        const provider = useProvider(data.value);
        return (
            <div className={classNames(classes.option, genStyles.flex, genStyles.alignCenter,
                isSelected && classes.selected, !isSelected && isFocused && classes.focused)}>
                {provider &&
                    <img src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />}
                <components.Option {...props} />
            </div>
        );
    }, modeOptionJss);

type IStyle = ReturnType<typeof editBundleViewJss>;

interface IProps extends WithClasses<IStyle> {
    bundle?: Bundle;
    copy?: boolean;
    onRequestClose?: (update?: Bundle, another?: boolean, reset?: () => void) => void;
    expandedSection?: string;
}

interface IState {
    update: Bundle;
}

export const HIDE_COST_TOOLTIP = "States if cost of trips of this mode will be hidden to users in the mobile app.";

export const BUNDLE_DURATION_TOOLTIP = i18n.t("The.duration.of.the.bundle.subscription.");

export const DISCOUNT_TOOLTIP = "Defines the discount to be applied on the cost of a trip of this mode, " +
    "which can be a fixed amount ('$' unit) " +
    "or a percentage of the trip cost ('%' unit).";

export const REWARD_POINTS_PER_COST_TOOLTIP = "Defines the reward points to be earned by the " +
    "user per dollar spent on trips of this mode.";

export const NOTIFICATION_EMAIL_TOOLTIP = i18n.t("Mail.of.Transport.Service.Provider.for.the.mode,.to.receive.notifications.on.user.subscriptions.to.the.bundle.");

export const OFFER_DESCRIPTION_TOOLTIP = i18n.t("To.be.displayed.in.the.app.along.with.the.mode.in.the.bundle.detail.view.");

export const ACTIVATION_STATUS_TOOLTIP = "If inactive it's not offered to users. " +
    "An inactive plan will not appear on the list of available plans on the mobile app. " +
    "But if a user is already on an inactive plan, it will still be visible to the user as his/her current plan.";

const RESTRICT_VISIBILITY_TOOLTIP = i18n.t("Restrict.visibility.of.this.bundle.to.users.of.specific.types.");

const PURCHASE_ONCE_TOOLTIP = i18n.t("Restrict.bundle.to.be.buyable.only.once.per.user.");

const ONLY_VISIBLE_TO_TOOLTIP = i18n.t("Visible.only.to.users.of.the.specified.types..If.no.type.is.listed.then.no.user.will.see.the.bundle.");

export const PURCHASE_ONCE_LABEL = "Buyable only once";

const durationsArray = ["Infinite", "Weekly", "Fortnightly", "Monthly", "Quarterly"] as const;
export type Durations = typeof durationsArray[number];

export function durationStringFromDays(days: number): Durations {
    switch (days) {
        case 0: return "Infinite";
        case 7: return "Weekly";
        case 15: return "Fortnightly";
        case 90: return "Quarterly";
        default: return "Monthly";
    }
}

export const durationOptions: { value: number, label: Durations }[] = [
    { value: 0, label: 'Infinite' },
    { value: 7, label: 'Weekly' },
    { value: 15, label: 'Fortnightly' },
    { value: 30, label: 'Monthly' },
    { value: 90, label: 'Quarterly' }
];

ValidatorForm.addValidationRule('isAModeWithABenefitSpecified', value =>
    value &&
    value.find((mode: TMode) =>
        (mode.pointsPerCost !== undefined && mode.pointsPerCost !== 1) ||
        (mode.discountPoints !== undefined && mode.discountPoints !== 0) ||
        (mode.rewardPerCost !== undefined)
    ) !== undefined
);
ValidatorForm.addValidationRule('isAModeSpecified', value =>
    value && value.length > 0
);
ValidatorForm.addValidationRule('isADurationSpecified', value => value && value.length > 0);

const EditBundleView: React.FunctionComponent<IProps> = (props) => {
    const { bundle, onRequestClose, copy, expandedSection, classes, appClasses } = props;
    const { selectedClientID } = useContext(AppContext);

    const [update, setUpdate] = useState<Bundle>(() => {
        let update: Bundle;
        if (bundle) {
            update = Util.deepClone(bundle);
            if (copy) {
                update.id = "";
                update.name = update.name + " (copy)";
                update.bundleConfig.forEach(config => {
                    config.currentUserCount = 0;
                    config.futureUserCount = 0;
                });
            }
        } else {
            update = new Bundle();
            update.tmodes = [];
        }
        return update;
    });

    const formRef = useRef<any>(null);
    const generalDetailsRef = useRef<HTMLDetailsElement>(null);
    const transportsDetailsRef = useRef<HTMLDetailsElement>(null);
    const userTypeOptions = useMemo(() => adminProfile.userTypes.map((type: string) => ({ value: type, label: FormatUtil.toFirstUpperCase(type) })), []);
    const [generalDetailsOpen, setGeneralDetailsOpen] = useState<boolean>(expandedSection === 'general' || expandedSection === undefined);
    const [transportsDetailsOpen, setTransportsDetailsOpen] = useState<boolean>(expandedSection === 'transports' || expandedSection === undefined);
    const [sponsorDetailsOpen, setSponsorDetailsOpen] = useState<boolean>(expandedSection === 'sponsor' || expandedSection === undefined);
    const initiativeOptions = useInitiativeOptions(selectedClientID);

    function reset() {
        const bundle = new Bundle();
        // Need to set a (non undefined) value to those fields that are initialized as undefined on Bundle creation
        // to actually get the fields reset.
        bundle.name = "";
        bundle.note = "";
        bundle.tmodes = [];
        setUpdate(bundle);
    }

    function onCancelClick() {
        onRequestClose?.();
    }

    function onSaveClick(another?: boolean) {
        formRef.current?.isFormValid(false)
            .then((valid: boolean) => {
                if (valid) {
                    onRequestClose?.(update, another, reset);
                }
            });
    }

    const isCreate = !bundle || copy;
    const providers = useProviders({ clientId: selectedClientID });
    const allProviderOptions = useProviderOptions({ clientId: selectedClientID, modeLabel: true });
    const modeOptions = useMemo(() =>
        allProviderOptions?.filter(({ value }) => !update.tmodes!.find((tmode: TMode) => tmode.mode === value)), [update.tmodes, allProviderOptions]);
    const availableDurations = durationOptions.filter(option => !update.bundleConfig.find(config => config.subscriptionDurationInDays === option.value));
    const discountOptions = [{ value: "%", label: "%" }, { value: "$", label: "$" }];
    const editable = update.userCount === 0;
    return (
        <View
            title={(isCreate ? "New" : "Edit") + " " + i18n.t("Bundle")}
        >
            <ValidatorForm
                instantValidate={false}
                ref={formRef}
                className={genStyles.grow}
                onSubmit={() => { }} // To avoid warning that onSubmit is required.
            >
                <div className={classNames(appClasses.form, genStyles.flex, genStyles.column)}>
                    <details open={generalDetailsOpen} {...{ onToggle: (e) => { setGeneralDetailsOpen(e.target.open); } }} ref={generalDetailsRef}>
                        <summary>General</summary>
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Bundle.Name")}</label>
                            <Validator
                                value={update.name}
                                name="name"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    if (params.errorMessage && !generalDetailsOpen) {
                                        setGeneralDetailsOpen(true);
                                    }
                                    return (
                                        <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.grow, genStyles.relative)}>
                                            <input
                                                value={params.value}
                                                onChange={(event) => {
                                                    const newUpdate = Util.deepClone(update);
                                                    newUpdate.name = event.target.value;
                                                    setUpdate(newUpdate);
                                                }}
                                                placeholder="e.g. Group A"
                                                className={genStyles.grow}
                                                disabled={!editable}
                                                autoFocus={!bundle}
                                            />
                                            {params.errorMessage && <div className={appClasses.validationError}>{params.errorMessage}</div>}
                                        </div>
                                    )
                                }}
                            </Validator>
                        </div>
                        <div className={appClasses.formGroup}>
                            <label>Description</label>
                            <textarea
                                className={genStyles.grow}
                                value={update.note || ""}
                                onChange={(event) => {
                                    const newUpdate = Util.deepClone(update);
                                    const value = event.target.value;
                                    newUpdate.note = value === "" ? null : value;
                                    setUpdate(newUpdate);
                                }}
                            />
                        </div>
                        <div className={classes.tModesFormGroup}>
                            <Validator
                                value={update.bundleConfig}
                                name="name"
                                validators={["isADurationSpecified"]}
                                errorMessages={["At least one subscription duration needs to be specified."]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    if (params.errorMessage && !generalDetailsOpen) {
                                        setGeneralDetailsOpen(true);
                                    }
                                    return (
                                        <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.relative)}>
                                            <BundleConfigsTable
                                                value={update}
                                                onChange={update => {
                                                    setUpdate(update);
                                                }}
                                                disabled={!editable}
                                                showRemove={isCreate} />
                                            {isCreate &&
                                                <div style={{ margin: '20px 0 0 30px' }}>
                                                    <DefaultSelect
                                                        options={availableDurations}
                                                        placeholder={"Add subscription duration..."}
                                                        value={null}
                                                        onChange={(option) => {
                                                            const newUpdate = Util.deepClone(update);
                                                            const config = new BundleConfig();
                                                            config.subscriptionDurationInDays = option;
                                                            config.status = BundleStatus.ACTIVE;
                                                            config.subscriptionFee = 0;
                                                            newUpdate.bundleConfig.push(config);
                                                            setUpdate(newUpdate);
                                                        }}
                                                        // menuIsOpen={true}                                                    
                                                        styles={(_theme, defaultStyles) => ({
                                                            option: {
                                                                ...defaultStyles.option,
                                                                textAlign: 'left'
                                                            }
                                                        })}
                                                        isDisabled={availableDurations.length === 0 || !editable}
                                                    >
                                                    </DefaultSelect>
                                                </div>}
                                            {params.errorMessage &&
                                                <div className={appClasses.validationError} style={{ bottom: '-28px' }}>{params.errorMessage}</div>}
                                        </div>
                                    );
                                }
                                }
                            </Validator>
                        </div>
                        {adminProfile.features.trackTripInitiative22828 &&
                            <div className={appClasses.formGroup}>
                                <label>{i18n.t("Initiative")}</label>
                                <div className={classNames(appClasses.value, classes.selectValue)}>
                                    <DefaultSelect
                                        options={initiativeOptions ?? []}
                                        value={update.initiative?.id}
                                        onChange={value => {
                                            setUpdate(Util.iAssign(update, { initiative: initiativeOptions?.find(io => io.value === value)?.initiative }));
                                        }}
                                        isSearchable
                                        isDisabled={!initiativeOptions || (adminProfile.features.editAssignedWallet23000 ? false : !editable)}
                                        allowNoValue={true}
                                        allOptionsLabel={"None"}
                                        placeholder="None"
                                        forFilter={true}
                                    />
                                </div>
                            </div>}
                    </details>
                    <details open={transportsDetailsOpen} {...{ onToggle: (e) => setTransportsDetailsOpen(e.target.open) }} ref={transportsDetailsRef}>
                        <summary>Transports</summary>
                        <div className={classes.tModesFormGroup}>
                            <Validator
                                value={update.tmodes}
                                name="name"
                                // validators={["isAModeWithABenefitSpecified"]}
                                // errorMessages={["At least one mode with a discount or reward needs to be specified."]}
                                validators={["isAModeSpecified"]}
                                errorMessages={["At least one mode needs to be specified."]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    if (params.errorMessage && !transportsDetailsOpen) {
                                        setTransportsDetailsOpen(true);
                                    }
                                    return (
                                        <div className={classNames(genStyles.flex, genStyles.column, genStyles.grow, genStyles.relative)}>
                                            <Table className={appClasses.table}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className={appClasses.field}
                                                            style={{
                                                                textAlign: 'right',
                                                                width: '138px'
                                                            }}
                                                        >Transport mode</TableCell>
                                                        {update.tmodes!.length > 0 ?
                                                            <>
                                                                <TableCell className={appClasses.fieldSecondary}
                                                                >
                                                                    <Tooltip title={HIDE_COST_TOOLTIP}>
                                                                        Hide cost
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={appClasses.fieldSecondary}>
                                                                    <Tooltip title={DISCOUNT_TOOLTIP}>
                                                                        Discount
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={appClasses.fieldSecondary}>
                                                                    <Tooltip title={REWARD_POINTS_PER_COST_TOOLTIP}>
                                                                        Reward points per dollar spent
                                                                    </Tooltip>
                                                                </TableCell>
                                                                {false && // Hide for now
                                                                    <TableCell className={appClasses.fieldSecondary}>
                                                                        Reward is indicative
                                                                    </TableCell>}
                                                                <TableCell className={appClasses.fieldSecondary}>
                                                                    <Tooltip title={NOTIFICATION_EMAIL_TOOLTIP}>
                                                                        Notification email
                                                                    </Tooltip>
                                                                </TableCell>
                                                                <TableCell className={appClasses.fieldSecondary}>
                                                                    <Tooltip title={OFFER_DESCRIPTION_TOOLTIP}>
                                                                        Offer description
                                                                    </Tooltip>
                                                                </TableCell>
                                                                {(adminProfile.features.editAssignedWallet23000 ? true : editable) &&
                                                                    <TableCell className={appClasses.fieldSecondary}></TableCell>}
                                                            </> : <TableCell className={appClasses.fieldSecondary} />}
                                                    </TableRow>
                                                </TableHead>
                                                {(params.value as TMode[]).length !== 0 &&
                                                    <TableBody>
                                                        {(params.value as TMode[]).map((mode: TMode, i: number) => {
                                                            const modeId = providers?.find(provider => provider.id === mode.mode) ?? new Provider();
                                                            // It works even if both discountPoints and pointsPerCost come as not null / undefined from api.
                                                            const isPointsPerCost = mode.discountPoints === undefined ||
                                                                (mode.pointsPerCost !== undefined && mode.discountPoints === 0);
                                                            const discountValue: number = isPointsPerCost ?
                                                                (FormatUtil.formatDiscountPercent(mode.pointsPerCost ?? 0, { decimals: 2 })) :
                                                                FormatUtil.formatDiscountPoints(mode.discountPoints);
                                                            const rewardPerCost: number = FormatUtil.truncateToDecimals((mode.rewardPerCost !== undefined ? mode.rewardPerCost : 0) * 100, 1);
                                                            return (
                                                                <TableRow key={mode.mode}>
                                                                    <TableCell className={appClasses.cell}>
                                                                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                                                                            <img src={TransportUtil.getTransportIconFromProvider(modeId)}
                                                                                className={appClasses.modeIcon}
                                                                                alt="" />
                                                                            <span className={genStyles.charSpaceLeft}>{modeId!.modeName}</span>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={appClasses.cell}>
                                                                        <CheckboxStyled
                                                                            checked={mode.hideCost === 1}
                                                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                                const newUpdate = Util.deepClone(update);
                                                                                const updateTMode = newUpdate.tmodes![i];
                                                                                updateTMode.hideCost = event.target.checked ? 1 : 0;
                                                                                setUpdate(newUpdate);
                                                                            }}
                                                                            disabled={!editable} />
                                                                    </TableCell>
                                                                    <TableCell className={appClasses.cell}>
                                                                        <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.relative, genStyles.grow)}>
                                                                            <Select
                                                                                options={discountOptions}
                                                                                value={isPointsPerCost ? discountOptions[0] : discountOptions[1]}
                                                                                onChange={(option) => {
                                                                                    const changeToPointsPerCost = option === discountOptions[0];
                                                                                    if (isPointsPerCost === changeToPointsPerCost) {
                                                                                        return; // Discount type didn't change
                                                                                    }
                                                                                    const newUpdate = Util.deepClone(update);
                                                                                    const updateTMode = newUpdate.tmodes![i];
                                                                                    if (changeToPointsPerCost) {
                                                                                        updateTMode.pointsPerCost = 1;
                                                                                        updateTMode.discountPoints = undefined;
                                                                                    } else {
                                                                                        updateTMode.pointsPerCost = undefined;
                                                                                        updateTMode.discountPoints = 0;
                                                                                    }
                                                                                    setUpdate(newUpdate);
                                                                                }}
                                                                                className={classes.selectDiscType}
                                                                                classNamePrefix={"select"}
                                                                                isDisabled={!editable} />
                                                                            <input
                                                                                type={"number"}
                                                                                min={0}
                                                                                max={isPointsPerCost ? 100 : Number.MAX_SAFE_INTEGER}
                                                                                step={isPointsPerCost ? ".01" : ".1"}
                                                                                // Use 0 as placeholder to indicate that this is the default value. No missing field validation required
                                                                                placeholder={"0"}
                                                                                value={discountValue <= 0 ? "" : discountValue}
                                                                                onChange={(event) => {
                                                                                    const newUpdate = Util.deepClone(update);
                                                                                    if (isPointsPerCost) {
                                                                                        let parsed = parseFloat(event.target.value);
                                                                                        if (isNaN(parsed)) {
                                                                                            parsed = 0;
                                                                                        }
                                                                                        if (parsed > 100) { // force max to 100
                                                                                            return;
                                                                                        }
                                                                                        newUpdate.tmodes![i].pointsPerCost = 1 - (parsed / 100);
                                                                                    } else {
                                                                                        let parsed = parseFloat(event.target.value);
                                                                                        if (isNaN(parsed)) {
                                                                                            parsed = 0;
                                                                                        }
                                                                                        newUpdate.tmodes![i].discountPoints = parsed * 100;
                                                                                    }
                                                                                    setUpdate(newUpdate);
                                                                                }}
                                                                                className={classNames(classes.discountInput, appClasses.hidePlaceholderOnFocus)}
                                                                                disabled={!editable} />
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell className={appClasses.cell}>
                                                                        <input
                                                                            type={"number"}
                                                                            min={0}
                                                                            step={"0.1"}
                                                                            // Use 0 as placeholder to indicate that this is the default value. No missing field validation required
                                                                            placeholder={"0"}
                                                                            value={rewardPerCost <= 0 ? "" : rewardPerCost}
                                                                            onChange={(event) => {
                                                                                const newUpdate = Util.deepClone(update);
                                                                                let parsed = parseFloat(event.target.value);
                                                                                if (isNaN(parsed)) {
                                                                                    parsed = 0;
                                                                                }
                                                                                newUpdate.tmodes![i].rewardPerCost = FormatUtil.truncateToDecimals(parsed / 100, 3);
                                                                                setUpdate(newUpdate);
                                                                            }}
                                                                            className={classNames(classes.discountInput, appClasses.hidePlaceholderOnFocus)}
                                                                            disabled={!editable} />
                                                                    </TableCell>
                                                                    {false && // Hide for now
                                                                        <TableCell className={appClasses.cell}>
                                                                            <CheckboxStyled
                                                                                checked={mode.symbolicRewardPerCost === 1}
                                                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                                                    const newUpdate = Util.deepClone(update);
                                                                                    const updateTMode = newUpdate.tmodes![i];
                                                                                    updateTMode.symbolicRewardPerCost = event.target.checked ? 1 : 0;
                                                                                    setUpdate(newUpdate);
                                                                                }}
                                                                                disabled={!editable} />
                                                                        </TableCell>}
                                                                    <TableCell className={appClasses.cell}>
                                                                        <Validator
                                                                            value={mode.notificationEmail}
                                                                            name="notificationEmail"
                                                                            validators={editable ? ["isEmail"] : []}
                                                                            errorMessages={editable ? ["enter a valid email or leave it blank"] : []}
                                                                            resetOnValid
                                                                        >
                                                                            {(params: { errorMessage: any; }) => {
                                                                                if (params.errorMessage && !transportsDetailsOpen) {
                                                                                    setTransportsDetailsOpen(true);
                                                                                }
                                                                                return <div className={classNames(appClasses.value, genStyles.grow)}>
                                                                                    <input
                                                                                        value={mode.notificationEmail}
                                                                                        onChange={(event) => {
                                                                                            const newUpdate = Util.deepClone(update);
                                                                                            const updateTMode = newUpdate.tmodes![i];
                                                                                            updateTMode.notificationEmail = event.target.value || undefined;
                                                                                            setUpdate(newUpdate);
                                                                                        }}
                                                                                        placeholder="Enter notification email"
                                                                                        className={genStyles.grow}
                                                                                        disabled={!editable} />
                                                                                    <div
                                                                                        className={appClasses.validationError}>{params.errorMessage}</div>
                                                                                </div>;
                                                                            }}
                                                                        </Validator>
                                                                    </TableCell>
                                                                    <TableCell className={appClasses.cell}>
                                                                        <textarea
                                                                            rows={1}
                                                                            className={classNames(appClasses.value, classes.textAreaValue, genStyles.grow)}
                                                                            value={mode.offerDescription || ""}
                                                                            onChange={(event) => {
                                                                                const value = event.target.value;
                                                                                const newUpdate = Util.deepClone(update);
                                                                                const updateTMode = newUpdate.tmodes![i];
                                                                                updateTMode.offerDescription = value === "" ? null : value;
                                                                                setUpdate(newUpdate);
                                                                            }}
                                                                            placeholder="Enter offer description" />
                                                                    </TableCell>
                                                                    {(adminProfile.features.editAssignedWallet23000 ? true : editable) &&
                                                                        <TableCell className={appClasses.cell}>
                                                                            <button className={classes.buttonDelete}
                                                                                // avoid pressing enter on discount input to trigger action on button.
                                                                                type="button"
                                                                                onClick={() => {
                                                                                    const newUpdate: Bundle = Util.deepClone(update);
                                                                                    newUpdate.tmodes!.splice(i, 1);
                                                                                    setUpdate(newUpdate);
                                                                                }}
                                                                                disabled={adminProfile.features.editAssignedWallet23000 ? false : !editable}
                                                                            >
                                                                                <IconRemove className={genStyles.svgFillCurrColor} />
                                                                            </button>
                                                                        </TableCell>}
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>}
                                            </Table>
                                            <div style={{ margin: '20px 0 0 30px' }}>
                                                <DefaultSelect
                                                    options={modeOptions ?? []}
                                                    placeholder={"Add transport..."}
                                                    components={{ Option: ProviderOption }}
                                                    value={null}
                                                    onChange={(option) => {
                                                        const newUpdate = Util.deepClone(update);
                                                        const tMode = new TMode();
                                                        tMode.mode = option;
                                                        tMode.pointsPerCost = 1;
                                                        tMode.symbolicRewardPerCost = 1;
                                                        newUpdate.tmodes!.push(tMode);
                                                        setUpdate(newUpdate);
                                                    }}
                                                    isDisabled={modeOptions?.length === 0 || (adminProfile.features.editAssignedWallet23000 ? false : !editable)}
                                                >
                                                </DefaultSelect>
                                            </div>
                                            {params.errorMessage &&
                                                <div className={appClasses.validationError} style={{ bottom: '-28px' }}>{params.errorMessage}</div>}
                                        </div>
                                    );
                                }
                                }
                            </Validator>
                        </div>
                    </details>
                    {adminProfile.features.bundleImg20922 &&
                        <details open={sponsorDetailsOpen} {...{ onToggle: (e) => setSponsorDetailsOpen(e.target.open) }}>
                            <summary>Sponsor</summary>
                            <div className={appClasses.formGroup}>
                                <label>Title</label>
                                <input
                                    className={appClasses.value}
                                    value={update.sponsorTitle ?? ""}
                                    onChange={(event) => {
                                        setUpdate(Util.iAssign(update, { sponsorTitle: event.target.value }));
                                    }}
                                />
                            </div>
                            <div className={appClasses.formGroup}>
                                <label>Image</label>
                                <div className={appClasses.value} style={{ height: '100%', boxSizing: 'border-box', flexGrow: 1, ...update.sponsorImageUrl && { border: '1px solid lightgray', borderRadius: '4px', padding: '10px' } }}>
                                    <DnDImage
                                        imageUrl={update.sponsorImageUrl}
                                        onImageChange={async (file?: any, url?: string) => {
                                            setUpdate(Util.iAssign(update, { sponsorBase64Img: file ? await Util.fileToBase64(file) : null, sponsorImageUrl: url }));
                                        }}
                                        accept="image/png, image/jpeg"
                                        placeholder={
                                            <div style={{ textAlign: 'center', fontSize: '18px' }}>
                                                Recommended Formatting: <br />
                                                1200 px wide
                                                <br />
                                                PNG or JPEG
                                            </div>
                                        }
                                        styles={(_theme, defaultStyle) => ({
                                            main: {
                                                ...defaultStyle.main,
                                                height: '400px',
                                                overflow: 'hidden'
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                            <div className={appClasses.formGroup}>
                                <label>Description</label>
                                <textarea
                                    className={classes.description}
                                    value={update.sponsorDescription || ""}
                                    onChange={(event) => {
                                        const newUpdate = Util.deepClone(update);
                                        const value = event.target.value;
                                        newUpdate.sponsorDescription = value;
                                        setUpdate(newUpdate);
                                    }}
                                    placeholder={"Please keep your description clear and to the point. We recommend limiting to around 250 characters."}
                                />
                            </div>
                        </details>}
                    <details>
                        <summary>Advanced</summary>
                        <div className={appClasses.formGroup}>
                            <Tooltip title={RESTRICT_VISIBILITY_TOOLTIP}>
                                <label className={appClasses.cursorHelp}>Restrict visibility</label>
                            </Tooltip>
                            <CheckboxStyled
                                checked={update.restrictedOnlyTo !== undefined}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const newUpdate = Util.iAssign(update, {
                                        restrictedOnlyTo: checked ? [] : undefined
                                    });
                                    setUpdate(newUpdate);
                                }}
                            />
                        </div>
                        {update.restrictedOnlyTo !== undefined &&
                            <div className={appClasses.formGroup}>
                                <Tooltip title={ONLY_VISIBLE_TO_TOOLTIP}>
                                    <label className={appClasses.cursorHelp}>Only visible to</label>
                                </Tooltip>
                                <DefaultSelect
                                    options={userTypeOptions}
                                    value={update.restrictedOnlyTo}
                                    onChange={(value) => {
                                        const newUpdate = Util.deepClone(update);
                                        newUpdate.restrictedOnlyTo = value;
                                        setUpdate(newUpdate);
                                    }}
                                    placeholder={"Nobody"}
                                    isMulti
                                />
                            </div>}
                        <div className={appClasses.formGroup}>
                            <Tooltip title={PURCHASE_ONCE_TOOLTIP}>
                                <label className={appClasses.cursorHelp}>{PURCHASE_ONCE_LABEL}</label>
                            </Tooltip>
                            <CheckboxStyled
                                checked={!!update.subscriptionPurchaseOnce}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const newUpdate = Util.iAssign(update, {
                                        subscriptionPurchaseOnce: checked
                                    });
                                    setUpdate(newUpdate);
                                }}
                                disabled={!editable}
                            />
                        </div>
                    </details>
                </div>
            </ValidatorForm >
            <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                <button onClick={onCancelClick} className={appClasses.buttonCancel}>
                    Cancel
                </button>
                <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    {!bundle &&
                        <button onClick={() => onSaveClick(true)} className={classNames(appClasses.buttonOk, classes.another)}>
                            Save and add another
                        </button>}
                    <button onClick={() => onSaveClick()} className={appClasses.buttonAdd}>
                        Save
                    </button>
                </div>
            </div>
        </View >
    );
}

const EditBundleViewStyled = withStyles(EditBundleView, editBundleViewJss);

export const BUNDLE_NEW_VIEW: IViewRouteConfig<{}> =
{
    path: BUNDLES_VIEW.path.map(path => path + "/new"),
    propsFromMatch: (match: any) => ({}),
    propsToPath: (props: {}) => "/new",
    navLabel: () => i18n.t("New.bundle"),
    render: ({ navHistory, waitFor, selectedClientID }) => {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.create)) {
            return null;
        }
        return (
            <EditBundleViewStyled
                onRequestClose={async (update?: Bundle, another?: boolean, reset?: () => void) => {
                    if (update && !update.id) {
                        update.clientId = selectedClientID;
                        try {
                            const newBundle = await waitFor(BundlesData.instance.create(update));
                            BundlesData.instance.invalidateBundlesCaches();
                            if (another) {
                                reset?.();
                            } else if (newBundle) {
                                navHistory.replace(BUNDLE_DETAIL_VIEW, { id: newBundle.id });
                            }
                        } catch (e) {
                            UIUtil.errorMessage(e as Error);
                            navHistory.pop();
                        }
                    } else {
                        navHistory.pop();
                    }
                }}
            />);
    }
};


export const BUNDLE_COPY_VIEW: IViewRouteConfig<{ id: string }> =
{
    path: BUNDLE_DETAIL_VIEW.path.map(path => path + "/copy"),
    propsFromMatch: (match: any) => ({ id: match.params.id }),
    propsToPath: (props: { id: string }) => "/copy",
    navLabel: () => i18n.t("New.bundle"),
    render: ({ viewProps, navHistory, waitFor, selectedClientID }) => {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.create)) {
            return null;
        }
        return (
            <EditBundleViewWithData
                {...viewProps}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (copy?: Bundle) => {
                    if (copy) {
                        try {
                            copy.clientId = selectedClientID;
                            const newBundle = await waitFor(BundlesData.instance.create(copy));
                            BundlesData.instance.invalidateBundlesCaches();
                            navHistory.pop(-2);
                            if (newBundle) {
                                navHistory.push(BUNDLE_DETAIL_VIEW, { id: newBundle.id });
                            }
                        } catch (e) {
                            UIUtil.errorMessage(e as Error);
                        }
                    } else {
                        navHistory.pop();
                    }
                }}
                copy
            />
        )
    }
};

// noinspection JSUnusedLocalSymbols
const EditBundleViewWithData = withAsycnData(EditBundleViewStyled,
    (query: { id?: string }) => query.id ? BundlesData.instance.get(query.id).then((bundle: Bundle | undefined) => { return { bundle } }) as Promise<{ bundle?: Bundle }> : Promise.resolve({ bundle: undefined }));

export const BUNDLE_EDIT_VIEW: IViewRouteConfig<{ id: string, section?: string }> =
{
    path: BUNDLE_DETAIL_VIEW.path.map(path => path + "/edit/section/:section")
        .concat(BUNDLE_DETAIL_VIEW.path.map(path => path + "/edit")),
    propsFromMatch: (match: any) => ({ id: match.params.id, section: match.params.section }),
    propsToPath: (props: { id: string, section?: string }) => "/edit" + (props.section ? `/section/${props.section}` : ""),
    navLabel: () => i18n.t("Edit.bundle"),
    render: ({ viewProps, navHistory, waitFor }) => {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.update)) {
            return null;
        }
        return (
            <EditBundleViewWithData
                {...viewProps}
                expandedSection={viewProps.section}
                renderWhenData={true}
                undefineOnUpdate={false}
                onRequestClose={async (update?: Bundle) => {
                    if (update) {
                        try {
                            await waitFor(BundlesData.instance.update(update));
                            BundlesData.instance.invalidateBundlesCaches();
                        } catch (e) {
                            UIUtil.errorMessage(e as Error);
                            navHistory.pop();
                        }
                    }
                    navHistory.pop();
                }}
            />
        )
    }
};