import React, { useState } from 'react';
import Filter from "../data/Filter";
import { WithClasses } from "../css/StyleHelper";
import { filterPopupJss } from "./FilterPopup.jss";
import { ReactComponent as IconTune } from "../images/ic-tune.svg";
import { ReactComponent as IconClear } from "../images/ic-cross2.svg";
import Util from '../util/Util';
import classNames from 'classnames';
import { FilterConfig } from '.';

type IStyle = ReturnType<typeof filterPopupJss>;

interface IProps extends WithClasses<IStyle> {
    filter: Filter;
    onFilterChange: (filter: Filter) => void;
    filterConfigs: FilterConfig[];
}

const FilterPopup: React.FunctionComponent<IProps> = (props: IProps) => {
    const { filterConfigs, filter, onFilterChange, classes, appClasses } = props;
    const [open, setOpen] = useState(false);
    const handleClearAll = () => {
        let filterUpdate = Util.deepClone(filter);
        props.filterConfigs.forEach(fc => {
            if (fc.onClear) {
                fc.onClear(filterUpdate, (filter: Filter) => { filterUpdate = filter; });
            }
        });
        onFilterChange(filterUpdate);
    };
    const popover =
        <div className={classes.popover}>
            <div className={classes.filters}>
                {props.filterConfigs.map((filterConfig: FilterConfig, i: number) =>
                    <div className={classNames(classes.filterContainer, filterConfig.className)} key={i}>
                        <label className={classes.label}>{filterConfig.label}</label>
                        <div className={classes.control}>{filterConfig.renderControl(filter, onFilterChange)}</div>
                    </div>)}
                {open && filterConfigs.some(fc => fc.valueSummary?.(filter)) &&
                    <button className={classes.clearAllDetailBtn} onClick={handleClearAll}>
                        Clear All
                    </button>}
            </div>
        </div >;
    const summaryValues = props.filterConfigs.map(fc => fc.valueSummary?.(filter)).filter(v => !!v);
    const summary = summaryValues.length === 0 ? null :
        <div className={classes.summary}>
            {filterConfigs.map((filterConfig: FilterConfig, i) => {
                const { showLabelInSummary = true } = filterConfig;
                const summary = filterConfig.valueSummary?.(filter);
                if (!summary) return null;
                return (
                    <div className={classes.filterSummary} key={i}>
                        {showLabelInSummary ? filterConfig.label + ":" : ""} {summary}
                        {filterConfig.onClear &&
                            <button className={classes.clearBtn} onClick={() => filterConfig.onClear!(filter, onFilterChange)}>
                                <IconClear />
                            </button>}
                    </div>
                );
            })}
            {filterConfigs.some(fc => fc.valueSummary?.(filter)) &&
                <button className={classes.clearAllBtn} onClick={handleClearAll}>
                    Clear All
                </button>}
        </div>;
    return (
        <div className={classes.main}>
            <details open={open} {...{ onToggle: (e) => { setOpen(e.target.open); } }}>
                <summary>
                    <div className={classes.summaryContent}>
                        <button className={classes.filterBtn} onClick={() => setOpen(!open)}>
                            <IconTune style={{ height: '100%', width: 'auto' }} />
                        </button>
                        {open ? "Hide Filters" : "Show Filters"}
                    </div>
                </summary>
                {popover}
            </details>
            {!open && summary}
        </div>
    );
};

export default FilterPopup;