import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { ChangeEvent } from "react";
import Bundle, { BundleConfig, BundleStatus } from "../model/Bundle";
import Tooltip from "../uiutil/Tooltip";
import { ACTIVATION_STATUS_TOOLTIP, BUNDLE_DURATION_TOOLTIP, durationStringFromDays } from "./EditBundleView";
import TableBody from "@mui/material/TableBody";
import classNames from "classnames";
import FormatUtil from "../util/FormatUtil";
import genStyles from "../css/general.module.css";
import { CheckboxStyled } from "../booking/BookingsView";
import { ReactComponent as IconRemove } from "../images/ic-cross.svg";
import Util from "../util/Util";
import bundleConfigsTableJss from "./BundleConfigsTable.jss";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { NUMBER_OF_STAFF_TOOLTIP } from "./BundlesView";
import { i18n } from "../i18n/TKI18nConstants";

type IStyle = ReturnType<typeof bundleConfigsTableJss>;

interface IProps extends WithClasses<IStyle> {
    value: Bundle;
    onChange?: (update: Bundle) => void;
    disabled?: boolean;
    showRemove?: boolean;
}

const BundleConfigsTable = (props: IProps) => {
    const { value, onChange, appClasses, classes, disabled, showRemove } = props;
    return (
        <Table className={appClasses.table} style={onChange && { tableLayout: 'fixed' }}>
            <TableHead>
                <TableRow>
                    <TableCell className={appClasses.field}
                        style={onChange ?
                            {
                                textAlign: 'right',
                                width: "138px"
                            } :
                            {
                                width: "30%"
                            }}
                    >
                        <Tooltip title={BUNDLE_DURATION_TOOLTIP}>
                            {i18n.t("Subscription.duration")}
                        </Tooltip>
                    </TableCell>
                    {value.bundleConfig.length !== 0 ?
                        <>
                            <TableCell className={appClasses.fieldSecondary}>
                                Subscription fee
                            </TableCell>
                            {!onChange &&
                                <TableCell className={appClasses.fieldSecondary}>
                                    <Tooltip title={NUMBER_OF_STAFF_TOOLTIP}>
                                        Subscribers
                                    </Tooltip>
                                </TableCell>}
                            <TableCell className={appClasses.fieldSecondary}>
                                <Tooltip title={ACTIVATION_STATUS_TOOLTIP}>
                                    Activation status
                                </Tooltip>
                            </TableCell>
                            {showRemove && onChange &&
                                <TableCell className={appClasses.fieldSecondary}></TableCell>}
                        </> : <TableCell className={appClasses.fieldSecondary} />}
                </TableRow>
            </TableHead>
            {value.bundleConfig.length !== 0 &&
                <TableBody>
                    {value.bundleConfig.map((config: BundleConfig, i: number) => {
                        const { subscriptionDurationInDays, status, subscriptionFee, currentUserCount, futureUserCount } = config;
                        const subscriptionDuration = durationStringFromDays(subscriptionDurationInDays);
                        return (
                            <TableRow key={subscriptionDurationInDays}>
                                <TableCell className={appClasses.cell}
                                    style={onChange && {
                                        textAlign: 'right',
                                        paddingRight: '20px'
                                    }}
                                >
                                    {subscriptionDuration}
                                </TableCell>
                                <TableCell className={appClasses.cell}>
                                    {onChange ?
                                        <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                            <div className={appClasses.currency}>$</div>
                                            <input
                                                type={"number"}
                                                min={0}
                                                step={"0.1"}
                                                // Use 0 as placeholder to indicate that this is the default value. No missing field validation required
                                                placeholder={"0"}
                                                value={subscriptionFee <= 0 ? "" : FormatUtil.truncateToDecimals(subscriptionFee / 100, 2)}
                                                onChange={(event) => {
                                                    const update: Bundle = Util.deepClone(value);
                                                    const parsed = parseFloat(event.target.value);
                                                    update.bundleConfig[i].subscriptionFee = isNaN(parsed) ? 0 : parsed * 100;
                                                    onChange(update);
                                                }}
                                                className={appClasses.hidePlaceholderOnFocus}
                                                disabled={disabled}
                                            />
                                        </div>
                                        :
                                        FormatUtil.toMoney(config.subscriptionFee, { nInCents: true })
                                    }
                                </TableCell>
                                {!onChange &&
                                    <TableCell className={appClasses.cell}>
                                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                                            <div className={classNames(classes.numOfStaffLink)}>
                                                <span className={genStyles.charSpaceRight}>Current:</span>
                                                {config.currentUserCount}
                                            </div>
                                            <div className={classNames(classes.numOfStaffLink)}>
                                                <span className={genStyles.charSpaceRight}>Upcoming:</span>
                                                {config.futureUserCount}
                                            </div>
                                        </div>
                                    </TableCell>}
                                <TableCell className={appClasses.cell}>
                                    {onChange ?
                                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                                            <CheckboxStyled
                                                checked={status === BundleStatus.ACTIVE}
                                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                    const update: Bundle = Util.deepClone(value);
                                                    update.bundleConfig[i].status = event.target.checked ? BundleStatus.ACTIVE : BundleStatus.INACTIVE;
                                                    onChange(update);
                                                }}
                                            />
                                            <span className={genStyles.charSpaceLeft}>
                                                {status === BundleStatus.ACTIVE ? "Activated" : "Deactivated"}
                                            </span>
                                        </div>
                                        :
                                        status === BundleStatus.ACTIVE ? "Activated" : "Deactivated"
                                    }
                                </TableCell>
                                {showRemove && onChange &&
                                    <TableCell className={appClasses.cell}>
                                        <button className={classes.buttonDelete}
                                            // avoid pressing enter on discount input to trigger action on button.
                                            type="button"
                                            onClick={() => {
                                                const update: Bundle = Util.deepClone(value);
                                                update.bundleConfig!.splice(i, 1);
                                                onChange(update);
                                            }}
                                            disabled={disabled}
                                        >
                                            <IconRemove className={genStyles.svgFillCurrColor} />
                                        </button>
                                    </TableCell>
                                }
                            </TableRow>
                        );
                    })}
                </TableBody>}
        </Table>
    );
}

export default withStyles(BundleConfigsTable, bundleConfigsTableJss);


