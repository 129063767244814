import React from "react";
import { genJss } from "../css/gen.jss";
import { ReactComponent as IconSpin } from '../images/ic-refresh.svg';
import { createUseStyles } from 'react-jss';

const loadingViewJss = () => ({
    main: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.center
    },

    iconLoading: {
        ...genJss.alignSelfCenter,
        ...genJss.selfCenter,
        ...genJss.animateSpin,
        margin: '0 5px',
        width: '25px',
        height: '25px'
    }
});

const useStyles = createUseStyles(loadingViewJss);

const Loading: React.SFC<{}> = () => {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <IconSpin className={classes.iconLoading} focusable="false" />
        </div>
    );
};

export default Loading;