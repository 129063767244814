import { black, genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const orgSelectorJss = (theme: Theme) => ({
    wrapper: {
        ...genJss.flex,
        ...genJss.column,
        width: '100%',
        // transform: 'translateY(20px)'
    },
    popup: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 247px)',   // Hardcoded based on its position: 247px is button top (`offsettop`) + 63px of button height. Can calculate it dinamically using button `offsettop`.
        overflowY: 'auto',
        background: 'white',
        transform: 'translateY(63px)',
        borderRight: '1px solid ' + black(5),
        borderBottom: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards'
    },
    tree: {
        ...genJss.flex,
        ...genJss.column,
        padding: '0 10px'
    },
    treeItem: {
        ...theme.textColorDefault,
        ...genJss.borderRadius(4),
        padding: '7px 15px',
        '&:hover': {
            backgroundColor: 'rgba(33, 42, 51, 0.05)'
        }
    },
    selected: {
        backgroundColor: 'rgba(33, 42, 51, 0.12)!important'
    },
    popupOpen: {
        // '&$wrapper': {
        //     height: '100%'
        // }
    },
    main: {
        ...resetJss.button,
        width: '100%',
        padding: '10px 25px 25px 25px',
        marginBottom: '10px',
        ...genJss.flex,
        alignItems: props => !props.isBeta ? 'center' : undefined,
        textAlign: 'left',
        '&:disabled': {
            cursor: 'default',
            '& $angleDown': {
                display: 'none'
            }
        }
    },
    cursorDefault: {
        cursor: 'default'
    },
    open: {
        borderRight: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards'

    },
    '@global': {
        '@keyframes toWhiteBackground': {
            '0%': { background: '#f6f7f7' },
            '100%': { background: 'white' }
        }
    },
    angleDown: {
        ...genJss.noShrink,
        // padding: '3px',
        // marginLeft: '3px',
        width: '9px',
        height: '9px'
    },
    up: {
        transform: 'rotate(180deg)'
    },
    icon: {
        height: '24px',
        width: '24px',
        ...genJss.noShrink,
        '& path': {
            fill: black(1)
        }
    },
    name: {
        ...genJss.grow,
        padding: '2px 16px',
        ...theme.textSizeBody,
        // whiteSpace: 'nowrap' // Reverted to allow text wrapping since organizations with long names get cut off.
    },
    treeIcon: {
        display: 'none!important'
    },
    treeLabel: {
        ...theme.textColorDefault,
        ...genJss.borderRadius(4),
        padding: '5px 0 5px 15px!important',
        margin: '1px 10px!important',
        '&:hover': {
            backgroundColor: 'rgba(33, 42, 51, 0.05)!important'
        }
    },

    partialNode: {
        color: 'gray'
    }
});