import React from 'react';
import { useStyles, WithStyles } from '../css/StyleHelper';
import CopyToClipboard from '../uiutil/CopyToClipboard';
import { Theme } from '../css/Theme';
import { genJss } from '../css/gen.jss';

const viewTitleWithIdJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        whiteSpace: 'nowrap',
        '& > *:not(:first-child)': {
            marginLeft: '15px'
        }
    }
});

type IStyle = ReturnType<typeof viewTitleWithIdJss>;

interface IProps extends WithStyles<IStyle> {
    title: React.ReactNode;
    shortId?: string;
    more?: React.ReactNode;
}

const ViewTitleWithId: React.FunctionComponent<IProps> = props => {
    const { title, shortId, more, classes } = useStyles(props, viewTitleWithIdJss);
    return (
        <div className={classes.main}>
            <div>
                {title}
            </div>
            {shortId &&
                <CopyToClipboard text={shortId} label={<><span style={{ fontWeight: 'bold', fontSize: '20px', marginRight: '3px' }}>#</span>{shortId}</>} />}
            {more}
        </div>
    );
}

export default ViewTitleWithId;