import React, { useContext, useRef } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { organizationsViewJss } from "./OrganizationsView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { i18n } from "../i18n/TKI18nConstants";
import ItemsResult from "../model/ItemsResult";
import { withAsyncDataObs } from "../data/WithAsyncData";
import OrganizationsData from "../data/OrganizationsData";
import Filter from "../data/Filter";
import Util from "../util/Util";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import Loading from "../view/Loading";
import SearchBox, { Item } from "../search/SearchBox";
import PageSizeSelect from "../paginate/PageSizeSelect";
import Paginate from "../paginate/Paginate";
import ViewRestyled from "../view/ViewRestyled";
import { ReactComponent as IconAdd } from '../images/ic-add.svg';
import Organization from "../model/Organization";
import UIUtil from "../util/UIUtil";
import { ORGANIZATION_EDIT_VIEW, ORGANIZATION_NEW_VIEW } from "./EditOrgView";
import OrganizationsTable from "./OrganizationsTable";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import FilterPopup, { FilterConfig } from "../FilterPopup";
import { DefaultSelect } from "../rewards/StatusSelect";
import { useInitiativeOptions } from "../data/InitiativesData";

export interface OrganizationsViewProps {
    data?: ItemsResult<Organization>;
    filter?: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onCreate?: () => void;
    onEdit?: (org: Organization) => void;
    onRemove?: (org: Organization) => void;
    onRefreshClick?: () => void;
}

export interface IProps extends OrganizationsViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof organizationsViewJss>;

const OrganizationsView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const { data, filter, onFilterChange, onCreate, onEdit, onRemove, onRefreshClick, classes, appClasses } = props;
    const { selectedClientID, profile: adminProfile } = useContext(AppContext);
    const initiativeOptions = useInitiativeOptions(selectedClientID);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter!, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    const indent = false;
    let content;
    if (!data) {
        content = <Loading />;
    } else if (data.count === 0) {
        content =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        content = <OrganizationsTable items={data.items} onEdit={onEdit} onRemove={onRemove} />;
    }
    const canAddUser = true;
    const addBtn = onCreate &&
        <button onClick={onCreate} className={appClasses.buttonOk} disabled={!canAddUser}>
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            {i18n.t("Add.organization")}
        </button>;
    const filterPopupConfigs: FilterConfig[] = [
        ...Util.insertIf(adminProfile.features.trackTripInitiative22828,
            {
                label: "Initiative",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={initiativeOptions ?? []}
                            value={filter.initiativeId}
                            onChange={value => {
                                const newFilter = Util.iAssign(filter, { initiativeId: value, page: 1 });
                                props.onFilterChange && props.onFilterChange(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel="All"
                            isSearchable
                            isDisabled={!initiativeOptions}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.initiativeId && initiativeOptions?.find(option => option.value === filter.initiativeId)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { initiativeId: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            })
    ];
    return (
        <ViewRestyled
            title={i18n.t("Organizations")}
            right={
                <div className={classNames(genStyles.flex, genStyles.alignCenter)} style={{ marginRight: '120px' }}>
                    {addBtn}
                </div>
            }
        >
            <div
                className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}
                style={{ overflow: 'auto' }}
            >
                <div className={classes.filters}>
                    {filterPopupConfigs.length > 0 &&
                        <FilterPopup
                            filter={filter}
                            onFilterChange={props.onFilterChange!}
                            filterConfigs={filterPopupConfigs}
                        />}
                    <div className={classes.searchbox}>
                        <SearchBox />
                    </div>
                    {/* {onExportData &&
                        <ExportButton filter={filter} onExportData={onExportData} helpTooltip={EXPORT_USERS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />} */}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                            />
                        </div>
                        {onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
                {content}
                <div className={appClasses.paginatePanel}>
                    {!!pageCount.current &&
                        <Paginate
                            pageCount={pageCount.current}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </div>
        </ViewRestyled>
    );
}

const OrganizationsViewStyled = withStyles(OrganizationsView, organizationsViewJss);

export interface OrganizationsViewProps {
    filter?: Filter;
}

export const OrganizationsViewWithData = withAsyncDataObs(OrganizationsViewStyled,
    (query: Required<Pick<OrganizationsViewProps, "filter">>) => {
        return OrganizationsData.instance.watchQuery(query.filter)
            .pipe(map((result?: ItemsResult<Organization>) => ({ data: result }))) as Observable<{ data?: ItemsResult<Organization>; }>;
    }
);

const organizationsViewId = "ORGANIZATIONS";

export const ORGANIZATIONS_VIEW: IViewRouteConfig<OrganizationsViewProps> =
{
    path: ["*/organizations/filter/:filter", "*/organizations"],
    propsToPath: ({ filter }) => "/organizations" +
        (filter ? "/filter/" + Util.stringifyFilter(filter) : ""),
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(organizationsViewId) ?? 10,
                page: 1,
                // sortOrder: SortOrder.ASC
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { filter: filter };
    },
    navLabel: () => i18n.t("Organizations"),
    render: ({ viewProps, navHistory, profile, onProfileChange, setWaiting, refreshOrgs }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(organizationsViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(ORGANIZATIONS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        const organizationsViewRef = React.createRef<any>();
        const onRefresh = () => {
            OrganizationsData.instance.invalidateCache();
            organizationsViewRef.current.refresh(true);
        };
        const onCreate = () => navHistory.push(ORGANIZATION_NEW_VIEW, {});
        const onEdit = (org: Organization) => navHistory.push(ORGANIZATION_EDIT_VIEW, { id: org.id });
        const onRemove = (org: Organization) => {
            UIUtil.confirmMsg({
                title: 'Confirm to delete',
                onConfirm: async () => {
                    try {
                        setWaiting(true);
                        await OrganizationsData.instance.delete(org);
                        OrganizationsData.instance.invalidateCache();
                        refreshOrgs();
                        await organizationsViewRef.current?.refresh(false);
                    } catch (e) {
                        console.log(e);
                        UIUtil.errorMessage(e as Error);
                    } finally {
                        setWaiting(false);
                    }
                }
            });
        }
        return (
            <OrganizationsViewWithData
                filter={filter}
                onFilterChange={onFilterChange}
                onCreate={onCreate}
                onEdit={onEdit}
                onRemove={onRemove}
                onRefreshClick={onRefresh}
                ref={organizationsViewRef}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        return {
            fields: [],
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace2(ORGANIZATIONS_VIEW, { filter });
            }
        }
    },
    accessible: ({ profile }) => profile.remote.userData.adminManagement
};