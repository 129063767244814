import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const addToOrgViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.spaceBetween,
        height: '100%'
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '40px'
    },
    form: {
        minHeight: '250px',
        paddingBottom: '30px'
    },
    value: {
        ...theme.textColorGray
    },
    bundleValue: {
        width: '350px',
        flexGrow: '0!important',
        boxSizing: 'border-box',
        '&>*': {
            ...genJss.grow
        }
    },
    searchValue: {
        '& input': {
            width: '350px',
            flexGrow: '0!important',
            boxSizing: 'border-box'
        },
        '& button': {
            flexGrow: 0,
            marginLeft: '20px'
        }
    },
    userResultWrapper: {
        ...genJss.flex,
        ...genJss.column,
        marginTop: '40px',
        marginLeft: '55px',
    },
    userResult: {
        ...genJss.flex,
        background: '#2029310a',
        borderRadius: '6px',
        padding: '20px',
        width: 'initial!important',
        '& label': {
            width: '75px'
        }
    },
    noUserResult: {
        ...genJss.alignSelfCenter,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    picture: {
        width: '130px',
        height: '130px',
        borderRadius: '5px',
    },
    addToOrgBtn: {
        ...genJss.alignSelfCenter,
        margin: '30px 0 0 55px'
    },
    createUserBtn: {
        display: 'inline-block',
        '&:hover': {
            textDecoration: 'underline'
        }
    }
});