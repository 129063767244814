import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Subtract } from 'utility-types';

type IProps = Subtract<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, { id?: string }> & {
    id: string;
    /**
     * To inform that the data has arrived, so the scrollTop can be applied (if there's no data / content, then the scrollTop will remain in 0 despite applying the stored scroll.)
     */
    hasData?: boolean;
};

export type MemoScrollRef = { reset: () => void };

const MemoScroll = forwardRef<MemoScrollRef, IProps>(function MemoScroll(props, ref) {
    const { id, hasData } = props;
    const lsKey = "memo_scroll_" + id;
    const scrollRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const scrollTopLS = localStorage.getItem(lsKey);
        if (scrollTopLS !== null && scrollRef.current) {
            scrollRef.current.scrollTop = parseInt(scrollTopLS);
        }
    }, [hasData]);
    useImperativeHandle(ref, () => {
        return {
            reset() {
                const lsKey = "memo_scroll_" + id;
                localStorage.removeItem(lsKey);
                if (scrollRef.current) {
                    scrollRef.current.scrollTop = 0;
                }
            }
        };
    }, []);
    const { hasData: _, ...forwardProps } = props
    return (
        <div
            {...forwardProps}
            onScroll={e => {
                const scrollTop = (e.target as any)?.scrollTop;
                localStorage.setItem(lsKey, scrollTop)
            }}
            ref={scrollRef}
        />
    );
});

export default MemoScroll;