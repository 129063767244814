import {Theme} from "../css/Theme";

export const navStackJss = (theme: Theme) => ({
    main: {
        '& *': {
            textDecoration: 'none',
            opacity: '.7',
            marginRight: '5px'
        },
        '& .active': {
            opacity: '1'
        }
    },
    link: {
        cursor: 'pointer',
        color: theme.colorPrimary,
    }
});