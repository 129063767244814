import { Theme } from "../css/Theme";
import { genJss, important } from "../css/gen.jss";

export const usersViewJss = (theme: Theme) => ({
    tableContainer: {
        ...genJss.flex,
        marginTop: "20px",
        // #scrollx
        overflowX: 'auto',
        '& table': {
            tableLayout: 'fixed',
            // width: '100%',
            width: 'initial',
            ...genJss.grow
        },
        '& th': {
            boxSizing: 'border-box',
        },
        '& td': {
            wordWrap: 'break-word',
            boxSizing: 'border-box',
            whiteSpace: 'pre-wrap!important'
        },
        '& td$orgsCell': {
            whiteSpace: 'nowrap!important'
        }
    },

    spinBar: {
        height: '33px'
    },

    iconError: {
        height: '20px',
        width: '20px',
        fontSize: '25px'
    },

    filters: {
        ...genJss.flex,
        ...genJss.alignStart,
        zIndex: 2,  // To be on top of table sticky header
        '& > *': {
            marginRight: '60px'
        }
    },
    filterSelect: {
        '&>*': {
            width: 'initial!important',
            minWidth: '200px'
        }
    },
    filterCheckbox: {
        width: '200px',
        height: '38px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
        boxSizing: 'border-box'
    },
    disabledFilter: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.noShrink,
        height: '38px',
        '& label': {
            marginRight: '10px'
        }
    },
    searchbox: {
        width: '300px'
    },
    waitingMore: {
        ...genJss.alignSelfCenter,
        marginTop: '20px'
    },

    pictureCell: {
        padding: '5px!important',
        ...important(genJss.flex),
        ...important(genJss.center),
        '& img': {
            width: '45px',
            height: '45px',
            ...genJss.borderRadius(50, '%')
        }
    },
    orgsCell: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '140px'
    },
    export: {
        display: 'flex',
        flexShrink: 0,
        height: '38px',
        alignItems: 'center'
    }
});