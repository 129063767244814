import React, { FunctionComponent } from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { actionsMenuButtonJss } from "./ActionsMenuButton.jss";
import Menu from "@mui/material/Menu/Menu";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { ReactComponent as IconAngleDown } from "../images/ic-angle-down.svg";
import Tooltip from "../uiutil/Tooltip";

export interface MenuAction {
    label: string;
    renderIcon?: ({ className }) => React.ReactNode;
    onClick: () => void;
    disabled?: boolean;
    tooltip?: string;
}

type IStyle = ReturnType<typeof actionsMenuButtonJss>;
export interface IProps extends WithStyles<IStyle> {
    label?: string;
    actions: MenuAction[];
}

const ActionsMenuButton: FunctionComponent<IProps> = (props: IProps) => {
    const { label = "Actions", actions, classes } = useStyles(props, actionsMenuButtonJss);
    const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const hasIcons = actions.some(action => action.renderIcon);
    const actionsMenuOpen = Boolean(actionsMenuAnchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setActionsMenuAnchorEl(event.currentTarget);
    };
    const handleActionsMenuClose = () => {
        setActionsMenuAnchorEl(null);
    };
    const actionsMenu =
        <Menu
            open={actionsMenuOpen}
            anchorEl={actionsMenuAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: -5,
                horizontal: 'left',
            }}
            onClose={handleActionsMenuClose}
        >
            {actions.map((action, i) => {
                const { label, renderIcon = hasIcons ? () => <div className={classes.actionIcon} /> : undefined, onClick, disabled, tooltip } = action;
                const icon = renderIcon?.({ className: classes.actionIcon });
                let result =
                    <MenuItem
                        key={i}
                        onClick={() => {
                            onClick();
                            handleActionsMenuClose();
                        }}
                        disabled={disabled}
                        data-testid={label}
                    >
                        {icon}
                        {label}
                    </MenuItem>;
                if (tooltip) {
                    result =
                        <Tooltip title={tooltip} key={i}>
                            <div>
                                {result}
                            </div>
                        </Tooltip>
                }
                return result;
            })}
        </Menu >
    return (
        <>
            <button onClick={handleClick} className={classes.buttonActions}>
                {label}
                <IconAngleDown />
            </button>
            {actionsMenu}
        </>
    );
};

export default ActionsMenuButton;