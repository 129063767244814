import { appGlobalJss } from "../css/app.jss";
import { black, genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";

export const filterPopupJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.alignCenter,
        flexWrap: 'wrap',
        '& details': {
            border: '1px solid ' + black(3),
            ...genJss.borderRadius(6),
            position: 'relative',
            whiteSpace: 'nowrap',
            ...genJss.alignSelfStart
        },
        '& details:not(:last-child)': {
            marginRight: '20px'
        },
        '& summary': {
            lineHeight: '30px',
            cursor: 'pointer',
            padding: '3px 10px',
            // paddingRight: '36px'    // Filter icon to the right
            // paddingLeft: '36px'    // Filter icon to the left
        }
    },
    filterBtn: {
        ...resetJss.button,
        ...genJss.flex,
        ...genJss.alignCenter,
        // border: '1px solid ' + black(3),
        // ...genJss.borderRadius(6),
        // padding: '7px',
        // height: '30px',
        // width: '100px'

        position: 'absolute',
        top: '5px',
        // right: '8px'    // Filter icon to the right
        // left: '8px' // Filter icon to the left
        left: '30px' // Filter icon in the middle
    },
    popover: {
        ...genJss.flex,
        ...genJss.column,
        borderTop: '1px solid #212a332e'
    },
    filters: {
        ...genJss.flex,
        padding: '10px',
        flexWrap: 'wrap'
    },
    filterContainer: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart,
        padding: '6px 0',
        '&:not(:last-child)': {
            marginRight: '10px'
        }
    },
    label: {
        marginLeft: '14px'
    },
    control: {
        padding: '3px'
    },
    summary: {
        ...genJss.flex,
        flexWrap: 'wrap'
    },
    filterSummary: {
        padding: '5px',
        background: 'lightgray',
        ...genJss.borderRadius(4),
        whiteSpace: 'nowrap',
        margin: '3px 0',
        '&:not(:last-child)': {
            marginRight: '20px'
        }
    },
    clearBtn: {
        ...resetJss.button,
        height: '16px',
        width: '16px',
        padding: '3px',
        marginLeft: '5px',
        '& svg': {
            height: '100%',
            width: '100%'
        }
    },
    summaryContent: {
        display: 'inline-flex',
        ...genJss.alignCenter,
        marginLeft: '35px'  // Filter icon in the middle
    },
    clearAllBtn: {
        ...appGlobalJss(theme).buttonLink,
        margin: '6px 0'
    },
    clearAllDetailBtn: {
        ...appGlobalJss(theme).buttonLink,
        // alignSelf: 'flex-end',
        // margin: '10px 14px 20px 14px'
        alignSelf: 'center',
        margin: '16px 14px 0px 14px'
    }
});