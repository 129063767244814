import {JsonProperty, JsonObject} from "json2typescript";
import {Moment} from "moment-timezone";

class ItemGroup<T> {
    public readonly items: T[];
    public readonly title: string;

    constructor(bookings: T[], title: string) {
        this.items = bookings;
        this.title = title;
    }
}

export default ItemGroup;