import React, { useState, useContext } from 'react';
import { WithClasses, withStyles } from "../css/StyleHelper";
import { globalSearchJss } from "./GlobalSearch.jss";
import AutocompleteBox, { AutocompleteBoxType } from '../autocomplete/AutocompleteBox';
import User from '../model/User';
import Transaction from '../model/Transaction';
import Bundle from '../model/Bundle';
import TransactionsData from '../data/TransactionsData';
import { AppContext, getClientIDPath, getOrgIDPath, appPathUpdate } from '../app/App';
import UsersData from '../data/UsersData';
import BundlesData from '../data/BundlesData';
import AutocompleteResult from '../autocomplete/AutocompleteResult';
import BookingAutocompleteResult from './BookingAutocompleteResult';
import { ReactComponent as IconUser } from "../images/ic-user.svg";
import { ReactComponent as IconWallet } from "../images/ic-wallet.svg";
import { ReactComponent as IconFromTo } from "../images/ic-from-to.svg";
import classNames from 'classnames';
import genStyles from "../css/general.module.css";

interface GlobalSearchResult {
    value: User | Transaction | Bundle;
    query: string;
}

type IStyle = ReturnType<typeof globalSearchJss>;

interface IProps extends WithClasses<IStyle> { }

const GlobalAutocompleteBox = AutocompleteBox as AutocompleteBoxType<GlobalSearchResult>;

const GlobalSearch: React.FunctionComponent<IProps> = (props: IProps) => {
    const { classes, appClasses } = props;
    function resultsFor(query: string): Promise<GlobalSearchResult[]> {
        // In the future, instead of queary, I will compute here the matched substring (or it will come from the backend).
        if (query.toLowerCase().startsWith("t")) {
            return TransactionsData.instance.search(query, { limit: 10, clientId: getClientIDPath(), orgId: getOrgIDPath() })
                .then(values => values.map((transaction) => ({ value: transaction, query })));
        } else if (query.toLowerCase().startsWith("u")) {
            return UsersData.instance.search(query, { limit: 10, clientId: getClientIDPath(), orgId: getOrgIDPath() })
                .then(values => values.map((transaction) => ({ value: transaction, query })));;
        } else if (query.toLowerCase().startsWith("w")) {
            return BundlesData.instance.search(query, { limit: 10, clientId: getClientIDPath() })
                .then(values => values.map((transaction) => ({ value: transaction, query })));
        }
        return Promise.resolve([]);
    }
    return (
        <div className={classNames(classes.main, appClasses.focusGlow)}>
            <GlobalAutocompleteBox
                onChange={(selected?: GlobalSearchResult) => {
                    if (selected?.value instanceof User) {
                        window.location.replace(`#${appPathUpdate({ path: "/parts/id/" + selected.value.id })}`)
                    } else if (selected?.value instanceof Transaction) {
                        window.location.replace(`#${appPathUpdate({ path: "/trans/all/id/" + selected.value.id })}`)
                    } else if (selected?.value instanceof Bundle) {
                        window.location.replace(`#${appPathUpdate({ path: "/bundles/id/" + selected.value.id })}`)
                    }
                }}
                resultsFor={resultsFor}
                toText={(selected?: GlobalSearchResult) => {
                    return (selected instanceof User ? selected.name :
                        selected instanceof Transaction ? selected.mode :
                            selected instanceof Bundle ? selected.name : "") ?? "";
                }}
                clearOnSelection={true}
                placeholder='Search by id'
                showGlassIcon={true}
                renderResult={(item: GlobalSearchResult, isHighlighted: boolean) => {
                    const value = item.value;
                    const query = item.query;
                    const matchHighlight = value.shortId.toLowerCase().startsWith(query.toLowerCase()) ?
                        <><span style={{ fontWeight: 'bold' }}>{value.shortId.substring(0, query.length)}</span>{value.shortId.substring(query.length)}</> : value.shortId;
                    if (value instanceof User) {
                        return (
                            <AutocompleteResult
                                icon={<IconUser />}
                                title={value.name}
                                subtitle={
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {value.email}
                                        <div>
                                            {matchHighlight}
                                        </div>
                                    </div>
                                }
                                isHighlighted={isHighlighted}
                                key={value.id}
                            />
                        );
                    } else if (value instanceof Bundle) {
                        return (
                            <AutocompleteResult
                                icon={<IconWallet />}
                                title={value.name}
                                subtitle={matchHighlight}
                                isHighlighted={isHighlighted}
                                key={value.id}
                            />
                        );
                    } else if (value instanceof Transaction) {
                        return (
                            <AutocompleteResult
                                icon={<IconFromTo className={classes.iconFromTo} />}
                                title={<BookingAutocompleteResult booking={value} />}
                                // subtitle={matchHighlight}
                                subtitle={
                                    <div style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' }}>
                                        {matchHighlight}
                                        <span className={classNames(genStyles.charSpace, genStyles.charSpaceLeft)}>⋅</span>
                                        <div style={{ flexShrink: 0 }}>
                                            {"By " + value.userName}
                                        </div>
                                    </div>
                                }
                                isHighlighted={isHighlighted}
                                key={value.id}
                            />
                        );
                    }
                    return <></>;
                }}
                selectOnBlur={false}
                styles={(_theme, defaultStyle) => ({
                    menu: { ...defaultStyle.menu, zIndex: 2 }   // So it's above the table headers (sticky)
                })}
            />
        </div>
    );
};

export default withStyles(GlobalSearch, globalSearchJss);