import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { ReactComponent as IconRemove } from '../images/ic-cross2.svg';
import Modal from 'react-modal';
import classNames from "classnames";
import { sideViewJss } from "./SideView.jss";

interface IProps extends WithClasses<IStyle> {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    onRequestClose?: () => void;
    children: React.ReactNode;
}

type IStyle = ReturnType<typeof sideViewJss>;


const SideView: React.FunctionComponent<IProps> = (props: IProps) => {
    const classes = props.classes;
    return (
        <Modal
            isOpen={true}
            style={{
                overlay: { position: 'absolute' }
            }}
            className={{
                // overlay: classes.modalContainer,
                base: classNames(classes.modal),
                afterOpen: classes.slideIn,
                beforeClose: classes.slideOut
            }}
            shouldCloseOnEsc={true}
            onRequestClose={() => props.onRequestClose && props.onRequestClose()}
        >
            <div className={classes.main}>
                {(props.title || props.subtitle || props.onRequestClose) &&
                    <div className={classes.header}>
                        <div className={classes.headerTop}>
                            <div className={classes.title}>
                                {props.title}
                            </div>
                            {props.onRequestClose &&
                                <button onClick={() => props.onRequestClose && props.onRequestClose()} className={classes.btnClear}>
                                    <IconRemove aria-hidden={true}
                                        className={classes.iconClear}
                                        focusable="false" />
                                </button>}
                        </div>
                        {props.subtitle &&
                            <div className={classes.subtitle}>
                                {props.subtitle}
                            </div>}
                    </div>}
                <div className={classes.body}>
                    {props.children}
                </div>
            </div>
        </Modal>
    );
};


export default withStyles(SideView, sideViewJss);