import { SearchField } from "../data/Filter";
import { AppContextProps } from "../app/App";
import { Item } from "../search/SearchBox";
import AdminProfile from "../account/AdminProfile";

export interface IViewRouteConfig<T> {
    path: string[];
    propsFromMatch: (match: any, profile: AdminProfile) => T;
    propsToPath: (params: T) => string;
    // justData in true means just data of the view should be rendered, but not the view itself.
    render: (routeProps: { match: boolean; viewProps: T; justData?: boolean } & AppContextProps) => JSX.Element | null;
    renderAlways?: boolean;
    navLabel: (routeProps: { viewProps: T }) => JSX.Element | string;
    searchProps?: (routeProps: { viewProps: T } & AppContextProps) =>
        { fields?: IFieldAutocomplete[], value?: Item; onChange: (value?: Item) => void, clientId?: string } | undefined;
    subViews?: IViewRouteConfig<any>[],
    isModal?: boolean;
    // It states that, whenever match, the data part of the view should be rendered, even if the view should not.
    // E.g. TRANSACTIONS_VIEW should always render data part of the view, even when BOOKING_DETAIL_VIEW (full screen
    // view) is displaying, despite view itself shouldn't be displayed. This maintains the data state of
    // TRANSACTIONS_VIEW while BOOKING_DETAIL_VIEW is showing, so when going back you immediately see the same than
    // before pushing BOOKING_DETAIL_VIEW, even in 'Next' view, where the associated graphql query is relative to now
    // and so when WithAsyncData re-request the data it misses the Apollo caché. In this case this renderDataIfMatch
    // avoid the display of a spinner until graphql query is resolved.
    renderDataIfMatch?: boolean;
    id?: string;
    accessible?: (props: AppContextProps) => boolean;
}

export interface IFieldAutocomplete {
    field: SearchField;
    predictor: (text: string) => Promise<Item[]>;
}

export const identityPredictor = (text: string) => Promise.resolve([{ label: text }]);
export const noPredictor = (text: string) => Promise.resolve([]);