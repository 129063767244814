import { colorWithOpacity } from "tripkit-react/dist/jss/TKUITheme";
import { Theme } from "../css/Theme";
import { black, genJss } from "../css/gen.jss";
import { PRODUCT_HIGHLIGHT_COLOR } from "./ProviderView";

// noinspection JSUnusedLocalSymbols
export const providerProductJss = (theme: Theme) => ({
    main: {
        borderRadius: '8px',
        padding: '8px',
        border: `1px solid ${black(3)}`,
        '&:hover': {
            borderColor: PRODUCT_HIGHLIGHT_COLOR
        }
    },
    title: {
        fontSize: '20px',
        padding: '16px'
    },
    header: {
        borderRadius: '8px',
        borderBottom: 'none!important',
        background: colorWithOpacity(black(), .1) + '!important'
    },
    allDay: {
        ...genJss.flex,
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    from: {
        ...genJss.flex,
        justifyContent: 'flex-end',
        whiteSpace: 'nowrap',
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    dash: {
        ...genJss.flex,
        justifyContent: 'center',
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    to: {
        ...genJss.flex,
        justifyContent: 'flex-start',
        whiteSpace: 'nowrap',
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    pricingRule: {
        ...genJss.flex,
        ...genJss.column,
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    pricingRuleName: {

    },
    pricingRuleDescription: {
        ...theme.textColorGray,
        ...theme.textSizeCaption
    },
    fareSacalesAndUnit: {
        ...genJss.flex,
        ...genJss.column,
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    fareName: {
        ...genJss.flex,
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    fareScales: {
        ...genJss.flex,
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    fareAmount: {
        ...genJss.flex,
        ...genJss.center,
        whiteSpace: 'nowrap',
        '&:not(:first-child)': {
            marginTop: '24px'
        }
    },
    row: {
        '&:last-child': {
            border: 'none'
        }
    },
    pricingTable: {
        borderCollapse: 'separate!important'
    }
});