import {JsonObject, JsonProperty} from "json2typescript";

@JsonObject
class Reward {
    @JsonProperty("id", String, true)   // It's optional for reward creation.
    public readonly id?: string = undefined;
    @JsonProperty("title")
    public readonly title: string = "";
    @JsonProperty("points", Number, true)
    public readonly points?: number = undefined;
    @JsonProperty("code", String, true)
    public readonly code?: string = undefined;
    @JsonProperty("description", String, true)  // Left as optional to avoid exception if missing on BE.
    public readonly description: string = "";
    @JsonProperty("expiryDate", String, true)
    public readonly expiryDate?: string = undefined;
    @JsonProperty("termsURL", String, true)
    public readonly url?: string = undefined;
    @JsonProperty("imageURL", String, true)
    public readonly imageUrl?: string = undefined;
    @JsonProperty("active", Boolean, true)
    public readonly active: boolean = false;
    @JsonProperty("moneyAmount", Number, true)
    public readonly moneyAmount?: number = undefined;
    @JsonProperty("estimatedTimeOfApproval", Number, true)
    public readonly estimatedTimeOfApproval?: number = undefined;
    @JsonProperty("featured", Boolean, true)
    public readonly featured: boolean = false;
    @JsonProperty("featuredUpTo", String, true)
    public readonly featuredUpTo?: string = undefined;

    public image?: any;
}

export default Reward;