import Util from "../util/Util";
import Transaction, { MoneyTransaction, TransType } from "../model/Transaction";
import { default as AppSync } from "./AppSync";
import {
    createBooking, createTransaction,
    deleteBooking,
    deleteMoneyTrans,
    getBooking,
    getBookings,
    IListBookingsQuery,
    updateBooking,
    updateMoneyTrans,
    updateRedemption,
    GET_BOOKING_QUERY,
    LIST_BOOKINGS_QUERY,
    BOOKING_MODE_FIELD,
    bookingUpdateFieldsBuilder,
    payBooking,
    PAY_BOOKING_QUERY,
    getExpiringPaymentsBookings,
    GET_EXPIRING_PAYMENTS_BOOKINGS,
    PriceChangeInput,
    createPriceChangeRequest,
    PriceChangeResponse,
    priceChangeResponse,
    changePrice,
    updateExternalBooking
} from "./TransactionsSchema";
import Filter, { SearchField, SortOrder } from "./Filter";
import * as moment from 'moment-timezone';
import ItemsData from "./ItemsData";
import { adminProfile, FieldCondition, ModelOperation, ModelType } from "../account/AdminProfile";
import DateTimeUtil from "../util/DateTimeUtil";
import NetworkUtil from "../util/NetworkUtil";
import GQLError from "../util/GQLError";
import { getOrgsFromFilter } from "../app/OrgSelectorHelpers";

function transQueryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    if (filter.range && filter.range.start.isAfter(filter.range.end)) {
        return null;
    }
    if (filter.charge) {
        const { clientId } = filter;
        return getExpiringPaymentsBookings({
            clientId
        });
    }

    // It's important to immutable update of start and end dates    
    let startMoment;
    let endMoment;
    if (filter.range) {
        startMoment = moment(filter.range.start);
        endMoment = moment(filter.range.end);
        if (endMoment.get("hour") === 0 && endMoment.get("minute") === 0) {
            endMoment.set("hour", 23).set("minute", 59);
        }
    } else {
        if (filter.startFromNow !== undefined) {
            startMoment = DateTimeUtil.getNow().add(filter.startFromNow, 'seconds')
        }
        if (filter.endFromNow !== undefined) {
            endMoment = DateTimeUtil.getNow().add(filter.endFromNow, 'seconds')
        }
    }
    const bookingsQueryParams: IListBookingsQuery = {
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        // dateInit: Math.floor(moment(filter.range.start).set("hour", 0).set("minute", 0).valueOf()/1000),
        // dateEnd: Math.floor(moment(filter.range.end).set("hour", 23).set("minute", 59).valueOf()/1000),
        // Don't round dateInit to start of day, nor dateEnd to end of day, to allow setting range filters with time
        // granularity. If endMoment is exactly at hour 0 and minute 0, then assume the date comes from the range picker,
        // which has day granularity, and so round dateEnd to end of day. Improve this by making DateRangePicker to
        // handle ranges in HTML5 date format, without time part, so we can distinguish between the case of day
        // granularity and the case where hour and minute are actually both 0.
        dateInit: startMoment && Math.floor(startMoment.valueOf() / 1000),
        dateEnd: endMoment && Math.floor(endMoment.valueOf() / 1000),
        clientId: filter.clientId,
        userId: filter.userId ?? filter.user?.id,
        bundleId: filter.bundleId ?? filter.bundle?.id,
        type: filter.type,
        status: filter.status,
        paymentStatus: filter.paymentStatus,
        nextToken: nextToken,
        mode: filter.mode,
        organizationIds: getOrgsFromFilter(filter.orgId),
        priceChange: filter.priceChange,
        balanceID: filter.balanceID,
        initiativeId: filter.initiativeId,
    };
    const searchQuery = filter.search;

    const itemAuth = adminProfile.itemAuth(ModelType.Transaction, ModelOperation.read);
    // The general implementation should iterate over itemAuth.conditions and for each one set the corresponding
    // field in bookingsQueryParams with the condition value.
    const modeCondition = itemAuth && itemAuth.conditions?.find((fieldCond: FieldCondition) => fieldCond.field === BOOKING_MODE_FIELD);
    if (modeCondition) {
        bookingsQueryParams.mode = modeCondition.values ? modeCondition.values[0] :
            modeCondition.valueTokenField ? adminProfile[modeCondition.valueTokenField] : undefined;
    }
    if (searchQuery) {
        if (!bookingsQueryParams.mode && searchQuery.field === SearchField.MODE) {
            bookingsQueryParams.mode = searchQuery.label;
        }
        if (searchQuery.field === SearchField.BUNDLE) {
            bookingsQueryParams.bundleId = searchQuery.data || searchQuery.label;
        }
        if (searchQuery.field === SearchField.USER_ID) {
            bookingsQueryParams.userId = searchQuery.label
        }
        if (searchQuery.field === SearchField.USER) {
            bookingsQueryParams.userId = searchQuery.data;
        }
        if (searchQuery.field === undefined) {
            bookingsQueryParams.search = searchQuery.label;
        }
    }
    bookingsQueryParams.limit = limit;
    return getBookings(bookingsQueryParams);
}

function processResults({ data, errors }) {
    if (!data || (!data[LIST_BOOKINGS_QUERY] && !data[GET_EXPIRING_PAYMENTS_BOOKINGS])) {
        return { error: errors && errors[0] && errors[0].message || "This data is currently unavailable" };
    }
    const queryResult = data[LIST_BOOKINGS_QUERY] ?? data[GET_EXPIRING_PAYMENTS_BOOKINGS];
    const items = queryResult.items
        // In case BALANCE transactions come from BE, we don't want to show them.
        .filter((itemJson: any) => itemJson.type !== "BALANCE")
        .map((itemJson: any) => Util.deserialize(itemJson, Transaction));
    return { items, nextToken: queryResult.nextToken }
}

class TransactionsData extends ItemsData<Transaction> {

    private static _instance: TransactionsData;

    public static get instance(): TransactionsData {
        if (!this._instance) {
            this._instance = new TransactionsData(transQueryFromFilter, processResults);
        }
        return this._instance;
    }

    public search(query: string, options: { limit?: number, clientId?: string, orgId?: string | null } = {}): Promise<Transaction[]> {
        const { limit = 5, clientId, orgId } = options;
        const queryParams: IListBookingsQuery = {
            search: query,
            limit,
            type: TransType.BOOKING,
            clientId,
            organizationIds: orgId === null ? undefined : getOrgsFromFilter(orgId),
            dateInit: 1641016800,
            dateEnd: DateTimeUtil.getNowDate().add(365, 'days').valueOf() / 1000
        };
        return AppSync.query({
            query: getBookings(queryParams)
        }).then((data: any) => {
            return data.data[LIST_BOOKINGS_QUERY].items
                .slice(0, 5)    // TODO: check if this is still necesessary
                .map((resultJson: any) => Util.deserialize(resultJson, Transaction));
        })
    }

    public getBookings(query: IListBookingsQuery): Promise<Transaction[]> {
        return AppSync.query({
            query: getBookings(query)
        }).then(({ data }) => {
            const bookingsData = data[LIST_BOOKINGS_QUERY];
            if (!bookingsData) {
                throw new GQLError('Bookings not found', undefined, true);
            }
            return bookingsData.items.map((itemJson: any) => Util.deserialize(itemJson, Transaction));
        });
    }

    public get(id: string, options: { fetchPolicy?: string } = {}): Promise<Transaction> {
        return AppSync.query({
            query: getBooking(id),
            ...options
        }).then(({ data }) => {
            const booking = data[GET_BOOKING_QUERY];
            if (!booking) {
                throw new GQLError('Booking not found for id: ' + id, undefined, true);
            }
            return Util.deserialize(booking, Transaction);
        });
    }

    public pay(paymentId: string, clientId?: string): Promise<string> {
        return AppSync.query({
            query: payBooking(paymentId, clientId)
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                const { result } = data[PAY_BOOKING_QUERY];
                return result;
            });
    }

    public create(value: Transaction): Promise<void> {
        return AppSync.mutate({
            mutation: createBooking(value)
        });
    }

    public update(value: Transaction): Promise<void> {
        let updateForType;
        switch (value.type) {
            case TransType.REDEMPTION:
                updateForType = updateRedemption;
                break;
            case TransType.BOOKING:
                updateForType = updateBooking;
                break;
        }
        if (!updateForType) {
            return Promise.reject("Update for " + value.type + " unsupported");
        }
        return AppSync.mutate({
            mutation: updateForType(value)
        })
            .then(NetworkUtil.rejectOnGQLError)
    }

    public updateInSatapp(value: Transaction, satappEndpoint): Promise<void> {
        return NetworkUtil.apiCallUrl(satappEndpoint + value.id, {
            method: "POST",
            headers: NetworkUtil.getTGApiHeaders(),
            body: JSON.stringify(Util.filterKeys(Util.serialize(value), bookingUpdateFieldsBuilder()))
        }).then((response) => console.log(response));
    }

    public updateExternalBooking(id: string, clientId: string) {
        return AppSync.mutate({
            mutation: updateExternalBooking(id, clientId)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public delete(id: string, clientId?: string): Promise<void> {
        return AppSync.mutate({
            mutation: deleteBooking(id, clientId)
        });
    }

    public createMoneyTransaction(value: MoneyTransaction): Promise<void> {
        return AppSync.mutate({
            mutation: createTransaction(value)
        });
    }

    public updateMoneyTrans(value: MoneyTransaction): Promise<void> {
        return AppSync.mutate({
            mutation: updateMoneyTrans(value)
        });
    }

    public deleteMoneyTrans(id: string): Promise<void> {
        return AppSync.mutate({
            mutation: deleteMoneyTrans(id)
        });
    }

    public invalidateTransCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_BOOKINGS_QUERY) && cache.data.delete(key);
        }
        );
    }

    public invalidateExpiringPaymentsBookingsCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(GET_EXPIRING_PAYMENTS_BOOKINGS) && cache.data.delete(key);
        }
        );
    }

    public invalidateTransCacheById(id: string) {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => key === id && cache.data.delete(key));
    }

    public getExpiringPaymentsBookings(query: { clientId?: string, limit?: number; nextToken?: string; }): Promise<Transaction[]> {
        return AppSync.query({
            query: getExpiringPaymentsBookings(query)
        }).then(({ data }) => {
            const bookingsData = data[GET_EXPIRING_PAYMENTS_BOOKINGS];
            if (!bookingsData) {
                throw new GQLError('Bookings not found', undefined, true);
            }
            return bookingsData.items.map((itemJson: any) => Util.deserialize(itemJson, Transaction));
        });
    }

    public changePrice(clientId: string, value: PriceChangeInput): Promise<void> {
        return AppSync.mutate({
            mutation: changePrice(clientId, value)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public createPriceChangeRequest(clientId: string, value: PriceChangeInput): Promise<void> {
        return AppSync.mutate({
            mutation: createPriceChangeRequest(clientId, value)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public priceChangeResponse(clientId: string, value: PriceChangeResponse): Promise<void> {
        return AppSync.mutate({
            mutation: priceChangeResponse(clientId, value)
        })
            .then(NetworkUtil.rejectOnGQLError);
    }

}

(window as any).TransactionsData = TransactionsData;

export default TransactionsData;