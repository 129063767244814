import Organization from '../model/Organization';
import { adminProfile } from '../account/AdminProfile';


type OrgNode = { org: Organization, children: OrgNode[] };

/**
 * Versions that just include organizations that the user has access to.
 */
function insertInTree2(org: Organization, tree: OrgNode): OrgNode {
    const child = tree.children.find(child => org.path.startsWith(child.org.path))    // find a child that is an ancestor of node.
    if (child) {
        insertInTree2(org, child);
    } else {
        tree.children.push({ org, children: [] });
    }
    return tree;
}

function orgsAsTree2(orgs: Organization[]): OrgNode {
    orgs.sort((org1, org2) => org1.path.localeCompare(org2.path)); // Sort by path lexicographically, which ensures tree nodes are in pre-order    
    return orgs.reduce((tree: OrgNode | undefined, org: Organization) => {
        if (!tree) {
            return { org, children: [] };
        }
        if (!org.fullAccess) {
            return tree;
        }
        return insertInTree2(org, tree);
    }, undefined)!;
}

export function orgFromId(orgId: string | undefined, orgs: Organization[]): Organization | undefined {
    return orgId ? orgs.find(o => o.id === orgId) : orgs.find(o => isRootOrg(o));
}

/**
 * @param tree 
 * @returns a version of the tree without non direct access nodes with a single child.
 * Could alternatively simply remove (hide from selector) non direct access nodes, and move children 1 level up (replace node by children).
 */
function removeRedundantNodes(tree: OrgNode): OrgNode {
    if (tree.children.length === 0) {
        return tree;
    }
    tree.children = tree.children.map(child => removeRedundantNodes(child));
    if (!tree.org.fullAccess && tree.children.length === 1) {
        return tree.children[0];
    }
    return tree;
}

function sortTree(tree: OrgNode): OrgNode {
    tree.children.sort((a, b) => a.org.name.localeCompare(b.org.name));
    tree.children.forEach(child => sortTree(child));
    return tree;
}

/**
 * Returns the subtree of organizations that have the given client id.
 * It assumes that the organization at the root has no client id, or coincides with the given client id.
 * @param tree an organizations tree.
 * @param clientId a given client id.
 * @returns the subtree of organizations that have the given client id.
 */
export function clientSubtree(tree: OrgNode, clientId): OrgNode | null {
    tree.children = tree.children
        .filter(child => child.org.clientId === clientId)
        .reduce((children, child) => {
            const childSubTree = clientSubtree(child, clientId);
            if (childSubTree) {
                children.push(childSubTree);
            }
            return children;
        }, [] as OrgNode[]);
    return tree;
}


export function descendentOrg(org1: Organization, org2: Organization): boolean {
    return org1.path.startsWith(org2.path);
}

export function isRootOrg(org: Organization): boolean {
    return org.path.length === 4;
}

/**
 * Calculates the filter (list of organizations) that needs to be sent with gql queries (e.g. listUsers) to match the organization selection (single organization selected).
 * @param selectedOrgId The id of the selected organization
 * @param orgs All the organizations
 * @param adminOrgIds Ids of the organizations the admin has access to
 * @returns descendents of the selected organization that are accessible by the admin, that is, that are also descendents of an org listed as accessible for the admin. 
 * The list is optimized to exclude redundant organizations, that is, organizations whose parents are also in the list.
 */
export function orgSelectionFilter(selectedOrgId: string, orgs: Organization[], adminOrgIds?: string[]): Organization[] {
    // All those orgs that the admin has access to such that are descendent of org
    const selectedOrg = orgs.find(o => o.id === selectedOrgId)!;
    const adminOrgs = orgs.filter(org => !adminOrgIds || adminOrgIds.includes(org.id));
    // Descendents of selected organization.
    const orgDescendents = orgs.filter(o => descendentOrg(o, selectedOrg));
    // Descendents of selected organization that are accessible by the admin, that is, that are also descendent of an org listed as accessible by the admin.
    const accessibleOrgDescendents = orgDescendents.filter(o => adminOrgs.some(ao => descendentOrg(o, ao)));
    // Remove redundant organizations, that is, descendents of others in accessible orgs.
    const optimizedAccessibleOrgs = accessibleOrgDescendents.filter(o => !accessibleOrgDescendents.some(ao => ao !== o && descendentOrg(o, ao)));
    return optimizedAccessibleOrgs;
}

export function getOrgsFromFilter(filterOrgId?: string): string[] | undefined {
    const selectedOrgId = filterOrgId ??
        // This is just necessary for org users, that may have no selected org, but still should be restricted to accessible ones.
        // TODO: see how to avoid this.
        (adminProfile.isOrgUser && adminProfile.orgs ? adminProfile.orgs.find(o => isRootOrg(o))?.id : undefined);
    let orgsFilter = selectedOrgId !== undefined ?
        orgSelectionFilter(selectedOrgId, adminProfile.orgs!, adminProfile.orgIds)
        : undefined;
    if (orgsFilter && orgsFilter.length === 1 && isRootOrg(orgsFilter[0])) {
        orgsFilter = undefined;
    }
    return orgsFilter?.map(org => org.id);
}

export function orgSelectorTree(orgs: Organization[]): OrgNode {
    // return removeRedundantNodes(orgsAsTree2(orgs));
    if (adminProfile.isOrgUser)
        return removeRedundantNodes(orgsAsTree2(orgs));
    else {
        return orgsAsTree2(orgs);
    }
}