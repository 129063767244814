import React, { useState } from "react";
import Filter from "../data/Filter";
import { ReactComponent as IconExport } from "../images/ic-export.svg";
import { ReactComponent as IconSpin } from '../images/ic-refresh.svg';
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { exportButtonJss } from "./ExportButton.jss";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MDashTooltip from "../uiutil/Tooltip";
import UIUtil from "../util/UIUtil";
import { i18n } from "../i18n/TKI18nConstants";

type IStyle = ReturnType<typeof exportButtonJss>;

const exportFormats = ["json", "csv"] as const;
export type ExportFormat = typeof exportFormats[number];

interface IProps extends WithClasses<IStyle> {
    label?: string;
    helpTooltip?: string;
    filter: Filter;
    onExportData: (filter: Filter, format: ExportFormat) => Promise<void>;
    formats?: ExportFormat[];
}

const ExportButton: React.FunctionComponent<IProps> = (props: IProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [exporting, setExporting] = useState<boolean>(false);
    const { classes, onExportData, filter, label, formats = ["json", "csv"], helpTooltip } = props;
    const handleTooltipClose = () => {
        setOpen(false);
    };
    let button = <button
        onClick={() => {
            if (formats.length === 1) {
                setExporting(true);
                onExportData(filter, formats[0])
                    .catch(e => UIUtil.errorMessage(e))
                    .finally(() => setExporting(false));
            } else {
                setOpen(true);
            }
        }}
        className={classNames(genStyles.flex, genStyles.pointer, classes.exportBtn)}>
        {exporting ?
            <IconSpin className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace, "sg-animate-spin")} /> :
            <IconExport className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />}
        {exporting ? "Exporting..." : (label || i18n.t("Export.data"))}
    </button>;
    if (helpTooltip) {
        button =
            <div>
                <MDashTooltip title={helpTooltip}>
                    {button}
                </MDashTooltip>
            </div>
    }
    return (
        formats.length === 1 ? button :
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    title={
                        <div className={classes.menu}>
                            {formats.map(format =>
                                <div key={format} className={classes.menuItem} onClick={() => {
                                    setExporting(true);
                                    onExportData(filter, format)
                                        .catch(e => UIUtil.errorMessage(e))
                                        .finally(() => setExporting(false));
                                }}>{format.toUpperCase()}</div>)}
                        </div>
                    }
                    open={open}
                    onClose={handleTooltipClose}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    PopperProps={{
                        disablePortal: true,
                        className: classes.popper
                    }}
                    classes={{
                        tooltip: classes.tooltip
                    }}
                >
                    {button}
                </Tooltip>
            </ClickAwayListener>
    );

}

export default withStyles(ExportButton, exportButtonJss);