import { gql } from "graphql-tag";
import Util from "../util/Util";
import AdminUser, { ADMIN_USER_ADMIN_MANAGEMENT_FIELD, ADMIN_USER_CREATED_AT_FIELD, ADMIN_USER_EMAIL_FIELD, ADMIN_USER_ID_FIELD, ADMIN_USER_IS_SKEDGO_DEV_FIELD, ADMIN_USER_FIRST_NAME_FIELD, ADMIN_USER_ORGANIZATIONS_FIELD, ADMIN_USER_PROVIDERS_FIELD, ADMIN_USER_ROLE_FIELD, ADMIN_USER_LAST_NAME_FIELD, ADMIN_USER_ORG_RELATIONS_FIELD, ADMIN_USER_FEATURES } from "../model/AdminUser";
import { PROVIDER_SCHEMA_BUILDER } from "./ProvidersData";
import { ORGANIZATION_SCHEMA_BUILDER } from "./OrganizationsData";
import ItemsData from "./ItemsData";
import AppSync from "./AppSync";
import NetworkUtil from "../util/NetworkUtil";
import Filter, { SortOrder } from "./Filter";
import GQLError from "../util/GQLError";
import Organization from "../model/Organization";
import Provider from "../model/Provider";
import { appCredentials } from "../account/appData";

export const ADMIN_USERS_SCHEMA_BUILDER = () => `
{
    ${ADMIN_USER_ID_FIELD}
    ${ADMIN_USER_EMAIL_FIELD}    
    ${ADMIN_USER_FIRST_NAME_FIELD}    
    ${ADMIN_USER_LAST_NAME_FIELD}    
    ${ADMIN_USER_CREATED_AT_FIELD}
    ${ADMIN_USER_IS_SKEDGO_DEV_FIELD}
    ${ADMIN_USER_ROLE_FIELD}
    ${ADMIN_USER_ADMIN_MANAGEMENT_FIELD}
    ${ADMIN_USER_ORGANIZATIONS_FIELD}
        ${ORGANIZATION_SCHEMA_BUILDER()}
    ${ADMIN_USER_ORG_RELATIONS_FIELD}
        {
            orgId
            notify
        }    
    ${ADMIN_USER_PROVIDERS_FIELD}
        ${PROVIDER_SCHEMA_BUILDER()}
}
`;

// ${ADMIN_USER_LAST_NAME_FIELD}

export const GET_ADMIN_USER_QUERY = "getAdminUser";
export const getAdminUser = (id: string) => gql`
query getAdminUser {
    ${GET_ADMIN_USER_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ Id: id })})    
        ${ADMIN_USERS_SCHEMA_BUILDER()}
}
`;

export const adminUserCreateFieldsBuilder = () => [
    ADMIN_USER_EMAIL_FIELD,
    ADMIN_USER_FIRST_NAME_FIELD,
    ADMIN_USER_LAST_NAME_FIELD,
    ADMIN_USER_IS_SKEDGO_DEV_FIELD,
    ADMIN_USER_ROLE_FIELD,
    ADMIN_USER_ADMIN_MANAGEMENT_FIELD
];

export type OtherCreateInput = { provider?: Provider, organizations?: Organization[] };

export const CREATE_ADMIN_USER_QUERY = "createAdminUser";
export const createAdminUser = (value: AdminUser, otherInput: OtherCreateInput = {}) => {
    const { organizations, provider, ...ontherInputToSend } = otherInput;
    const query = { ...Util.filterKeys(Util.serialize(value), adminUserCreateFieldsBuilder()), ...ontherInputToSend };
    return gql`
    mutation createAdminUser {
        ${CREATE_ADMIN_USER_QUERY} 
        (
            input: ${Util.graphqlStringify(query)}            
        ) ${ADMIN_USERS_SCHEMA_BUILDER()}
    }
`
};

export const adminUserUpdateFieldsBuilder = () => [
    ADMIN_USER_ID_FIELD,
    ...adminUserCreateFieldsBuilder()
];

export type OtherUpdateInput = Pick<OtherCreateInput, "provider">;

export const UPDATE_ADMIN_USER_QUERY = "updateAdminUser";
export const updateAdminUser = (value: AdminUser, otherInput: OtherUpdateInput = {}) => {
    const query = Util.filterKeys(Util.serialize(value), adminUserUpdateFieldsBuilder());
    return gql`
    mutation updateAdminUser {
        ${UPDATE_ADMIN_USER_QUERY} 
        (
            input: ${Util.graphqlStringify(query)}            
        ) ${ADMIN_USERS_SCHEMA_BUILDER()}
    }
`
};

export const DELETE_ADMIN_USER_QUERY = "disableAdminUser";
export const deleteAdminUser = (id: string) => {
    return gql`
    mutation deleteAdminUser {
        ${DELETE_ADMIN_USER_QUERY} 
        (                        
            id: "${id}"
        ) 
        {
            result
        }
    }
`
};

// linkOrgToAdminUser(input: OrgAdminUserLink): Response

export type LinkAdminUserToOrgInput = {
    adminUserId: string;
    orgId: string;
    clientId: string;
    notify: boolean;
}

export const LINK_ADMIN_USER_TO_ORG_QUERY = "linkOrgToAdminUser";
export const linkAdminUserToOrg = (input: LinkAdminUserToOrgInput) => {
    return gql`
    mutation linkOrgToAdminUser {
        ${LINK_ADMIN_USER_TO_ORG_QUERY} 
        (
            input: ${Util.graphqlStringify(input)}            
        ) { result }
    }
`
};

export const UPDATE_ADMIN_USER_TO_ORG_LINK_QUERY = "updateOrgAdminRelation";
export const updateAdminUserToOrgLink = (input: LinkAdminUserToOrgInput) => {
    return gql`
    mutation updateOrgAdminRelation {
        ${UPDATE_ADMIN_USER_TO_ORG_LINK_QUERY} 
        (
            input: ${Util.graphqlStringify(input)}            
        ) { result }
    }
`
};

export type UnlinkAdminUserToOrgInput = Pick<LinkAdminUserToOrgInput, "adminUserId" | "clientId" | "orgId">;

export const UNLINK_ADMIN_USER_TO_ORG_QUERY = "unlinkOrgAdminUser";
export const unlinkAdminUserToOrg = (input: UnlinkAdminUserToOrgInput) => {
    return gql`
    mutation unlinkOrgAdminUser {
        ${UNLINK_ADMIN_USER_TO_ORG_QUERY} (${NetworkUtil.getGQLParamsFromQuery(input)}) { result }
    }
`
};

export type LinkAdminUserToProviderInput = {
    adminUserId: string;
    providerId: string;
    clientId: string;
}

export const LINK_ADMIN_USER_TO_PROVIDER_QUERY = "linkProviderToAdminUser";
export const linkAdminUserToProvider = (input: LinkAdminUserToProviderInput) => {
    return gql`
    mutation linkProviderToAdminUser {
        ${LINK_ADMIN_USER_TO_PROVIDER_QUERY} 
        (
            input: ${Util.graphqlStringify(input)}            
        ) { result }
    }
`
};

export const UNLINK_ADMIN_USER_TO_PROVIDER_QUERY = "unlinkProviderAdminUser";
export const unlinkAdminUserToProvider = (input: LinkAdminUserToProviderInput) => {
    return gql`
    mutation unlinkProviderAdminUser {
        ${UNLINK_ADMIN_USER_TO_PROVIDER_QUERY} (${NetworkUtil.getGQLParamsFromQuery(input)}) { result }
    }
`
};

export interface IListAdminUsersQuery {
    search?: string;
    limit?: number;
    organizationId?: string;
    sortAsc?: boolean;
    nextToken?: string;
}

export const LIST_ADMIN_USERS_QUERY = "listAdminUsers";
export const listAdminUsers = (query: IListAdminUsersQuery) => {
    return gql`
    query listAdminUsers {
        ${LIST_ADMIN_USERS_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
            items ${ADMIN_USERS_SCHEMA_BUILDER()}           
            nextToken
    }
}
`
};

export const GET_ADMIN_GRANTS = "getAdminUserGrants";
export const getAdminUserGrants = () => {
    return gql`
    query getAdminUserGrants {
        ${GET_ADMIN_GRANTS} ${ADMIN_USERS_SCHEMA_BUILDER()}
    }
`
};

export const RESET_ADMIN_USER_PASSWORD_QUERY = "resetAdminUserPassword";
export const resetAdminUserPassword = (email: string) => gql`
  query resetAdminUserPassword {
    ${RESET_ADMIN_USER_PASSWORD_QUERY}(${NetworkUtil.getGQLParamsFromQuery({ email })})
    {
        result
    }
  }
`;

function queryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const queryParams: IListAdminUsersQuery = {
        search: filter.search?.label,
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        organizationId: filter.orgId,
        limit: limit,
        nextToken: nextToken
    };
    return listAdminUsers(queryParams);
}

function processResults({ data, errors }) {
    if (!data?.[LIST_ADMIN_USERS_QUERY]) {
        return { error: errors?.[0]?.message ?? "This data is currently unavailable" };
    }
    const queryResult = data[LIST_ADMIN_USERS_QUERY];
    const items = queryResult.items
        .map((itemJson: any) => Util.deserialize(itemJson, AdminUser));
    return { items, nextToken: queryResult.nextToken }
}

class AdminUsersData extends ItemsData<AdminUser> {
    private static _instance: AdminUsersData;
    public static get instance(): AdminUsersData {
        if (!this._instance) {
            this._instance = new AdminUsersData(queryFromFilter, processResults);
        }
        return this._instance;
    }

    public invalidateCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_ADMIN_USERS_QUERY) && cache.data.delete(key);
        }
        );
    }

    public invalidateCacheById(id: string) {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => key === id && cache.data.delete(key));
    }

    public get(id: string): Promise<AdminUser> {
        return AppSync.query({
            query: getAdminUser(id)
        }).then(({ data }) => {
            const adminUserJSON = data[GET_ADMIN_USER_QUERY];
            if (!adminUserJSON) {
                throw new GQLError('Booking not found for id: ' + id, undefined, true);
            }
            return Util.deserialize(adminUserJSON, AdminUser);
        });
    }

    public create(adminUser: AdminUser, otherInput: OtherCreateInput = {}): Promise<AdminUser | undefined> {
        return AppSync.mutate({
            mutation: createAdminUser(adminUser, otherInput)
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const resultJson = data.data[CREATE_ADMIN_USER_QUERY];
                const adminUserResult = resultJson && Util.deserialize(Util.undefineNulls(resultJson, true), AdminUser);
                return adminUserResult;
            })
            .then(async (adminUserResult) => {
                await Promise.all(otherInput.organizations?.map(org => this.linkToOrg({ adminUserId: adminUserResult.id, orgId: org.id, clientId: org.clientId!, notify: false })) ?? []);
                return adminUserResult;
            })
            .then(async (adminUserResult) => {
                if (otherInput.provider) {
                    await this.linkToProvider({ adminUserId: adminUserResult.id, providerId: otherInput.provider?.id!, clientId: otherInput.provider?.clientId! });
                }
                return adminUserResult;
            })
            .then(NetworkUtil.rejectOnGQLError);

    }

    public update(adminUser: AdminUser, otherInput: OtherUpdateInput = {}): Promise<AdminUser | void> {
        return AppSync.mutate({
            mutation: updateAdminUser(adminUser)
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then((data: any) => {
                const resultJson = data.data[UPDATE_ADMIN_USER_QUERY];
                const adminUserResult = resultJson && Util.deserialize(Util.undefineNulls(resultJson, true), AdminUser);
                return adminUserResult;
            })
            .then(async (adminUserResult: AdminUser) => {
                if (otherInput.provider) {
                    if (adminUser.providers.length > 0) {
                        await this.unlinkToProvider({ adminUserId: adminUser.id, clientId: otherInput.provider?.clientId!, providerId: adminUser.providers[0].id! })
                    }
                    await this.linkToProvider({ adminUserId: adminUserResult.id, clientId: otherInput.provider?.clientId!, providerId: otherInput.provider?.id! });
                }
                return adminUserResult;
            })
            .then(NetworkUtil.rejectOnGQLError);
    }

    public delete(id: string): Promise<void> {
        return AppSync.mutate({
            mutation: deleteAdminUser(id)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public resetPassword(email: string): Promise<{ result: string }> {
        return AppSync.query({
            query: resetAdminUserPassword(email),
            fetchPolicy: "network-only"
        }).then(({ data }) => {
            const response = data[RESET_ADMIN_USER_PASSWORD_QUERY]
            if (!response?.result) {
                throw new Error("Reset password failed");
            }
            return response;
        });
    }

    public linkToOrg(input: LinkAdminUserToOrgInput) {
        return AppSync.mutate({
            mutation: linkAdminUserToOrg(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public updateLinkToOrg(input: LinkAdminUserToOrgInput) {
        return AppSync.mutate({
            mutation: updateAdminUserToOrgLink(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public unlinkToOrg(input: UnlinkAdminUserToOrgInput) {
        return AppSync.mutate({
            mutation: unlinkAdminUserToOrg(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public linkToProvider(input: LinkAdminUserToProviderInput) {
        return AppSync.mutate({
            mutation: linkAdminUserToProvider(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public unlinkToProvider(input: LinkAdminUserToProviderInput) {
        return AppSync.mutate({
            mutation: unlinkAdminUserToProvider(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    public getGrants(): Promise<AdminUser> {
        return AppSync.query({
            query: getAdminUserGrants(),
            fetchPolicy: "no-cache"
            // fetchPolicy: "network-only"
        }).then(({ data }) => {
            const adminUserJSON = data[GET_ADMIN_GRANTS];
            return Util.deserialize(adminUserJSON, AdminUser);
        }).then(NetworkUtil.rejectOnGQLError);
    }

    /**
     * This method is used to fetch the admin user grants using fetch instead of AppSync,
     * since when using AppSync I get an error that seems to be related to the cache.
     */
    public getGrantsFetch(): Promise<AdminUser> {
        return fetch(appCredentials.gqlApiUrl, {
            "headers": {
                "accept": "*/*",
                "authorization": AppSync.token,
                "content-type": "application/json",
            },
            "body": `{\"operationName\":\"${GET_ADMIN_GRANTS}\",\"variables\":{},\"query\":\"query getAdminUserGrants {\\n  getAdminUserGrants {\\n    Id\\n    email\\n    firstName\\n    lastName\\n    createdAt\\n    isSkedgoDev\\n    roles\\n    adminManagement\\n    ${ADMIN_USER_FEATURES}\\n    organizations {\\n      Id\\n      name\\n      clientId\\n      path\\n      isClientApp\\n      __typename\\n    }\\n    providers {\\n      id\\n      name\\n      clientId\\n      __typename\\n    }\\n    __typename\\n  }\\n}\\n\"}`,
            "method": "POST",
        })
            .then(response => response.json())
            .then(({ data }) => {
                const adminUserJSON = data[GET_ADMIN_GRANTS];
                return Util.deserialize(adminUserJSON, AdminUser);
            }).then(NetworkUtil.rejectOnGQLError);
    }

    public getMockGrants(): Promise<AdminUser> {
        const admin = {
            "Id": "8cbfc6b4-8657-4de5-a924-379d064f7c59",
            "email": "mauro+admin@skedgo.com",
            "firstName": "Mauro",
            "lastName": "Gómez 2",
            "createdAt": "2024-09-12T22:26:27.752Z",
            "isSkedgoDev": true,
            "roles": [
                "admin"
            ],
            "adminManagement": true,
            "organizations": [
                {
                    "Id": "9a3f1e23-b791-463c-b267-cb1ce7245a56",
                    "name": "Feonix",
                    "clientId": "1409623403931",
                    "path": "0001",
                    "isClientApp": false,
                    "__typename": "Organization"
                }
            ],
            "providers": [],
            "__typename": "AdminUser"
        };
        const tspAdmin = {
            "Id": "8cbfc6b4-8657-4de5-a924-379d064f7c59",
            "email": "mauro+admin@skedgo.com",
            "firstName": "Mauro",
            "lastName": "Gómez 2",
            "createdAt": "2024-09-12T22:26:27.752Z",
            "isSkedgoDev": true,
            "roles": [
                "tsp_admin"
            ],
            "adminManagement": false,
            "organizations": [],
            "providers": [
                {
                    "id": "ps_drt_mi-jackson-transportation",
                    "name": "Jackson Transportation Authority",
                    "clientId": "1409623477025",
                    "__typename": "Provider"
                }
            ],
            "__typename": "AdminUser"
        };
        const orgAdmin = {
            "Id": "8cbfc6b4-8657-4de5-a924-379d064f7c59",
            "email": "mauro+admin@skedgo.com",
            "firstName": "Mauro",
            "lastName": "Gómez 2",
            "createdAt": "2024-09-12T22:26:27.752Z",
            "isSkedgoDev": true,
            "roles": [
                "org_admin"
            ],
            "adminManagement": false,
            "organizations": [
                // {
                //     "Id": "9a3f1e23-b791-463c-b267-cb1ce7245a56",
                //     "name": "Feonix",
                //     "clientId": "1409623403931",
                //     "path": "0001",
                //     "isClientApp": false,
                //     "__typename": "Organization"
                // },
                // {
                //     "Id": "1641ecf8-4649-4ae1-a0f0-fb81c0ba18c1",
                //     "name": "Michigan",
                //     "clientId": "1409623477025",
                //     "path": "00010004",
                //     "isClientApp": true,
                //     "__typename": "Organization"
                // },
                // {
                //     "Id": "dc134acb-29a3-4b2a-aafe-370edfb3e7ae",
                //     "name": "North Texas",
                //     "clientId": "1409624091304",
                //     "path": "00010003",
                //     "isClientApp": true,
                //     "__typename": "Organization"
                // },
                {
                    "Id": "17a2d461-255e-49d4-8b51-69bc1f9d0607",
                    "name": "After 8 to Educate",
                    "clientId": "1409624091304",
                    "path": "000100030002",
                    "isClientApp": false,
                    "__typename": "Organization"
                },
                // {
                //     "Id": "30b3d735-91c0-41cf-bd3b-6a72d977798a",
                //     "name": "Academic Internal Medicine",
                //     "clientId": "1409623477025",
                //     "path": "00010004000Z0001",
                //     "isClientApp": false,
                //     "__typename": "Organization"
                // }
            ],
            "providers": [],
            "__typename": "AdminUser"
        };
        return Promise.resolve(Util.deserialize(admin, AdminUser));
    }
}

export const getAdminUserCacheRedirect = (_, args, { getCacheKey }) => {
    // args: arguments of getUser query, i.e. getUser(Id:"${id}")
    // getCacheKey uses dataIdFromObject function to map (artificially created) object
    // {__typename: 'User', Id: args.Id} to the cache id.
    return getCacheKey({ __typename: 'AdminUser', Id: args.Id });
};

export default AdminUsersData;