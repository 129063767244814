import { Theme } from "../css/Theme";
import { black, genJss, resetJss } from "../css/gen.jss";
import { priceChangeStatusColor } from "../model/Transaction";
import UIUtil from "../util/UIUtil";

export const bookingTableJss = (theme: Theme) => ({
    tableContainer: {
        ...genJss.flex,
        marginTop: '20px',
        // #scrollx
        // overflowX: 'auto',   // To achieve sticky header
        '& table': {
            tableLayout: 'fixed',
            width: '100%'
        },
        '& th': {
            boxSizing: 'border-box',
        },
        '& td': {
            wordWrap: 'break-word',
            boxSizing: 'border-box',
            whiteSpace: 'pre-wrap!important'
        }
    },

    iconEdit: {
        ...genJss.svgPathFillCurrColor,
        height: '16px',
        width: '16px',
        cursor: 'pointer',
        color: theme.colorPrimary
    },

    scheduleButton: {
        ...resetJss.button,
        position: 'relative',
        '&:disabled $iconSchedule': {
            opacity: .5
        }
    },

    iconSchedule: {
        ...genJss.svgPathFillCurrColor,
        height: '22px',
        width: '22px',
        color: theme.colorPrimary,
        '& polygon': {
            fill: theme.colorPrimary
        },
        '&:not(:last-child)': {
            background: 'white',
            ...genJss.borderRadius(5)
        },
        '&:not(:first-child)': {
            position: 'absolute',
            top: '5px',
            left: '5px',
            zIndex: -1
        }
    },

    detailsAndReviewButton: {
        ...resetJss.button,
        ...genJss.flex,
        ...genJss.alignCenter,
        height: '32px',
        borderRadius: '50%',
        '&:disabled $iconSchedule': {
            opacity: .5
        },
        '& svg': {
            width: 'auto',
            '& path': {
                fill: theme.colorPrimary
            },
            '&:nth-child(1)': {
                height: '80%',
            },
            '&:nth-child(2)': {
                height: '100%',
                marginLeft: '-5px'
            }
        },
        '&:hover': {
            filter: `drop-shadow(0px 0px .5px ${UIUtil.colorWithOpacity(theme.colorPrimary, 1)})`
        }
    },

    iconWheelchair: {
        height: '22px',
        width: '22px',
        '& path': {
            fill: black()
        }
    },

    returnButton: {
        ...resetJss.button,
        cursor: 'default',
        height: '40px',
        width: '70px',
        '& svg': {
            height: '22px',
            width: '22px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },

    relatedButton: {
        ...resetJss.button,
        cursor: 'default',
        height: '40px',
        width: '70px',
        '& svg': {
            height: '22px',
            width: '22px',
            '& path': {
                fill: black(1)
            }
        }
    },

    outbound: {
        '& svg': {
            transform: 'rotate(180deg)'
        }
    },

    relatedHighlight: {
        '& svg': {
            width: '26px',
            height: '26px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },

    editStatusBtn: {
        ...resetJss.button,
        height: '28px',
        width: '28px',
        marginLeft: '5px'
    },

    statusCell: {
        ...genJss.flex,
        ...genJss.alignCenter
    },

    loading: {
        width: '30px',
        height: '30px'
    },

    error: {
        width: '15px',
        height: '15px',
        '& path': {
            fill: theme.colorError
        }
    },

    statusInDescription: {
        display: 'inline-block'
    },

    transport: {
        ...genJss.flex,
        ...genJss.alignCenter
    },
    charge: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& svg': {
            marginLeft: '5px'
        },
        '& path': {
            fill: theme.colorWarning
        }
    },
    tableSettingsBtn: {
        ...resetJss.button,
        '& svg': {
            height: '20px',
            width: '20px'
        }
    },
    relatedTooltip: {
        padding: '10px',
        '&>*:not(:first-child)': {
            marginTop: '5px'
        }
    },
    iconAccept: {
        height: '30px',
        width: '30px',
        marginLeft: '28px',
        '& path': {
            fill: priceChangeStatusColor('ACCEPTED', theme)
        }
    },
    iconReject: {
        height: '30px',
        width: '30px',
        marginLeft: '28px',
        '& path': {
            fill: priceChangeStatusColor('REJECTED', theme)
        }
    },
    iconFromTo: {
        margin: '-1px 6px -1px 0px',
        width: '26px',
        height: '26px',
        opacity: '0.6'
    },
    iconClock: {
        marginRight: '7px',
        width: '24px',
        height: '24px',
        padding: '2px',
        boxSizing: 'border-box'
    }
});