import gql from "graphql-tag";
import { default as Bundle } from "../model/Bundle";
import Util from "../util/Util";
import NetworkUtil from "../util/NetworkUtil";
import { adminProfile } from "../account/AdminProfile";
import { INITIATIVE_SCHEMA_BUILDER } from "../model/Initiative";

export const getBundleCacheRedirect = (_, args, { getCacheKey }) => {
  // args: arguments of getUser query, i.e. getUser(Id:"${id}")
  // getCacheKey uses dataIdFromObject function to map (artificially created) object
  // {__typename: 'User', Id: args.Id} to the cache id.
  return getCacheKey({ __typename: 'Bundle', Id: args.Id });
};

export const BUNDLE_ID_FIELD = "Id";
export const BUNDLE_SHORT_ID_FIELD = "shortId";
export const BUNDLE_NAME_FIELD = "name";
export const BUNDLE_TMODES_FIELD = "transportModes";
export const BUNDLE_APPLIED_TIMESTAMP_FIELD = "appliedTimestamp";
export const BUNDLE_EXPIRATION_TIMESTAMP_FIELD = "expirationTimestamp";
export const BUNDLE_TO_BE_APPLIED_TIMESTAMP_FIELD = "toBeAppliedTimestamp";
export const BUNDLE_SUBSCRIPTION_AUTO_RENEW_FIELD = "subscriptionAutoRenew";
export const BUNDLE_NOTE_FIELD = "note";
export const BUNDLE_RESTRICTED_ONLY_TO_FIELD = "restrictedOnlyTo";
export const BUNDLE_CONFIG_FIELD = "bundleConfig";
export const BUNDLE_PURCHASE_ONCE_FIELD = "subscriptionPurchaseOnce";
export const BUNDLE_CLIENT_ID_FIELD = "clientId";
export const BUNDLE_SPONSOR_TITLE_FIELD = "sponsorTitle";
export const BUNDLE_SPONSOR_DESCRIPTION_FIELD = "sponsorDescription";
export const BUNDLE_SPONSOR_IMAGE_URL_FIELD = "sponsorImageURL";
export const BUNDLE_SPONSOR_IMAGE_BASE64_FIELD = "base64Img";
export const BUNDLE_INITIATIVE_FIELD = "initiative";
export const BUNDLE_INITIATIVE_ID_FIELD = "initiativeId";

export const TMODE_MODE_FIELD = "mode";
export const TMODE_POINTS_PER_COST_FIELD = "pointsPerCost";
export const TMODE_DISCOUNT_POINTS_FIELD = "discountPoints";
export const TMODE_REWARD_PER_COST_FIELD = "rewardPerCost";
export const TMODE_HIDE_COST_FIELD = "hideCost";
export const TMODE_SYMBOLIC_REWARD_PER_COST_FIELD = "symbolicRewardPerCost";
export const TMODE_NOTIFICATION_EMAIL_FIELD = "notificationEmail";
export const TMODE_OFFER_DESCRIPTION_FIELD = "offerDescription";

export const BUNDLE_SUBSCRIPTION_FEE_FIELD = "subscriptionFee";
export const BUNDLE_SUBSCRIPTION_DURATION_FIELD = "subscriptionDurationInDays";
export const BUNDLE_STATUS_FIELD = "status";
export const BUNDLE_CURRENT_USER_COUNT_FIELD = "currentUserCount";
export const BUNDLE_FUTURE_USER_COUNT_FIELD = "futureUserCount";
export const BUNDLE_USER_COUNT_FIELD = "userCount";


export const BUNDLE_FIELDS_BUILDER = () => `
        ${BUNDLE_ID_FIELD}
        ${BUNDLE_SHORT_ID_FIELD}
        ${BUNDLE_NAME_FIELD}
        ${adminProfile.isSuperApp ? BUNDLE_CLIENT_ID_FIELD : ""}
        ${BUNDLE_TMODES_FIELD} {
          ${TMODE_MODE_FIELD}
          ${TMODE_POINTS_PER_COST_FIELD}
          ${TMODE_DISCOUNT_POINTS_FIELD}
          ${TMODE_REWARD_PER_COST_FIELD}
          ${TMODE_HIDE_COST_FIELD}
          ${TMODE_SYMBOLIC_REWARD_PER_COST_FIELD}
          ${TMODE_NOTIFICATION_EMAIL_FIELD}
          ${TMODE_OFFER_DESCRIPTION_FIELD}
        }                
        ${BUNDLE_SUBSCRIPTION_AUTO_RENEW_FIELD}        
        ${BUNDLE_NOTE_FIELD}
        ${BUNDLE_RESTRICTED_ONLY_TO_FIELD}
        ${BUNDLE_CONFIG_FIELD} {
          ${BUNDLE_STATUS_FIELD}
          ${BUNDLE_SUBSCRIPTION_FEE_FIELD}
          ${BUNDLE_SUBSCRIPTION_DURATION_FIELD}
          ${BUNDLE_CURRENT_USER_COUNT_FIELD}
          ${BUNDLE_FUTURE_USER_COUNT_FIELD}
          ${BUNDLE_USER_COUNT_FIELD}
        }
        ${BUNDLE_PURCHASE_ONCE_FIELD}
        ${adminProfile.features.bundleImg20922 ? BUNDLE_SPONSOR_TITLE_FIELD : ""}
        ${adminProfile.features.bundleImg20922 ? BUNDLE_SPONSOR_DESCRIPTION_FIELD : ""}
        ${adminProfile.features.bundleImg20922 ? BUNDLE_SPONSOR_IMAGE_URL_FIELD : ""}
        ${adminProfile.features.trackTripInitiative22828 ?
    `${BUNDLE_INITIATIVE_FIELD} ${INITIATIVE_SCHEMA_BUILDER()}` : ""}
`;

export const BUNDLE_SCHEMA_BUILDER = () => `
    {
        ${BUNDLE_FIELDS_BUILDER()}        
    }
`;

export interface IListBundlesQuery {
  clientId?: string,
  sortAsc?: boolean,
  search?: string,
  initiativeId?: string,
  limit?: number,
  nextToken?: string
}

export const LIST_BUNDLES_QUERY = "listBundles";
export const listBundles = (query?: IListBundlesQuery) => {
  return gql`
      query listBundles {
        ${LIST_BUNDLES_QUERY}(${NetworkUtil.getGQLParamsFromQuery(query)}) {
          items ${BUNDLE_SCHEMA_BUILDER()}
          nextToken
        }
      }
    `;
};

export const GET_BUNDLE_QUERY = "getBundle";
export const getBundle = (id: string) => gql`
  query getBundle {
    ${GET_BUNDLE_QUERY}(Id:"${id}")
    ${BUNDLE_SCHEMA_BUILDER()}
  }
`;


const bundleCreateEditFieldsBuilder = () => ([
  BUNDLE_NAME_FIELD, BUNDLE_TMODES_FIELD, BUNDLE_NOTE_FIELD,
  BUNDLE_RESTRICTED_ONLY_TO_FIELD, BUNDLE_CONFIG_FIELD, BUNDLE_PURCHASE_ONCE_FIELD,
  ...adminProfile.features.bundleImg20922 ? [BUNDLE_SPONSOR_TITLE_FIELD, BUNDLE_SPONSOR_DESCRIPTION_FIELD, BUNDLE_SPONSOR_IMAGE_BASE64_FIELD] : [],
  ...adminProfile.isSuperApp ? [BUNDLE_CLIENT_ID_FIELD] : [],
  ...adminProfile.features.trackTripInitiative22828 ? [BUNDLE_INITIATIVE_ID_FIELD] : []
]);

const bundleCreateFieldsBuilder = () => ([
  ...bundleCreateEditFieldsBuilder()
]);

const bundleEditFieldsBuilder = () => bundleCreateEditFieldsBuilder().concat([
  BUNDLE_ID_FIELD
]);

function filterBundleConfigInputFields(bundle: Bundle, create: boolean): string {
  let bundleObj = Util.serialize(bundle);
  bundleObj[BUNDLE_INITIATIVE_ID_FIELD] = bundle.initiative?.id ?? null;  // Need to replace undefined by null to get the initiative actually removed  
  bundleObj = Util.filterKeys(bundleObj, create ? bundleCreateFieldsBuilder() : bundleEditFieldsBuilder());
  bundleObj[BUNDLE_CONFIG_FIELD] = bundleObj[BUNDLE_CONFIG_FIELD]
    .map(configObj => Util.filterKeys(configObj, [BUNDLE_STATUS_FIELD, BUNDLE_SUBSCRIPTION_FEE_FIELD, BUNDLE_SUBSCRIPTION_DURATION_FIELD]));
  return Util.stringifyJustValues(bundleObj);
}

export const createBundle = (bundle: Bundle) => gql`
    mutation createBundle {
        createBundle ( input: ${filterBundleConfigInputFields(bundle, true)})
        ${BUNDLE_SCHEMA_BUILDER()}
}
`;

export const updateBundle = (bundle: Bundle) => gql`
    mutation updateBundle {
        updateBundle ( input: ${filterBundleConfigInputFields(bundle, false)})
        ${BUNDLE_SCHEMA_BUILDER()}
}
`;

export const deleteBundle = (id: string) => gql`
    mutation deleteBundle {
        deleteBundle ( input: { Id:"${id}" } )
        ${BUNDLE_SCHEMA_BUILDER()}
}
`;