import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

export const scheduleBookingConfirmJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        height: '100%',
        ...genJss.column,
        ...genJss.spaceBetween
    },
    reviews: {
        ...genJss.flex,
        ...genJss.column,
        '&>*:not(:last-child)': {
            marginBottom: '30px'
        }
    },
    review: {
        ...genJss.flex,
        ...genJss.column
    },
    type: {
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        marginTop: '20px',
        '&>*:not(:last-child)': {
            marginRight: '20px'
        }
    },
    empty: {
        ...genJss.grow,
        ...genJss.flex,
        ...genJss.center,
        ...genJss.alignCenter,
        ...theme.textColorGray,
        fontSize: '20px'
    }
});