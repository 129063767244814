import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const editAdminViewJss = (theme: Theme) => ({
    narrowForm: {
        maxWidth: '600px',
        '& label': {
            width: '250px'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colorPrimary
        },
        ...genJss.grow
    },
    radioGroup: {
        '& label': {
            ...genJss.alignSelfStart,
            lineHeight: '42px'
        }
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },
    orgsSelect: {
        ...genJss.grow,
        '& > *': {
            width: '100%'
        }
    }
});