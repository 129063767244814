import React from 'react';
import BemMixin from './utils/BemMixin';
import PureRenderMixin from 'react-addons-pure-render-mixin';


class PaginationArrow extends React.Component {
  mixins = [BemMixin, PureRenderMixin]
  displayName = "PaginationArrow"

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.mixins.forEach(mixin => Object.assign(this, mixin));
  }

  render() {
    let { disabled, direction, onTrigger, ...props } = this.props;
    let modifiers = { [direction]: true };
    let states = { disabled };

    let elementOpts = {
      modifiers,
      states,
    };

    let iconOpts = {
      element: 'PaginationArrowIcon',
      modifiers,
      states,
    };

    return (
      <div className={this.cx(elementOpts)} {...props} onClick={onTrigger}>
        <div className={this.cx(iconOpts)} />
      </div>
    );
  }
};

export default PaginationArrow;
