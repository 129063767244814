import React, { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import Transaction, { ConfirmationInput, relationTypeCompareFc } from "../model/Transaction";
import DateTimeUtil from "../util/DateTimeUtil";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import TransportUtil from "../util/TransportUtil";
import FormatUtil from "../util/FormatUtil";
import { WithStyles, useStyles } from "../css/StyleHelper";
import { bookingViewJss } from "./BookingView.jss";
import View from "../view/View";
import Loading from "../view/Loading";
import TransactionsData from "../data/TransactionsData";
import { matchFavorite, default as UsersData } from "../data/UsersData";
import { removeRepeatedId } from "./ScheduleBookingView";
import { PaymentStatusPill } from "./TransactionsTable";
import { adminProfile, ModelOperation, ModelType } from "../account/AdminProfile";
import { TKRoot, TKUIConfig, TKUIMapView, TripGoApi } from "tripkit-react";
import { Theme } from "../css/Theme";
import Note from "./Note";
import { AppContext, geocodingConfig, mapConfig } from "../app/App";
import Tooltip from "../uiutil/Tooltip";
import { orgFromId } from "../app/OrgSelectorHelpers";
import Tabs from "@mui/material/Tabs/Tabs";
import Tab from "@mui/material/Tab/Tab";
import { useBeforeInitialRender, useAsyncValue } from "../util/usehooks";
import Util from "../util/Util";
import { Client } from "../model/Client";
import { genJss } from "../css/gen.jss";
import { BOOKING_CREATE_SIMILAR_VIEW } from "./NewCreateBookingView";
import { ReactComponent as IconSchedule } from "../images/ic-schedule.svg";
import { ReactComponent as IconReschedule } from "../images/ic-reschedule.svg";
import { ReactComponent as IconAddCopy } from "../images/ic-add-copy.svg";
import { ReactComponent as IconRefresh } from "../images/ic-refresh2.svg";
import ActionsMenuButton from "../nav/ActionsMenuButton";
import { TransStatusPill } from "./StatusPill";
import { i18n } from "../i18n/TKI18nConstants";
import { PriceChangeActionType } from "./PriceChangeViewHelpers";
import PriceChangeDetails from "./PriceChangeDetails";
import ViewTitleWithId from "../view/ViewTitleWithId";
import { useProviders } from "../data/ProvidersData";
import { ADD_BOOKING_NOTE_VIEW, EDIT_BOOKING_NOTE_VIEW } from "./EditNoteView";
import { useTheme } from "react-jss";
import { TKUIMapViewClass } from "tripkit-react/dist/map/TKUIMapView";
import { BookingSubtitle } from "./BookingSubtitle";
import { canCancel, canEditBookingStatus, locIconStyleOverride } from "./BookingViewHelpers";

type IStyle = ReturnType<typeof bookingViewJss>;

interface IProps extends WithStyles<IStyle> {
    booking?: Transaction;
    selectedClientID?: string;
    clients?: Client[];
    onSchedule?: () => void;
    onCancel?: (bookingId: string) => void;
    onRemove?: (booking: Transaction) => void;
    onOpenPayment?: (paymentId: string) => void;
    onEditStatus?: (trans: Transaction) => void;
    onEditInitiative?: (trans: Transaction) => void;
    onPriceAction?: (booking: Transaction, priceActionType?: PriceChangeActionType) => void;
    onSelectRelated?: (bookingId: string) => void;
    onRefreshExternal?: (booking: Transaction) => void;
}

const BookingView: FunctionComponent<IProps> = props => {
    const {
        booking = new Transaction(),    // To avoid null checks, it will never come in null since assumes renderWhenData={true}
        onSchedule, onRefreshExternal, onRemove, onCancel, onOpenPayment, onEditStatus, onEditInitiative, onPriceAction, onSelectRelated, selectedClientID, clients, classes, appClasses
    } = useStyles(props, bookingViewJss);

    const appContext = useContext(AppContext);
    const { navHistory, waitFor } = appContext;
    const providers = useProviders({ clientId: booking.clientId, adminProfile });

    const theme = useTheme() as Theme;

    const userFavorites = useAsyncValue(adminProfile.features.favNamesOnBookingDetails23475 && booking.userId && booking.clientId ? () => UsersData.instance.fetchUserFavorites({ userId: booking.userId!, adminProfile, clientAppId: booking.clientId! }) : () => Promise.resolve(undefined), []);
    const fromFavorite = userFavorites?.find(fav => matchFavorite(fav, booking.from));
    const toFavorite = userFavorites?.find(fav => matchFavorite(fav, booking.to));

    useBeforeInitialRender(() => {
        // Set the client id to that one of the booking.
        // Use client ID in path as fallback, but client ID should always come for super app bookings, and also
        // when no client is selected ("All apps") then there won't be any client ID on path, so it's fundamental that
        // the one in booking comes.
        TripGoApi.clientID = booking.clientId ?? selectedClientID;
    });

    const config: TKUIConfig = useMemo(() => ({
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        TKUIMapView: {
            props: () => mapConfig()
        },
        TKUIMapLocationIcon: {
            styles: locIconStyleOverride
        },
        geocoding: geocodingConfig,
        theme: {
            colorPrimary: theme.colorPrimary,
            fontFamily: theme.fontFamily
        },
        isDarkMode: false
    }), []);

    const mapRef = useRef<TKUIMapViewClass>(null);

    const [relatedBookings, setRelatedBookings] = useState<{ bookingId: string, type: string, booking?: Transaction }[]>(() => {
        const related = [{ bookingId: booking.id, booking: booking, type: booking.relatedBookings.some(rb => rb.type === "OUTBOUND") ? "RETURN" : "OUTBOUND" }]
            .concat(Util.serialize(removeRepeatedId(booking.relatedBookings)));
        related.sort((a, b) => relationTypeCompareFc(a.type, b.type));
        return related;
    });

    useEffect(() => {   // Prefetch related bookings. It's not necessary, just to avoid the delay (blank screen) when switching tabs.
        relatedBookings.forEach(async rb => {
            if (!rb.booking) {
                rb.booking = await TransactionsData.instance.get(rb.bookingId);
                setRelatedBookings(relatedBookings => [...relatedBookings]);
            }
        });
        // Workaround since after upgrading react-scripts to v5 the map doesn't fit the bounds properly the first time (like it's not ready yet)
        setTimeout(() => {
            mapRef.current?.fitMapFromTo();
        }, 500);
    }, []);
    const hasRelatedBookings = relatedBookings.length > 1;

    const tabs = hasRelatedBookings &&
        <div className={classes.tabs}>
            <Tabs
                value={booking.id}
                onChange={(_event, value) => onSelectRelated?.(value)}
                aria-label="text formatting"
            >
                {relatedBookings.map(rb =>
                    <Tab value={rb.bookingId} label={FormatUtil.toFirstUpperCase(rb.type)} disableFocusRipple disableTouchRipple key={rb.bookingId} />
                )}
            </Tabs>
        </div >;

    const returnTrip = booking?.confirmationInput?.returnTrip;

    let title;
    let body;
    let actionsMenuBtn;
    if (booking) {
        const timezone = booking.timezone;
        const depart = DateTimeUtil.momentTZTime(booking.depart * 1000, timezone);
        const arrive = booking.arrive && DateTimeUtil.momentTZTime(booking.arrive * 1000, timezone);
        title = depart ? "Trip on " + depart.format(DateTimeUtil.dayMonthFormat()) + " at " + depart.format("h:mm A") : "Loading trip";
        if (booking.shortId) {
            title = <ViewTitleWithId title={title} shortId={booking.shortId} />
        }
        const showGMT = adminProfile.isSuperApp && !selectedClientID;
        const provider = providers?.find(provider => provider.id === booking.mode);
        const scheduleAction = booking.actions.find(action => action.action === "SCHEDULE" || action.action === "RESCHEDULE");
        const scheduleButtonLabel = (scheduleAction?.action === "RESCHEDULE" ? "Re-schedule Trip" : "Schedule Trip") + (hasRelatedBookings ? "s" : "");
        const scheduleButton = scheduleAction ?
            <button
                onClick={onSchedule}
                className={appClasses.buttonAdd}
                disabled={scheduleAction.disabled}
            >
                {scheduleAction.hint ?
                    <Tooltip title={scheduleAction.hint}>
                        <div>
                            {scheduleButtonLabel}
                        </div>
                    </Tooltip> : scheduleButtonLabel
                }
            </button> : null;
        const isStatusEditable = canEditBookingStatus(booking);
        const createdAt = booking.createdAt && DateTimeUtil.isoToMomentTimezone(booking.createdAt, timezone);
        const handleRecreateBooking = async () => {
            await waitFor(UsersData.instance.get(booking.userId!));
            navHistory.push(BOOKING_CREATE_SIMILAR_VIEW, { bookingId: booking.id })
        }
        const IconScheduleAction = scheduleAction?.action === "RESCHEDULE" ? IconReschedule : IconSchedule;
        actionsMenuBtn =
            <div style={{
                marginLeft: '40px',
                marginBottom: '-10px',
                marginTop: '-10px',
                ...genJss.alignSelfEnd
            }}>
                <ActionsMenuButton
                    actions={[
                        ...Util.insertIf(adminProfile.features.createSimilarBooking21820, {
                            label: "Create Similar Booking",
                            renderIcon: ({ className }) => <IconAddCopy className={className} />,
                            onClick: handleRecreateBooking
                        }),
                        ...Util.insertIf(onEditStatus && isStatusEditable,
                            {
                                label: "Update Status",
                                renderIcon: ({ className }) => <IconEdit className={className} style={{ boxSizing: 'border-box', padding: '3px' }} />,
                                onClick: () => {
                                    onEditStatus!(booking);
                                }
                            }),
                        ...Util.insertIf(onSchedule && !!scheduleAction,
                            {
                                label: scheduleButtonLabel,
                                renderIcon: ({ className }) => <IconScheduleAction className={className} />,
                                onClick: onSchedule!,
                                disabled: scheduleAction?.disabled,
                                tooltip: scheduleAction?.hint
                            }),
                        ...Util.insertIf(onRefreshExternal && adminProfile.isSkedGoDev && booking.actions?.some(action => action.action === "EXTERNAL_UPDATE"),
                            {
                                label: "Refresh External",
                                renderIcon: ({ className }) => <IconRefresh className={className} />,
                                onClick: () => {
                                    onRefreshExternal!(booking);
                                }
                            })
                    ]}
                />
            </div>;
        const updateOrRequestAction = booking.actions.find(action => action.changes === "PRICE" && (action.action === "UPDATE" || action.action === "REQUEST"));
        const onAddNote = () => {
            navHistory.push(ADD_BOOKING_NOTE_VIEW, { bookingId: booking.id });
        };
        const onEditNote = (noteId: number) => {
            navHistory.push(EDIT_BOOKING_NOTE_VIEW, { bookingId: booking.id, noteId });
        };
        body =
            <>
                <div className={classes.detailForm}>
                    <div className={classNames(classes.gridSection, genStyles.grow)}>
                        <div className={classes.entry} style={{ gridArea: '1 / 1 / 3 / 2' }}>
                            <div className={classes.field} id={"field_trip"}>Trip</div>
                            <div className={genStyles.flex}>
                                <div className={classes.fromToIcon}>
                                    <div className={classes.dot} />
                                    <div className={classes.line} />
                                    <div className={classes.dot} />
                                </div>
                                <div className={classNames(classes.entry, genStyles.spaceBetween)} style={{ gridArea: '1 / 2 / 2 / 3' }}>
                                    <div className={classes.locLab}>From</div>
                                    <div className={classes.locLab}>To</div>
                                </div>
                                <div className={classNames(classes.entry, genStyles.spaceBetween)} style={{ gridArea: '2 / 2 / 3 / 3' }}>
                                    <div className={classes.value}>{booking.from.address}</div>
                                    {fromFavorite && !booking.from.address?.includes(fromFavorite.name ?? "") &&
                                        <div className={classNames(classes.value, classes.locationName)}>{fromFavorite.name}</div>}
                                    <div className={classNames(classes.value, classes.toValue)}>{booking.to ? booking.to.address : ""}</div>
                                    {toFavorite && !booking.to.address?.includes(toFavorite.name ?? "") &&
                                        <div className={classNames(classes.value, classes.locationName)}>{toFavorite.name}</div>}
                                </div>
                            </div>
                        </div>
                        <div className={classes.entry}>
                            <div className={classes.field}>Date</div>
                            <div className={classes.value}>
                                {depart.format(DateTimeUtil.dateFormat())}
                            </div>
                        </div>
                        <div className={classes.entry}>
                            <div className={classes.field}>Time</div>
                            <div className={classes.value}>
                                {depart.format(DateTimeUtil.timeFormat()) +
                                    (arrive ? " - " + arrive.format("h:mm A") : "")
                                    + (showGMT ? " " + DateTimeUtil.toGMTString(depart) : "")}
                            </div>
                        </div>
                        {adminProfile.features.queryTimeAndPref && booking.queryTime !== undefined &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Requested time</div>
                                <div className={classes.value}>
                                    {(booking.queryIsLeaveAfter ? "Pick up at " : "Drop off by ") +
                                        DateTimeUtil.isoToMomentTimezone(booking.queryTime, timezone)
                                            .format(DateTimeUtil.dateFormat() + ", " + DateTimeUtil.timeFormat())}
                                </div>
                            </div>}
                        {createdAt &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Creation time</div>
                                <div className={classes.value}>
                                    {createdAt.format(DateTimeUtil.dateFormat()) + ", " + createdAt.format(DateTimeUtil.timeFormat()) + (showGMT ? " " + DateTimeUtil.toGMTString(createdAt) : "")}
                                </div>
                            </div>}
                        {booking?.relatedBookings.length === 0 && returnTrip &&     // Maintain support for old return trip representation.
                            <div className={classes.entry}>
                                <div className={classes.field}>Return</div>
                                <div className={classes.value}>
                                    {!returnTrip ? "-" : returnTrip === ConfirmationInput.ONE_WAY_ONLY ? "Declined" :
                                        DateTimeUtil.isoToMomentDefaultTimezone(returnTrip)
                                            .format(DateTimeUtil.dateFormat() + ", " + DateTimeUtil.timeFormat())}
                                </div>
                            </div>}
                        <div className={classes.entry}>
                            <div className={classes.field}>Service</div>
                            {provider &&
                                <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                                    <img className={appClasses.modeIcon}
                                        src={TransportUtil.getTransportIconFromProvider(provider)}
                                        alt=""
                                    />
                                    <div className={classes.value}>{provider.modeName}</div>
                                </div>}
                        </div>
                        {/* {booking.durationInMinutes &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Duration</div>
                                <div className={classes.value}>
                                    {DateTimeUtil.durationToBriefString(booking.durationInMinutes)}
                                </div>
                            </div>
                        } */}
                        {adminProfile.features.bookingPrice &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Price</div>
                                <div className={classes.value}>
                                    {FormatUtil.toMoney(booking.price, { nInCents: true })}
                                    {adminProfile.features.priceChanges21702 && updateOrRequestAction && onPriceAction &&
                                        <button className={classes.editStatusBtn} style={{ marginLeft: '10px' }}>
                                            <Tooltip title={updateOrRequestAction.action === "REQUEST" ? "Request price change" : "Update price"}>
                                                <div>
                                                    <IconEdit
                                                        className={classes.iconEdit}
                                                        onClick={(e) => {
                                                            onPriceAction(booking, updateOrRequestAction.action as PriceChangeActionType);
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </button>}
                                    {adminProfile.features.payments && adminProfile.features.autoCharge && booking.chargeAutomatically ?
                                        <> &nbsp; ⋅ &nbsp; Charge automatically</> : null}
                                </div>
                            </div>}
                        {adminProfile.features.priceChanges21702 && booking.priceChange && onPriceAction &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Price Change Request</div>
                                <div className={classes.value}>
                                    <PriceChangeDetails booking={booking} onOpenPriceChange={onPriceAction} />
                                </div>
                            </div>}
                        <div className={classes.entry}>
                            <div className={classes.field}>Status</div>
                            <div className={classes.statusCell} data-testid={"booking-status"}>
                                <TransStatusPill status={booking.status!} />
                                {isStatusEditable &&
                                    <button className={classes.editStatusBtn}>
                                        <Tooltip title={"Edit status"}>
                                            <div>
                                                <IconEdit
                                                    className={classes.iconEdit}
                                                    onClick={(e) => {
                                                        onEditStatus?.(booking);
                                                        e.stopPropagation();
                                                    }}
                                                />
                                            </div>
                                        </Tooltip>
                                    </button>}
                            </div>
                        </div>
                        {false && booking.productName &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Product Name</div>
                                <div className={classes.value}>
                                    {booking.productName}
                                </div>
                            </div>}
                        {booking.transportId &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Transport ID</div>
                                <div className={classes.value}>
                                    {booking.transportId}
                                </div>
                            </div>}
                        {booking.phone &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Phone number</div>
                                <div className={classes.value}>
                                    {booking.phone}
                                </div>
                            </div>}
                        {booking.organizationId &&
                            <div className={classes.entry}>
                                <div className={classes.field}>{i18n.t("Organization")}</div>
                                <div className={classes.value}>
                                    {adminProfile.orgs && (orgFromId(booking.organizationId, adminProfile.orgs)?.name ?? "unknown")}
                                </div>
                            </div>}
                        {booking.confirmationInput?.totalPassengers !== undefined &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Number of Passengers</div>
                                <div className={classes.value}>
                                    {booking.confirmationInput?.totalPassengers}
                                    {booking.confirmationInput.wheelchairPassengers ?   // Not undefined, and > 0
                                        <span style={{ marginLeft: '10px' }}>{"( " + booking.confirmationInput.wheelchairPassengers + " on wheelchair )"}</span> : null}
                                </div>
                            </div>}
                        {booking.distance &&
                            <div className={classes.entry}>
                                <div className={classes.field}>Distance</div>
                                <div className={classes.value}>
                                    {FormatUtil.toDistance(booking.distance)}
                                </div>
                            </div>}
                        {adminProfile.features.trackTripInitiative22828 &&
                            <div className={classes.entry}>
                                <div className={classes.field}>{i18n.t("Initiative")}</div>
                                <div className={classes.value}>
                                    {booking.initiative?.title || 'None'}
                                    {onEditInitiative &&
                                        <button className={classes.editStatusBtn} style={{ marginLeft: '12px' }}>
                                            <Tooltip title={"Edit initiative"}>
                                                <div>
                                                    <IconEdit
                                                        className={classes.iconEdit}
                                                        onClick={(e) => {
                                                            onEditInitiative?.(booking);
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </button>}
                                </div>
                            </div>}
                    </div>
                    {adminProfile.features.payments &&
                        (booking.paymentStatus || onOpenPayment && booking.paymentID) &&
                        <div className={classes.entry}>
                            <label className={classes.field}>Payment</label>
                            <div className={classNames(classes.value, classes.gridSection)}>
                                {booking.paymentStatus &&
                                    <div className={classes.entry}>
                                        <div className={classes.field}>Status</div>
                                        <PaymentStatusPill status={booking.paymentStatus} />
                                    </div>}
                                {onOpenPayment && booking.paymentID &&
                                    <div className={classes.entry} style={{ justifyContent: 'flex-end' }}>
                                        <button
                                            onClick={() => { onOpenPayment(booking.paymentID!) }}
                                            className={appClasses.buttonAdd}
                                        >
                                            Payment details
                                        </button>
                                    </div>}
                                {booking.paymentStatus && booking.message &&
                                    <div className={classes.entry} style={{ gridColumn: '1 / span 2' }}>
                                        <div className={classes.field}>Charge warning</div>
                                        <div className={classes.warn}>
                                            {booking.message}
                                        </div>
                                    </div>}
                            </div>
                        </div>}
                    <div className={classNames(classes.entry, classes.notesSection)}>
                        <div className={classes.field}>Notes</div>
                        <div className={classes.value}>
                            {booking.notes
                                // remove flag condition when adminProfile.features.internalTripAndRiderNotes22953 is enabled in prod
                                .filter(note => adminProfile.features.internalTripAndRiderNotes22953 ? (adminProfile.isSuperAdmin || !note.internal) : true)
                                .map((note, i) => <Note note={note} timezone={booking.timezone} onEdit={adminProfile.features.internalTripAndRiderNotes22953 ? () => onEditNote(i) : undefined} key={i} />)
                                .reverse()}
                        </div>
                        {adminProfile.features.internalTripAndRiderNotes22953 &&
                            <button className={appClasses.buttonAdd} onClick={() => onAddNote()} style={{ marginTop: '16px' }}>Add Note</button>}
                    </div>
                    {/* <div className={classNames(styles.group, styles.noteSection)}> */}
                    <div className={classes.entry}>
                        <label className={classes.field}>Additional Details</label>
                        <div className={classNames(classes.value, classes.gridSection)} style={{ maxWidth: '550px' }}>
                            <div className={classes.entry}>
                                <div className={classes.field}>Mobility options</div>
                                <div className={classes.value}>
                                    {booking.confirmationInput?.mobilityOptions.map(FormatUtil.camelCaseToSpaced).join(", ")}
                                </div>
                            </div>
                            <div className={classes.entry}>
                                <div className={classes.field}>Trip purpose</div>
                                <div className={classes.value}>
                                    {booking.confirmationInput?.purpose}
                                </div>
                            </div>
                            <div className={classes.entry}>
                                <div className={classes.field}>Booking notes</div>
                                <div className={classes.value}>
                                    {booking.confirmationInput?.notes}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <div className={classes.buttons}>
                        {adminProfile.itemAuth(ModelType.Transaction, ModelOperation.delete) &&
                            <button onClick={() => onRemove?.(booking)} className={appClasses.buttonDelete}>
                                Delete
                            </button>}
                    </div>
                    <div className={classes.buttons}>
                        {!canCancel(booking).hide &&
                            <button
                                onClick={() => onCancel?.(booking.id)}
                                className={appClasses.buttonOk}
                                disabled={!canCancel(booking).value}
                            >
                                {(canCancel(booking).actionLabel ?? "Cancel") + " Trip" + (canCancel(booking).withRelated ? "s" : "")}
                            </button>}
                        {scheduleButton}
                    </div>
                </div>
            </>
    } else {
        body = <Loading />;
    }
    const onOpenUserDetails = userId => {
        // waitFor(UsersData.instance.get(userId));
        navHistory.pushS(`/userId/${userId}`);
    }
    return (
        <div className={classes.main}>
            <div className={classes.leftContainer}>
                <View
                    title={title}
                    subtitle={
                        <>
                            <BookingSubtitle booking={booking} selectedClientID={selectedClientID} clients={clients} onOpenUserDetails={onOpenUserDetails} />
                            {actionsMenuBtn}
                        </>
                    }
                    styles={hasRelatedBookings ?
                        (_theme, defaultThemed) => ({
                            header: {
                                ...defaultThemed.header,
                                borderBottom: 'none',
                                marginBottom: '0'
                            }
                        }) : undefined}
                >
                    {tabs}
                    {body}
                </View>
            </div >
            <div className={classes.mapContainer}>
                <TKRoot config={config}>
                    <TKUIMapView
                        from={booking?.from}
                        to={booking?.to || undefined}
                        readonly={true}
                        hideLocations={true}
                        showCurrLocBtn={false}
                        ref={mapRef}
                    />
                </TKRoot>
            </div>
        </div >
    );
}

export default BookingView;