import { JsonObject, JsonProperty } from "json2typescript";

export const INITIATIVE_ID_FIELD = "Id";
export const INITIATIVE_TITLE_FIELD = "title";
export const INITIATIVE_BILLING_CODE_FIELD = "billingCode";
export const INITIATIVE_DESCRIPTION_FIELD = "description";
export const INITIATIVE_CLIENT_ID_FIELD = "clientId";
export const INITIATIVE_CREATED_AT_FIELD = "createdAt";
export const INITIATIVE_DELETED_AT_FIELD = "deletedAt";

export const INITIATIVE_SCHEMA_BUILDER = () => `
    {
        ${INITIATIVE_ID_FIELD}
        ${INITIATIVE_TITLE_FIELD}
        ${INITIATIVE_BILLING_CODE_FIELD}
        ${INITIATIVE_DESCRIPTION_FIELD}
        ${INITIATIVE_CLIENT_ID_FIELD}
        ${INITIATIVE_CREATED_AT_FIELD}
        ${INITIATIVE_DELETED_AT_FIELD}    
    }
`;

@JsonObject
class Initiative {
    @JsonProperty(INITIATIVE_ID_FIELD, String, true)
    public readonly id: string = "";
    @JsonProperty(INITIATIVE_TITLE_FIELD, String, true)
    public readonly title: string = "";
    @JsonProperty(INITIATIVE_BILLING_CODE_FIELD, String, true)
    public readonly billingCode: string = "";
    @JsonProperty(INITIATIVE_DESCRIPTION_FIELD, String, true)
    public readonly description: string = "";
    @JsonProperty(INITIATIVE_CLIENT_ID_FIELD, String, true)
    public readonly clientId?: string = undefined;
    @JsonProperty(INITIATIVE_CREATED_AT_FIELD, String, true)
    public readonly createdAt?: string = undefined;
    @JsonProperty(INITIATIVE_DELETED_AT_FIELD, String, true)
    public readonly deletedAt?: string = undefined;
}

export default Initiative;