import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert, Any } from "json2typescript";
import { TKLocation as Location } from "tripkit-react";
import DateTimeUtil from "../util/DateTimeUtil";
import { Moment } from "moment-timezone";
import {
    TRANSACTION_AMOUNT_FIELD,
    TRANSACTION_ID_FIELD,
    TRANSACTION_NOTE_FIELD,
    TRANSACTION_TIMESTAMP_FIELD,
    TRANSACTION_USER_ID_FIELD,
    TRANSACTION_USER_NAME_FIELD,
    TRANSACTION_TYPE_FIELD,
    BOOKING_BUDGET_POINTS_FIELD,
    BOOKING_ARRIVE_FIELD,
    BOOKING_DEPART_FIELD,
    BOOKING_PRICE_FIELD,
    REDEMPTION_STATUS_FIELD,
    BOOKING_MODE_FIELD,
    TRANSACTION_USER_EXTERNAL_ID_FIELD,
    BOOKING_PRODUCT_NAME_FIELD,
    TRANSACTION_POINTS_FIELD,
    BOOKING_FROM_FIELD,
    BOOKING_TO_FIELD,
    REDEMPTION_REWARD_ID_FIELD,
    REDEMPTION_REWARD_TITLE_FIELD,
    REDEMPTION_REWARD_DESCRIPTION_FIELD,
    TRANSACTION_EXTERNAL_ID_FIELD,
    REDEMPTION_UPDATE_MESSAGES_FIELD,
    REDEMPTION_UPDATE_MESSAGE_TIMESTAMP_FIELD,
    REDEMPTION_UPDATE_MESSAGE_FIELD,
    REDEMPTION_FIXED_STATUS_FIELD,
    BOOKING_TRANSPORT_ID_FIELD,
    BOOKING_PHONE_FIELD,
    BOOKING_CONFIRMATION_INPUT_FIELD,
    BOOKING_CONFIRMATION_INPUT_NOTES_FIELD,
    BOOKING_CONFIRMATION_INPUT_MOBILITY_OPTIONS_FIELD,
    BOOKING_CONFIRMATION_INPUT_PURPOSE_FIELD,
    TRANSACTION_NOTES_FIELD,
    TRANSACTION_NOTES_TEXT_FIELD,
    TRANSACTION_NOTES_INTERNAL_FIELD,
    TRANSACTION_NOTES_EMAIL_FIELD,
    TRANSACTION_NOTES_PROVIDER_MODE_FIELD,
    TRANSACTION_NOTES_TIMESTAMP_FIELD,
    TRANSACTION_USER_PHONE_FIELD,
    BOOKING_CONFIRMATION_INPUT_RETURN_TRIP_FIELD,
    BOOKING_RETURN_TRIP_DEPART_FIELD,
    BOOKING_QUERY_TIME,
    BOOKING_QUERY_IS_LEAVE_AFTER,
    BOOKING_PICKUP_WINDOW_DURATION,
    BOOKING_CONFIRMATION_INPUT_TOTAL_PASSENGERS_FIELD,
    BOOKING_CONFIRMATION_INPUT_WHEELCHAIR_PASSENGERS_FIELD,
    BOOKING_PAYMENT_ID_FIELD,
    TRANSACTION_PAYMENT_STATUS_FIELD,
    TRANSACTION_STRIPE_URL_FIELD,
    BOOKING_AUTO_CHARGE_FIELD,
    TRANSACTION_CLIENT_ID_FIELD,
    BOOKING_MESSAGE_FIELD,
    TRANSACTION_ORGANIZATION_FIELD,
    BOOKING_RELATED_BOOKINGS,
    RELATED_BOOKING_ID_FIELD,
    RELATED_BOOKING_TYPE_FIELD,
    BOOKING_CREATED_AT_FIELD,
    BOOKING_UPDATED_AT_FIELD,
    BOOKING_ACTIONS,
    BOOKING_ACTION_NEW_STATUS,
    BOOKING_ACTION_ACTION,
    BOOKING_ACTION_ALERT,
    BOOKING_ACTION_NOTIFICATIONS,
    BOOKING_ACTION_ALERT_CHECKED,
    BOOKING_ACTION_ALERT_UNCHECKED,
    BOOKING_ACTION_WITH_RELATED,
    BOOKING_UPDATE_RELATED,
    BOOKING_DISTANCE_FIELD,
    TRANSACTION_BUNDLE_NAME_FIELD,
    BOOKING_ACTION_ONLY_CHECKED,
    BOOKING_PRICE_CHANGES_FIELD,
    BOOKING_PRICE_CHANGE_ID_FIELD,
    BOOKING_PRICE_CHANGE_CREATED_AT_FIELD,
    BOOKING_PRICE_CHANGE_STATUS_FIELD,
    BOOKING_PRICE_CHANGE_AMOUNT_FIELD,
    BOOKING_PRICE_CHANGE_NOTE_FIELD,
    BOOKING_PRICE_CHANGE_ORIGINAL_AMOUNT_FIELD,
    BOOKING_ACTION_CHANGES,
    TRANSACTION_SHORT_ID_FIELD,
    TRANSACTION_WALLET_BALANCE_FIELD,
    TRANSACTION_WALLET_FIELD,
    TRANSACTION_WALLET_NAME_FIELD,
    TRANSACTION_WALLET_APPLIED_TIMESTAMP_FIELD,
    TRANSACTION_WALLET_EXPIRATION_TIMESTAMP_FIELD,
    TRANSACTION_BALANCE_FIELD,
    MONEY_TRANSACTION_BOOKING_ID_FIELD,
    MONEY_TRANSACTION_TYPE_FIELD,
    MONEY_TRANSACTION_OPERATION_FIELD,
    TRANSACTION_USER_EMAIL_FIELD,
    TRANSACTION_USER_SHORT_ID_FIELD,
    BOOKING_INITIATIVE_FIELD,
    BOOKING_ACTION_HINT,
    BOOKING_ACTION_DISABLED
} from "../data/TransactionsSchema";
import Util from "../util/Util";
import FormatUtil from "../util/FormatUtil";
import { adminProfile } from "../account/AdminProfile";
import { Theme } from "../css/Theme";
import { Balance } from "./User";
import Initiative from "./Initiative";

export enum TransType {
    BOOKING = "BOOKING",
    MONEY = "MONEY",
    REWARD = "REWARD",
    REDEMPTION = "REDEMPTION",
    BALANCE = "BALANCE"
}

@JsonConverter
export class TransTypeConverter implements JsonCustomConvert<TransType> {
    public serialize(transType: TransType): any {
        return transType;
    }
    public deserialize(transTypeS: any): TransType {
        return transTypeS;
    }
}

export enum TransStatus {
    // Redemption
    PENDING = "PENDING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED",
    REVERTED = "REVERTED",
    // Booking
    PROCESSING = "PROCESSING",
    URGENT_ACTION_REQUIRED = "URGENT_ACTION_REQUIRED",
    PROVIDER_ACCEPTED = "PROVIDER_ACCEPTED",
    // USER_ACCEPTED = "USER_ACCEPTED",
    // ARRIVING = "ARRIVING",
    IN_PROGRESS = "IN_PROGRESS",
    PROVIDER_CANCELED = "PROVIDER_CANCELED",
    PROVIDER_DECLINED = "PROVIDER_DECLINED",
    PROVIDER_NO_SHOW = "PROVIDER_NO_SHOW",
    USER_CANCELED = "USER_CANCELED",
    USER_CANCELED_LATE = "USER_CANCELED_LATE",
    COMPLETED = "COMPLETED",
    ENABLED = "ENABLED",
    PENDING_CHANGES = "PENDING_CHANGES",
    WAITING_PAYMENT = "WAITING_PAYMENT",
}

export function transStatusToDisplayString(status: TransStatus): string {
    switch (status) {
        case TransStatus.PROCESSING:
            return "Standby";
        case TransStatus.PROVIDER_ACCEPTED:
            return "Confirmed";
        case TransStatus.IN_PROGRESS:
            return "Ongoing";
        case TransStatus.COMPLETED:
            return "Completed";
        case TransStatus.PROVIDER_CANCELED:
            return "Provider Canceled";
        case TransStatus.USER_CANCELED:
            return "User Canceled";
        case TransStatus.PENDING_CHANGES:
            return "Pending Changes";
        case TransStatus.PENDING:
            return "Pending";
        case TransStatus.URGENT_ACTION_REQUIRED:
            return "Urgent - Action Required";
        case TransStatus.USER_CANCELED_LATE:
            return "Late Notice Cancellation"
    }
    return status ? FormatUtil.upperCaseToSpaced(status) : "Unknown";
}

export function moneyTypeToDisplayString(value: string): string {
    return FormatUtil.upperCaseToSpaced(value);
}

export function moneyOperationToDisplayString(value: string): string {
    return FormatUtil.upperCaseToSpaced(value);
}

export const redemptionStatusValues: TransStatus[] = [
    TransStatus.PENDING, TransStatus.APPROVED, TransStatus.REJECTED, TransStatus.REVERTED
];
export const bookingStatusValues = Object.values(TransStatus)
    .filter((value: TransStatus) => !redemptionStatusValues.includes(value));

export const paymentStatusValues = ["PENDING", "REQUIRES_CAPTURE", "SUCCEEDED", "CANCELED", "FAILED", "FREE", "WALLET", "TO_BE_INVOICED"] as const;
export type PaymentStatus = typeof paymentStatusValues[number];

export const actionTypeValues = ["SCHEDULE", "RESCHEDULE", "UPDATE", "CANCEL", "REQUEST", "RESPOND", "EXTERNAL_UPDATE"] as const;
export type ActionType = typeof actionTypeValues[number];
export const actionChangesValues = ["STATUS", "PRICE"] as const;
export type ActionChanges = typeof actionChangesValues[number];

export const notificationTypeValues = ["USER", "TSP"] as const;
export type NotificationType = typeof notificationTypeValues[number];

export const paymentMethodValues = ["WALLET", "FREE", "STRIPE", "INVOICE", "EXTERNAL"] as const;
export type PaymentMethod = typeof paymentMethodValues[number];

export const priceChangeStatusValues = ["CREATED", "ACCEPTED", "REJECTED"] as const;
export type PriceChangeStatus = typeof priceChangeStatusValues[number];
export function priceChangeStatusLabel(status: PriceChangeStatus): string {
    switch (status) {
        case "CREATED": return "Pending";
        default: return FormatUtil.upperCaseToSpaced(status);
    }
}
export function priceChangeStatusColor(status: PriceChangeStatus, theme: Theme): string | undefined {
    switch (status) {
        case "CREATED": return theme.colorInfo;
        case "ACCEPTED": return theme.colorSuccess;
        case "REJECTED": return theme.colorError;
    }
}

@JsonConverter
export class TransStatusConverter implements JsonCustomConvert<TransStatus> {
    public serialize(transType: TransStatus): any {
        return transType;
    }
    public deserialize(transTypeS: any): TransStatus {
        return transTypeS;
    }
}

@JsonObject
export class UpdateMessage {
    @JsonProperty(REDEMPTION_UPDATE_MESSAGE_TIMESTAMP_FIELD, String, true)
    public timestamp: string = "";
    @JsonProperty(REDEMPTION_UPDATE_MESSAGE_FIELD, String, true)
    public message: string = "";
}

@JsonObject
export class TransactionNote {

    public static create(text: string, { internal = false }: { internal?: boolean } = {}): TransactionNote {
        return Util.iAssign(new TransactionNote(), {
            text,
            timestamp: DateTimeUtil.getNow().utc().toISOString(),
            creatorEmail: adminProfile.email,
            internal,
            providerMode: adminProfile.tspMode
        });
    }

    @JsonProperty(TRANSACTION_NOTES_TIMESTAMP_FIELD, String)
    public timestamp: string = "";
    @JsonProperty(TRANSACTION_NOTES_TEXT_FIELD, String, true)
    public text: string = "";
    @JsonProperty(TRANSACTION_NOTES_INTERNAL_FIELD, Boolean, true)
    public internal: boolean = true;
    @JsonProperty(TRANSACTION_NOTES_EMAIL_FIELD, String, true)
    public creatorEmail: string = "";
    @JsonProperty(TRANSACTION_NOTES_PROVIDER_MODE_FIELD, String, true)
    public providerMode?: string = undefined;
    editing?: boolean;
}

@JsonObject
export class ConfirmationInput {
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_MOBILITY_OPTIONS_FIELD, [String], true)
    public mobilityOptions: string[] = [];
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_PURPOSE_FIELD, String, true)
    public purpose: string = "";
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_NOTES_FIELD, String, true)
    public notes: string = "";
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_RETURN_TRIP_FIELD, String, true)
    public returnTrip?: string = undefined;     // ISO8601 | "One-way only"
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_TOTAL_PASSENGERS_FIELD, Number, true)
    public totalPassengers?: number = undefined;
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_WHEELCHAIR_PASSENGERS_FIELD, Number, true)
    public wheelchairPassengers?: number = undefined;

    public static ONE_WAY_ONLY = "One-way only";
}

@JsonConverter
export class LocationConverter implements JsonCustomConvert<Location> {
    public serialize(location: Location): any {
        return Util.filterKeys(Util.serialize(location), ["address", "lat", "lng"]);
    }
    public deserialize(locationJson: any): Location {
        return Util.iAssign(new Location(), locationJson);
    }
}

function relationTypeToOrder(type: string): number {
    switch (type) {
        case "OUTBOUND": return 0;
        case "RETURN": return 1;
        default: return 2;
    }
}

export function relationTypeCompareFc(a: string, b: string): number {
    return relationTypeToOrder(a) - relationTypeToOrder(b);
}

export type RelatedBookingType = "OUTBOUND" | "RETURN" | "RELATED";

@JsonObject
export class RelatedBooking {
    @JsonProperty(RELATED_BOOKING_ID_FIELD, String, true)
    public bookingId: string = "";
    @JsonProperty(RELATED_BOOKING_TYPE_FIELD, String, true)
    public type: RelatedBookingType = "RELATED";
}

@JsonObject
export class WithRelated {
    @JsonProperty(BOOKING_ACTION_ALERT_CHECKED, String, true)
    public alertChecked?: string = undefined;
    @JsonProperty(BOOKING_ACTION_ALERT_UNCHECKED, String, true)
    public alertUnchecked?: string = undefined;
    @JsonProperty(BOOKING_ACTION_ONLY_CHECKED, Boolean, true)
    public onlyChecked: boolean = false;
}
@JsonObject
export class BookingAction {
    @JsonProperty(BOOKING_ACTION_NEW_STATUS, TransStatusConverter, true)
    public newStatus: TransStatus = TransStatus.PENDING;
    @JsonProperty(BOOKING_ACTION_ACTION, String, true)
    public action: ActionType = "UPDATE";
    @JsonProperty(BOOKING_ACTION_CHANGES, String, true)
    public changes: ActionChanges = "STATUS";
    @JsonProperty(BOOKING_ACTION_ALERT, String, true)
    public alert?: string = undefined;
    @JsonProperty(BOOKING_ACTION_NOTIFICATIONS, [String], true)
    public notifications: NotificationType[] = [];
    @JsonProperty(BOOKING_ACTION_WITH_RELATED, WithRelated, true)
    public withRelated?: WithRelated = undefined;
    @JsonProperty(BOOKING_ACTION_HINT, String, true)
    public hint?: string = undefined;
    @JsonProperty(BOOKING_ACTION_DISABLED, Boolean, true)
    public disabled: boolean = false;
}

@JsonObject class PriceChange {
    @JsonProperty(BOOKING_PRICE_CHANGE_ID_FIELD, String, true)
    public id: string = "";
    @JsonProperty(BOOKING_PRICE_CHANGE_CREATED_AT_FIELD, String, true)
    public createdAt?: string = undefined;
    @JsonProperty(BOOKING_PRICE_CHANGE_STATUS_FIELD, String, true)
    public status?: PriceChangeStatus = undefined;
    @JsonProperty(BOOKING_PRICE_CHANGE_AMOUNT_FIELD, Number, true)
    public amount?: number = undefined;
    @JsonProperty(BOOKING_PRICE_CHANGE_ORIGINAL_AMOUNT_FIELD, Number, true)
    public originalAmount?: number = undefined;
    @JsonProperty(BOOKING_PRICE_CHANGE_NOTE_FIELD, String, true)
    public note?: string = undefined;
}

@JsonObject class TransactionWallet {
    @JsonProperty(TRANSACTION_WALLET_NAME_FIELD, String, true)
    public name?: string = undefined;
    @JsonProperty(TRANSACTION_WALLET_APPLIED_TIMESTAMP_FIELD, String, true)
    public appliedTimestamp?: string = undefined;
    @JsonProperty(TRANSACTION_WALLET_EXPIRATION_TIMESTAMP_FIELD, String, true)
    public expirationTimestamp?: string = undefined;
}

@JsonObject
class Transaction {
    @JsonProperty(TRANSACTION_CLIENT_ID_FIELD, String, true)
    public clientId?: string = undefined
    @JsonProperty(TRANSACTION_ORGANIZATION_FIELD, String, true)
    public organizationId?: string = undefined
    // @JsonProperty(TRANSACTION_TYPE_FIELD, TransTypeConverter)
    // public type: TransType = TransType.BOOKING;
    // TODO: UNDO!
    @JsonProperty(TRANSACTION_TYPE_FIELD, TransTypeConverter, true)
    public type: TransType = TransType.MONEY;
    @JsonProperty(TRANSACTION_TIMESTAMP_FIELD)
    public timestamp: number = -1;
    @JsonProperty(BOOKING_MODE_FIELD, String, true)
    public mode: string = "";
    @JsonProperty(BOOKING_FROM_FIELD, LocationConverter, true)
    public from: Location = new Location();
    @JsonProperty(BOOKING_TO_FIELD, LocationConverter, true)
    public to: Location = new Location();
    @JsonProperty(BOOKING_DEPART_FIELD, Number, true)
    public depart: number = -1;
    @JsonProperty(BOOKING_ARRIVE_FIELD, Number, true)
    public arrive: number | null = null;
    @JsonProperty(BOOKING_PRICE_FIELD, Number, true)
    public price: number = 0;
    @JsonProperty(TRANSACTION_POINTS_FIELD, Number, true)
    public points: number | undefined = undefined;
    @JsonProperty(TRANSACTION_NOTE_FIELD, String, true)
    public note: string | undefined = undefined;
    @JsonProperty(TRANSACTION_NOTES_FIELD, [TransactionNote], true)
    public notes: TransactionNote[] = [];
    @JsonProperty(TRANSACTION_ID_FIELD)
    public readonly id: string = "";
    @JsonProperty(TRANSACTION_SHORT_ID_FIELD, String, true)
    public readonly shortId: string = "";
    @JsonProperty(TRANSACTION_USER_ID_FIELD, String, true)
    public userId: string | undefined = "";
    @JsonProperty(TRANSACTION_USER_SHORT_ID_FIELD, String, true)
    public userShortId: string | undefined = "";
    @JsonProperty(TRANSACTION_USER_NAME_FIELD, String, true)
    public userName: string | undefined = undefined;
    @JsonProperty(TRANSACTION_USER_EXTERNAL_ID_FIELD, String, true)
    public userExternalId: string | undefined = undefined;
    @JsonProperty(TRANSACTION_USER_PHONE_FIELD, String, true)
    public userPhone: string | undefined = undefined;
    @JsonProperty(TRANSACTION_USER_EMAIL_FIELD, String, true)
    public userEmail: string | undefined = undefined;
    @JsonProperty(TRANSACTION_AMOUNT_FIELD, Number, true)
    public amount: number = -1;
    @JsonProperty(TRANSACTION_WALLET_BALANCE_FIELD, Number, true)
    public walletBalance?: number = undefined;
    @JsonProperty(BOOKING_BUDGET_POINTS_FIELD, Number, true)
    public budgetPoints: number | undefined = undefined;
    @JsonProperty(BOOKING_PRODUCT_NAME_FIELD, String, true)
    public productName: string | undefined = undefined;
    @JsonProperty(REDEMPTION_STATUS_FIELD, TransStatusConverter, true)
    public status?: TransStatus = undefined;
    @JsonProperty(REDEMPTION_REWARD_ID_FIELD, String, true)
    public rewardId?: string = undefined;
    @JsonProperty(REDEMPTION_REWARD_TITLE_FIELD, String, true)
    public rewardTitle?: string = undefined;
    @JsonProperty(REDEMPTION_REWARD_DESCRIPTION_FIELD, String, true)
    public description?: string = undefined;
    @JsonProperty(REDEMPTION_FIXED_STATUS_FIELD, Boolean, true)
    public fixedStatus: boolean = false;
    @JsonProperty(REDEMPTION_UPDATE_MESSAGES_FIELD, [UpdateMessage], true)
    public updateMessages: UpdateMessage[] = [];
    @JsonProperty(TRANSACTION_EXTERNAL_ID_FIELD, String, true)
    public externalId?: string = undefined;
    @JsonProperty(BOOKING_TRANSPORT_ID_FIELD, String, true)
    public transportId: string | undefined = undefined;
    @JsonProperty(BOOKING_PHONE_FIELD, String, true)
    public phone: string | undefined = undefined;
    @JsonProperty(BOOKING_CONFIRMATION_INPUT_FIELD, ConfirmationInput, true)
    public confirmationInput: ConfirmationInput | undefined = undefined;
    @JsonProperty(BOOKING_RETURN_TRIP_DEPART_FIELD, String, true)
    public returnTripDepart?: string = undefined;
    @JsonProperty(BOOKING_QUERY_TIME, String, true)
    public queryTime?: string = undefined;
    @JsonProperty(BOOKING_QUERY_IS_LEAVE_AFTER, Boolean, true)
    public queryIsLeaveAfter?: boolean = undefined;
    @JsonProperty(BOOKING_PICKUP_WINDOW_DURATION, Number, true)
    public pickupWindowDuration?: number = 30;
    @JsonProperty(BOOKING_PAYMENT_ID_FIELD, String, true)
    public paymentID?: string = undefined;
    @JsonProperty(BOOKING_AUTO_CHARGE_FIELD, Boolean, true)
    public chargeAutomatically?: boolean = undefined;
    @JsonProperty(BOOKING_MESSAGE_FIELD, String, true)
    public message?: string = undefined;
    @JsonProperty(TRANSACTION_PAYMENT_STATUS_FIELD, String, true)
    public paymentStatus?: string = undefined;
    @JsonProperty(TRANSACTION_STRIPE_URL_FIELD, String, true)
    public stripeUrl?: string = undefined;
    @JsonProperty(TRANSACTION_BUNDLE_NAME_FIELD, String, true)
    public bundleName?: string = undefined;
    @JsonProperty(BOOKING_RELATED_BOOKINGS, [RelatedBooking], true)
    public relatedBookings: RelatedBooking[] = [];
    @JsonProperty(BOOKING_ACTIONS, [BookingAction], true)
    public actions: BookingAction[] = [];
    @JsonProperty(BOOKING_UPDATE_RELATED, Boolean, true)
    public updateRelated?: boolean = undefined;
    @JsonProperty(BOOKING_CREATED_AT_FIELD, String, true)
    public createdAt?: string = undefined;
    @JsonProperty(BOOKING_UPDATED_AT_FIELD, String, true)
    public updatedAt?: string = undefined;
    @JsonProperty(BOOKING_DISTANCE_FIELD, Number, true)
    public distance?: number = undefined;
    @JsonProperty(BOOKING_PRICE_CHANGES_FIELD, [PriceChange], true)
    public priceChanges: PriceChange[] = [];
    @JsonProperty(BOOKING_INITIATIVE_FIELD, Initiative, true)
    public initiative?: Initiative = undefined;
    @JsonProperty(TRANSACTION_WALLET_FIELD, TransactionWallet, true)
    public wallet?: TransactionWallet = undefined;
    @JsonProperty(TRANSACTION_BALANCE_FIELD, Balance, true)
    public balance?: Balance = undefined;
    @JsonProperty(MONEY_TRANSACTION_BOOKING_ID_FIELD, String, true)
    public bookingId?: string = undefined;
    @JsonProperty(MONEY_TRANSACTION_TYPE_FIELD, String, true)
    public moneyType?: string = undefined;
    @JsonProperty(MONEY_TRANSACTION_OPERATION_FIELD, String, true)
    public moneyOperation?: string = undefined;

    get durationInMinutes(): number | undefined {
        return this.arrive ? Math.floor(this.arrive / 60) - Math.floor(this.depart / 60) : undefined;
    }

    get departMoment(): Moment {
        return DateTimeUtil.momentTZTime(this.depart * 1000, this.timezone);
    }

    set departMoment(depart: Moment) {
        this.depart = Math.floor(depart.valueOf() / 1000);
    }

    get arriveMoment(): Moment | undefined {
        return this.arrive ? DateTimeUtil.momentTZTime(this.arrive * 1000, this.timezone) : undefined;
    }

    set arriveMoment(arrive: Moment | undefined) {
        this.arrive = arrive ? Math.floor(arrive.valueOf() / 1000) : null;
    }

    get timestampMoment(): Moment {
        return DateTimeUtil.momentTZTime(this.timestamp * 1000);
    }

    get transactionMoment(): Moment {
        return this.type === TransType.BOOKING ? this.departMoment : this.timestampMoment;
    }

    get transactionTime(): number {
        return this.type === TransType.BOOKING ? this.depart : this.timestamp;
    }

    /**
     * false for opal trips
     */
    get hasArrive(): boolean {
        return !!this.arrive;
    }

    /**
     * Workaround until timezone comes from booking.
     */
    get timezone(): string {
        return adminProfile.getTimezone(this.clientId);
    }

    get editingInternalNote(): string {
        return this.notes.find(note => note.editing && note.internal)?.text ?? "";
    }

    set editingInternalNote(text: string) {
        const currentNote = this.notes.find(note => note.editing && note.internal);
        if (currentNote) {
            if (text) {
                currentNote.text = text;
            } else {
                this.notes = this.notes.filter(note => note !== currentNote);
            }
        } else if (text) {
            this.notes.push(
                Util.iAssign(new TransactionNote(), {
                    text,
                    timestamp: DateTimeUtil.getNow().utc().toISOString(),
                    creatorEmail: adminProfile.email,
                    internal: true,
                    providerMode: adminProfile.tspMode,
                    editing: true
                })
            );
        }
    }

    get editingExternalNote(): string {
        return this.notes.find(note => note.editing && !note.internal)?.text ?? "";
    }

    set editingExternalNote(text: string) {
        const currentNote = this.notes.find(note => note.editing && !note.internal);
        if (currentNote) {
            if (text) {
                currentNote.text = text;
            } else {
                this.notes = this.notes.filter(note => note !== currentNote);
            }
        } else if (text) {
            this.notes.push(
                Util.iAssign(new TransactionNote(), {
                    text,
                    timestamp: DateTimeUtil.getNow().utc().toISOString(),
                    creatorEmail: adminProfile.email,
                    internal: false,
                    providerMode: adminProfile.tspMode,
                    editing: true
                })
            );
        }
    }

    get priceChange(): PriceChange | undefined {
        return this.priceChanges[this.priceChanges.length - 1];
    }

    get priceChangeRequest(): PriceChange | undefined {
        return this.priceChanges.slice().reverse().find(priceChange => priceChange.status === "CREATED");
    }

    get priceChangeResponse(): PriceChange | undefined {
        const request = this.priceChangeRequest;
        return request ? this.priceChanges[this.priceChanges.indexOf(request) + 1] : undefined;
    }

}

@JsonObject
class MoneyTransaction {
    @JsonProperty(TRANSACTION_USER_ID_FIELD)
    public userId: string = "";
    @JsonProperty(TRANSACTION_TIMESTAMP_FIELD)
    public timestamp: number = -1;
    @JsonProperty(TRANSACTION_AMOUNT_FIELD)
    public amount: number = 0;
    @JsonProperty(TRANSACTION_NOTE_FIELD, String, true)
    public note: string | undefined = undefined;

    @JsonProperty(TRANSACTION_ID_FIELD)
    public readonly id: string = "";

    get timestampMoment(): Moment {
        return DateTimeUtil.momentTZTime(this.timestamp * 1000);
    }

    set timestampMoment(timestamp: Moment) {
        this.timestamp = Math.floor(timestamp.valueOf() / 1000);
    }
}

export default Transaction;
export { MoneyTransaction };