import React, { useEffect, useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { assignBundleViewJss } from "./AssignBundleView.jss";
import Loading from "../view/Loading";
import Bundle, { BundleConfig } from "../model/Bundle";
import SideView from "../view/SideView";
import AutocompleteBox, { AutocompleteBoxType } from "../autocomplete/AutocompleteBox";
import BundlesData from "../data/BundlesData";
import Util from "../util/Util";
import Filter from "../data/Filter";
import { PART_DETAIL_VIEW_PATH } from "./UserViewConstants";
import BundleTable from "../bundle/BundleTable";
import { durationOptions, durationStringFromDays } from "../bundle/EditBundleView";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { DefaultSelect } from "../rewards/StatusSelect";
import Table, { ITableColSpec } from "../view/Table";
import FormatUtil from "../util/FormatUtil";
import withAsycnData from "../data/WithAsyncData";
import UsersData from "../data/UsersData";
import User from "../model/User";
import UIUtil from "../util/UIUtil";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import { i18n } from "../i18n/TKI18nConstants";
import TransactionsData from "../data/TransactionsData";

const BundleAutocompleteBox = AutocompleteBox as AutocompleteBoxType<Bundle>;

type IStyle = ReturnType<typeof assignBundleViewJss>;

interface IProps extends WithClasses<IStyle> {
    user?: User;
    future?: boolean;
    onRequestClose: (updateData?: { user: User, bundle: Bundle, duration: number, amount?: number, note?: string }) => void;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

const AssignBundleView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { user, future, onRequestClose, classes, appClasses } = props;
    const [waiting, setWaiting] = useState<boolean>(false);
    const [bundle, setBundle] = useState<Bundle | undefined>();
    const [bundlePreview, setBundlePreview] = useState<Bundle | undefined>();
    const [duration, setDuration] = useState<number | undefined>();
    const [amount, setAmount] = useState<number | undefined>(undefined);
    const [note, setNote] = useState<string>("");
    const [addAmount, setAddAmount] = useState<boolean>(false);

    const formRef = useRef<any>(null);

    const bundleDurations = bundle && durationOptions.filter(option => bundle.bundleConfig.find(config => config.subscriptionDurationInDays === option.value));

    useEffect(() => {
        if (bundleDurations && bundleDurations.length === 1) {
            setDuration(bundleDurations[0].value);
        }
    }, [bundleDurations]);

    return (
        <SideView
            title={`Assign ${future ? "Future" : ""} ${i18n.t("Bundle")}`}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        <div className={appClasses.formGroup}>
                            <label>
                                {i18n.t("Bundle")}
                            </label>
                            <div className={classNames(appClasses.value, appClasses.input, classes.bundleValue)}>
                                <BundleAutocompleteBox
                                    value={bundle}
                                    onChange={update => {
                                        // Need to compare bundles by id since bundle objects are different from different calls to
                                        // resultsFor function (given it deserializes JSON to Bundle objects each time).
                                        if (update?.id === bundle?.id) {
                                            return;
                                        }
                                        setBundle(update);
                                        setAddAmount(false);
                                        setAmount(undefined);
                                        setDuration(undefined);
                                    }}
                                    onResultHighlight={setBundlePreview}
                                    toText={value => value.name ?? ""}
                                    resultsFor={query => {
                                        const filter = Util.deserialize(
                                            {
                                                pageSize: 1000, page: 1, clientId: user!.clientId
                                            }, Filter);
                                        filter.clientId = user!.clientId;
                                        return BundlesData.instance.getList(filter)
                                            .then(bundlesResult => bundlesResult.items.filter(bundle => bundle.name?.toLowerCase().includes(query.toLowerCase())));
                                    }}
                                    placeholder={`Search ${i18n.t("bundle")}`}
                                />
                            </div>
                        </div>
                        {bundleDurations &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    {i18n.t("Subscription.duration")}
                                </label>
                                <div className={classNames(appClasses.value, classes.durationValue)}>
                                    <Validator
                                        value={duration}
                                        validators={["required"]}
                                        errorMessages={["this field is required"]}
                                        resetOnValid
                                    >
                                        {({ errorMessage }) =>
                                            <>
                                                <DefaultSelect
                                                    options={bundleDurations}
                                                    placeholder={"Pick duration"}
                                                    value={duration}
                                                    onChange={value => {
                                                        setDuration(value);
                                                    }}
                                                    allowNoValue={bundleDurations.length > 1}
                                                    isDisabled={bundleDurations.length === 1}
                                                />
                                                {errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                            </>}
                                    </Validator>
                                </div>
                            </div>}
                        {bundle && !future &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Initial Balance
                                </label>
                                <Validator
                                    value={amount}
                                    validators={[]}
                                    errorMessages={[]}
                                    resetOnValid
                                >
                                    {({ errorMessage }) =>
                                        <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                                            <div className={appClasses.currency}>$</div>
                                            <input
                                                type={"number"}
                                                min={0}
                                                step={"0.1"}
                                                value={amount === undefined || amount < 0 ? "" : FormatUtil.truncateToDecimals(amount / 100, 2)}
                                                onChange={(event) => {
                                                    const parsed = parseFloat(event.target.value);
                                                    setAmount((isNaN(parsed) || parsed <= 0) ? undefined : parsed * 100);
                                                }}
                                                placeholder="0"
                                                className={appClasses.hidePlaceholderOnFocus}
                                            />
                                            {errorMessage && <div className={appClasses.validationError}>{errorMessage}</div>}
                                        </div>}
                                </Validator>
                            </div>}
                        {bundle &&
                            <div className={appClasses.formGroup}>
                                <label>
                                    Note
                                </label>
                                <div className={classNames(appClasses.value)} style={{ width: '100%' }}>
                                    <textarea
                                        className={genStyles.grow}
                                        style={{ alignSelf: 'stretch' }}
                                        value={note}
                                        onChange={event => {
                                            setNote(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>}
                    </div>
                </ValidatorForm>
                {(bundlePreview || bundle) &&
                    <div className={classNames(classes.details, classes.entry)}>
                        <div className={classes.field}>Details</div>
                        <div style={{ marginTop: '30px', width: '100%' }}>
                            <BundleTable bundle={bundlePreview ?? bundle} />
                        </div>
                        {bundle !== undefined && duration !== undefined && !bundlePreview &&
                            <div style={{ marginTop: '30px' }}>
                                <Table
                                    tableId={"assign-bundle"}
                                    items={[bundle.bundleConfig.find(config => config.subscriptionDurationInDays === duration)!]}
                                    contentSpec={[
                                        {
                                            id: "subscription_duration",
                                            name: i18n.t("Subscription.duration"),
                                            label: <div style={{ width: '190px' }}>{i18n.t("Subscription.duration")}</div>,
                                            cellValue: item => durationStringFromDays(item!.subscriptionDurationInDays),
                                        },
                                        {
                                            id: "subscription_fee",
                                            name: "Subscription fee",
                                            label: <span className={classes.tableHeader}>Subscription fee</span>,
                                            cellValue: item => FormatUtil.toMoney(item!.subscriptionFee, { nInCents: true })
                                        }
                                    ] as ITableColSpec<BundleConfig>[]}
                                    rowClass={""}
                                />
                            </div>}
                    </div>}
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    <button onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                if (valid) {
                                    onRequestClose({ user: user!, bundle: bundle!, duration: duration!, amount: amount !== undefined ? Math.floor(amount) : undefined, note });
                                    setWaiting(true);
                                }
                            })
                    }} className={appClasses.buttonAdd} disabled={!bundle}>
                        Assign
                    </button>
                </div>
            </div>
        </SideView >
    );
};

const AssignBundleViewStyled = withStyles(AssignBundleView, assignBundleViewJss);

const AssignBundleViewData = withAsycnData(AssignBundleViewStyled,
    (query: { userId: string }) => UsersData.instance.get(query.userId)
        .then((user: User | undefined) => ({ user }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ user?: User, error?: Error }>);

// noinspection JSUnusedLocalSymbols
export const ASSIGN_BUNDLE_VIEW: IViewRouteConfig<{ userId: string, future?: boolean }> =
{
    path: PART_DETAIL_VIEW_PATH.reduce((acc: string[], path: string) => {
        return acc.concat([
            path + "/assignBundle/:future",
            path + "/assignBundle"
        ]);
    }, []),
    propsFromMatch: (match: any) => {
        return ({ userId: match.params.id, future: !!match.params.future });
    },
    propsToPath: props => `/assignBundle${props.future ? "/future" : ""}`,
    navLabel: ({ viewProps }) => `Assign ${viewProps.future ? "Future" : ""} ${i18n.t("Bundle")}`,
    render: ({ viewProps, navHistory }) => {
        return (
            <AssignBundleViewData
                userId={viewProps.userId}
                onRequestClose={updateData => {
                    if (updateData) {
                        const { user, bundle, duration, amount, note } = updateData;
                        UsersData.instance.assignBundle({
                            userID: viewProps.userId,
                            bundleID: bundle.id + "D" + duration,
                            clientID: user.clientId!,
                            future: viewProps.future,
                            amount,
                            note,
                            freshStart: !viewProps.future ? true : undefined
                        })
                            .then(() => {
                                UsersData.instance.invalidateUserCache(viewProps.userId);
                                TransactionsData.instance.invalidateTransCache();
                            })
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            })
                    } else {
                        navHistory.pop();
                    }
                }}
                renderWhenData={true}
                future={viewProps.future}
            />
        );
    },
    isModal: true
};