import { gql } from "graphql-tag";
import Provider, { MobilityOption, PROVIDER_CLIENT_ID_FIELD, PROVIDER_CONFIG_FIELD, PROVIDER_COVERAGE_FIELD, PROVIDER_ID_FIELD, PROVIDER_INFO_FIELD, PROVIDER_MOBILITY_OPTION_CODE_CATEGORY_FIELD, PROVIDER_MOBILITY_OPTION_CODE_NUMBER_FIELD, PROVIDER_MOBILITY_OPTION_ID_FIELD, PROVIDER_MOBILITY_OPTION_NAME_FIELD, PROVIDER_MOBILITY_OPTION_TRANSLATIONS_FIELD, PROVIDER_MOBILITY_OPTIONS_FIELD, PROVIDER_NAME_FIELD, PROVIDER_PURPOSE_ID_FIELD, PROVIDER_PURPOSE_NAME_FIELD, PROVIDER_PURPOSE_TRANSLATIONS_FIELD, PROVIDER_PURPOSES_FIELD, Purpose } from "../model/Provider";
import NetworkUtil from "../util/NetworkUtil";
import Filter, { SortOrder } from "./Filter";
import Util from "../util/Util";
import ItemsData from "./ItemsData";
import AppSync from "./AppSync";
import GQLError from "../util/GQLError";
import { useEffect, useMemo, useState } from "react";
import { StatusSelectOption } from "../rewards/StatusSelect";
import AdminProfile from "../account/AdminProfile";

const PURPOSE_SCHEMA_BUILDER = () => `
{
    ${PROVIDER_PURPOSE_ID_FIELD}
    ${PROVIDER_PURPOSE_NAME_FIELD}
    ${PROVIDER_PURPOSE_TRANSLATIONS_FIELD}
}
`;

const MOBILITY_OPTION_SCHEMA_BUILDER = () => `
{
    ${PROVIDER_MOBILITY_OPTION_ID_FIELD}
    ${PROVIDER_MOBILITY_OPTION_NAME_FIELD}
    ${PROVIDER_MOBILITY_OPTION_TRANSLATIONS_FIELD}
    ${PROVIDER_MOBILITY_OPTION_CODE_NUMBER_FIELD}
    ${PROVIDER_MOBILITY_OPTION_CODE_CATEGORY_FIELD}
}
`;

// Should not disable PROVIDER_CONFIG_FIELD, PROVIDER_COVERAGE_FIELD, and PROVIDER_INFO_FIELD with providerSettings22829 flag
// since it's needed for dynamic modes.
export const PROVIDER_SCHEMA_BUILDER = () => `
{
    ${PROVIDER_ID_FIELD}
    ${PROVIDER_NAME_FIELD}
    ${PROVIDER_CLIENT_ID_FIELD}
    ${PROVIDER_CONFIG_FIELD}
    ${PROVIDER_COVERAGE_FIELD}
    ${PROVIDER_INFO_FIELD}
    ${PROVIDER_PURPOSES_FIELD} ${PURPOSE_SCHEMA_BUILDER()}
    ${PROVIDER_MOBILITY_OPTIONS_FIELD} ${MOBILITY_OPTION_SCHEMA_BUILDER()}
}
`;

export const GET_PROVIDER_QUERY = "getProvider";
export const getProvider = (id: string) => gql`
query getProvider {
    ${GET_PROVIDER_QUERY} (${NetworkUtil.getGQLParamsFromQuery({ Id: id })})    
        ${PROVIDER_SCHEMA_BUILDER()}
}
`;

export interface UpdateProviderInput {
    id: string;
    clientId?: string;
    // name?: string;    
    // notifyTo?: string;
    purposes?: string[];
    mobilityOptions?: string[];
}

export const UPDATE_PROVIDER_QUERY = "updateProvider";
export const updateProvider = (input: UpdateProviderInput) => {
    return gql`
    mutation updateProvider {
        ${UPDATE_PROVIDER_QUERY} 
        (
            input: ${Util.graphqlStringify(input)}            
        ) ${PROVIDER_SCHEMA_BUILDER()}
    }
`
};

export interface IListProvidersQuery {
    search?: string;
    limit?: number;
    clientId?: string;
    sortAsc?: boolean;
    nextToken?: string;
}

export const LIST_PROVIDERS_QUERY = "listProviders";
export const listProviders = (query: IListProvidersQuery) => {
    return gql`
        query listProviders {
            ${LIST_PROVIDERS_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
                items ${PROVIDER_SCHEMA_BUILDER()}           
                nextToken
        }
    }
    `
};

function queryFromFilter(filter: Filter, limit: number, nextToken?: string): any {
    const queryParams: IListProvidersQuery = {
        search: filter.search?.label,
        clientId: filter.clientId,
        sortAsc: filter.sortOrder && filter.sortOrder === SortOrder.ASC,
        limit: limit,
        nextToken: nextToken
    };
    return listProviders(queryParams);
}

function processResults({ data, errors }) {
    if (!data?.[LIST_PROVIDERS_QUERY]) {
        return { error: errors?.[0]?.message ?? "This data is currently unavailable" };
    }
    const queryResult = data[LIST_PROVIDERS_QUERY];
    const items = queryResult.items
        .map((itemJson: any) => Util.deserialize(itemJson, Provider));
    return { items, nextToken: queryResult.nextToken }
}

export function getProvidersP({ clientId, adminProfile }: { clientId?: string, adminProfile?: AdminProfile } = {}): Promise<Provider[]> {
    if (adminProfile?.isTSPUser) {
        return Promise.resolve(adminProfile.remote.userData.providers);
    }
    return ProvidersData.instance.getAll(clientId)
        .then(providers => providers.sort((a, b) => a.name.localeCompare(b.name)));
}

export function useProviders({ clientId, adminProfile, undefineOnUpdate }: { clientId?: string, adminProfile?: AdminProfile, undefineOnUpdate?: boolean } = {}): Provider[] | undefined {
    const [providers, setProviders] = useState<Provider[] | undefined>();
    useEffect(() => {
        if (undefineOnUpdate) {
            setProviders(undefined);
        }
        getProvidersP({ clientId, adminProfile })
            .then(providers => {
                setProviders(providers);
            })
    }, [clientId])
    return providers;
}

export function useProvider(id: string): Provider | undefined {
    const providers = useProviders();
    return useMemo(() => providers?.find(provider => provider.id === id), [id, providers]);
}

export function useProviderOptions(props: { clientId?: string, adminProfile?: AdminProfile, undefineOnUpdate?: boolean, modeLabel?: boolean }): (StatusSelectOption<string> & { provider: Provider })[] | undefined {
    return useProviders(props)?.map((provider: Provider) => ({ value: provider.id, label: props.modeLabel ? provider.modeName : provider.name, provider }));
}

const LIST_MOBILITY_OPTIONS_QUERY = "listMobilityOptions";

export function getAllMobilityOptionsP(): Promise<MobilityOption[]> {
    return ProvidersData.instance.listMobilityOptions({ limit: 1000 })
        .then(providers => providers.sort((a, b) => a.name.localeCompare(b.name)));
}

export function useMobilityOptions(): MobilityOption[] | undefined {
    const [mobilityOptions, setMobilityOptions] = useState<MobilityOption[] | undefined>();
    useEffect(() => {
        getAllMobilityOptionsP()
            .then(options => {
                setMobilityOptions(options);
            })
    }, [])
    return mobilityOptions;
}

export function useMobilityOptionsForSelect(): (StatusSelectOption<string> & { option: MobilityOption })[] | undefined {
    return useMobilityOptions()?.map((option: MobilityOption) => ({ value: option.id, label: option.name, option }));
}

const LIST_PURPOSES_QUERY = "listPurposes";

export function getAllPurposesP(): Promise<Purpose[]> {
    return ProvidersData.instance.listPurposes({ limit: 1000 })
        .then(providers => providers.sort((a, b) => a.name.localeCompare(b.name)));
}

export function usePurposes(): Purpose[] | undefined {
    const [purposes, setPurposes] = useState<Purpose[] | undefined>();
    useEffect(() => {
        getAllPurposesP()
            .then(purposes => {
                setPurposes(purposes);
            })
    }, [])
    return purposes;
}

export function usePurposesForSelect(): (StatusSelectOption<string> & { purpose: Purpose })[] | undefined {
    return usePurposes()?.map((purpose: Purpose) => ({ value: purpose.id, label: purpose.name, purpose }));
}

class ProvidersData extends ItemsData<Provider> {
    private static _instance: ProvidersData;
    public static get instance(): ProvidersData {
        if (!this._instance) {
            this._instance = new ProvidersData(queryFromFilter, processResults);
        }
        return this._instance;
    }

    public invalidateCache() {
        const cache = AppSync.getClient().cache;
        Object.keys(cache.data.data).forEach(key => {
            key.includes(LIST_PROVIDERS_QUERY) && cache.data.delete(key);
        }
        );
    }

    public getAll(clientId?: string): Promise<Provider[]> {
        // if (process.env.NODE_ENV === 'development') {
        //     const data = require('../mock/data/listProviders.json').data;
        //     const queryResult = data?.[LIST_PROVIDERS_QUERY];
        //     return Promise.resolve((queryResult?.items ?? [])   // TODO: remove ?? [], just to avoid exception while endpoint returns null.
        //         .map((itemJson: any) => Util.deserialize(itemJson, Provider))
        //         .filter((provider: Provider) => !clientId || provider.clientId === clientId));
        // }
        return AppSync.query({
            query: listProviders({ limit: 500, clientId })
        })
            .then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                const queryResult = data?.[LIST_PROVIDERS_QUERY];
                return (queryResult?.items ?? [])   // TODO: remove ?? [], just to avoid exception while endpoint returns null.
                    .map((itemJson: any) => Util.deserialize(itemJson, Provider));
            });
    }

    public get(id: string): Promise<Provider> {
        return AppSync.query({
            query: getProvider(id)
        }).then(({ data }) => {
            const providerJSON = data[GET_PROVIDER_QUERY];
            if (!providerJSON) {
                throw new GQLError('Provider not found for id: ' + id, undefined, true);
            }
            return Util.deserialize(providerJSON, Provider);
        });
    }

    // public create(provider: Provider, otherInput?: OtherCreateInput): Promise<Provider | undefined> {
    //     return AppSync.mutate({
    //         mutation: createProvider(provider, otherInput)
    //     }).then(NetworkUtil.rejectOnGQLError)
    //         .then((data: any) => {
    //             const resultJson = data.data[CREATE_PROVIDER_QUERY];
    //             return resultJson && Util.deserialize(Util.undefineNulls(resultJson, true), Provider);
    //         });
    // }

    public update(input: UpdateProviderInput): Promise<void> {
        return AppSync.mutate({
            mutation: updateProvider(input)
        }).then(NetworkUtil.rejectOnGQLError);
    }

    // public delete(id: string): Promise<void> {
    //     return AppSync.mutate({
    //         mutation: deleteProvider(id)
    //     });
    // }

    public listMobilityOptions(query: { limit?: number, sortAsc?: boolean, nextToken?: string }): Promise<MobilityOption[]> {
        return AppSync.query({
            query: gql`
            query getMobilityOptions {
                ${LIST_MOBILITY_OPTIONS_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
                    items ${MOBILITY_OPTION_SCHEMA_BUILDER()}           
                    nextToken
            }
            }
            `
        }).then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                return Util.jsonConvert().deserializeArray(data?.[LIST_MOBILITY_OPTIONS_QUERY]?.items, MobilityOption);
            });
    }

    public listPurposes(query: { limit?: number, sortAsc?: boolean, nextToken?: string }): Promise<Purpose[]> {
        return AppSync.query({
            query: gql`
            query getPurposes {
                ${LIST_PURPOSES_QUERY} (${NetworkUtil.getGQLParamsFromQuery(query)}) {
                    items ${PURPOSE_SCHEMA_BUILDER()}           
                    nextToken
            }
            }
            `
        }).then(NetworkUtil.rejectOnGQLError)
            .then(({ data }) => {
                return Util.jsonConvert().deserializeArray(data?.[LIST_PURPOSES_QUERY]?.items, Purpose);
            });
    }
}

export const getProviderCacheRedirect = (_, args, { getCacheKey }) => {
    return getCacheKey({ __typename: 'Provider', ...args });
};

export default ProvidersData;