import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const editOrgViewJss = (theme: Theme) => ({
    narrowForm: {
        maxWidth: '600px',
        '& label': {
            width: '250px'
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.colorPrimary
        },
        ...genJss.grow
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },
    orgSelectValue: {
        ...genJss.grow,
        '& > *': {
            ...genJss.grow,
            margin: '-3px'
        }
    },
    selectValue: {
        ...genJss.grow,
        '& > div': {
            ...genJss.grow,
            width: 'initial'
        }
    }
});