import React from "react";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { LinkAdminUserToOrgInput } from "../data/AdminUsersData";
import ModalView from "../view/ModalView";
import { isRootOrg } from "./OrgSelectorHelpers";
import { ValidatorForm } from "react-form-validator-core";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { Theme } from "../css/Theme";

export interface IProps extends WithClasses<IStyle> {
    title?: string;
    imageUrl: string;
    onRequestClose: (input?: LinkAdminUserToOrgInput) => void;
}

export const statusDefinitionsViewJss = (theme: Theme) => ({
    main: {
        flexGrow: '1',
        display: 'flex',
        background: '#eff2f1',
        margin: '5px'
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        marginTop: 'auto',
        marginBottom: 'auto'
    },
    modal: {
        width: '90%'
    },
    header: {}
});

type IStyle = ReturnType<typeof statusDefinitionsViewJss>;

ValidatorForm.addValidationRule('cantAddAllWhenSpecific', ({ selectedOrg, adminOrgs }) => {
    if (isRootOrg(selectedOrg) && adminOrgs.length > 0) {
        return false;
    }
    return true;
});

ValidatorForm.addValidationRule('cantAddSpecificWhenAll', ({ selectedOrg, adminOrgs }) => {
    if (!isRootOrg(selectedOrg) && adminOrgs.some(o => isRootOrg(o))) {
        return false;
    }
    return true;
});

const StatusDefinitionsView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { title, imageUrl, onRequestClose, classes, injectedStyles, appClasses } = props;
    return (
        <ModalView
            title={title}
            onRequestClose={() => onRequestClose()}
            styles={(_theme, defaultStyle) => ({
                modal: {
                    ...defaultStyle.modal,
                    ...injectedStyles.modal as any
                },
                header: {
                    ...defaultStyle.header,
                    ...injectedStyles.header as any
                },
                body: {
                    flexGrow: 1,
                    overflowY: 'auto',
                    display: 'flex'
                }
            })}
        >
            <div className={classes.main}>
                <img src={imageUrl} className={classes.image} />
            </div>
        </ModalView>
    );
}

const StatusDefinitionsViewStyled = withStylesO(StatusDefinitionsView, statusDefinitionsViewJss);

export const STATUS_DEFINITIONS_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/statusDefinitions"],
    propsFromMatch: () => ({}),
    propsToPath: () => "/statusDefinitions",
    navLabel: () => "Trip Status Definitions",
    render: ({ navHistory }) => {
        return (
            <StatusDefinitionsViewStyled
                title="Trip Status Definitions"
                imageUrl="/images/status-definitions.jpg"
                onRequestClose={() => {
                    navHistory.pop();
                }}
            />
        );
    },
    isModal: true
};

export const CANCELLATION_POLICY_VIEW: IViewRouteConfig<{}> =
{
    path: ["*/cancellationPolicy"],
    propsFromMatch: () => ({}),
    propsToPath: () => "/cancellationPolicy",
    navLabel: () => "Cancellation Policy",
    render: ({ navHistory }) => {
        return (
            <StatusDefinitionsViewStyled
                imageUrl="/images/cancellation-policy.jpg"
                onRequestClose={() => {
                    navHistory.pop();
                }}
                styles={(_theme, defaultStyle) => ({
                    main: {
                        ...defaultStyle.main,
                        background: '#ffffff'
                    },
                    modal: {
                        ...defaultStyle.modal,
                        width: '95%',
                        inset: '10px 40px 10px 50%'
                    },
                    header: {
                        ...defaultStyle.header,
                        position: 'absolute',
                        right: 0,
                        borderBottom: 'none'
                    }
                })}
            />
        );
    },
    isModal: true
};