import React, { FunctionComponent } from "react";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import Bundle from "../model/Bundle";
import FormatUtil from "../util/FormatUtil";
import { ReactComponent as SpinBar } from "../images/spin-bar.svg";
import { userViewJss } from "./UserView.jss";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { Balance } from "../model/User";
import Tooltip from "../uiutil/Tooltip";
import { ITableColSpec, default as Table } from "../view/Table";
import { adminProfile } from "../account/AdminProfile";
import DateTimeUtil from "../util/DateTimeUtil";
import { BUNDLE_DURATION_TOOLTIP, durationStringFromDays } from "../bundle/EditBundleView";
import { i18n } from "../i18n/TKI18nConstants";
import { ReactComponent as ShowIcon } from "../images/ic-external-link.svg";
import { appPathUpdate } from "../app/App";

type IStyle = ReturnType<typeof userViewJss>;

interface IProps extends WithClasses<IStyle> {
    bundle?: Bundle;
    balance?: Balance;
}

export const MONEY_BALANCE_TOOLTIP = "Sums up money flow in the system for the user, where, for instance, " +
    "subscription payments and bookings costs impact against this balance.";
export const REWARDS_BALANCE_TOOLTIP = "Registers points currently accumulated by the user, where, for instance, " +
    "making a booking may sum points, that can then be consumed (redeemed) to get rewards.";
export const AVAILABLE_REWARDS_TOOLTIP = "As rewards balance, but subtracts points of redemptions that are pending for approval.";
export const LIFETIME_REWARDS_TOOLTIP = "The total of reward points earned by the user since she / he joined the service.";

const BalanceTable: FunctionComponent<IProps> = (props: IProps) => {
    const { classes, appClasses, bundle, balance } = props;
    const startTime = bundle && bundle.appliedMoment;
    const expiryTime = bundle && bundle.expirationMoment;
    const subscriptionDurationInDays = bundle?.bundleConfig?.[0]?.subscriptionDurationInDays;
    const spinner: JSX.Element = <SpinBar className={classes.spinBar} />;
    const rawBalance = balance && balance.rawBalance !== undefined &&
        FormatUtil.toMoney(balance.rawBalance, { nInCents: true });
    const rewardsBalance = balance && balance.rewardsBalance !== undefined && FormatUtil.toPoints(balance.rewardsBalance);
    const availableRewardsBalance = balance && balance.availableRewardsBalance !== undefined && FormatUtil.toPoints(balance.availableRewardsBalance);
    const lifetimeRewardsBalance = balance && balance.lifetimeRewardsBalance !== undefined && FormatUtil.toPoints(balance.lifetimeRewardsBalance);

    const tableContent: ITableColSpec<unknown>[] = [
        {
            id: "name",
            name: "Name",
            cellValue: () => (
                <div className={classNames(classes.bundleName, balance === null && classes.iconError)}>
                    {!bundle ? i18n.t("No.associated.bundle") :
                        <a
                            className={appClasses.detailLinkIconOnHover}
                            href={`#${appPathUpdate({ path: `/bundles/id/${bundle.id}` })}`}
                        >
                            {bundle.name}
                            <ShowIcon />
                        </a>}
                </div>
            ),
            visible: adminProfile.features.bundles
        },
        {
            id: "subscription_duration",
            name: i18n.t("Subscription.duration"),
            label: (
                <Tooltip title={BUNDLE_DURATION_TOOLTIP}>
                    <div className={appClasses.cursorHelp}>
                        Subscription <br /> Duration
                    </div>
                </Tooltip>
            ),
            cellValue: () => (
                <div className={classes.bundleName}>
                    {subscriptionDurationInDays && durationStringFromDays(subscriptionDurationInDays)}
                </div>
            ),
            visible: adminProfile.features.bundles
        },
        {
            id: "start",
            name: "Start",
            cellValue: () =>
                <div className={classes.bundleDate}>
                    {startTime && startTime.format(DateTimeUtil.dayMonthFormat())}
                </div>,
            visible: adminProfile.features.bundles
        },
        {
            id: "expiry",
            name: "Expiry",
            cellValue: () =>
                <div className={classes.bundleDate}>
                    {expiryTime && expiryTime.format(DateTimeUtil.dayMonthFormat())}
                </div>,
            visible: adminProfile.features.bundles
        },
        {
            id: "money_balance",
            name: i18n.t("Money.balance"),
            label: (
                <React.Fragment>
                    <Tooltip title={MONEY_BALANCE_TOOLTIP}>
                        {i18n.t("Money.balance")}
                    </Tooltip>
                    {/* This is for UQ, parameterize to re-enable just for UQ if still necessary */}
                    {false && <span style={{ fontWeight: 'normal' }}>(estimate)</span>}
                </React.Fragment>
            ),
            cellValue: () => (
                <span className={classNames(genStyles.flex, genStyles.alignCenter)}>
                    <span className={classNames(classes.balance, balance === null && classes.iconError)}>
                        {rawBalance}
                    </span>
                    {bundle === undefined ? "-" : balance === undefined && spinner}
                </span>
            )
        },
        {
            id: "rewards_balance",
            name: "Rewards Balance",
            label: (
                <Tooltip title={REWARDS_BALANCE_TOOLTIP}>
                    Rewards Balance
                </Tooltip>
            ),
            cellValue: () => (
                <span className={classNames(genStyles.flex, genStyles.alignCenter)}>
                    <span className={classNames(classes.balance, balance === null && classes.iconError)}>
                        {rewardsBalance}
                    </span>
                    {bundle === undefined ? "-" : balance === undefined && spinner}
                </span>
            ),
            visible: adminProfile.features.rewards
        },
        {
            id: "available_rewards",
            name: "Available Rewards",
            label: (
                <Tooltip title={AVAILABLE_REWARDS_TOOLTIP}>
                    Available Rewards
                </Tooltip>
            ),
            cellValue: () => (
                <span className={classNames(genStyles.flex, genStyles.alignCenter)}>
                    <span className={classNames(classes.balance, balance === null && classes.iconError)}>
                        {availableRewardsBalance}
                    </span>
                    {bundle === undefined ? "-" : balance === undefined && spinner}
                </span>
            ),
            visible: adminProfile.features.rewards
        },
        {
            id: "lifetime_rewards",
            name: "Lifetime Rewards",
            label: (
                <Tooltip title={LIFETIME_REWARDS_TOOLTIP}>
                    Lifetime Rewards
                </Tooltip>
            ),
            cellValue: () => (
                <span className={classNames(genStyles.flex, genStyles.alignCenter)}>
                    <span className={classNames(classes.balance, balance === null && classes.iconError)}>
                        {lifetimeRewardsBalance}
                    </span>
                    {bundle === undefined ? "-" : balance === undefined && spinner}
                </span>
            ),
            visible: adminProfile.features.rewards
        },
    ];

    return (
        <Table
            tableId={"balance"}
            contentSpec={tableContent}
            items={[{}]}
            rowClass={""}
            configurable={false}
        />
    );
}

export default withStyles(BalanceTable, userViewJss);
