import React, { useContext } from 'react';
import { WithClasses, withStyles } from "../css/StyleHelper";
import { profileViewJss } from "./ProfileView.jss";
import ModalView from "../view/ModalView";
import { AppContext } from "../app/App";
import classNames from 'classnames';
import Util from "../util/Util";
import { CheckboxStyled } from "../booking/BookingsView";
import Tooltip from "../uiutil/Tooltip";
import Environment from '../env/Environment';

type IStyle = ReturnType<typeof profileViewJss>;

interface IProps extends WithClasses<IStyle> {
    onRequestClose?: () => void;
}

const POLL_REFRESH_TOOLTIP = "If enabled the dashboard will poll for booking list updates each 10 seconds.";

const DAYS_TO_ADD_TOOLTIP = "Days to be added to the currant date for getting bookings in 'Charge' view (through gql query 'getExpiringPaymentsBookings'). Useful to test expiration warnings.";

const ProfileView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { profile, onProfileChange, logout } = useContext(AppContext);
    const classes = props.classes;
    const appClasses = props.appClasses;
    return (
        <ModalView
            title={"Profile"}
            onRequestClose={() => props.onRequestClose && props.onRequestClose()}
            styles={{
                modal: {
                    background: 'none',
                    border: 'none',
                    padding: '5px',
                    transform: 'translate(-50%, 0)',
                    top: '25%',
                    left: '50%',
                    width: '480px',
                    height: '25%',
                    minHeight: '250px'
                }
            }}
        >
            <div className={classes.main}>
                <div className={classNames(appClasses.form, classes.form)}>
                    {false &&
                        <div className={appClasses.formGroup}>
                            <Tooltip title={POLL_REFRESH_TOOLTIP}>
                                <label className={appClasses.cursorHelp}>Poll refresh bookings</label>
                            </Tooltip>
                            <CheckboxStyled
                                checked={profile.pollRefreshBookings}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    const profileUpdate = Util.clone(profile);
                                    profileUpdate.pollRefreshBookings = checked;
                                    onProfileChange(profileUpdate);
                                }}
                            />
                        </div>}
                    {Environment.isBeta() &&
                        <div className={appClasses.formGroup}>
                            <Tooltip title={DAYS_TO_ADD_TOOLTIP}>
                                <label className={appClasses.cursorHelp}>Days to add</label>
                            </Tooltip>
                            <input
                                type={"number"}
                                step={1}
                                value={profile.local.daysToAdd ?? ""}
                                onChange={(event) => {
                                    const update = Util.deepClone(profile);
                                    let parsed: number | undefined = parseInt(event.target.value);
                                    if (isNaN(parsed)) {
                                        parsed = undefined;
                                    }
                                    update.local.daysToAdd = parsed;
                                    onProfileChange(update);
                                }}
                                key="1"
                                style={{ width: '50px', flexGrow: 0 }}
                            />
                        </div>}
                </div>
                <button className={classNames(appClasses.buttonCancel, classes.logout)} onClick={logout}>
                    Logout
                </button>
            </div>
        </ModalView>
    )
};

export default withStyles(ProfileView, profileViewJss)