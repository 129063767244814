import {Theme} from "../css/Theme";
import {genJss} from "../css/gen.jss";

export const editRewardViewJss = (theme: Theme) => ({
    main: {

    },
    imageLabel: {
        textAlign: 'left!important',
        marginBottom: '13px'
    },
    dnDImage: {
        ...genJss.flex,
        width: '100%',
        height: '400px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        marginTop: '20px',
        '&>*:not(:last-child)': {
            marginRight: '20px'
        }
    },
    durationInput: {
        textAlign: 'right',
        '&::placeholder': {
            textAlign: 'left',
        }
    }
});