import React, { useContext, useRef } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { ticketsViewJss } from "./TicketsView.jss";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import ViewRestyled from "../view/ViewRestyled";
import classNames from "classnames";
import { AppContext, getClientIDPath, getOrgIDPath } from "../app/App";
import FormatUtil from "../util/FormatUtil";
import { Client } from "../model/Client";
import Filter, { SortOrder } from "../data/Filter";
import Transaction, { paymentMethodValues } from "../model/Transaction";
import { ExportFormat } from "../export/ExportButton";
import ItemsResult from "../model/ItemsResult";
import Util from "../util/Util";
import Loading from "../view/Loading";
import TransactionsData from "../data/TransactionsData";
import genStyles from "../css/general.module.css";
import Paginate from "../paginate/Paginate";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { Observable, from } from 'rxjs';
import { concatAll, map } from 'rxjs/operators';
import { adminProfile } from "../account/AdminProfile";
import PageSizeSelect from "../paginate/PageSizeSelect";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import Ticket from "../model/Ticket";
import TicketsData from "../data/TicketsData";
import TicketsTable from "./TicketsTable";
import DateRangePicker from "../time/DateRangePicker";
import { DefaultSelect } from "../rewards/StatusSelect";
import * as moment from 'moment-timezone';
import DateTimeUtil from "../util/DateTimeUtil";

export interface TicketsViewProps {
    data?: ItemsResult<Ticket>;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    onSelect?: (value: Ticket) => void;
    onExportData?: (filter: Filter, format: ExportFormat) => Promise<void>;
    onRefreshClick?: () => void;
    selectedClientID?: string;
    clients?: Client[];
    showFilters?: boolean;
    showDateRangePicker?: boolean;
    asView?: boolean;
}

export interface IProps extends TicketsViewProps, WithClasses<IStyle> { }

type IStyle = ReturnType<typeof ticketsViewJss>;

const TicketsView: React.FunctionComponent<IProps> = (props: IProps) => {
    // This value persists page changes, but should be reset on other filter changes.
    // Probably need to move to state.
    const pageCount = useRef<number>();

    const { data, appClasses, classes, filter, showFilters = true, showDateRangePicker = true, onSelect, onFilterChange, asView = true } = props;
    const { profile: adminProfile, clients } = useContext(AppContext);

    function handlePageClick(selectedItem: { selected: number }) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter, { page: selectedItem.selected + 1 });
            props.onFilterChange(filterUpdate);
        }
    }

    function handleDateRangeChange(range: Range) {
        if (props.onFilterChange) {
            const filterUpdate = Util.iAssign(props.filter,
                {
                    range: range,
                    page: 1
                }
            );
            props.onFilterChange(filterUpdate)
        }
    }

    if (!filter) {  // Comment since it shouldn't happen if called from WithBookingsData
        return null;
    }
    const page = filter && filter.page ? filter.page : 1;
    if (data) {
        pageCount.current = data.pageCount + (data.more ? 1 : 0);
    }

    let transactions;
    if (!data) {
        transactions = <Loading />;
    } else if (data.count === 0) {
        transactions =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        transactions =
            <TicketsTable
                tableId={adminProfile.app + "-" + ticketsViewId}
                values={data.items}
                filter={filter}
                onFilterChange={onFilterChange}
                onSelect={onSelect}
            />
    }
    const paymentMethodOptions = paymentMethodValues
        .map(status => ({ value: status, label: FormatUtil.upperCaseToSpaced(status) }))
        .sort((el1: any, el2: any) => el1.label.localeCompare(el2.label));
    const content =
        <div
            className={classNames(genStyles.flex, genStyles.column, genStyles.grow)}
            style={{ overflow: 'auto' }}
        >
            <div className={classes.transHeader}>
                {showFilters &&
                    <div className={classes.filterContainer}>
                        <div className={classes.filters}>
                            <div className={classes.filterSelect}>
                                <DefaultSelect
                                    options={paymentMethodOptions}
                                    value={filter.paymentMethod}
                                    onChange={(value) => {
                                        const newFilter = Util.iAssign(filter, { paymentMethod: value, page: 1 });
                                        props.onFilterChange && props.onFilterChange(newFilter);
                                    }}
                                    forFilter={true}
                                    allOptionsLabel="All payment methods"
                                    isSearchable />
                            </div>
                        </div>
                        {showDateRangePicker &&
                            <DateRangePicker
                                value={filter.range}
                                onChange={handleDateRangeChange} />}
                    </div>}
                <div className={classes.subFilterContainer}>
                    {/* {props.onExportData &&
    <ExportButton filter={props.filter} onExportData={props.onExportData} helpTooltip={EXPORT_TRANSACTIONS_TOOLTIP(adminProfile.appMetadata?.export?.formats)} formats={adminProfile.appMetadata?.export?.formats} />
} */}
                    <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)}>
                        <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                            <label className={genStyles.charSpace}>Page size</label>
                            <PageSizeSelect
                                value={filter.pageSize}
                                onChange={(pageSize: number) => props.onFilterChange &&
                                    props.onFilterChange(Util.iAssign(filter, { pageSize, page: 1 }))} />
                        </div>
                        {props.onRefreshClick &&
                            <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                                onClick={props.onRefreshClick}
                            >
                                <IconSpin />
                            </button>}
                    </div>
                </div>
            </div>
            <div className={classNames(classes.transContainer, !!pageCount.current && (pageCount.current > 1) && classes.minHeight)}>
                {transactions}
                {data && data.waitingMore &&
                    <div className={classes.waitingMore}><Loading /></div>}
            </div>
            <div className={classNames(appClasses.paginatePanel, classes.paginatePanel)}>
                {!!pageCount.current &&
                    <Paginate
                        pageCount={pageCount.current}
                        onPageChange={handlePageClick}
                        page={page} />}
            </div>
        </div>;
    return (!asView ? content :
        <ViewRestyled
            title={"Tickets"}
            className={classes.view}
        >
            {content}
        </ViewRestyled >
    );
}

const TicketsViewStyled = withStyles(TicketsView, ticketsViewJss);

export const TicketsViewWithData = withAsyncDataObs(TicketsViewStyled,
    (query: { filter: Filter }) =>
        /**
         * Do this to chain the query after orgsP promise is resolved, since need orgs to calculate query filter.
         * The argument of `from` is a promise of an observable (Promise<Observable<...>>), so
         * `from` converts it to Observable<Observable>, and .pipe(concatAll()) turns it into a single Observable.
         * All this won't be necessary if orgs come with the admin profile at the login.
        */
        from((adminProfile.features.organizations ? adminProfile.orgsP : Promise.resolve() as any).then(() =>  // Check if we need organizations for tickets.
            TicketsData.instance.watchQuery(query.filter)
                .pipe(map((result?: ItemsResult<Ticket>) => ({ data: result }))) as Observable<{ data?: ItemsResult<Ticket> }>))
            .pipe(concatAll() as any) as any
);

const ticketsViewId = "TICKETS_VIEW";

export const TICKETS_VIEW_PATH = ["*/tickets/filter/:filter", "*/tickets"];

export const TICKETS_VIEW: IViewRouteConfig<{ filter?: Filter }> =
{
    path: TICKETS_VIEW_PATH,
    propsToPath: ({ filter }) => "/tickets" +
        (filter ? "/filter/" + JSON.stringify(Util.serialize(filter)) : ""),
    propsFromMatch: (match, profile) => {
        const filter = Util.deserialize(match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            JSON.parse(match.params.filter) :
            {
                pageSize: profile.pageSizeByView.get(ticketsViewId) ?? 10,
                page: 1,
                range: moment.range(DateTimeUtil.getNowDate().add(-15, 'days'), DateTimeUtil.getNowDate()),
                sortOrder: SortOrder.DESC
            }, Filter);
        filter.clientId = getClientIDPath();
        filter.orgId = getOrgIDPath();
        return { filter: filter };
    },
    navLabel: () => "Money Transactions",
    render: ({ viewProps, navHistory, profile, onProfileChange, justData }) => {
        const filter = viewProps.filter!;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(ticketsViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace2(TICKETS_VIEW,
                Util.iAssign(viewProps, { filter: update }));
        };
        // Reset page number after a reload. 
        // ~~Avoid reset if just data, since it means other view is on top, and the filter update will break the url.~~ Implemented navHistory.replace2
        // which replace the view passed, preserving the url part of those on top. Also, when PART_DETAIL_VIEW_MODAL dialog is on top, then justData is false
        // since the view is still rendered (the dialog doesn't cover the view completly).        
        if (filter.page > 1 && TicketsData.instance.isEmpty() && !justData) { // !justData, or alternatively, navHistory.viewAt() === MONEY_TRANSACTIONS_VIEW            
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const onExport: (filter: Filter, format: ExportFormat) => Promise<void> = (filter, format) => {
            const csvFormat = adminProfile.appMetadata?.export?.moneyTrans?.csvFormat;
            const csvFields = csvFormat?.map(field => {
                if (typeof field === 'object' && field.trans) {
                    return { label: field.label ?? field.value, value: FormatUtil.csvTransFunction(field.value, field.trans) }
                }
                return field;
            })
            return TransactionsData.instance.getExportData(filter, { limit: 500 }).then((transactions: Transaction[]) => {
                const transactionsJSON = Util.serialize(transactions);
                switch (format) {
                    case "json": return FormatUtil.downloadObjectAsJson(transactionsJSON, "transactions");
                    case "csv": return FormatUtil.downloadObjectAsCSV(transactionsJSON,
                        {
                            exportName: "transactions",
                            fields: csvFields
                        });
                }

            });
        };
        const bookingsViewRef = React.createRef<any>();
        const onRefresh = () => {
            TicketsData.instance.invalidateCache();
            bookingsViewRef.current.refresh(true);
        };
        return (
            <TicketsViewWithData
                filter={filter}
                // onSelect={onSelect}
                onFilterChange={onFilterChange}
                onExportData={onExport}
                onRefreshClick={onRefresh}
                ref={bookingsViewRef}
                justData={justData}
            />
        );
    },
    renderDataIfMatch: true
};