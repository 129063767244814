import { Theme } from "../css/Theme";
import { appGlobalJss } from "../css/app.jss";
import { black, genJss, white } from "../css/gen.jss";
import { genStyles } from "tripkit-react";

// noinspection JSUnusedLocalSymbols
export const userViewJss = (theme: Theme) => ({

    iconEdit: {
        ...genJss.svgPathFillCurrColor,
        height: '16px',
        width: '16px',
        marginRight: '1ch'
    },

    '@global': {
        '.react-tabs__tab--selected': {
            borderClor: '#e2e2e2!important'
        },
        '.react-tabs__tab-panel--selected': {
            ...genJss.flex,
            ...genJss.grow
        }
    },

    bundles: {
        ...genJss.flex,
        ...genJss.column,
        '& table': {
            width: 'initial'
        }
    },

    bundleTable: {
        width: 'initial!important',
        margin: '20px 0',
        '& th:nth-child(1)': {
            width: '200px'
        },
        '& th:nth-child(2)': {
            width: '170px'
        },
        '& th:nth-child(3)': {
            width: '170px'
        }
    },

    bundleName: {
        ...genJss.flex,
        height: '19px',
        minWidth: '70px',
        maxWidth: '180px',
        color: '#02b5cc!important',
        '& a': {
            color: '#02b5cc!important'
        },
        '& svg path': {
            fill: '#02b5cc!important'
        }

    },

    bundleDate: {
        width: '100px'
    },

    balance: {
        height: '19px',
        minWidth: '51px',
        color: 'black!important'
    },

    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginBottom: '-10px'
    },

    buttonsRight: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& *': {
            marginLeft: '15px'
        }
    },

    spinBar: {
        height: '35px'
    },

    iconError: {
        fontSize: '25px'
    },

    fieldsGrid: {
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        gridRowGap: '50px',
        gridColumnGap: '20px',
        marginBottom: '50px',
        marginTop: '16px'
    },

    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },

    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },

    value: {
        ...theme.textColorGray
    },

    rightPanel: {
        ...genStyles.alignSelfEnd
    },

    picture: {
        width: '130px',
        height: '130px',
        borderRadius: '5px'
    },

    pictureInGrid: {
        width: '130px',
        height: '130px',
        borderRadius: '5px',
        gridColumn: 3
    },

    buttonAddMoney: {
        whiteSpace: 'nowrap',
        padding: '5px 16px',
        '& svg': {
            width: '28px',
            height: 'auto'
        }
    },

    openFullBtn: {
        marginLeft: '15px',
        '& svg': {
            height: '18px',
            width: '18px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },

    tabs: {
        '& .MuiTabs-root': {
            borderBottom: '1px solid ' + black(4)
        },
        '& .MuiTabs-flexContainer': {

        },
        '& .MuiTab-root': {
            textTransform: 'initial'
        },
        '& .Mui-selected': {
            color: theme.colorPrimary
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.colorPrimary + '!important'
        },
        marginBottom: '2px'
    },

    openBundleLink: {
        display: 'flex',
        color: '#02b5cc',
        '& svg': {
            marginLeft: '5px',
            height: '18px',
            width: '18px',
            '& path': {
                fill: 'currentColor'
            },
            visibility: 'hidden'
        },
        '&:hover svg': {
            visibility: 'visible'
        }
    },

    buttonActions: {
        ...appGlobalJss(theme).buttonAdd,
        ...genJss.alignSelfEnd,
        marginBottom: '20px',
        '& svg': {
            width: '10px',
            height: '10px',
            marginLeft: '10px',
            '& path': {
                fill: white()
            }
        }
    },

    actionIcon: {
        height: '22px',
        width: '22px',
        marginRight: '10px',
        '& path, polygon': {
            fill: theme.colorPrimary
        }
    },
    suspended: {
        color: theme.colorError,
        fontStyle: 'italic'
    },
    iconWhite: {
        '& path': {
            fill: white(),
            stroke: white()
        }
    }

});

export const transTypeOptionStyle = (theme: Theme, optionValue?: any) => ({
    ...theme.textSizeBody,
    ...optionValue && theme.textWeightSemibold,
    ...genJss.flex,
    ...genJss.borderRadius(12),
    padding: '0 8px'
});
