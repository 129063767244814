import React, { useEffect, useMemo, useRef, useState } from 'react';
import { WithClasses, withStyles } from "../css/StyleHelper";
import { black, genJss, resetJss } from "../css/gen.jss";
import { Theme } from "../css/Theme";
import { ReactComponent as IconTriangleDown } from '../images/ic-angle-down.svg';
import { ReactComponent as IconCity } from "../images/ic-city.svg";
import classNames from 'classnames';
import { Client } from '../model/Client';
import { i18n } from '../i18n/TKI18nConstants';

const orgSelectorJss = (theme: Theme) => ({
    wrapper: {
        ...genJss.flex,
        ...genJss.column,
        width: '100%',
        // transform: 'translateY(20px)'
    },
    popup: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 247px)',   // Hardcoded based on its position: 247px is button top (`offsettop`) + 63px of button height. Can calculate it dinamically using button `offsettop`.
        overflowY: 'auto',
        background: 'white',
        transform: 'translateY(63px)',
        borderRight: '1px solid ' + black(5),
        borderBottom: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards'
    },
    tree: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    treeItem: {
        ...resetJss.button,
        ...theme.textColorDefault,
        ...genJss.borderRadius(4),
        padding: '5px 0 5px 15px!important',
        margin: '1px 10px!important',
        '&:hover': {
            backgroundColor: 'rgba(33, 42, 51, 0.05)'
        }
    },
    popupOpen: {
        // '&$wrapper': {
        //     height: '100%'
        // }
    },
    main: {
        ...resetJss.button,
        width: '100%',
        padding: '10px 25px 25px 25px',
        marginBottom: '10px',
        ...genJss.flex,
        alignItems: props => !props.isBeta ? 'center' : undefined,
        textAlign: 'left',
        '&:disabled': {
            cursor: 'default',
            '& $angleDown': {
                display: 'none'
            }
        }
    },
    cursorDefault: {
        cursor: 'default'
    },
    open: {
        borderRight: '1px solid ' + black(5),
        boxSizing: 'border-box',
        animation: 'toWhiteBackground .5s ease 0s 1 normal forwards'

    },
    '@global': {
        '@keyframes toWhiteBackground': {
            '0%': { background: '#f6f7f7' },
            '100%': { background: 'white' }
        }
    },
    angleDown: {
        ...genJss.noShrink,
        // padding: '3px',
        // marginLeft: '3px',
        width: '9px',
        height: '9px'
    },
    up: {
        transform: 'rotate(180deg)'
    },
    icon: {
        height: '24px',
        width: '24px',
        ...genJss.noShrink,
        '& path': {
            fill: black(1)
        }
    },
    name: {
        ...genJss.grow,
        padding: '2px 16px',
        ...theme.textSizeBody,
        // whiteSpace: 'nowrap' // Reverted to allow text wrapping since organizations with long names get cut off.
    },
    treeIcon: {
        display: 'none!important'
    },
    treeLabel: {
        ...theme.textColorDefault,
        ...genJss.borderRadius(4),
        padding: '5px 0 5px 15px!important',
        margin: '1px 10px!important',
        '&:hover': {
            backgroundColor: 'rgba(33, 42, 51, 0.05)!important'
        }
    },
    selected: {
        '& > .MuiTreeItem-content > $treeLabel, > .MuiTreeItem-content > $treeLabel:hover': {
            backgroundColor: 'rgba(33, 42, 51, 0.12)!important'
        }
    },
    partialNode: {
        color: 'gray'
    }
});

export const clientSelectorJss = (theme: Theme) => {
    const orgSelectorJssWithTheme = orgSelectorJss(theme);
    return ({
        ...orgSelectorJssWithTheme,
        popup: {
            ...orgSelectorJssWithTheme.popup,
            height: 'calc(100% - 183px)'
        },
        option: {
            ...theme.textColorDefault,
            ...genJss.borderRadius(4),
            cursor: 'pointer',
            padding: '7px 15px',
            margin: '1px 10px',
            textDecoration: 'none',
            lineHeight: 1.5,
            '&:hover:not($optionSelected)': {
                backgroundColor: 'rgba(33, 42, 51, 0.05)'
            }
        },
        optionSelected: {
            backgroundColor: 'rgba(33, 42, 51, 0.12)'
        }
    });
};

type IStyle = ReturnType<typeof clientSelectorJss>

interface IProps extends WithClasses<IStyle> {
    values: Client[];
    value?: Client;
    onChange: (value?: Client) => void;
}

const ClientSelector: React.FunctionComponent<IProps> = ({ values, value, onChange, classes, injectedStyles }) => {

    const clientOptions = useMemo<{ value: Client | undefined, label: string }[]>(() => {
        let valueOptions = values.map(value => ({ value, label: value.clientName }));
        if (valueOptions.length !== 1) {
            valueOptions = [{ value: undefined as any, label: i18n.t("All.apps") }].concat(valueOptions);
        }
        return valueOptions;
    }, [values]);

    const valueOption = clientOptions.find(option => option.value === value) ?? clientOptions[0];

    const [showPopup, setShowPopup] = useState<boolean>(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const onClick = ({ target }: MouseEvent): void => {
            if (wrapperRef.current && !wrapperRef.current.contains(target as Node)) {
                setShowPopup(false);
            }
        };
        window.addEventListener('click', onClick);
        return () => window.removeEventListener('click', onClick);
    }, []);

    return (
        <div
            className={classNames(classes.wrapper, showPopup && classes.popupOpen)}
            ref={wrapperRef}
        >
            <button
                className={classNames(classes.main, showPopup && classes.open, values.length === 0 && classes.cursorDefault)}
                onClick={clientOptions.length > 1 ? (() => setShowPopup(!showPopup)) : undefined}
                disabled={clientOptions.length <= 1}
            >
                <IconCity className={classes.icon} />
                <div className={classes.name}>{valueOption.label}</div>
                {clientOptions.length > 1 &&
                    <IconTriangleDown className={classNames(classes.angleDown, showPopup && classes.up)} />}
            </button>
            {showPopup &&
                <div className={classes.popup}>
                    {clientOptions.map((option, i) => {
                        const isSelected = option === valueOption;
                        return (
                            <div
                                className={classNames(classes.option, isSelected && classes.optionSelected)}
                                onClick={() => {
                                    onChange(option.value);
                                    setShowPopup(false);
                                }}
                                key={i}
                            >
                                {option.label}
                            </div>
                        );
                    }
                    )}
                </div>}
        </div >
    );
};



export default withStyles(ClientSelector, clientSelectorJss);