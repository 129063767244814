export const PARTICIPANTS_VIEW_PATH = ["*/parts/filter/:filter", "*/parts"];

export const PART_DETAIL_VIEW_PATH = PARTICIPANTS_VIEW_PATH.reduce((acc: string[], path: string) => {
    return acc.concat([
        path + "/id/:id/tab/:tab/userFilters/:userFilters",
        path + "/id/:id/tab/:tab",
        path + "/id/:id/userFilters/:userFilters",
        path + "/id/:id"
    ]);
}, []);

export const PART_DETAIL_VIEW_MODAL_PATH_BUILDER = (basePaths: string[], specific?: boolean) => basePaths.map(path => path + (specific ? "/userId/:userId" : "/id/:id"));