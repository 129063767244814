import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const addOrgToAdminViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        height: '100%'
    },
    orgSelectValue: {
        ...genJss.grow,
        '& > *': {
            ...genJss.grow,
            margin: '-3px'
        }
    },
    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: 'auto'
    }
});