import React, { useState, useEffect, useRef } from 'react';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Auth0Profile, RemoteProfile } from "./AdminProfile";
import AppSync from "../data/AppSync";
import { appPathUpdate } from '../app/App';
import AdminUsersData from '../data/AdminUsersData';
import { additionalAppData } from './appData';
import AdminUser from '../model/AdminUser';

interface IProps {
    auth0Domain: string;
    auth0ClientId: string;
    children: (context: IAccountContext) => React.ReactNode;
}

export enum SignInStatus {
    signedIn, signedOut, loading
}

export interface IAccountContext {
    status: SignInStatus;
    user?: RemoteProfile;
    login: () => void;
    logout: () => void;
}

function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const Auth0ToTKAccount: React.FunctionComponent<{ children: (context: IAccountContext) => React.ReactNode, withPopup?: boolean }> = (props) => {
    // With typescript can do useAuth0<RemoteProfile>() according to doc: https://auth0.github.io/auth0-react/
    const { loginWithRedirect, logout, getIdTokenClaims, isLoading, isAuthenticated, user } = useAuth0();
    const [token, setToken] = useState<string | undefined>(undefined);
    const [remoteProfile, setRemoteProfile] = React.useState<RemoteProfile | undefined>(undefined);
    const prevIsAuthenticated = usePrevious(isAuthenticated);
    useEffect(() => {
        if (isAuthenticated !== prevIsAuthenticated && isAuthenticated && !token) {
            getIdTokenClaims()
                .then((claims) => setToken(claims?.__raw))
                .catch((error) => console.log(error));
        }
    });
    useEffect(() => {
        if (token) {
            AppSync.token = token;
            (process.env.NODE_ENV === 'development' && false ?
                AdminUsersData.instance.getMockGrants()
                :
                AdminUsersData.instance.getGrantsFetch())

                .then((adminUser) => {
                    // Compose a remote profile with data coming from Auth0, `getAdminUserGrants`, 
                    // and additional (hardcoded) app data, to be eventually moved to BE.
                    const remoteProfile: RemoteProfile = {
                        ...user as Auth0Profile,
                        ...adminUser,
                        name: adminUser.name,
                        isSkedGoDev: adminUser.isSkedGoDev,
                        userData: adminUser,
                        appData: additionalAppData(adminUser)
                    };
                    setRemoteProfile(remoteProfile);
                });
        }
    }, [token]);
    const login = () => {
        // Includes in the redirectUri the part of the hash that encodes the app, since appData.ts reads app id from the url
        // to get the proper credentials. Also includes the search part of the URL, since I use it to specify feature flags.
        // I don't include the hash part of the URL completly, since the filter JSON in the URL causes issues 
        // (I would need to encode it, through encodeURI, and the decode it on each view with decodeURI).
        // Instead I use appState.returnToHash to record the full hash to redirect to the same view after login.
        loginWithRedirect({
            redirectUri: window.location.origin + window.location.search + (getAppIdPath() ? `#/${getAppIdPath()}` : ""),
            appState: { returnToHash: window.location.hash },
            connection: "AdminUsers" // TODO: parameterize the connection.
        })
            .catch((error) => console.log(error));
    };
    const logoutHandler = () => {
        // To preserve the app id in the hash on logout, we need to add it to the returnTo URL, so it's logged in to the same app.
        logout({ returnTo: window.location.origin + (getAppIdPath() ? `#/${getAppIdPath()}` : "") });
        setToken(undefined);
        localStorage.removeItem(LS_DASH_APP_ID);
        AppSync.resetLSCaches();
        AppSync.clearCacheStorage();
    };
    const appIdFromAuth = remoteProfile && remoteProfile.appData.app;
    if (appIdFromAuth && getAppIdPath() !== appIdFromAuth) {
        // Replace app id in path by the one from auth
        window.location.hash = `#${appPathUpdate({ appId: appIdFromAuth })}`;
    }
    // At this point app id in path, LS and user profile are synched.
    return (
        <React.Fragment>
            {props.children({
                status: isLoading || (isAuthenticated && !token) ? SignInStatus.loading :
                    token ? SignInStatus.signedIn : SignInStatus.signedOut,
                user: remoteProfile,
                login,
                logout: logoutHandler
            })}
        </React.Fragment>
    );
};

const LS_DASH_APP_ID = "DASH_APP_ID";

/**
 * I removed the need for the '+' terminal character for the app id (though remains support for it to make old urls to remain working). 
 * Now it takes the hash string and captures anyting until the end or '/'. The result can be '' (e.g. when empty hash, or it's just '#', 
 * or '#/'), in which case we return undefined, or a candidate app name that we send to Auth0, and if it returns the same id, then it's 
 * valid, while if not, we reset to the default app id.
 */
export const APP_ID_REGEX = "^([^\/]*)";
export function getAppIdPath(): string | undefined {
    return (window.location.hash.substring(2).match(APP_ID_REGEX)?.[1] ?? window.location.hash.substring(2).match(new RegExp(APP_ID_REGEX))?.[1]) || undefined;
}

export const WithAuth: React.FunctionComponent<IProps> = (props: IProps) => {
    const onRedirectCallback = (appState) => {
        // Clear the search part of the URL
        // Clear the code and state params added by Auth0. Don't clear the search entirely since I use it specify feature flags.
        const url = new URL(window.location.href);
        url.searchParams.delete("code");
        url.searchParams.delete("state");
        // Reinstate the hash part of the URL to preserve the state.
        if (appState?.returnToHash !== undefined) {
            url.hash = appState.returnToHash;
        }
        // Update the URL without reloading the page
        window.history.replaceState({}, document.title, url as any);
    };
    return (
        <Auth0Provider
            domain={props.auth0Domain}
            clientId={props.auth0ClientId}
            onRedirectCallback={onRedirectCallback}
        >
            <Auth0ToTKAccount>
                {props.children}
            </Auth0ToTKAccount>
        </Auth0Provider>
    );
};