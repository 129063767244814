import { Theme } from "../css/Theme";
import { genJss, resetJss } from "../css/gen.jss";

export const viewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.grow,
        padding: '30px',
        boxSizing: 'border-box'
    },
    header: {
        ...genJss.flex,
        ...genJss.spaceBetween,
        ...genJss.alignCenter,
        borderBottom: '1px solid #ececec',
        marginBottom: '30px',
    },
    navContainer: {
        height: '20px'
    },
    title: {
        fontSize: '34px',
        color: '#4c4c4c',
        textAlign: 'left',
        marginTop: '10px'
    },
    subtitle: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...theme.textColorGray,
        marginBottom: '20px'
    },
    left: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    back: {
        ...resetJss.button,
        ...theme.textSizeBody,
        ...genJss.flex,
        ...genJss.alignCenter,
        color: theme.colorPrimary,
        '& svg': {
            transform: 'rotate(180deg)',
            marginRight: '5px'
        },
        '& polygon': {
            fill: theme.colorPrimary
        }
    }
});