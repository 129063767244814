import * as React from 'react'
import Util from "../util/Util";
import genStyles from "../css/general.module.css";
import classNames from "classnames";
import {ValidatorForm} from "react-form-validator-core";
import Validator from "../validator/Validator.jsx";
import DateTimeUtil from "../util/DateTimeUtil";
import User from "../model/User";
import {MoneyTransaction} from "../model/Transaction";
import FormatUtil from "../util/FormatUtil";
import DatePicker from "../time/DatePicker";
import {WithClasses, withStyles} from "../css/StyleHelper";
import {editTransViewJss} from "./EditTransView.jss";
import View from "../view/View";
import {IViewRouteConfig} from "../nav/IViewRouteConfig";
import TransactionsData from "../data/TransactionsData";
import UIUtil from "../util/UIUtil";
import {default as UsersData} from "../data/UsersData";
import withAsycnData from "../data/WithAsyncData";
import Transaction from "../model/Transaction";
import {PART_DETAIL_VIEW_PATH} from "../user/UserViewConstants";

type IStyle = ReturnType<typeof editTransViewJss>;

interface IProps extends WithClasses<IStyle> {
    transaction?: MoneyTransaction;
    user?: User;
    onRequestClose?: (update?: MoneyTransaction) => void;
    onRemove?: () => void;
}

interface IState {
    update: MoneyTransaction;
}

class EditTransView extends React.Component<IProps, IState> {

    private formRef: any;

    constructor(props: IProps) {
        super(props);
        // User will come instantiated on construction when editing existing user.
        let update: MoneyTransaction;
        if (this.props.transaction) {
            update = Util.deepClone(this.props.transaction);
        } else {
            update = new MoneyTransaction();
            update.userId = this.props.user!.id;
            update.timestampMoment = DateTimeUtil.getNowDate();
        }
        this.state = {
            update: update
        };
        this.onSaveClick = this.onSaveClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onUpdateDate = this.onUpdateDate.bind(this);
    }

    private onSaveClick() {
        this.formRef.isFormValid(false)
            .then((valid: boolean) => {
                if (valid && this.props.onRequestClose) {
                    const update = this.state.update;
                    this.props.onRequestClose(update);
                }
            });
    }

    private onCancelClick() {
        if (this.props.onRequestClose) {
            this.props.onRequestClose();
        }
    }

    private onUpdateDate(value: string) {
        if (!value) {
            value = this.state.update.timestampMoment.format(DateTimeUtil.HTML5_DATE_FORMAT);
        }
        const update = Util.clone(this.state.update);
        update.timestampMoment = DateTimeUtil.momentDefaultTZ(value, DateTimeUtil.HTML5_DATE_FORMAT);
        this.setState({update: update});
    };

    public render(): React.ReactNode {
        const styles = this.props.classes;
        const appStyles = this.props.appClasses;
        const update = this.state.update;
        const departDateValue = update.timestampMoment.format(DateTimeUtil.HTML5_DATE_FORMAT);
        return (
            <View
                title={(this.props.transaction ? "Edit" : "New") + " Transaction"}
                subtitle={this.props.user && this.props.user.name ? "By " + this.props.user.name : ""}
                right={
                    <button onClick={this.props.onRemove}
                            className={appStyles.buttonCancel}>
                        Remove
                    </button>
                }
            >
                <ValidatorForm
                    onSubmit={() => {
                        console.log("callback");
                    }}
                    instantValidate={false}
                    ref={(ref: any) => this.formRef = ref}
                    className={genStyles.grow}
                >
                    <div className={classNames(styles.form, genStyles.flex, genStyles.column)}>
                        <div className={classNames(styles.row, genStyles.flex, genStyles.alignCenter)}>
                            <label>Applicable Date</label>
                            <Validator
                                value={departDateValue}
                                name="date"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    return (
                                        <div
                                            className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.relative, genStyles.grow)}>
                                            <DatePicker
                                                inputRef={params.inputRefCallback}
                                                value={params.value}
                                                onChange={(value: string) => this.onUpdateDate(value)}
                                                className={classNames(styles.departDateInput)}
                                                key="1"
                                            />
                                            {params.errorMessage &&
                                            <div className={appStyles.validationError} key="2">{params.errorMessage}</div>}
                                        </div>
                                    )
                                }}
                            </Validator>
                        </div>
                        <div className={classNames(styles.row, genStyles.flex, genStyles.alignCenter)}>
                            <label>Amount</label>
                            <Validator
                                value={update.amount === 0 ? "" : update.amount}
                                name="price"
                                validators={["required"]}
                                errorMessages={["this field is required"]}
                                resetOnValid
                            >
                                {(params: { errorMessage: any, value: any, inputRefCallback: any }) => {
                                    return (
                                        <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.relative, genStyles.grow)}>
                                            <div className={styles.currency}>$</div>
                                            <input
                                                type={"number"}
                                                step={"0.1"}
                                                ref={params.inputRefCallback}
                                                value={params.value === "" ? "" : FormatUtil.fromCents(params.value)}
                                                onChange={(event) => {
                                                    const update = Util.deepClone(this.state.update);
                                                    const parsed = parseFloat(event.target.value);
                                                    update.amount = isNaN(parsed) ? 0 : FormatUtil.toCents(parsed);
                                                    this.setState({update: update});
                                                }}
                                                // placeholder={"0"}
                                                className={classNames(styles.priceInput, appStyles.hidePlaceholderOnFocus)}
                                                key="1"
                                            />
                                            {params.errorMessage &&
                                            <div className={appStyles.validationError} key="2">{params.errorMessage}</div>}
                                        </div>
                                    )
                                }}
                            </Validator>
                        </div>
                        <div className={classNames(styles.row, styles.noteSection, genStyles.flex, genStyles.alignCenter)}>
                            <label>Description</label>
                            <textarea
                                className={genStyles.grow}
                                value={update.note}
                                onChange={(event) => {
                                    const update = Util.deepClone(this.state.update);
                                    const value = event.target.value;
                                    update.note = value === "" ? undefined : value;
                                    this.setState({update: update});
                                }}
                            />
                        </div>
                    </div>
                </ValidatorForm>
                <div className={styles.buttonsPanel}>
                    <button onClick={this.onCancelClick} className={appStyles.buttonCancel}>
                        Cancel
                    </button>
                    <button onClick={this.onSaveClick} className={appStyles.buttonOk}>
                        Save
                    </button>
                </div>
            </View>
        );
    }

}

const EditTransViewStyled = withStyles(EditTransView, editTransViewJss);

// noinspection JSUnusedLocalSymbols
const NewTransViewWithData = withAsycnData(EditTransViewStyled,
    (query: {userId: string}) => UsersData.instance.get(query.userId).then((user: User) => {return {user}}) as Promise<{user?: User}>);

export const TRANS_NEW_VIEW: IViewRouteConfig<{userId: string}> =
    {
        path: PART_DETAIL_VIEW_PATH.map(path => path + "/newtrans"),
        propsFromMatch: (match: any) => ({userId: match.params.id!}),
        propsToPath: () => "/newtrans",
        navLabel: () => "New Transaction",
        render: ({viewProps, navHistory, waitFor}) => {
            return (
                <NewTransViewWithData
                    userId={viewProps.userId}
                    renderWhenData={true}
                    undefineOnUpdate={false}
                    onRequestClose={(update?: MoneyTransaction) => {
                        if (update) {
                            waitFor(TransactionsData.instance.createMoneyTransaction(update).then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                navHistory.pop();
                            }));
                        } else {
                            navHistory.pop();
                        }
                    }}
                />
            )
        }
    };

// noinspection JSUnusedLocalSymbols
const EditTransViewWithData = withAsycnData(EditTransViewStyled,
    (query: {id: string}) => TransactionsData.instance.get(query.id)
        .then((booking: Transaction | undefined) => {return {transaction: Util.transerialize(booking, MoneyTransaction)}}) as Promise<{transaction?: MoneyTransaction}>);


export const TRANS_EDIT_VIEW: IViewRouteConfig<{id: string}> =
    {
        path: ["*/transEdit/:transId"],
        propsToPath: (params: {id: string}) => "/transEdit/" + params.id,
        propsFromMatch: (match: any) => ({id: match.params.transId}),
        navLabel: () => "Edit Transaction",
        render: ({viewProps, navHistory, waitFor}) => {
            const onRemove = () => {
                UIUtil.confirmMsg({
                    message: 'Confirm to delete',
                    onConfirm: () => waitFor(TransactionsData.instance.deleteMoneyTrans(viewProps.id)
                        .then(() => {
                            TransactionsData.instance.invalidateTransCache();
                            return navHistory.pop();
                        }))
                });
            };
            return (<EditTransViewWithData
                    {...viewProps}
                    renderWhenData={true}
                    undefineOnUpdate={false}
                    onRequestClose={(update?: MoneyTransaction) => {
                        if (update) {
                            waitFor(TransactionsData.instance.updateMoneyTrans(update).then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                UsersData.instance.invalidateFetchCache();
                                return navHistory.pop();
                            }));
                        } else {
                            navHistory.pop();
                        }
                    }}
                    onRemove={onRemove}
                />
            )
        }
    };