import { Theme } from "../css/Theme";
import { genJss, resetJss } from "../css/gen.jss";

export const bundleConfigsTableJss = (theme: Theme) => ({

    buttonDelete: {
        ...resetJss.button,
        '& svg': {
            width: '24px',
            height: '24px'
        }
    },
    numOfStaffLink: {
        ...genJss.flex,
        ...genJss.alignCenter,
        paddingRight: '48px'
    },
    openUsersBtn: {        
        ...resetJss.button,
        ...genJss.noShrink,
        width: '18px',
        height: '18px',
        marginLeft: '15px',
        '& svg': {
            width: '100%',
            height: '100%',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    }

});

export default bundleConfigsTableJss;