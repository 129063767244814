import React, { useEffect, useRef, useState } from "react";
import Autocomplete from 'tripkit-react/dist/util_components/TKUIAutocomplete';
import { ReactComponent as IconSpin } from '../images/ic-loading2.svg';
import { ReactComponent as IconRemove } from '../images/ic-cross.svg';
import { Theme } from "../css/Theme";
import { StyleOverride, WithClasses, withStylesO } from "../css/StyleHelper";
import { black, genJss, resetJss } from "../css/gen.jss";
import AutocompleteResult from "./AutocompleteResult";
import { Subtract } from "utility-types";
import { ReactComponent as IconGlass } from "../images/ic-glass.svg";

type IStyle = ReturnType<typeof autocompleteBoxJss>;

interface IProps<T> extends WithClasses<IStyle> {
    value?: T;
    onChange: (value?: T) => void;
    onResultHighlight?: (value?: T) => void,
    debounce?: number;
    toText: (value: T) => string;
    resultsFor: (query: string) => Promise<T[]>;
    renderResult?: (item: T, isHighlighted: boolean) => JSX.Element
    placeholder?: string;
    reserveSpaceForRemoveIcon?: boolean;
    clearOnSelection?: boolean;
    showGlassIcon?: boolean;
    selectOnBlur?: boolean; // True by default
    disabled?: boolean;
    className?: string;
    name?: string;
}

const autocompleteBoxJss = (theme: Theme) => ({
    inputBox: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.grow,
        fontFamily: theme.fontFamily,
        '& input:disabled': {
            background: 'initial'
        }
    },
    iconLoading: {
        marginLeft: '6px',
        width: '16px',
        height: '16px',
        '& path': {
            fill: black(1)
        },
        ...genJss.animateSpin
    },
    btnClear: {
        ...resetJss.button,
        margin: '0',
        padding: '0',
        height: '17px',
        cursor: 'pointer'
    },
    iconClear: {
        width: '17px',
        height: '17px',
        marginLeft: '5px',
        cursor: 'pointer',
        ...genJss.svgFillCurrColor,
        color: black(1)
    },
    iconGlass: {
        width: '16px',
        height: '16px',
        marginLeft: '6px',
        '& path': {
            fill: black(2)
        }
    },
    resultItem: {
        ...genJss.flex,
        ...genJss.column,
        padding: '5px 10px',
        '& > *': {
            lineHeight: '24px'
        }
    },
    resultTitle: {

    },
    resultSubtitle: {
        color: '#212a33de'
    },
    menu: {
        background: 'white',
        borderRadius: '3px',
        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
        padding: '5px 0',
        fontSize: '90%',
        position: 'absolute',
        top: '37px',
        left: '-10px',
        overflow: 'auto',
        maxHeight: '400px',
        zIndex: 1,
        minWidth: 'calc(100% + 20px)'
    }
});

// function AutocompleteBox<T>(props: PropsWithChildren<IProps<T>>): JSX.Element {
const AutocompleteBox = <T extends unknown>(props: IProps<T>) => {
    const { value, onChange, onResultHighlight, toText, renderResult, placeholder, debounce, reserveSpaceForRemoveIcon, clearOnSelection, showGlassIcon, selectOnBlur, disabled, classes, injectedStyles, name } = props;
    const [waiting, setWaiting] = useState<boolean>(false);
    const [results, setResults] = useState<T[]>([]);
    const [inputText, setInputText] = useState<string>("");
    // Use a ref to access the current inputText value in
    // an async callback.
    const inputTextRef = useRef<string>();
    inputTextRef.current = inputText;
    const autocompleteRef = useRef<any>();

    useEffect(() => {
        if (value) {
            setInputText(toText(value))
        } else {
            setInputText("");
        }
    }, [value]);

    function onInputTextChange(newInputText: string) {
        setInputText(newInputText);
        if (newInputText === '') {
            refreshResults(newInputText);
        } else {
            setTimeout(() => {
                if (inputTextRef.current === newInputText) {
                    refreshResults(inputTextRef.current)
                }
            }, debounce ?? 200);
        }
        // Remove value when user deletes all text.
        if (newInputText === "" && value) {
            onChange(undefined);
            onResultHighlight?.(undefined);
        }
    }

    function refreshResults(text) {
        setWaiting(true);
        props.resultsFor(text)
            .then(results => {
                if (text !== inputTextRef.current) {
                    return;
                }
                setWaiting(false);
                setResults(results);
            });
    }

    function onClearClicked() {
        // It's equivalent to call setInputText(""); setResults([]); setWaiting(false); onChange(undefined);
        onInputTextChange("");
        // focus() must be called after completion of setState() inside setValue()
        setTimeout(() => {
            autocompleteRef.current?.focus();
        }, 100);
    }

    function refreshHighlight() {
        const highlightedIndex = autocompleteRef.current?.state.highlightedIndex;
        props.onResultHighlight?.(results[highlightedIndex]);
    }

    function renderInput(props: any) {
        return (
            <div className={classes.inputBox} role="none">
                <input
                    {...props}
                    disabled={disabled}
                />
                {disabled ? null :
                    waiting ?
                        <IconSpin className={classes.iconLoading} focusable="false" /> :
                        inputText ?
                            <button
                                onClick={onClearClicked}
                                className={classes.btnClear}
                                aria-hidden={true}
                                tabIndex={-1}>
                                <IconRemove
                                    aria-hidden={true}
                                    className={classes.iconClear}
                                    focusable="false"
                                />
                            </button> : showGlassIcon ? <IconGlass className={classes.iconGlass} /> :
                                reserveSpaceForRemoveIcon ? <div style={{ width: 22 }}></div> : null
                }
            </div>
        );
    }

    return (
        <Autocomplete
            items={results}
            getItemValue={item => toText(item)}
            renderItem={renderResult ?? ((item, isHighlighted) => (
                <AutocompleteResult
                    title={props.toText(item)}
                    isHighlighted={isHighlighted}
                    key={results.indexOf(item)}
                />
            ))}
            value={inputText}
            onChange={(_event, value) => onInputTextChange(value)}
            onSelect={(newText, newValue) => {
                onChange(newValue);
                if (clearOnSelection) {
                    setInputText("");
                } else {
                    setInputText(toText(newValue));
                }
                setResults([]);
                autocompleteRef.current?.blur();   // Lose focus on selection (e.g. user hits enter on highligthed result)
                onResultHighlight?.(undefined);
            }}
            renderInput={renderInput}
            inputProps={{
                type: "text",
                spellCheck: false,
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "off",
                placeholder: placeholder,
                style: {
                    flexGrow: 1
                },
                onFocus: () => {
                    refreshResults(inputText);
                },
                onKeyDown: e => {
                    if (e.keyCode === 38 || e.keyCode === 40) {
                        // Need to wait for the highlighted index to be updated.
                        setTimeout(refreshHighlight, 1);
                    }
                },
                name
            }}
            wrapperProps={props.className ? {
                className: props.className
            } : undefined}
            wrapperStyle={{
                position: 'relative',
                display: 'flex',
                ...disabled && {
                    backgroundColor: 'light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3))'
                }
            }}
            menuStyle={injectedStyles.menu}
            // open={process.env.NODE_ENV === 'development' || undefined}            
            ref={autocompleteRef}
            selectOnBlur={selectOnBlur ?? true}
            {...results.length === 0 && { open: false }}
        />
    )

}

export type AutocompleteBoxType<T> = (props: Subtract<IProps<T>, WithClasses<IStyle>> & { styles?: StyleOverride<IStyle> }) => JSX.Element;

export default withStylesO(AutocompleteBox, autocompleteBoxJss);