import React, { useContext, useEffect, useState } from "react";
import Transaction, { PriceChangeStatus, priceChangeStatusLabel } from "../model/Transaction";
import DateTimeUtil from "../util/DateTimeUtil";
import TransportUtil from "../util/TransportUtil";
import FormatUtil from "../util/FormatUtil";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";
import { ReactComponent as IconSchedule } from "../images/ic-schedule.svg";
import { ReactComponent as IconReschedule } from "../images/ic-reschedule.svg";
import { ReactComponent as IconReturnArrow } from "../images/ic-return-arrow.svg";
import { ReactComponent as IconAlert } from "../images/ic-circle-warning.svg";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bookingTableJss } from "./BookingsTable.jss";
import { PaymentStatusPill } from "./TransactionsTable";
import genStyles from "../css/general.module.css";
import TableSortLabel from "@mui/material/TableSortLabel";
import Filter, { SortOrder } from "../data/Filter";
import Util from "../util/Util";
import { canEditBookingStatus } from "./BookingViewHelpers";
import Tooltip from "../uiutil/Tooltip";
import { TransGroups } from "./BookingsView";
import AdminProfile, { ModelOperation, ModelType } from "../account/AdminProfile";
import Table, { ITableColSpec } from "../view/Table";
import { AppContext, appPathUpdate } from "../app/App";
import { descendentOrg, orgFromId } from "../app/OrgSelectorHelpers";
import classNames from "classnames";
import TransactionsData from "../data/TransactionsData";
import { Client } from "../model/Client";
import { ReactComponent as IconInfo } from "../images/ic-info.svg";
import { ReactComponent as IconWheelchair } from "tripkit-react/dist/images/service/ic_wheelchair_accessible.svg.js";
import { i18n } from "../i18n/TKI18nConstants";
import { statusPillBuilder, TransStatusPill } from "./StatusPill";
import LocationUtil from "../util/LocationUtil";
import { ReactComponent as IconFromTo } from "../images/ic-from-to.svg";
import { ReactComponent as IconClock } from "../images/ic-clock.svg";
import { ReactComponent as IconExternalLink } from "../images/ic-external-link.svg";
import { ReactComponent as IconAccept } from "../images/ic-check.svg";
import { ReactComponent as IconReject } from "../images/ic-reject.svg";
import { genClassNames } from "tripkit-react/dist/css/GenStyle.css";
import PriceChangeDetails from "./PriceChangeDetails";
import { PriceChangeActionType } from "./PriceChangeViewHelpers";
import { ReactComponent as IconArrowRight } from "../images/ic-arrow-right.svg";
import CopyToClipboard from "../uiutil/CopyToClipboard";
import { useProviders } from "../data/ProvidersData";

export const ShortIdCell = ({ shortId }: { shortId: string }) =>
    <CopyToClipboard
        text={shortId}
        label={<div style={{ fontSize: '14px', flexGrow: 1 }}>{shortId}</div>}
        styles={(_theme, defaultStyles) => ({
            main: {
                ...defaultStyles.main,
                flexDirection: 'row-reverse',
            },
            icon: {
                ...defaultStyles.icon,
                width: '16px',
                height: '16px',
                marginLeft: 0,
                marginRight: '5px'
            }
        })}
    />

type IStyle = ReturnType<typeof bookingTableJss>;

interface IProps extends WithClasses<IStyle> {
    values: Transaction[];
    onSelect?: (transaction: Transaction) => void;
    onEdit?: (trans: Transaction) => void;
    onSchedule?: (trans: Transaction) => void;
    onEditStatus?: (trans: Transaction) => void;
    onOpenPriceChange?: (trans: Transaction, priceActionType?: PriceChangeActionType) => void;
    tableId: string;
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    selectedClientID?: string;
    clients?: Client[];
    /**
     * If undefined, the config button is not displayed, but space is reserved for it.
     */
    showConfigBtn?: boolean;
}

// This functions are called to pass values to table config view, to display column selector, and from here (or Admin Profile), to calculate default columns for the table.

type BookingTableColId =
    "short_id" | "charge" | "depart" | "arrive" | "createdAt" | "udatedAt" | "user" | "wheelchair" | "client_app" | "org" | "status" | "schedule" |
    "from" | "from_street" | "from_city" | "from_state" | "from_zip" |
    "to" | "to_street" | "to_city" | "to_state" | "to_zip" |
    "distance" | "related_trips" | "return_trip" | "service" | "product" | "price" | "payment" | "number_of_passengers" | "edit" | "bundle_name" |
    "price_change" | "price_change_response" | "price_change_created_at" | "price_change_note" | "price_change_review" | "booking_summary" | "initiative";

/**
 * Order is relevant, too. Notice the order of charge column changes depending on the tableId.
 * No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(tableId: string, adminProfile: AdminProfile): BookingTableColId[] {
    const insIf = (field: BookingTableColId, cond: boolean) => Util.insertIf<BookingTableColId>(cond, field);
    if (tableId.includes("-price-change-requests")) {
        return [
            "price_change_created_at", "booking_summary", "price_change", "price_change_note", "price_change_response"
        ];
    }
    const dafaultColumns: BookingTableColId[] = [
        ...insIf("charge", tableId.includes("-charge")),
        "depart", "arrive", "user", "wheelchair", "client_app", "status",
        ...insIf("schedule", !tableId.includes("-charge")),
        "from", "to",
        ...insIf("distance", adminProfile.isTSPUser),
        "return_trip", "related_trips", "service", "product", "price", "payment",
        ...insIf("charge", !tableId.includes("-charge")),
        "number_of_passengers", "org", "edit"
    ];
    return dafaultColumns;
}

const priceChangeStatusColor = (status: PriceChangeStatus) => {
    return undefined;
}

export const PriceChangeStatusPill = statusPillBuilder({
    statusToColor: priceChangeStatusColor,
    statusToDisplayString: priceChangeStatusLabel
});

const BookingsTable: React.FunctionComponent<IProps> = (props: IProps) => {

    const { values, filter, selectedClientID, clients, tableId, showConfigBtn, onSelect, onFilterChange, onSchedule, onEdit, onEditStatus, onOpenPriceChange, classes, appClasses } = props;

    const { profile: adminProfile, selectedOrgID, navHistory } = useContext(AppContext);

    const providers = useProviders({ clientId: selectedClientID, adminProfile });

    const [highlightRelated, setHighlightRelated] = useState<Transaction | undefined>();
    const theOtherRelatedId = highlightRelated?.relatedBookings.find(rb => rb.type === "OUTBOUND" || rb.type === "RETURN")?.bookingId;
    const [theOtherRelated, setTheOtherRelated] = useState<Transaction | undefined>();

    useEffect(() => {
        if (theOtherRelatedId) {
            TransactionsData.instance.get(theOtherRelatedId)
                .then(booking => setTheOtherRelated(booking))
        } else {
            setTheOtherRelated(undefined);
        }
    }, [theOtherRelatedId])

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = filter.sortOrder;
            const filterUpdate = Util.iAssign(filter,
                {
                    sortOrder: !currSortOrder || currSortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate);
        }
    }

    const groupByDay = filter.groupBy === TransGroups.DAY;

    const tableContent: ITableColSpec<Transaction, BookingTableColId>[] = [
        {
            id: "depart",
            name: "Depart",
            label: (
                <TableSortLabel
                    active={true}
                    direction={filter.sortOrder || SortOrder.ASC}
                    onClick={handleSortOrderClick}
                >
                    Depart
                </TableSortLabel>
            ),
            cellValue: (booking: Transaction) => {
                const timezone = booking.timezone;
                const showGMT = DateTimeUtil.isOnDifferentTimezone(booking.timezone);
                // Show day if booking is on different timezone and it's not the same day as in the local time.
                const showDay = DateTimeUtil.isOnDifferentTimezone(booking.timezone) &&
                    DateTimeUtil.momentTZTime(booking.transactionTime * 1000, adminProfile.localTimezone ?? adminProfile.timezone)
                        .format("DD") !== booking.transactionMoment.format("DD");
                const depart = DateTimeUtil.momentTZTime(booking.depart * 1000, timezone);
                return groupByDay ? depart.format(DateTimeUtil.timeFormat() + (showDay ? " ddd DD" : "")) + (showGMT ? " " + DateTimeUtil.toGMTString(depart) : "") :
                    DateTimeUtil.formatRelativeDay(booking.departMoment, DateTimeUtil.dateFormatWithDay() +
                        ", " + DateTimeUtil.timeFormat(), { partialReplace: DateTimeUtil.dateFormatWithDay(), timezone }) + (showGMT ? " " + DateTimeUtil.toGMTString(depart) : "");
            },
            width: groupByDay ? 110 : 180
        },
        {
            id: "arrive",
            name: "Arrive",
            cellValue: (booking: Transaction) => {
                const timezone = booking.timezone;
                const arrive = booking.arrive ? DateTimeUtil.momentTZTime(booking.arrive * 1000, timezone) : undefined;
                return arrive?.format(DateTimeUtil.timeFormat());
            },
            width: 110
        },
        {
            id: "createdAt",
            name: "Creation Time",
            cellValue: (booking: Transaction) => {
                const timezone = booking.timezone;
                const showGMT = DateTimeUtil.isOnDifferentTimezone(booking.timezone);
                const createdAt = booking.createdAt ? DateTimeUtil.isoToMomentTimezone(booking.createdAt, timezone) : undefined;
                return createdAt && DateTimeUtil.formatRelativeDay(createdAt, DateTimeUtil.dateFormatWithDay() +
                    ", " + DateTimeUtil.timeFormat(), { partialReplace: DateTimeUtil.dateFormatWithDay(), timezone }) + (showGMT ? " " + DateTimeUtil.toGMTString(createdAt) : "");
            },
            width: 140
        },
        {
            id: "udatedAt",
            name: "Update Time",
            cellValue: (booking: Transaction) => {
                const timezone = booking.timezone;
                const showGMT = DateTimeUtil.isOnDifferentTimezone(booking.timezone);
                const updatedAt = booking.updatedAt ? DateTimeUtil.isoToMomentTimezone(booking.updatedAt, timezone) : undefined;
                return updatedAt && DateTimeUtil.formatRelativeDay(updatedAt, DateTimeUtil.dateFormatWithDay() +
                    ", " + DateTimeUtil.timeFormat(), { partialReplace: DateTimeUtil.dateFormatWithDay(), timezone }) + (showGMT ? " " + DateTimeUtil.toGMTString(updatedAt) : "");
            },
            width: 140
        },
        {
            id: "user",
            name: "User",
            available: !tableId.includes("-user"),
            cellValue: (booking: Transaction) =>
                <a
                    className={appClasses.detailLinkIconOnHover}
                    onClick={() => {
                        // waitFor(UsersData.instance.get(booking.userId!));
                        navHistory.pushS(`/userId/${booking.userId}`);
                    }}
                >
                    {booking.userName}
                    <IconInfo />
                </a>,
            width: 200
        },
        {
            id: "wheelchair",
            name: "Wheelchair",
            label: null,
            cellValue: (booking: Transaction) => {
                const wheelchairOptions = booking.confirmationInput?.mobilityOptions.filter(mo => mo.toLowerCase().startsWith("wheelchair"));
                if (!wheelchairOptions || wheelchairOptions.length === 0) {
                    return null;
                }
                return (
                    <Tooltip title={wheelchairOptions.map(FormatUtil.camelCaseToSpaced).join(", ")}>
                        <div style={{ marginLeft: '-25px' }}>
                            <IconWheelchair className={classes.iconWheelchair} />
                        </div>
                    </Tooltip >
                );
            },
            width: 10
        },
        {
            id: "client_app",
            name: i18n.t("Client.app"),
            available: adminProfile.isSuperApp,
            cellValue: (booking: Transaction) => clients?.find(client => client.clientID === booking.clientId)?.clientName,
            visible: !selectedClientID,
            width: 140
        },
        {
            id: "org",
            name: i18n.t("Organization"),
            available: adminProfile.features.organizations,
            cellValue: (booking: Transaction) => booking.organizationId && adminProfile.orgs && (orgFromId(booking.organizationId, adminProfile.orgs)?.name ?? "unknown"),
            visible: !selectedOrgID ||
                (adminProfile.orgs && adminProfile.orgs.some(o => o.id !== selectedOrgID && orgFromId(selectedOrgID, adminProfile.orgs!) && descendentOrg(o, orgFromId(selectedOrgID, adminProfile.orgs!)!))),
            width: 140
        },
        {
            id: "status",
            name: "Status",
            cellValue: (booking: Transaction) => {
                const isStatusEditable = canEditBookingStatus(booking);
                return (
                    <div className={classes.statusCell}>
                        <TransStatusPill status={booking.status!} noSpaceForIcon={true} />
                        {isStatusEditable && onEditStatus &&
                            <button className={classes.editStatusBtn}>
                                <Tooltip title={"Edit status"}>
                                    <div>
                                        <IconEdit
                                            className={classes.iconEdit}
                                            onClick={(e) => {
                                                onEditStatus?.(booking);
                                                e.stopPropagation();
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            </button>}
                    </div>
                );
            },
            width: 150
        },
        {
            id: "schedule",
            name: "Schedule",
            label: null,
            cellValue: (booking: Transaction) => {
                const scheduleAction = booking.actions.find(action => action.action === "SCHEDULE" || action.action === "RESCHEDULE");
                const hasRelatedBookings = booking.relatedBookings.length > 0;
                const tooltipElems: string[] = [(scheduleAction?.action === 'RESCHEDULE' ? "Re-schedule trip" : "Schedule trip") + (hasRelatedBookings ? "s" : "")];
                if (scheduleAction?.hint) {
                    tooltipElems.push(scheduleAction.hint);
                }
                const Icon = scheduleAction?.action === "RESCHEDULE" ? IconReschedule : IconSchedule;
                return onSchedule && scheduleAction &&
                    <button className={classes.scheduleButton} disabled={scheduleAction.disabled}>
                        <Tooltip title={tooltipElems.join(". ")}>
                            <div>
                                <Icon
                                    className={classes.iconSchedule}
                                    onClick={(e) => {
                                        onSchedule?.(booking);
                                        e.stopPropagation();
                                    }}
                                />
                                {hasRelatedBookings &&
                                    <Icon className={classes.iconSchedule} />}
                            </div>
                        </Tooltip>
                    </button>
            },
            width: 40
        },
        {
            id: "from",
            name: "From",
            cellValue: (booking: Transaction) => booking.from.address,
            width: 300
        },
        {
            id: "from_street",
            name: "From Street",
            cellValue: (booking: Transaction) => booking.from.street,
            width: 200
        },
        {
            id: "from_city",
            name: "From City",
            cellValue: (booking: Transaction) => booking.from.city,
            width: 150
        },
        {
            id: "from_state",
            name: "From State",
            cellValue: (booking: Transaction) => booking.from.state,
            width: 100
        },
        {
            id: "from_zip",
            name: "From Zip",
            cellValue: (booking: Transaction) => booking.from.zip,
            width: 100
        },
        {
            id: "to",
            name: "To",
            cellValue: (booking: Transaction) => booking.to ? booking.to.address : "",
            width: 300
        },
        {
            id: "to_street",
            name: "To Street",
            cellValue: (booking: Transaction) => booking.to.street,
            width: 200
        },
        {
            id: "to_city",
            name: "To City",
            cellValue: (booking: Transaction) => booking.to.city,
            width: 150
        },
        {
            id: "to_state",
            name: "To State",
            cellValue: (booking: Transaction) => booking.to.state,
            width: 100
        },
        {
            id: "to_zip",
            name: "To Zip",
            cellValue: (booking: Transaction) => booking.to.zip,
            width: 100
        },
        {
            id: "distance",
            name: "Distance",
            cellValue: (booking: Transaction) => booking.distance && FormatUtil.toDistance(booking.distance, { briefUnit: true }),
            width: 100
        },
        {
            id: "related_trips",
            name: "Round Trip",
            available: !!adminProfile.features.relatedBookings,
            cellValue: (booking: Transaction) => {
                const bookingType = booking.relatedBookings.find(rb => rb.type === "OUTBOUND") ? "RETURN" : (booking.relatedBookings.find(rb => rb.type === "RETURN") || booking.confirmationInput?.returnTrip) ? "OUTBOUND" : undefined;
                return bookingType &&
                    <Tooltip
                        title={
                            (theOtherRelated || booking.confirmationInput?.returnTrip) ?
                                <div className={classes.relatedTooltip} onClick={e => e.stopPropagation()}>
                                    <div>{FormatUtil.toFirstUpperCase(bookingType) + "."}</div>
                                    <div>
                                        {FormatUtil.toFirstUpperCase(bookingType === "OUTBOUND" ? "RETURN" : "OUTBOUND")
                                            + ": "
                                            + ((theOtherRelated?.departMoment ?? DateTimeUtil.isoToMomentTimezone(booking.confirmationInput!.returnTrip!, booking.timezone))
                                                .format(DateTimeUtil.timeFormat() + ", " + DateTimeUtil.dayMonthFormat()))}
                                    </div>
                                </div> : []
                        }
                        onClose={() => setHighlightRelated(undefined)}
                        placement="right"
                    >
                        <button
                            className={classNames(classes.relatedButton, bookingType === "OUTBOUND" && classes.outbound, (booking === highlightRelated || booking.id === theOtherRelatedId) && classes.relatedHighlight)}
                            onMouseEnter={() => {
                                setHighlightRelated(booking);
                            }}
                        // onMouseLeave={() => {
                        //     setHighlightRelated(undefined);
                        // }}
                        >
                            <IconReturnArrow />
                        </button>
                    </Tooltip>;
            },
            cellClass: appClasses.cellCenter,
            width: 116
        },
        {
            id: "service",
            name: "Provider",
            cellValue: (booking: Transaction) => {
                const provider = providers?.find(provider => provider.id === booking.mode);
                return provider &&
                    <div className={classes.transport}>
                        <img className={appClasses.modeIcon}
                            src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />
                        {provider.modeName}
                    </div>;
            },
            width: 250
        },
        {
            id: "product",
            name: "Product",
            available: false,
            cellValue: (booking: Transaction) => booking.productName,
            width: 250
        },
        {
            id: "price",
            name: "Price",
            available: !!adminProfile.features.bookingPrice,
            cellValue: (booking: Transaction) => FormatUtil.toMoney(booking.price, { nInCents: true }),
            width: 100
        },
        {
            id: "payment",
            name: "Payment",
            available: !!adminProfile.features.payments,
            cellValue: (booking: Transaction) =>
                booking.paymentStatus ?
                    <div className={genStyles.flex}>
                        <PaymentStatusPill status={booking.paymentStatus} />
                    </div> : "-",
            width: 150
        },
        {
            id: "charge",
            name: "Charge",
            available: !!adminProfile.features.payments,
            label: tableId.includes("-charge") ? "Charge warn" : "Charge",
            cellValue: (booking: Transaction) => {
                let charCellContent = adminProfile.features.payments && booking.paymentStatus &&
                    <div className={classes.charge}>
                        {booking.chargeAutomatically ? "auto" : "manual"}
                        {booking.message &&
                            <IconAlert />}
                    </div>
                if (charCellContent && booking.message) {
                    charCellContent =
                        <Tooltip title={booking.message}>
                            {charCellContent}
                        </Tooltip>
                }
                return charCellContent;
            },
            width: tableId.includes("-charge") ? 133 : 120
        },
        {
            id: "bundle_name",
            name: i18n.t("Bundle.Name"),
            available: adminProfile.features.bundles && !!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.read),
            cellValue: (booking: Transaction) => booking.bundleName,
            width: 170
        },
        {
            id: "number_of_passengers",
            name: "Number of passengers",
            available: !!adminProfile.features.multiPassenger,
            cellValue: (booking: Transaction) => booking.confirmationInput?.totalPassengers ?? "-",
            width: 130
        },
        {
            id: "short_id",
            name: "ID",
            cellValue: (value: Transaction) =>
                value.shortId &&
                <ShortIdCell shortId={value.shortId} />,
            width: 145
        },
        {
            id: "price_change_created_at",
            name: tableId.includes("-price-change-requests") ? "Request time" : "Price Change Request Time",
            cellValue: (booking: Transaction) => {
                const timezone = booking.timezone;
                const showGMT = DateTimeUtil.isOnDifferentTimezone(booking.timezone);
                const latestPCR = booking.priceChanges.length > 0 ? booking.priceChange : undefined;
                const createdAt = latestPCR?.createdAt ? DateTimeUtil.isoToMomentTimezone(latestPCR.createdAt, timezone) : undefined;
                return createdAt && DateTimeUtil.formatRelativeDay(createdAt, DateTimeUtil.dateFormatWithDay() +
                    ", " + DateTimeUtil.timeFormat(), { partialReplace: DateTimeUtil.dateFormatWithDay(), timezone }) + (showGMT ? " " + DateTimeUtil.toGMTString(createdAt) : "");
            },
            width: 150,
            available: adminProfile.features.priceChanges21702
        },
        {
            id: "price_change",
            name: "Price Change",
            cellValue: (item: Transaction) => <PriceChangeDetails booking={item} showStatus={!tableId.includes("-price-change-requests")} onOpenPriceChange={tableId.includes("-price-change-requests") ? undefined : onOpenPriceChange} />,
            width: tableId.includes("-price-change-requests") ? 150 : 300,
            available: adminProfile.features.priceChanges21702
        },
        {
            id: "price_change_response",
            name: "Response",
            cellValue: (booking: Transaction) => {
                if (booking.priceChanges.length === 0) {
                    return null;
                }
                const status = booking.priceChangeResponse?.status;
                return status === "ACCEPTED" ? <IconAccept className={classes.iconAccept} /> :
                    status === "REJECTED" ? <IconReject className={classes.iconReject} /> :
                        adminProfile.isSuperAdmin &&
                        <button
                            className={appClasses.buttonAdd}
                            onClick={(e) => {
                                onOpenPriceChange?.(booking, "RESPOND");
                                e.stopPropagation();
                            }}
                        >
                            Review
                        </button>;
            },
            width: 150,
            available: adminProfile.features.priceChanges21702 && tableId.includes("-price-change-requests")
        },
        {
            id: "price_change_note",
            name: "Note",
            cellValue: (booking: Transaction) => booking.priceChange?.note,
            width: 250,
            available: adminProfile.features.priceChanges21702
        },
        {
            id: "booking_summary",
            name: "Booking",
            cellValue: (booking: Transaction) => {
                const showGMT = DateTimeUtil.isOnDifferentTimezone(booking.timezone);
                const depart = booking.departMoment;
                const currentYear = depart.get('year') === DateTimeUtil.getNow().get('year');
                const departS = depart.format((currentYear ? DateTimeUtil.dayMonthFormat() : DateTimeUtil.dateFormat()) + " " + DateTimeUtil.timeFormat() + (showGMT ? " " + DateTimeUtil.toGMTString(depart) : ""))
                const provider = providers?.find(provider => provider.id === booking.mode);
                const summary = (
                    <div className={classNames(genClassNames.flex, genClassNames.alignCenter, genClassNames.wrap)}>
                        <div className={classNames(genClassNames.flex, genClassNames.alignCenter, genClassNames.wrap)} style={{ marginRight: '8px' }}>
                            <IconFromTo className={classes.iconFromTo} />
                            {LocationUtil.getMainText(booking.from)}
                            <IconArrowRight style={{ flexShrink: 0 }} />
                            {LocationUtil.getMainText(booking.to)}
                        </div>
                        <div className={classNames(genClassNames.flex, genClassNames.alignCenter)} style={{ marginRight: '8px' }}>
                            <IconClock className={classes.iconClock} />
                            {departS}
                        </div>
                        {provider &&
                            <div className={classNames(genClassNames.flex, genClassNames.alignCenter)}>
                                <img className={appClasses.modeIcon} style={{ opacity: 0.7, marginRight: '7px' }}
                                    src={TransportUtil.getTransportIconFromProvider(provider)} alt="" />
                                {provider.modeName}
                            </div>}
                    </div>
                );
                return (
                    <a
                        className={appClasses.detailLinkIconOnHover}
                        href={`#${appPathUpdate({ path: `/trans/all/id/${booking.id}` })}`}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                    >
                        {summary}
                        <IconExternalLink />
                    </a>
                );
            },
            available: adminProfile.features.priceChanges21702 && tableId.includes("-price-change-requests"),
            width: 300
        },
        {
            id: "initiative",
            name: i18n.t("Initiative"),
            available: adminProfile.features.trackTripInitiative22828,
            cellValue: booking => {
                return booking.initiative?.title;
            },
            width: 140
        },
        {
            id: "edit",     // I don't want this to be controlled by table config, think how to exclude from there.
            name: "Edit",
            label: null,
            cellValue: (booking: Transaction) =>
                <IconEdit
                    className={classes.iconEdit}
                    onClick={(e) => {
                        onEdit?.(booking);
                        e.stopPropagation();
                    }}
                />,
            visible: !!onEdit
        }
    ];

    return (
        <div className={classes.tableContainer}>
            <Table
                tableId={tableId}
                contentSpec={tableContent}
                defaultColumns={getDefaultColumns(tableId, adminProfile)}
                items={values}
                tableClass={appClasses.scrollX}
                onClick={onSelect ? booking => onSelect(booking) : undefined}
                configurable={showConfigBtn}
            />
        </div>
    );
}

export default withStyles(BookingsTable, bookingTableJss);