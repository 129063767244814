import React, { useEffect } from "react";
import { default as ReactDatePicker } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse } from "date-fns";
import "./DatePicker.css";
import UIUtil from "../util/UIUtil";
import DateTimeUtil from "../util/DateTimeUtil";

interface IProps {
    value: string;
    onChange: (value: string) => void;
    name?: string;
    className?: string;
    inputRef?: (ref: any) => void;
    disabled?: boolean;
}

const dateInputSupported = UIUtil.inputTypeSupported("date");

const DatePicker: React.FunctionComponent<IProps> = ({ value, onChange, name, className, disabled, inputRef }) => {
    const [inputValue, setInputValue] = React.useState<string>(value);
    useEffect(() => {
        if (value !== inputValue) {
            setInputValue(value);
        }
    }, [value]);
    function handleInputChange(value: string) {
        setInputValue(value);
        const valueMoment = DateTimeUtil.momentDefaultTZ(value);
        if (valueMoment.isValid()) {
            onChange(value);
        }
    }
    return dateInputSupported ?
        <input
            type={"date"}
            ref={inputRef}
            value={inputValue}
            onChange={(event) => handleInputChange(event.target.value)}
            name={name}
            className={className}
            disabled={disabled}
            onBlur={() => setInputValue(value)}
        /> :
        <ReactDatePicker
            selected={parse(value, 'yyyy-MM-dd', new Date())}
            onChange={(value: Date) => {
                let formattedDate;
                try {
                    formattedDate = format(value, 'yyyy-MM-dd');
                } catch (e) {
                    // If typed date is invalid, then restore the previous date.
                    formattedDate = value;
                }
                onChange(formattedDate);
            }}
            dateFormat={'dd/MM/yyyy'}
            name={name}
            disabled={disabled}
        />

}

export default DatePicker;