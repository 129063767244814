import React, { useRef, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import { editBookingInitiativeViewJss } from "./EditBookingInitiativeView.jss";
import Loading from "../view/Loading";
import SideView from "../view/SideView";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import withAsycnData from "../data/WithAsyncData";
import { ValidatorForm } from "react-form-validator-core";
import UIUtil from "../util/UIUtil";
import { EDIT_BOOKING_INITIATIVE_VIEW_PATH } from "./TransactionsViewConstants";
import TransactionsData from "../data/TransactionsData";
import Transaction from "../model/Transaction";
import { DefaultSelect } from "../rewards/StatusSelect";
import Util from "../util/Util";
import UsersData from "../data/UsersData";
import { i18n } from "../i18n/TKI18nConstants";
import { useInitiativeOptions } from "../data/InitiativesData";

type IStyle = ReturnType<typeof editBookingInitiativeViewJss>;

interface IProps extends WithClasses<IStyle> {
    booking?: Transaction;
    onRequestClose: (updateData?: { booking: Transaction }) => void;
}

const EditBookingInitiativeView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { booking = new Transaction(), onRequestClose, classes, appClasses } = props; // booking is never undefined since we assume renderWhenData is true    
    const [waiting, setWaiting] = useState<boolean>(false);
    const [update, setUpdate] = useState<Transaction>(booking);
    const formRef = useRef<any>(null);
    const initiativeOptions = useInitiativeOptions(booking.clientId);
    return (
        <SideView
            title={"Edit Booking Initiative"}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        <div className={appClasses.formGroup}>
                            <label>{i18n.t("Initiative")}</label>
                            {/* <div className={classNames(appClasses.value, classes.selectValue)}> */}
                            <div className={classNames(appClasses.value)}>
                                <DefaultSelect
                                    options={initiativeOptions ?? []}
                                    value={update.initiative?.id}
                                    onChange={value => {
                                        setUpdate(Util.iAssign(update, { initiative: initiativeOptions?.find(io => io.value === value)?.initiative }));
                                    }}
                                    isSearchable
                                    isDisabled={!initiativeOptions}
                                    allowNoValue={true}
                                    allOptionsLabel={"None"}
                                    placeholder="None"
                                    forFilter={true}
                                />
                            </div>
                        </div>
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        Cancel
                    </button>
                    <button onClick={() => {
                        formRef.current.isFormValid(false)
                            .then((valid: boolean) => {
                                if (valid) {
                                    onRequestClose({ booking: update });
                                    setWaiting(true);
                                }
                            })
                    }} className={appClasses.buttonAdd}>
                        Save
                    </button>
                </div>
            </div >
        </SideView >
    );
};

const EditBookingInitiativeViewStyled = withStyles(EditBookingInitiativeView, editBookingInitiativeViewJss);

const EditBookingInitiativeViewData = withAsycnData(EditBookingInitiativeViewStyled,
    (query: { bookingId: string }) => TransactionsData.instance.get(query.bookingId)
        .then((booking: Transaction | undefined) => ({ booking }))
        .catch((error: any) => Promise.resolve({ error })) as Promise<{ booking?: Transaction, error?: Error }>);

// noinspection JSUnusedLocalSymbols
export const EDIT_BOOKING_INITIATIVE_VIEW: IViewRouteConfig<{ bookingId: string }> =
{
    path: EDIT_BOOKING_INITIATIVE_VIEW_PATH,
    propsFromMatch: (match: any) => {
        return ({ bookingId: match.params.bookingId });
    },
    propsToPath: ({ bookingId }) => `/editInitiative/${bookingId}`,
    navLabel: () => "Edit initiative",
    render: ({ viewProps, navHistory }) => {
        return (
            <EditBookingInitiativeViewData
                bookingId={viewProps.bookingId}
                onRequestClose={updateData => {
                    if (updateData) {
                        const { booking } = updateData;
                        TransactionsData.instance.update(booking!)
                            .then(() => {
                                TransactionsData.instance.invalidateTransCache();
                                booking.relatedBookings.forEach(rb =>
                                    TransactionsData.instance.invalidateTransCacheById(rb.bookingId));
                                booking.userId && UsersData.instance.invalidateUserCache(booking.userId);
                            })
                            .catch(UIUtil.errorMessage)
                            .finally(() => {
                                navHistory.pop();
                            })
                    } else {
                        navHistory.pop();
                    }
                }}
                renderWhenData={true}
            />
        );
    },
    isModal: true
};