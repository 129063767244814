import React, { useContext, useEffect, useMemo, useState } from "react";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTheme } from 'react-jss';
import { useAppGlobalStyles } from "../css/app.jss";
import classnames from "classnames";
import TableConfigView from "./TableConfigView";
import { ReactComponent as IconTableSettings } from "../images/ic-table-setting.svg";
import { WithClasses, withStylesO } from "../css/StyleHelper";
import { Theme } from "../css/Theme";
import { resetJss } from "../css/gen.jss";
import { AppContext } from "../app/App";
import { Subtract } from "utility-types";
import Util from "../util/Util";

const tableJss = (theme: Theme) => ({
    tableSettingsBtn: {
        ...resetJss.button,
        '& svg': {
            height: '20px',
            width: '20px'
        }
    },
    stickyHeader: {
        '& th': {
            position: 'sticky',
            // top: 0,
            top: '-10px',
            zIndex: 1,  // This requires a higher z-index in transHeader class.
            background: 'white',
        }
    }
})

type IStyle = ReturnType<typeof tableJss>;
export interface ITableColSpec<T, ID = string> {
    id: ID;
    name: string;
    available?: boolean;    // default: true
    visible?: boolean;
    label?: React.ReactNode;
    cellValue: (item: T) => React.ReactNode;
    cellClass?: string | ((item: T) => string | undefined);
    cellStyle?: any | ((item: T) => any);
    cellProps?: any | ((item: T) => any);
    cellColSpan?: number | ((item: T) => number | undefined);
    cellRowSpan?: number | ((item: T) => number | undefined);
    width?: number | string;
    class?: string;
    style?: any;
    colSpan?: number;
}
interface IProps<T, ID> extends WithClasses<IStyle> {
    tableId: string;
    contentSpec: ITableColSpec<T, ID>[];
    defaultColumns?: ID[];
    items: T[];
    onClick?: (item: T) => void;
    rowClass?: string | ((defaultClass) => string);
    rowProps?: any | ((item: T) => any);
    tableClass?: string;
    /**
     * If undefined, the config button is not displayed, but space is reserved for it.
     */
    configurable?: boolean;
}

function Table<T, ID extends string>(props: IProps<T, ID>) {
    const { tableId, contentSpec, configurable, classes } = props;
    const availableColumns = contentSpec.filter(col => col.available !== false).map(col => col.id);
    const defaultColumns = props.defaultColumns?.filter(dcol => availableColumns.includes(dcol)) ?? availableColumns;

    const { profile, onProfileChange } = useContext(AppContext);
    useEffect(() => {
        // Reset table config if there's a new available column, and it's among default columns, so it becomes visible to the user.
        // TODO: achieve the same in a more subtle way, so the user does completly lose that table config.
        const availabeColsLS = profile.local.tableConfigById.get(tableId)?.availableCols as ID[];
        if (configurable && availabeColsLS && defaultColumns.some(dcol => !availabeColsLS.includes(dcol))) {
            const profileUpdate = Util.deepClone(profile);
            profileUpdate.local.tableConfigById.delete(tableId);
            onProfileChange(profileUpdate);
        }
    }, []);
    const visibleColumnsLS = useMemo(() => (profile.local.tableConfigById.get(tableId)?.visibleCols as ID[])
        ?.filter(scol => availableColumns.includes(scol)), [profile]);
    const visibleColumns = visibleColumnsLS ?? defaultColumns;
    let visibleContent = visibleColumns?.map(vcol => contentSpec.find(columnSpec => vcol === columnSpec.id)!)
        .filter((colSpec: ITableColSpec<T, ID>) => colSpec.visible !== false);
    visibleContent = [
        ...Util.insertIf(configurable !== false,
            {
                id: "settings" as any,
                name: "",
                label: configurable ?
                    <button className={classes.tableSettingsBtn} onClick={() => setShowTableConfig(true)}>
                        <IconTableSettings />
                    </button> : null,
                cellValue: () => null,
                width: '10px',
                style: { paddingLeft: 0, maxWidth: '10px' }
            }),
        ...visibleContent
    ]

    const [showTableConfig, setShowTableConfig] = useState<boolean>(false);
    const tableConfig = showTableConfig &&
        <TableConfigView
            tableId={tableId}
            availableColumns={contentSpec}
            defaultColumns={defaultColumns}
            storedColumns={visibleColumnsLS}
            onRequestClose={() => setShowTableConfig(false)}
        />

    const theme = useTheme();
    const appClasses = useAppGlobalStyles({ theme: theme as any });

    return (
        <>
            <MUITable className={classnames(appClasses.table, classes.stickyHeader, props.tableClass)}>
                <TableHead>
                    <TableRow>
                        {visibleContent.map((colSpec: ITableColSpec<T, ID>, i: number) => (
                            <TableCell
                                key={i}
                                className={classnames(appClasses.field, colSpec.class)}
                                style={{ ...colSpec.style, ...colSpec.width ? { width: typeof colSpec.width === 'number' ? colSpec.width + 'px' : colSpec.width } : undefined }}
                                colSpan={colSpec.colSpan}
                            >
                                {colSpec.label !== undefined ? colSpec.label : colSpec.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.items.map((item: T, i: number) => (
                        <TableRow
                            key={i}
                            onClick={() => props.onClick && props.onClick(item)}
                            hover={!!props.onClick}
                            className={props.rowClass !== undefined ?
                                (props.rowClass instanceof Function ? props.rowClass(appClasses.row) : props.rowClass)
                                : classnames(appClasses.row, props.onClick && appClasses.clickableRow)}
                            {...(props.rowProps ? (typeof props.rowProps === 'function' ? props.rowProps(item) : props.rowProps) : {})}
                        >
                            {visibleContent.map((colSpec: ITableColSpec<T, ID>, i: number) => (
                                <TableCell
                                    className={classnames(appClasses.cell,
                                        colSpec.cellClass ? (typeof colSpec.cellClass === 'function' ? colSpec.cellClass(item) : colSpec.cellClass) : undefined
                                    )}
                                    style={colSpec.cellStyle ? (typeof colSpec.cellStyle === 'function' ? colSpec.cellStyle(item) : colSpec.cellStyle) : undefined}
                                    key={i}
                                    colSpan={colSpec.cellColSpan ? (typeof colSpec.cellColSpan === 'number' ? colSpec.cellColSpan : colSpec.cellColSpan(item)) : undefined}
                                    rowSpan={colSpec.cellRowSpan ? (typeof colSpec.cellRowSpan === 'number' ? colSpec.cellRowSpan : colSpec.cellRowSpan(item)) : undefined}
                                    {...(colSpec.cellProps ? (typeof colSpec.cellProps === 'function' ? colSpec.cellProps(item) : colSpec.cellProps) : {})}
                                >
                                    {colSpec.cellValue(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </MUITable>
            {tableConfig}
        </>
    );
}

// Need to do this to make withStyles exprted component to have the same genericity features than the original Table
// Notice that `export default Table` worked well, but adding withStyles broke genericity.
// export default withStyles<IProps<any, any>, IStyle>(Table, tableJss);
const TableWithStyle = withStylesO<IProps<any, any>, IStyle>(Table, tableJss);
function GenTable<T, ID extends string>(props: Subtract<IProps<T, ID>, WithClasses<IStyle>>) {
    return <TableWithStyle {...props} />
}
export default GenTable;