import { JsonObject, JsonProperty } from "json2typescript/src/json2typescript/json-convert-decorators";
import Initiative from "./Initiative";

export const ORGANIZATION_ID_FIELD = "Id";
export const ORGANIZATION_NAME_FIELD = "name";
export const ORGANIZATION_CLIENT_ID_FIELD = "clientId";
export const ORGANIZATION_PATH_FIELD = "path";
export const ORGANIZATION_PARENT_ID_FIELD = "parentId";
export const ORGANIZATION_IS_CLIENT_APP_FIELD = "isClientApp";
export const ORGANIZATION_INITIATIVES_FIELD = "initiatives";
export const ORGANIZATION_INITIATIVE_IDS_FIELD = "initiativeIds";

@JsonObject
class Organization {
    @JsonProperty(ORGANIZATION_ID_FIELD, String)
    public readonly id: string = "";
    @JsonProperty(ORGANIZATION_NAME_FIELD, String, true)
    public readonly name: string = "";
    @JsonProperty(ORGANIZATION_CLIENT_ID_FIELD, String, true)
    public readonly clientId?: string = undefined;
    @JsonProperty(ORGANIZATION_PATH_FIELD, String, true)
    public readonly path: string = "";
    @JsonProperty(ORGANIZATION_IS_CLIENT_APP_FIELD, Boolean, true)
    private _isClientApp?: boolean = undefined;
    @JsonProperty(ORGANIZATION_INITIATIVES_FIELD, [Initiative], true)
    public initiatives: Initiative[] = [];

    public get isClientApp(): boolean {
        return this._isClientApp ?? false;
    }
    public set isClientApp(value: boolean) {
        this._isClientApp = value;
    }

    public get isRoot(): boolean {
        return this.path.length === 4;
    }

    /**
     * Derived from the tree representation of the organizations and the list of organizations
     * the admin user has access to: those organizations that are listed as accessible by the admin,
     * or are descendents of those.
     */
    public fullAccess?: boolean;

}

export default Organization;