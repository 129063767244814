import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const organizationsTableJss = (theme: Theme) => ({
    iconEdit: {
        ...genJss.svgPathFillCurrColor,
        height: '16px',
        width: '16px',
        cursor: 'pointer',
        color: theme.colorPrimary
    },
    nameCell: {
        '& path, polygon': {
            fill: 'gray'
        }
    },
    orgsCell: {
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '140px',
        // whiteSpace: 'nowrap!important',
        '&td': {
            whiteSpace: 'nowrap!important',
        }
    }
});