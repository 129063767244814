import React from "react";
import { Subtract } from "utility-types";
import { Observable } from 'rxjs';

interface IWithAsyncDataState<T> {
    data?: T;
}

interface WithAsyncDataProps<SP> {
    shouldRefresh?: (prevProps: SP, props: SP) => boolean;
    shouldUndefineOnUpdate?: (prevProps: SP, props: SP) => boolean;
    // deprecated
    undefineOnUpdate?: boolean;
    renderWhenData?: boolean;
    justData?: boolean;
}

/**
 * HOC that receives a component and a requester function that maps some synchronous props SP (generally coming from
 * the url, e.g. a filter object, or an item ID) to (a promise of) asynchronous props AS (generally data fetched from
 * the sync props), and returns a component that receives SP props, maintains an state with AS props (initially all
 * undefined) calculated using the requester function, and injects AS props to the component.
 * AS props must all be optional: they are computed asynchronously, so at first they will be undefined.
 *
 * @param {React.ComponentType<CP extends AP>} Consumer receives CP after mapping SP to AP.
 * @param {(query: SP) => Promise<AP>} requester maps SP to AP, asynchronously.
 * @returns WithAsyncData component that receives as properties (CP - AP) + SP
 */

function withAsycnData<SP, AP extends object, CP extends AP & { refresh?: (undefineOnUpdate?: boolean) => Promise<void> }>(Consumer: React.ComponentType<CP>, requester: (query: SP) => Promise<AP>) {
    return withAsyncDataObs(Consumer, (query: SP) => {
        return new Observable<object>(subscriber => {
            requester(query).then((data: AP) => {
                subscriber.next(data);
            })
        });
    })
}

/**
 * Idem withAsycnData, but the requester returns a rxjs Observable instead of a Promise, in case the AS properties can
 * suffer updates for the same SP props.
 */

function withAsyncDataObs<SP, AP extends object, CP extends AP & { refresh?: (undefineOnUpdate?: boolean) => Promise<void> }>(
    Consumer: React.ComponentType<CP>,
    requester: (query: SP) => Observable<AP>
) {

    return class WithAsyncData extends React.Component<Subtract<CP, AP> & SP & WithAsyncDataProps<SP>, IWithAsyncDataState<AP>> {

        public static defaultProps = {
            undefineOnUpdate: true,
            shouldRefresh: (prevProps: SP, props: SP) => props !== prevProps
        };

        private subscription;

        constructor(props: Subtract<CP, AP> & SP & WithAsyncDataProps<SP>) {
            super(props);
            this.state = {};
            this.shouldUndefineOnUpdate = this.shouldUndefineOnUpdate.bind(this);
            this.refresh = this.refresh.bind(this);
        }

        private shouldUndefineOnUpdate(prevProps: SP, props: SP): boolean {
            // noinspection JSPotentiallyInvalidUsageOfThis
            return this.props.shouldUndefineOnUpdate ?
                this.props.shouldUndefineOnUpdate(prevProps, props) :
                this.props.undefineOnUpdate!;
        }

        /**
         * This method is now sent to the consumer as a prop, so it can trigger / force a refresh
         * if it knows the (async) data may have changed. Return a promise so the consumer can
         * show a waiting state until the refresh finishes.
         */
        public refresh(undefineOnUpdate?: boolean): Promise<void> {
            const nextPromise = new Promise<void>((resolve, reject) => {
                if (undefineOnUpdate) {
                    this.setState({ data: undefined });
                }
                if (this.subscription) {
                    this.subscription.unsubscribe();
                }
                const self = this;
                this.subscription = requester(this.props).subscribe({
                    next(data: AP) {
                        self.setState({
                            data: data
                        });
                        resolve();
                    }
                });
            });
            return nextPromise;
        }

        public render(): React.ReactNode {
            const consumerProps = { ...this.props, ...this.state.data, refresh: this.refresh } as CP;
            if (this.props.justData) {
                return null;
            }
            return (!this.props.renderWhenData || this.state.data) ? <Consumer {...consumerProps} /> : null
        }

        public componentDidMount(): void {
            this.refresh();
        }

        public componentDidUpdate(prevProps: SP): void {
            if (this.props.shouldRefresh!(prevProps, this.props)) {
                this.refresh(this.shouldUndefineOnUpdate!(prevProps, this.props));
            }
        }

        public componentWillUnmount() {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        }
    }
}

export default withAsycnData;
export { withAsyncDataObs };