import * as CSS from 'csstype';
import {black, genJss} from "./gen.jss";

export interface Theme {
    colorPrimary: CSS.Color;
    colorSecondary: CSS.Color;
    colorSuccess: CSS.Color;
    colorInfo: CSS.Color;
    colorWarning: CSS.Color;
    colorError: CSS.Color;
    fontFamily: CSS.FontFamilyProperty;
    textColorDefault: CSS.Properties;
    textColorGray: CSS.Properties;
    textColorDisabled: CSS.Properties;

    textSizeBody: CSS.Properties;
    textSizeCaption: CSS.Properties;

    textWeightRegular: CSS.Properties;
    textWeightMedium: CSS.Properties;
    textWeightSemibold: CSS.Properties;
    textWeightBold: CSS.Properties;

    divider: CSS.Properties;
}

export const defaultTheme: Theme = {
    colorPrimary: '#2377CB',
    colorSecondary: '#592c82',
    colorSuccess: '#23b15e',
    colorInfo: '#2b7eed',
    colorWarning: '#fcba1e',
    colorError: '#e34040',

    fontFamily: 'sans-serif',

    textColorDefault: {
        color: black()
    },
    textColorGray: {
        color: black(1)
    },
    textColorDisabled: {
        color: black(2)
    },

    textSizeBody: genJss.fontM,
    textSizeCaption: genJss.fontS,

    textWeightRegular: {
        fontWeight: 'normal'    // 400
    },
    textWeightMedium: {
        fontWeight: 500
    },
    textWeightSemibold: {
        fontWeight: 600
    },
    textWeightBold: {
        fontWeight: 700
    },
    divider: {
        borderBottom: '1px solid ' + black(4)
    }
};