import React, { useMemo } from 'react';
import { Classes } from "jss";
import { appGlobalJss, useAppGlobalStyles } from "./app.jss";
import { Subtract } from "utility-types";
import { useTheme, createUseStyles, Styles } from 'react-jss';
import { Theme } from "./Theme";
import Util from '../util/Util';
import * as CSS from 'csstype';

export interface WithClasses<S> extends WithStyles<S> {
    injectedStyles: Styles<keyof S, any>;
    classes: Classes<Extract<keyof S, string>>;  // More details on Extract: https://stackoverflow.com/a/51808262
    appClasses: Classes<keyof ReturnType<typeof appGlobalJss>>;
}

export interface WithStyles<S> {
    styles?: StyleOverride<S>
}

export function withStyles<PROPS extends WithClasses<STYLE>, STYLE>(
    Consumer: React.ComponentType<PROPS>,
    stylesJss: (theme: Theme) => STYLE) {
    const useStyles = createUseStyles(stylesJss);
    // Trying to make dynamic refresh work without success. See BookingsView.jss.ts
    // const useStyles = createUseStyles(stylesJss, {link: true});
    return (props: Subtract<PROPS, WithClasses<STYLE>>) => {
        const theme = useTheme();
        const classes = useStyles({ ...props, theme: theme as any });
        const injectedStyles = stylesJss(theme as any) as any;
        const appClasses = useAppGlobalStyles({ theme: theme as any });
        const consumerProps = { ...props, classes, injectedStyles, appClasses } as PROPS; // See why I need to do this.        
        return <Consumer {...consumerProps} />;
    };
}

type FcValues<PR> = ((props: PR) => CSS.Properties);
type CSSProperties<PR> = FcValues<PR>;
export type CSSProps<Props> = CSS.Properties | CSSProperties<Props>;
export type CSSPropertiesCreator<Props> = ((defaultStyle: CSSProps<Props>) => CSSProps<Props>);
export type TKUICustomCSSProperties<Props> = CSSProps<Props> | CSSPropertiesCreator<Props>;
export type CustomStyles<ClassKey extends string | number | symbol = string, Props = {}> = Record<
    ClassKey,
    TKUICustomCSSProperties<Props>
>;
export type StyleOverride<STYLE> = ((theme: Theme, defaultStyle: CustomStyles<keyof STYLE, any>) => Partial<CustomStyles<keyof STYLE, any>>) | Partial<CustomStyles<keyof STYLE, any>>;

export function overrideStyles<STYLE>(
    defaultStyle: (theme: Theme) => STYLE,
    override: StyleOverride<STYLE>
): (theme: Theme) => STYLE {
    // ): (theme: Theme) => any {
    return (theme: Theme) => {
        const defaultStyleObj = defaultStyle(theme);
        const overrideObject = Util.isFunction(override) ? (override as any)(theme, defaultStyleObj) : override;
        return { ...defaultStyleObj, ...overrideObject };
    }
}

export function useStyles<
    PROPS extends { styles?: StyleOverride<STYLE> },
    STYLE,
    OUTPROPS = PROPS & WithClasses<STYLE>
>(props: PROPS, stylesJss: (theme: Theme) => STYLE): OUTPROPS {
    const theme = useTheme();
    const resultStyles = props.styles ? overrideStyles(stylesJss, props.styles) : stylesJss;
    const useStylesJSS = useMemo(() => createUseStyles<Extract<keyof STYLE, string>, PROPS, Theme>(resultStyles as ((theme: Theme) => Styles<Extract<keyof STYLE, string>, PROPS>)), []);
    const classes = useStylesJSS({ ...props, theme: theme as any });
    const injectedStyles = resultStyles(theme as any) as any;
    const appClasses = useAppGlobalStyles({ theme: theme as any });
    const consumerProps = { ...props, classes, injectedStyles, appClasses } as OUTPROPS; // See why I need to do this.
    return consumerProps;
}

export function withStylesO<PROPS extends WithClasses<STYLE>, STYLE>(
    Consumer: React.ComponentType<PROPS>,
    stylesJss: (theme: Theme) => STYLE
) {
    // Trying to make dynamic refresh work without success. See BookingsView.jss.ts
    // const useStyles = createUseStyles(stylesJss, {link: true});
    return (props: Subtract<PROPS, WithClasses<STYLE>> & { styles?: StyleOverride<STYLE> }) => {
        const theme = useTheme();
        const resultStyles = props.styles ? overrideStyles(stylesJss, props.styles) : stylesJss;
        const useStyles = useMemo(() => createUseStyles(resultStyles), []);
        const classes = useStyles({ ...props, theme: theme as any });
        const injectedStyles = resultStyles(theme as any) as any;
        const appClasses = useAppGlobalStyles({ theme: theme as any });
        const consumerProps = { ...props, classes, injectedStyles, appClasses } as PROPS; // See why I need to do this.        
        return <Consumer {...consumerProps} />;
    };
}

export function withProps<PROPS extends ADD_PROPS, ADD_PROPS extends object>(
    Consumer: React.ComponentType<PROPS>,
    addProps: ADD_PROPS): React.SFC<Subtract<PROPS, ADD_PROPS>> {
    return (props: Subtract<PROPS, ADD_PROPS>) => {
        const consumerProps = { ...props, ...addProps } as any;
        return <Consumer {...consumerProps} />;
    }
}