import React, { useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import ReactTimePicker from 'react-time-picker';
import "./TimePicker.css";
import UIUtil from "../util/UIUtil";
import DateTimeUtil from "../util/DateTimeUtil";

interface IProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
    inputRef?: (ref: any) => void;
}

const dateInputSupported = UIUtil.inputTypeSupported("time");

const TimePicker: React.FunctionComponent<IProps> = ({ value, onChange, className, inputRef }) => {
    const [inputValue, setInputValue] = React.useState<string>(value);
    useEffect(() => {
        if (value !== inputValue) {
            setInputValue(value);
        }
    }, [value]);
    function handleInputChange(value: string) {
        setInputValue(value);
        const valueMoment = DateTimeUtil.momentDefaultTZ(value);
        if (valueMoment.isValid()) {
            onChange(value);
        }
    }
    return dateInputSupported ?
        <input type={"time"}
            ref={inputRef}
            value={inputValue}
            onChange={(event) => handleInputChange(event.target.value)}
            onBlur={() => setInputValue(value)}
            className={className}
        /> :
        <ReactTimePicker
            value={value}
            onChange={(value) => onChange(value)}
            disableClock={true}
            clearIcon={null}
            className={className}
        />
}

export default TimePicker;