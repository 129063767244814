import React, { useContext, useEffect, useState } from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Util from "../util/Util";
import { genJss } from "../css/gen.jss";
import DateTimeUtil from "../util/DateTimeUtil";
import { BOOKING_DETAIL_VIEW_PATH, TRANSACTIONS_VIEW_PATH } from "./TransactionsViewConstants";
import { bookingViewId, locIconStyleOverride } from "./BookingViewHelpers";
import { TKRoot, TKLocation, overrideClass, TripGoApi, TKUserProfile, TKUtil, TKDefaultGeocoderNames } from "tripkit-react";
import { adminProfile } from "../account/AdminProfile";
import { AppContext, appPathUpdate, geocodingConfig, mapConfig } from "../app/App";
import { Theme } from "../css/Theme";
import RoutingQuery, { TimePreference } from "tripkit-react/dist/model/RoutingQuery";
import User from "../model/User";
import { tKUIButtonDefaultStyle } from "tripkit-react/dist/buttons/TKUIButton.css";
import { appGlobalJss } from "../css/app.jss";
import TransactionsData from "../data/TransactionsData";
import { PART_DETAIL_VIEW_PATH } from "../user/UserViewConstants";
import withAsycnData from "../data/WithAsyncData";
import UsersData from "../data/UsersData";
import UIUtil from "../util/UIUtil";
import GQLError from "../util/GQLError";
import { i18n } from "../i18n/TKI18nConstants";
import Transaction, { ConfirmationInput, TransType } from "../model/Transaction";
import LocationUtil from "../util/LocationUtil";
import TKLocationUtil from "tripkit-react/dist/util/LocationUtil";
import { ReactComponent as IconExternalLink } from "../images/ic-external-link.svg";
import FavouriteLocation from "tripkit-react/dist/model/favourite/FavouriteLocation";
import { TKFavouritesContext } from "tripkit-react/dist/favourite/TKFavouritesProvider";
import { SignInStatus, TKAccountContext } from "tripkit-react/dist/account/TKAccountContext";
import TKUserAccount from "tripkit-react/dist/account/TKUserAccount";
import { Subtract } from "utility-types/dist/mapped-types";
import { requestUserToken } from "../user/FavoritesView";
import StaticGeocoder from "tripkit-react/dist/geocode/StaticGeocoder";
import { ReactComponent as IconFavourite } from "../images/ic-favorite.svg";
import { ReactComponent as IconRecent } from "../images/ic-recent.svg";
import { useProviders } from "../data/ProvidersData";
import { useTheme } from "react-jss";
import NewCreateBookingView from "./NewCreateBookingViewComp";
import { CreateOptions, FAVORITES_GEOCODER_ID, NewCreateBookingViewProps, RECENT_GEOCODER_ID } from "./NewCreateBookingViewHelpers";

const ONE_WAY_ONLY = ConfirmationInput.ONE_WAY_ONLY;

const FavoritesDataUpdater = ({ user }: { user?: User }) => {
    const { favouriteList, isLoadingFavourites } = useContext(TKFavouritesContext);
    const [recentLocations, setRecentLocations] = useState<TKLocation[] | undefined>(undefined);
    useEffect(() => {
        if (user) {
            TransactionsData.instance.getBookings({
                userId: user.id,
                type: TransType.BOOKING,
                dateInit: DateTimeUtil.getNowDate().add(-30, 'days').valueOf() / 1000,
                dateEnd: DateTimeUtil.getNowDate().add(90, 'days').valueOf() / 1000,
                sortAsc: false,
                limit: 15
            })
                .then(bookings => {
                    const recentValues = bookings.reduce((acc, booking) => {
                        acc.push(booking.from);
                        acc.push(booking.to);
                        return acc;
                    }, [] as TKLocation[]);
                    const recentNoDuplicates: TKLocation[] = [];
                    recentValues.forEach(value => {
                        if (!recentNoDuplicates.some(v => v.address === value.address)) {
                            recentNoDuplicates.push(value);
                        }
                    });
                    setRecentLocations(recentNoDuplicates);
                });
        } else {
            setRecentLocations(undefined);
        }
    }, [user]);
    useEffect(() => {
        if (!isLoadingFavourites && recentLocations) {
            const favoriteLocations = favouriteList
                .filter(fav => fav instanceof FavouriteLocation)
                .map(fav => {
                    // Create a copy of the favorite location, specially since setting its name. If fav location has the fav name
                    // the location input box on favorite editing view will display the (favorite) name instead of the address.
                    const favLocation = TKUtil.deepClone((fav as FavouriteLocation).location);
                    favLocation.suggestion = fav;
                    if (fav.name && favLocation.address && !favLocation.address.startsWith(fav.name)) {
                        favLocation.name = fav.name;
                    }
                    return favLocation;
                });
            favoriteLocations.sort((a, b) => recentLocations.some(loc => loc.address === a.address) ? -1 : recentLocations.some(loc => loc.address === b.address) ? 1 : 0);
            favoritesGeocoder.setValues(favoriteLocations);
            recentGeocoder.setValues(recentLocations);
        } else {
            recentGeocoder.setValues([]);
            favoritesGeocoder.setValues([]);
        }
    }, [isLoadingFavourites, recentLocations, favouriteList]);
    return null;
}

const favoritesGeocoder = new StaticGeocoder({
    emptyMatchAll: true,
    resultsLimit: 5,
    renderIcon: (loc) => loc.source === FAVORITES_GEOCODER_ID ? <IconFavourite /> : <IconRecent />
});
const recentGeocoder = new StaticGeocoder({
    emptyMatchAll: true,
    resultsLimit: 5,
    renderIcon: () => <IconRecent />
});

const compare = (l1: TKLocation, l2: TKLocation, query: string) => {

    if (!query) {
        // Put current location at the top
        if (l1.source === TKDefaultGeocoderNames.geolocation) {
            return -1;
        } else if (l2.source === TKDefaultGeocoderNames.geolocation) {
            return 1;
        }
    }

    // Then favorites
    if (l1.source === FAVORITES_GEOCODER_ID) {
        return -1;
    } else if (l2.source === FAVORITES_GEOCODER_ID) {
        return 1;
    }

    // Then recents
    if (l1.source === RECENT_GEOCODER_ID) {
        return -1;
    } else if (l2.source === RECENT_GEOCODER_ID) {
        return 1;
    }

    if (l1.source === TKDefaultGeocoderNames.skedgo) {
        return -1;
    } else if (l2.source === TKDefaultGeocoderNames.skedgo) {
        return 1
    }

    const relevanceDiff = TKLocationUtil.relevance(query, l2) - TKLocationUtil.relevance(query, l1);

    // Prioritize skedgo geocoder result if
    // - query has 3 or more characters, and
    // - query is related to "airport" or "stop", and
    // - relevance is not less than 0.1 from other source result
    if (query.length >= 3 &&
        (TKLocationUtil.relevanceStr(query, "airport") >= .7 ||
            TKLocationUtil.relevanceStr(query, "stop") >= .7)) {
        if (l1.source === TKDefaultGeocoderNames.skedgo && relevanceDiff <= 0.1) {
            return -1;
        } else if (l2.source === TKDefaultGeocoderNames.skedgo && relevanceDiff >= -0.1) {
            return 1
        }
    }

    if (relevanceDiff !== 0) {
        return relevanceDiff;
    }

    return 0;
};

const geocoding = () => {
    const result = geocodingConfig();
    result.geocoders = {
        ...result.geocoders,
        [FAVORITES_GEOCODER_ID]: favoritesGeocoder,
        [RECENT_GEOCODER_ID]: recentGeocoder,
    };
    result.maxResults = 10;
    result.compare = compare;
    return result;
};

const NewCreateBookingViewWithRoot = (props: Subtract<NewCreateBookingViewProps, { setUser?: (value?: User) => void }>) => {
    const defaultUserProfile = new TKUserProfile();
    defaultUserProfile.routingQueryParams = { groupDRT: adminProfile.features.providerMobilityOptions22827 };
    const resetUserProfile = true;
    const theme = useTheme() as Theme;
    const config = {
        apiServer: adminProfile.appMetadata!.tripgoApiUrl,
        apiKey: adminProfile.appMetadata!.tripgoApiKey,
        i18n: {
            locale: adminProfile.locale,
            translations: {} as any     // TODO: support translations as in tripgo-web/src/white_labels/feonix/src/app/TripPlannerApp.tsx
        },
        TKUIMapView: {
            props: () => mapConfig()
        },
        TKUIMapLocationIcon: {
            styles: locIconStyleOverride
        },
        geocoding,
        theme: {
            colorPrimary: theme.colorPrimary,
            fontFamily: theme.fontFamily
        },
        isDarkMode: false,
        defaultUserProfile,
        resetUserProfile,
        computeModeSetsBuilder: defaulFunction =>
            (query: RoutingQuery, options: TKUserProfile) => {
                let modes = defaulFunction(query, options);
                modes = modes.filter(modes => modes.length === 1);  // Filter out multi-modal (for now).
                if (adminProfile.role === "tsp_admin") {
                    const tspMode = adminProfile.tspMode ?? "ps_drt";
                    modes = modes
                        .map(modeSet => modeSet.map(mode => mode === "ps_drt" ? tspMode : mode))    // replace ps_drt by tsp mode
                        .filter(modeSet => modeSet.some(mode => mode === tspMode))                  // remove all sets not containing the tspMode
                        .map(modeSet => modeSet.filter(mode => !mode.startsWith("ps_drt") || mode === tspMode)) // remove from the multi-modal all "ps_drt_X" that are not tspMode
                } else {
                    modes = modes.filter(modeSet => modeSet.some(mode => mode.startsWith("ps_drt")));  // remove all sets not containing "ps_drt"
                }
                return modes;
            },
        TKUIButton: {
            styles: buttonTheme => ({
                main: {},
                primary: appGlobalJss(theme).buttonAdd,
                secondary: appGlobalJss(theme).buttonCancel,
                link: defaultStyle => ({
                    ...tKUIButtonDefaultStyle(buttonTheme).main,
                    ...defaultStyle
                })
            })
        },
        TKUIFromTo: {
            props: {
                showDate: true,
                formatRelativeDay: false
            }
        },
        TKUICard: {
            styles: {
                modalContent: overrideClass({
                    width: '720px'
                })
            }
        },
        TKUIAutocompleteResult: {
            styles: {
                main: overrideClass({
                    borderBottom: 'none'
                })
            }
        }
    };
    const [signInStatus, setSignInStatus] = React.useState(SignInStatus.loading);
    const [user, setUser] = useState<User | undefined>(props.user);
    const [initLoadingP, setInitLoadingP] = useState<Promise<SignInStatus.signedIn | SignInStatus.signedOut>>(Promise.resolve(SignInStatus.signedOut));
    useEffect(() => {
        // Reset userToken if user changes.
        TripGoApi.userToken = undefined;
        if (user) {
            const userTokenRequest =
                requestUserToken({ user: user!, adminProfile })
                    .then(token => {
                        TripGoApi.userToken = token;
                        setSignInStatus(SignInStatus.signedIn);
                    });
            setInitLoadingP(userTokenRequest.then(() => SignInStatus.signedIn));
        } else {
            setSignInStatus(SignInStatus.signedOut);
            setInitLoadingP(Promise.resolve(SignInStatus.signedOut));
        }
    }, [user]);
    return (
        <TKAccountContext.Provider
            value={{
                accountsSupported: true,
                status: signInStatus,
                login: () => Promise.resolve(),
                logout: () => { },
                finishInitLoadingPromise: initLoadingP,
                resetUserToken: () => { },
                refreshUserProfile: () => Promise.resolve(new TKUserAccount())
            }}
        >
            <TKRoot config={config}>
                <NewCreateBookingView {...props} user={user} setUser={props.user ? undefined : setUser} />
                <FavoritesDataUpdater user={user} />
            </TKRoot>
        </TKAccountContext.Provider>
    );
}

const NewCreateBookingViewWithData = withAsycnData(NewCreateBookingViewWithRoot,
    ({ userId, options }: { userId?: string, options?: CreateOptions }) =>
        userId ?
            UsersData.instance.get(userId).then((user: User) => { return { user, options } }) as Promise<{ user?: User, options?: CreateOptions }> :
            Promise.resolve({ options }));

export const NEW_BOOKING_CREATE_VIEW: IViewRouteConfig<{ userId?: string, options?: CreateOptions }> =
{
    path: TRANSACTIONS_VIEW_PATH.map(path => path + "/new")
        .concat(PART_DETAIL_VIEW_PATH.map(path => path + "/newBooking")),
    propsFromMatch: match => ({ userId: match.params.id }),
    propsToPath: ({ userId }) => userId ? "/newBooking" : "/new",
    navLabel: () => i18n.t("Create.booking"),
    render: ({ viewProps, navHistory, waitFor, profile }) => {
        function close() {
            if (viewProps.options?.onRequestClose) {
                viewProps.options.onRequestClose();
            } else {
                navHistory.pop();
            }
        }
        return (
            <NewCreateBookingViewWithData
                userId={viewProps.userId}
                onRequestClose={(success, data) => {
                    TransactionsData.instance.invalidateTransCache();
                    const refreshUser = () => {
                        if (data) {
                            UsersData.instance.invalidateUserCache(data?.userId);
                        }
                    }
                    if (data?.updateURL) {
                        waitFor(TripGoApi.apiCallUrl(data.updateURL, "GET")
                            .then(TripGoApi.deserializeRoutingResults)
                            .then(results => {
                                const bookingSegment = results.groups?.[0]?.segments.find(segment => segment.booking);
                                const bookingUrl = bookingSegment?.booking?.confirmation?.actions.find(action => action.type === "CANCEL")?.internalURL;
                                // e.g./s:
                                // - https://galaxies.skedgo.com/lab/beta/satapp/booking/v1/666a66bf-c62f-462f-953d-06a49871b59a/cancel?bsb=1&psb=1
                                // - https://lepton.buzzhives.com/satapp/booking/v1/2c555c5c-b40d-481a-89cc-e753e4223ce6/update
                                const bookingId = bookingUrl?.split(/[\/\/]/).splice(-2)[0];
                                if (bookingId) {
                                    return Util.retryOnError(() => TransactionsData.instance.get(bookingId, { fetchPolicy: "network-only" }), { times: 3, delay: 3000 })
                                        .then(() => {
                                            refreshUser();
                                            // If this is the case, the current view is directly nested under a booking details view (e.g. create similar booking),
                                            // so need to pop first, so the navHistory.replaceS below replaces that booking details view.
                                            if (navHistory.viewAt(-1).id === bookingViewId) {
                                                navHistory.pop();
                                            }
                                            navHistory.replaceS("/tripId/" + bookingId);
                                        })
                                        .catch(() => {
                                            UIUtil.errorMessage(new GQLError("The booking will appear in the system soon.", undefined, true));
                                            refreshUser();
                                            close();
                                        });
                                }
                                return;
                            }));
                    } else if (data?.refreshURLForSourceObject) {
                        const bookingId = data?.refreshURLForSourceObject?.split(/[\/\/]/).splice(-2)[0];
                        bookingId && Util.retryOnError(() => TransactionsData.instance.get(bookingId, { fetchPolicy: "network-only" }), { times: 3, delay: 3000 })
                            .then(() => {
                                refreshUser();
                                // If this is the case, the current view is directly nested under a booking details view (e.g. create similar booking),
                                // so need to pop first, so the navHistory.replaceS below replaces that booking details view.
                                if (navHistory.viewAt(-1).id === bookingViewId) {
                                    navHistory.pop();
                                }
                                navHistory.replaceS("/tripId/" + bookingId);
                            })
                            .catch(() => {
                                UIUtil.errorMessage(new GQLError("The booking will appear in the system soon.", undefined, true));
                                refreshUser();
                                close();
                            });
                    } else {
                        close();
                    }
                }}
                onOpenUserDetails={user => {
                    navHistory.pushS("/userId/" + user.id);
                }}
                renderWhenData={true}
                undefineOnUpdate={false}
                options={viewProps.options}
            />
        );
    }
};

interface CreateSimilarBookingSubtitleProps extends WithClasses<ReturnType<typeof createSimilarBookingSubtitleJss>> {
    booking: Transaction
}
const createSimilarBookingSubtitleJss = (theme: Theme) => ({
    recreateSubtitle: {
        ...genJss.flex,
        ...genJss.column,
        '& > *:last-child': {
            marginTop: '10px'
        }
    },
    recreateBookingLink: {
        ...appGlobalJss(theme).detailLinkIconOnHover,
        color: theme.colorPrimary,
        textDecoration: 'none'
    },
});
export const CreateSimilarBookingSubtitle =
    withStyles<CreateSimilarBookingSubtitleProps, ReturnType<typeof createSimilarBookingSubtitleJss>>(({ booking, classes }) => {
        const timezone = booking.timezone;
        const provider = useProviders({ clientId: booking.clientId })?.find(p => p.id === booking.mode);
        return (
            <div className={classes.recreateSubtitle}>
                <div>
                    <a
                        className={classes.recreateBookingLink}
                        href={`#${appPathUpdate({ path: `/trans/all/id/${booking.id}` })}`}
                        target="_blank"
                    >
                        <div>{`From ${LocationUtil.getMainText(booking.from)} to ${LocationUtil.getMainText(booking.to)}`}</div>
                        {/* <div>{`, on ${depart.format(DateTimeUtil.dayMonthFormat())} at ${depart.format("h:mm A")}`}</div> */}
                        {provider ? <div>{`, with ${provider.modeName}`}</div> : undefined}
                        <IconExternalLink />
                    </a>
                </div>
                <div>{`by ${booking.userName}`}</div>
            </div>
        );
    }, createSimilarBookingSubtitleJss);

export function prefillBookingViewProps(booking: Transaction): CreateOptions {
    const originalQueryTime = DateTimeUtil.isoToMomentTimezone(booking.queryTime!, booking.timezone);
    const queryTime = DateTimeUtil.pastToFuture(originalQueryTime, "sameTime");
    return ({
        title: "Create Similar Booking",
        subtitle: <CreateSimilarBookingSubtitle booking={booking} />,
        // onRequestClose: () => appContext.navHistory.pop(),
        referenceBooking: booking,
        "QUERY": {
            query: RoutingQuery.create(booking.from, booking.to, booking.queryIsLeaveAfter ? TimePreference.LEAVE : TimePreference.ARRIVE, queryTime)
        },
        "TRIPS": {
            defaultMode: adminProfile.features.providerMobilityOptions22827 ? "ps_drt" : booking.mode
        },
        "BOOKING": {
            formPrefillFc(bookingForm, query) {
                const result = TKUtil.deepClone(bookingForm);
                result.input.forEach(field => {
                    if (field.id === "mobilityOptions") {
                        field.values = booking.confirmationInput?.mobilityOptions;
                    }
                    if (field.id === "purpose") {
                        field.value = booking.confirmationInput?.purpose;
                    }
                    if (field.id === "notes") {
                        field.value = booking.confirmationInput?.notes;
                    }
                    if (field.id === "returnTrip") {
                        // // TODO: address the situation where relatedBookings are still being fetched.
                        // const returnTrip = relatedBookings.find(rb => rb.type === "RETURN");
                        // // If the original trip (in canceled state) is an outbound trip with a canceled return, the field will be pre-filled with the original return trip date-time.
                        // if (returnTrip && returnTrip.booking &&
                        //     (returnTrip.booking.status === TransStatus.PROVIDER_CANCELED
                        //         || returnTrip.booking.status === TransStatus.PROVIDER_NO_SHOW
                        //         || returnTrip.booking.status === TransStatus.REJECTED
                        //         || returnTrip.booking.status === TransStatus.USER_CANCELED
                        //         || returnTrip.booking.status === TransStatus.USER_CANCELED_LATE)) {
                        //     field.value = DateTimeUtil.momentTZTime(returnTrip.booking.depart * 1000).format();
                        // } else {
                        //     // Otherwise, set to "One-way only",                                         
                        //     field.value = "One-way only";
                        // }
                        let returnTripTime = booking.returnTripDepart ?? ONE_WAY_ONLY;
                        if (returnTripTime && returnTripTime !== ONE_WAY_ONLY) {
                            returnTripTime = query.time.clone().add(DateTimeUtil.isoToMomentTimezone(returnTripTime, booking.timezone).valueOf() - originalQueryTime.valueOf(), 'ms').format();
                        }
                        field.value = returnTripTime;
                    }
                })
                // TODO: The booking (Transaction type in gql) doesn not have the "tickets" purchased with the booking.
                // Anyway, does it make sense to recreate a booking with the same tickets? Different providers may have different tickets.
                // result.tickets?.forEach(ticket => {

                // });
                return result;
            }
        },
        "PROVIDER_OPTIONS": {
            // Not used for now, since there's currently no way in TKUIBookingProviderOptions to show a provider option as the default. Anyway, the provider in the
            // original booking is indicated in the create similar booking view subtitle.
            defaultProvider: booking.mode
        },
        "PAYMENT": {
            defaultPaymentMethodFc(paymentMethods) {
                if (booking.organizationId) {
                    return paymentMethods.find(pm => pm.paymentOption.paymentMode === "INVOICE");
                }
                if (booking.paymentStatus === "WALLET") {
                    return paymentMethods.find(pm => pm.paymentOption.paymentMode === "WALLET");
                }
                else if (booking.stripeUrl) {
                    return paymentMethods.find(pm => !!pm.data?.stripePaymentMethod);
                }
                return paymentMethods[0];
            },
            defaultInvoiceOrganizationId: booking.organizationId
        }
    });
}

const CreateSimilarBookingView: React.FunctionComponent<{ booking?: Transaction }> = ({ booking }) => {
    const appContext = useContext(AppContext);
    const [returnTripDepart, setReturnTripDepart] = useState<string | undefined>(undefined);
    useEffect(() => {
        const returnTripId = booking!.relatedBookings.find(rb => rb.type === "RETURN")?.bookingId;
        if (returnTripId) {
            TransactionsData.instance.get(returnTripId).then(returnTrip => {
                setReturnTripDepart(DateTimeUtil.momentTZTime(returnTrip.depart * 1000).format());
            });
        } else {
            setReturnTripDepart(ONE_WAY_ONLY);
        }
    }, []);
    // Fill the booking with returnTripDepart value, which doesn't come from the BE anymore. Notice I need to do it on each re-render, since
    // booking is a different object on each one.
    booking!.returnTripDepart = returnTripDepart;
    const createBookingViewProps = {
        userId: booking!.userId,
        options: prefillBookingViewProps(booking!)
    };
    return NEW_BOOKING_CREATE_VIEW.render({ viewProps: createBookingViewProps, match: true, ...appContext });
}

const CreateSimilarBookingViewWithData = withAsycnData(CreateSimilarBookingView,
    async ({ bookingId }: { bookingId: string }): Promise<{ booking?: Transaction }> => {
        const booking = await TransactionsData.instance.get(bookingId);
        return Promise.resolve({ booking }) as Promise<{ booking?: Transaction }>;
    });

export const BOOKING_CREATE_SIMILAR_VIEW: IViewRouteConfig<{ bookingId: string }> =
{
    path: BOOKING_DETAIL_VIEW_PATH.map(path => path + "/createSimilar"),
    propsFromMatch: match => ({ bookingId: match.params.tripId ?? match.params.id }),
    propsToPath: ({ }) => "/createSimilar",
    navLabel: () => "Create Similar Booking",
    id: "new_create_booking_view",
    render: ({ viewProps }) => {
        return (
            <CreateSimilarBookingViewWithData
                bookingId={viewProps.bookingId}
                renderWhenData={true}
                undefineOnUpdate={false}
            />
        );
    }
}