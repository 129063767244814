import { Theme } from "../css/Theme";
import { genJss } from "../css/gen.jss";

// noinspection JSUnusedLocalSymbols
export const adminUsersViewJss = (theme: Theme) => ({
    filters: {
        ...genJss.flex,
        ...genJss.alignCenter,
        marginBottom: '20px',
        '& > *:first-child': {
            width: '300px'
        },
        '& > *': {
            marginRight: '40px'
        }
    },

});