import { Theme } from "../css/Theme";
import { black, genJss, resetJss } from "../css/gen.jss";
import { genStyles } from "tripkit-react";

export const bookingViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex
    },
    leftContainer: {
        ...genJss.flex,
        ...genJss.column,
        width: '50%',
        overflowY: 'auto',
        height: '100%'
    },
    mapContainer: {
        width: '50%'
    },
    tabs: {
        marginBottom: '30px',
        '& .MuiTabs-root': {
            borderBottom: '1px solid ' + black(4)
        },
        '& .MuiTabs-flexContainer': {
            justifyContent: 'space-around'
        },
        '& .MuiTab-root': {
            textTransform: 'initial'
        },
        '& .Mui-selected': {
            color: theme.colorPrimary
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.colorPrimary + '!important'
        }
    },
    fromToIcon: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignCenter,
        margin: '4px'
    },
    dot: {
        width: '8px',
        height: '8px',
        ...genJss.borderRadius(5),
        '&:first-child': {
            backgroundColor: theme.colorPrimary,
        },
        '&:last-child': {
            backgroundColor: theme.colorSecondary,
        }
    },
    line: {
        ...genJss.grow,
        borderLeft: '1px solid ' + theme.colorPrimary
    },
    locLab: {
        ...theme.textColorGray,
        margin: '0 15px'
    },
    toValue: {
        marginTop: '20px'
    },
    locationName: {
        marginTop: '10px',
        fontStyle: 'italic'
    },
    noteSection: {
        gridColumnStart: '0',
        gridColumnEnd: '1',
        '& $value': {
            textAlign: 'left'
        }
    },
    notesSection: {
        '& $value > *:not(:first-child)': {
            marginTop: '20px'
        }
    },
    iconEdit: {
        height: '16px',
        width: '16px',
        marginRight: '1ch',
        '& path': {
            fill: theme.colorPrimary
        }
    },
    subHeader: {
        marginBottom: '10px'
    },
    buttons: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& button:not(:first-child)': {
            marginLeft: '20px'
        }
    },
    /**
     * Move styles below to app.jss.ts. It's already being reused by MoneyTransactionView.jss.ts
     * Nest section, gridSection, entry, field and value below detailForm.
     */
    detailForm: {
        '& > *': {
            marginBottom: '40px'
        }
    },
    section: {
        ...genStyles.flex,
        ...genStyles.column,
        '& > *:not(:last-child)': {
            marginBottom: '40px'
        }
    },
    gridSection: {
        display: 'grid!important',
        width: '100%',
        gridTemplateColumns: '47% 47%',
        gridRowGap: '40px',
        gridColumnGap: '6%'
    },
    entry: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.alignStart
    },
    field: {
        ...genJss.fontL,
        ...theme.textWeightSemibold,
        marginBottom: '15px'
    },
    value: {
        '&$gridSection': {
            gridRowGap: '30px'
        },
        '&$gridSection, section': {
            marginTop: '10px'
        },
        '& $entry': {
            margin: '0 30px 0 0'
        },
        '& $field': {
            ...theme.textSizeBody,
            ...theme.textColorGray
        },
        '& $value': {
            ...theme.textColorDefault
        }
    },
    warn: {
        color: theme.colorError
    },
    editStatusBtn: {
        ...resetJss.button,
        height: '28px',
        width: '28px',
        marginLeft: '5px'
    },
    statusCell: {
        ...genJss.flex,
        ...genJss.alignCenter,
        '& > *': {
            whiteSpace: 'nowrap'
        }
    }
});