import { Theme } from "../css/Theme";
import { genJss, resetJss } from "../css/gen.jss";
import { appGlobalJss } from "../css/app.jss";

export const editBundleViewJss = (theme: Theme) => ({

    discountInput: {
        padding: '0 25px',
        width: '80px',
        marginLeft: '10px',
        boxSizing: 'border-box',
        flexGrow: '0!important'
    },

    selectDiscType: {
        width: '70px',
        '& .select__value-container': {
            paddingTop: '0',
            paddingBottom: '0'
        },
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        },
        '& *': {
            lineHeight: '36px'
        }
    },

    tModesFormGroup: {
        ...appGlobalJss(theme).formGroup,
        ...genJss.column,
        ...genJss.alignStretch
    },

    buttonsPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.spaceBetween,
        marginTop: '35px',
        '& button': {
            maxWidth: '300px'
        }
    },

    buttonDelete: {
        ...resetJss.button,
        '& svg': {
            width: '24px',
            height: '24px'
        }
    },

    another: {
        marginRight: '40px'
    },

    select: {
        width: '300px',
        margin: '20px 0 0 30px',
        '& .select__value-container > div:last-child': {
            padding: '0',
            margin: '0'
        }
    },

    activationMessage: {
        fontSize: '19px'
    },

    activated: {
        color: theme.colorPrimary
    },

    textAreaValue: {
        minHeight: '42px'
    },
    sponsorImgPlaceholder: {
        ...resetJss.button,
        border: '1px dashed #ccc',
        height: '100px',
        width: '150px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '4px',
        position: 'relative',
        '&:hover': {
            borderColor: theme.colorPrimary,
            '& svg path': {
                fill: theme.colorPrimary
            }
        }
    },
    sponsorImgContainer: {
        ...genJss.flex,
        position: 'relative',
        borderRadius: '4px',
        '&:hover svg path': {
            fill: theme.colorPrimary
        }
    },
    sponsorImg: {
        width: '150px',
        height: 'auto',
        maxHeight: '250px',
        borderRadius: '8px',
        minHeight: '90px',
        objectFit: 'contain'
    },
    addImgBtn: {
        ...resetJss.button,
        position: 'absolute',
        bottom: '-5px',
        right: '-10px',
        height: '30px',
        width: '30px',
        padding: '6px',
        background: 'white',
        border: '1px solid lightgray',
        borderRadius: '50%',
        '& svg': {
            width: '100%',
            height: '100%'
        },
        '& svg path': {
            fill: 'gray'
        }
    },
    description: {
        ...genJss.grow,
        '&::placeholder': {
            fontStyle: 'italic'
        }
    },
    selectValue: {
        width: '300px',
        '& > div': {
            ...genJss.grow,
            width: 'initial'
        }
    }
});