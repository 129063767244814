import React, { useEffect, useMemo, useRef, useState } from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { priceChangeViewJss } from "./PriceChangeView.jss";
import Loading from "../view/Loading";
import SideView from "../view/SideView";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import FormatUtil from "../util/FormatUtil";
import Validator from "../validator/Validator";
import { ValidatorForm } from "react-form-validator-core";
import UIUtil from "../util/UIUtil";
import TransactionsData from "../data/TransactionsData";
import Transaction, { PriceChangeStatus, priceChangeStatusColor, priceChangeStatusLabel, priceChangeStatusValues } from "../model/Transaction";
import { DefaultSelect } from "../rewards/StatusSelect";
import DateTimeUtil from "../util/DateTimeUtil";
import { useTheme } from "react-jss";
import { Theme } from "../css/Theme";
import GQLError from "../util/GQLError";
import { PriceChangeActionType } from "./PriceChangeViewHelpers";

type IStyle = ReturnType<typeof priceChangeViewJss>;

interface IProps extends WithStyles<IStyle> {
    booking?: Transaction;
    priceActionType?: PriceChangeActionType;
    onRequestClose: () => void;
}

ValidatorForm.addValidationRule('isGreaterThanZero', (value) => {
    return value && value > 0;
});

const PriceChangeView: React.FunctionComponent<IProps> = (props: IProps) => {
    const { booking = new Transaction(), priceActionType, onRequestClose, classes, appClasses } = useStyles(props, priceChangeViewJss); // booking is never undefined since we assume renderWhenData is true
    const theme = useTheme() as Theme;
    const [waiting, setWaiting] = useState<boolean>(false);
    const [newPrice, setNewPrice] = useState<number | undefined>(undefined);
    const [selectedResponse, setSelectedResponse] = useState<PriceChangeStatus | undefined>(undefined);
    const [note, setNote] = useState<string>("");
    const formRef = useRef<any>(null);
    const responseOptions = useMemo(() => priceChangeStatusValues
        .filter(status => status !== "CREATED")
        .map(status => ({ value: status, label: priceChangeStatusLabel(status) })), []);
    const request = (priceActionType === "UPDATE" || priceActionType === "REQUEST") ? undefined : booking.priceChangeRequest;
    const response = !priceActionType && booking.priceChangeResponse;
    useEffect(() => {
        // This is to prevent the user from performing an action that they're not allowed to perform, e.g. and admin shared an UPDATE action deep link
        // to a TSP user, which is only allowd to perform a REQUEST instead.
        const priceAction = booking.actions.find(action => action.changes === "PRICE");
        if (priceActionType && priceActionType !== priceAction?.action) {
            UIUtil.errorMessage(new GQLError("You're not allowed to perform this action.", undefined, true),
                { onClose: () => onRequestClose() });
        }
    }, []);
    let title: string;
    switch (priceActionType) {
        case "REQUEST":
        case "RESPOND":
            title = "Price Change Request";
            break;
        case "UPDATE":
            title = "Price Update";
            break;
        default:
            title = "Price Change";
            break;
    }
    let submitButtonText: string | undefined;
    switch (priceActionType) {
        case "REQUEST":
            submitButtonText = "Submit";
            break;
        case "RESPOND":
            submitButtonText = "Submit Response";
            break;
        case "UPDATE":
            submitButtonText = "Update";
            break;
    }
    const submitEnabled = (priceActionType === "REQUEST" || priceActionType === "UPDATE") ? newPrice !== undefined : selectedResponse;
    const closeButtonText = priceActionType ? "Cancel" : "Close";
    const requestUI =
        <>
            <div className={appClasses.formGroup}>
                <label>
                    {priceActionType ? "Current price" : "Original price"}
                </label>
                <div className={classNames(appClasses.value)}>
                    {(priceActionType === "UPDATE" || priceActionType === "REQUEST") ?
                        FormatUtil.toMoney(booking.price, { nInCents: true }) :
                        request?.originalAmount && FormatUtil.toMoney(request.originalAmount, { nInCents: true })}
                </div>
            </div>
            <div className={appClasses.formGroup}>
                <label>
                    New price
                </label>
                {(priceActionType === "UPDATE" || priceActionType === "REQUEST") ?
                    <div className={classNames(appClasses.value, appClasses.currencyValue)}>
                        <div className={appClasses.currency}>$</div>
                        <input
                            type={"number"}
                            min={0}
                            step={"0.1"}
                            value={newPrice === undefined || newPrice < 0 ? "" : FormatUtil.truncateToDecimals(newPrice / 100, 2)}
                            onChange={(event) => {
                                const parsed = parseFloat(event.target.value);
                                setNewPrice((isNaN(parsed) || parsed <= 0) ? undefined : parsed * 100);
                            }}
                            placeholder="0"
                            className={appClasses.hidePlaceholderOnFocus} />
                    </div> :
                    <div className={classNames(appClasses.value, classes.newPrice)}>
                        {request?.amount && FormatUtil.toMoney(request.amount, { nInCents: true })}
                    </div>}
            </div>
            <div className={appClasses.formGroup}>
                <label>
                    Note
                </label>
                {(priceActionType === "UPDATE" || priceActionType === "REQUEST") ?
                    <textarea
                        name="note"
                        value={note}
                        onChange={(e) => {
                            setNote(e.currentTarget.value);
                        }}
                        placeholder="Enter a note here..." /> :
                    <div className={classNames(appClasses.value)}>
                        {request?.note}
                    </div>}
            </div>
            {request &&
                <div className={classNames(appClasses.formGroup, classes.timestamp)}>
                    <label></label>
                    <div className={classNames(appClasses.value)}>
                        {request.createdAt && DateTimeUtil.isoToMomentTimezone(request.createdAt).format(DateTimeUtil.dateFormatWithDay() + ", " + DateTimeUtil.timeFormat())}
                    </div>
                </div>}
        </>;
    const responseUI = (response || priceActionType === "RESPOND") &&
        <>
            <div className={classes.separator} />
            <div className={appClasses.formGroup}>
                <label>
                    Response
                </label>
                {!response ?
                    <Validator
                        value={selectedResponse}
                        name="newStatus"
                        validators={["required"]}
                        errorMessages={["this field is required"]}
                        resetOnValid
                    >
                        {({ errorMessage }) => <div className={classNames(appClasses.value)}>
                            <DefaultSelect
                                options={responseOptions}
                                value={selectedResponse}
                                onChange={(status?: PriceChangeStatus) => {
                                    setSelectedResponse(status);
                                }}
                                allowNoValue={true} />
                            {errorMessage &&
                                <div className={appClasses.validationError}>{errorMessage}</div>}
                        </div>}
                    </Validator> :
                    <div className={classNames(appClasses.value)} style={{ color: priceChangeStatusColor(response.status!, theme), fontWeight: 'bold' }}>
                        {priceChangeStatusLabel(response.status!)}
                    </div>}
            </div>
            <div className={appClasses.formGroup} style={{ marginRight: '30px' }}>
                <label htmlFor="note">Response Note</label>
                {!response ?
                    <textarea name="note"
                        value={note}
                        onChange={(e) => {
                            setNote(e.currentTarget.value);
                        }}
                        placeholder="Enter a note here..." /> :
                    <div className={classNames(appClasses.value)}>
                        {response.note}
                    </div>}
            </div>
            {response &&
                <div className={classNames(appClasses.formGroup, classes.timestamp)}>
                    <label></label>
                    <div className={classNames(appClasses.value)}>
                        {response.createdAt && DateTimeUtil.isoToMomentTimezone(response.createdAt).format(DateTimeUtil.dateFormatWithDay() + ", " + DateTimeUtil.timeFormat())}
                    </div>
                </div>}
        </>;
    return (
        <SideView
            title={title}
            onRequestClose={onRequestClose}
        >
            <div className={classes.main}>
                {waiting && <Loading overlay={true} />}
                <ValidatorForm
                    instantValidate={false}
                    ref={formRef}
                    onSubmit={() => { }} // To avoid warning that onSubmit is required.
                >
                    <div className={classNames(appClasses.form, classes.form)}>
                        {requestUI}
                        {responseUI}
                    </div>
                </ValidatorForm>
                <div className={classNames(classes.buttonsPanel, genStyles.flex, genStyles.alignCenter, genStyles.spaceBetween)}>
                    <button onClick={() => onRequestClose()} className={appClasses.buttonCancel}>
                        {closeButtonText}
                    </button>
                    {submitButtonText &&
                        <button
                            onClick={async () => {
                                const valid = await formRef.current.isFormValid(false);
                                if (valid) {
                                    setWaiting(true);
                                    try {
                                        if (priceActionType === "UPDATE") {
                                            await TransactionsData.instance.changePrice(booking.clientId!, {
                                                bookingID: booking.id,
                                                amount: newPrice!,
                                                note: note
                                            });
                                        } else if (priceActionType === "REQUEST") {
                                            await TransactionsData.instance.createPriceChangeRequest(booking.clientId!, {
                                                bookingID: booking.id,
                                                amount: newPrice!,
                                                note: note
                                            });
                                        } else if (priceActionType === "RESPOND") {
                                            await TransactionsData.instance.priceChangeResponse(booking.clientId!, {
                                                bookingID: booking.id,
                                                accept: selectedResponse === "ACCEPTED",
                                                note: note
                                            });
                                        } else {
                                            const errorMessage = "Invalid price action type.";
                                            console.log(errorMessage)
                                            throw new Error(errorMessage);
                                        }
                                        onRequestClose();
                                    } catch (error) {
                                        UIUtil.errorMessage(error as Error);
                                    } finally {
                                        setWaiting(false);
                                    }
                                }
                            }}
                            className={appClasses.buttonAdd}
                            disabled={!submitEnabled}
                        >
                            {submitButtonText}
                        </button>}
                </div>
            </div >
        </SideView >
    );
};

export default PriceChangeView;