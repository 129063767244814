import React from "react";
import { useStyles, WithStyles } from "../css/StyleHelper";
import { noteJss } from "./Note.jss";
import { TransactionNote } from "../model/Transaction";
import DateTimeUtil from "../util/DateTimeUtil";
import classNames from "classnames";
import { ReactComponent as IconLocked } from '../images/ic-locked.svg';
import Tooltip from "../uiutil/Tooltip";
import { adminProfile } from "../account/AdminProfile";
import { useProviders } from "../data/ProvidersData";
import { ReactComponent as IconEdit } from "../images/ic-edit.svg";

type IStyle = ReturnType<typeof noteJss>;

interface IProps extends WithStyles<IStyle> {
    note: TransactionNote;
    timezone: string;
    onEdit?: () => void;
}

const Note: React.FunctionComponent<IProps> = (props: IProps) => {
    const { note, timezone, onEdit, classes, appClasses } = useStyles(props, noteJss);
    const providers = useProviders();
    const noteProvider = note.providerMode && providers?.find(p => p.id === note.providerMode);
    const author = note.creatorEmail === adminProfile.email ? "Me" :
        note.creatorEmail + (noteProvider ? " from " + noteProvider.modeName : "");
    return (
        <div className={classNames(classes.main, note.internal && classes.internal, !note.internal && classes.public)}>
            <div className={classes.bubbleAndLock}>
                <div className={classes.bubble}>
                    <div className={classes.text}>
                        {note.text}
                    </div>
                    {onEdit &&
                        <IconEdit
                            className={classes.iconEdit}
                            onClick={onEdit}
                        />}
                </div>
                {note.internal &&
                    <Tooltip title={"Internal note, not visible to the rider."} className={appClasses.cursorHelp}>
                        <IconLocked className={classes.lock} />
                    </Tooltip>
                }
            </div>
            <div className={classes.footer}>
                {author + " ⋅ " +
                    DateTimeUtil.formatRelativeDay(DateTimeUtil.momentTZ(note.timestamp, timezone), DateTimeUtil.dateFormat()) +
                    ", " + DateTimeUtil.momentTZ(note.timestamp, timezone).format(DateTimeUtil.timeFormat())}
            </div>
        </div>
    );
};

export default Note;