import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject
class ModeIdentifier {
    @JsonProperty("title", String)
    public readonly title: string = "";
    @JsonProperty("icon", String, true)
    public readonly icon?: string = undefined;
    @JsonProperty("combines", [String], true)
    public readonly combines?: string[] = undefined;
    @JsonProperty("phone", String, true)
    public readonly phone?: string = undefined;
    @JsonProperty("unschedulable", Boolean, true)
    public readonly unschedulable?: boolean = undefined;
    @JsonProperty("unschedulableReason", String, true)
    public readonly unschedulableReason?: string = undefined;
    @JsonProperty("exclusive", Boolean, true)
    public readonly exclusive?: boolean = undefined;

    get isCombined(): boolean {
        return !!this.combines && this.combines.length > 0;
    }

    get schedulable(): boolean {
        return !this.unschedulable;
    }

    private _identifier: string = "";

    public static readonly SCHOOLBUS_ID = "pt_ltd_SCHOOLBUS";
    public static readonly UBER_ID = "ps_tnc_UBER";
    public static readonly CAR_RENTAL_SW_ID = "me_car-r_SwiftFleet";
    public static readonly TAXI_ID = "ps_tax";
    public static readonly PUBLIC_TRANSPORT_ID = "pt_pub";
    public static readonly TRAM_ID = "pt_pub_tram";
    public static readonly WALK_ID = "wa_wal";

    get identifier(): string {
        return this._identifier;
    }

    set identifier(value: string) {
        this._identifier = value;
    }
}

export default ModeIdentifier;