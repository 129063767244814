import React, { useRef } from "react";
import classNames from "classnames";
import genStyles from "../css/general.module.css";
import { ReactComponent as IconAdd } from "../images/ic-add.svg";
import Bundle, { BundleStatus, TMode } from "../model/Bundle";
import TransportUtil from "../util/TransportUtil";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { bundlesViewJss } from "./BundlesView.jss";
import Loading from "../view/Loading";
import Filter, { SearchField, SortOrder } from "../data/Filter";
import { IViewRouteConfig } from "../nav/IViewRouteConfig";
import Util from "../util/Util";
import { ACTIVATION_STATUS_TOOLTIP, BUNDLE_NEW_VIEW, durationStringFromDays } from "./EditBundleView";
import { withAsyncDataObs } from "../data/WithAsyncData";
import { BUNDLE_DETAIL_VIEW } from "./BundleView";
import { map } from 'rxjs/operators';
import { PARTICIPANTS_VIEW } from "../user/UsersView";
import Paginate from "../paginate/Paginate";
import ItemsResult from "../model/ItemsResult";
import BundlesData from "../data/BundlesData";
import { Observable } from 'rxjs';
import { ReactComponent as ShowIcon } from '../images/ic-external-link.svg'
import Tooltip from "../uiutil/Tooltip";
import AdminProfile, { ModelOperation, ModelType, adminProfile } from "../account/AdminProfile";
import { getClientIDPath } from "../app/App";
import Table, { ITableColSpec } from "../view/Table";
import { Client } from "../model/Client";
import { i18n } from "../i18n/TKI18nConstants";
import TableSortLabel from "@mui/material/TableSortLabel/TableSortLabel";
import PageSizeSelect from "../paginate/PageSizeSelect";
import ViewRestyled from "../view/ViewRestyled";
import MemoScroll, { MemoScrollRef } from "../uiutil/MemoScroll";
import SearchBox, { Item } from "../search/SearchBox";
import { ShortIdCell } from "../booking/BookingsTable";
import FilterPopup, { FilterConfig } from "../FilterPopup";
import { DefaultSelect } from "../rewards/StatusSelect";
import { useInitiativeOptions } from "../data/InitiativesData";
import { ReactComponent as IconSpin } from "../images/ic-refresh.svg";
import { useProviders } from "../data/ProvidersData";

type BundleTableColId = "short_id" | "bundle_name" | "client_app" | "subscribers" | "transport_modes" | "subscription_durations" | "status" | "initiative";

/**
 * Order is relevant, too. Notice the order of charge column changes depending on the tableId.
 * No need to filter out non available since Table component does this.
 */
export function getDefaultColumns(adminProfile: AdminProfile): BundleTableColId[] {
    const dafaultColumns: BundleTableColId[] = [
        "bundle_name", "client_app", "subscribers", "transport_modes", "subscription_durations", "status"
    ];
    return dafaultColumns;
}

type IStyle = ReturnType<typeof bundlesViewJss>;

export interface IProps extends WithClasses<IStyle> {
    filter: Filter;
    onFilterChange?: (filter?: Filter) => void;
    bundlesResult?: ItemsResult<Bundle>;
    onSelect?: (id: string) => void;
    onCreate?: () => void;
    onShowUsers?: (bundle: Bundle, upcoming: boolean) => void;
    selectedClientID?: string;
    clients?: Client[];
    onRefreshClick?: () => void;
}

export const NUMBER_OF_STAFF_TOOLTIP = i18n.t("Number.of.users.that.are.currently.subscribed.to.the.bundle.(current.subscribers),.and.number.of.users.that.picked.the.bundle.as.the.next.one,.to.be.applied.on.the.next.period.(upcoming.subscribers).");
export const TRANSPORT_MODES_TOOLTIP = "Transport modes for which some benefit is applied by the plan.";
export const BUNDLE_DURATIONS_TOOLTIP = i18n.t("The.possible.durations.of.the.bundle.subscription:.Weekly.(W),.Fortnightly.(F),.Monthly(M).or.Quarterly.(Q)");

const BundlesView: React.FunctionComponent<IProps> = ({
    filter, onFilterChange, selectedClientID, bundlesResult,
    onSelect, onCreate, onShowUsers, classes, appClasses, clients, onRefreshClick
}) => {

    function handlePageClick(selectedItem: { selected: number }) {
        if (onFilterChange) {
            const filterUpdate = Util.iAssign(filter, { page: selectedItem.selected + 1 });
            onFilterChange(filterUpdate)
        }
    }

    function handleSortOrderClick() {
        if (onFilterChange) {
            const currSortOrder = filter.sortOrder;
            const filterUpdate = Util.iAssign(filter,
                {
                    sortOrder: (!currSortOrder || currSortOrder === SortOrder.ASC) ? SortOrder.DESC : SortOrder.ASC,
                    page: 1
                });
            onFilterChange(filterUpdate)
        }
    }

    const initiativeOptions = useInitiativeOptions(selectedClientID);
    const providers = useProviders({ clientId: selectedClientID, adminProfile });

    const memoScrollRef = useRef<MemoScrollRef>(null);

    const page = filter.page ?? 1;
    const pageCount = bundlesResult ? bundlesResult.pageCount + (bundlesResult.more ? 1 : 0) : undefined;
    let body;
    if (!bundlesResult || (bundlesResult.count === 0 && bundlesResult.waitingMore)) {
        body = <Loading />;
    } else if (bundlesResult.count === 0) {
        body =
            <div className={appClasses.noResults}>
                No results found
            </div>;
    } else {
        const tableContent: ITableColSpec<Bundle, BundleTableColId>[] = [
            {
                id: "bundle_name",
                name: i18n.t("Bundle.Name"),
                label: (
                    <TableSortLabel
                        active={true}
                        direction={filter.sortOrder ?? SortOrder.ASC}
                        onClick={handleSortOrderClick}
                    >
                        {i18n.t("Bundle.Name")}
                    </TableSortLabel>
                ),
                cellValue: item => item.name
            },
            {
                id: "client_app",
                name: i18n.t("Client.app"),
                cellValue: item => clients?.find(client => client.clientID === item.clientId)?.clientName,
                available: adminProfile.isSuperApp,
                visible: !selectedClientID,
                width: 140
            },
            {
                id: "subscribers",
                name: "Subscribers",
                label: (
                    <Tooltip title={NUMBER_OF_STAFF_TOOLTIP}>
                        Subscribers
                    </Tooltip>
                ),
                cellValue: item =>
                    <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                        <div className={classNames(classes.numOfStaffLink)}>
                            <span className={genStyles.charSpaceRight}>Current:</span>
                            {item.currentUserCount}
                            <button className={appClasses.cellHiddenBtn}
                                onClick={(e) => {
                                    onShowUsers && onShowUsers(item, false);
                                    e.stopPropagation();
                                }}
                            >
                                <ShowIcon />
                            </button>
                        </div>
                        <div className={classNames(classes.numOfStaffLink)}>
                            <span className={genStyles.charSpaceRight}>Upcoming:</span>
                            {item.futureUserCount}
                            <button className={appClasses.cellHiddenBtn}
                                onClick={(e) => {
                                    onShowUsers && onShowUsers(item, true);
                                    e.stopPropagation();
                                }}
                            >
                                <ShowIcon />
                            </button>
                        </div>
                    </div>
            },
            {
                id: "transport_modes",
                name: "Transport Modes",
                label: (
                    <Tooltip title={TRANSPORT_MODES_TOOLTIP}>
                        Transport Modes
                    </Tooltip>
                ),
                cellValue: item =>
                    item.tmodes && item.tmodes.map((tmode: TMode, i) => {
                        const provider = providers?.find(provider => provider.id === tmode.mode);
                        return provider &&
                            <img className={appClasses.modeIcon}
                                src={TransportUtil.getTransportIconFromProvider(provider)}
                                alt=""
                                key={`${tmode.mode}-${i}`}
                            />
                    })
            },
            {
                id: "subscription_durations",
                name: i18n.t("Subscription.durations"),
                label: (
                    <Tooltip title={BUNDLE_DURATIONS_TOOLTIP}>
                        {i18n.t("Subscription.durations")}
                    </Tooltip>
                ),
                cellValue: item =>
                    item.bundleConfig.map(config =>
                        durationStringFromDays(config.subscriptionDurationInDays).substring(0, 1))
                        .join(", ")
            },
            {
                id: "status",
                name: "Status",
                label: (
                    <Tooltip title={ACTIVATION_STATUS_TOOLTIP}>
                        Status
                    </Tooltip>
                ),
                cellValue: item => {
                    const active = item.status === BundleStatus.ACTIVE;
                    return (
                        <div className={active ? classes.active : undefined}>{active ? "Active" : "Inactive"}</div>
                    );
                }
            },
            {
                id: "short_id",
                name: "ID",
                cellValue: (value: Bundle) =>
                    value.shortId &&
                    <ShortIdCell shortId={value.shortId} />
            },
            {
                id: "initiative",
                name: i18n.t("Initiative"),
                available: adminProfile.features.trackTripInitiative22828,
                cellValue: bundle => {
                    return bundle.initiative?.title;
                },
                width: 140
            }
        ]
        body =
            <React.Fragment>
                <Table
                    tableId={adminProfile.app + "-" + bundlesViewId}
                    contentSpec={tableContent}
                    defaultColumns={getDefaultColumns(adminProfile)}
                    items={bundlesResult.items}
                    configurable={true}
                    onClick={item => onSelect && onSelect(item.id)}
                />
                {bundlesResult && bundlesResult.waitingMore && <Loading />}
            </React.Fragment>;
    }
    const isAllAppsView = adminProfile.isSuperApp && !selectedClientID;
    let addBundleBtn =
        adminProfile.itemAuth(ModelType.Bundle, ModelOperation.create) &&
        <button onClick={onCreate} className={appClasses.buttonAdd} disabled={isAllAppsView}>
            <IconAdd className={classNames(genStyles.svgPathFillCurrColor, genStyles.halfCharSpace)} />
            {i18n.t("Add.bundle")}
        </button>;
    if (isAllAppsView && addBundleBtn) {
        addBundleBtn =
            <Tooltip title={i18n.t("Select.a.client.app.on.the.left.nav.bar.to.be.able.to.add.a.bundle.")}>
                <div>
                    {addBundleBtn}
                </div>
            </Tooltip>;
    }
    const filterPopupConfigs: FilterConfig[] = [
        ...Util.insertIf(adminProfile.features.trackTripInitiative22828,
            {
                label: "Initiative",
                renderControl: (filter: Filter, onFilterChange: (filter: Filter) => void) =>
                    <div className={classes.filterSelect}>
                        <DefaultSelect
                            options={initiativeOptions ?? []}
                            value={filter.initiativeId}
                            onChange={value => {
                                const newFilter = Util.iAssign(filter, { initiativeId: value, page: 1 });
                                onFilterChange?.(newFilter);
                            }}
                            forFilter={true}
                            allOptionsLabel="All"
                            isSearchable
                            isDisabled={!initiativeOptions}
                        />
                    </div>,
                valueSummary: (filter: Filter) => filter.initiativeId && initiativeOptions?.find(option => option.value === filter.initiativeId)?.label,
                onClear: (filter: Filter, onFilterChange: (filter: Filter) => void) => {
                    const newFilter = Util.iAssign(filter, { initiativeId: undefined, page: 1 });
                    onFilterChange(newFilter);
                }
            })
    ];
    return (
        <ViewRestyled
            title={i18n.t("Mobility.bundles")}
            right={addBundleBtn}
        >
            <div className={classes.filters}>
                {filterPopupConfigs.length > 0 &&
                    <FilterPopup
                        filter={filter}
                        onFilterChange={onFilterChange!}
                        filterConfigs={filterPopupConfigs}
                    />}
                <div className={classes.search}>
                    <SearchBox />
                </div>
                <div className={classNames(genStyles.flex, genStyles.alignCenter, genStyles.marginLeftAuto)} style={{ marginLeft: 'auto' }}>
                    <div className={classNames(genStyles.flex, genStyles.alignCenter)}>
                        <label className={genStyles.charSpace}>{i18n.t("Page.size")}</label>
                        <PageSizeSelect
                            value={filter.pageSize}
                            onChange={(pageSize: number) => onFilterChange?.(Util.iAssign(filter, { pageSize, page: 1 }))}
                        />
                    </div>
                    {onRefreshClick &&
                        <button className={classNames(appClasses.refreshBtn, genStyles.separationLeft)}
                            onClick={onRefreshClick}
                        >
                            <IconSpin />
                        </button>}
                </div>
            </div>
            <MemoScroll id={"usersList"} className={classes.tableContainer} ref={memoScrollRef} hasData={bundlesResult && !bundlesResult.waitingMore}>
                {body}
                <div className={appClasses.paginatePanel}>
                    {!!pageCount &&
                        <Paginate
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            page={page}
                        />}
                </div>
            </MemoScroll>
        </ViewRestyled>
    );

}

export function getObservableBundles(query: { filter: Filter }): Observable<{ bundlesResult?: ItemsResult<Bundle> }> {
    return BundlesData.instance.watchQuery(query.filter)
        .pipe(map((result?: ItemsResult<Bundle>) => ({ bundlesResult: result }))) as Observable<{ bundlesResult?: ItemsResult<Bundle> }>;
}

const BundlesViewStyled = withStyles(BundlesView, bundlesViewJss);

// noinspection JSUnusedLocalSymbols
const BundlesViewWithData = withAsyncDataObs(BundlesViewStyled, getObservableBundles);

const bundlesViewId = "BUNDLES_VIEW";

export const BUNDLES_VIEW: IViewRouteConfig<{ filter?: Filter }> =
{
    path: ["*/bundles/filter/:filter", "*/bundles"],
    propsFromMatch: (match, profile) => {
        const filter = match !== null && match.params.filter && !Util.isEmpty(match.params.filter) ?
            Util.parseFilter(match.params.filter) :
            Util.deserialize({
                pageSize: profile.pageSizeByView.get(bundlesViewId) ?? 10,
                page: 1,
                sortOrder: SortOrder.ASC
            }, Filter);
        filter.clientId = getClientIDPath();
        return { filter: filter };
    },
    propsToPath: (props: { filter?: Filter }) => "/bundles" + (props.filter ? "/filter/" + Util.stringifyFilter(props.filter) : ""),
    navLabel: () => i18n.t("Mobility.bundles"),
    render: ({ viewProps, navHistory, selectedClientID, clients, profile, onProfileChange }) => {
        if (!adminProfile.itemAuth(ModelType.Bundle, ModelOperation.read)) {
            return null;
        }
        const filter = viewProps.filter as Filter;
        const onFilterChange = (update?: Filter) => {
            if (update && filter.pageSize !== update.pageSize) {
                const profileUpdate = Util.deepClone(profile);
                profileUpdate.pageSizeByView.set(bundlesViewId, update.pageSize);
                onProfileChange(profileUpdate);
            }
            navHistory.replace(BUNDLES_VIEW, { filter: update })
        };
        const onSelect = (id: string) => navHistory.push(BUNDLE_DETAIL_VIEW, { id });
        const onCreate = () => navHistory.push(BUNDLE_NEW_VIEW, {});
        const onShowUsers = (bundle: Bundle, upcoming: boolean) => {
            const filter = Util.deserialize({
                pageSize: 10,
                page: 1,
                search: { label: bundle.name, data: bundle.id, field: upcoming ? SearchField.UPCOMING_BUNDLE : SearchField.BUNDLE }
            }, Filter);
            navHistory.replace(PARTICIPANTS_VIEW, { filter: filter }, true);
        };
        // Reset page number after a reload.
        if (filter.page > 1 && BundlesData.instance.isEmpty()) {
            onFilterChange(Util.iAssign(filter, { page: 1 }));
        }
        const viewRef = React.createRef<any>();
        const onRefresh = () => {
            BundlesData.instance.invalidateBundlesCaches();
            viewRef.current.refresh(true);
        };
        return (
            <BundlesViewWithData
                filter={filter!}
                onFilterChange={onFilterChange}
                onSelect={onSelect}
                onCreate={onCreate}
                onShowUsers={onShowUsers}
                selectedClientID={selectedClientID}
                clients={clients}
                onRefreshClick={onRefresh}
                ref={viewRef}
            />
        );
    },
    searchProps: ({ viewProps, navHistory, selectedClientID }) => {
        const filter = viewProps.filter!;
        return {
            fields: [],
            value: filter.search,
            clientId: selectedClientID,
            onChange: (value?: Item) => {
                filter.search = value;
                filter.page = 1;
                navHistory.replace2(BUNDLES_VIEW, { filter });
            }
        }
    }
};