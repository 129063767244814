import { adminProfile } from "../account/AdminProfile";
import { Theme } from "../css/Theme";
import { appGlobalJss } from "../css/app.jss";
import { black, genJss, resetJss } from "../css/gen.jss";

const LEFT_PANEL_MAX_WIDTH_MAP_WHEN_ROOM = 1000;

export const newCreateBookingViewJss = (theme: Theme) => ({
    main: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.spaceBetween,
        '& *': {
            boxSizing: 'border-box'
        },
        '& *[class^="stepperButtons-"] button': {
            ...resetJss.button
        }
    },

    displayMap: {
        '& $leftContainer': {
            width: '530px'
        },
        '& $mapContainer': {
            width: 'calc(100% - 600px)'
        }
    },

    displayMapWhenRoom: {
        ['@media (max-width: ' + 1500 + 'px)']: {
            '& $leftContainer': {
                width: '100%!important'
            },
            '& $mapContainer': {
                position: 'absolute',
                right: '-600px'
            }
        },
        '& $leftContainer': {
            width: 'initial!important',
            flexGrow: 1,
            maxWidth: LEFT_PANEL_MAX_WIDTH_MAP_WHEN_ROOM + 'px',
            marginRight: '20px'
        },
        '& $mapContainer': {
            // width: 'calc(100% - 1120px)'
            width: `calc(100% - ${LEFT_PANEL_MAX_WIDTH_MAP_WHEN_ROOM + 20}px)`    // 20 of separation between form and map
        }
    },

    leftContainer: {
        ...genJss.flex,
        ...genJss.column,
        width: '100%',
        position: 'relative',
        '& form': {
            ...genJss.grow
        }
    },
    mapContainer: {
        height: 'calc(100vh - 264px)',
        borderRadius: '6px',
        overflow: 'hidden'
    },
    imageLabel: {
        textAlign: 'left!important',
        marginBottom: '13px'
    },
    dnDImage: {
        ...genJss.flex,
        width: '100%',
        height: '400px'
    },
    footer: {
        ...genJss.flex,
        ...genJss.justifyEnd,
        marginTop: '10px',
        '&>*:not(:last-child)': {
            marginRight: '20px'
        }
    },
    durationInput: {
        textAlign: 'right',
        '&::placeholder': {
            textAlign: 'left',
        }
    },
    lockPanel: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.alignSelfStretch,
        margin: '22px 0'
    },
    lockLines: {
        ...genJss.alignSelfStretch,
        width: '20px',
        borderTop: '1px solid ' + black(2),
        borderRight: '1px solid ' + black(2),
        borderBottom: '1px solid ' + black(2),
    },
    lockButton: {
        ...resetJss.button,
        width: '20px',
        height: '20px',
        marginLeft: '7px',
        '& svg': {
            width: '100%',
            height: '100%',
            '& path': {
                fill: black(2)
            }
        }
    },
    schedulingModeWarn: {
        ...theme.textWeightSemibold,
        ...theme.textSizeBody,
        ...theme.divider,
        margin: '55px 10px 0 10px',
        paddingBottom: '15px'
    },
    timePrefSelect: {
        ...genJss.flex,
        width: '125px',
        marginRight: '15px',
        marginLeft: '-3px'
    },
    returnTripInput: {
        ...genJss.flex,
        ...genJss.alignCenter,
        ...genJss.grow,
        '&>*:first-child': {
            marginRight: '15px'
        },
        'input': {
            with: '300px'
        }
    },
    loadingPanel: {
        ...genJss.flex,
        ...genJss.grow,
        ...genJss.center,
        position: 'absolute',
        top: '0',
        backgroundColor: '#ffffffbf',
        height: '100%',
        width: '100%',
        zIndex: 5
    },
    iconUserDetails: {
        ...resetJss.button,
        padding: '7px',
        margin: '0px -40px 0px 3px',
        '& svg path': {
            fill: theme.colorPrimary
        }
    },
    selectContainer: {
        '&>*': {
            width: 'initial!important',
            minWidth: '185px'
        }
    },
    formGroup: {
        ...adminProfile.features.favorites22590 ? { marginBottom: '40px' } : {}
    },
    availabilityInfo: {
        ...genJss.flex,
        ...genJss.alignCenter,
        color: theme.colorError,
        '& svg': {
            width: '20px',
            height: '20px',
            marginRight: '10px',
            ...genJss.noShrink
        },
        '& path': {
            fill: theme.colorError
        }
    },
    loadingText: {
        display: 'inline-block',
        '&::after': {
            content: "'...'",
            display: 'inline-block',
            width: '12px',
            animation: 'dots 1.5s steps(3, end) infinite'
        }
    },
    '@global': {
        '@keyframes dots': {
            '0%, 20%': { content: "''" },
            '40%': { content: "'.'" },
            '60%': { content: "'..'" },
            '80%, 100%': { content: "'...'" }
        }
    },
    inputWrapper: {
        '&>*': {
            ...appGlobalJss(theme).input
        }
    },
    viewSubtitle: {
        ...genJss.flex,
        ...genJss.column,
        ...genJss.grow,
        '&>*:not(:first-child)': {
            marginTop: '10px'
        }
    },
    openFullBtn: {
        marginLeft: '15px',
        '& svg': {
            height: '18px',
            width: '18px',
            '& path': {
                fill: theme.colorPrimary
            }
        }
    },
    longTextLabel: {
        alignSelf: 'flex-start',
        marginTop: '10px'
    }
});