import React from "react";
import { WithClasses, withStyles } from "../css/StyleHelper";
import { paginateJss } from "./Paginate.jss";
import ReactPaginate from "react-paginate";

type IStyle = ReturnType<typeof paginateJss>;

interface IProps extends WithClasses<IStyle> {
    pageCount: number,
    page: number,
    onPageChange: (selectedItem: { selected: number }) => void;
}

const Paginate: React.FunctionComponent<IProps> = (props: IProps) => {
    const styles = props.classes;
    return (
        <ReactPaginate
            pageCount={props.pageCount}
            pageRangeDisplayed={0}
            marginPagesDisplayed={0}
            onPageChange={props.onPageChange}
            forcePage={props.page - 1}
            breakLabel={null}
            breakClassName={'break-me'}
            containerClassName={styles.main}
            activeClassName={styles.active}
            previousLabel={"prev"}
            nextLabel={"next"}
        />
    );
};

export default withStyles(Paginate, paginateJss)